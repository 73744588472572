export enum StudyListColumn {
  PatientID = "patientId",
  PatientBirthdate = "patientBirthdate",
  Trainee = "trainee",
  Technician = "technician",
  Physician = "physician",
  Institute = "institute",
  PerformedBy = "performedBy",
  StudyType = "studyType",
}
