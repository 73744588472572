import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { computed, reactive, ref, type ComputedRef, type Ref } from "vue";
import type { ReportStructure } from "../../../backend/src/reporting/report-structure";
import type { ReportTemplateVersion } from "../utils/study-data";
import type { ReportStructureMutation } from "./report-structure-mutations";

/**
 * Reactive undo/redo functioanlity used by the report template editor.
 */
export function useReportStructureUndoRedo(
  selectedVersion: Ref<ReportTemplateVersion | undefined>
): {
  isUndoAvailable: ComputedRef<boolean>;
  isRedoAvailable: ComputedRef<boolean>;
  resetUndoHistory: () => void;
  addMutation: (mutation: ReportStructureMutation) => void;
  undo: () => boolean;
  redo: () => boolean;
  undoTooltip: ComputedRef<string>;
  redoTooltip: ComputedRef<string>;
} {
  const structure = computed(() => selectedVersion.value?.structure);

  const undoRedoHistory = ref<
    { title: string; structure: ReportStructure; undoRedoMergeKey?: string; timestamp: DateTime }[]
  >([]);
  const undoRedoPosition = ref(0);

  const isUndoAvailable = computed(() => undoRedoPosition.value > 0);
  const isRedoAvailable = computed(() => undoRedoHistory.value.length > undoRedoPosition.value + 1);

  function resetUndoHistory(): void {
    if (structure.value === undefined) {
      return;
    }

    undoRedoHistory.value.splice(0);
    undoRedoHistory.value.push({
      title: "",
      structure: cloneDeep(structure.value),
      timestamp: DateTime.now(),
    });
    undoRedoPosition.value = 0;
  }

  function addMutation(mutation: ReportStructureMutation): void {
    if (structure.value === undefined) {
      return;
    }

    mutation.perform(structure.value);

    // Erase any redo history
    undoRedoHistory.value.splice(undoRedoPosition.value + 1);

    // If this mutation can be merged with the last entry on the undo/redo history, i.e. it has the
    // same merge key and is within a 3 second timeout, then update the existing entry rather than
    // creating a new one.
    if (
      mutation.undoRedoMergeKey !== undefined &&
      mutation.undoRedoMergeKey ===
        undoRedoHistory.value[undoRedoPosition.value].undoRedoMergeKey &&
      undoRedoHistory.value[undoRedoPosition.value].timestamp.diffNow().milliseconds > -3000
    ) {
      undoRedoHistory.value[undoRedoPosition.value].structure = cloneDeep(structure.value);
      undoRedoHistory.value[undoRedoPosition.value].timestamp = DateTime.now();
    } else {
      undoRedoHistory.value.push({
        title: mutation.title,
        structure: cloneDeep(structure.value),
        undoRedoMergeKey: mutation.undoRedoMergeKey,
        timestamp: DateTime.now(),
      });
      undoRedoPosition.value = undoRedoHistory.value.length - 1;
    }
  }

  function undo(): boolean {
    if (!isUndoAvailable.value || selectedVersion.value === undefined) {
      return false;
    }

    undoRedoPosition.value -= 1;

    selectedVersion.value.structure = reactive(
      undoRedoHistory.value[undoRedoPosition.value].structure
    );

    return true;
  }

  function redo(): boolean {
    if (!isRedoAvailable.value || selectedVersion.value === undefined) {
      return false;
    }

    undoRedoPosition.value += 1;

    selectedVersion.value.structure = reactive(
      undoRedoHistory.value[undoRedoPosition.value].structure
    );

    return true;
  }

  const undoTooltip = computed(() => {
    if (!isUndoAvailable.value) {
      return "There is no action to undo";
    }

    return `Undo ${undoRedoHistory.value[undoRedoPosition.value].title}`;
  });

  const redoTooltip = computed(() => {
    if (!isRedoAvailable.value) {
      return "There is no action to redo";
    }

    return `Redo ${undoRedoHistory.value[undoRedoPosition.value + 1].title}`;
  });

  return {
    isUndoAvailable,
    isRedoAvailable,
    resetUndoHistory,
    addMutation,
    undo,
    redo,
    undoTooltip,
    redoTooltip,
  };
}
