import Flatten from "@flatten-js/core";
import type { StudyClipRegion } from "../studies/study-clip-region";
import { RegionUnit } from "../studies/study-clip-region";
import { MeasuredValue } from "./measured-value";
import { getVolumeFromPointsAndAxis } from "./measurement-tool-evaluation-volume-helpers";
import {
  MeasurementPhysicalQuantity,
  MeasurementUnit,
  getUnitPhysicalQuantity,
} from "./measurement-units";

/** Returns the value for a linear measurement on the specified clip in the region's units. */
export function getLinearMeasurementValue(
  from: number[],
  to: number[],
  clip: { width: number | null; height: number | null },
  region: StudyClipRegion
): MeasuredValue {
  if (clip.width === null || clip.height === null) {
    return MeasuredValue.Null;
  }

  const xDistance = Math.abs(from[0] - to[0]) * clip.width * region.physicalDeltaX;
  const yDistance = Math.abs(from[1] - to[1]) * clip.height * region.physicalDeltaY;

  let clipUnit: MeasurementUnit | null = null;
  let quantity: MeasurementPhysicalQuantity | null = null;

  if (
    (region.xDirectionUnit === RegionUnit.Centimeters &&
      region.yDirectionUnit === RegionUnit.Centimeters) ||
    (region.yDirectionUnit === RegionUnit.Centimeters && xDistance === 0)
  ) {
    clipUnit = MeasurementUnit.Centimeters;
    quantity = MeasurementPhysicalQuantity.Length;
  } else if (region.xDirectionUnit === RegionUnit.Seconds && yDistance === 0) {
    clipUnit = MeasurementUnit.Seconds;
    quantity = MeasurementPhysicalQuantity.Time;
  } else {
    throw Error("The combination of points and clip region is not valid for a linear measurement");
  }

  return new MeasuredValue(quantity, {
    value: Math.sqrt(xDistance ** 2 + yDistance ** 2),
    unit: clipUnit,
  });
}

/**
 * Returns the area enclosed by the given set of points in the clip's physical units squared.
 */
export function getAreaMeasurementValue(
  points: number[],
  clip: { width: number | null; height: number | null },
  region: StudyClipRegion
): MeasuredValue {
  if (clip.width === null || clip.height === null) {
    return MeasuredValue.Null;
  }

  let outputUnit = undefined;

  if (
    region.xDirectionUnit === RegionUnit.Centimeters &&
    region.yDirectionUnit === RegionUnit.Centimeters
  ) {
    outputUnit = MeasurementUnit.CentimetersSquared;
  } else if (
    region.xDirectionUnit === RegionUnit.Seconds &&
    region.yDirectionUnit === RegionUnit.CentimetersPerSecond
  ) {
    outputUnit = MeasurementUnit.Centimeters;
  } else {
    throw Error("Can't measure area on a region with these region units");
  }

  const physicalCoordinates = [];
  for (let i = 0; i < points.length; i += 2) {
    const x = points[i] * clip.width * region.physicalDeltaX;
    const y = points[i + 1] * clip.height * region.physicalDeltaY;
    physicalCoordinates.push([x, y]);
  }

  let area = 0;
  let j = points.length / 2 - 1;
  for (let i = 0; i < points.length / 2; i++) {
    area +=
      (physicalCoordinates[j][0] + physicalCoordinates[i][0]) *
      (physicalCoordinates[j][1] - physicalCoordinates[i][1]);
    j = i;
  }

  return new MeasuredValue(getUnitPhysicalQuantity(outputUnit), {
    value: Math.abs(area) / 2,
    unit: outputUnit,
  });
}

/**
 * Estimates the volume for an area on a clip rotated around the given axis.
 *
 * @param points The points that outline the volume, stored as `[x0, y0, x1, y1, ...]`. These values
 * are normalized to the 0-1 range along each axis.
 * @param axis The axis line to rotate around, stored as `[x0, y0, x1, y1]`. These values are
 * normalized to the 0-1 range along each axis, and should be on the edge of the outlined area.
 * @param clip The clip that the volume is being measured on.
 * @returns The estimated volume as a MeasuredValue object containing the value as an internal unit.
 */
export function getVolumeMeasurementValue(
  points: number[],
  axis: number[],
  clip: { width: number | null; height: number | null },
  region: StudyClipRegion
): MeasuredValue {
  if (clip.width === null || clip.height === null || axis.length !== 4) {
    return MeasuredValue.Null;
  }

  if (
    region.xDirectionUnit !== RegionUnit.Centimeters ||
    region.yDirectionUnit !== RegionUnit.Centimeters
  ) {
    throw Error("Can't measure volume on a region without cm by cm region units");
  }

  return new MeasuredValue(MeasurementPhysicalQuantity.Volume, {
    value: getVolumeFromPointsAndAxis(
      normalizePointsToPhysical(points, clip.width, clip.height, region),
      normalizePointsToPhysical(axis, clip.width, clip.height, region)
    ),
    unit: MeasurementUnit.Milliliters,
  });
}

export function getAngleMeasurementValue(points: number[]): MeasuredValue {
  if (points.length !== 6) {
    return MeasuredValue.Null;
  }

  // It's fairly common to get a divide by zero error here for some invalid angles so return
  // null in that case
  try {
    const vec1 = Flatten.vector(
      Flatten.point(points[0], points[1]),
      Flatten.point(points[2], points[3])
    );

    const vec2 = Flatten.vector(
      Flatten.point(points[4], points[5]),
      Flatten.point(points[2], points[3])
    );

    const angle = vec1.angleTo(vec2) * (180 / Math.PI);

    // Always take the smaller angle
    return new MeasuredValue(MeasurementPhysicalQuantity.Angle, {
      value: angle > 180 ? 360 - angle : angle,
      unit: MeasurementUnit.Degrees,
    });
  } catch {
    return MeasuredValue.Null;
  }
}

export function normalizePointsToPhysical(
  pts: number[],
  width: number,
  height: number,
  region: StudyClipRegion
): { x: number; y: number }[] {
  const result: { x: number; y: number }[] = [];

  // Calculate scales to reach the clip's physical units
  const xScale = width * region.physicalDeltaX;
  const yScale = height * region.physicalDeltaY;

  for (let i = 0; i < pts.length; i += 2) {
    result.push({ x: pts[i] * xScale, y: pts[i + 1] * yScale });
  }

  return result;
}

export function calculateEjectionFractionFromVolumes(edv: number, esv: number): number {
  if (esv === 0 || edv === 0) {
    return 0;
  }

  return (100 * (edv - esv)) / edv;
}
