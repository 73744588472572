<template>
  <Modal title="Download Clip as MP4" @header-button-click="closeModal">
    <div class="study-clip-download-modal">
      <img
        crossorigin="anonymous"
        :src="getStudyClipImageDataUrl(study, clip, StudyClipImageDataType.AnimatedThumbnail) ?? ''"
      />

      <button v-if="!isMp4Ready" disabled>Converting to MP4 … <LoadingIndicator /></button>

      <a
        v-else
        :href="getStudyClipImageDataUrl(study, clip, StudyClipImageDataType.MP4) ?? ''"
        :download="`HeartLab - ${clip.sopInstanceUid}.mp4`"
      >
        <button class="accented">Download MP4</button>
      </a>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import axios from "axios";
import { onMounted, onUnmounted, ref } from "vue";
import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Modal from "../components/Modal.vue";
import { addNotification } from "../utils/notifications.js";
import { Study, StudyClip } from "../utils/study-data";
import { getStudyClipImageDataUrl } from "./study-clip-image-data";

interface Props {
  study: Study;
  clip: StudyClip;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isMp4Ready = ref(false);
const abortConversion = new AbortController();

async function downloadStudyClip(): Promise<void> {
  try {
    await axios.get<Blob>(
      `/api/studies/${props.study.id}/clips/${props.clip.id}/convert-to-mp4`,
      { responseType: "blob", timeout: 0, signal: abortConversion.signal }
    );

    isMp4Ready.value = true;
  } catch {
    if (!abortConversion.signal.aborted) {
      addNotification({ type: "error", message: "Error creating the MP4 of this clip" });
    }

    closeModal();
  }
}

onMounted(downloadStudyClip);

function closeModal(): void {
  emits("close");
}

onUnmounted(() => abortConversion.abort());
</script>

<style scoped lang="scss">
.study-clip-download-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

img {
  border-radius: var(--border-radius);
}
</style>
