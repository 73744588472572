<template>
  <div class="top-line" :class="{ 'has-attached-files': study.attachedFiles.length > 0 }">
    <KeyPatientInfo :study="study" show-participants />

    <div class="docked-right">
      <div class="tool-buttons">
        <!-- This spacer is needed on secondary windows when there is no patient studies popper -->
        <div v-if="getWindowType() !== WindowType.Primary" style="width: 16px" />

        <Tooltip v-if="reportState !== null" placement="bottom">
          <template #content>
            <div style="display: flex; flex-direction: column; gap: 2px">
              <div v-for="row in reportState.tooltipMessage" :key="row">
                {{ row }}
              </div>
            </div>
          </template>

          <div class="report-status-pill">
            <FontAwesomeIcon
              class="report-status-icon"
              :icon="reportState.icon"
              :class="reportState.status"
              fixed-width
            />
            {{ reportState?.textDescription }}
          </div>
        </Tooltip>

        <IconButton
          v-if="currentTenant.isStudySharingEnabled && hasStudySharePermission"
          tooltip="Share this study"
          icon="share"
          size="lg"
          class="top-line-button highlighting share-icon"
          fixed-width
          data-testid="study-share-button"
          @click="isStudyShareModalVisible = true"
        />

        <PatientStudiesPopper
          v-if="getWindowType() === WindowType.Primary && !currentUser.roleNames.includes('Guest')"
          :patient-studies="patientStudies"
          :excluded-study-id="study.id"
          show-tooltip
          @open-comparison="(comparedStudyId) => emits('open-comparison', comparedStudyId)"
        >
          <template #default="{ isPopperOpen, isAvailable }">
            <div
              class="top-line-button"
              :class="{
                active: isAvailable && isPopperOpen,
                highlighting: isAvailable,
              }"
              data-testid="patient-studies"
              :data-test-patient-studies-count="patientStudies.length"
            >
              <FontAwesomeIcon icon="clock-rotate-left" fixed-width />
            </div>
          </template>
        </PatientStudiesPopper>

        <StudyUrgentFlag :study="study" />

        <StudyDropdownPopper :study="study" is-study-open @study-deleted="backToStudyList" />

        <StudyAttachFilesPopper :study="study">
          <template #default="{ isPopperOpen }">
            <div
              class="top-line-button highlighting"
              :class="{
                active: isPopperOpen,
              }"
            >
              <div class="attached-files-btn">
                <FontAwesomeIcon icon="paperclip" fixed-width />
                <FontAwesomeIcon v-if="study.attachedFiles.length === 0" icon="plus" fixed-width />
                <template v-else>
                  <span>{{ study.attachedFiles.length }}</span>
                  <FontAwesomeIcon style="margin-bottom: 2px" icon="caret-down" fixed-width />
                </template>
              </div>
            </div>
          </template>
        </StudyAttachFilesPopper>
      </div>

      <Popper
        placement="bottom-end"
        :offset-distance="2"
        :interactive="false"
        :disabled="study.reports.length > 0"
        @open="isReportTemplateSelectorDropdownOpen = true"
        @close="isReportTemplateSelectorDropdownOpen = false"
      >
        <button
          class="report-button accented"
          :class="{ selected: isReportPaneVisible, active: isReportTemplateSelectorDropdownOpen }"
          :disabled="
            study.reports.length === 0 &&
            !hasStudyReportPreliminaryCreatePermission &&
            !hasStudyReportPreliminaryCreateInitialPermission
          "
          data-testid="toggle-report"
          @click="emits('toggle-report')"
        >
          <FontAwesomeIcon icon="file-pen" />
          Report
        </button>

        <template #content>
          <ReportTemplateSelector
            v-if="hasStudyUpdatePermission"
            @select-template="(id) => emits('create-report', id)"
          />
        </template>
      </Popper>
    </div>
  </div>

  <StudyShareModal
    v-if="isStudyShareModalVisible"
    :study-id="study.id"
    @close="isStudyShareModalVisible = false"
  />
</template>

<script setup lang="ts">
import {
  hasStudyReportPreliminaryCreateInitialPermission,
  hasStudyReportPreliminaryCreatePermission,
  hasStudySharePermission,
  hasStudyUpdatePermission,
} from "@/auth/authorization";
import { currentTenant } from "@/auth/current-session";
import IconButton from "@/components/IconButton.vue";
import { getStudyReportStateDetails } from "@/reporting/report-status";
import { Study } from "@/utils/study-data";
import { useUserList } from "@/utils/users-list";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { PatientStudiesGetManyResponseDto } from "../../../backend/src/studies/dto/patient-studies-get-many.dto";
import { currentUser } from "../auth/current-session";
import Popper from "../components/Popper.vue";
import StudyDropdownPopper from "../components/StudyDropdownPopper.vue";
import StudyShareModal from "../components/StudyShareModal.vue";
import StudyUrgentFlag from "../components/StudyUrgentFlag.vue";
import Tooltip from "../components/Tooltip.vue";
import ReportTemplateSelector from "../reporting/ReportTemplateSelector.vue";
import router from "../router";
import KeyPatientInfo from "./KeyPatientInfo.vue";
import PatientStudiesPopper from "./PatientStudiesPopper.vue";
import StudyAttachFilesPopper from "./StudyAttachFilesPopper.vue";
import { getWindowType, WindowType } from "./multi-window/secondary-window";

interface Props {
  study: Study;
  patientStudies: PatientStudiesGetManyResponseDto;
  isReportPaneVisible: boolean;
}

interface Emits {
  (event: "open-comparison", comparedStudyId: string): void;

  (event: "toggle-report"): void;

  (event: "create-report", reportTemplateId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isStudyShareModalVisible = ref(false);
const isReportTemplateSelectorDropdownOpen = ref(false);

const { userList } = useUserList();

const reportState = computed(() => getStudyReportStateDetails(props.study.reports, userList.value));

async function backToStudyList(): Promise<void> {
  await router.push({ name: "study-list" });
}
</script>

<style scoped lang="scss">
@mixin flex-container($direction: row, $gap: 0, $align: stretch, $justify: flex-start) {
  display: flex;
  flex-direction: $direction;
  gap: $gap;
  align-items: $align;
  justify-content: $justify;
}

.top-line {
  @include flex-container(row, 12px, center, space-between);
  padding: 4px 8px;
  background-color: var(--bg-color-2);

  &.has-attached-files {
    grid-auto-columns: calc(100vw - 308px) 170px 100px;
  }

  .docked-right {
    @include flex-container($gap: 16px);
    display: flex;
    flex-direction: row;
    gap: 16px;

    .tool-buttons {
      @extend .docked-right;
      align-items: center;
      gap: 12px;
    }
  }

  .top-line-button {
    color: var(--accent-color-1);

    &.highlighting {
      cursor: pointer;
      transition: color 100ms ease;

      &:hover {
        color: var(--accent-color-2);
      }
    }

    &.active {
      color: var(--accent-color-2);
    }

    // For some reason the share icon renders lower than the rest of the icons
    &.share-icon {
      padding-bottom: 2px;
    }
  }

  .report-button {
    width: 100%;
    margin: 2px 0;
  }
}

.report-status-pill {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-color-4);
  color: var(--accent-color-2);
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  font-weight: bold;
}

.report-status-icon {
  height: 12px;

  &.preliminaryReportApproved {
    color: var(--accent-color-blue);
  }

  &.amendmentInProgress {
    color: var(--accent-color-purple);
  }

  &.reportFinalized {
    color: var(--accent-color-green);
  }

  &.reportInProgress {
    color: #797979;
  }
}

.attached-files-btn {
  height: 22px;
  display: flex;
  gap: 4px;
  padding: 4px 6px;
  align-items: center;
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);

  &:hover {
    border: 1px solid var(--accent-color-1);
  }
}
</style>
