<template>
  <NotForClinicalUseBanner />

  <RouterView />

  <NotificationList />
</template>

<script setup lang="ts">
import sysend from "sysend";
import NotForClinicalUseBanner from "../../components/NotForClinicalUseBanner.vue";
import NotificationList from "../../components/NotificationList.vue";
import { postMessageToPrimaryWindow, setPrimaryWindowId } from "./primary-window-messages";
import { onSecondaryWindowMessageReceived } from "./secondary-window-messages";

interface Props {
  primaryWindowId: string;
}

const props = defineProps<Props>();

setPrimaryWindowId(props.primaryWindowId);

postMessageToPrimaryWindow({
  type: "secondary-window-opened",
  secondaryWindowId: sysend.id,
});

onSecondaryWindowMessageReceived("close-window", () => window.close());
</script>
