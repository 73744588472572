<template>
  <Modal
    :title="mode === 'import' ? 'Import Sentence Groups' : 'Export Sentence Groups'"
    @header-button-click="emits('close')"
  >
    <div class="import-export-sentence-groups-modal">
      <span v-if="mode === 'export' || sentenceGroupList.length !== 0">
        Select sentence groups to {{ mode }}:
      </span>

      <div v-if="sentenceGroupList.length !== 0" class="sentence-group-list">
        <div
          v-for="(sentenceGroup, index) in sentenceGroupList"
          :key="sentenceGroup.id"
          class="sentence-group"
        >
          <Checkbox
            :model-value="selectedGroups[sentenceGroup.id]"
            :data-testid="`group-checkbox-${index}`"
            class="sentence-group-checkbox"
            @update:model-value="(newValue) => (selectedGroups[sentenceGroup.id] = newValue)"
          >
            {{ sentenceGroup.name }}

            <i v-if="mode === 'import' && doesGroupNameExist(sentenceGroup.name)">
              (Group name already exists)
            </i>
          </Checkbox>
        </div>
      </div>

      <div
        v-if="mode === 'import' && sentenceGroupList.length === 0"
        class="file-drop-area"
        :class="{ dragging: isDraggingOver }"
        @dragover="onDragOver"
        @drop="onFileDrop"
        @dragleave="onDragOff"
      >
        <FontAwesomeIcon icon="upload" size="2x" />
        <div>
          Drag and drop, or
          <br />
          <a style="text-decoration: underline" @click="fileInputElement?.click()">
            browse for a file
          </a>
        </div>

        <input
          ref="fileInputElement"
          type="file"
          accept=".json"
          hidden
          data-testid="upload-file"
          @change="onFileUpload"
        />
      </div>

      <div
        v-if="(mode === 'import' && sentenceGroupList.length !== 0) || mode === 'export'"
        class="bottom-row"
      >
        <a class="select-all-link" @click="setSelectedValues(!isAllSelected)">
          {{ isAllSelected ? "Deselect all" : "Select all" }}
        </a>

        <button
          class="import-export-button accented"
          :data-testid="mode === 'import' ? 'import-button' : 'export-button'"
          :disabled="!Object.values(selectedGroups).includes(true)"
          @click="onImportExportClick"
        >
          {{ mode === "import" ? "Import Selected" : "Download" }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Checkbox from "@/components/Checkbox.vue";
import Modal from "@/components/Modal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { saveAs } from "file-saver";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, reactive, ref } from "vue";
import { z } from "zod";
import { currentTenant } from "../auth/current-session";
import { addNotification } from "../utils/notifications";

interface SentenceGroupWithID {
  id: string;
  name: string;
  sentences: string[];
}

interface Props {
  mode: "export" | "import";
  currentSentenceGroups: SentenceGroupWithID[];
}

interface Emits {
  (event: "close"): void;
  (event: "import-group", name: string, sentences: string[]): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const parsedSentenceGroups = ref<SentenceGroupWithID[]>([]);

const sentenceGroupList = computed(() =>
  props.mode === "import" ? parsedSentenceGroups.value : props.currentSentenceGroups
);

function doesGroupNameExist(name: string): boolean {
  return props.currentSentenceGroups.some(
    (group) => group.name.toLowerCase() === name.toLowerCase()
  );
}

const selectedGroups = reactive<Record<string, boolean>>({});

const isAllSelected = computed(() => !Object.values(selectedGroups).includes(false));

setSelectedValues(true);

function setSelectedValues(selected: boolean): void {
  for (const group of sentenceGroupList.value) {
    selectedGroups[group.id] = selected;
  }
}

function onImportExportClick(): void {
  if (props.mode === "import") {
    importSelected();
  } else {
    downloadSelected();
  }
}

const sentenceLibraryFileSchema = z
  .strictObject({
    name: z.string(),
    sentences: z.string().array(),
  })
  .array()
  .min(1);

type SentenceLibraryFile = z.infer<typeof sentenceLibraryFileSchema>;

//
// Import
//

const isDraggingOver = ref(false);

function onDragOver(e: DragEvent): void {
  e.preventDefault();
  e.stopPropagation();
  isDraggingOver.value = true;
}

function onDragOff(e: DragEvent): void {
  e.preventDefault();
  e.stopPropagation();
  isDraggingOver.value = false;
}

async function onFileDrop(e: DragEvent): Promise<void> {
  onDragOff(e);

  const files = e.dataTransfer?.files;
  if (files && files[0] instanceof File) {
    await importFile(files[0]);
  }
}

const fileInputElement = ref<HTMLInputElement | null>(null);

async function onFileUpload(): Promise<void> {
  if (fileInputElement.value === null) {
    return;
  }

  const file = fileInputElement.value.files?.[0];
  if (!file) {
    return;
  }

  await importFile(file);
}

async function importFile(file: File): Promise<void> {
  if (fileInputElement.value === null) {
    return;
  }

  fileInputElement.value.value = "";

  // Read the selected file as text and then parse as JSON and check it's a valid sentence library
  let importedSentenceLibrary: SentenceLibraryFile | undefined = undefined;
  try {
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsText(file);
    });

    importedSentenceLibrary = sentenceLibraryFileSchema.parse(JSON.parse(reader.result as string));
  } catch {
    addNotification({
      type: "error",
      message: "This file doesn't contain sentence library content",
    });
    return;
  }

  parsedSentenceGroups.value = importedSentenceLibrary.map((group) => ({
    id: uuidv4(),
    name: group.name,
    sentences: group.sentences,
  }));

  // Remove any groups that already exist in the sentence library, as there's no potential reason to
  // import them
  parsedSentenceGroups.value = parsedSentenceGroups.value.filter(
    (group) =>
      !props.currentSentenceGroups.some(
        (existingGroup) =>
          existingGroup.name.toLowerCase() === group.name.toLowerCase() &&
          isEqual(
            existingGroup.sentences.map((s) => s.toLowerCase()),
            group.sentences.map((s) => s.toLowerCase())
          )
      )
  );

  if (parsedSentenceGroups.value.length === 0) {
    addNotification({ type: "info", message: "This file doesn't contain any new sentence groups" });
    return;
  }

  setSelectedValues(true);

  // Automatically deselect any groups from the file whose name is already in use in the sentence
  // library
  for (const group of sentenceGroupList.value) {
    if (doesGroupNameExist(group.name)) {
      selectedGroups[group.id] = false;
    }
  }
}

function importSelected(): void {
  for (const group of parsedSentenceGroups.value.filter((g) => selectedGroups[g.id])) {
    emits("import-group", group.name, group.sentences);
  }

  emits("close");
}

//
// Export
//

function downloadSelected(): void {
  // Only export group name and sentence text
  const exportedGroups: SentenceLibraryFile = sentenceGroupList.value
    .filter((group) => selectedGroups[group.id])
    .map((group) => ({ name: group.name, sentences: group.sentences }));

  const json = JSON.stringify(exportedGroups, null, 2);
  const utf8 = new TextEncoder().encode(json);

  saveAs(new Blob([utf8]), `HeartLab - ${currentTenant.name}.sentence-library.json`);

  emits("close");
}
</script>

<style scoped lang="scss">
.import-export-sentence-groups-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 450px;
}

.sentence-group {
  display: flex;
}

.sentence-group-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
}

.file-drop-area {
  padding: 64px 0;
  transition: background-color 100ms ease;
  border: 1px dashed var(--accent-color-1);
  border-radius: var(--border-radius);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.dragging {
  background-color: var(--bg-color-1);
}

.bottom-row {
  display: grid;
  grid-template-areas: "main";
}

.select-all-link {
  grid-area: main;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9em;
}

.import-export-button {
  grid-area: main;
  place-self: center;
}
</style>
