<template>
  <div
    class="grid-table-row audit-log-row selectable-text"
    :class="{ expanded: isExpanded && details }"
    data-testid="audit-log-row"
    @click="isExpanded = !isExpanded"
  >
    <div class="expansion-arrow">
      <FontAwesomeIcon v-if="details" :icon="isExpanded ? 'chevron-down' : 'chevron-right'" />
    </div>
    <div v-if="isTenantColumnVisible">{{ auditLogEvent.tenantName }}</div>
    <div data-testid="audit-log-timestamp">{{ timestamp }}</div>
    <div>
      <span class="capitalize">{{ actionDescription }}</span>
      <FontAwesomeIcon
        v-if="isAuditActionError(auditLogEvent.action)"
        icon="triangle-exclamation"
        style="color: red; margin-left: 16px"
      />
    </div>
    <div class="ellipsis-on-overflow">{{ auditLogEvent.user }}</div>
    <template v-if="!isStudyView">
      <div class="ellipsis-on-overflow">{{ auditLogEvent.patientId }}</div>
      <div>
        <a
          v-if="auditLogEvent.studyId !== null"
          :href="`/study-view/${auditLogEvent.studyId}`"
          target="_blank"
          style="white-space: nowrap"
          @click.stop
        >
          Go to study
        </a>
      </div>

      <div>
        {{ auditLogEvent.authMethod ? getAuthMethodDisplayName(auditLogEvent.authMethod) : "" }}
      </div>
    </template>
    <code style="white-space: nowrap">{{ auditLogEvent.sourceIp }}</code>
    <code style="white-space: nowrap">{{ auditLogEvent.sessionId }}</code>
  </div>
  <div
    v-if="isExpanded && details"
    class="details-container selectable-text"
    data-testid="audit-log-row-details"
  >
    <div>
      <template v-for="(line, index) in details" :key="index">
        <pre>{{ line }}</pre>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getAuditLogActionDescription } from "@/audit-logs/audit-action-descriptions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { stringify } from "yaml";
import { AuditAction, isAuditActionError } from "../../../backend/src/audit/audit-actions";
import { getAuthMethodDisplayName } from "../../../backend/src/auth/auth-method";
import type { AuditLogEventGetManyResponseDto } from "../../../backend/src/tenants/dto/audit-log-event-get-many.dto";
import { formatDateTime } from "../utils/date-time-utils";

interface Props {
  auditLogEvent: AuditLogEventGetManyResponseDto[0];
  isTenantColumnVisible: boolean;
  isStudyView: boolean;
}

const props = defineProps<Props>();

const isExpanded = ref(false);

const detailsColumnCount = computed(() => {
  if (props.isStudyView) {
    return 6;
  }

  return 9 + (props.isTenantColumnVisible ? 1 : 0);
});

const actionDescription = computed(() => {
  let description = getAuditLogActionDescription(props.auditLogEvent.action);

  if (props.auditLogEvent.action === AuditAction.DicomMessageReceived) {
    description += ` (${props.auditLogEvent.details?.dimseMessage as string})`;
  }

  if (props.auditLogEvent.action === AuditAction.StudyFileUploaded) {
    const source = props.auditLogEvent.details?.source;
    if (typeof source === "string") {
      description += ` (${source})`;
    }
  }

  return description;
});

const timestamp = computed(() =>
  formatDateTime(props.auditLogEvent.timestamp.toString(), {
    includeTime: true,
    includeSeconds: true,
  })
);

const details = computed(() => {
  if (Object.entries(props.auditLogEvent.details ?? {}).length === 0) {
    return undefined;
  }

  return stringify(props.auditLogEvent.details ?? {}, null, 2).split("\n");
});
</script>

<style scoped lang="scss">
.audit-log-row {
  &.expanded {
    > * {
      background-color: var(--bg-color-4);
    }

    > :first-child {
      border-bottom-left-radius: 0;
    }
    > :last-child {
      border-bottom-right-radius: 0;
    }
  }

  &:hover {
    .expansion-arrow {
      color: var(--accent-color-2);
    }
  }
}

.expansion-arrow {
  display: grid;
  place-content: center;
  color: var(--accent-color-1);
}

a {
  text-decoration: underline;
}

.details-container {
  background-color: var(--bg-color-2);
  display: flex;
  height: max-content;
  grid-column: v-bind("`span ${detailsColumnCount}`");
  padding: 8px 64px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  line-height: 1.4em;
}

.ellipsis-on-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

pre {
  padding: 0;
  margin: 0;
  cursor: text;
}
</style>
