import Flatten from "@flatten-js/core";
import { z } from "zod";
import type {
  StudyGetOneResponseDto,
  StudyMeasurementValueResponseDto,
} from "../studies/dto/study-get-one.dto";
import { getClips } from "../studies/study-helpers";
import { findRegionForContour } from "./measurement-helpers";
import { normalizePointsToPhysical } from "./measurement-tool-evaluation";
import {
  getDisksForVolume,
  translateAndRotateAxis,
} from "./measurement-tool-evaluation-volume-helpers";

/*
 * The expected input for the biplane volume calculation formula. The `a2c` and `a4c` properties
 * are the IDs of the measurement values that are used to calculate the volume. These are accessed
 * inside a calculation through the `inputs.*` field.
 */
const biplaneVolumeArgsSchema = z.strictObject({
  a2c: z.string().nullable(),
  a4c: z.string().nullable(),
});

type BiplaneVolumeArgs = z.infer<typeof biplaneVolumeArgsSchema>;

/**
 * Returns the A2C & A4C measurement values for use in biplane calculation from the given study
 * and calculation inputs.
 */
function getBiplaneVolumesFromCalculationArgs(
  study: StudyGetOneResponseDto,
  args: BiplaneVolumeArgs
): {
  a2c: StudyMeasurementValueResponseDto | undefined;
  a4c: StudyMeasurementValueResponseDto | undefined;
} {
  const allMeasurementValues = study.measurements.flatMap((m) => m.values);

  return {
    a2c: allMeasurementValues.find((m) => m.id === args.a2c),
    a4c: allMeasurementValues.find((m) => m.id === args.a4c),
  };
}

/**
 * Returns the contour for the given measurement value normalized to physical units.
 */
function getNormalizedContourForMeasurementValue(
  study: StudyGetOneResponseDto,
  measurement: StudyMeasurementValueResponseDto
): { x: number; y: number }[] | undefined {
  const clip = getClips(study).find((c) => c.id === measurement.studyClipId);
  if (!clip || clip.height === null || clip.width === null || measurement.contour === null) {
    return undefined;
  }

  const region = findRegionForContour(clip, clip.regions, measurement.contour);
  if (!region) {
    return undefined;
  }

  return normalizePointsToPhysical(measurement.contour, clip.width, clip.height, region);
}

/**
 * Calculates biplane volume from the given study and input A2C & A4C measurements using the method
 * of disks adjusted to handle both volumes. The shortest volume measurement is stretched in the
 * direction of the major axis such that the axis lengths are the same before volume is calculated.
 * According to ASE guidelines, this is only valid when the difference between axis length is <20%.
 */
export function calculateBiplaneVolume(
  study: StudyGetOneResponseDto,
  args: unknown
): number | undefined {
  const parsedArgs = biplaneVolumeArgsSchema.safeParse(args);
  if (!parsedArgs.success) {
    throw new Error("Invalid arguments passed to calculateBiplaneVolume");
  }

  const { a2c, a4c } = getBiplaneVolumesFromCalculationArgs(study, parsedArgs.data);

  if (a2c === undefined || a4c === undefined) {
    return undefined;
  }

  const normalizedA2CContour = getNormalizedContourForMeasurementValue(study, a2c);
  const normalizedA4CContour = getNormalizedContourForMeasurementValue(study, a4c);

  if (normalizedA2CContour === undefined || normalizedA4CContour === undefined) {
    return undefined;
  }

  const { rotatedAxis: rotatedA2CAxis, rotatedPoints: rotatedA2CPoints } = translateAndRotateAxis(
    normalizedA2CContour.slice(0, -2),
    normalizedA2CContour.slice(-2)
  );
  const { rotatedAxis: rotatedA4CAxis, rotatedPoints: rotatedA4CPoints } = translateAndRotateAxis(
    normalizedA4CContour.slice(0, -2),
    normalizedA4CContour.slice(-2)
  );

  const a2cAxisLength = getSegmentLength(rotatedA2CAxis[0], rotatedA2CAxis[1]);
  const a4cAxisLength = getSegmentLength(rotatedA4CAxis[0], rotatedA4CAxis[1]);

  // Stretch the shortest axis to match the length of the largest axis, which allows for each disk
  // to match up exactly against the other volume.
  const isA2CShorter = a2cAxisLength < a4cAxisLength;
  const shorterPoints = isA2CShorter ? rotatedA2CPoints : rotatedA4CPoints;
  const shorterAxis = isA2CShorter ? rotatedA2CAxis : rotatedA4CAxis;

  const scaleFactor = isA2CShorter ? a4cAxisLength / a2cAxisLength : a2cAxisLength / a4cAxisLength;

  shorterPoints.forEach((p) => (p.x *= scaleFactor));
  shorterAxis.forEach((p) => (p.x *= scaleFactor));

  const diskSize = (isA2CShorter ? a4cAxisLength : a2cAxisLength) / 100;

  const a2cDisks = getDisksForVolume(rotatedA2CPoints, rotatedA2CAxis, 100);
  const a4cDisks = getDisksForVolume(rotatedA4CPoints, rotatedA4CAxis, 100);

  let volume = 0;
  for (const [a2cDisk, a4cDisk] of a2cDisks.map((d, i) => [d, a4cDisks[i]])) {
    const a2cRadius = (a2cDisk.right - a2cDisk.left) / 2;
    const a4cRadius = (a4cDisk.right - a4cDisk.left) / 2;
    const diskVolume = Math.PI * a2cRadius * a4cRadius * diskSize;
    volume += diskVolume;
  }

  return volume;
}

export function getBiplaneVolumeCalculationErrors(
  study: StudyGetOneResponseDto,
  args: unknown
): string | undefined {
  const parsedArgs = biplaneVolumeArgsSchema.safeParse(args);

  if (!parsedArgs.success) {
    throw new Error("Invalid arguments passed to calculateBiplaneVolume");
  }

  const { a2c, a4c } = getBiplaneVolumesFromCalculationArgs(study, parsedArgs.data);

  if (a2c === undefined || a4c === undefined) {
    return `One or both of the measurements are not present`;
  }

  const normalizedA2CContour = getNormalizedContourForMeasurementValue(study, a2c);
  const normalizedA4CContour = getNormalizedContourForMeasurementValue(study, a4c);

  if (normalizedA2CContour === undefined || normalizedA4CContour === undefined) {
    return `Cannot normalise contours against their study clips to physical units`;
  }

  const a2cAxisLength = getSegmentLength(
    normalizedA2CContour[normalizedA2CContour.length - 2],
    normalizedA2CContour[normalizedA2CContour.length - 1]
  );
  const a4cAxisLength = getSegmentLength(
    normalizedA4CContour[normalizedA4CContour.length - 2],
    normalizedA4CContour[normalizedA4CContour.length - 1]
  );

  if (a2cAxisLength / a4cAxisLength < 0.8 || a2cAxisLength / a4cAxisLength > 1.2) {
    return `Biplane volume cannot be performed on measurements with axis lengths that differ by more than 20%`;
  }

  return undefined;
}

function getSegmentLength(p1: { x: number; y: number }, p2: { x: number; y: number }): number {
  return Flatten.segment(Flatten.point(p1.x, p1.y), Flatten.point(p2.x, p2.y)).length;
}
