import { ThemeProperty, type Theme } from "./theme";

/** The default charcoal theme used in the application. */
export const charcoalTheme: Theme = {
  name: "Charcoal",
  values: {
    [ThemeProperty.BorderRadius]: "4px",

    [ThemeProperty.BackgroundColor1]: "#0b0b0b",
    [ThemeProperty.BackgroundColor2]: "#1a1a1a",
    [ThemeProperty.BackgroundColor3]: "#282828",
    [ThemeProperty.BackgroundColor4]: "#3a3a3a",

    [ThemeProperty.BorderColor1]: "#404040",

    [ThemeProperty.ConfirmColor1]: "#00443b",
    [ThemeProperty.ConfirmColor2]: "#00be63",

    [ThemeProperty.TextColor1]: "#dddddd",
    [ThemeProperty.TextColor2]: "#ffffff",
    [ThemeProperty.TextColor3]: "#f1f2f2",

    [ThemeProperty.AccentColor1]: "#b8b8b8",
    [ThemeProperty.AccentColor2]: "#ffffff",
    [ThemeProperty.AccentColorHot]: "#ff0d64",

    [ThemeProperty.AccentColorBlue]: "#2661ff",
    [ThemeProperty.AccentBackgroundColorBlue]: "#071435",
    [ThemeProperty.AccentBackgroundColorBlueHover]: "#0d225c",

    [ThemeProperty.AccentColorGreen]: "#00aa58",
    [ThemeProperty.AccentBackgroundColorGreen]: "#04160d",
    [ThemeProperty.AccentBackgroundColorGreenHover]: "#093520",

    [ThemeProperty.AccentColorPurple]: "#a85df2",
    [ThemeProperty.AccentBackgroundColorPurple]: "#12071d",
    [ThemeProperty.AccentBackgroundColorPurpleHover]: "#340e5a",

    [ThemeProperty.InputFocusBorderColor]: "#777777",

    [ThemeProperty.ButtonAccentedBackgroundColor]: "#2661ff",
    [ThemeProperty.ButtonAccentedActiveColor]: "#4779ff",
    [ThemeProperty.ButtonAccentedDisabledColor]: "#2d438d",

    [ThemeProperty.ClipListSelectedClipBorderColor]: "#4779ff",

    [ThemeProperty.NotificationBorderRadius]: "4px",
    [ThemeProperty.NotificationTextColor]: "#ffffff",
    [ThemeProperty.NotificationIconColor]: "#ffffff",
    [ThemeProperty.NotificationInfoBackgroundColor]: "#018545",
    [ThemeProperty.NotificationInfoIconBackgroundColor]: "#00aa58",
    [ThemeProperty.NotificationInfoCloseIconColor]: "#ffffff",
    [ThemeProperty.NotificationErrorBackgroundColor]: "#881910",
    [ThemeProperty.NotificationErrorIconBackgroundColor]: "#dd0000",
    [ThemeProperty.NotificationErrorCloseIconColor]: "#ffffff",

    [ThemeProperty.ReportContainerBackgroundColor]: "rgb(82, 86, 89)",
    [ThemeProperty.ReportBoxBackgroundColor]: "#f4f4f4",
    [ThemeProperty.ReportBoxBorderColor]: "#808080",
    [ThemeProperty.ReportTableGridlineColor]: "#222222",
    [ThemeProperty.ReportTableHeaderCellBackgroundColor]: "#f4f4f4",
    [ThemeProperty.ReportSectionBackgroundColor]: "#f4f4f4",
    [ThemeProperty.ReportWidgetBackgroundColor]: "#e8e8e8",
    [ThemeProperty.ReportWidgetBackgroundColorHover]: "#d8d8d8",
    [ThemeProperty.ReportWidgetBorderColor]: "#d0d0d0",
    [ThemeProperty.ReportPlaceholderTextColor]: "#999999",
    [ThemeProperty.ReportPlaceholderTextColorFocused]: "#888888",
    [ThemeProperty.ReportAccentColor1]: "#666666",
    [ThemeProperty.ReportAccentColor2]: "#444444",
    [ThemeProperty.ReportTextColor1]: "#444444",
    [ThemeProperty.ReportTextColor2]: "#000000",
    [ThemeProperty.ReportPopperBackgroundColor]: "#eeeeee",
    [ThemeProperty.ReportCheckboxCheckedColor]: "#15bc05",
    [ThemeProperty.ReportCheckboxUncheckedColor]: "#81beff",
    [ThemeProperty.ReportCheckboxCheckedBackgroundColor]: "#caf2cb",
    [ThemeProperty.ReportWidgetOutlineColorFocused]: "#87ceeb",

    [ThemeProperty.ScrollbarTrackColor]: "#121212",
    [ThemeProperty.ScrollbarThumbColor]: "#808080",
    [ThemeProperty.ScrollbarHoverColor]: "#bbbbbb",

    [ThemeProperty.RwmaAkineticColor]: "#ff3b3b",
    [ThemeProperty.RwmaAneurysmalColor]: "#00ecec",
    [ThemeProperty.RwmaDyskineticColor]: "#c6a4ff",
    [ThemeProperty.RwmaHypokineticColor]: "#ffe144",
    [ThemeProperty.RwmaNormalColor]: "#64f056",
    [ThemeProperty.RwmaNotWellSeenColor]: "#e4e4e4",
    [ThemeProperty.RwmaBloodPoolColor]: "#dbdff7",
  },
};
