<template>
  <div class="measurement-pane" data-testid="measurement-pane">
    <div class="top-bar">
      <FilterInput
        :model-value="searchTerm"
        placeholder="Search measurements"
        :show-border="false"
        blur-on-enter-press
        style="border-bottom: 1px solid var(--border-color-1); flex: 1"
        data-testid="measurement-pane-search-input"
        @update:model-value="emits('update:searchTerm', $event)"
      />

      <Popper
        v-if="isStudyUpdatePermitted(study)"
        ref="addCustomMeasurementCardPopper"
        :offset-distance="-1"
        :offset-distance-cross-axis="-4"
        placement="right-start"
        class="add-custom-measurement-card-popper"
        @open="isAddCustomMeasurementCardVisible = true"
        @close="isAddCustomMeasurementCardVisible = false"
      >
        <Tooltip content="Create custom measurement" :visible="!isAddCustomMeasurementCardVisible">
          <div
            class="add-custom-measurement-button"
            :class="{ active: isAddCustomMeasurementCardVisible }"
            data-testid="add-custom-measurement-button"
          >
            <FontAwesomeIcon icon="plus" />
          </div>
        </Tooltip>

        <template #content>
          <AddCustomMeasurementCard
            :study="study"
            @close="addCustomMeasurementCardPopper?.close()"
          />
        </template>
      </Popper>
    </div>
    <div v-if="expanded" class="measurement-content">
      <OverlayScrollbar
        :style="{ flex: study.measurements.length + 1 }"
        :scroll-target="cardToScrollTo"
      >
        <div class="measurement-cards os-host-flexbox" data-testid="measurements-list">
          <template v-for="measurement in filteredMeasurements" :key="measurement.id">
            <MeasurementCard
              :id="`measurement-card-${measurement.id}`"
              v-model:is-expanded="isMeasurementCardExpanded[measurement.id]"
              :highlighted-measurement-id="highlightedMeasurementId"
              :hovered-measurement-value-id="hoveredMeasurementValueId"
              :study="study"
              :measurement="measurement"
              :reference-range-set-name="referenceRangeSetName"
              :patient-metrics="patientMetrics"
              :visible-frames="visibleFrames"
              @jump-to-value="(value) => emits('jump-to-measurement-value', value)"
            />
          </template>
        </div>
      </OverlayScrollbar>

      <template v-if="visibleClipMeasurements.length">
        <div
          class="measurements-divider"
          @click="isMeasurementsOnSelectedClipVisible = !isMeasurementsOnSelectedClipVisible"
        >
          Measurements on Selected Clip<template v-if="selectedClipIds.length !== 1">s</template>
          <FontAwesomeIcon
            :icon="isMeasurementsOnSelectedClipVisible ? 'chevron-down' : 'chevron-up'"
          />
        </div>

        <OverlayScrollbar
          v-if="isMeasurementsOnSelectedClipVisible"
          :style="{ flex: visibleClipMeasurements.length, minHeight: '160px' }"
          :scroll-target="clipCardToScrollTo"
        >
          <div class="measurement-cards os-host-flexbox">
            <template v-for="measurement in visibleClipMeasurements" :key="measurement.id">
              <MeasurementCard
                :id="`selected-clip-measurement-card-${measurement.id}`"
                v-model:is-expanded="isMeasurementCardExpanded[measurement.id]"
                :highlighted-measurement-id="highlightedMeasurementId"
                :hovered-measurement-value-id="hoveredMeasurementValueId"
                :study="study"
                :measurement="measurement"
                :reference-range-set-name="referenceRangeSetName"
                :patient-metrics="patientMetrics"
                :visible-frames="visibleFrames"
                @jump-to-value="(value) => emits('jump-to-measurement-value', value)"
              />
            </template>
          </div>
        </OverlayScrollbar>
      </template>

      <ActiveMeasurementCalculation
        v-if="activeMeasurementCalculation"
        :study="study"
        :calculation="activeMeasurementCalculation"
        :top-shadow="visibleClipMeasurements.length === 0 || isMeasurementsOnSelectedClipVisible"
        @jump-to-value="(value) => emits('jump-to-measurement-value', value)"
        @scroll-to-measurement="emits('scroll-to-measurement', $event)"
        @highlight-measurement-card="emits('highlight-measurement-card', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import AddCustomMeasurementCard from "@/measurements/AddCustomMeasurementCard.vue";
import MeasurementCard from "@/measurements/MeasurementCard.vue";
import { ReferenceRangeSetName } from "@/reference-ranges/reference-range-sets";
import { Study, StudyMeasurement, StudyMeasurementValue } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStorage } from "@vueuse/core";
import { computed, ref, watch, watchEffect, type Ref } from "vue";
import {
  getMeasurementDisplayName,
  type PatientMetrics,
} from "../../../backend/src/measurements/measurement-display";
import { isStudyUpdatePermitted } from "../auth/authorization";
import FilterInput from "../components/FilterInput.vue";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import Tooltip from "../components/Tooltip.vue";
import ActiveMeasurementCalculation from "./calculations/ActiveMeasurementCalculation.vue";
import { activeMeasurementCalculation } from "./calculations/measurement-calculations";
import { getMatchingMeasurements } from "./measurement-search";

interface Props {
  study: Study;
  searchTerm: string;
  referenceRangeSetName: ReferenceRangeSetName;
  patientMetrics: PatientMetrics | undefined;
  selectedClipIds: string[];
  measurementIdToScrollTo: string | null;
  measurementIdsToExpand: string[] | undefined;
  visibleFrames: {
    studyClipId: string;
    frame: number;
    soloMeasurementValueId: string | undefined;
  }[];
  highlightedMeasurementId: string | null;
  hoveredMeasurementValueId: string | null;
}

interface Emits {
  (event: "jump-to-measurement-value", measurementValue: StudyMeasurementValue): void;
  (event: "scroll-to-measurement", measurementId: string): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "update:searchTerm", newValue: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const expanded = useStorage("measurement-pane-expanded", true);

const addCustomMeasurementCardPopper = ref<{ close(): void } | null>(null);

const isAddCustomMeasurementCardVisible = ref(false);

const sortedMeasurements = computed(() =>
  [...props.study.measurements].sort((a, b) =>
    getMeasurementDisplayName(a, "unindexed").localeCompare(
      getMeasurementDisplayName(b, "unindexed")
    )
  )
);

const filteredMeasurements = computed(() =>
  getMatchingMeasurements(sortedMeasurements.value, props.searchTerm, props.patientMetrics)
);

const isMeasurementsOnSelectedClipVisible = useStorage("measurement-pane-selected-clips", false);

const cardToScrollTo = ref<HTMLElement | null>(null);
const clipCardToScrollTo = ref<HTMLElement | null>(null);

const visibleClipMeasurements = computed((): StudyMeasurement[] => {
  return props.study.measurements
    .filter((measurement) =>
      measurement.values.some(
        (value) => value.studyClipId !== null && props.selectedClipIds.includes(value.studyClipId)
      )
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

watch(
  () => props.measurementIdToScrollTo,
  () => {
    if (props.measurementIdToScrollTo === null) {
      cardToScrollTo.value = null;
      clipCardToScrollTo.value = null;
      return;
    }

    cardToScrollTo.value = document.getElementById(
      `measurement-card-${props.measurementIdToScrollTo}`
    );
    clipCardToScrollTo.value = document.getElementById(
      `selected-clip-measurement-card-${props.measurementIdToScrollTo}`
    );

    isMeasurementCardExpanded.value[props.measurementIdToScrollTo] = true;
  }
);

const isMeasurementCardExpanded: Ref<Record<string, boolean>> = ref({});

// Ensure there are explicit values for each measurement in isMeasurementCardExpanded
watchEffect(() => {
  for (const measurement of props.study.measurements) {
    if (measurement.id in isMeasurementCardExpanded.value) {
      continue;
    }

    isMeasurementCardExpanded.value[measurement.id] = false;
  }
});

// When the set of measurement ids to expand changes, ensure that only those measurement cards are
// expanded
watch(
  () => props.measurementIdsToExpand,
  () => {
    if (props.measurementIdsToExpand === undefined) {
      return;
    }

    for (const measurement of props.study.measurements) {
      isMeasurementCardExpanded.value[measurement.id] = props.measurementIdsToExpand.includes(
        measurement.id
      );
    }

    // Close the 'measurements on selected clips' section when expanding a set of measurement cards
    isMeasurementsOnSelectedClipVisible.value = false;
  }
);
</script>

<style scoped lang="scss">
.measurement-pane {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--border-color-1);
}

.top-bar {
  display: flex;

  .add-custom-measurement-button {
    height: 32px;
    width: 32px;
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: var(--bg-color-2);
    transition:
      color 100ms ease,
      background-color 100ms ease;

    &.active,
    &:hover {
      background-color: var(--bg-color-4);
      color: var(--text-color-2);
    }
  }
}

:deep(.add-custom-measurement-card-popper) {
  padding: 0;
}

.measurement-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 300px;
}

.measurement-alerts {
  padding: 0 4px 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.measurement-cards {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.measurements-divider {
  background-color: var(--bg-color-3);
  padding: 0 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  border-top: 1px solid var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);

  // Put a shadow on the top edge of the divider to better separate it visually from the
  // scrolling content above
  box-shadow: 0 0 8px 4px rgba(black, 0.5);
  clip-path: inset(-15px 0 0 0);
  z-index: 1;

  svg {
    color: var(--accent-color-1);
    transition: color 100ms ease;
  }

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-3);

    svg {
      color: var(--text-color-2);
    }
  }
}
</style>
