<template>
  <Modal title="Setup MFA" :activity-text="activityText" @header-button-click="emits('close')">
    <div class="totp-setup-modal">
      <div class="description">
        Scan this QR Code with an authenticator app such as Google Authenticator or Authy, then
        enter a verification code below.
      </div>

      <img class="qr-code" data-testid="totp-qr-code-image" :src="qrCodeDataUri" />

      <div class="token-field">
        <b>Enter Code</b>
        <input v-model="token" data-testid="totp-input" @keydown.enter="verifyCode" />
      </div>

      <button
        class="accented"
        style="margin: 0 auto; padding: 0 32px"
        :disabled="!isCodeValid"
        data-testid="totp-verify-code-button"
        @click="verifyCode"
      >
        Setup MFA
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { CurrentUserMfaTotpStartResponseDto } from "../../../backend/src/user/dto/current-user-mfa-totp-start.dto";
import Modal from "../components/Modal.vue";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { currentUser } from "./current-session";

interface Emits {
  (event: "close"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const qrCodeDataUri = ref("");

const token = ref("");

const isCodeValid = computed(() => token.value.length === 6);

async function fetchQrCode(): Promise<void> {
  let response: AxiosResponse<CurrentUserMfaTotpStartResponseDto> | undefined = undefined;

  activityText.value = "Loading QR code";

  try {
    response = await axios.post<CurrentUserMfaTotpStartResponseDto>(`/api/user/current/mfa/totp`);
  } catch {
    addNotification({ type: "error", message: "Failed loading QR code" });
    emits("close");
    return;
  }

  qrCodeDataUri.value = response.data.qrCodeDataUri;
  activityText.value = "";
}

onMounted(fetchQrCode);

async function verifyCode(): Promise<void> {
  if (!isCodeValid.value) {
    return;
  }

  activityText.value = "Verifying code";

  try {
    await axios.post(`/api/user/current/mfa/totp-verify`, { token: token.value });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed verifying code",
    });
    return;
  } finally {
    activityText.value = "";
  }

  currentUser.mfaEnabled = true;

  addNotification({ type: "info", message: "MFA enabled" });
  emits("close");
}
</script>

<style scoped lang="scss">
.totp-setup-modal {
  display: flex;
  flex-direction: column;
  width: 320px;
  gap: 24px;
}

.qr-code {
  width: 75%;
  margin: 0 auto;
  aspect-ratio: 1;
}

.token-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
</style>
