import { useStorage } from "@vueuse/core";
import { getWindowType, WindowType } from "./multi-window/secondary-window";

export const primaryClipsLayout = useStorage(
  getWindowType() === WindowType.SerialStudy
    ? "serial-study-window-clips-layout"
    : "primary-window-clips-layout",
  { width: 1, height: 1 }
);

export const extraClipsWindowClipsLayout = useStorage("extra-clips-window-clips-layout", {
  width: 2,
  height: 2,
});
