<template>
  <div style="display: grid; gap: 0.8em">
    <div
      v-if="showFields && columns.flat().length !== 0"
      :class="{
        [`rpt-section-${section.type}`]: true,
        'formatting-enabled': section.isFormattingEnabled,
      }"
      :style="`--rpt-table-column-count: ${finalFields.length}`"
    >
      <div
        v-for="(column, columnIndex) of columns"
        :key="columnIndex"
        :class="{ 'rpt-section-fields-column': section.type === 'fields' }"
      >
        <template v-for="(field, fieldIndex) of column" :key="fieldIndex">
          <!-- Put background on table cells when they are static and uneditable text -->
          <div
            v-if="section.type === 'table' && isStaticTextField(field)"
            class="field-static-text"
            :style="getFieldGridArea(fieldIndex, columnIndex)"
          />

          <div
            class="rpt-text-normal"
            :style="{
              ...getFieldGridArea(fieldIndex, columnIndex),
              ...getFieldAlignmentCSS(section, field),
            }"
          >
            <b v-if="section.type !== 'table'">{{ field.name }}:</b>
            <span :style="getFieldStylingCSS(field)"> {{ ` ${field.value}` }} </span>
          </div>
        </template>
      </div>

      <ReportTableGridlines
        v-if="section.type === 'table'"
        :row-count="rowCount"
        :column-count="columns.length"
      />
    </div>

    <div v-if="finalReportSectionText" class="rpt-text-normal" style="word-break: break-word">
      <TextWithLineBreaks
        :text="finalReportSectionText"
        :data-testid="`rpt-section-final-text-${section.name}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { Study, StudyReport } from "../utils/study-data";
import ReportTableGridlines from "./ReportTableGridlines.vue";
import TextWithLineBreaks from "./TextWithLineBreaks.vue";
import { CalculationScope } from "./report-calculation-scope";
import {
  getFieldAlignmentCSS,
  getFieldStylingCSS,
  getFinalReportSectionFields,
  getFinalReportSectionText,
  isStaticTextField,
  type FinalReportSectionField,
} from "./report-content";

interface Props {
  study: Study;
  report: StudyReport;
  calculationScope: CalculationScope;
  sectionId: string;
  showFields: boolean;
}

const props = defineProps<Props>();

const section = computed(() => {
  const result = props.report.reportTemplateVersion.structure.sections.find(
    (s) => s.id === props.sectionId
  );

  if (result === undefined) {
    throw Error(`Section with id ${props.sectionId} is not present in the report template`);
  }

  return result;
});

const finalReportSectionText = computed(() =>
  getFinalReportSectionText(props.report, props.sectionId, props.calculationScope)
);

const finalFields = computed(() =>
  getFinalReportSectionFields(props.study, props.report, props.calculationScope, props.sectionId)
);

const rowCount = computed(() => Math.max(...columns.value.map((column) => column.length)));

const columns = computed(() =>
  section.value.isFieldCompactionEnabled ? compactFields(finalFields.value) : finalFields.value
);

function compactFields(fields: FinalReportSectionField[][]): FinalReportSectionField[][] {
  const columnCount = fields.length;

  const flatFields = fields.flat();
  const columnHeight = Math.floor((flatFields.length + columnCount - 1) / columnCount);

  const result = [];
  for (let i = 0; i < columnCount; i++) {
    result.push(flatFields.slice(i * columnHeight, (i + 1) * columnHeight));
  }

  return result;
}

function getFieldGridArea(fieldIndex: number, columnIndex: number): { gridArea: string } {
  return {
    gridArea: `${fieldIndex + 1} / ${columnIndex + 1} / ${fieldIndex + 2} / ${columnIndex + 2}`,
  };
}
</script>
