import App from "@/App.vue";
import "@/assets/global.scss";
import router from "@/router";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { debounce } from "lodash";
import "normalize-scss";
import { createPinia } from "pinia";
import "v-calendar/dist/style.css";
import { createApp } from "vue";
import { setupDatadogRealUserMonitoring } from "./datadog-real-user-monitoring";
import { setupIcons } from "./icons";
import { addRoutesToRouter } from "./routes";

// Debounce the ResizeObserver to avoid "ResizeObserver completed with undelivered notifications"
// errors, which prevents excessive calls to the callback and can cause the error.
// See https://github.com/vuejs/vue-cli/issues/7431
window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
  constructor(callback: ResizeObserverCallback) {
    super(debounce(callback, 10));
  }
};

setupIcons();

addRoutesToRouter(router);

router.beforeEach((_to, _from, next) => {
  // Comment out this block if connecting to dev from another device (e.g. mobile or a VM)
  if (import.meta.env.DEV) {
    if (window.location.hostname !== "localhost") {
      const url = new URL(window.location.href);
      url.hostname = "localhost";

      window.location.href = url.toString();
    }
  }

  // Temporary redirect for legacy subdomains
  // Only run this if the heartlab variable is set by the backend
  if (window.heartlab) {
    const url = new URL(window.location.href);

    if (
      window.heartlab.environmentName === "production-au" &&
      window.location.hostname !== "anz.heartlab.com"
    ) {
      url.hostname = "anz.heartlab.com";
      window.location.href = url.toString();
    }

    if (
      window.heartlab.environmentName === "production-usa" &&
      window.location.hostname !== "usa.heartlab.com"
    ) {
      url.hostname = "usa.heartlab.com";
      window.location.href = url.toString();
    }

    if (
      window.heartlab.environmentName === "staging" &&
      window.location.hostname !== "staging.heartlab.com"
    ) {
      url.hostname = "staging.heartlab.com";
      window.location.href = url.toString();
    }
  }

  next();
});

setupDatadogRealUserMonitoring();

const pinia = createPinia();

createApp(App).use(router).use(pinia).use(VueQueryPlugin).mount("#app");
