<template>
  <div class="settings-title">Sentence Library</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <div style="margin-left: auto; display: flex; column-gap: 12px">
      <Popper
        class="dropdown-menu-container"
        placement="bottom-end"
        :offset-distance="2"
        :interactive="false"
        @open="isDropdownOpen = true"
        @close="isDropdownOpen = false"
      >
        <button
          class="dropdown-button"
          :class="{ active: isDropdownOpen, open: isDropdownOpen }"
          data-testid="sentence-library-dropdown-menu"
        >
          <FontAwesomeIcon icon="bars" />
        </button>

        <template #content>
          <div class="dropdown-menu">
            <a
              class="export-button menu-item"
              data-testid="import-sentence-library-from-file"
              @click="importExportModalMode = 'import'"
            >
              <FontAwesomeIcon icon="upload" fixed-width />
              Import from file
            </a>
            <a
              class="export-button menu-item"
              data-testid="export-sentence-library-to-file"
              @click="importExportModalMode = 'export'"
            >
              <FontAwesomeIcon icon="download" fixed-width />
              Export to file
            </a>
          </div>
        </template>
      </Popper>

      <button
        class="add-sentence-group-button accented"
        data-testid="report-sentence-group-add"
        :disabled="!isSearchTermEmpty"
        @click="createEmptySentenceGroup"
      >
        Create Sentence Group
      </button>
    </div>
  </div>

  <div class="sentence-library">
    <OverlayScrollbar>
      <div class="report-sentence-groups" data-testid="sentence-group-list">
        <ReportSentenceGroup
          v-for="(sentenceGroup, groupIndex) in sentenceGroupsFiltered"
          :key="sentenceGroup.id"
          :group="sentenceGroup"
          :group-index="groupIndex"
          @duplicate="duplicateSentenceGroup(sentenceGroup)"
          @delete="deleteSentenceGroup(sentenceGroup)"
          @reorder="(sortableEvent) => reorderSentences(sentenceGroup, sortableEvent)"
          @save="
            groupsToSave.add(sentenceGroup.id);
            saveGroupsDebounced();
          "
        />

        <div v-if="sentenceGroups.length === 0" class="no-groups-text">
          The sentence library is empty
        </div>
        <div v-else-if="sentenceGroupsFiltered.length == 0" class="no-groups-text">
          No results found
        </div>
      </div>
    </OverlayScrollbar>

    <OverlayScrollbar>
      <div class="side-pane">
        <div
          v-for="sentenceGroup in sentenceGroupsFiltered"
          :key="sentenceGroup.id"
          class="jump-to-sentence-group-button"
          :class="{ disabled: !sentenceGroupsFiltered.includes(sentenceGroup) }"
          @click="focusSentenceGroup(sentenceGroup.id)"
        >
          <template v-if="sentenceGroup.name.length > 0">
            {{ sentenceGroup.name }}
          </template>
          <i v-else> (no name) </i>

          <Tooltip content="Delete sentence group" style="margin-right: 4px; margin-left: auto">
            <FontAwesomeIcon
              class="side-delete-button"
              icon="trash"
              size="sm"
              @click="deleteSentenceGroup(sentenceGroup)"
            />
          </Tooltip>
        </div>
      </div>
    </OverlayScrollbar>

    <ActivityOverlay v-if="activityText" :text="activityText" />
  </div>

  <ReportSentenceGroupImportExportModal
    v-if="importExportModalMode !== 'hidden'"
    :current-sentence-groups="
      sentenceGroups.map((group) => ({
        id: group.id,
        name: group.name,
        sentences: group.sentences.map((sentence) => sentence.text),
      }))
    "
    :mode="importExportModalMode"
    @close="importExportModalMode = 'hidden'"
    @import-group="createSentenceGroup"
  />
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import type { SortableEvent } from "sortablejs";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import type { ReportSentenceGroupCreateResponseDto } from "../../../backend/src/reporting/dto/report-sentence-group-create.dto";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import FilterInput from "../components/FilterInput.vue";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";
import ReportSentenceGroup from "./ReportSentenceGroup.vue";
import ReportSentenceGroupImportExportModal from "./ReportSentenceGroupImportExportModal.vue";

interface ReportSentenceGroupWithIds {
  id: string;
  name: string;
  sentences: { id: string; text: string }[];
}

const sentenceGroups = ref<ReportSentenceGroupWithIds[]>([]);
const importExportModalMode = ref<"export" | "hidden" | "import">("hidden");
const searchTerm = ref("");
const isSearchTermEmpty = computed(() => searchTerm.value.trim() === "");

const activityText = ref("");

const isDropdownOpen = ref(false);

void fetchSentenceGroups();

const sentenceGroupsFiltered = computed(() =>
  sentenceGroups.value.filter(
    (sentenceGroup) =>
      !isStringFiltered(sentenceGroup.name) ||
      sentenceGroup.sentences.some((sentence) => !isStringFiltered(sentence.text))
  )
);

function isStringFiltered(value: string): boolean {
  const term = searchTerm.value.trim();

  return !value.toLowerCase().includes(term.toLowerCase());
}

async function createEmptySentenceGroup(): Promise<void> {
  const newGroupId = await createSentenceGroup("", [""]);
  if (newGroupId !== null) {
    focusSentenceGroup(newGroupId);
  }
}

function focusSentenceGroup(id: string): void {
  document.getElementById(`report-sentence-group-${id}`)?.scrollIntoView();
  document.querySelector<HTMLInputElement>(`#report-sentence-group-name-${id} input`)?.focus();
}

async function fetchSentenceGroups(): Promise<void> {
  activityText.value = "Loading";

  let response: AxiosResponse<ReportSentenceGroupCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<ReportSentenceGroupCreateResponseDto[]>(
      "/api/report-sentence-groups"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading sentence groups" });
    return;
  } finally {
    activityText.value = "";
  }

  sentenceGroups.value = response.data.map((group) => ({
    ...group,
    sentences: group.sentences.map((sentence) => ({ id: uuidv4(), text: sentence })),
  }));
}

async function createSentenceGroup(name: string, sentences: string[]): Promise<string | null> {
  let response: AxiosResponse<ReportSentenceGroupCreateResponseDto> | undefined = undefined;
  try {
    response = await axios.post<ReportSentenceGroupCreateResponseDto>(
      `/api/report-sentence-groups`,
      { name, sentences }
    );
  } catch {
    addNotification({ type: "error", message: "Error adding sentence group" });
    return null;
  }

  // Put the new sentence group at the top
  sentenceGroups.value.splice(0, 0, {
    ...response.data,
    sentences: response.data.sentences.map((sentence) => ({ id: uuidv4(), text: sentence })),
  });

  return response.data.id;
}

const groupsToSave = new Set<string>();

async function saveGroups(): Promise<void> {
  try {
    const savePromises = [...groupsToSave].map(async (groupId) => {
      const group = sentenceGroups.value.find((g) => g.id === groupId);
      if (group === undefined) {
        return;
      }

      return axios.patch(`/api/report-sentence-groups/${group.id}`, {
        name: group.name,
        sentences: group.sentences.map((s) => s.text),
      });
    });

    groupsToSave.clear();

    await Promise.all(savePromises);
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving sentence library" });
    return;
  }

  addNotification({ type: "info", message: "Sentence library saved" });
}

const saveGroupsDebounced = useDebounceFn(() => void saveGroups(), 500);

async function deleteSentenceGroup(sentenceGroup: { id: string; name: string }): Promise<void> {
  if (!confirm(`Are you sure you want to delete the sentence group '${sentenceGroup.name}'`)) {
    return;
  }

  activityText.value = "Deleting sentence group";

  try {
    await axios.delete(`/api/report-sentence-groups/${sentenceGroup.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting sentence group" });
    return;
  } finally {
    activityText.value = "";
  }

  // Remove the deleted group from the list
  const index = sentenceGroups.value.findIndex((g) => g.id === sentenceGroup.id);
  sentenceGroups.value.splice(index, 1);

  groupsToSave.delete(sentenceGroup.id);

  addNotification({ type: "info", message: "Deleted sentence group" });
}

function getReportSentenceGroupIdFromElement(
  element: HTMLElement
): ReportSentenceGroupWithIds | undefined {
  return sentenceGroups.value.find(
    (group) =>
      document.getElementById(`report-sentence-group-${group.id}`)?.contains(element) === true
  );
}

function reorderSentences(sentenceGroup: ReportSentenceGroupWithIds, event: SortableEvent): void {
  if (event.oldIndex === undefined || event.newIndex === undefined) {
    return;
  }

  // Drag between groups
  if (event.from !== event.to) {
    const fromGroup = getReportSentenceGroupIdFromElement(event.from);
    const toGroup = getReportSentenceGroupIdFromElement(event.to);
    if (fromGroup === undefined || toGroup === undefined) {
      return;
    }

    const sentence = fromGroup.sentences[event.oldIndex];
    toGroup.sentences.splice(event.newIndex, 0, sentence);
    fromGroup.sentences.splice(event.oldIndex, 1);

    groupsToSave.add(fromGroup.id);
    groupsToSave.add(toGroup.id);
  }

  // Drag in the same group
  else {
    const [sentence] = sentenceGroup.sentences.splice(event.oldIndex, 1);
    sentenceGroup.sentences.splice(event.newIndex, 0, sentence);

    groupsToSave.add(sentenceGroup.id);
  }

  void saveGroupsDebounced();
}

async function duplicateSentenceGroup(sentenceGroup: ReportSentenceGroupWithIds): Promise<void> {
  await createSentenceGroup(
    `${sentenceGroup.name} (Copy)`,
    sentenceGroup.sentences.map((s) => s.text)
  );
  await fetchSentenceGroups();
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.sentence-library {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  flex: 1;
}

.side-pane {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
}

.jump-to-sentence-group-button {
  justify-content: left;
  border-radius: var(--border-radius);
  width: 100%;
  font-weight: bold;
  color: var(--text-color-1);
  cursor: pointer;
  transition: color 100ms ease;
  padding: 4px 0;
  display: flex;

  &:hover {
    color: var(--text-color-2);

    .side-delete-button {
      color: var(--text-color-2);
    }
  }
}

.add-sentence-group-button {
  padding: 0 12px;
}

.report-sentence-groups {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 12px;
}

.no-groups-text {
  padding-top: 20px;
  font-style: italic;
}

.side-delete-button {
  align-self: center;
  color: var(--bg-color-1);
  transition: color 100ms ease;
}

.dropdown-button {
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

:deep(.dropdown-menu-container) {
  background-color: var(--bg-color-4);
  border-radius: var(--border-radius) 0 var(--border-radius) var(--border-radius);
  padding: 4px 8px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0;

  .menu-item {
    cursor: pointer;
    transition: color 100ms ease;
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    padding: 0 4px;
    align-items: center;
    justify-content: left;

    &:hover {
      color: var(--text-color-2);
    }
  }
}
</style>
