<template>
  <ReportTemplateEditSlider
    :name="name"
    :model-value="structure.spacing[spacing]"
    :min="min"
    :max="max"
    @update:model-value="onSpacingValueUpdate"
  />
</template>

<script setup lang="ts">
import {
  ReportSpacingOption,
  ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import {
  createSpacingMutation,
  ReportStructureMutation,
} from "../reporting/report-structure-mutations";
import ReportTemplateEditSlider from "./ReportTemplateEditSlider.vue";

interface Props {
  name: string;
  structure: ReportStructure;
  spacing: ReportSpacingOption;
}

interface Emits {
  (event: "update", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const min = 0;
const max = props.spacing === ReportSpacingOption.Section ? 2 : 1.2;

function onSpacingValueUpdate(newValue: number): void {
  emits("update", createSpacingMutation({ ...props.structure.spacing, [props.spacing]: newValue }));
}
</script>
