import type { StudyMeasurementValueResponseDto } from "../studies/dto/study-get-one.dto";
import { getMeasurementDecimalPlaces } from "./measurement-display";
import { createTransientValue } from "./measurement-helpers";
import type { MeasurementName } from "./measurement-names";
import {
  MeasurementPhysicalQuantity,
  MeasurementUnit,
  convertMeasurementUnit,
  getInternalUnitForPhysicalQuantity,
  getMeasurementDisplayUnit,
  getUnitDisplayText,
  getUnitPhysicalQuantity,
} from "./measurement-units";

/**
 * A class that represents a physical value measured on a study clip.
 */
export class MeasuredValue {
  /** The physical quantity that this measured value measures. */
  public readonly physicalQuantity: MeasurementPhysicalQuantity;

  /** The amount of this measured value, in the measurement unit for its physical quantity. */
  private readonly valueInInternalUnit: number | null = null;

  constructor(
    quantity = MeasurementPhysicalQuantity.None,
    value: { value: number; unit: MeasurementUnit } | null = null
  ) {
    this.physicalQuantity = quantity;

    if (value === null) {
      return;
    }

    const convertedValue = convertMeasurementUnit(value.value, value.unit, this.getInternalUnit());

    if (convertedValue === null) {
      throw Error(`Can't set value of ${value.unit} for value quantity ${this.physicalQuantity}`);
    }

    this.valueInInternalUnit = convertedValue;
  }

  /** Returns whether or not a value is set in this measured value. */
  isNull(): boolean {
    return this.valueInInternalUnit === null;
  }

  /** Returns whether or not a value is set in this measured value. */
  isZero(): boolean {
    return this.valueInInternalUnit === 0;
  }

  /** Returns the unit this measured value stores its value in. */
  private getInternalUnit(): MeasurementUnit {
    return getInternalUnitForPhysicalQuantity(this.physicalQuantity);
  }

  /** Returns the value and unit for this measured value. */
  getInternalValueAndUnit(): { value: number; unit: MeasurementUnit } | null {
    if (this.valueInInternalUnit === null) {
      return null;
    }

    return { value: this.valueInInternalUnit, unit: this.getInternalUnit() };
  }

  /** Returns the value of this measured value converted to the appropriate unit, if possible. */
  getValueAsUnit(unit: MeasurementUnit): number | null {
    if (this.valueInInternalUnit === null) {
      return null;
    }

    return convertMeasurementUnit(this.valueInInternalUnit, this.getInternalUnit(), unit);
  }

  /**
   * Formats the measured value to a `{value} {unit}` string in converted to the relevant display
   * unit and decimal places for the provided measurement name.
   */
  formatAsStringForName(name: MeasurementName): string {
    const displayUnit = getMeasurementDisplayUnit(name);

    return `${(this.getValueAsUnit(displayUnit) ?? 0).toFixed(
      getMeasurementDecimalPlaces(name)
    )} ${getUnitDisplayText(displayUnit)}`;
  }

  /**
   * Creates a transient StudyMeasurementValue for this measured value which can be used as the
   * value measurement labels during measuring
   */
  createTransientValue(
    name: MeasurementName,
    contour: number[],
    frame: number
  ): StudyMeasurementValueResponseDto {
    return createTransientValue(
      name,
      contour,
      this.getValueAsUnit(getMeasurementDisplayUnit(name)) ?? 0,
      getMeasurementDisplayUnit(name),
      frame
    );
  }

  /**
   * Returns a new measured value containing the result of another measured value being subtracted
   * from this measured value. Only measured values of the same quantity can be subtracted.
   */
  subtract(other: MeasuredValue): MeasuredValue {
    if (this.valueInInternalUnit === null || other.valueInInternalUnit === null) {
      return MeasuredValue.Null;
    }

    if (this.physicalQuantity !== other.physicalQuantity) {
      throw Error(
        `Attempted to subtract incompatible quantities: ${this.physicalQuantity} / ${other.physicalQuantity}`
      );
    }

    return new MeasuredValue(this.physicalQuantity, {
      value: this.valueInInternalUnit - other.valueInInternalUnit,
      unit: this.getInternalUnit(),
    });
  }

  /**
   * Returns a new measured value containing the division result of this measured value by another.
   * Only certain divisions are allowed to ensure the correct units are placed on the result as the
   * units will change as a result of this operation. (e.g. dividing velocity by time to get
   * acceleration slope or dividing two of the same to produce a ratio).
   */
  divide(other: MeasuredValue): MeasuredValue {
    if (this.valueInInternalUnit === null || other.valueInInternalUnit === null) {
      return MeasuredValue.Null;
    }

    const newUnit = getDivisionResultUnit(this.getInternalUnit(), other.getInternalUnit());
    if (newUnit === null) {
      throw Error(
        `Attempted to divide incompatible units: ${this.getInternalUnit()} / ${other.getInternalUnit()}`
      );
    }

    return new MeasuredValue(getUnitPhysicalQuantity(newUnit), {
      value: this.valueInInternalUnit / other.valueInInternalUnit,
      unit: newUnit,
    });
  }

  /**
   * Returns a new measured value containing this measured value's value multiplied by a constant.
   */
  multiplyByConstant(factor: number): MeasuredValue {
    if (this.valueInInternalUnit === null) {
      return MeasuredValue.Null;
    }

    return new MeasuredValue(this.physicalQuantity, {
      value: this.valueInInternalUnit * factor,
      unit: this.getInternalUnit(),
    });
  }

  /** Returns a new measured value containing this measured value's value divided by a constant */
  divideByConstant(divisor: number): MeasuredValue {
    return this.multiplyByConstant(1 / divisor);
  }

  /** Returns a new measured value containing this measured value's value raised to a power. */
  power(exponent: number): MeasuredValue {
    if (this.valueInInternalUnit === null) {
      return MeasuredValue.Null;
    }

    return new MeasuredValue(this.physicalQuantity, {
      value: this.valueInInternalUnit ** exponent,
      unit: this.getInternalUnit(),
    });
  }

  /** Returns a new measurement value containing the absolute value of this measured value. */
  abs(): MeasuredValue {
    if (this.valueInInternalUnit === null) {
      return MeasuredValue.Null;
    }

    return new MeasuredValue(this.physicalQuantity, {
      value: Math.abs(this.valueInInternalUnit),
      unit: this.getInternalUnit(),
    });
  }

  static readonly Null = new MeasuredValue();
}

/**
 * Returns the appropriate unit for a division result between two units. If the division is not
 * allowed or otherwise invalid, null will be returned.
 */
function getDivisionResultUnit(a: MeasurementUnit, b: MeasurementUnit): MeasurementUnit | null {
  if (a === b) {
    return MeasurementUnit.Ratio;
  } else if (a === MeasurementUnit.MetersPerSecond && b === MeasurementUnit.Seconds) {
    return MeasurementUnit.MetersPerSecondSquared;
  } else if (a === MeasurementUnit.Meters && b === MeasurementUnit.Seconds) {
    return MeasurementUnit.MetersPerSecond;
  } else if (
    a === MeasurementUnit.MetersPerSecond &&
    b === MeasurementUnit.MetersPerSecondSquared
  ) {
    return MeasurementUnit.Seconds;
  }

  return null;
}
