<template>
  <div
    v-if="mode === ReportContentMode.EditReportStructure"
    ref="rootElement"
    class="structured-report-section heading"
  >
    <ReportSectionHeader
      :study="study"
      :report="report"
      :mode="mode"
      :section-id="sectionId"
      @mutate-structure="emits('mutate-structure', $event)"
    />
  </div>

  <template
    v-if="
      mode === ReportContentMode.EditReportContent ||
      mode === ReportContentMode.ViewReportStructure ||
      (isVisible && mode === ReportContentMode.GenerateHTML)
    "
  >
    <div
      v-if="isVisible || ReportContentMode.ViewReportStructure"
      class="rwma-title rpt-main-header"
      :class="{ hidden: !isVisible }"
      @click="onToggleVisibility"
    >
      <ReportCheckbox
        v-if="
          mode === ReportContentMode.EditReportContent ||
          mode === ReportContentMode.ViewReportStructure
        "
        data-testid="report-rwma-checkbox"
        :model-value="isVisible"
      />
      <span class="rpt-main-header-text">{{ sectionName }}</span>
    </div>

    <template v-if="isVisible">
      <div class="rpt-rwma-legend">
        <div
          v-for="rwmaOption in RWMAOptions"
          :key="rwmaOption.name"
          style="display: flex; gap: 0.4em; align-items: center"
        >
          <div
            class="rpt-rwma-legend-color-box"
            :style="`background-color: var(--rwma-${rwmaOption.id}-color)`"
          />
          <span class="rpt-text-normal">{{ rwmaOption.name }}</span>
        </div>
      </div>

      <RWMAContent
        :study="study"
        :report="report"
        :mode="mode"
        :editable="isEditingReportContent"
        :scale-factor="scaleFactor"
        :section-id="sectionId"
        @update-report-content="emits('update-report-content')"
      />
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RWMAOptions } from "../../../../backend/src/reporting/report-content-rwma";
import type { Study, StudyReport } from "../../utils/study-data";
import ReportCheckbox from "../ReportCheckbox.vue";
import ReportSectionHeader from "../ReportSectionHeader.vue";
import { ReportContentMode } from "../report-content";
import { ReportStructureMutation } from "../report-structure-mutations";
import RWMAContent from "./RWMAContent.vue";

interface Props {
  study: Study;
  report: StudyReport;
  mode: ReportContentMode;
  sectionId: string;
  scaleFactor: number;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
  (event: "done"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const reportStructure = computed(() => props.report.reportTemplateVersion.structure);

const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);

const sectionName = computed(() => {
  const name = reportStructure.value.sections.find((s) => s.id === props.sectionId)?.name;
  return name === "" ? "Regional Wall Motion Abnormalities" : name;
});

const reportContent = computed(() => props.report.content);

const isVisible = computed(() => reportContent.value.sections[props.sectionId].visible);

function onToggleVisibility(): void {
  const newValue = !isVisible.value;

  reportContent.value.sections[props.sectionId].visible = newValue;
}
</script>

<style scoped lang="scss">
.structured-report-section {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  background-color: var(--report-section-bg-color);
  padding: 0.6em;
  border-radius: 0.2em;
  margin: var(--report-spacing-section) 0;

  &.heading {
    margin-top: calc(var(--report-spacing-heading) + var(--report-spacing-section));
    height: 0.6em;
  }
}

.header {
  display: flex;

  &.clickable {
    cursor: pointer;
  }
}
.rwma-title {
  display: flex;
  gap: 0.8em;
  align-items: center;
  width: max-content;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 1;
  }

  &.hidden {
    color: #888;
  }
}
</style>
