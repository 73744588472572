<template>
  <div
    :id="`measurement-calculation-${calculation.id}`"
    ref="rootElement"
    class="measurement-calculation"
  >
    <div :id="`measurement-calculation-name-${calculation.id}`" class="name">
      <input
        :value="calculation.name"
        placeholder="Measurement calculation name"
        :data-testid="`measurement-calculation-${calculationIndex}-name`"
        @input="updateName($event)"
      />

      <Tooltip content="Delete measurement calculation">
        <button
          tabindex="-1"
          class="side-button"
          :data-testid="`measurement-calculation-${calculationIndex}-delete`"
          @click="emits('delete')"
        >
          <FontAwesomeIcon icon="trash" size="sm" />
        </button>
      </Tooltip>

      <Tooltip :content="toggleTooltipContent">
        <ToggleSwitch
          class="toggle"
          :enabled="calculationErrors === undefined"
          :model-value="calculation.enabled"
          @update:model-value="updateEnabled($event)"
        />
      </Tooltip>

      <div style="width: 28px; display: grid; place-content: center">
        <Tooltip :content="`${calculationErrors ?? 'The calculation is valid'}.`" max-width="400px">
          <div
            :style="{ color: calculationErrors ? 'red' : 'green' }"
            :data-testid="`calculation-${calculationIndex}-validity`"
            :data-test-is-valid="calculationErrors === undefined"
          >
            <FontAwesomeIcon v-if="calculationErrors" icon="xmark" style="display: block" />
            <FontAwesomeIcon v-else icon="check" style="display: block" />
          </div>

          <template #content>
            <template v-if="calculationErrors">
              <b> Error in formula: </b>
              {{ calculationErrors }}
            </template>
            <template v-else> The calculation is valid </template>
          </template>
        </Tooltip>
      </div>
    </div>

    <div class="calculation-list">
      <div class="row">
        <b>Formula</b>

        <input
          placeholder="Enter formula for calculation"
          :data-testid="`measurement-calculation-${calculationIndex}-formula`"
          :value="calculation.formula"
          @input="updateFormula($event)"
        />
      </div>

      <div class="row output">
        <b>Output</b>

        <DropdownWidget
          class="dropdown"
          data-testid="output-name-dropdown"
          placeholder="Select measurement name"
          :model-value="calculation.outputMeasurementName ?? ''"
          :items="outputMeasurementNameDropdownItems"
          @update:model-value="setOutputMeasurementName($event as MeasurementName)"
        />

        <DropdownWidget
          class="dropdown"
          style="width: 100px"
          data-testid="output-unit-dropdown"
          placeholder="Select unit"
          :model-value="calculation.outputMeasurementUnit ?? ''"
          :items="outputMeasurementUnitDropdownItems"
          :disabled="calculation.outputMeasurementName === null"
          @update:model-value="setOutputMeasurementUnit($event as MeasurementUnit)"
        />
      </div>

      <MeasurementCalculationVariableRow
        v-for="(variable, variableIndex) in calculation.variables"
        :key="variable.id"
        :calculation="calculation"
        :calculation-index="calculationIndex"
        :variable="variable"
        :data-testid="`calculation-variable-${variableIndex}`"
        @delete="deleteVariable(variableIndex)"
        @save="emits('save')"
      />
    </div>

    <div class="bottom-buttons">
      <Popper
        :offset-distance="2"
        placement="bottom-end"
        @open="isTryCalculationModalVisible = true"
        @close="isTryCalculationModalVisible = false"
      >
        <Tooltip
          max-width="360px"
          :content="
            doesCalculationContainAdvancedFormula(calculation.formula)
              ? 'Calculation contains an advanced formula that uses more than just the input measurement values and can\'t be tried out.'
              : ''
          "
        >
          <button
            class="try-it-out"
            :class="{ active: isTryCalculationModalVisible }"
            :data-testid="`calculation-${calculationIndex}-try-it-out`"
            :disabled="
              calculationErrors !== undefined ||
              doesCalculationContainAdvancedFormula(calculation.formula)
            "
          >
            Try it out
          </button>
        </Tooltip>

        <template #content>
          <MeasurementCalculationTryItOut :calculation="calculation" />
        </template>
      </Popper>

      <Tooltip content="Add variable to this measurement calculation" placement="left">
        <button
          class="add-variable-button"
          tabindex="-1"
          :data-testid="`measurement-calculation-${calculationIndex}-add-variable`"
          @click="addVariable"
        >
          +
        </button>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { doesCalculationContainAdvancedFormula } from "../../../../backend/src/measurements/measurement-calculation-evaluation";
import {
  MeasurementCalculationVariableType,
  type MeasurementCalculationVariable,
} from "../../../../backend/src/measurements/measurement-calculation-variable";
import { getMeasurementDisplayName } from "../../../../backend/src/measurements/measurement-display";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import {
  MeasurementUnit,
  getMeasurementPhysicalQuantity,
  getUnitDisplayText,
  getUnitPhysicalQuantity,
} from "../../../../backend/src/measurements/measurement-units";
import DropdownWidget from "../../components/DropdownWidget.vue";
import ToggleSwitch from "../../components/ToggleSwitch.vue";
import Tooltip from "../../components/Tooltip.vue";
import {
  MeasurementCalculation,
  getMeasurementCalculationErrors,
} from "../../measurements/calculations/measurement-calculations";
import MeasurementCalculationTryItOut from "./MeasurementCalculationTryItOut.vue";
import MeasurementCalculationVariableRow from "./MeasurementCalculationVariable.vue";

interface Props {
  calculation: MeasurementCalculation;
  calculationIndex: number;
}

interface Emits {
  (name: "delete"): void;
  (name: "save"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const alphabet = [..."abcdefghijklmnopqrstuvwxyz"];

const rootElement = ref<HTMLDivElement | null>(null);
const focusedFieldIndex = ref<number | null>();

const isTryCalculationModalVisible = ref(false);

const outputMeasurementNameDropdownItems = computed(() =>
  Object.values(MeasurementName)
    .filter((m) => !isCustomMeasurement(m))
    .sort((a, b) => a.localeCompare(b))
    .map((m) => ({ value: m, text: getMeasurementDisplayName(m, "unindexed") }))
);

const outputMeasurementUnitDropdownItems = computed(() => {
  const outputMeasurementName = props.calculation.outputMeasurementName;
  if (outputMeasurementName === null) {
    return [];
  }

  return Object.values(MeasurementUnit)
    .filter(
      (u) => getUnitPhysicalQuantity(u) === getMeasurementPhysicalQuantity(outputMeasurementName)
    )
    .map((u) => ({ value: u, text: getUnitDisplayText(u) }));
});

const toggleTooltipContent = computed(() => {
  if (calculationErrors.value !== undefined) {
    return "Calculation can't be used because there are errors";
  }

  return props.calculation.enabled
    ? "Calculation is enabled and can be used to create measurements"
    : "Calculation is disabled";
});

function updateEnabled(enabled: boolean): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.enabled = enabled;
  emits("save");
}

function updateName(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.name = event.target.value;
  emits("save");
}

function updateFormula(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.formula = event.target.value;
  emits("save");
}

function setOutputMeasurementName(measurementName: MeasurementName): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.outputMeasurementName = measurementName;
  emits("save");
}

function setOutputMeasurementUnit(unit: MeasurementUnit): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.outputMeasurementUnit = unit;
  emits("save");
}

function addVariable(): void {
  const newVariable: MeasurementCalculationVariable = {
    id: uuidv4(),
    type: MeasurementCalculationVariableType.Measurement,
    variableName:
      alphabet.find((l) => !props.calculation.variables.some((v) => v.variableName === l)) ?? "",
  };

  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.variables.splice(props.calculation.variables.length, 0, newVariable);
  emits("save");
}

function deleteVariable(index: number): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.calculation.variables.splice(index, 1);
  emits("save");
}

const calculationErrors = computed(() => getMeasurementCalculationErrors(props.calculation));

onKeyStroke("Escape", () => (focusedFieldIndex.value = undefined));
</script>

<style scoped lang="scss">
.name {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border: 1px solid var(--border-color-1);
  overflow: hidden;
  align-items: center;

  .toggle {
    padding-left: 6px;
  }

  &:hover,
  &:focus-within {
    .side-button {
      visibility: visible;
      opacity: 1;
    }
  }

  &:focus-within .side-button {
    background-color: var(--bg-color-4);
  }

  input {
    font-weight: bold;
    padding: 4px 8px;
    height: 20px;
    border-radius: 0;
    border: none;

    &::placeholder {
      font-weight: normal;
    }
  }
}

.side-button {
  height: 28px;
  width: 24px;
  background-color: var(--bg-color-3);
  opacity: 0;
  transition:
    opacity 100ms ease,
    background-color 100ms ease;
  border-radius: 0 !important;
}

.row {
  background-color: var(--bg-color-2);
  display: grid;
  grid-template-columns: 80px 1fr max-content;
  min-height: 28px;
  border: 1px solid var(--border-color-1);
  border-top: none;

  input {
    height: 28px;
    padding: 0 8px;
    border: 0;
    border-radius: 0;
  }

  > :first-child {
    align-self: center;
    text-align: right;
    padding-right: 4px;
  }

  &.output {
    padding-right: 24px;
  }
}

.add-variable-button {
  height: 28px;
  width: 28px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.dropdown {
  height: 28px;
  border: none;
  border-radius: 0;
}

.bottom-buttons {
  margin-left: auto;
  width: max-content;
  display: flex;
  gap: 8px;
  margin-top: 2px;
}

.try-it-out {
  height: 28px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
</style>
