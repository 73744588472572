import { tryOnScopeDispose } from "@vueuse/core";
import sysend from "sysend";
import { z } from "zod";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { notificationSyncMessages } from "./notification-sync-messages";

let primaryWindowId = "";

/**
 * Sets the ID of the primary window to send messages to. This is read out of the URLs used by
 * secondary windows.
 */
export function setPrimaryWindowId(id: string): void {
  primaryWindowId = id;
}

/** Schema for messages that can be sent to the primary window. */
const primaryWindowIncomingMessageSchema = z.discriminatedUnion("type", [
  z.strictObject({
    type: z.literal("secondary-window-opened"),
    secondaryWindowId: z.string(),
  }),
  z.strictObject({
    type: z.literal("secondary-window-closed"),
  }),
  z.strictObject({
    type: z.literal("secondary-window-key-pressed"),
    key: z.string(),
  }),
  z.strictObject({
    type: z.literal("selected-clips-changed"),
    selectedClipIds: z.string().array(),
  }),
  z.strictObject({
    type: z.literal("change-measurements"),
    measurementsJson: z.string(),
  }),
  z.strictObject({
    type: z.literal("next-clips"),
  }),
  z.strictObject({
    type: z.literal("prev-clips"),
  }),
  z.strictObject({
    type: z.literal("set-clips-playing"),
    isPlaying: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("set-stress-mode-enabled"),
    isStressModeEnabled: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("stress-mode-step-view"),
    step: z.number(),
  }),
  z.strictObject({
    type: z.literal("set-measurement-tool"),
    tool: z.nativeEnum(MeasurementToolName).nullable(),
  }),
  z.strictObject({
    type: z.literal("set-measurement-sequence"),
    sequenceId: z.string(),
    stepIndex: z.number(),
  }),
  z.strictObject({
    type: z.literal("scroll-and-highlight-measurement"),
    measurementId: z.string(),
  }),
  ...notificationSyncMessages,
]);

type PrimaryWindowIncomingMessage = z.infer<typeof primaryWindowIncomingMessageSchema>;

/** Posts a message to the primary window. This should be called from a secondary window. */
export function postMessageToPrimaryWindow(message: PrimaryWindowIncomingMessage): void {
  if (primaryWindowId === "") {
    return;
  }

  sysend.post(primaryWindowId, message);
}

let isHandlingMessage = false;

export function isHandlingPrimaryWindowMessage(): boolean {
  return isHandlingMessage;
}

/**
 * Adds a handler that will be called when the specified message is received on the primary window.
 */
export function onPrimaryWindowMessageReceived<
  MessageType extends PrimaryWindowIncomingMessage["type"],
  MessageData extends Extract<PrimaryWindowIncomingMessage, { type: MessageType }>,
>(type: MessageType, handler: (message: MessageData) => void): void {
  function callback(payload: { data: unknown; origin: string }): void {
    const parsedMessage = primaryWindowIncomingMessageSchema.safeParse(payload.data);

    if (parsedMessage.success && parsedMessage.data.type === type) {
      isHandlingMessage = true;
      try {
        handler(payload.data as MessageData);
      } finally {
        isHandlingMessage = false;
      }
    }
  }

  sysend.track("message", callback);

  tryOnScopeDispose(() => {
    sysend.untrack("message", callback as () => void);
  });
}
