<template>
  <div class="settings-title">Activity</div>
  <div class="chart-controls">
    <div class="chart-dropdowns">
      <template v-if="currentTenant.isGlobalAdmin">
        <b>Tenant</b>
        <DropdownInputCheckbox
          v-model="selectedTenants"
          :options="allTenants.map((tenant) => ({ value: tenant.id, text: tenant.name }))"
          :text="tenantsDropdownText"
        />
      </template>

      <b>Chart Period</b>
      <DropdownWidget
        v-model="selectedChartPeriod"
        :items="[
          { value: 'lastWeek', text: 'Last week' },
          { value: 'lastMonth', text: 'Last month' },
          { value: 'lastYear', text: 'Last year' },
        ]"
        class="dropdown"
      />

      <b>Chart Type</b>
      <DropdownWidget
        v-model="selectedChartIndex"
        :items="
          availableCharts.map((chart, index) => ({ value: index.toString(), text: chart.name }))
        "
        class="dropdown"
      />

      <template
        v-if="
          selectedChart.dataType === ChartDataType.AuditAction &&
          selectedChart.auditAction === undefined
        "
      >
        <div />
        <DropdownInputCheckbox
          v-model="selectedAuditActions"
          :text="
            selectedAuditActions.length === 0
              ? 'Select action'
              : selectedAuditActions
                  .map((action) => getAuditLogActionDescription(action))
                  .join(', ')
          "
          :options="
            Object.values(AuditAction).map((action) => ({
              value: action,
              text: getAuditLogActionDescription(action),
            }))
          "
        />
      </template>
    </div>

    <i style="position: absolute; top: 0; right: 0"> Chart refreshes every 30s </i>
  </div>

  <div style="position: relative; flex: 1; display: grid; place-content: stretch">
    <TenantActivityChart :samples="chartData" :label="chartLabel" />
    <ActivityOverlay v-if="isLoading" text="Loading" />
  </div>
</template>

<script setup lang="ts">
import DropdownInputCheckbox from "@/components/DropdownInputCheckbox.vue";
import DropdownWidget from "@/components/DropdownWidget.vue";
import { useDebounceFn, useIntervalFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { computed, onMounted, ref, watch } from "vue";
import { AuditAction } from "../../../backend/src/audit/audit-actions";
import { ChartDataType } from "../../../backend/src/chart/charting";
import { ChartDataGetResponseDto } from "../../../backend/src/chart/dto/chart-data-get.dto";
import { getAuditLogActionDescription } from "../audit-logs/audit-action-descriptions";
import { currentTenant } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import TenantActivityChart from "../components/TenantActivityChart.vue";
import { useTenants } from "../utils/all-tenants";
import { addNotification } from "../utils/notifications";

const isLoading = ref(false);

const allTenants = useTenants();

type ChartPeriod = "lastMonth" | "lastWeek" | "lastYear";

const availableCharts: { name: string; dataType: ChartDataType; auditAction?: AuditAction }[] = [
  {
    name: "Active users",
    dataType: ChartDataType.UsersActive,
  },
  {
    name: "Total studies",
    dataType: ChartDataType.StudiesTotal,
  },
  {
    name: "Studies signed",
    dataType: ChartDataType.AuditAction,
    auditAction: AuditAction.StudyReportSigned,
  },
  {
    name: "Studies viewed",
    dataType: ChartDataType.AuditAction,
    auditAction: AuditAction.StudyViewed,
  },
  {
    name: "Studies created",
    dataType: ChartDataType.AuditAction,
    auditAction: AuditAction.StudyCreated,
  },
  {
    name: "Average reporting time",
    dataType: ChartDataType.ReportingTime,
  },
  {
    name: "Average report clicks",
    dataType: ChartDataType.ReportingClicks,
  },
  {
    name: "Average report sentence library clicks",
    dataType: ChartDataType.ReportingSentenceLibraryClicks,
  },
  {
    name: "Average reporting keystrokes",
    dataType: ChartDataType.ReportingKeystrokes,
  },
  {
    name: "Custom action",
    dataType: ChartDataType.AuditAction,
  },
];

const selectedTenants = ref<string[]>([]);

const tenantsDropdownText = computed(() => {
  if (allTenants.value.length === selectedTenants.value.length) {
    return "All tenants";
  }

  if (selectedTenants.value.length === 0) {
    return "No tenant selected";
  }

  return selectedTenants.value
    .map((tenantId) => allTenants.value.find((tenant) => tenant.id === tenantId)!.name)
    .join(", ");
});

// Select all tenants by default
watch(allTenants, () => (selectedTenants.value = allTenants.value.map((tenant) => tenant.id)), {
  immediate: true,
});

const selectedChartIndex = ref("0");
const selectedChartPeriod = ref<ChartPeriod>("lastWeek");
const selectedChart = computed(() => availableCharts[Number(selectedChartIndex.value)]);

const selectedAuditActions = ref<AuditAction[]>([]);

const chartData = ref<ChartDataGetResponseDto>([]);

const chartLabel = ref("");

function getChartLabel(): string {
  if (
    selectedChart.value.dataType === ChartDataType.AuditAction &&
    selectedChart.value.auditAction === undefined
  ) {
    let names = selectedAuditActions.value.map((selectedAction) =>
      getAuditLogActionDescription(selectedAction)
    );

    if (names.length > 5) {
      names = [...names.slice(0, 5), "… "];
    }

    return names.join(" + ");
  }

  return selectedChart.value.name;
}

async function loadChartData(): Promise<void> {
  let sampleFrequency = "";
  let sampleCount = 0;

  if (selectedChartPeriod.value === "lastWeek") {
    sampleFrequency = "day";
    sampleCount = 7;
  } else if (selectedChartPeriod.value === "lastMonth") {
    sampleFrequency = "day";
    sampleCount = 30;
  } else {
    sampleFrequency = "month";
    sampleCount = 12;
  }

  let response: AxiosResponse<ChartDataGetResponseDto> | undefined = undefined;
  isLoading.value = true;

  try {
    const auditActionNames =
      selectedChart.value.auditAction === undefined
        ? selectedAuditActions.value
        : [selectedChart.value.auditAction];

    // If querying a custom audit action then at least one action name must be selected in order to
    // run the query
    if (
      selectedChart.value.dataType === ChartDataType.AuditAction &&
      auditActionNames.length === 0
    ) {
      chartData.value = [];
      return;
    }

    response = await axios.post<ChartDataGetResponseDto>(`/api/charts/chart-data`, {
      tenantIds: selectedTenants.value,
      dataType: selectedChart.value.dataType,
      auditActions: auditActionNames,
      sampleFrequency,
      sampleCount,
      originTimestamp: DateTime.local().toISO(),
      includePartialSamplePeriod: true,
    });
  } catch {
    addNotification({ type: "error", message: "Failed loading chart data" });
    return;
  } finally {
    isLoading.value = false;
    chartLabel.value = getChartLabel();
  }

  if (!isEqual(chartData.value, response.data)) {
    chartData.value = response.data;

    // When showing reporting time, convert from seconds to minutes for display
    if (selectedChart.value.dataType === ChartDataType.ReportingTime) {
      for (const item of chartData.value) {
        item.value /= 60;
      }
    }
  }
}

const debouncedLoadChartData = useDebounceFn(() => {
  void loadChartData();
}, 500);

watch(
  [selectedTenants, selectedChartIndex, selectedChartPeriod, selectedAuditActions, allTenants],
  debouncedLoadChartData
);

// Refresh every 30 seconds
useIntervalFn(() => {
  void loadChartData();
}, 30 * 1000);

onMounted(async () => {
  await loadChartData();
});
</script>

<style scoped lang="scss">
.chart-controls {
  display: flex;
  align-items: center;
  position: relative;
}

.chart-dropdowns {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 8px 32px;
}

.dropdown {
  height: 32px;
  width: 220px;
}

.chart-container {
  flex: 1;
}
</style>
