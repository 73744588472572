<template>
  <Modal
    title="Send to DICOM Modality"
    :activity-text="activityText"
    @header-button-click="emits('close')"
  >
    <div class="send-study-modal" data-testid="dicom-endpoint-send-modal">
      <div class="patient-details">
        <b>Patient Name</b>
        <div class="row-value selectable-text" data-testid="patient-name">
          {{ formatDicomName(study.patientName, currentTenant.patientNameFormat) }}
        </div>

        <b>{{ currentTenant.patientIdLabel }}</b>
        <div class="row-value selectable-text" data-testid="patient-id">
          {{ study.patientId }}
        </div>

        <b>Patient Birthdate</b>
        <div class="row-value selectable-text" data-testid="patient-birthdate">
          {{ formatDateTime(study.patientBirthdate) }}
        </div>

        <div style="height: 1em" />
        <div />

        <b>Study Date</b>
        <div class="row-value selectable-text" data-testid="study-date">
          {{ formatDateTime(getStudyDateTime(study), { includeTime: true }) }}
        </div>

        <b>DICOM Study Instance UID</b>
        <div class="row-value selectable-text" data-testid="study-instance-uid">
          {{ study.studyInstanceUid }}
        </div>

        <template v-if="studyClip !== undefined">
          <b>DICOM SOP Instance UID</b>
          <div class="row-value selectable-text" data-testid="sop-instance-uid">
            {{ studyClip.sopInstanceUid }}
          </div>
          <b>DICOM Transfer Syntax</b>
          <div class="row-value selectable-text" data-testid="transfer-syntax-uid">
            {{ studyClip.transferSyntaxUid }}
          </div>
        </template>

        <div style="height: 1em" />
        <div />

        <b>Total Size</b>
        <div class="row-value selectable-text" data-testid="total-size">
          {{ prettyBytes(totalDicomSize) }}
        </div>
      </div>

      <div class="field" data-testid="send-to">
        <b>Send To</b>
        <DropdownWidget v-model="selectedDicomEndpoint" :items="dicomEndpoints" />
      </div>

      <button
        class="accented"
        :disabled="selectedDicomEndpoint === ''"
        data-testid="send-study-button"
        @click="requestDicomSend"
      >
        Send Study {{ studyClip ? "Clip" : "" }}
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import axios, { AxiosResponse } from "axios";
import prettyBytes from "pretty-bytes";
import { computed, onMounted, ref } from "vue";
import type { DicomEndpointGetManyResponseDto } from "../../../backend/src/dicom/dto/dicom-endpoint-get-many.dto";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { currentTenant } from "../auth/current-session";
import { formatDateTime } from "../utils/date-time-utils";
import { addNotification } from "../utils/notifications";
import { type Study, type StudyClip } from "../utils/study-data";
import DropdownWidget from "./DropdownWidget.vue";

interface Props {
  study: Study;
  studyClip?: StudyClip;
}

const emits = defineEmits(["close"]);
const props = defineProps<Props>();

const dicomEndpoints = ref<{ value: string; text: string }[]>([]);
const selectedDicomEndpoint = ref("");

const activityText = ref("");

const totalDicomSize = computed(() => {
  if (props.studyClip !== undefined) {
    return parseInt(props.studyClip.dicomSize);
  }

  return parseInt(props.study.dicomSize);
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<DicomEndpointGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<DicomEndpointGetManyResponseDto>(`/api/dicom-endpoints`);
  } catch (error) {
    addNotification({ type: "error", message: "Failed loading DICOM endpoints" });
    return;
  } finally {
    activityText.value = "";
  }

  dicomEndpoints.value = response.data.map((endpoint) => ({
    value: endpoint.id,
    text: endpoint.name,
  }));

  selectedDicomEndpoint.value = response.data[0].id;
});

async function requestDicomSend(): Promise<void> {
  activityText.value = "Requesting DICOM send";

  try {
    await axios.post(`/api/dicom-endpoints/${selectedDicomEndpoint.value}/send`, {
      studyId: props.study.id,
      studyClipId: props.studyClip?.id,
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed starting DICOM send" });
    return;
  } finally {
    activityText.value = "";
  }

  emits("close");

  addNotification({ type: "info", message: "DICOM send started" });
}
</script>

<style scoped lang="scss">
.send-study-modal {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    margin-top: 8px;
    max-width: max-content;
    align-self: center;
  }
}

.field {
  display: grid;
  gap: 8px;
}

.patient-details {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 24px;
  row-gap: 4px;
  border: 2px solid var(--bg-color-4);
  border-radius: var(--border-radius);
  padding: 12px;

  .row-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
