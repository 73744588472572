import { ref } from "vue";
import {
  type MeasurementCalculationScope,
  advancedCalculationFormulas,
  doesCalculationContainAdvancedFormula,
  evaluateCalculationFormula,
} from "../../../../backend/src/measurements/measurement-calculation-evaluation";
import {
  MeasurementCalculationVariableType,
  type MeasurementCalculationVariable,
} from "../../../../backend/src/measurements/measurement-calculation-variable";
import type { MeasurementCalculation as MeasurementCalculationEntity } from "../../../../backend/src/measurements/measurement-calculation.entity";
import { calculateBiplaneVolume } from "../../../../backend/src/measurements/measurement-evaluation-biplane";
import { mathjsInstance } from "../../../../backend/src/shared/mathjs";
import { getEmptyStudy } from "../../utils/study-data";

export type MeasurementCalculation = Pick<
  MeasurementCalculationEntity,
  | "id"
  | "name"
  | "enabled"
  | "formula"
  | "variables"
  | "outputMeasurementName"
  | "outputMeasurementUnit"
>;

/**
 * The currently active measurement calculation. Will be null if there is no in-progress
 * calculation.
 */
export const activeMeasurementCalculation = ref<MeasurementCalculation | null>(null);

export function isVariableNameUsedMultipleTimes(
  calculation: MeasurementCalculation,
  variable: MeasurementCalculationVariable
): boolean {
  if (variable.variableName === "") {
    return false;
  }

  return calculation.variables.some(
    (v) => variable.variableName === v.variableName && v.id !== variable.id
  );
}

export function getMeasurementCalculationErrors(
  calculation: MeasurementCalculation
): string | undefined {
  if (calculation.formula.trim() === "") {
    return `Calculation formula must not be empty`;
  }

  if (calculation.variables.length === 0) {
    return "Calculation must have at least one variable";
  }

  if (calculation.variables.some((v) => v.variableName === "")) {
    return "Variable names must not be empty";
  }

  for (const disallowedName of ["inputs", ...Object.keys(advancedCalculationFormulas)]) {
    if (calculation.variables.some((v) => v.variableName === disallowedName)) {
      return `Variable name '${disallowedName}' is not allowed`;
    }
  }

  if (calculation.outputMeasurementName === null || calculation.outputMeasurementUnit === null) {
    return "Output measurement name and unit must be set";
  }

  const alreadyUsedVariable = calculation.variables.find((v) =>
    isVariableNameUsedMultipleTimes(calculation, v)
  );
  if (alreadyUsedVariable !== undefined) {
    return `Variable name "${alreadyUsedVariable.variableName}" is already in use`;
  }

  for (const v of calculation.variables) {
    if (v.unit === undefined && v.type !== MeasurementCalculationVariableType.PatientMetric) {
      return `Variable "${v.variableName}" has no unit`;
    }

    if (
      v.type === MeasurementCalculationVariableType.Measurement &&
      v.measurementName === undefined
    ) {
      return `Variable "${v.variableName}" has no measurement name selected`;
    }

    if (
      v.type === MeasurementCalculationVariableType.ManuallyEntered &&
      v.label.trim().length === 0
    ) {
      return `Variable "${v.variableName}" has no label specified`;
    }
  }

  const scope = calculation.variables.reduce<Record<string, number>>(
    (curr, acc) => ({ ...curr, [acc.variableName]: 1 }),
    {}
  );

  const inputs = calculation.variables.reduce(
    (curr, acc) => ({
      ...curr,
      [acc.variableName]: null,
    }),
    {}
  );

  const study = getEmptyStudy();

  try {
    // If the calculation evaluates in the scope created above as a number the calculation is valid
    const result: unknown = mathjsInstance.evaluate(calculation.formula, {
      ...scope,
      inputs,
      calculateBiplaneVolume: (args: unknown) => calculateBiplaneVolume(study, args),
    });

    if (
      typeof result !== "number" &&
      calculation.formula !== "" &&
      (result !== undefined || !doesCalculationContainAdvancedFormula(calculation.formula))
    ) {
      return `Output is not a number (output is ${typeof result})`;
    }

    return undefined;
  } catch (error: unknown) {
    return String(error);
  }
}

export function evaluateMeasurementCalculation(
  formula: string,
  scope: MeasurementCalculationScope
): number | undefined {
  return evaluateCalculationFormula(mathjsInstance, formula, scope);
}
