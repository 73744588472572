<template>
  <div class="reference-range-list">
    <template v-for="range in referenceRanges" :key="range.name">
      <template v-if="range.name !== ReferenceRangeName.Unknown">
        <div class="row" :class="{ highlighted: range.name === highlightedRange }">
          <div>{{ getReferenceRangeDisplayName(range.name) }}</div>
          <div style="text-align: right">{{ range.from }} － {{ range.to }}{{ unit }}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { MeasurementName } from "@/../../../backend/src/measurements/measurement-names";
import {
  getReferenceRangeDisplayName,
  ReferenceRangeName,
} from "@/reference-ranges/reference-range";
import {
  getReferenceRangeSetData,
  ReferenceRangeSetName,
} from "@/reference-ranges/reference-range-sets";
import { computed } from "vue";

interface Props {
  referenceRangeSetName: ReferenceRangeSetName;
  measurementName: MeasurementName;
  indexed: boolean;
  unit: string;
  highlightedRange: ReferenceRangeName;
}

const props = defineProps<Props>();

const referenceRanges = computed(() =>
  getReferenceRangeSetData(props.referenceRangeSetName, props.measurementName, props.indexed)
);
</script>

<style scoped lang="scss">
.reference-range-list {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3px;
}

.row {
  display: contents;

  &.highlighted {
    font-weight: bold;
  }
}
</style>
