<template>
  <div
    class="active-calculation-header"
    :class="{ 'top-shadow': topShadow }"
    data-testid="active-calculation-header"
  >
    <Tooltip content="Measurement calculation in progress">
      <div class="as2">
        <FontAwesomeIcon icon="calculator" size="lg" />

        <b> {{ calculation.name }} </b>
      </div>
    </Tooltip>

    <div style="flex: 1" />

    <Tooltip content="Cancel measurement calculation" class="close-icon">
      <FontAwesomeIcon icon="xmark" @click="onCancelMeasurementCalculation" />
    </Tooltip>
  </div>

  <div
    v-if="calculation.outputMeasurementName !== null && calculation.outputMeasurementUnit !== null"
    class="active-measurement-calculation"
  >
    <div class="calculation-grid">
      <template v-for="variable in calculation.variables" :key="variable.id">
        <template
          v-if="
            variable.unit !== undefined ||
            variable.type === MeasurementCalculationVariableType.PatientMetric
          "
        >
          <div class="variable-name">
            <span
              :class="{
                jumpable:
                  variable.type === MeasurementCalculationVariableType.Measurement &&
                  study.measurements.some((mmt) => mmt.name === variable.measurementName),
              }"
              @click="onClickVariableName(variable)"
            >
              {{ getVariableLabel(variable) }}
            </span>

            <Tooltip
              v-if="
                variable.type === 'measurement' &&
                variable.measurementName !== undefined &&
                (variableBeingMeasured === null || variableBeingMeasured.id === variable.id) &&
                allDrawableMeasurements.has(variable.measurementName)
              "
              :content="
                variableBeingMeasured?.id === variable.id
                  ? 'Cancel measuring'
                  : 'Take this measurement to use in calculation'
              "
            >
              <FontAwesomeIcon
                :class="{ measuring: variableBeingMeasured?.id === variable.id }"
                icon="ruler"
                :data-testid="`take-measurement-${variable.measurementName}`"
                @click="startMeasuringCalculationVariable(variable)"
              />
            </Tooltip>
          </div>

          <MeasurementCalculationValueSelection
            v-if="
              variable.type === MeasurementCalculationVariableType.Measurement &&
              variable.unit !== undefined
            "
            :study="props.study"
            :measurement="props.study.measurements.find((m) => m.name === variable.measurementName)"
            :formula="calculation.formula"
            :selected-measurement-value="selectedMeasurementValues[variable.id]"
            :variable-unit="variable.unit"
            @select-value="selectedMeasurementValues[variable.id] = $event"
          />

          <div
            v-else-if="variable.type === MeasurementCalculationVariableType.ManuallyEntered"
            class="manually-entered-value"
          >
            <input
              type="number"
              :data-testid="`calculation-manual-input-${variable.label}`"
              @input="(e) => setManualInputValue(variable, e)"
            />
          </div>

          <div
            v-else-if="variable.type === MeasurementCalculationVariableType.PatientMetric"
            class="patient-metric-value"
          >
            {{ patientMetrics[variable.patientMetric]?.toPrecision(3) }}
          </div>

          <span>
            {{
              variable.type === MeasurementCalculationVariableType.PatientMetric
                ? getCalculationMetricDisplayUnit(variable.patientMetric)
                : getUnitDisplayText(variable.unit!)
            }}
          </span>
        </template>
      </template>

      <div />
      <div class="result-divider" />
    </div>

    <div class="calculation-grid">
      <span>
        {{
          getMeasurementDisplayName(
            calculation.outputMeasurementName ?? MeasurementName.CustomValue,
            "unindexed"
          )
        }}
      </span>

      <span style="text-align: right; grid-column: 2 / span 2" data-testid="calculation-result">
        {{ calculationResult?.toFixed(2) ?? "—" }}
        {{ getUnitDisplayText(calculation.outputMeasurementUnit) }}
      </span>

      <Tooltip
        max-width="340px"
        :content="calculationEvaluationErrors ?? ''"
        :offset-distance="2"
        style="grid-column: 2 / span 2; margin-top: 8px"
      >
        <button
          class="accented save-calculation-button"
          :disabled="calculationResult === undefined || calculationEvaluationErrors !== undefined"
          data-testid="save-calculation-button"
          @click="saveCalculatedMeasurement"
        >
          Save
        </button>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, reactive, ref, watch } from "vue";
import {
  allDrawableMeasurements,
  getDrawableMeasurementsForTool,
} from "../../../../backend/src/measurements/drawable-measurements";
import {
  doesCalculationContainAdvancedFormula,
  evaluateCalculationFormula,
  findMeasurementForVariableName,
} from "../../../../backend/src/measurements/measurement-calculation-evaluation";
import {
  CalculationPatientMetric,
  MeasurementCalculationVariable,
  MeasurementCalculationVariableType,
  getCalculationMetricDisplayUnit,
  getVariableLabel,
} from "../../../../backend/src/measurements/measurement-calculation-variable";
import {
  getMeasurementDisplayName,
  getMeasurementMeanValue,
  getPatientMetrics,
} from "../../../../backend/src/measurements/measurement-display";
import {
  calculateBiplaneVolume,
  getBiplaneVolumeCalculationErrors,
} from "../../../../backend/src/measurements/measurement-evaluation-biplane";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import {
  MeasurementUnit,
  convertMeasurementUnit,
  getInternalUnitForMeasurementName,
  getUnitDisplayText,
} from "../../../../backend/src/measurements/measurement-units";
import { mathjsInstance } from "../../../../backend/src/shared/mathjs";
import { StudyMeasurementBatchChangeResponseDto } from "../../../../backend/src/studies/dto/study-measurement-batch-change.dto";
import { StudyMeasurementValueCalculationInputCreateRequestDto } from "../../../../backend/src/studies/dto/study-measurement-value-create.dto";
import { StudyMeasurementValueSource } from "../../../../backend/src/studies/study-measurement-enums";
import Tooltip from "../../components/Tooltip.vue";
import { removeNullish } from "../../utils/array-helpers";
import { addNotification } from "../../utils/notifications";
import {
  Study,
  StudyMeasurementValue,
  getPatientAgeInYearsWhenScanned,
} from "../../utils/study-data";
import { changeBatchMeasurements } from "../measurement-save-helpers";
import { activeMeasurement, startMeasuring, stopMeasuring } from "../measurement-tool-state";
import MeasurementCalculationValueSelection from "./MeasurementCalculationValueSelection.vue";
import { MeasurementCalculation, activeMeasurementCalculation } from "./measurement-calculations";

interface Props {
  study: Study;
  calculation: MeasurementCalculation;
  topShadow: boolean;
}

interface Emits {
  (event: "jump-to-value", value: StudyMeasurementValue): void;
  (event: "scroll-to-measurement", measurementId: string): void;
  (event: "highlight-measurement-card", measurementId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const manualInputs = reactive<Record<string, string>>({});
const selectedMeasurementValues = reactive<Record<string, StudyMeasurementValue | null>>({});

const patientMetrics: Partial<Record<CalculationPatientMetric, number | undefined>> = {
  ...getPatientMetrics(props.study),
  age: getPatientAgeInYearsWhenScanned(props.study),
};

const variableBeingMeasured = ref<
  (MeasurementCalculationVariable & { type: MeasurementCalculationVariableType.Measurement }) | null
>(null);

const calculationResult = computed(() => {
  const scope: Record<string, number | undefined> = {};
  const inputs: Record<string, string | undefined> = {};

  // Build the Math.js scope in which to evaluate the calculation
  for (const variable of props.calculation.variables) {
    if (variable.type === MeasurementCalculationVariableType.ManuallyEntered) {
      scope[variable.variableName] = parseFloat(manualInputs[variable.id]);
    } else if (variable.type === MeasurementCalculationVariableType.Measurement) {
      const variableValue =
        selectedMeasurementValues[variable.id]?.value ??
        getMeasurementMeanValue(
          findMeasurementForVariableName(
            variable.variableName,
            props.study.measurements,
            props.calculation.variables
          )
        );

      if (
        variableValue === undefined ||
        variable.measurementName === undefined ||
        variable.unit === undefined
      ) {
        continue;
      }

      scope[variable.variableName] =
        convertMeasurementUnit(
          variableValue,
          getInternalUnitForMeasurementName(variable.measurementName),
          variable.unit
        ) ?? undefined;

      inputs[variable.variableName] = selectedMeasurementValues[variable.id]?.id;
    } else {
      scope[variable.variableName] = patientMetrics[variable.patientMetric];
    }
  }

  return evaluateCalculationFormula(mathjsInstance, props.calculation.formula, {
    ...scope,
    inputs,
    calculateBiplaneVolume: (args: unknown) => calculateBiplaneVolume(props.study, args),
  });
});

watch(
  () => props.calculation,
  () => {
    for (const key of Object.keys(manualInputs)) {
      delete manualInputs[key];
    }

    for (const key of Object.keys(selectedMeasurementValues)) {
      delete selectedMeasurementValues[key];
    }

    for (const variable of props.calculation.variables) {
      if (variable.type === MeasurementCalculationVariableType.ManuallyEntered) {
        manualInputs[variable.id] = "";
      } else if (
        doesCalculationContainAdvancedFormula(props.calculation.formula) &&
        variable.type === MeasurementCalculationVariableType.Measurement
      ) {
        // If the calculation contains an advanced formula, mean values aren't permitted so
        // select the first value for the measurement
        const measurement = findMeasurementForVariableName(
          variable.variableName,
          props.study.measurements,
          props.calculation.variables
        );
        selectedMeasurementValues[variable.id] =
          measurement?.values.find((v) => v.contour !== null) ?? null;
      } else {
        // Default to the the measurement mean
        selectedMeasurementValues[variable.id] = null;
      }
    }
  },
  { immediate: true }
);

function onClickVariableName(variable: MeasurementCalculationVariable): void {
  const value = selectedMeasurementValues[variable.id];
  const measurementId =
    value?.measurementId ??
    findMeasurementForVariableName(
      variable.variableName,
      props.study.measurements,
      props.calculation.variables
    )?.id;

  if (measurementId === undefined) {
    return;
  }

  emits("scroll-to-measurement", measurementId);
  emits("highlight-measurement-card", measurementId);

  if (value !== null) {
    emits("jump-to-value", value);
  }
}

function onCancelMeasurementCalculation(): void {
  if (variableBeingMeasured.value !== null) {
    stopMeasuring();
  }

  activeMeasurementCalculation.value = null;
}

function setManualInputValue(variable: MeasurementCalculationVariable, event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  manualInputs[variable.id] = event.target.value;
}

function startMeasuringCalculationVariable(variable: MeasurementCalculationVariable) {
  if (variable === variableBeingMeasured.value) {
    stopMeasuring();
    return;
  }

  if (
    variable.type !== MeasurementCalculationVariableType.Measurement ||
    variable.measurementName === undefined
  ) {
    return;
  }

  const measurementName = variable.measurementName;
  const tools = Object.values(MeasurementToolName);
  const tool = tools.find((t) => getDrawableMeasurementsForTool(t).includes(measurementName));

  if (tool === undefined) {
    return;
  }

  startMeasuring({ tool, study: props.study, clipId: "" });

  variableBeingMeasured.value = variable;

  activeMeasurement.value.measurementName.value = measurementName;
  activeMeasurement.value.isMeasurementNameFixed.value = true;

  activeMeasurement.value.onSaved.push((response: StudyMeasurementBatchChangeResponseDto) => {
    if (variableBeingMeasured.value === null) {
      return;
    }

    const newMeasurementValue = response.createResponses.find(
      (r) => r.measurement.name === variableBeingMeasured.value?.measurementName
    )?.measurementValue;

    selectedMeasurementValues[variableBeingMeasured.value.id] = newMeasurementValue ?? null;
  });

  activeMeasurement.value.onDestroy.push(() => (variableBeingMeasured.value = null));
}

async function saveCalculatedMeasurement(): Promise<void> {
  if (
    props.calculation.outputMeasurementName === null ||
    props.calculation.outputMeasurementUnit === null ||
    calculationResult.value === undefined
  ) {
    return;
  }

  try {
    // If we have any manually entered values, we need to create those as custom measurements first
    const manuallyEnteredCalculationInputs: StudyMeasurementValueCalculationInputCreateRequestDto[] =
      [];
    if (
      props.calculation.variables.some(
        (v) => v.type === MeasurementCalculationVariableType.ManuallyEntered
      )
    ) {
      type ManuallyEnteredValue = MeasurementCalculationVariable & {
        unit: MeasurementUnit;
        label: string;
      };

      const manuallyEnteredVariables = props.calculation.variables.filter(
        (v): v is ManuallyEnteredValue =>
          v.type === MeasurementCalculationVariableType.ManuallyEntered && v.unit !== undefined
      );

      const response = await changeBatchMeasurements(props.study, {
        creates: manuallyEnteredVariables.map((v) => ({
          name: MeasurementName.CustomValue,
          customName: v.label,
          value: parseFloat(manualInputs[v.id]),
          unit: v.unit,
        })),
      });

      manuallyEnteredCalculationInputs.push(
        ...removeNullish(response?.createResponses.map((r) => r.measurementValue) ?? []).map(
          (v, i) => ({
            measurementValueId: v.id,
            variableName: manuallyEnteredVariables[i].variableName,
          })
        )
      );
    }

    const measurementCalculationInputs = Object.entries(selectedMeasurementValues)
      .map((v) => ({
        measurementValueId: v[1]?.id ?? null,
        variableName: props.calculation.variables.find((variable) => v[0] === variable.id)
          ?.variableName,
      }))
      .filter(
        (v): v is StudyMeasurementValueCalculationInputCreateRequestDto =>
          v.variableName !== undefined
      );

    const response = await changeBatchMeasurements(props.study, {
      creates: [
        {
          name: props.calculation.outputMeasurementName,
          customName: isCustomMeasurement(props.calculation.outputMeasurementName)
            ? getMeasurementDisplayName(props.calculation.outputMeasurementName, "unindexed")
            : undefined,
          value: null,
          unit: props.calculation.outputMeasurementUnit,
          source: StudyMeasurementValueSource.Calculated,
          calculationId: props.calculation.id,
          calculationInputs: [...measurementCalculationInputs, ...manuallyEnteredCalculationInputs],
        },
      ],
    });

    // Jump to the newly created measurement
    const newMeasurementValue = response?.createResponses[0].measurementValue;
    if (newMeasurementValue) {
      emits("scroll-to-measurement", newMeasurementValue.measurementId);
      emits("highlight-measurement-card", newMeasurementValue.measurementId);
      emits("jump-to-value", newMeasurementValue);
    }
  } catch (e) {
    addNotification({ type: "error", message: "Failed saving calculated measurement" });
    return;
  }

  activeMeasurementCalculation.value = null;
}

const calculationEvaluationErrors = computed(() => {
  if (activeMeasurementCalculation.value?.formula.includes("calculateBiplaneVolume") === true) {
    const inputs = Object.entries(selectedMeasurementValues).reduce((acc, [variableId, value]) => {
      const variableName = props.calculation.variables.find(
        (v) => v.id === variableId
      )?.variableName;

      if (variableName === undefined) {
        return acc;
      }

      return {
        ...acc,
        [variableName]: value?.id ?? "",
      };
    }, {});

    return getBiplaneVolumeCalculationErrors(props.study, inputs);
  }

  return undefined;
});
</script>

<style scoped lang="scss">
.active-calculation-header {
  background-color: var(--button-accented-bg-color);
  padding: 0 8px;
  height: 36px;

  display: flex;
  gap: 8px;
  align-items: center;

  &.top-shadow {
    border-top: 1px solid var(--border-color-1);
    box-shadow: 0 0 8px 4px rgba(black, 0.5);
    clip-path: inset(-15px 0 0 0);
  }
}

.as2 {
  display: flex;
  gap: 8px;
  align-items: center;
}

.active-measurement-calculation {
  background-color: var(--bg-color-2);
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}

.close-icon svg {
  display: block;
  color: var(--text-color-1);
  transition: color 100ms ease;
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
  }
}

.calculation-grid {
  display: grid;
  grid-template-columns: 1fr minmax(70px, max-content) max-content;
  gap: 4px 6px;
  align-items: center;
}

.variable-name {
  display: flex;
  gap: 8px;
  align-items: center;
  transition: color 100ms ease;

  svg {
    font-size: 0.9em;
    color: var(--text-color-1);
    transition: color 100ms ease;
    cursor: pointer;

    &:hover {
      color: var(--text-color-2);
    }

    &.measuring {
      color: var(--confirm-color-2);

      &:hover {
        color: var(--confirm-color-2);
      }
    }
  }

  .jumpable {
    cursor: pointer;

    &:hover {
      color: var(--text-color-2);
    }
  }
}

.manually-entered-value {
  place-self: stretch;
  position: relative;
  height: 24px;

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: right;
  }
}

.patient-metric-value {
  display: grid;
  text-align: right;
  height: 24px;
  align-items: center;
}

.result-divider {
  grid-column: 2 / span 2;
  margin: 12px 0 6px;
  height: 1px;
  background-color: var(--input-focus-border-color);
}

.save-calculation-button {
  height: 28px;
  width: 100px;
}
</style>
