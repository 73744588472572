import Flatten from "@flatten-js/core";
import { v4 as uuidv4 } from "uuid";
import type { ComputedRef, Ref } from "vue";
import { ref, watch } from "vue";
import { isPointInRegion } from "../../../../backend/src/measurements/measurement-helpers";
import type { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import {
  convertMeasurementUnit,
  getInternalUnitForMeasurementName,
  type MeasurementUnit,
} from "../../../../backend/src/measurements/measurement-units";
import { clamp, distance, isPointWithinTolerance } from "../../../../backend/src/shared/math-utils";
import {
  RegionType,
  RegionUnit,
  SpatialFormat,
} from "../../../../backend/src/studies/study-clip-region";
import { StudyMeasurementValueSource } from "../../../../backend/src/studies/study-measurement-enums";
import type { StudyClip, StudyClipRegion, StudyMeasurementValue } from "../../utils/study-data";
import { createFlattenPolygon } from "../measurement-helpers";

export const MEASUREMENT_COLOR = "#4f4";
export const MEASUREMENT_INVALID_COLOR = "#f00";
export const MEASUREMENT_BASAL_COLOR = "#007bff";

export const RESTART_MEASUREMENT_ICON = "undo";

export const TRANSIENT_MEASUREMENT_VALUE_ID = "transient";

export function createTransientValue(
  name: MeasurementName,
  contour: number[] | undefined,
  value: number | null,
  unit: MeasurementUnit,
  frame: number | undefined
): StudyMeasurementValue {
  const newValue = convertMeasurementUnit(value, unit, getInternalUnitForMeasurementName(name));
  if (newValue === null) {
    throw Error("Unable to convert measurement units");
  }

  return {
    id: TRANSIENT_MEASUREMENT_VALUE_ID,
    measurementTool: null,
    measurementCreationBatchId: uuidv4(),
    calculationFormula: null,
    calculationOutputUnit: null,
    calculationVariables: null,
    calculationInputs: [],
    frame: frame ?? null,
    contour: contour ?? null,
    value: newValue,
    source: StudyMeasurementValueSource.Manual,
    selected: true,
    studyClipId: "",
    createdById: "",
    measurementId: "",
    createdAt: null,
    apiKeyName: null,
    lastUpdatedAt: null,
    lastUpdatedById: "",
  };
}

export function checkForMeasurementPointEdits(
  points: number[],
  pt: number[]
): { editingPointIndex: number; editPointOffset: number[] } | undefined {
  for (let i = 0; i < points.length; i += 2) {
    if (!isPointWithinTolerance(pt, points.slice(i, i + 2))) {
      continue;
    }

    return {
      editingPointIndex: i / 2,
      editPointOffset: [pt[0] - points[i], pt[1] - points[i + 1]],
    };
  }

  return undefined;
}

export function drawXMark(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size = 5,
  opacity = 1
): void {
  ctx.globalAlpha = opacity;

  ctx.beginPath();
  ctx.moveTo(x - size, y - size);
  ctx.lineTo(x + size, y + size);
  ctx.moveTo(x + size, y - size);
  ctx.lineTo(x - size, y + size);
  ctx.stroke();

  ctx.globalAlpha = 1;
}

export function drawPlusMark(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size = 5,
  opacity = 1
): void {
  ctx.strokeStyle = MEASUREMENT_COLOR;
  ctx.lineWidth = 2;
  ctx.globalAlpha = opacity;

  ctx.beginPath();
  ctx.moveTo(x - size, y);
  ctx.lineTo(x + size, y);
  ctx.moveTo(x, y - size);
  ctx.lineTo(x, y + size);
  ctx.stroke();

  ctx.globalAlpha = 1;
}

// Helper functions for working with clip regions.

export function findRegionOfPoint(
  clip: StudyClip,
  pt: number[]
): { region: StudyClipRegion; idx: number } | undefined {
  for (const [idx, region] of clip.regions.entries()) {
    if (isPointInRegion(clip, region, pt)) {
      return { region, idx };
    }
  }

  return undefined;
}

/**
 * Takes a list of points and returns a reduced number of points that are close to equidistant from
 * each other. The calculation is done in pixels, not normalized coordinates, because the
 * non-uniform scale of the normalized coordinates causes incorrect equidistance results.
 */
export function getEquidistantPoints(
  normalizedPoints: number[],
  numberOfSamples: number,
  studyClip: { width: number | null; height: number | null }
): number[] {
  if (
    numberOfSamples >= normalizedPoints.length / 2 ||
    studyClip.width === null ||
    studyClip.height === null
  ) {
    return normalizedPoints;
  }

  // Convert normalized points to pixels
  const points: number[] = [];
  for (let i = 0; i < normalizedPoints.length; i++) {
    points.push(normalizedPoints[i] * (i % 2 === 0 ? studyClip.width : studyClip.height));
  }

  const result = [points[0], points[1]];

  let totalLength = 0;
  for (let i = 0; i < points.length - 2; i += 2) {
    totalLength += distance([points[i], points[i + 1]], [points[i + 2], points[i + 3]]);
  }

  const desiredLength = totalLength / (numberOfSamples - 1);

  let currentLength = 0;
  for (let i = 2; i < points.length; i += 2) {
    currentLength += distance([points[i - 2], points[i - 1]], [points[i], points[i + 1]]);

    if (currentLength < desiredLength) {
      continue;
    }

    result.push(points[i], points[i + 1]);
    currentLength -= desiredLength;

    if (result.length / 2 === numberOfSamples - 1) {
      result.push(points[points.length - 2], points[points.length - 1]);
      break;
    }
  }

  // Convert pixels back into normalized coords
  for (let i = 0; i < result.length; i++) {
    result[i] /= i % 2 === 0 ? studyClip.width : studyClip.height;
  }

  return result;
}

export function isMouseNearPoints(mousePt: number[], points: number[]): boolean {
  if (mousePt.length !== 2 || points.length < 6) {
    return true;
  }

  const polygon = createFlattenPolygon(points);
  if (polygon.contains(Flatten.point(mousePt[0], mousePt[1]))) {
    return true;
  }

  for (let i = 0; i < points.length / 2; i++) {
    if (isPointWithinTolerance(mousePt, points.slice(i * 2, i * 2 + 2), 0.025)) {
      return true;
    }
  }

  return false;
}

/**
 * Takes a boolean value and returns a ref that does a linear transition to the corresponding alpha
 * value over ~100ms when the boolean value changes. This is used to animate boolean transitions in
 * the measurement tools.
 */
export function useAlphaTransition(isVisible: ComputedRef<boolean>): Ref<number> {
  const alpha = ref(isVisible.value ? 1 : 0);

  let targetAlpha = alpha.value;
  let timer: number | undefined = undefined;

  function updateAlpha(): void {
    alpha.value = clamp(alpha.value + (alpha.value < targetAlpha ? 0.05 : -0.05), 0, 1);

    // If the target has been reached then clear the timer
    if (alpha.value === targetAlpha) {
      window.clearInterval(timer);
      timer = undefined;
    }
  }

  // When the visibility state changes, update the target alpha and start a timer if one isn't
  // already running
  watch(isVisible, () => {
    targetAlpha = isVisible.value ? 1 : 0;
    timer ??= window.setInterval(updateAlpha, 5);
  });

  return alpha;
}

export function isRegionSpectral(r: StudyClipRegion): boolean {
  return (
    (r.type === RegionType.CWSpectralDoppler || r.type === RegionType.PWSpectralDoppler) &&
    r.yDirectionUnit === RegionUnit.CentimetersPerSecond &&
    r.xDirectionUnit === RegionUnit.Seconds
  );
}

export function isRegionMMode(r: StudyClipRegion): boolean {
  return (
    r.spatialFormat === SpatialFormat.MMode &&
    r.yDirectionUnit === RegionUnit.Centimeters &&
    r.xDirectionUnit === RegionUnit.Seconds
  );
}
