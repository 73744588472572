<template>
  <div
    ref="contextMenuElement"
    class="study-clip-context-menu"
    :class="{ visible: isContextMenuVisible }"
    :style="contextMenuPosition"
    data-testid="study-clip-context-menu"
  >
    <Tooltip :content="downloadTooltip" placement="left">
      <div
        class="context-menu-option"
        :class="{ disabled: !isDownloadPngEnabled || downloadTooltip !== '' }"
        data-testid="download-png"
        @click="onDownloadPngClick"
      >
        Download PNG
      </div>
    </Tooltip>

    <Tooltip :content="downloadTooltip" placement="left">
      <div
        class="context-menu-option"
        :class="{ disabled: !isDownloadMp4Enabled || downloadTooltip !== '' }"
        @click="onDownloadMp4Click"
      >
        Download MP4
      </div>
    </Tooltip>

    <Tooltip :content="downloadDicomTooltip" placement="left">
      <div
        class="context-menu-option"
        :class="{ disabled: downloadDicomTooltip !== '' }"
        data-testid="download-dicom"
        @click="onDownloadDicomClick"
      >
        Download DICOM
      </div>
    </Tooltip>

    <div class="divider" />

    <Tooltip :content="sendToDicomEndpointTooltip" placement="left">
      <a
        class="context-menu-option"
        :class="{ disabled: sendToDicomEndpointTooltip !== '' }"
        data-testid="send-dicom"
        @click="onSendToDicomEndpointClick"
      >
        Send to DICOM Modality
      </a>
    </Tooltip>

    <div class="divider" />

    <Tooltip :content="deleteTooltip" placement="left">
      <a
        class="context-menu-option"
        :class="{ disabled: deleteTooltip !== '' }"
        data-testid="delete-clip"
        @click="onDeleteClick"
      >
        Delete
      </a>
    </Tooltip>
  </div>

  <template v-if="studyClip !== null">
    <StudyClipDownloadMp4Modal
      v-if="isDownloadClipModalVisible"
      :study="study"
      :clip="studyClip"
      @close="isDownloadClipModalVisible = false"
    />

    <StudyClipDeleteModal
      v-if="isDeleteClipModalVisible"
      :study="study"
      :study-clip="studyClip"
      @deleted="emits('clip-deleted', studyClip)"
      @close="isDeleteClipModalVisible = false"
    />

    <DicomEndpointSendModal
      v-if="isDicomEndpointSendModalVisible && studyClip !== null"
      :study="study"
      :study-clip="studyClip"
      @close="isDicomEndpointSendModalVisible = false"
    />
  </template>
</template>

<script setup lang="ts">
import { computePosition, flip } from "@floating-ui/dom";
import { onClickOutside } from "@vueuse/core";
import axios from "axios";
import { saveAs } from "file-saver";
import { computed, reactive, ref } from "vue";
import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import {
  hasDicomEndpointSendPermission,
  hasStudyClipDeletePermission,
  hasStudyUpdatePermission,
  isStudyUpdatePermitted,
} from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import DicomEndpointSendModal from "../components/DicomEndpointSendModal.vue";
import Tooltip from "../components/Tooltip.vue";
import { getStudyClipImageDataUrl } from "../study-view/study-clip-image-data";
import { convertImageUrlToPngDataUrl } from "../utils/image-utils";
import { addNotification } from "../utils/notifications";
import type { Study, StudyClip } from "../utils/study-data";
import StudyClipDeleteModal from "./StudyClipDeleteModal.vue";
import StudyClipDownloadMp4Modal from "./StudyClipDownloadMp4Modal.vue";

interface Props {
  study: Study;
}

interface Emits {
  (event: "clip-deleted", deletedClip: StudyClip): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const studyClip = ref<StudyClip | null>(null);

const contextMenuElement = ref<HTMLElement | null>(null);
const contextMenuPosition = reactive({ left: "0", top: "0" });
const isContextMenuVisible = ref(false);

async function showContextMenu(event: MouseEvent, clip: StudyClip): Promise<void> {
  if (contextMenuElement.value === null) {
    return;
  }

  // Construct a virtual element at the mouse position to use for positioning the context menu
  const { clientX, clientY } = event;
  const virtualElement = {
    getBoundingClientRect: () => ({
      width: 0,
      height: 0,
      x: clientX,
      y: clientY,
      top: clientY,
      left: clientX,
      right: clientX,
      bottom: clientY,
    }),
  };

  // Use floating-ui to determine the position for the context menu
  const { x, y } = await computePosition(virtualElement, contextMenuElement.value, {
    placement: "right-start",
    middleware: [flip()],
  });

  contextMenuPosition.left = `${x}px`;
  contextMenuPosition.top = `${y}px`;
  isContextMenuVisible.value = true;
  studyClip.value = clip;
}

function hideContextMenu(): void {
  isContextMenuVisible.value = false;
}

defineExpose({ showContextMenu, hideContextMenu, studyClip, isContextMenuVisible });

onClickOutside(contextMenuElement, hideContextMenu);

const isDownloadPngEnabled = computed(() => (studyClip.value?.frameCount ?? 0) === 1);

const isDownloadMp4Enabled = computed(
  () => (studyClip.value?.frameCount ?? 0) > 1 && (studyClip.value?.fps ?? 0) > 0
);

const isDownloadClipModalVisible = ref(false);
const isDeleteClipModalVisible = ref(false);
const isDicomEndpointSendModalVisible = ref(false);

const sendToDicomEndpointTooltip = computed(() => {
  if (!currentTenant.hasDicomEndpoints) {
    return "There are no DICOM modalities configured";
  }

  if (!hasDicomEndpointSendPermission.value) {
    return "You do not have permission to send to DICOM modalities";
  }

  if (isFakedSeriesClip.value) {
    return "Sending CT and MR series is not yet supported";
  }

  return "";
});

const downloadTooltip = computed(() => {
  if (isFakedSeriesClip.value) {
    return "Downloading CT and MR series is not yet supported";
  }

  return "";
});

const downloadDicomTooltip = computed(() =>
  studyClip.value?.deidentify === true
    ? "DICOM not available for download due to deidentification"
    : downloadTooltip.value
);

const deleteTooltip = computed(() => {
  if (!hasStudyUpdatePermission.value || !hasStudyClipDeletePermission.value) {
    return "You do not have permission to delete clips";
  }

  if (isFakedSeriesClip.value) {
    return "Deleting CT and MR series is not yet supported";
  }

  if (!isStudyUpdatePermitted(props.study)) {
    return "Cannot delete clips when the report is signed";
  }

  return "";
});

const isFakedSeriesClip = computed(
  () =>
    (studyClip.value?.modality === "MR" || studyClip.value?.modality === "CT") &&
    (studyClip.value.frameCount ?? 0) > 1
);

/**
 * Downloads the :sop-instance-uid.webp-packed file containing a single frame without the header to
 * get the single webP file.
 */
async function extractFrameFromSingleFramePackedWebP(studyClip: StudyClip): Promise<string> {
  if (studyClip.frameCount !== 1) {
    throw Error("Clip frame count must be 1.");
  }

  const url = getStudyClipImageDataUrl(props.study, studyClip, StudyClipImageDataType.AllFrames);
  if (url === null) {
    throw Error("Clip is unavailable for download.");
  }

  // Download the webp-packed file with the header (bytes 0 to 7) stripped to get the single webp
  // file
  const response = await axios.get<ArrayBuffer>(url, {
    responseType: "arraybuffer",
    headers: { Range: "bytes=8-" },
  });

  const blob = new Blob([response.data]);
  return URL.createObjectURL(blob);
}

async function onDownloadPngClick(): Promise<void> {
  if (studyClip.value === null) {
    return;
  }

  hideContextMenu();

  try {
    const allFramesBlobUrl = await extractFrameFromSingleFramePackedWebP(studyClip.value);
    const pngDataUri = await convertImageUrlToPngDataUrl(allFramesBlobUrl);

    if (pngDataUri === null) {
      throw Error();
    }

    saveAs(pngDataUri, `HeartLab - ${studyClip.value.sopInstanceUid}.png`);
    addNotification({ type: "info", message: "Downloaded PNG" });
  } catch (error) {
    addNotification({ type: "error", message: "Failed downloading PNG" });
  }
}

async function onDownloadDicomClick(): Promise<void> {
  if (studyClip.value === null) {
    return;
  }

  try {
    const url = getStudyClipImageDataUrl(
      props.study,
      studyClip.value,
      StudyClipImageDataType.Dicom
    );

    if (url !== null) {
      const response = await axios.get<Blob>(url, { responseType: "blob" });
      saveAs(response.data, `HeartLab - ${studyClip.value.sopInstanceUid}.dcm`);
    }
  } catch {
    addNotification({ type: "error", message: "Failed downloading DICOM file" });
  }

  hideContextMenu();
}

function onDownloadMp4Click(): void {
  isDownloadClipModalVisible.value = true;
  hideContextMenu();
}

function onSendToDicomEndpointClick(): void {
  isDicomEndpointSendModalVisible.value = true;
  hideContextMenu();
}

function onDeleteClick(): void {
  isDeleteClipModalVisible.value = true;
  hideContextMenu();
}
</script>

<style scoped lang="scss">
.study-clip-context-menu {
  position: absolute;
  background-color: var(--bg-color-4);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);

  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 5;
  opacity: 1;
  transition: opacity 100ms ease;

  &:not(.visible) {
    opacity: 0;
    pointer-events: none;
  }
}

.context-menu-option {
  transition: color 100ms ease;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  gap: 12px;
  margin: 0 4px;

  &:hover {
    color: var(--text-color-2);
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}

.divider {
  border-top: 1px solid var(--accent-color-1);
}
</style>
