import { arePointsEqual, isPolygonClosed } from "../shared/math-utils";
import { RegionUnit, type StudyClipRegion } from "../studies/study-clip-region";
import type {
  AssociatedMeasurementValues,
  DopplerSlopeAssociatedMeasurementName,
  VTIAssociatedMeasurementName,
} from "./associated-measurements";
import {
  dopplerSlopeAssociatedMeasurements,
  vtiAssociatedMeasurements,
} from "./associated-measurements";
import { MeasuredValue } from "./measured-value";
import type { MeasurementName } from "./measurement-names";
import { getAreaMeasurementValue } from "./measurement-tool-evaluation";
import {
  MeasurementPhysicalQuantity,
  MeasurementUnit,
  getUnitPhysicalQuantity,
} from "./measurement-units";

/*
 * Doppler tool helper functions
 */

export function getMidlineYPosition(
  region: StudyClipRegion,
  clip: { width: number | null; height: number | null }
): number | undefined {
  return clip.height !== null
    ? ((region.referencePixelY ?? 0) + region.top) / clip.height
    : undefined;
}

export function getYPositionOfPointOnDopplerClip(
  region: StudyClipRegion,
  clip: { width: number | null; height: number | null },
  pt: number[]
): MeasuredValue {
  const midlineY = getMidlineYPosition(region, clip);

  if (
    clip.height === null ||
    midlineY === undefined ||
    pt.length !== 2 ||
    arePointsEqual(pt, [-1, -1])
  ) {
    return MeasuredValue.Null;
  }

  // DICOM spec for ultrasound region units specifies velocity in cm per second for spectral
  // clips, and displacement in cm for M-mode regions. Valid doppler clips will have these units.
  // See https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011/00186024
  const yPositionInCentiUnits = region.physicalDeltaY * (midlineY - pt[1]) * clip.height;

  const unit =
    region.yDirectionUnit === RegionUnit.CentimetersPerSecond
      ? MeasurementUnit.CentimetersPerSecond
      : MeasurementUnit.Centimeters;

  return new MeasuredValue(getUnitPhysicalQuantity(unit), {
    value: yPositionInCentiUnits,
    unit,
  });
}

export function getYPositionForVelocityOnDopplerClip(
  region: StudyClipRegion,
  clip: { width: number | null; height: number | null },
  velocity: MeasuredValue,
  baselineY: number
): number | null {
  if (clip.height === null) {
    return null;
  }

  if (region.yDirectionUnit !== RegionUnit.CentimetersPerSecond) {
    throw Error(`Can't find y-position for velocity on clip with y-unit ${region.yDirectionUnit}`);
  }

  const valueInCentimetersPerSecond = velocity.getValueAsUnit(MeasurementUnit.CentimetersPerSecond);
  if (valueInCentimetersPerSecond === null) {
    return null;
  }

  const yPositionInCentiUnits = valueInCentimetersPerSecond / region.physicalDeltaY;

  return baselineY + yPositionInCentiUnits / clip.height;
}

export function getYDifferenceBetweenPointsOnDopplerClip(
  region: StudyClipRegion,
  clip: { width: number | null; height: number | null },
  pointA: number[],
  pointB: number[]
): MeasuredValue {
  const yPositionOfPointA = getYPositionOfPointOnDopplerClip(region, clip, pointA);
  const yPositionOfPointB = getYPositionOfPointOnDopplerClip(region, clip, pointB);

  return yPositionOfPointB.subtract(yPositionOfPointA).abs();
}

export function getXDifferenceBetweenPointsOnDopplerClip(
  region: StudyClipRegion,
  clip: { width: number | null; height: number | null },
  pointA: number[],
  pointB: number[]
): MeasuredValue {
  if (pointB[0] === -1 || clip.width === null) {
    return MeasuredValue.Null;
  }

  return new MeasuredValue(MeasurementPhysicalQuantity.Time, {
    value: Math.abs(pointB[0] - pointA[0]) * clip.width * region.physicalDeltaX,
    unit: MeasurementUnit.Seconds,
  });
}

export function getDopplerSlopeMeasuredValue(
  yMeasuredValue: MeasuredValue,
  timeMeasuredValue: MeasuredValue
): MeasuredValue {
  return yMeasuredValue.abs().divide(timeMeasuredValue);
}

/**
 * Calculates an estimated pressure gradient from a velocity using the simplified Bernoulli
 * equation. Note that this is an **estimate** based on several assumptions about the nature of the
 * measured velocity, and is commonly used in clinical practice.
 *
 * See https://ecgwaves.com/topic/the-bernoulli-principle-and-calculation-of-pressure-difference-pressure-gradient/
 * for details on the derivation.
 */
export function calculateGradientFromVelocity(velocity: MeasuredValue): MeasuredValue {
  if (velocity.physicalQuantity !== MeasurementPhysicalQuantity.Velocity) {
    throw Error("Invalid physical quantity to calculate gradient with");
  }

  const velocityInMetersPerSecond = velocity.getValueAsUnit(MeasurementUnit.MetersPerSecond);

  return new MeasuredValue(
    MeasurementPhysicalQuantity.Pressure,
    velocityInMetersPerSecond !== null
      ? {
          value: 4 * velocityInMetersPerSecond ** 2,
          unit: MeasurementUnit.MillimetersOfMercury,
        }
      : null
  );
}

/**
 * Slope tool and associated measurements
 */
export interface BaseSlopeCalculation {
  yMeasuredValue: MeasuredValue;
  timeMeasuredValue: MeasuredValue;
  slopeMeasuredValue: MeasuredValue;
}

interface PressureHalfTimeCalculation {
  pressureHalfTime: MeasuredValue;
  contour: number[];
}

export function calculateBaseSlopeDetails(
  studyClip: { width: number | null; height: number | null } | undefined,
  region: StudyClipRegion | undefined,
  pointA: number[],
  pointB: number[]
): BaseSlopeCalculation {
  if (studyClip === undefined || region === undefined) {
    return {
      yMeasuredValue: MeasuredValue.Null,
      timeMeasuredValue: MeasuredValue.Null,
      slopeMeasuredValue: MeasuredValue.Null,
    };
  }

  // Neither negative acceleration, negative velocity, or negative displacement appear to be used
  // in practice. Even though deceleration slope and similar measurements are used as measurement
  // names, their values appear to be stored as positive and their sign is conveyed by the chosen
  // measurement name. It's possible this assumption may not hold for all measurements though.

  // This is the displacement measurement in a motion mode slope measurement.
  const yMeasuredValue = getYDifferenceBetweenPointsOnDopplerClip(
    region,
    studyClip,
    pointA,
    pointB
  );

  const timeMeasuredValue = getXDifferenceBetweenPointsOnDopplerClip(
    region,
    studyClip,
    pointA,
    pointB
  );

  // This measurement value is an acceleration in doppler and velocity in motion mode.
  const slopeMeasuredValue = getDopplerSlopeMeasuredValue(yMeasuredValue, timeMeasuredValue);

  return {
    yMeasuredValue,
    timeMeasuredValue,
    slopeMeasuredValue,
  };
}

export function calculateVTIMeasuredValue(
  studyClip: { width: number | null; height: number | null } | undefined,
  region: StudyClipRegion | undefined,
  points: number[]
): MeasuredValue {
  if (!studyClip || !region || !isPolygonClosed(points)) {
    return MeasuredValue.Null;
  }

  return getAreaMeasurementValue(points, studyClip, region);
}

export function calculatePressureHalfTime(
  baseSlopeCalculation: BaseSlopeCalculation,
  studyClip: { width: number | null; height: number | null } | undefined,
  region: StudyClipRegion | undefined,
  pointA: number[],
  pointB: number[]
): PressureHalfTimeCalculation | undefined {
  if (
    baseSlopeCalculation.slopeMeasuredValue.isNull() ||
    studyClip === undefined ||
    studyClip.width === null ||
    region === undefined
  ) {
    return undefined;
  }

  // PHT velocity is defined by the max velocity divided by root 2
  const phtVelocity = baseSlopeCalculation.yMeasuredValue.divideByConstant(Math.sqrt(2));

  // Calculate the length from envelope start to the slope line at the PHT velocity
  const phtInSeconds = baseSlopeCalculation.yMeasuredValue
    .subtract(phtVelocity)
    .abs()
    .divide(baseSlopeCalculation.slopeMeasuredValue);

  const isAboveMidline =
    (getYPositionOfPointOnDopplerClip(region, studyClip, pointA).getInternalValueAndUnit()?.value ??
      0) <
    (getYPositionOfPointOnDopplerClip(region, studyClip, pointB).getInternalValueAndUnit()?.value ??
      0);

  // Convert the PHT velocity to a height into a canvas position ratio
  const signedVelocity = phtVelocity.multiplyByConstant(isAboveMidline ? -1 : 1);
  const phtHeight =
    getYPositionForVelocityOnDopplerClip(region, studyClip, signedVelocity, pointA[1]) ?? 0;

  // Convert the PHT width into a canvas position ratio
  const isFacingRight = pointA[0] > pointB[0];
  const phtLength = phtInSeconds
    .divideByConstant(region.physicalDeltaX)
    .divideByConstant(studyClip.width);

  // Find the x-position the PHT line intersects with the slope line
  const phtIntersectionX =
    pointB[0] + (isFacingRight ? 1 : -1) * (phtLength.getValueAsUnit(MeasurementUnit.Seconds) ?? 0);

  return {
    pressureHalfTime: phtInSeconds,
    contour: [pointB[0], phtHeight, phtIntersectionX, phtHeight],
  };
}

export function getDopplerSlopeAssociatedMeasurementValues(
  measurementName: MeasurementName,
  baseSlopeCalculation: BaseSlopeCalculation,
  phtCalculation: PressureHalfTimeCalculation | undefined,
  pointA: number[],
  pointB: number[]
): AssociatedMeasurementValues<DopplerSlopeAssociatedMeasurementName> {
  const associatedNames = dopplerSlopeAssociatedMeasurements[measurementName];
  if (associatedNames === undefined) {
    return {};
  }

  const result: AssociatedMeasurementValues<DopplerSlopeAssociatedMeasurementName> = {};

  const slopeToolUnitAndValue = baseSlopeCalculation.yMeasuredValue.getInternalValueAndUnit();
  if (slopeToolUnitAndValue) {
    result.peakVelocity = {
      ...slopeToolUnitAndValue,
      name: associatedNames.peakVelocity,
      contour: pointB,
    };
  }

  const timeUnitAndValue = baseSlopeCalculation.timeMeasuredValue.getInternalValueAndUnit();
  if (timeUnitAndValue) {
    result.time = {
      ...timeUnitAndValue,
      name: associatedNames.time,
      contour: [...pointA, pointB[0], pointA[1]],
    };
  }

  const phtTimeAndValue = phtCalculation?.pressureHalfTime.getInternalValueAndUnit();
  if (
    phtTimeAndValue &&
    phtCalculation?.contour &&
    associatedNames.pressureHalfTime !== undefined
  ) {
    result.pressureHalfTime = {
      ...phtTimeAndValue,
      name: associatedNames.pressureHalfTime,
      contour: phtCalculation.contour,
    };

    const phtInMilliseconds = phtCalculation.pressureHalfTime.getValueAsUnit(
      MeasurementUnit.Milliseconds
    );

    if (associatedNames.areaByPressureHalfTime !== undefined && phtInMilliseconds !== null) {
      const areaByPressureHalfTime = new MeasuredValue(MeasurementPhysicalQuantity.Area, {
        value: 220 / phtInMilliseconds,
        unit: MeasurementUnit.CentimetersSquared,
      });

      const areaByPHTUnitAndValue = areaByPressureHalfTime.getInternalValueAndUnit();

      if (areaByPHTUnitAndValue) {
        result.areaByPressureHalfTime = {
          ...areaByPHTUnitAndValue,
          name: associatedNames.areaByPressureHalfTime,
        };
      }
    }
  }

  return result;
}

// eslint-disable-next-line max-statements
export function getVTIAssociatedMeasurementValues(
  measurementName: MeasurementName,
  studyClip: { width: number | null; height: number | null } | undefined,
  region: StudyClipRegion | undefined,
  vtiMeasuredValue: MeasuredValue,
  contour: number[]
): AssociatedMeasurementValues<VTIAssociatedMeasurementName> {
  const associatedNames = vtiAssociatedMeasurements[measurementName];
  if (
    associatedNames === undefined ||
    studyClip === undefined ||
    region === undefined ||
    studyClip.width === null ||
    !isPolygonClosed(contour)
  ) {
    return {};
  }

  const result: AssociatedMeasurementValues<VTIAssociatedMeasurementName> = {};

  // Determine peak velocity by looking at all the points in the contour. Note that the absolute
  // value of the velocity measurement is taken here.
  const velocities: MeasuredValue[] = [];
  for (let i = 0; i < contour.length; i += 2) {
    const velocity = getYPositionOfPointOnDopplerClip(region, studyClip, contour.slice(i, i + 2));
    if (velocity.isNull()) {
      continue;
    }

    velocities.push(velocity.abs());
  }

  const peakVelocityMeasuredValue = velocities
    .slice(1)
    .reduce(
      (curr, next) =>
        (curr.getInternalValueAndUnit()?.value ?? 0) > (next.getInternalValueAndUnit()?.value ?? 0)
          ? curr
          : next,
      velocities[0]
    );

  const peakVelocityPointIndex = velocities.indexOf(peakVelocityMeasuredValue) * 2;

  const totalTimeInSeconds =
    Math.abs(contour[contour.length - 4] - contour[0]) * studyClip.width * region.physicalDeltaX;

  const accelerationTimeInSeconds =
    Math.abs(contour[peakVelocityPointIndex] - contour[0]) *
    studyClip.width *
    region.physicalDeltaX;

  const accelerationTimeContour = [
    ...contour.slice(0, 2),
    contour[peakVelocityPointIndex],
    contour[1],
  ];

  const accelerationTime = new MeasuredValue(MeasurementPhysicalQuantity.Time, {
    value: accelerationTimeInSeconds,
    unit: MeasurementUnit.Seconds,
  });

  const meanVelocity =
    (vtiMeasuredValue.getValueAsUnit(MeasurementUnit.Meters) ?? 0) / totalTimeInSeconds;

  const meanVelocityMeasuredValue = new MeasuredValue(MeasurementPhysicalQuantity.Velocity, {
    value: meanVelocity,
    unit: MeasurementUnit.MetersPerSecond,
  });

  const peakGradient = calculateGradientFromVelocity(peakVelocityMeasuredValue);
  const meanGradient = calculateGradientFromVelocity(meanVelocityMeasuredValue);

  const accelerationTimeValueAndUnit = accelerationTime.getInternalValueAndUnit();
  if (accelerationTimeValueAndUnit) {
    result.accelerationTime = {
      ...accelerationTimeValueAndUnit,
      name: associatedNames.accelerationTime,
      contour: accelerationTimeContour,
    };
  }

  const peakGradientValueAndUnit = peakGradient.getInternalValueAndUnit();
  if (peakGradientValueAndUnit) {
    result.peakGradient = {
      ...peakGradientValueAndUnit,
      name: associatedNames.peakGradient,
    };
  }

  const peakVelocityValueAndIndex = peakVelocityMeasuredValue.getInternalValueAndUnit();
  if (peakVelocityValueAndIndex) {
    result.peakVelocity = {
      ...peakVelocityValueAndIndex,
      name: associatedNames.peakVelocity,
      contour: contour.slice(peakVelocityPointIndex, peakVelocityPointIndex + 2),
    };
  }

  const meanGradientValueAndIndex = meanGradient.getInternalValueAndUnit();
  if (meanGradientValueAndIndex) {
    result.meanGradient = {
      ...meanGradientValueAndIndex,
      name: associatedNames.meanGradient,
    };
  }

  const meanVelocityValueAndIndex = meanVelocityMeasuredValue.getInternalValueAndUnit();
  if (meanVelocityValueAndIndex) {
    result.meanVelocity = {
      ...meanVelocityValueAndIndex,
      name: associatedNames.meanVelocity,
    };
  }

  return result;
}
