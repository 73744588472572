<template>
  <div class="horizontal-clip-list">
    <OverlayScrollbar direction="x">
      <div class="clip-thumbnails">
        <div v-for="(clip, clipIndex) in validClips" :key="clip.id">
          <ClipThumbnail
            v-if="clip"
            :key="clip.id"
            style="width: 80px"
            :study="study"
            :clip="clip"
            :clip-number="validClips.indexOf(clip) + 1"
            :clip-count="validClips.length"
            :data-testid="`clip-thumbnail-${clipIndex}`"
            :is-selected="selectedClipIds.includes(clip.id)"
            :is-selected-on-secondary-window="false"
            :context-menu-clip-id="null"
            @click="emits('clip-thumbnail-click', clip)"
            @measurement-icon-click="
              (measurementValue) => emits('measurement-icon-click', measurementValue)
            "
          />
        </div>
      </div>
    </OverlayScrollbar>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import OverlayScrollbar from "../../components/OverlayScrollbar.vue";
import { Study, StudyClip, StudyMeasurementValue } from "../../utils/study-data";
import ClipThumbnail from "../ClipThumbnail.vue";
import { getValidClips } from "../study-clip-helpers";

interface Props {
  study: Study;
  selectedClipIds: string[];
}

interface Emits {
  (event: "clip-thumbnail-click", clip: StudyClip): void;
  (event: "measurement-icon-click", measurementValue: StudyMeasurementValue): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const validClips = computed(() => getValidClips(props.study));
</script>

<style scoped lang="scss">
.horizontal-clip-list {
  min-width: 0;
  display: flex;
  min-height: 0;
  height: 80px;
  width: 100%;
  position: relative;
}

.clip-thumbnails {
  display: flex;
  gap: 0.5px 1px;
}
</style>
