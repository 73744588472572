<template>
  <DropdownWidget
    :model-value="modelValue"
    :items="items"
    :placeholder="placeholder"
    :disabled="disabled || isUpdating"
    :data-test-selected-type="modelValue"
    @update:model-value="changeStudyType($event as StudyType)"
  />
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, ref } from "vue";
import {
  StudyType,
  studyTypeDisplayName,
  studyTypesForModality,
} from "../../../backend/src/studies/study-type";
import { addNotification } from "../utils/notifications";
import type { Study } from "../utils/study-data";
import DropdownWidget from "./DropdownWidget.vue";

interface Props {
  modelValue: string;
  placeholder?: string;
  disabled?: boolean;

  // When set, changes to the study type will be saved directly onto this study
  targetStudy?: Study | null;
}

interface Emits {
  (name: "update:modelValue", newValue: string): void;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  studyId: "",
  disabled: false,
  targetStudy: null,
});

const emits = defineEmits<Emits>();

const items = computed(() => {
  const allStudyTypes = Object.entries(studyTypeDisplayName).map(([value, text]) => ({
    value,
    text,
  }));

  const modalityStudyTypes = studyTypesForModality[props.targetStudy?.modality ?? ""];
  if (modalityStudyTypes === undefined) {
    return allStudyTypes;
  }

  return [
    {
      value: StudyType.NotSpecified,
      text: studyTypeDisplayName[StudyType.NotSpecified],
    },
    ...allStudyTypes.filter((type) => modalityStudyTypes.includes(type.value as StudyType)),
  ];
});

const isUpdating = ref(false);

async function changeStudyType(newStudyType: StudyType): Promise<void> {
  emits("update:modelValue", newStudyType);

  if (props.targetStudy === null) {
    return;
  }

  isUpdating.value = true;

  // eslint-disable-next-line vue/no-mutating-props
  props.targetStudy.type = newStudyType;

  try {
    await axios.patch(`/api/studies/${props.targetStudy.id}`, { type: newStudyType });
  } catch {
    addNotification({ type: "error", message: "Failed changing study type" });
    return;
  } finally {
    isUpdating.value = false;
  }

  addNotification({ type: "info", message: "Changed study type" });
}
</script>
