<template>
  <Modal
    title="Add SFTP Integration"
    :activity-text="activityText"
    @header-button-click="emits('close')"
    @enter-key-press="addIntegration"
  >
    <div class="content">
      <div class="field">
        <b>Integration Name</b>
        <input
          ref="nameInputElement"
          v-model="integrationName"
          type="text"
          data-testid="sftp-name-input"
        />
      </div>

      <div class="field">
        <b>Endpoint</b>
        <input
          v-model="endpoint"
          type="text"
          placeholder="IP address or DNS name"
          data-testid="sftp-endpoint-input"
        />
      </div>

      <div class="field">
        <b>Port</b>
        <input v-model="port" type="number" data-testid="sftp-port-input" />
      </div>

      <div class="field">
        <b>Username</b>
        <input v-model="user" type="text" data-testid="sftp-user-input" />
      </div>
      <div class="field">
        <b>Remote Target Prefix</b>
        <input
          v-model="remoteTargetPrefix"
          type="text"
          placeholder="Optional"
          data-testid="sftp-target-prefix-input"
        />
      </div>

      <div class="field">
        <b>Private Key (in PEM format)</b>
        <textarea v-model="privateKey" data-testid="sftp-private-key-input" />
      </div>

      <button
        class="accented"
        style="align-self: center"
        :disabled="!isComplete"
        data-testid="sftp-add"
        @click="addIntegration"
      >
        Create SFTP Integration
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";

interface Emits {
  (event: "close"): void;
  (event: "integration-added"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const integrationName = ref("");
const endpoint = ref("");
const port = ref(22);
const user = ref("");
const remoteTargetPrefix = ref("");
const privateKey = ref("");

const nameInputElement = ref<HTMLInputElement>();
useFocus(nameInputElement, { initialValue: true });

const isComplete = computed(
  () =>
    integrationName.value !== "" &&
    endpoint.value !== "" &&
    user.value !== "" &&
    privateKey.value !== ""
);

async function addIntegration(): Promise<void> {
  if (!isComplete.value) {
    return;
  }

  activityText.value = "Adding integration";

  try {
    await axios.post(`/api/integrations/sftp`, {
      name: integrationName.value,
      endpoint: endpoint.value,
      port: port.value,
      user: user.value,
      remoteTargetPrefix: remoteTargetPrefix.value,
      privateKey: privateKey.value,
    });
  } catch {
    addNotification({ type: "error", message: `Error adding SFTP integration` });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Added SFTP integration` });

  emits("integration-added");
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

textarea {
  height: 60px;
  resize: none;
}
</style>
