import { getDrawableDistanceMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { RegionUnit } from "../../../../../backend/src/studies/study-clip-region";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import type { MeasurementTool } from "../measurement-tool";
import { createBaseLinearMeasurementTool } from "./measurement-base-linear";

export function createDistanceMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  return {
    displayName: "Distance Measurement",

    isMeasurableOnStudyClip: isDistanceMeasurableOnStudyClip,
    getCreatableMeasurementNames: getDrawableDistanceMeasurements,

    ...createBaseLinearMeasurementTool(study, studyClipId, region, {
      customMeasurementName: MeasurementName.CustomDistance,
      tool: MeasurementToolName.Distance,
      isXAxisLocked: false,
      isYAxisLocked: false,
    }),
  };
}

export function isDistanceMeasurableOnStudyClip(
  clip: StudyClip,
  region?: StudyClipRegion
): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some(
    (r) =>
      r.xDirectionUnit === RegionUnit.Centimeters && r.yDirectionUnit === RegionUnit.Centimeters
  );
}
