<template>
  <div
    class="structured-report-field"
    :class="{
      'single-column': section.type === 'table',
      overridable:
        (field.type === 'measurement' || field.type === 'text') &&
        field.isEditable &&
        !(isTextbox(field) || isTextOverridden),
    }"
    @click="onFieldClick"
  >
    <!-- Field names are always hidden on tables. -->
    <div
      v-if="section.type !== 'table'"
      class="rpt-text-normal"
      style="word-wrap: break-word; align-self: center"
    >
      {{ field.name }}
    </div>

    <div
      v-if="isTextbox(field) || isTextOverridden"
      class="rpt-fancy-input"
      style="background: white; display: grid; grid-template-columns: 1fr min-content; gap: 0.8em"
      :style="{ ...fieldCss, alignSelf: undefined }"
    >
      <ResizingTextbox
        v-model="textContent"
        class="rpt-text-normal"
        :scale-factor="scaleFactor"
        :text-align="field.cellAlignment?.horizontal"
        :color="field.styling.color"
        :font-weight="fieldCss.fontWeight"
        :font-style="fieldCss.fontStyle"
        :initial-focus="initialFocus"
      />
      <Tooltip content="Revert to default text">
        <FontAwesomeIcon
          v-if="isTextOverridden"
          class="revert-text-override-arrow"
          icon="rotate-left"
          @click.stop="isTextOverridden = false"
        />
      </Tooltip>
    </div>

    <div
      v-else-if="field.type === 'measurement' || field.type === 'text'"
      class="rpt-text-normal"
      style="width: 100%"
      :style="{ ...fieldCss, alignSelf: undefined }"
    >
      {{ getReportFieldDisplayText(study, report, props.calculationScope, sectionId, field) }}
    </div>

    <ReportDropdown
      v-else-if="field.type === 'dropdown'"
      v-model="optionIds"
      v-model:custom-text="textContent"
      :initial-focus="initialFocus"
      :enabled="isStudyUpdatePermitted(study)"
      :field="field"
      :scale-factor="scaleFactor"
      :data-testid="`rpt-dropdown-${field.name}`"
      @enter="emits('enter')"
    />
  </div>
</template>

<script setup lang="ts">
import ResizingTextbox from "@/components/ResizingTextbox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { ReportSectionContent } from "../../../backend/src/reporting/report-content";
import { ReportSectionStructuredField } from "../../../backend/src/reporting/report-structure";
import { isStudyUpdatePermitted } from "../auth/authorization";
import Tooltip from "../components/Tooltip.vue";
import type { Study, StudyReport } from "../utils/study-data";
import ReportDropdown from "./ReportDropdown.vue";
import { CalculationScope } from "./report-calculation-scope";
import {
  getFieldAlignmentCSS,
  getFieldStylingCSS,
  getReportFieldDisplayText,
  isTextbox,
} from "./report-content";

interface Props {
  study: Study;
  report: StudyReport;
  calculationScope: CalculationScope;
  sectionId: string;
  field: ReportSectionStructuredField;
  sectionContent: ReportSectionContent;
  scaleFactor: number;
  initialFocus: boolean;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "enter"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const section = computed(() => {
  const result = props.report.reportTemplateVersion.structure.sections.find(
    (s) => s.id === props.sectionId
  );

  if (result === undefined) {
    throw Error(`Section with id ${props.sectionId} is not present in the report template`);
  }

  return result;
});

const fieldContent = computed(() => props.sectionContent.structuredFields[props.field.id]);

const fieldCss = computed(() => ({
  ...getFieldStylingCSS(props.field),
  ...getFieldAlignmentCSS(section.value, props.field),
}));

const optionIds = computed({
  get() {
    return fieldContent.value.optionIds;
  },
  set(newValue: string[]) {
    fieldContent.value.optionIds = newValue;

    // When going into 'custom' set the initial value from the "custom" option's sentence
    if (
      props.field.type === "dropdown" &&
      newValue.includes("custom") &&
      textContent.value === ""
    ) {
      textContent.value = props.field.options.find((opt) => opt.id === "custom")?.sentence ?? "";
    }

    emits("update-report-content");
  },
});

const textContent = computed({
  get() {
    return props.sectionContent.structuredFields[props.field.id].text;
  },
  set(newValue: string) {
    fieldContent.value.text = newValue;
    emits("update-report-content");
  },
});

const isTextOverridden = computed({
  get() {
    return fieldContent.value.isTextOverriden;
  },

  set(newValue: boolean) {
    fieldContent.value.isTextOverriden = newValue;
    emits("update-report-content");
  },
});

function onFieldClick(): void {
  if (
    (props.field.type === "text" || props.field.type === "measurement") &&
    props.field.isEditable
  ) {
    fieldContent.value.text = getReportFieldDisplayText(
      props.study,
      props.report,
      props.calculationScope,
      props.sectionId,
      props.field
    );
    isTextOverridden.value = true;
  }
}
</script>

<style scoped lang="scss">
.structured-report-field {
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  &.single-column {
    grid-template-columns: 1fr;
  }

  &.overridable {
    cursor: pointer;
    min-height: 1em;
  }
}

.revert-text-override-arrow {
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 1;
  }
}
</style>
