<template>
  <div
    class="header"
    :class="{ clickable: isEditingReportContent }"
    :data-testid="`rpt-section-header-${section.name}`"
    @click="emits('done')"
  >
    <template v-if="isEditingReportContent">
      <strong class="rpt-text-normal">{{ section.name }}</strong>
      <span class="done-button"> <FontAwesomeIcon icon="times" /></span>
    </template>

    <div
      v-else-if="isViewingReportStructure"
      class="rpt-text-normal rpt-fancy-input section-name-textbox"
      :class="{
        editable: isEditingReportStructure,
        disabled: !isEditingReportStructure,
        'show-buttons': isSectionNameInputFocused,
      }"
      :style="section.type === 'heading' ? { fontSize: 'var(--report-font-size-heading)' } : {}"
    >
      <input
        ref="sectionNameInputElement"
        v-model="section.name"
        :placeholder="`${getSectionType()} name`"
        :disabled="!isEditingReportStructure"
        @update:model-value="emits('mutate-structure', createSectionNameChangeMutation(section.id))"
      />
      <div v-if="isEditingReportStructure" class="edit-structure-buttons" @click.stop>
        <Tooltip v-if="sections[0] !== section" :content="`Move ${getSectionType()} up`">
          <FontAwesomeIcon icon="arrow-up" class="icon" @click="onMoveSection(-1)" />
        </Tooltip>
        <Tooltip
          v-if="sections[sections.length - 1] !== section"
          :content="`Move ${getSectionType()} down`"
        >
          <FontAwesomeIcon icon="arrow-down" class="icon" @click="onMoveSection(1)" />
        </Tooltip>
        <Tooltip v-if="!(section.type === 'heading')" content="Duplicate section">
          <FontAwesomeIcon icon="copy" class="icon" @click="onDuplicateSection" />
        </Tooltip>
        <Tooltip :content="`Delete ${getSectionType()}`">
          <FontAwesomeIcon icon="trash" class="icon" @click="onDeleteSection" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import { computed, ref } from "vue";
import Tooltip from "../components/Tooltip.vue";
import { Study, StudyReport } from "../utils/study-data";
import { ReportContentMode } from "./report-content";
import {
  createSectionDuplicateMutation,
  createSectionMoveMutation,
  createSectionNameChangeMutation,
  createSectionRemoveMutation,
  type ReportStructureMutation,
} from "./report-structure-mutations";

interface Props {
  study: Study;
  report: StudyReport;
  sectionId: string;
  mode: ReportContentMode;
}

interface Emits {
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
  (event: "done"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const structure = computed(() => props.report.reportTemplateVersion.structure);

const sections = computed(() => structure.value.sections);

const section = computed(() => {
  const result = sections.value.find((s) => s.id === props.sectionId);

  if (result === undefined) {
    throw Error(`Section with id ${props.sectionId} is not present in the report template`);
  }

  return result;
});

const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);
const isViewingReportStructure = computed(
  () =>
    props.mode === ReportContentMode.ViewReportStructure ||
    props.mode === ReportContentMode.EditReportStructure
);
const isEditingReportStructure = computed(() => {
  return props.mode === ReportContentMode.EditReportStructure;
});

const sectionNameInputElement = ref<HTMLInputElement>();
const { focused: isSectionNameInputFocused } = useFocus(sectionNameInputElement, {
  initialValue: true,
});

function getSectionType(): string {
  if (section.value.type === "rwma") {
    return "RWMA";
  }
  return section.value.type === "heading" ? "Heading" : "Section";
}

function onMoveSection(indexDelta: -1 | 1): void {
  const index = sections.value.indexOf(section.value);
  if (
    (index === 0 && indexDelta === -1) ||
    (index === sections.value.length - 1 && indexDelta === 1)
  ) {
    return;
  }

  emits(
    "mutate-structure",
    createSectionMoveMutation(index, (index + indexDelta) % (sections.value.length + 1))
  );
}

function onDeleteSection(): void {
  emits("mutate-structure", createSectionRemoveMutation(section.value.id));
}

function onDuplicateSection(): void {
  emits("mutate-structure", createSectionDuplicateMutation(section.value.id));
}
</script>

<style scoped lang="scss">
.header {
  display: flex;

  &.clickable {
    cursor: pointer;
  }
}

.done-button {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--report-text-color-1);
  transition: color 100ms ease;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: var(--report-text-color-2);
  }
}

.edit-structure-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8em;
  margin-right: 0.2em;
  opacity: 0;
  transition: opacity 100ms ease;
}
.icon {
  color: var(--report-accent-color-1);
  cursor: pointer;

  &:hover {
    color: var(--report-accent-color-2) !important;
  }
}

.section-name-textbox {
  flex: 1;
  margin: -0.7em -0.5em 0.2em;
  background: white !important;
  display: flex;
  align-items: center;
  padding-top: 0.2em !important;

  input {
    font-weight: bold;
    color: var(--report-text-color-1);
    flex: 1;
  }

  &:hover.editable,
  &.show-buttons {
    .edit-structure-buttons {
      opacity: 1;
    }

    input {
      color: var(--report-text-color-2);
    }
  }
}
</style>
