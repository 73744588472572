/* eslint-disable max-statements */

import { computed, ref, watch } from "vue";
import type { VTIAssociatedMeasurementName } from "../../../../../backend/src/measurements/associated-measurements";
import { vtiAssociatedMeasurements } from "../../../../../backend/src/measurements/associated-measurements";
import { getDrawableVTIMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import {
  calculateVTIMeasuredValue,
  getVTIAssociatedMeasurementValues,
} from "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { MeasurementUnit } from "../../../../../backend/src/measurements/measurement-units";
import { isPointWithinTolerance } from "../../../../../backend/src/shared/math-utils";
import { RegionType, RegionUnit } from "../../../../../backend/src/studies/study-clip-region";
import { getClips } from "../../../../../backend/src/studies/study-helpers";
import { currentTenant } from "../../../auth/current-session";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import { findSavedAssociatedMeasurements } from "../../measurement-helpers";
import {
  buildMeasurementToolBatchChangeRequest,
  generateAssociatedMeasurementRefs,
  setAssociatedMeasurementDetailsOnEdit,
  setDefaultEnabledForAssociatedMeasurements,
} from "../../measurement-tool-helpers";
import { restartMeasuring } from "../../measurement-tool-state";
import {
  createBaseContourMeasurement,
  isPolygonSelfIntersecting,
} from "../measurement-base-contour";
import type {
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
} from "../measurement-tool";
import {
  MeasurementCreationMethod,
  type AssociatedMeasurement,
  type MeasurementLabel,
  type MeasurementTool,
  type ToolbarItem,
} from "../measurement-tool";
import { getAreaMeasurementLabelPosition } from "../measurement-tool-area";
import {
  RESTART_MEASUREMENT_ICON,
  createTransientValue,
  drawPlusMark,
} from "../measurement-tool-helpers";
import {
  measurementIncompleteTooltipText,
  measurementIntersectingTooltipText,
} from "../measurement-tooltips";
import { createBaseDopplerMeasurement } from "./measurement-base-doppler";

/**
 * Creates a new velocity-time integral measurement tool instance for the given study and region.
 */
export function createVTIMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomVelocityTimeIntegral;
  const customName = ref("");

  const baseMeasurementCreationRequest = {
    tool: MeasurementToolName.VelocityTimeIntegral,
    studyClipId,
  };

  const frame = ref(0);

  const lastMousePosition = ref<number[]>([]);

  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const baseContourMeasurement = createBaseContourMeasurement(
    studyClip,
    region,
    lastMousePosition,
    currentTenant.isVTIMeasurementAutomaticResamplingEnabled
  );

  const baseDopplerMeasurement = createBaseDopplerMeasurement(studyClip, region);

  const vtiMeasuredValue = computed(() =>
    calculateVTIMeasuredValue(studyClip, region, baseContourMeasurement.points)
  );

  const isComplete = computed(() => {
    if (baseContourMeasurement.points.length < 6) {
      return false;
    }

    return (
      baseContourMeasurement.points[1] ===
      baseContourMeasurement.points[baseContourMeasurement.points.length - 1]
    );
  });

  const isSaveButtonEnabled = computed(() => saveButtonTooltip.value === "");

  const saveButtonTooltip = computed(() => {
    if (isPolygonSelfIntersecting(baseContourMeasurement.points)) {
      return measurementIntersectingTooltipText;
    }

    return isComplete.value ? "" : measurementIncompleteTooltipText;
  });

  const helpText = computed(() => {
    if (region === undefined) {
      return "Select the doppler clip region";
    }

    return "Click to place points";
  });

  const toolbarItems = computed((): ToolbarItem[] => {
    const items: ToolbarItem[] = [
      {
        text: vtiMeasuredValue.value.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
      {
        text: "Resample",
        enabled: isComplete.value,
        onClick: baseContourMeasurement.resamplePoints,
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: (): void => {
          restartMeasuring({ studyClipId: undefined, region: undefined });
        },
      });
    }

    return items;
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (!isComplete.value) {
      return [];
    }

    const position = getAreaMeasurementLabelPosition({
      canvas,
      points: baseContourMeasurement.points,
    });

    const labels: MeasurementLabel[] = [];

    // Add label for the main VTI measurement
    if (!vtiMeasuredValue.value.isNull()) {
      labels.push({
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: vtiMeasuredValue.value.createTransientValue(
          customMeasurementName,
          baseContourMeasurement.points,
          frame.value
        ),
        x: position.x,
        y: position.y,
      });
    }

    // Add label for the peak velocity
    const peakVelocity = associatedMeasurements.value.peakVelocity;
    if (peakVelocity?.enabled.value === true && peakVelocity.contour !== undefined) {
      labels.push({
        measurementName: MeasurementName.CustomVelocity,
        measurementValue: createTransientValue(
          MeasurementName.CustomVelocity,
          peakVelocity.contour,
          peakVelocity.value,
          MeasurementUnit.MetersPerSecond,
          frame.value
        ),
        x: peakVelocity.contour[0] * canvas.width + 20,
        y: peakVelocity.contour[1] * canvas.height,
      });
    }

    return labels;
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    if (
      baseDopplerMeasurement.midline.value === undefined ||
      region === undefined ||
      studyClip === undefined
    ) {
      return;
    }

    baseDopplerMeasurement.drawMidline(
      canvas,
      ctx,
      baseContourMeasurement.points.length === 0 ? lastMousePosition.value : []
    );

    if (baseContourMeasurement.points.length >= 0) {
      if (isPointBeyondMidline(lastMousePosition.value)) {
        lastMousePosition.value = [
          lastMousePosition.value[0],
          baseDopplerMeasurement.midline.value[1],
        ];
      }

      baseContourMeasurement.drawToCanvas(canvas, ctx);
    }

    // Peak velocity
    const peakVelocity = associatedMeasurements.value.peakVelocity;
    if (peakVelocity?.enabled.value === true && peakVelocity.contour !== undefined) {
      drawPlusMark(
        ctx,
        peakVelocity.contour[0] * canvas.width,
        peakVelocity.contour[1] * canvas.height,
        5
      );
    }
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (
      baseDopplerMeasurement.midline.value === undefined ||
      baseDopplerMeasurement.midlineY.value === undefined ||
      studyClip === undefined ||
      studyClip.height === null
    ) {
      return;
    }

    if (baseContourMeasurement.points.length === 0) {
      baseContourMeasurement.points.push(pt[0], baseDopplerMeasurement.midline.value[1]);
      return;
    } else if (isPointBeyondMidline(pt)) {
      const yPos = baseDopplerMeasurement.midlineY.value;

      // Simulate a double-click to complete the measurement when clicking on the other side of the
      // midline
      baseContourMeasurement.onCanvasMouseDown([pt[0], yPos]);
      baseContourMeasurement.onCanvasMouseDown([pt[0], yPos]);
    }

    baseContourMeasurement.onCanvasMouseDown(pt, { editingOnly: false, allowDoubleClick: false });
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    lastMousePosition.value = pt;

    if (isComplete.value && baseDopplerMeasurement.midline.value) {
      baseContourMeasurement.onCanvasMouseMove(
        isPointBeyondMidline(pt) ? [pt[0], baseDopplerMeasurement.midline.value[1]] : pt
      );
      return true;
    }

    if (
      baseDopplerMeasurement.midline.value === undefined ||
      baseContourMeasurement.points.length === 0 ||
      baseContourMeasurement.method === MeasurementCreationMethod.ClickToPlacePoints
    ) {
      return false;
    }

    if (isPointBeyondMidline(pt) && baseContourMeasurement.points.length >= 4) {
      baseContourMeasurement.onCanvasMouseUp([pt[0], baseDopplerMeasurement.midline.value[1]]);
      return true;
    }

    return baseContourMeasurement.onCanvasMouseMove(lastMousePosition.value);
  }

  function onCanvasMouseUp(pt: number[]): void {
    if (baseContourMeasurement.points.length > 0) {
      if (
        baseContourMeasurement.method === MeasurementCreationMethod.ClickAndDrag &&
        !isPointWithinTolerance(baseContourMeasurement.points.slice(2, 4), pt) &&
        !isComplete.value &&
        baseDopplerMeasurement.midline.value &&
        baseContourMeasurement.points.length >= 4
      ) {
        baseContourMeasurement.onCanvasMouseUp([pt[0], baseDopplerMeasurement.midline.value[1]]);
        return;
      }
      baseContourMeasurement.onCanvasMouseUp(pt);
    }
  }

  const associatedAccelerationTimeRefs = generateAssociatedMeasurementRefs();
  const associatedPeakVelocityRefs = generateAssociatedMeasurementRefs();
  const associatedPeakGradientRefs = generateAssociatedMeasurementRefs();
  const associatedMeanVelocityRefs = generateAssociatedMeasurementRefs();
  const associatedMeanGradientRefs = generateAssociatedMeasurementRefs();

  const associatedMeasurements = computed(
    (): Partial<Record<VTIAssociatedMeasurementName, AssociatedMeasurement>> => {
      if (measurementName.value === undefined) {
        return {};
      }

      const associatedNames = vtiAssociatedMeasurements[measurementName.value];
      if (associatedNames === undefined) {
        return {};
      }

      const result: Partial<Record<VTIAssociatedMeasurementName, AssociatedMeasurement>> = {};

      const associatedMeasurementValues = getVTIAssociatedMeasurementValues(
        measurementName.value,
        studyClip,
        region,
        vtiMeasuredValue.value,
        baseContourMeasurement.points
      );

      if (associatedMeasurementValues.accelerationTime) {
        result.accelerationTime = {
          ...associatedAccelerationTimeRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.accelerationTime,
          frame: frame.value,
        };
      }

      if (associatedMeasurementValues.peakVelocity) {
        result.peakVelocity = {
          ...associatedPeakVelocityRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.peakVelocity,
          frame: frame.value,
        };
      }

      if (associatedMeasurementValues.peakGradient) {
        result.peakGradient = {
          ...associatedPeakGradientRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.peakGradient,
          frame: frame.value,
        };
      }

      if (associatedMeasurementValues.meanVelocity) {
        result.meanVelocity = {
          ...associatedMeanVelocityRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.meanVelocity,
          frame: frame.value,
        };
      }

      if (associatedMeasurementValues.meanGradient) {
        result.meanGradient = {
          ...associatedMeanGradientRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.meanGradient,
          frame: frame.value,
        };
      }

      if (savedMeasurementBeingEdited.value === undefined) {
        setDefaultEnabledForAssociatedMeasurements(result, associatedNames);
      }

      return result;
    }
  );

  function toggleAssociatedMeasurement(name: string): void {
    const associatedMeasurement =
      associatedMeasurements.value[name as VTIAssociatedMeasurementName];

    if (associatedMeasurement !== undefined) {
      associatedMeasurement.enabled.value = !associatedMeasurement.enabled.value;

      requestRedrawHandlers.forEach((fn) => fn());
    }
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    if (measurementName.value === undefined) {
      return {};
    }

    const vtiUnitAndValue = vtiMeasuredValue.value.getInternalValueAndUnit() ?? null;

    if (vtiUnitAndValue === null) {
      return {};
    }

    const mainMeasurementDetails = {
      ...baseMeasurementCreationRequest,
      ...vtiUnitAndValue,
      name: measurementName.value,
      customName: customName.value,
      frame: frame.value,
      contour: baseContourMeasurement.points,
    };

    return buildMeasurementToolBatchChangeRequest(
      mainMeasurementDetails,
      savedMeasurementBeingEdited.value,
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);
  const previouslySavedAssociatedMeasurements = ref<
    (MeasurementToolRecreationDetails & { measurementId: string })[]
  >([]);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    baseContourMeasurement.points.splice(0, baseContourMeasurement.points.length, ...value.contour);
    frame.value = value.frame ?? 0;

    previouslySavedAssociatedMeasurements.value = findSavedAssociatedMeasurements(
      study,
      value,
      vtiAssociatedMeasurements
    );

    setAssociatedMeasurementDetailsOnEdit(
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  function isPointBeyondMidline(pt: number[], tol = 0.02): boolean {
    if (!baseDopplerMeasurement.midline.value) {
      return false;
    }

    if (
      pt[1] < baseDopplerMeasurement.midline.value[1] + tol &&
      pt[1] > baseDopplerMeasurement.midline.value[1] - tol &&
      baseContourMeasurement.points.length >= 4
    ) {
      return true;
    }

    return (
      baseContourMeasurement.points.length >= 4 &&
      (baseContourMeasurement.points[3] - baseDopplerMeasurement.midline.value[1]) *
        (pt[1] - baseDopplerMeasurement.midline.value[1]) <=
        0
    );
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([...baseContourMeasurement.redrawTriggers.value, lastMousePosition], () => {
    requestRedrawHandlers.forEach((fn) => fn());
  });

  return {
    displayName: "VTI Measurement",
    studyClipId,
    region,
    measurementName,
    customMeasurementName,
    customName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled,
    saveButtonTooltip,
    helpText,
    toolbarItems,

    interactivePoints: computed(() => (isComplete.value ? baseContourMeasurement.points : [])),
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),
    shouldPassMousedownAfterRegionSelection: false,

    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (newFrame: number) => (frame.value = newFrame),
    isChangeAllowedOf: (_target: "clip" | "frame") => baseContourMeasurement.points.length === 0,

    associatedMeasurements,
    toggleAssociatedMeasurement,
    getMeasurementChangeRequests,
    getCreatableMeasurementNames: getDrawableVTIMeasurements,
    isMeasurableOnStudyClip: isVTIMeasurableOnStudyClip,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function isVTIMeasurableOnStudyClip(clip: StudyClip, region?: StudyClipRegion): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some(
    (r) =>
      (r.type === RegionType.CWSpectralDoppler || r.type === RegionType.PWSpectralDoppler) &&
      r.xDirectionUnit === RegionUnit.Seconds &&
      r.yDirectionUnit === RegionUnit.CentimetersPerSecond
  );
}
