<template>
  <div ref="referenceElement" v-bind="$attrs">
    <slot />
  </div>

  <Teleport v-if="content || slots.content" to="#tooltip-target">
    <div
      ref="popperElement"
      class="tooltip"
      :data-testid="contentDataTestId"
      :style="{
        display: isHovered ? 'inherit' : 'none',
        visibility: visible ? 'visible' : 'hidden',
        ...(maxWidth === '' ? {} : { maxWidth }),
        ...(whiteSpace === null ? {} : { whiteSpace }),
        ...popperPosition,
      }"
    >
      <div class="popper-content">
        <slot name="content">
          {{ content }}
        </slot>
        <div v-if="shortcut" class="shortcut-badge">{{ shortcut }}</div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { Placement, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";
import { useElementHover, useIntervalFn } from "@vueuse/core";
import { Ref, onUnmounted, reactive, ref, useSlots, watch } from "vue";

interface Props {
  content?: string;
  disabled?: boolean;
  placement?: Placement;
  offsetDistance?: number;
  visible?: boolean;
  maxWidth?: string;
  shortcut?: string;
  whiteSpace?: "nowrap" | null;
  contentDataTestId?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  content: undefined,
  disabled: false,
  placement: "top",
  offsetDistance: 6,
  visible: true,
  maxWidth: "",
  shortcut: "",
  whiteSpace: null,
  contentDataTestId: null,
});

const slots = useSlots();

let cleanupPopper: (() => void) | undefined = undefined;
const popperPosition = reactive({ left: "0", top: "0" });

const referenceElement = ref<HTMLElement>();
const popperElement = ref<HTMLElement | null>(null);
const isHovered = useElementHover(referenceElement as Ref);

// The useElementHover() function relies on mouseenter and mouseleave events. These seem to fire
// adequately on Safari and Chrome, but on Firefox the mouseleave event doesn't fire if the element
// moves underneath the mouse pointer such that it is no longer hovered. This causes the tooltip to
// stay visible when it shouldn't. To fix this, when the tooltip is visible check every 100ms
// whether the mouse is still hovering the element, and if it isn't then clear the isHovered state.
// This fixes the issue on Firefox. See PULSE-880.
const isHoveredCheck = useIntervalFn(
  () => (isHovered.value = referenceElement.value?.matches(":hover") === true),
  100,
  { immediate: false }
);

watch(isHovered, () => {
  cleanupPopper?.();

  if (!isHovered.value || props.disabled || popperElement.value === null) {
    return;
  }

  cleanupPopper = autoUpdate(referenceElement.value!, popperElement.value, () => {
    if (referenceElement.value && popperElement.value) {
      void computePosition(referenceElement.value, popperElement.value, {
        placement: props.placement,
        middleware: [offset(props.offsetDistance), shift({ padding: 2 }), flip()],
      }).then(({ x, y }) => {
        popperPosition.left = `${x}px`;
        popperPosition.top = `${y}px`;
      });
    }
  });

  isHoveredCheck.resume();
});

onUnmounted(() => {
  cleanupPopper?.();
  isHoveredCheck.pause();
});
</script>

<style lang="scss">
.tooltip {
  position: absolute;
  background-color: var(--bg-color-1);
  color: var(--text-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  padding: 6px 8px;
  box-shadow: none;
  z-index: 100;
  animation: tooltip-fade-in 200ms ease 0s 1;
}

@keyframes tooltip-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popper-content {
  display: flex;
  gap: 7px;
  align-items: center;
}

.shortcut-badge {
  padding: 3px 5px;
  border-radius: var(--border-radius);
  font-weight: bold;
  font-size: 12px;
  display: grid;
  place-content: center;
  line-height: 1em;
  background-color: var(--bg-color-3);
  color: var(--accent-color-2);
}
</style>
