import axios, { type AxiosError } from "axios";

/**
 * Extracts the error message to display from the given error response thrown from an axios call.
 */
export function getRequestErrorMessage(error: unknown): string | undefined {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<{ message?: string }>;

    if (axiosError.response) {
      // If there's a custom error string on the response then use that
      if (typeof axiosError.response.data.message === "string") {
        return axiosError.response.data.message;
      }
    }
  }

  return undefined;
}
