<template>
  <Modal
    title="Add Webhook"
    :activity-text="activityText"
    @header-button-click="emits('close')"
    @enter-key-press="addIntegration"
  >
    <div class="content">
      <div class="field">
        <b>Name</b>
        <input
          ref="nameInputElement"
          v-model="integrationName"
          type="text"
          data-testid="webhook-name-input"
        />
      </div>

      <div class="field">
        <b>URL</b>
        <input v-model="url" type="text" data-testid="webhook-url-input" />
      </div>

      <Tooltip :content="invalidFormReason" style="align-self: center">
        <button
          class="accented"
          :disabled="invalidFormReason !== ''"
          data-testid="webhook-add"
          @click="addIntegration"
        >
          Create Webhook
        </button>
      </Tooltip>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import Tooltip from "../../../components/Tooltip.vue";
import { isValidHttpUrl } from "../../../utils/validation";

interface Emits {
  (event: "close"): void;
  (event: "integration-added"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const integrationName = ref("");
const url = ref("");

const nameInputElement = ref<HTMLInputElement>();
useFocus(nameInputElement, { initialValue: true });

const invalidFormReason = computed(() => {
  if (integrationName.value.trim() === "") {
    return "Enter an integration name";
  }

  if (!isWebhookUrlValid.value) {
    return "Enter a valid http:// or https:// URL";
  }

  return "";
});

async function addIntegration(): Promise<void> {
  if (invalidFormReason.value !== "") {
    return;
  }

  activityText.value = "Adding integration";

  try {
    await axios.post(`/api/integrations/webhook`, {
      name: integrationName.value,
      url: url.value,
    });
  } catch {
    addNotification({ type: "error", message: `Error adding webhook integration` });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Added webhook integration` });

  emits("integration-added");
}

const isWebhookUrlValid = computed(() => isValidHttpUrl(url.value));
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
