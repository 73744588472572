export const DNS_REGEXP =
  /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/;

/**
 * Simplify checking if a value is null/undefined/empty
 * @param value
 */
export function isNullOrEmpty<T>(value: T): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  if (typeof value === "number" && isNaN(value)) {
    return true;
  }

  return false;
}

/**
 * Simply checking if a value is not null/undefined/empty
 * @param value
 */
export function isDefined<T>(value: T): value is NonNullable<T> {
  return !isNullOrEmpty(value);
}
