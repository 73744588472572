<template>
  <div class="field">
    <b>{{ header }}</b>

    <div style="display: grid; grid-template-columns: 1fr auto; gap: 4px">
      <input
        :value="token"
        style="font-family: monospace"
        readonly
        type="text"
        data-testid="token-input"
      />
      <Tooltip :content="`Copy ${tokenType} to the clipboard`">
        <button @click="copyToClipboard">
          <FontAwesomeIcon icon="copy" size="lg" />
        </button>
      </Tooltip>
    </div>

    <div style="line-height: 1.5em">
      <slot />
      <br />
      <br />
      Note: this is the <b style="text-decoration: underline">only</b> time that this
      {{ tokenType }} can be viewed. After this dialog is closed this {{ tokenType }} can't be
      viewed again.
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useClipboard } from "@vueuse/core";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";

interface Props {
  header: string;
  tokenType: string;
  token: string;
}

const props = defineProps<Props>();

const { copy, isSupported: isClipboardSupported } = useClipboard();

async function copyToClipboard(): Promise<void> {
  if (!isClipboardSupported.value) {
    addNotification({
      type: "info",
      message: `Unable to copy the ${props.tokenType} to the clipboard`,
    });
    return;
  }

  await copy(props.token);

  addNotification({ type: "info", message: `Copied ${props.tokenType} to the clipboard` });
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-size: 0.85em;
  }
}
</style>
