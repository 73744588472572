<template>
  <Modal
    title="Add HL7 Integration"
    :activity-text="activityText"
    @header-button-click="emits('close')"
    @enter-key-press="addIntegration"
  >
    <div class="content">
      <div>
        <div class="input-label">Integration Name</div>
        <input
          ref="integrationNameInputElement"
          v-model="integrationName"
          type="text"
          placeholder="Integration Name"
        />
      </div>

      <div class="input-row">
        <div class="input-label">Endpoint</div>
        <div class="input-label input-field">Receiving Application</div>

        <input v-model="endpoint" type="text" placeholder="IP address or DNS name" />
        <input
          v-model="receivingApplication"
          type="text"
          class="input-field"
          placeholder="Your EHR"
        />
      </div>

      <div class="input-row">
        <div class="input-label">Sending Facility</div>
        <div class="input-label input-field">Receiving Facility</div>
        <input v-model="sendingFacility" type="text" placeholder="Nelson Hospital" />
        <input
          v-model="receivingFacility"
          class="input-field"
          type="text"
          placeholder="Hospital/Clinic name"
        />
      </div>

      <div>
        <div class="input-label">Provider Details</div>

        <div class="name-input">
          <input v-model="providerTitle" type="text" placeholder="Dr" style="width: 36px" />
          <input
            v-model="providerFirstName"
            type="text"
            placeholder="Christiaan"
            class="input-field"
          />
          <input v-model="providerLastName" type="text" placeholder="Barnard" class="input-field" />
        </div>
      </div>

      <div>
        <div class="input-label">TLS Configuration</div>

        <div class="tls-config">
          <input
            ref="privateKeyAndCertInput"
            hidden
            type="file"
            @change="onPrivateKeyAndCertSelected()"
          />

          <div class="file-upload-row">
            <button @click="privateKeyAndCertInput?.click()">
              Select Private Key & Cert (PKCS#12)
            </button>
            {{ privateKeyAndCertFilename }}
          </div>

          <input ref="caChainInput" hidden type="file" @change="onCAChainSelected()" />

          <div class="file-upload-row">
            <button @click="caChainInput?.click()">Select CA Chain (PEM)</button>
            {{ caChainFilename }}
          </div>
        </div>
      </div>

      <button
        class="accented"
        style="align-self: center"
        :disabled="!isComplete"
        @click="addIntegration"
      >
        Create HL7 Integration
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";

interface Emits {
  (event: "close"): void;
  (event: "integration-added"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const integrationName = ref("");
const endpoint = ref("");
const receivingApplication = ref("");
const receivingFacility = ref("");
const sendingFacility = ref("");
const providerTitle = ref("");
const providerFirstName = ref("");
const providerLastName = ref("");

const privateKeyAndCertInput = ref<HTMLInputElement>();
const privateKeyAndCert = ref("");
const privateKeyAndCertFilename = ref("");

const caChainInput = ref<HTMLInputElement>();
const caChain = ref("");
const caChainFilename = ref("");

const integrationNameInputElement = ref<HTMLInputElement>();
useFocus(integrationNameInputElement, { initialValue: true });

async function onPrivateKeyAndCertSelected(): Promise<void> {
  const files = privateKeyAndCertInput.value!.files!;
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  const blob = new Blob([await file.arrayBuffer()]);

  const dataURL = await new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = reject;
    fileReader.onload = (): void => {
      resolve(fileReader.result as string);
    };
    fileReader.readAsDataURL(blob);
  });

  privateKeyAndCert.value = dataURL.substring(dataURL.indexOf(",") + 1);
  privateKeyAndCertFilename.value = file.name;
}

async function onCAChainSelected(): Promise<void> {
  const files = caChainInput.value!.files!;
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  const blob = new Blob([await file.arrayBuffer()]);

  const content = await new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = reject;
    fileReader.onload = (): void => {
      resolve(fileReader.result as string);
    };
    fileReader.readAsText(blob);
  });

  caChain.value = content;
  caChainFilename.value = file.name;
}

const isComplete = computed(
  () =>
    integrationName.value !== "" &&
    endpoint.value !== "" &&
    receivingFacility.value !== "" &&
    sendingFacility.value !== "" &&
    providerFirstName.value !== "" &&
    providerLastName.value !== "" &&
    privateKeyAndCert.value !== "" &&
    caChain.value !== ""
);

async function addIntegration(): Promise<void> {
  if (!isComplete.value) {
    return;
  }

  activityText.value = "Adding integration";

  try {
    await axios.post(`/api/integrations/hl7`, {
      name: integrationName.value,
      endpoint: endpoint.value,
      receivingApplication: receivingApplication.value,
      receivingFacility: receivingFacility.value,
      sendingFacility: sendingFacility.value,
      providerDetails: [providerLastName.value, providerFirstName.value, "", providerTitle.value],
      tlsPrivateKeyAndCert: privateKeyAndCert.value,
      tlsCAChain: caChain.value,
    });
  } catch {
    addNotification({ type: "error", message: `Error adding HL7 integration` });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Added HL7 integration` });

  emits("integration-added");
}
</script>

<style scoped lang="scss">
.content {
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-label {
  margin-bottom: 8px;
  font-weight: bold;
}

.input-row {
  display: grid;
  grid-template-columns: auto auto;
}

.input-field {
  margin-left: 8px;
}

.tls-config {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .file-upload-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
