<template>
  <div class="report-section-sentence-selector">
    <input v-model="searchTerm" class="field-input show-border" placeholder="Search" />

    <OverlayScrollbar style="height: 25em">
      <div class="groups">
        <DraggableList @reorder="reorderSelectedSentences">
          <div v-for="(group, i) of selectedSentenceGroups" :key="group.id" class="draggable-row">
            <ReportCheckbox
              :model-value="section.sentenceGroupIds.includes(group.id)"
              :data-testid="`selected-sentence-group-${i}-${section.name}-${group.name}`"
              @update:model-value="toggleGroup(group.id)"
            >
              {{ group.name }}
            </ReportCheckbox>

            <FontAwesomeIcon
              icon="grip-lines-vertical"
              class="drag-handle"
              :data-testid="`drag-handle-${section.name}-${group.name}`"
            />
          </div>
        </DraggableList>
      </div>

      <div v-if="selectedSentenceGroups.length > 0" class="divider" />

      <div class="groups">
        <template
          v-for="(group, i) of filteredSentenceGroups.filter(
            (g) => !selectedSentenceGroups.includes(g)
          )"
          :key="group.id"
        >
          <ReportCheckbox
            :model-value="section.sentenceGroupIds.includes(group.id)"
            :data-testid="`unselected-sentence-group-${i}-${section.name}-${group.name}`"
            @update:model-value="toggleGroup(group.id)"
          >
            {{ group.name }}
          </ReportCheckbox>
        </template>
      </div>
    </OverlayScrollbar>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { SortableEvent } from "sortablejs";
import { computed, ref } from "vue";
import { ReportSentenceGroupCreateResponseDto } from "../../../backend/src/reporting/dto/report-sentence-group-create.dto";
import type {
  ReportSectionStructure,
  ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import DraggableList from "../components/DraggableList.vue";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import { addNotification } from "../utils/notifications";
import ReportCheckbox from "./ReportCheckbox.vue";
import {
  ReportStructureMutation,
  createSentenceGroupIdToggleMutation,
  createSentenceGroupReorderMutation,
} from "./report-structure-mutations";

interface Props {
  structure: ReportStructure;
  section: ReportSectionStructure;
}

interface Emits {
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const sentenceGroups = ref<ReportSentenceGroupCreateResponseDto[]>([]);

const selectedSentenceGroups = computed(() => {
  const groups: ReportSentenceGroupCreateResponseDto[] = [];

  for (const id of props.section.sentenceGroupIds) {
    const group = sentenceGroups.value.find((g) => g.id === id);
    if (group) {
      groups.push(group);
    }
  }

  return groups;
});

async function fetchSentenceGroups(): Promise<void> {
  let response: AxiosResponse<ReportSentenceGroupCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<ReportSentenceGroupCreateResponseDto[]>(
      "/api/report-sentence-groups"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading sentence groups" });
    return;
  }

  sentenceGroups.value = response.data;
}

void fetchSentenceGroups();

const searchTerm = ref("");

const filteredSentenceGroups = computed(() =>
  sentenceGroups.value.filter((sentenceGroup) => !isStringFiltered(sentenceGroup.name))
);

function isStringFiltered(value: string): boolean {
  const term = searchTerm.value.trim();

  return !value.toLowerCase().includes(term.toLowerCase());
}

function toggleGroup(id: string): void {
  emits("mutate-structure", createSentenceGroupIdToggleMutation(props.section.id, id));
}

function reorderSelectedSentences(evt: SortableEvent): void {
  if (evt.oldIndex === undefined || evt.newIndex === undefined) {
    return;
  }

  emits(
    "mutate-structure",
    createSentenceGroupReorderMutation(props.section.id, evt.oldIndex, evt.newIndex)
  );
}
</script>

<style scoped lang="scss">
.report-section-sentence-selector {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  color: var(--report-text-color-1);
}

input {
  color: var(--report-text-color-1);
  transition: color 100ms ease;

  &:focus {
    color: var(--report-text-color-2);
  }
}

.groups {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  width: 30em;
}

.divider {
  background-color: #aaa;
  height: 1px;
  margin: 0.8em;
}

.draggable-row {
  display: grid;
  gap: 0.4em;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.drag-handle {
  cursor: grab;
  font-size: 1.2em;
  padding: 0.2em;
  margin-right: 1em;
  color: var(--report-text-color-1);

  &:hover {
    color: var(--report-text-color-2);
  }
}
</style>
