import { tryOnScopeDispose } from "@vueuse/core";
import sysend from "sysend";
import { z } from "zod";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { notificationSyncMessages } from "./notification-sync-messages";
import { getSecondaryWindowId } from "./secondary-window";

/** Schema for messages that can be sent to the secondary window. */
const secondaryWindowIncomingMessageSchema = z.discriminatedUnion("type", [
  z.strictObject({
    type: z.literal("set-study"),
    studyJson: z.string(),
  }),
  z.strictObject({
    type: z.literal("close-window"),
  }),
  z.strictObject({
    type: z.literal("set-selected-clips"),
    selectedClipIds: z.string().array(),
  }),
  z.strictObject({
    type: z.literal("set-clip-sync-enabled"),
    isClipSyncEnabled: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("set-playback-speed-factor"),
    playbackSpeedFactor: z.number(),
  }),
  z.strictObject({
    type: z.literal("set-clips-playing"),
    isPlaying: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("step-frames"),
    frameStepDelta: z.number(),
  }),
  z.strictObject({
    type: z.literal("set-measurement-visibility"),
    isVisible: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("set-stress-mode-enabled"),
    isStressModeEnabled: z.boolean(),
  }),
  z.strictObject({
    type: z.literal("set-stress-mode-view"),
    viewName: z.string(),
  }),
  z.strictObject({
    type: z.literal("next-clips"),
  }),
  z.strictObject({
    type: z.literal("prev-clips"),
  }),
  z.strictObject({
    type: z.literal("set-measurement-tool"),
    tool: z.nativeEnum(MeasurementToolName).nullable(),
  }),
  z.strictObject({
    type: z.literal("set-measurement-sequence"),
    sequenceId: z.string(),
    stepIndex: z.number(),
  }),
  ...notificationSyncMessages,
]);

type SecondaryWindowIncomingMessage = z.infer<typeof secondaryWindowIncomingMessageSchema>;

/**
 * Posts a message to the secondary window. This should be called from a primary window, and does
 * nothing if there is no secondary window open.
 */
export function postMessageToSecondaryWindow(message: SecondaryWindowIncomingMessage): void {
  if (getSecondaryWindowId() === "") {
    return;
  }

  sysend.post(getSecondaryWindowId(), message);
}

let isHandlingMessage = false;

export function isHandlingSecondaryWindowMessage(): boolean {
  return isHandlingMessage;
}

/**
 * Adds a handler that will be called when the specified message is received on the secondary
 * window.
 */
export function onSecondaryWindowMessageReceived<
  MessageType extends SecondaryWindowIncomingMessage["type"],
  MessageData extends Extract<SecondaryWindowIncomingMessage, { type: MessageType }>,
>(type: MessageType, handler: (message: MessageData) => void): void {
  function callback(payload: { data: unknown; origin: string }): void {
    const parsedMessage = secondaryWindowIncomingMessageSchema.safeParse(payload.data);

    if (parsedMessage.success && parsedMessage.data.type === type) {
      isHandlingMessage = true;
      try {
        handler(payload.data as MessageData);
      } finally {
        isHandlingMessage = false;
      }
    }
  }

  sysend.track("message", callback);

  tryOnScopeDispose(() => {
    sysend.untrack("message", callback as () => void);
  });
}
