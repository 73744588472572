import isURL from "validator/es/lib/isURL";

export function isValidHttpUrl(urlString: string): boolean {
  // Add the same restrictions on the validation of the URL than the backend
  if (!isURL(urlString)) {
    return false;
  }

  let url: URL | undefined = undefined;
  try {
    url = new URL(urlString);
  } catch (error) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
