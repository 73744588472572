<template>
  <div class="name-container">
    <b>Enter your name:</b>
    <input
      ref="inputElement"
      v-model="currentUser.name"
      data-testid="onboarding-name-input"
      @keydown.enter="emits('continue')"
    />
  </div>
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import { ref } from "vue";
import { currentUser } from "../auth/current-session";

interface Emits {
  (event: "continue"): void;
}

const emits = defineEmits<Emits>();

const inputElement = ref<HTMLInputElement>();

useFocus(inputElement, { initialValue: true });

if (currentUser.name === currentUser.email) {
  currentUser.name = "";
}
</script>

<style scoped lang="scss">
.name-container {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 320px;
}

input {
  height: 36px;
  flex: 1;
  padding: 0 8px;
  border: 1px solid var(--accent-color-1) !important;
}
</style>
