<template>
  <div class="auth-page" data-testid="auth-page">
    <form class="auth-box" @submit.prevent="emits('submit')">
      <img class="logo" src="/logo-with-name.png" alt="HeartLab" />

      <slot />

      <div class="auth-button-container">
        <div v-if="backIconVisible" class="back" @click="emits('back')">
          <Tooltip content="Back to sign in">
            <button class="secondary" data-testid="cancel-reset-button">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
              Cancel
            </button>
          </Tooltip>
        </div>
        <button
          v-if="authButtonVisible"
          type="submit"
          class="auth-button accented"
          :disabled="!authButtonEnabled"
          data-testid="auth-button"
        >
          <LoadingIndicator v-if="authButtonSpinner" />
          <template v-else>{{ authButtonText }}</template>
        </button>
      </div>

      <slot name="footer" />
    </form>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Tooltip from "../components/Tooltip.vue";

interface Props {
  authButtonVisible: boolean;
  authButtonText: string;
  authButtonEnabled: boolean;
  authButtonSpinner: boolean;
  backIconVisible?: boolean;
}

interface Emits {
  (event: "submit"): void;
  (event: "back"): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.auth-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.auth-box {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 16px;
  opacity: 1;

  background-color: #121212;

  border: 1px solid var(--border-color-1);
  border-radius: 3px;

  padding: 40px;

  transform: translateY(calc(-10vh));
}

.logo {
  width: 60%;
  object-fit: contain;
  margin: 0 auto;
}

.tagline {
  text-align: center;
  margin-bottom: 24px;
}

.auth-button-container {
  display: flex;
  margin-top: 16px;
  gap: 8px;
}

.auth-button {
  flex-grow: 1;
}

.back {
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}
</style>
