import axios, { type AxiosError } from "axios";
import type { NavigationGuard } from "vue-router";
import { TOTP_REQUIRED_STRING } from "../../../backend/src/auth/auth-helpers";
import { SessionCreateResult } from "../../../backend/src/auth/session-create-result";
import router from "../router";
import { addNotification } from "../utils/notifications";
import { setupAutoSignOut } from "./auto-sign-out";
import { clearCurrentTenantAndUser, fetchCurrentTenantAndUser } from "./current-session";
import { createMfaDeviceTokenId, getStoredMfaDeviceTokenId } from "./mfa.device-token-storage";

// We create a new instance of axios to avoid using the interceptors defined in that file.
const authAxios = axios.create();

export interface SelectableTenants {
  id: string;
  name: string;
}

interface AuthenticationResponse {
  message: string;
  tenants?: SelectableTenants[];
}

/** Creates a new session by authenticating with the given username and password. */
export async function signInWithEmail({
  email,
  password,
  tenantId,
  totpToken,
  isMfaDeviceTokenRequested,
}: {
  email: string;
  password: string;
  tenantId?: string;
  totpToken?: string;
  isMfaDeviceTokenRequested?: boolean;
}): Promise<{ result: SessionCreateResult; tenants?: SelectableTenants[] }> {
  const mfaDeviceTokenId = await getStoredMfaDeviceTokenId(email);

  try {
    await authAxios.post("/api/auth/email-password", {
      email,
      password,
      totpToken,
      ...(mfaDeviceTokenId === "" ? {} : { mfaDeviceTokenId }),
      ...(tenantId === "" ? {} : { tenantId }),
    });

    await fetchCurrentTenantAndUser();

    if (isMfaDeviceTokenRequested === true) {
      await createMfaDeviceTokenId(email);
    }

    setupAutoSignOut();

    return { result: SessionCreateResult.Success };
  } catch (error: unknown) {
    if (axios.isAxiosError<AuthenticationResponse>(error) && error.response) {
      if (error.response.data.message === TOTP_REQUIRED_STRING) {
        return { result: SessionCreateResult.TOTPRequired };
      }

      if (error.response.data.tenants) {
        return {
          result: SessionCreateResult.TenantSelectionRequired,
          tenants: error.response.data.tenants,
        };
      }

      addNotification({ type: "error", message: error.response.data.message });
      return { result: SessionCreateResult.Failure };
    } else {
      throw error;
    }
  }
}

/** Creates a new session by authenticating with a study share link. */
export async function signInWithStudyShareLink(
  studyId: string,
  token: string,
  passcode: string
): Promise<SessionCreateResult> {
  try {
    await authAxios.post("/api/auth/study-share-link", {
      studyId,
      token,
      passcode,
    });

    await fetchCurrentTenantAndUser();

    return SessionCreateResult.Success;
  } catch (error: unknown) {
    if (axios.isAxiosError<AuthenticationResponse>(error) && error.response) {
      addNotification({ type: "error", message: error.response.data.message });
      return SessionCreateResult.Failure;
    } else {
      throw error;
    }
  }
}

/** Destroys the current session and also redirects to the sign-in page if requested. */
export async function signOut({ redirectToSignIn } = { redirectToSignIn: true }): Promise<void> {
  try {
    await axios.delete("/api/auth");
  } catch {
    // Swallow any failures on logout
  }

  clearCurrentTenantAndUser();

  // Go to sign-in route if requested, refreshing the page to flush any latent app state
  if (redirectToSignIn) {
    window.location.href = `${window.location.origin}/sign-in`;
  }
}

// eslint-disable-next-line func-style
export const routeGuard: NavigationGuard = async (to, _from) => {
  // There's no auth check on the following routes
  if (
    to.name === "sign-in" ||
    to.name === "accept-invitation" ||
    to.name === "passcode-prompt" ||
    to.name === "reset-password"
  ) {
    return true;
  }

  // If there's a shareToken for a study share link in the query params then redirect to the
  // passcode prompt in order to create a session using that token
  const urlParams = new URLSearchParams(window.location.search);
  if (to.name === "study-view" && urlParams.get("shareToken") !== null) {
    await router.push({
      name: "passcode-prompt",
      query: { studyId: to.params.id, shareToken: urlParams.get("shareToken") },
    });

    return false;
  }

  // Fetch the logged in tenant and user. If not logged in then this will get a 401 and redirect to
  // the sign-in page, with a reference back to the original page the user was trying to go to.
  const status = await fetchCurrentTenantAndUser();
  if (status === 401) {
    return { name: "sign-in", query: { redirectTo: to.fullPath } };
  }

  return true;
};

//
// Axios integration
//

// Default request timeout to 15 seconds
axios.interceptors.request.use((config) => {
  config.timeout ??= 15000;
  return config;
});

// Axios requests that return a 401 cause the user to be sent to the sign-in page and then
// redirected back to where they were on successful sign in
axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401 && router.currentRoute.value.name !== "sign-in") {
      // Go to the sign-in page with the redirectTo query param set
      void router.push({
        name: "sign-in",
        query: { redirectTo: String(router.currentRoute.value.fullPath) },
      });
    }

    return Promise.reject(error);
  }
);

export function isPINValid(pin: string): boolean {
  return /^[0-9]{4}$/.test(pin);
}
