<template>
  <svg width="39" height="28.5" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 17.5461V2.78932C1 2.78932 3.79529 3.4518 5.54054 3.11364C7.34781 2.76347 7.91786 1.09106 9.75676 1.00554C11.0609 0.944878 13 1.81635 13 1.81635C13 1.81635 15.0409 0.921776 16.4054 1.00554C18.2428 1.11832 18.8122 2.7749 20.6216 3.11364C22.3069 3.42914 25 2.78932 25 2.78932V17.8704C25 17.8704 22.2989 18.2027 20.6216 17.8704C18.8679 17.5229 18.1812 16.2937 16.4054 16.0866C15.0785 15.9319 13 16.4109 13 16.4109C13 16.4109 11.0223 15.9502 9.75676 16.0866C7.97922 16.2782 7.30445 17.5789 5.54054 17.8704C3.78662 18.1603 1 17.5461 1 17.5461Z"
      stroke="white"
    />
    <path d="M13 1.81635V16.4109" stroke="white" />
    <path
      d="M20.5118 11.9585C20.4985 12.2585 20.7885 12.4085 21.3818 12.4085V12.9785H18.2818V12.4085C18.8552 12.4085 19.1418 12.2585 19.1418 11.9585V8.91851C19.1418 8.61184 18.8552 8.45851 18.2818 8.45851V7.88851H20.5118V11.9585ZM20.6318 6.20851C20.6318 6.44184 20.5485 6.64517 20.3818 6.81851C20.2218 6.98517 20.0218 7.06851 19.7818 7.06851C19.6618 7.06851 19.5485 7.04517 19.4418 6.99851C19.3418 6.95184 19.2518 6.89184 19.1718 6.81851C19.0985 6.73851 19.0385 6.64851 18.9918 6.54851C18.9452 6.44184 18.9218 6.32851 18.9218 6.20851C18.9218 5.96851 19.0052 5.76851 19.1718 5.60851C19.3452 5.44184 19.5485 5.35851 19.7818 5.35851C20.0152 5.35851 20.2152 5.44184 20.3818 5.60851C20.5485 5.77517 20.6318 5.97517 20.6318 6.20851Z"
      fill="white"
    />
  </svg>
</template>
