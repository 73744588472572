import axios from "axios";
import type { AuditLogEventCreateRequestDto } from "../../../backend/src/tenants/dto/audit-log-event-create.dto";
import { addNotification } from "./notifications";

/**
 * Creates an audit log event in the backend. This function must only be used to log events that
 * happened in the frontend which didn't need a backend request that would have otherwise been able
 * to create an audit log event. For example, opening or closing a secondary window.
 *
 * Only a small subset of audit log events are allowed to be created directly from the frontend
 * using this function.
 */
export async function createAuditLogEvent(data: AuditLogEventCreateRequestDto): Promise<void> {
  try {
    await axios.post("/api/tenants/audit-log-events", data);
  } catch (error) {
    addNotification({ type: "error", message: `Failed creating audit log event '${data.action}'` });
  }
}
