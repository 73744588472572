<template>
  <DropdownWidget
    :model-value="modelValue"
    :items="
      sortedReports.map((report, index) => ({
        value: report.id,
        text: getReportDropdownDisplayText(report, index),
      }))
    "
    data-testid="study-reports-dropdown"
    @update:model-value="(value) => emits('update:modelValue', value)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import DropdownWidget from "../components/DropdownWidget.vue";
import { formatDateTime } from "../utils/date-time-utils";
import { getSortedReports, StudyReportDescription } from "../utils/study-data";

interface Props {
  modelValue: string;
  reports: StudyReportDescription[];
}

interface Emits {
  (event: "close"): void;
  (event: "update:modelValue", reportId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const sortedReports = computed(() => getSortedReports(props.reports));

function getReportDropdownDisplayText(report: StudyReportDescription, index: number): string {
  if (report.completedAt === null) {
    return "Current";
  }

  const timestamp = formatDateTime(report.completedAt.toString());

  const reportType = {
    [StudyReportType.Preliminary]: "Preliminary Report",
    [StudyReportType.Final]: "Final Report",
    [StudyReportType.Amendment]: "Amendment",
  }[report.type];

  return `#${props.reports.length - index}: ${reportType} on ${timestamp}`;
}
</script>
