<template>
  <div class="settings-title">
    SFTP Settings

    <OutboundSourceIpLabel />
  </div>

  <div class="top-row">
    <FilterInput v-model="integrationSearchTerm" placeholder="Search" />
    <button
      style="margin-left: auto"
      data-testid="add-sftp-integration-modal-btn"
      class="accented"
      @click="isSftpIntegrationModalVisible = true"
    >
      Create SFTP Integration
    </button>
  </div>

  <div class="integrations-table">
    <div class="header">
      <div>Name</div>
      <div>Endpoint</div>
      <div>Port</div>
      <div>Username</div>
      <div>Enabled</div>
    </div>

    <div class="header-line" />

    <template v-for="integration in filteredSftpIntegrations" :key="integration.id">
      <div
        class="grid-table-row"
        :data-testid="`sftp-row-${integration.name}`"
        @click="viewIntegration(integration.id)"
      >
        <div data-testid="name">{{ integration.name }}</div>
        <div>{{ integration.endpoint }}</div>
        <div>{{ integration.port }}</div>
        <div>{{ integration.user }}</div>
        <div>{{ integration.enabled ? "Yes" : "No" }}</div>
      </div>
    </template>
  </div>

  <ActivityOverlay v-if="isLoading" text="Loading" />

  <AddSftpIntegrationModal
    v-if="isSftpIntegrationModalVisible"
    @close="isSftpIntegrationModalVisible = false"
    @integration-added="
      isSftpIntegrationModalVisible = false;
      fetchSftpIntegrations();
    "
  />
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import type { SftpIntegrationGetManyResponseDto } from "../../../../../backend/src/integrations/sftp/dto/sftp-integration-get-many.dto";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import FilterInput from "../../../components/FilterInput.vue";
import router from "../../../router";
import { addNotification } from "../../../utils/notifications";
import OutboundSourceIpLabel from "../OutboundSourceIpLabel.vue";
import AddSftpIntegrationModal from "./AddSftpIntegrationModal.vue";

const isLoading = ref(false);
const sftpIntegrations = ref<SftpIntegrationGetManyResponseDto>([]);
const integrationSearchTerm = ref<string>("");

const isSftpIntegrationModalVisible = ref(false);

async function fetchSftpIntegrations(): Promise<void> {
  isLoading.value = true;

  let response: AxiosResponse<SftpIntegrationGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<SftpIntegrationGetManyResponseDto>(`/api/integrations/sftp`);
  } catch {
    addNotification({ type: "error", message: "Failed loading SFTP integration data" });
    return;
  } finally {
    isLoading.value = false;
  }

  sftpIntegrations.value = response.data;
}

onMounted(fetchSftpIntegrations);

// Integration search by name, endpoint, username
const filteredSftpIntegrations = computed(() => {
  const searchTerm = integrationSearchTerm.value.toLowerCase();

  return sftpIntegrations.value.filter(
    (integration) =>
      integration.name.toLowerCase().includes(searchTerm) ||
      integration.endpoint.toLowerCase().includes(searchTerm) ||
      integration.user.toLowerCase().includes(searchTerm)
  );
});

async function viewIntegration(integrationId: string): Promise<void> {
  await router.push({ name: "settings-integrations-sftp-view", params: { id: integrationId } });
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.integrations-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 5;
    border-bottom: 2px solid var(--bg-color-4);
  }
}
</style>
