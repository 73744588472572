import { computed, ref } from "vue";
import type { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import type { MeasurementTool } from "./measurement-tool";

/** Creates a new null measurement tool instance. */
export function createNullMeasurementTool(): Readonly<MeasurementTool> {
  return {
    displayName: "null",
    measurementName: ref(undefined),
    customName: ref(""),
    studyClipId: "",
    region: undefined,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled: computed(() => false),
    helpText: computed(() => ""),
    toolbarItems: computed(() => []),
    shouldPassMousedownAfterRegionSelection: true,

    interactivePoints: computed(() => []),
    getMeasurementLabels: () => [],
    requestRedrawHandlers: new Set(),
    frameChangeHandlers: new Set(),

    drawToCanvas: () => null,
    onCanvasMouseDown: () => null,
    onCanvasMouseMove: () => false,
    onCanvasMouseUp: () => null,
    onFrameChange: () => null,
    isChangeAllowedOf: (_target: "clip" | "frame") => true,

    associatedMeasurements: computed(() => ({})),
    toggleAssociatedMeasurement: () => null,
    getCreatableMeasurementNames: (): MeasurementName[] => [],
    getMeasurementChangeRequests: () => ({}),
    isMeasurableOnStudyClip: () => false,

    editingMeasurementBatchId: ref(null),
    loadSavedMeasurement: () => null,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}
