<template>
  <div class="unknown-path">
    <img class="header" src="/logo-with-name.png" />

    <b> Oops, looks like that page doesn't exist. </b>

    <RouterLink :to="{ name: 'home' }">
      <button class="accented">Go Home</button>
    </RouterLink>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.unknown-path {
  flex: 1;
  align-self: center;
  display: grid;
  gap: 32px;
  place-content: center;
  justify-items: center;
}

img {
  width: 200px;
  margin-bottom: 32px;
}
</style>
