import type { Study, StudyClip, StudyClipRegion } from "@/utils/study-data";
import hull from "hull.js";
import { computed, ref, watch } from "vue";
import { getDrawableAreaMeasurements } from "../../../../backend/src/measurements/drawable-measurements";
import { MeasuredValue } from "../../../../backend/src/measurements/measured-value";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import { getAreaMeasurementValue } from "../../../../backend/src/measurements/measurement-tool-evaluation";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { RegionUnit } from "../../../../backend/src/studies/study-clip-region";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { currentTenant } from "../../auth/current-session";
import { buildMeasurementToolBatchChangeRequest } from "../measurement-tool-helpers";
import { getLinearMeasurementLabelPosition } from "./linear/measurement-base-linear";
import {
  createBaseContourMeasurement,
  isPolygonSelfIntersecting,
} from "./measurement-base-contour";
import type {
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "./measurement-tool";
import { RESTART_MEASUREMENT_ICON } from "./measurement-tool-helpers";
import {
  measurementIncompleteTooltipText,
  measurementIntersectingTooltipText,
} from "./measurement-tooltips";

/** Creates a new area measurement tool instance for the given study and region. */
export function createAreaMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomArea;
  const customName = ref("");

  const frame = ref(0);

  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const lastMousePosition = ref<number[]>([]);

  const baseContourMeasurement = createBaseContourMeasurement(
    studyClip,
    region,
    lastMousePosition,
    currentTenant.isAreaMeasurementAutomaticResamplingEnabled
  );

  const measuredValue = computed(() => {
    if (!studyClip || !region || !baseContourMeasurement.isClosed.value) {
      return MeasuredValue.Null;
    }

    return getAreaMeasurementValue(baseContourMeasurement.points, studyClip, region);
  });

  const isSaveButtonEnabled = computed(() => saveButtonTooltip.value === "");

  const saveButtonTooltip = computed(() => {
    if (!baseContourMeasurement.isClosed.value) {
      return measurementIncompleteTooltipText;
    }

    if (isPolygonSelfIntersecting(baseContourMeasurement.points)) {
      return measurementIntersectingTooltipText;
    }

    return "";
  });

  const helpText = computed(() =>
    !baseContourMeasurement.isClosed.value && baseContourMeasurement.points.length < 6
      ? "Click and drag to place points"
      : ""
  );

  const toolbarItems = computed((): ToolbarItem[] => {
    const items: ToolbarItem[] = [
      {
        text: measuredValue.value.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
      {
        text: "Resample",
        enabled: baseContourMeasurement.isClosed.value,
        onClick: baseContourMeasurement.resamplePoints,
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: baseContourMeasurement.restart,
      });
    }

    return items;
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (!baseContourMeasurement.isClosed.value) {
      return [];
    }

    const position = getAreaMeasurementLabelPosition({
      canvas,
      points: baseContourMeasurement.points,
    });

    return [
      {
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: measuredValue.value.createTransientValue(
          measurementName.value ?? customMeasurementName,
          baseContourMeasurement.points,
          frame.value
        ),
        x: position.x,
        y: position.y,
      },
    ];
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    baseContourMeasurement.drawToCanvas(canvas, ctx);
  }

  function onCanvasMouseDown(pt: number[]): void {
    baseContourMeasurement.onCanvasMouseDown(pt);
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    lastMousePosition.value = pt;

    return baseContourMeasurement.onCanvasMouseMove(pt);
  }

  function onCanvasMouseUp(pt: number[]): void {
    baseContourMeasurement.onCanvasMouseUp(pt);
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const measuredValueUnitAndValue = measuredValue.value.getInternalValueAndUnit() ?? null;
    if (measurementName.value === undefined || measuredValueUnitAndValue === null) {
      return {};
    }

    const measurementDetails = {
      ...measuredValueUnitAndValue,
      name: measurementName.value,
      tool: MeasurementToolName.Area,
      customName: isCustomMeasurement(measurementName.value) ? customName.value : "",
      frame: frame.value,
      contour: baseContourMeasurement.points,
      studyClipId,
    };

    return buildMeasurementToolBatchChangeRequest(
      measurementDetails,
      savedMeasurementBeingEdited.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    baseContourMeasurement.points.splice(0, baseContourMeasurement.points.length, ...value.contour);
    frame.value = value.frame ?? 0;
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch(baseContourMeasurement.redrawTriggers.value, () =>
    requestRedrawHandlers.forEach((fn) => fn())
  );

  return {
    displayName: "Area Measurement",
    studyClipId,
    region,
    measurementName,
    customName,
    customMeasurementName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled,
    saveButtonTooltip,
    helpText,
    toolbarItems,
    shouldPassMousedownAfterRegionSelection: true,

    interactivePoints: baseContourMeasurement.interactivePoints,
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),

    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (frameNumber: number) => (frame.value = frameNumber),
    isChangeAllowedOf: (_target: "clip" | "frame") => baseContourMeasurement.points.length === 0,

    associatedMeasurements: computed(() => ({})),
    toggleAssociatedMeasurement: () => null,
    getCreatableMeasurementNames: getDrawableAreaMeasurements,
    getMeasurementChangeRequests,
    isMeasurableOnStudyClip: isAreaMeasurableOnStudyClip,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function getAreaMeasurementLabelPosition(args: {
  canvas: HTMLCanvasElement;
  points: number[];
}): { x: number; y: number } {
  const { canvas, points } = args;

  // Build convex hull around the entire shape
  const vertices: number[][] = [];
  for (let i = 0; i < points.length; i += 2) {
    vertices.push([points[i], points[i + 1]]);
  }
  const convexHull = hull(vertices, Infinity) as number[][];
  convexHull.pop();

  // Get the index of the convex hull edge with the right-most midpoint
  let rightmostVertexIndex = -1;
  for (let i = 0; i < convexHull.length; i++) {
    if (rightmostVertexIndex === -1 || convexHull[i][0] > convexHull[rightmostVertexIndex][0]) {
      rightmostVertexIndex = i;
    }
  }

  // Get the indices of the next and previous vertices
  const prevVertexIndex = (rightmostVertexIndex + convexHull.length - 1) % convexHull.length;
  const nextVertexIndex = (rightmostVertexIndex + 1) % convexHull.length;

  // Use the edge that makes the largest angle with the X axis
  const prevEdgeAngle = Math.atan2(
    convexHull[prevVertexIndex][1] - convexHull[rightmostVertexIndex][1],
    convexHull[prevVertexIndex][0] - convexHull[rightmostVertexIndex][0]
  );
  const nextEdgeAngle = Math.atan2(
    convexHull[nextVertexIndex][1] - convexHull[rightmostVertexIndex][1],
    convexHull[nextVertexIndex][0] - convexHull[rightmostVertexIndex][0]
  );
  const bestEdge = prevEdgeAngle < nextEdgeAngle ? prevVertexIndex : nextVertexIndex;

  return getLinearMeasurementLabelPosition({
    canvas,
    from: convexHull[rightmostVertexIndex],
    to: convexHull[bestEdge],
  });
}

export function isAreaMeasurableOnStudyClip(clip: StudyClip, region?: StudyClipRegion): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some(
    (r) =>
      r.xDirectionUnit === RegionUnit.Centimeters && r.yDirectionUnit === RegionUnit.Centimeters
  );
}
