import { useLocalStorage } from "@vueuse/core";
import axios from "axios";
import type { CreateMFADeviceTokenResponse } from "../../../backend/src/auth/dto/mfa-device-token.dto";
import { getSHA256Hash } from "../utils/hash-helpers";
import { addNotification } from "../utils/notifications";

/**
 * Requests and stores a new MFA device token ID for the given email.
 */
export async function createMfaDeviceTokenId(email: string): Promise<void> {
  try {
    const { data } = await axios.get<CreateMFADeviceTokenResponse>(`/api/auth/mfa-device-token`);
    await setMfaDeviceTokenId(email, data.token);
  } catch {
    addNotification({
      type: "error",
      message: "Error remembering device",
    });
  }
}

/**
 * Returns the stored MFA device token ID for the given email, or an empty string if there is no
 * stored token ID.
 */
export async function getStoredMfaDeviceTokenId(email: string): Promise<string> {
  return useLocalStorage(await getLocalStorageKey(email), "").value;
}

/** Sets the MFA device token ID to store for the given email address. */
export async function setMfaDeviceTokenId(email: string, tokenId: string): Promise<void> {
  useLocalStorage(await getLocalStorageKey(email), "").value = tokenId;
}

async function getLocalStorageKey(email: string): Promise<string> {
  const sanitizedEmail = email.trim().toLowerCase();

  // Hash the email address to avoid storing it in plain text in local storage
  const emailHash = await getSHA256Hash(sanitizedEmail);

  return `mfa-device-token-id-${emailHash}`;
}
