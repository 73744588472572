<template>
  <div class="report-template-edit-slider">
    <div>{{ name }}</div>

    <Slider
      :model-value="modelValue"
      :min="min"
      :max="max"
      @update:model-value="(newValue: number) => emits('update:modelValue', newValue)"
    >
      <div class="value">
        {{ modelValue.toFixed(2) }}
      </div>
    </Slider>
  </div>
</template>

<script setup lang="ts">
import Slider from "@/components/Slider.vue";

interface Props {
  modelValue: number;
  name: string;
  min: number;
  max: number;
}

interface Emits {
  (event: "update:modelValue", newValue: number): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.report-template-edit-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.value {
  min-width: 36px;
}
</style>
