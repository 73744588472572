import { DateTime } from "luxon";

/**
 * The Luxon format string used for dates in the application. This gives dates in the format
 * "4 Jul 2022" which is concise as well as unambiguous regarding day and month ordering.
 */
export const DATE_FORMAT_STRING = "d LLL yyyy";

/**
 * The Luxon format string used for times in the application. This gives times in the format
 * "6:21 PM".
 */
export const TIME_FORMAT_STRING = "h:mm a";

/**
 * The Luxon format string used for times with seconds in the application. This gives times in the
 * format "6:21:49 PM".
 */
export const TIME_WITH_SECONDS_FORMAT_STRING = "h:mm:ss a";

/**
 * Formats a Luxon DateTime, JavaScript Date object, or ISO 8601 date into a string for display.
 * By default the date is shown without a time, but both date and time can be configured
 * independently. The date and time formats used are defined in this file, and result in output such
 * as "4 Jul 2022, 6:21 PM". This method ensures consistent and correct display of dates and times
 * and should be used for all dates and times displayed to the user.
 */
export function formatDateTime(
  datetime: Date | DateTime | string | null | undefined,
  args?: { includeDate?: boolean; includeTime?: boolean; includeSeconds?: boolean }
): string {
  if (datetime === null || datetime === undefined) {
    return "";
  }

  let dt = datetime;
  if (typeof dt === "string") {
    dt = DateTime.fromISO(dt);
  } else if (dt instanceof Date) {
    dt = DateTime.fromJSDate(dt);
  }

  const format = [];
  if (args?.includeDate ?? true) {
    format.push(DATE_FORMAT_STRING);
  }
  if (args?.includeTime ?? false) {
    if (args?.includeSeconds ?? false) {
      format.push(TIME_WITH_SECONDS_FORMAT_STRING);
    } else {
      format.push(TIME_FORMAT_STRING);
    }
  }

  return dt.toFormat(format.join(", "));
}

/**
 * Formats a Luxon DateTime, JavaScript Date object, or ISO 8601 date as a relative time, e.g.
 * "6 minutes ago" or "4 months ago". If the given value is null or undefined then the string
 * "Never" will be returned.
 */
export function formatRelativeTime(datetime: Date | DateTime | string | null | undefined): string {
  if (datetime === null || datetime === undefined) {
    return "Never";
  }

  let dt = datetime;
  if (typeof dt === "string") {
    dt = DateTime.fromISO(dt);
  } else if (dt instanceof Date) {
    dt = DateTime.fromJSDate(dt);
  }

  return (
    dt.toRelative({ unit: ["years", "months", "weeks", "days", "hours", "minutes", "seconds"] }) ??
    ""
  );
}
