// RWMA content used by the echocardiogram template. Also used in the frontend to display the RWMA
// SVGs and editing UI.

import { getDefaultFieldStyling } from "./report-structure";

export enum RWMARegion {
  BasalAnterior = "basalAnterior",
  BasalAnteroseptal = "basalAnteroseptal",
  BasalInferoseptal = "basalInferoseptal",
  BasalInferior = "basalInferior",
  BasalInferolateral = "basalInferolateral",
  BasalAnterolateral = "basalAnterolateral",
  MidAnterior = "midAnterior",
  MidAnteroseptal = "midAnteroseptal",
  MidInferoseptal = "midInferoseptal",
  MidInferior = "midInferior",
  MidInferolateral = "midInferolateral",
  MidAnterolateral = "midAnterolateral",
  ApicalAnterior = "apicalAnterior",
  ApicalSeptal = "apicalSeptal",
  ApicalInferior = "apicalInferior",
  ApicalLateral = "apicalLateral",
}

export const RWMAOptions = [
  { id: "normal", name: "Normal", sentence: "" },
  { id: "hypokinetic", name: "Hypokinetic", sentence: "" },
  { id: "akinetic", name: "Akinetic", sentence: "" },
  { id: "dyskinetic", name: "Dyskinetic", sentence: "" },
  { id: "aneurysmal", name: "Aneurysmal", sentence: "" },
  { id: "notWellSeen", name: "Not well seen", sentence: "" },
];

const rwmaOption = {
  type: "dropdown",
  options: RWMAOptions,
  isMultiSelect: false,
  styling: getDefaultFieldStyling(),
  isSentence: false,
} as const;

export const RWMAFields = [
  { id: RWMARegion.BasalAnterior, name: "Basal anterior", ...rwmaOption },
  { id: RWMARegion.BasalAnteroseptal, name: "Basal anteroseptal", ...rwmaOption },
  { id: RWMARegion.BasalInferoseptal, name: "Basal inferoseptal", ...rwmaOption },
  { id: RWMARegion.BasalInferior, name: "Basal inferior", ...rwmaOption },
  { id: RWMARegion.BasalInferolateral, name: "Basal inferolateral", ...rwmaOption },
  { id: RWMARegion.BasalAnterolateral, name: "Basal anterolateral", ...rwmaOption },
  { id: RWMARegion.MidAnterior, name: "Mid anterior", ...rwmaOption },
  { id: RWMARegion.MidAnteroseptal, name: "Mid anteroseptal", ...rwmaOption },
  { id: RWMARegion.MidInferoseptal, name: "Mid inferoseptal", ...rwmaOption },
  { id: RWMARegion.MidInferior, name: "Mid inferior", ...rwmaOption },
  { id: RWMARegion.MidInferolateral, name: "Mid inferolateral", ...rwmaOption },
  { id: RWMARegion.MidAnterolateral, name: "Mid anterolateral", ...rwmaOption },
  { id: RWMARegion.ApicalAnterior, name: "Apical anterior", ...rwmaOption },
  { id: RWMARegion.ApicalSeptal, name: "Apical septal", ...rwmaOption },
  { id: RWMARegion.ApicalInferior, name: "Apical inferior", ...rwmaOption },
  { id: RWMARegion.ApicalLateral, name: "Apical lateral", ...rwmaOption },
];
