<template>
  <div class="measurement-calculation-variable">
    <b class="variables-heading">
      <template v-if="calculation.variables.indexOf(variable) === 0"> Variables </template>
    </b>

    <Tooltip :content="getVariableNameTooltipText(variable)">
      <input
        class="variable-name-input"
        data-testid="variable-name-input"
        placeholder="Variable name"
        :class="{
          invalid: isVariableNameUsedMultipleTimes(calculation, variable),
        }"
        :value="variable.variableName"
        @input="updateVariableName($event)"
      />
    </Tooltip>

    <DropdownWidget
      class="dropdown"
      data-testid="measurement-calculation-type-dropdown"
      :model-value="variable.type"
      :items="[
        {
          value: MeasurementCalculationVariableType.Measurement,
          text: 'Use value of study measurement',
        },
        {
          value: MeasurementCalculationVariableType.PatientMetric,
          text: 'Use value of patient metric',
        },
        {
          value: MeasurementCalculationVariableType.ManuallyEntered,
          text: 'Use value entered by user',
        },
      ]"
      @update:model-value="setVariableType($event as MeasurementCalculationVariableType)"
    />

    <DropdownWidget
      v-if="variable.type === 'measurement'"
      class="dropdown"
      data-testid="measurement-name-dropdown"
      placeholder="Select measurement name"
      :model-value="variable.measurementName ?? ''"
      :items="measurementNameDropdownItems"
      @update:model-value="setVariableMeasurementName($event as MeasurementName)"
    />

    <DropdownWidget
      v-else-if="variable.type === 'patientMetric'"
      class="dropdown"
      data-testid="patient-metric-dropdown"
      placeholder="Select patient metric"
      :model-value="variable.patientMetric ?? ''"
      :items="patientMetricDropdownItems"
      @update:model-value="setVariablePatientMetric($event as CalculationPatientMetric)"
    />

    <input
      v-else
      class="manually-entered-name-input"
      data-testid="manually-entered-name-input"
      placeholder="Label for manually entered value"
      :value="variable.label"
      @input="updateVariableLabel($event)"
    />

    <DropdownWidget
      v-if="variable.type !== 'patientMetric'"
      class="dropdown"
      data-testid="measurement-unit-dropdown"
      placeholder="Select unit"
      :model-value="variable.unit ?? ''"
      :items="unitDropdownItems"
      :disabled="variable.type === 'measurement' && variable.measurementName === undefined"
      @update:model-value="setVariableUnit($event as MeasurementUnit)"
    />

    <div v-else style="width: 92px; padding-left: 8px">
      {{ getCalculationMetricDisplayUnit(variable.patientMetric) }}
    </div>

    <Tooltip content="Delete variable" placement="top">
      <button
        class="side-button"
        tabindex="-1"
        :data-testid="`measurement-calculation-${calculationIndex}-variable-${calculation.variables.indexOf(
          variable
        )}-delete`"
        @click="emits('delete')"
      >
        <FontAwesomeIcon icon="trash" size="sm" />
      </button>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import {
  CalculationPatientMetric,
  MeasurementCalculationVariable,
  MeasurementCalculationVariableType,
  getCalculationMetricDisplayText,
  getCalculationMetricDisplayUnit,
} from "../../../../backend/src/measurements/measurement-calculation-variable";
import { getMeasurementDisplayName } from "../../../../backend/src/measurements/measurement-display";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import {
  MeasurementUnit,
  getMeasurementPhysicalQuantity,
  getUnitDisplayText,
  getUnitPhysicalQuantity,
} from "../../../../backend/src/measurements/measurement-units";
import DropdownWidget from "../../components/DropdownWidget.vue";
import {
  MeasurementCalculation,
  isVariableNameUsedMultipleTimes,
} from "../../measurements/calculations/measurement-calculations";

interface Props {
  calculation: MeasurementCalculation;
  variable: MeasurementCalculationVariable;
  calculationIndex: number;
}

interface Emits {
  (name: "delete"): void;
  (name: "save"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const measurementNameDropdownItems = computed(() =>
  Object.values(MeasurementName)
    .filter((m) => !isCustomMeasurement(m))
    .sort((a, b) => a.localeCompare(b))
    .map((m) => ({
      value: m,
      text: getMeasurementDisplayName(m, "unindexed"),
    }))
);

const patientMetricDropdownItems = Object.values(CalculationPatientMetric).map((m) => ({
  value: m,
  text: getCalculationMetricDisplayText(m),
}));

const unitDropdownItems = computed(() =>
  Object.values(MeasurementUnit)
    .filter(
      (u) =>
        props.variable.type !== MeasurementCalculationVariableType.Measurement ||
        props.variable.measurementName === undefined ||
        getUnitPhysicalQuantity(u) ===
          getMeasurementPhysicalQuantity(props.variable.measurementName)
    )
    .map((u) => ({
      value: u,
      text: getUnitDisplayText(u),
    }))
);

function setVariableType(type: MeasurementCalculationVariableType): void {
  Object.assign(props.variable, {
    type,
    measurementName: undefined,
    unit: undefined,
    patientMetric: undefined,
    label: type === MeasurementCalculationVariableType.ManuallyEntered ? "" : undefined,
  });
  emits("save");
}

function setVariableMeasurementName(measurementName: MeasurementName): void {
  if (props.variable.type !== MeasurementCalculationVariableType.Measurement) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.variable.measurementName = measurementName;

  // If the unit is no longer valid for the type of the new measurement then clear it
  if (
    props.variable.unit !== undefined &&
    getUnitPhysicalQuantity(props.variable.unit) !== getMeasurementPhysicalQuantity(measurementName)
  ) {
    // eslint-disable-next-line vue/no-mutating-props
    props.variable.unit = undefined;
  }

  emits("save");
}

function setVariablePatientMetric(patientMetric: CalculationPatientMetric): void {
  if (props.variable.type !== MeasurementCalculationVariableType.PatientMetric) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.variable.patientMetric = patientMetric;

  emits("save");
}

function setVariableUnit(unit: MeasurementUnit): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.variable.unit = unit;
  emits("save");
}

function updateVariableName(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.variable.variableName = event.target.value;
  emits("save");
}

function updateVariableLabel(event: Event): void {
  if (
    !(event.target instanceof HTMLInputElement) ||
    props.variable.type !== MeasurementCalculationVariableType.ManuallyEntered
  ) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.variable.label = event.target.value;
  emits("save");
}

function getVariableNameTooltipText(variable: MeasurementCalculationVariable): string {
  if (isVariableNameUsedMultipleTimes(props.calculation, variable)) {
    return "This variable name is not unique";
  }

  return "";
}
</script>

<style scoped lang="scss">
.measurement-calculation-variable {
  background-color: var(--bg-color-2);
  display: grid;
  grid-template-columns: 80px 110px max-content 1fr auto auto;
  border: 1px solid var(--border-color-1);
  border-top: none;
  align-items: center;

  &:hover .side-button {
    opacity: 1;
  }

  input {
    height: 28px;
    padding: 0 8px;
    border: 0;
    border-radius: 0;
  }
}

.variables-heading {
  text-align: right;
  padding-right: 4px;
}

.manually-entered-name-input {
  height: 28px;
  padding: 0 0 0 8px;
  border: none;
  background: none;
}

.variable-name-input {
  &.invalid {
    background-color: darkred !important;
  }
}

.dropdown {
  height: 28px;
  border: none;
  border-radius: 0;
  min-width: 100px;
}

.side-button {
  height: 28px;
  width: 24px;
  background-color: var(--bg-color-3);
  opacity: 0;
  transition: opacity 100ms ease;
  border-radius: 0 !important;
}
</style>
