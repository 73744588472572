import coerce from "semver/functions/coerce";
import satisfies from "semver/functions/satisfies";
import UAParser from "ua-parser-js";

export function isBrowserSupported(): boolean {
  const minimumBrowserVersions: Record<string, string> = {
    Chrome: ">= 100",
    Edge: ">= 100",
    Safari: ">= 14",
    Firefox: ">= 102",
    Brave: ">= 100",
  };

  const browser = new UAParser().getBrowser();
  if (browser.name === undefined) {
    return false;
  }

  const minimumVersion = minimumBrowserVersions[browser.name];
  if (!minimumVersion) {
    return false;
  }

  const browserVersion = coerce(browser.version);
  if (!browserVersion || !satisfies(browserVersion, minimumVersion)) {
    return false;
  }

  return true;
}

export function getBrowserName(): string {
  const browser = new UAParser().getBrowser();

  return `${browser.name} ${browser.version}`;
}

export function isTouchDevice(): boolean {
  return ["mobile", "tablet"].includes(new UAParser().getDevice().type ?? "");
}
