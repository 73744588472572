import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import { signOut } from "../auth/authentication";
import type { Study, StudyClip } from "../utils/study-data";

/**
 * Returns the URL to the requested type of image data for a study clip. This can return URLs to
 * the webp-packed content, WebP thumbnails, WebP animated thumbnails, and WebP individual frames.
 */
export function getStudyClipImageDataUrl(
  study: Study,
  clip: StudyClip,
  type: StudyClipImageDataType
): string | null {
  if (clip.processedAt === null) {
    return null;
  }

  let url = `/api/studies/${study.id}/clips/${clip.id}/`;

  const series = study.series.find((s) => s.id === clip.seriesId);

  if (
    type === StudyClipImageDataType.AllFrames &&
    (clip.modality === "CT" || clip.modality === "MR") &&
    series !== undefined
  ) {
    url += `series-image-data?seriesInstanceUid=${series.seriesInstanceUid}`;
  } else {
    const urlSearchParams = new URLSearchParams({ type });
    url += `image-data?${urlSearchParams.toString()}`;
  }

  return url;
}

/**
 * Starts loading the image data for the specified clip. Loaded frames will be put into the
 * given `frames` array when they are ready, and the speed at which this happens depends on
 * download speeds and image data sizes. The in-progress load can be aborted via the signal.
 * If an error occurs then the load will be retried up to 3 times.
 */
export async function fetchStudyClipImageData(
  study: Study,
  clip: StudyClip,
  abortSignal: AbortSignal,
  frames: (HTMLImageElement | undefined)[],
  retryCount = 0
): Promise<void> {
  const url = getStudyClipImageDataUrl(study, clip, StudyClipImageDataType.AllFrames);
  if (url === null) {
    return;
  }

  try {
    const response = await fetch(url, { method: "get", signal: abortSignal });

    const body = response.body;
    if (!body || !response.ok) {
      if (response.status === 401) {
        await signOut();
      }

      return;
    }

    const reader = body.getReader();

    function onFrameCountReady(frameCount: number): void {
      frames.splice(0, frames.length);

      for (let i = 0; i < frameCount; i++) {
        frames.push(undefined);
      }
    }

    function onFrameReady(frameIndex: number, frame: HTMLImageElement): void {
      frames[frameIndex] = frame;
    }

    const streamParser = new StudyClipImageDataStreamParser(
      Number(response.headers.get("Content-Length")),
      onFrameCountReady,
      onFrameReady
    );

    while (true) {
      const { value } = await reader.read();
      if (!value) {
        break;
      }

      await streamParser.ingest(value);
    }
  } catch (e) {
    // If the fetch was intentionally aborted then no need to retry
    if (abortSignal.aborted) {
      return;
    }

    if (retryCount >= 3) {
      throw e;
    }

    return fetchStudyClipImageData(study, clip, abortSignal, frames, retryCount + 1);
  }
}

/**
 * Progressively parses chunks of data from a clip's webp-packed file, emitting individual frames as
 * they become ready. This allows for progressive loading of image data in the app.
 */
class StudyClipImageDataStreamParser {
  constructor(
    contentLength: number,
    private readonly onFrameCountReady: (frameCount: number) => void,
    private readonly onFrameReady: (frameIndex: number, frame: HTMLImageElement) => void
  ) {
    this.data = new Uint8Array(contentLength);
  }

  async ingest(data: Uint8Array): Promise<void> {
    this.data.set(data, this.receivedByteCount);
    this.receivedByteCount += data.length;

    this.parseHeader();
    if (!this.header) {
      return;
    }

    const receivedFrameCount = this.getReceivedFrameCount();
    if (receivedFrameCount === undefined) {
      return;
    }

    // Keep emitting frames until all received frames have been emitted
    const blob = new Blob([this.data]);
    while (this.emittedFrameCount < receivedFrameCount) {
      const blobStart =
        this.header.length +
        this.header.frameSizes
          .slice(0, this.emittedFrameCount)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const blobEnd = blobStart + this.header.frameSizes[this.emittedFrameCount];

      const img = new Image();
      const frameImage = await new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = (): void => resolve(img);
        img.onerror = reject;

        // Create object URL for the data
        img.src = URL.createObjectURL(blob.slice(blobStart, blobEnd, "image/webp"));
      });

      this.onFrameReady(this.emittedFrameCount, frameImage);

      this.emittedFrameCount++;
    }
  }

  private parseHeader(): void {
    if (this.header) {
      return;
    }

    // First four bytes is the number of the frames in the file
    if (this.receivedByteCount < 4) {
      return;
    }

    const dataview = new DataView(this.data.buffer);

    // Read frame count
    const frameCount = dataview.getUint32(0, true);

    // Check header is fully loaded
    const headerLength = (frameCount + 1) * 4;
    if (this.receivedByteCount < headerLength) {
      return;
    }

    // Read frame sizes
    const frameSizes: number[] = [];
    for (let i = 0; i < frameCount; i++) {
      frameSizes.push(dataview.getUint32((1 + i) * 4, true));
    }

    this.header = { frameCount, frameSizes, length: headerLength };

    this.onFrameCountReady(this.header.frameCount);
  }

  private getReceivedFrameCount(): number | undefined {
    if (!this.header) {
      return;
    }

    let sum = this.header.length;

    let i = 0;
    for (; i < this.header.frameCount; i++) {
      sum += this.header.frameSizes[i];
      if (sum > this.receivedByteCount) {
        break;
      }
    }

    return i;
  }

  private readonly data: Uint8Array;
  private header?: { frameCount: number; frameSizes: number[]; length: number };
  private emittedFrameCount = 0;
  private receivedByteCount = 0;
}
