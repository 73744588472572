<template>
  <div ref="rootElement" class="resizing-textbox">
    <textarea
      ref="textareaElement"
      tabindex="0"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :style="{ fontSize, textAlign, fontWeight, fontStyle, color }"
      data-testid="resizing-textbox"
      @input="onInput"
      @keydown.enter="emits('enter')"
      @focusin="emits('focus-changed', true)"
      @focusout="emits('focus-changed', false)"
      @keydown.meta.z.exact.prevent="undo"
      @keydown.ctrl.z.exact.prevent="undo"
      @keydown.meta.shift.z.exact.prevent="redo"
      @keydown.ctrl.y.exact.prevent="redo"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useFocus } from "@vueuse/core";
import type * as CSS from "csstype";
import { nextTick, onMounted, ref, watch } from "vue";

interface Props {
  modelValue: string;
  textAlign?: CSS.Property.TextAlign;
  fontSize?: string;
  fontWeight?: CSS.Property.FontWeight;
  fontStyle?: CSS.Property.FontStyle;
  color?: CSS.Property.Color;
  placeholder?: string;
  disabled?: boolean;
  scaleFactor: number;
  allowNewlines?: boolean;
  initialFocus?: boolean;
}

interface Emits {
  (name: "update:modelValue", newValue: string): void;
  (name: "enter"): void;
  (name: "focus-changed", focused: boolean): void;
  (name: "click-outside", event: PointerEvent): void;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  disabled: false,
  textAlign: "left",
  fontWeight: "inherit",
  fontStyle: "inherit",
  color: "inherit",
  fontSize: "inherit",
  allowNewlines: false,
  initialFocus: false,
});
const emits = defineEmits<Emits>();

// Parent wrapper div is required because textareas can overflow their parent div
// so workaround by creating a parent here and then force changing that height
const textareaElement = ref<HTMLTextAreaElement | null>(null);
const rootElement = ref<HTMLElement | null>(null);

onMounted(adjustVerticalSize);

function onInput(): void {
  if (textareaElement.value === null) {
    return;
  }

  // If it's more than a second since the last edit add an undo state for the current value prior to
  // this update
  const delta = performance.now() - lastEditedAt;
  if (delta > 1000 || isLastActionUndo.value) {
    pushCurrentValueToStack(undoStack.value);
    isLastActionUndo.value = false;
  }
  lastEditedAt = performance.now();

  // Clear the redo stack now that new changes have been made
  redoStack.value = [];

  if (!props.allowNewlines) {
    textareaElement.value.value = textareaElement.value.value.replace(/\n/g, "");
  }

  adjustVerticalSize();
  emits("update:modelValue", textareaElement.value.value);
}

const explicitHeight = ref("0");

function adjustVerticalSize(): void {
  if (textareaElement.value === null || rootElement.value === null) {
    return;
  }

  explicitHeight.value = "0";

  void nextTick(() => {
    explicitHeight.value = `${Math.max(textareaElement.value!.scrollHeight, props.scaleFactor)}px`;
  });
}

watch(() => props.fontSize, adjustVerticalSize);

//
// Undo/redo is implemented manually so it can be made to work with text inserted from the
// sentence library.
//

const undoStack = ref<string[]>([]);
const redoStack = ref<string[]>([]);
let lastEditedAt = 0;
const isLastActionUndo = ref(false);

function undo(): void {
  let lastValue: string | undefined = "";

  // Keep popping until a value different to the current value is found
  do {
    lastValue = undoStack.value.pop();
  } while (lastValue === props.modelValue);

  if (lastValue === undefined) {
    return;
  }

  lastEditedAt = performance.now();

  pushCurrentValueToStack(redoStack.value);

  emits("update:modelValue", lastValue);
  isLastActionUndo.value = true;
}

function redo(): void {
  const lastValue = redoStack.value.pop();
  if (lastValue === undefined) {
    return;
  }

  lastEditedAt = performance.now();

  emits("update:modelValue", lastValue);

  pushCurrentValueToStack(undoStack.value);
}

function pushCurrentValueToStack(stack: string[]): void {
  stack.push(props.modelValue);

  // Enforce a maximum stack size to cap memory usage
  if (stack.length > 100) {
    stack.shift();
  }
}

const { focused } = useFocus(textareaElement, { initialValue: props.initialFocus });

defineExpose({
  isFocused() {
    return focused.value;
  },

  focus() {
    textareaElement.value?.setSelectionRange(props.modelValue.length, props.modelValue.length);
    focused.value = true;
  },

  // Inserts text into this textbox, replacing the current selection (if any). The `replacePartial`
  // value is used when transcribing to remove the last bit of transcribed text and replace it with
  // new dictated text which is considered to be more accurate.
  insertText(text: string, replacePartial = ""): void {
    if (textareaElement.value === null) {
      return;
    }

    let before = props.modelValue.substring(0, textareaElement.value.selectionStart);
    const after = props.modelValue.substring(textareaElement.value.selectionEnd);

    pushCurrentValueToStack(undoStack.value);

    // If the 'before' text ends with the partial dictation result then remove it before doing the
    // insertion
    if (before.endsWith(replacePartial)) {
      before = before.substring(0, before.length - replacePartial.length);
    }

    emits("update:modelValue", `${before}${text}${after}`);

    focused.value = true;

    adjustVerticalSize();

    // Put cursor at the end of the inserted text
    void nextTick().then(() =>
      textareaElement.value?.setSelectionRange(
        before.length + text.length,
        before.length + text.length
      )
    );
  },
});

onClickOutside(textareaElement, (event) => emits("click-outside", event));
</script>

<style scoped lang="scss">
.resizing-textbox {
  position: relative;
  height: v-bind("explicitHeight");
}

textarea {
  overflow: hidden;
  resize: none;
  background: none;
  padding: 0;
  color: black;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: inherit;
}
</style>
