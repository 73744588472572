import { PatientNameFormat } from "../tenants/patient-name-format";

/**
 * Takes a DICOM name in the PN (Person Name) value representation and converts it to a single
 * string using the specified format.
 */
export function formatDicomName(
  name: string[],
  patientNameFormat = PatientNameFormat.FirstNameMiddleNameLastName
): string {
  const [familyName, givenName, middleName, namePrefix, nameSuffix] = name;

  if (patientNameFormat === PatientNameFormat.LastNameFirstNameMiddleName) {
    return [familyName, [givenName, middleName].filter(Boolean).join(" ")]
      .filter(Boolean)
      .join(", ");
  }

  return [namePrefix, givenName, middleName, familyName, nameSuffix]
    .filter(Boolean)
    .join(" ")
    .trim();
}
