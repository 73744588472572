import sysend from "sysend";
import { ref } from "vue";
import { AuditAction } from "../../../../backend/src/audit/audit-actions";
import router from "../../router";
import { createAuditLogEvent } from "../../utils/audit-log-utils";
import { onPrimaryWindowMessageReceived } from "./primary-window-messages";
import { getLargestSecondScreenLocation } from "./window-management-helpers";

export enum SecondaryWindowState {
  Closed = "closed",
  Opening = "opening",
  Open = "open",
}

export enum WindowType {
  /**
   * A primary window of the application that can optionally have an associated secondary window.
   */
  Primary = "primary",

  /** A secondary window containing extra clips for the study that's open in the primary window. */
  ExtraClips = "extraClips",

  /**
   * A secondary window showing a different study to the primary window for the same patient,
   * allowing for comparison of studies.
   */
  SerialStudy = "serialStudy",
}

const secondaryWindowState = ref(SecondaryWindowState.Closed);
const secondaryWindowType = ref<WindowType | null>(null);

/** Returns the current state of the secondary window. */
export function getSecondaryWindowState(): SecondaryWindowState {
  return secondaryWindowState.value;
}

/**
 * Returns the type of the secondary window open for this primary window, or `null` if no secondary
 * window is open.
 */
export function getSecondaryWindowType(): WindowType | null {
  if (secondaryWindowState.value !== SecondaryWindowState.Open) {
    return null;
  }

  return secondaryWindowType.value;
}

// Keep track of the clips selected in the secondary window
const selectedClipIds = ref<string[]>([]);
onPrimaryWindowMessageReceived(
  "selected-clips-changed",
  (message) => (selectedClipIds.value = message.selectedClipIds)
);

/**
 * Returns whether the specified study clip ID is selected in the secondary window. This is used
 * to outline it on the clip list in the primary window.
 */
export function isClipSelectedInSecondaryWindow(clipId: string): boolean {
  return selectedClipIds.value.includes(clipId);
}

/**
 * Opens a secondary window. If the type of the secondary window is `SerialStudy` then the ID of
 * the different study to open must be passed.
 */
export async function openSecondaryWindow(
  options:
    | { type: WindowType.SerialStudy; studyId: string }
    | { type: WindowType.ExtraClips; isStressModeEnabled: boolean }
): Promise<void> {
  // Secondary windows can only be opened from the primary window
  if (getWindowType() !== WindowType.Primary) {
    return;
  }

  // If this is a request for an extra clips window but an extra clips window is already open then
  // there's nothing to do
  if (
    options.type === WindowType.ExtraClips &&
    secondaryWindowType.value === WindowType.ExtraClips &&
    secondaryWindowState.value !== SecondaryWindowState.Closed
  ) {
    return;
  }

  secondaryWindowState.value = SecondaryWindowState.Opening;
  secondaryWindowType.value = options.type;

  let windowFeatures: string[] | undefined = undefined;

  // Request the location bar be hidden, although most browsers don't allow this for security
  // reasons
  windowFeatures = ["location=0"];

  // Attempt to use the Window Management API to place the secondary window maximized on the
  // largest secondary monitor
  const largestScreen = await getLargestSecondScreenLocation();
  if (largestScreen !== null) {
    windowFeatures.push(
      `left=${largestScreen.left}`,
      `top=${largestScreen.top}`,
      `width=${largestScreen.width}`,
      `height=${largestScreen.height}`
    );
  }

  const primaryWindowId = sysend.id;

  // Build the URL for the secondary window
  let url = `${window.location.origin}/secondary-window/${primaryWindowId}`;
  if (options.type === WindowType.SerialStudy) {
    url = `${url}/serial-study/${options.studyId}`;
  } else {
    url = `${url}/extra-clips`;
  }

  // Open the secondary window
  window.open(url, `heartlab-secondary-window-${primaryWindowId}`, windowFeatures.join(","));

  // Create an audit log event that the secondary window opened
  await createAuditLogEvent({ action: AuditAction.SecondaryWindowOpened, details: options });
}

let secondaryWindowId = "";

/** Returns the `sysend` ID of the secondary window that can be used to send messages to it. */
export function getSecondaryWindowId(): string {
  return secondaryWindowId;
}

onPrimaryWindowMessageReceived("secondary-window-opened", (message) => {
  secondaryWindowState.value = SecondaryWindowState.Open;
  secondaryWindowId = message.secondaryWindowId;
});

// Listen for when the secondary window closes and reset state appropriately
sysend.track("close", (data) => {
  if (data.id !== secondaryWindowId) {
    return;
  }

  secondaryWindowState.value = SecondaryWindowState.Closed;
  secondaryWindowId = "";
  selectedClipIds.value = [];

  // When the secondary window closes, create a message from it regarding the close. This is sent
  // from the primary window to itself, so that the same message handling system can be used, and it
  // looks like the message came from the secondary window.
  sysend.post(sysend.id, { type: "secondary-window-closed" });

  // Create an audit log event that the window closed
  void createAuditLogEvent({ action: AuditAction.SecondaryWindowClosed });
});

/**
 * Returns the type of the current window, which can be a primary window or a type of secondary
 * window.
 */
export function getWindowType(): WindowType {
  switch (router.currentRoute.value.name) {
    case "secondary-window-extra-clips":
      return WindowType.ExtraClips;
    case "secondary-window-serial-study":
      return WindowType.SerialStudy;
    default:
      return WindowType.Primary;
  }
}
