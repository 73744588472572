<template>
  <div
    ref="rootElement"
    class="report-structure-draggable-item"
    :class="{ editable, 'has-delete-button': showDeleteButton }"
  >
    <div class="drag-handle">
      <FontAwesomeIcon icon="grip-lines-vertical" class="rpt-text-normal" />
    </div>

    <!-- eslint-disable vue/no-mutating-props -->
    <div class="field-input" :style="nameFieldWidth !== 'auto' ? { maxWidth: nameFieldWidth } : {}">
      <input
        v-model="name"
        :placeholder="placeholder"
        :disabled="!editable"
        class="rpt-text-normal"
        @keydown.enter="emits('enter-pressed')"
      />
    </div>

    <slot />

    <div v-if="editable && showDeleteButton" class="delete-button-container">
      <div class="delete-icon">
        <Tooltip :content="deleteButtonTooltip">
          <FontAwesomeIcon icon="trash" class="rpt-text-normal" @click="emits('delete')" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";

interface Props {
  modelValue: string;
  placeholder: string;
  editable: boolean;
  nameFieldWidth?: string;
  showDeleteButton?: boolean;
  deleteButtonTooltip?: string;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
  (event: "enter-pressed"): void;
  (event: "delete"): void;
}

const name = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: string) {
    emits("update:modelValue", newValue);
  },
});

const props = withDefaults(defineProps<Props>(), {
  nameFieldWidth: "auto",
  showDeleteButton: false,
  deleteButtonTooltip: "",
});
const emits = defineEmits<Emits>();

const rootElement = ref<HTMLDivElement>();
defineExpose({ rootElement });
</script>

<style scoped lang="scss">
.report-structure-draggable-item {
  display: flex;
  align-items: stretch;
  height: 2.2em;

  &:not(.editable) {
    .drag-handle {
      pointer-events: none;
    }
  }

  > :last-child {
    border: 0.1em solid var(--report-widget-border-color);
    border-left-width: 0;
    border-radius: 0 0.2em 0.2em 0;
  }

  &:not(&.has-delete-button) {
    > :last-child {
      border-left-width: 0.1em;
    }
  }

  &.has-delete-button {
    &:hover {
      .delete-icon {
        opacity: 1;
      }
    }
  }
}

.drag-handle {
  color: var(--report-accent-color-1);
  transition: color 100ms ease;
  width: 1.8em;
  background-color: var(--report-widget-bg-color);
  border-right: 0.1em solid var(--report-widget-border-hover);
  display: grid;
  place-content: center;
  cursor: grab;
  border: 0.1em solid var(--report-widget-border-color);
  border-radius: 0.2em 0 0 0.2em;

  &:hover {
    color: var(--report-accent-color-2);
  }
}

.delete-button-container {
  background: white;
  display: grid;
  place-content: center;
  padding: 0 0.4em;
}

.delete-icon {
  opacity: 0;
  transition: opacity 100ms ease;

  svg {
    cursor: pointer;
    transition: color 100ms ease;
    color: var(--report-accent-color-1);

    &:hover {
      color: var(--report-accent-color-2);
    }
  }
}
</style>
