<template>
  <ShadowDOMWrap
    v-if="scaleFactor !== 0"
    :styles="reportStyles"
    :use-shadow-root="isGeneratingHtml"
  >
    <!-- This element is used by E2E tests to trigger and wait for analytics saves -->
    <div hidden data-testid="save-report-analytics" @click="saveAnalytics">
      {{ analyticsSaveCount }}
    </div>

    <div
      class="report"
      :data-testid="isGeneratingHtml ? 'report-html-generator' : 'report'"
      :data-test-analytics-total-time="report.analyticsTotalTime"
      :data-test-analytics-report-pane-clicks="report.analyticsReportPaneClickCount"
      :data-test-analytics-keystroke-count="report.analyticsKeystrokeCount"
      :data-test-analytics-sentence-library-click-count="report.analyticsSentenceLibraryClickCount"
      :style="{
        minWidth: '79.4em',
        maxWidth: '79.4em',
        fontSize: `${10 * scaleFactor}px`,
      }"
      @click="analyticsReportPaneClickCount++"
      @keydown="analyticsKeystrokeCount++"
    >
      <!-- It's mandatory to use inline styles for the header content. -->
      <div
        ref="header"
        :style="{
          fontFamily,
          width: sectionWidth,
          padding: `0 ${reportStructure.margins.horizontal}em`,
          display: 'grid',
          gridTemplateColumns: '28% 40% 28%',
          gap: '2%',

          // The bottom padding here is used to preview the top margin setting
          ...(isGeneratingHtml
            ? { fontSize: '10px' }
            : { paddingBottom: `${reportStructure.margins.top}em` }),
        }"
      >
        <ReportLogo
          v-if="reportStructure.logoDataUri !== '' || isEditingReportStructure"
          v-model="reportStructure.logoDataUri"
          :mode="mode"
          @update:model-value="emits('mutate-structure', createLogoMutation())"
        />
        <div v-else />

        <div v-if="isEditingReportStructure" class="rpt-fancy-input">
          <ResizingTextbox
            v-model="reportStructure.title"
            data-testid="report-template-title"
            :font-size="`${reportStructure.fontSizes.title}em`"
            font-weight="bold"
            text-align="center"
            placeholder="Report title"
            allow-newlines
            :scale-factor="scaleFactor"
            :disabled="!isEditingReportStructure"
            @update:model-value="emits('mutate-structure', createTitleMutation())"
          />
        </div>
        <div v-else style="text-align: center">
          <strong
            :style="`font-size: ${reportStructure.fontSizes.title}em`"
            data-testid="report-template-title"
          >
            <TextWithLineBreaks :text="reportStructure.title" />
          </strong>

          <template v-if="displayMode === StudyReportType.Preliminary">
            <br />
            <strong
              :style="{ color: 'red', fontSize: `${reportStructure.fontSizes.title * (2 / 3)}em` }"
              data-testid="report-preliminary-header"
            >
              PRELIMINARY
            </strong>
          </template>
        </div>

        <div
          v-if="isEditingReportStructure"
          class="rpt-fancy-input"
          style="position: relative; padding-bottom: 3em"
          @mouseover="isHeaderTextHovered = true"
          @mouseleave="isHeaderTextHovered = false"
        >
          <ResizingTextbox
            v-model="reportStructure.headerText"
            :text-align="reportStructure.headerTextAlignment"
            :font-size="`${reportStructure.fontSizes.header}em`"
            placeholder="Header text"
            allow-newlines
            :scale-factor="scaleFactor"
            :disabled="!isEditingReportStructure"
            @focus-changed="isHeaderTextFocused = $event"
            @update:model-value="emits('mutate-structure', createHeaderTextMutation())"
          />

          <div class="align-icons" :class="{ visible: isHeaderTextHovered || isHeaderTextFocused }">
            <FontAwesomeIcon
              icon="align-left"
              class="align-icon"
              :class="{ selected: reportStructure.headerTextAlignment === 'left' }"
              @click="updateAlignment(ReportTemplateHeaderTextAlignment.Left)"
            />
            <FontAwesomeIcon
              icon="align-center"
              class="align-icon"
              :class="{ selected: reportStructure.headerTextAlignment === 'center' }"
              @click="updateAlignment(ReportTemplateHeaderTextAlignment.Center)"
            />
            <FontAwesomeIcon
              icon="align-right"
              class="align-icon"
              :class="{ selected: reportStructure.headerTextAlignment === 'right' }"
              @click="updateAlignment(ReportTemplateHeaderTextAlignment.Right)"
            />
          </div>
        </div>
        <div
          v-else
          :style="{
            textAlign: reportStructure.headerTextAlignment,
            fontSize: `${reportStructure.fontSizes.header}em`,
            display: 'flex',
            justifyContent: 'flex-end',
          }"
        >
          <div>
            <TextWithLineBreaks :text="reportStructure.headerText" bold-first-line />
          </div>
        </div>
      </div>

      <div
        ref="body"
        class="rpt-report-body"
        :class="{ 'rpt-left-pane-visible': reportStructure.layout.isLeftPaneVisible }"
        data-testid="rpt-report-body"
        :style="{
          '--report-font-family': fontFamily,

          '--report-font-size-normal': `${reportStructure.fontSizes.normal}em`,
          '--report-font-size-heading': `${reportStructure.fontSizes.heading}em`,
          '--report-font-size-measurement': `${reportStructure.fontSizes.measurement}em`,
          '--report-font-size-table': `${reportStructure.fontSizes.table}em`,

          '--report-spacing-section': `${reportStructure.spacing.section}em`,
          '--report-spacing-heading': `${reportStructure.spacing.heading}em`,
          '--report-spacing-field': `${reportStructure.spacing.field}em`,
          '--report-spacing-table': `${reportStructure.spacing.table}em`,

          ...(isGeneratingHtml
            ? { fontSize: '10px' }
            : {
                width: sectionWidth,
                margin: `0 ${reportStructure.margins.horizontal}em`,
              }),
        }"
      >
        <div v-if="reportStructure.layout.isLeftPaneVisible" class="rpt-left-pane">
          <div class="rpt-left-pane-box">
            <div
              v-for="detail in getTopBoxDetails(study).filter((item) => item.value !== '')"
              :key="detail.key"
              class="rpt-left-pane-item"
            >
              <div class="rpt-text-normal rpt-comment-heading">{{ detail.key }}</div>
              <div class="rpt-text-normal">{{ detail.value }} &nbsp;</div>
            </div>
          </div>

          <div class="rpt-left-pane-box">
            <div
              v-for="detail in getBottomBoxDetails(
                study,
                formatDateTime(reportedAt, { includeTime: true }),
                userList
              ).filter((item) => item.value !== '')"
              :key="detail.key"
              class="rpt-left-pane-item"
            >
              <div class="rpt-text-normal rpt-comment-heading">{{ detail.key }}</div>
              <div class="rpt-text-normal">{{ detail.value }} &nbsp;</div>
            </div>
          </div>
        </div>

        <div v-if="report.type === StudyReportType.Amendment" class="rpt-amendment-container">
          <div class="rpt-text-normal">
            <strong>
              Amended by {{ currentUser.name }} on
              {{ formatDateTime(reportedAt, { includeTime: true }) }}
            </strong>
            <template v-if="!isEditingReportContent && report.content.amendmentReason">
              <b>:</b>
              {{ report.content.amendmentReason }}
            </template>
          </div>

          <template v-if="isEditingReportContent">
            <div class="rpt-fancy-input">
              <!-- eslint-disable vue/no-mutating-props -->
              <input
                v-model="report.content.amendmentReason"
                data-testid="amendment-reason-textfield"
                :placeholder="`Enter the reason for amending this report. ${
                  currentTenant.isAmendmentReasonRequired ? 'Required' : 'Optional'
                }.`"
                class="rpt-text-normal"
                @update:model-value="emits('update-report-content')"
              />
            </div>
          </template>
        </div>

        <template
          v-for="component in reportStructure.layout.components.filter((c) => c.isVisible)"
          :key="component"
        >
          <ReportSections
            v-if="component.name === ReportComponent.Sections"
            :study="study"
            :report="report"
            :mode="mode"
            :scale-factor="scaleFactor"
            @update-report-content="emits('update-report-content')"
            @update-patient-info="emits('update-patient-info')"
            @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
            @section-comment-field-action="
              (details) => emits('section-comment-field-action', details)
            "
          />

          <div v-if="reportStructure.layout.isLeftPaneVisible" style="clear: both" />

          <ReportMeasurements
            v-if="component.name === ReportComponent.MeasurementGroups"
            :study="study"
            :report="report"
            :mode="mode"
            @update-report-content="emits('update-report-content')"
            @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
          />

          <ReportSignature
            v-if="
              component.name === ReportComponent.Signature &&
              displayMode !== StudyReportType.Preliminary
            "
            :signature-data="signatureData"
            :mode="mode"
          />
        </template>
      </div>

      <ReportFooter
        :mode="mode"
        :study="study"
        :report="report"
        :report-structure="reportStructure"
        :font-family="fontFamily"
        :scale-factor="scaleFactor"
        @update-footer-element="footer = $event"
        @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
      />
    </div>
  </ShadowDOMWrap>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useIdle, useIntervalFn, useTimeoutFn, useTimestamp } from "@vueuse/core";
import axios from "axios";
import { DateTime } from "luxon";
import { computed, nextTick, onBeforeUnmount, ref, watch } from "vue";
import {
  ReportComponent,
  ReportTemplateFont,
  ReportTemplateHeaderTextAlignment,
} from "../../../backend/src/reporting/report-structure";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { currentTenant, currentUser } from "../auth/current-session";
import ResizingTextbox from "../components/ResizingTextbox.vue";
import { setThemeCssVariablesOnElement } from "../themes";
import { formatDateTime } from "../utils/date-time-utils";
import { SignatureData, Study, StudyReport } from "../utils/study-data";
import { useUserList } from "../utils/users-list";
import ReportFooter from "./ReportFooter.vue";
import ReportLogo from "./ReportLogo.vue";
import ReportMeasurements from "./ReportMeasurements.vue";
import ReportSections from "./ReportSections.vue";
import ReportSignature from "./ReportSignature.vue";
import reportStyles from "./ReportStyles.css?inline";
import ShadowDOMWrap from "./ShadowDOMWrap.vue";
import TextWithLineBreaks from "./TextWithLineBreaks.vue";
import type { HTMLReportContent } from "./generate-report-pdf";
import {
  ReportContentMode,
  getBottomBoxDetails,
  getTopBoxDetails,
  type ReportSectionCommentFieldEventDetails,
} from "./report-content";
import {
  createHeaderTextAlignmentMutation,
  createHeaderTextMutation,
  createLogoMutation,
  createTitleMutation,
  type ReportStructureMutation,
} from "./report-structure-mutations";

interface Props {
  study: Study;
  report: StudyReport;
  signatureData: SignatureData;
  mode: ReportContentMode;
  analyticsEnabled?: boolean;
  displayMode: StudyReportType;
  containerWidth?: number;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "update-patient-info"): void;
  (event: "html-updated", htmlReportContent: HTMLReportContent): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
  (event: "section-comment-field-action", details: ReportSectionCommentFieldEventDetails): void;
}

const props = withDefaults(defineProps<Props>(), {
  containerWidth: 794,
  analyticsEnabled: true,
});
const emits = defineEmits<Emits>();

const { userList, isUserListLoaded } = useUserList();

// 794px is A4 page width at 96 PPI, so we consider 794px as the 1:1 level. All scaling is done
// using `font-size` and em units based on this scale factor with a base font-size of 10px, which is
// why this report is given a min/max width of 79.4em in its CSS below.
const scaleFactor = computed(() => props.containerWidth / 794);

const isHeaderTextHovered = ref(false);
const isHeaderTextFocused = ref(false);

const isGeneratingHtml = computed(() => props.mode === ReportContentMode.GenerateHTML);
const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);
const isEditingReportStructure = computed(
  () => props.mode === ReportContentMode.EditReportStructure
);

const reportedAt = ref(DateTime.now());

// Keep the timestamp up to date
useTimeoutFn(() => (reportedAt.value = DateTime.now()), 60000);

const reportStructure = computed(() => props.report.reportTemplateVersion.structure);

// CSS calculation for the width of a section
const sectionWidth = computed(
  () => `calc(100% - ${reportStructure.value.margins.horizontal * 2}em)`
);

// The CSS font-family to use for the selected report template font
const fontFamily = computed(
  () =>
    ({
      [ReportTemplateFont.SansSerif]: `Helvetica, Arial, "Liberation Sans", sans-serif`,
      [ReportTemplateFont.Gilmer]: "Gilmer",
    })[reportStructure.value.font]
);

const header = ref<HTMLElement | null>(null);
const body = ref<HTMLElement | null>(null);
const footer = ref<HTMLElement | null>(null);

async function emitReportHTML(): Promise<void> {
  if (!isUserListLoaded.value) {
    return;
  }

  await nextTick();

  if (!header.value || !body.value || !footer.value) {
    return;
  }

  setThemeCssVariablesOnElement(body.value);

  const headerContent = header.value.outerHTML;
  const bodyContent = `${body.value.outerHTML}<style>${reportStyles}</style>`;
  const footerContent = footer.value.outerHTML;

  const margins = { ...reportStructure.value.margins };

  // The top and bottom margins for the body are the height of the header or footer plus the
  // configured top or bottom margin on the report template, which ensures that these margins
  // always leave enough space for the header and footer content
  margins.top += header.value.clientHeight / 10;
  margins.bottom += footer.value.clientHeight / 10;

  emits("html-updated", {
    header: headerContent,
    body: bodyContent,
    footer: footerContent,
    margins,
  });
}

if (isGeneratingHtml.value) {
  watch(
    () => [props.study, props.report.content, isUserListLoaded, props.displayMode],
    emitReportHTML,
    { deep: true, immediate: true }
  );
}

//
// Report analytics. This includes time spent open, clicks, keys pressed, etc.
//

const shouldSaveAnalytics = computed(
  () => props.analyticsEnabled && props.mode === ReportContentMode.EditReportContent
);

let analyticsTotalTime = 0;
let analyticsReportPaneClickCount = 0;
let analyticsKeystrokeCount = 0;

const analyticsSaveCount = ref(0);

function updateAlignment(alignment: ReportTemplateHeaderTextAlignment) {
  emits("mutate-structure", createHeaderTextAlignmentMutation(alignment));
}

async function saveAnalytics(): Promise<void> {
  if (!shouldSaveAnalytics.value) {
    return;
  }

  // Accumulate total time currently in-progress
  if (!isIdle.value) {
    analyticsTotalTime += currentTimestamp.value - interactionStartedAt;

    // Reset the current interaction time so that the next analytics save counts up from this save
    interactionStartedAt = currentTimestamp.value;
  }

  // Check that there's actually something to save
  if (
    analyticsReportPaneClickCount === 0 &&
    analyticsKeystrokeCount === 0 &&
    analyticsTotalTime === 0
  ) {
    return;
  }

  try {
    await axios.patch(
      `/api/studies/${props.study.id}/reports/${props.report.id}/increment-analytics`,
      {
        analyticsTotalTime: Math.round(analyticsTotalTime / 1000), // Convert to seconds
        analyticsReportPaneClickCount,
        analyticsKeystrokeCount,
      }
    );

    analyticsTotalTime = 0;
    analyticsReportPaneClickCount = 0;
    analyticsKeystrokeCount = 0;

    analyticsSaveCount.value += 1;
  } catch (error) {
    return;
  }
}

// Save analytics every 30 seconds, and also when the component unmounts during normal usage
useIntervalFn(() => void saveAnalytics(), 30 * 1000);
onBeforeUnmount(saveAnalytics);

// The page is considered idle if the user does not interact with it for 15 seconds, at which point
// analytics will stop accumulating reporting time
const IDLE_TIME = 15 * 1000;
const { idle: isIdle } = useIdle(IDLE_TIME);

const currentTimestamp = useTimestamp();

let interactionStartedAt = currentTimestamp.value;

watch(isIdle, () => {
  if (isIdle.value) {
    analyticsTotalTime += Math.max(currentTimestamp.value - interactionStartedAt - IDLE_TIME, 0);
  } else {
    interactionStartedAt = currentTimestamp.value;
  }
});
</script>

<style scoped lang="scss">
.report {
  height: max-content;
  color: black;
  background-color: white;

  // Spacing at the top/bottom of the report and between the header/footer and the body content
  // These are only used when viewing as HTML
  display: flex;
  flex-direction: column;
  padding: 1.5em 0;
}

.rpt-report-body {
  font-family: var(--report-font-family);
}

:deep(.rpt-fancy-input) {
  border: 0.1em solid var(--report-widget-border-color);
  border-radius: 0.2em;
  background-color: var(--report-section-bg-color);
  padding: 0.4em 0.4em 0.2em;
  color: black;

  &:focus-within {
    border: 0.1em solid var(--report-widget-outline-color-focused);
  }

  input,
  textarea {
    color: var(--report-text-color-1);
    transition: color 100ms ease;
    background: none !important;
    padding: 0;
    width: 100%;
    font-family: var(--report-font-family);
    border: none;

    &:hover,
    &:focus {
      color: var(--report-text-color-2);
    }

    &::placeholder {
      color: var(--report-placeholder-text-color);
    }

    &:focus {
      &::placeholder {
        color: var(--report-placeholder-text-color-focused);
      }
    }

    &:disabled {
      color: var(--report-text-color-2);
    }
  }
}

.align-icons {
  position: absolute;
  bottom: 0.4em;
  right: 0.4em;
  display: flex;
  background-color: var(--report-widget-bg-color);
  border-radius: 0.1em;
  border: 1px solid var(--report-box-border-color);

  opacity: 0;
  transition: opacity 100ms ease;

  &.visible {
    opacity: 1;
  }
}

.align-icon {
  padding: 0.4em;
  color: var(--report-text-color-1);

  &:hover,
  &.selected {
    color: var(--report-text-color-2);
  }

  &:hover {
    cursor: pointer;
  }
}

:deep(.field-input) {
  flex: 1;
  align-self: stretch;
  background: white;
  border: 0.1em solid var(--report-widget-border-color);
  border-left-width: 0;
  border-right-width: 0;
  display: flex;
  padding: 0 0.4em;
  align-items: center;

  input {
    width: 100%;
    color: var(--report-text-color-1);
    padding: 0;
    align-self: stretch;
    background: none !important;
    line-height: 0;
    border: none;

    &:hover,
    &:focus {
      color: var(--report-text-color-2);
    }
    &:disabled {
      color: var(--report-text-placeholder-color);

      // Required on Safari/iOS
      -webkit-text-fill-color: var(--report-text-placeholder-color);
      opacity: 1;
    }

    &::placeholder {
      color: var(--report-placeholder-text-color);

      &:focus {
        color: var(--report-placeholder-text-color-focused);
      }
    }
  }

  &.show-border {
    min-height: 2em;
    border-left-width: 0.1em;
    border-right-width: 0.1em;
    border-radius: 0.2em;

    input {
      font-size: 1.2em;
    }
  }
}
</style>
