<template>
  <DropdownWidget
    :model-value="modelValue"
    :items="[
      ...(allowEmpty ? [{ value: '', text: emptyText }] : []),
      ...availableUsers.map((user) => ({
        value: user.id,
        text: `${user.isEnabled ? '' : '[Disabled] '}${user.name}`,
      })),
    ]"
    :disabled="disabled"
    :placeholder="placeholder"
    @update:model-value="(newValue) => emits('update:modelValue', newValue)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUserList } from "../utils/users-list";
import DropdownWidget from "./DropdownWidget.vue";

interface Props {
  modelValue: string;
  requiredRoleId?: string;
  allowEmpty?: boolean;
  emptyText?: string;
  disabled?: boolean;
  placeholder?: string;
}

interface Emits {
  (name: "update:modelValue", newValue: string): void;
}

const props = withDefaults(defineProps<Props>(), {
  allowEmpty: true,
  requiredRoleId: undefined,
  emptyText: "",
  disabled: false,
  placeholder: "",
});
const emits = defineEmits<Emits>();

const { userList } = useUserList();

// Lists the users that are enabled and have the required role ID (if one is specified). The user
// currently specified by the `modelValue` is always included in the list even if they don't match
// these criteria, as otherwise their name wouldn't be visible.
const availableUsers = computed(() =>
  userList.value.filter(
    (user) =>
      props.modelValue === user.id ||
      (user.isVisible &&
        user.isEnabled &&
        (props.requiredRoleId === undefined || user.roleIds.includes(props.requiredRoleId)))
  )
);
</script>
