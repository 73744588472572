<template>
  <div ref="rootElement" class="stress-layout-table-row-headings">
    <div
      v-for="(view, index) of views"
      :key="view"
      class="row-heading"
      :data-testid="`row-heading-${index}`"
    >
      <div class="view-dropdown-container">
        <div class="view-dropdown">
          <DropdownWidget
            :style="{ maxWidth: `${viewDropdownMaxHeight}px`, overflow: 'hidden' }"
            :model-value="views[index]"
            :items="stressViewNames.map((viewName) => ({ value: viewName, text: viewName }))"
            :data-testid="`view-dropdown-${index}`"
            @update:model-value="emits('change-view', index, $event)"
          />
        </div>
      </div>

      <div class="controls">
        <Tooltip
          v-if="views.length > 1"
          content="Remove this view row"
          placement="right"
          @click="emits('remove-view', index)"
        >
          <FontAwesomeIcon icon="xmark" :data-testid="`view-row-remove-${index}`" />
        </Tooltip>

        <Tooltip
          v-if="views.length < 4"
          content="Add view row below"
          placement="right"
          @click="emits('add-view', index, getUnusedView() ?? view)"
        >
          <FontAwesomeIcon icon="plus" :data-testid="`view-row-add-${index}`" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DropdownWidget from "@/components/DropdownWidget.vue";
import { Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useElementSize } from "@vueuse/core";
import { computed, ref } from "vue";
import Tooltip from "../../components/Tooltip.vue";
import { getStressViewNames } from "../study-clip-helpers";

interface Props {
  study: Study;
  views: string[];
}

interface Emits {
  (event: "add-view", index: number, newView: string): void;
  (event: "remove-view", index: number): void;
  (event: "change-view", index: number, newView: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const stressViewNames = computed(() => getStressViewNames(props.study));

function getUnusedView(): string | undefined {
  return stressViewNames.value.find((viewName) => !props.views.includes(viewName));
}

const rootElement = ref(null);
const { height: rootElementHeight } = useElementSize(rootElement);

const viewDropdownMaxHeight = computed(() => {
  const rowHeight = rootElementHeight.value / props.views.length;

  // There are two buttons placed in the top of the row header when there are fewer than
  // 4 views selected
  const columnVerticalPadding = props.views.length === 4 ? 28 : 52;

  return rowHeight - columnVerticalPadding * 2;
});
</script>

<style scoped lang="scss">
.stress-layout-table-row-headings {
  background-color: var(--bg-color-2);

  height: 100%;
  display: grid;
  grid-template-rows: v-bind("`repeat(${views.length}, minmax(0, 1fr))`");

  &:hover {
    .controls {
      opacity: 1;
    }
  }
}

.row-heading {
  display: grid;
  grid-template-areas: "main";

  > * {
    grid-area: main;
  }
}

.view-dropdown-container {
  position: relative;
  pointer-events: none;

  .view-dropdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);

    :deep(.dropdown-widget) {
      width: max-content;
      pointer-events: all;
    }
  }
}

.controls {
  place-self: start start;

  opacity: 0;
  transition: opacity 100ms ease;

  width: 36px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px 0;

  svg {
    cursor: pointer;
    color: var(--text-color-1);
    transition: color 100ms ease;

    &:hover {
      color: var(--text-color-2);
    }
  }
}
</style>
