import axios, { type AxiosResponse } from "axios";
import { ref, watch, type Ref } from "vue";
import { addNotification } from "../utils/notifications";

export interface HTMLReportContent {
  header: string;
  body: string;
  footer: string;
  margins: { top: number; bottom: number; horizontal: number };
}

/**
 * Automatically generates a report PDF when HTML content updates. This makes a call to the
 * backend to generate the PDF.
 */
export function useGeneratedReportPdf(isEnabled: Ref<boolean>): {
  onReportHtmlUpdated: (htmlContent: HTMLReportContent) => void;
  reportPdfBlob: Ref<Blob | undefined>;
  reportPdfBlobUri: Ref<string>;
} {
  const reportPdfBlob = ref<Blob | undefined>(undefined);
  const reportPdfBlobUri = ref("");

  let latestReportContent: HTMLReportContent | undefined = undefined;

  function onReportHtmlUpdated(htmlReportContent: HTMLReportContent): void {
    latestReportContent = htmlReportContent;

    // Clear any existing PDF report
    reportPdfBlob.value = undefined;
    reportPdfBlobUri.value = "";

    if (isEnabled.value) {
      void updatePdf();
    }
  }

  async function updatePdf(): Promise<void> {
    if (latestReportContent === undefined) {
      return;
    }

    const content = latestReportContent;
    latestReportContent = undefined;

    let response: AxiosResponse<Blob> | undefined = undefined;
    try {
      response = await axios.post<Blob>(
        `/api/studies/generate-report/`,
        {
          header: content.header,
          body: content.body,
          footer: content.footer,
          marginTop: content.margins.top,
          marginBottom: content.margins.bottom,
          marginHorizontal: content.margins.horizontal,
        },
        { responseType: "blob" }
      );
    } catch {
      addNotification({ type: "error", message: "Failed generating PDF report" });
      latestReportContent = content;
      return;
    }

    // Check the latest report content hasn't been updated while the above request was running. If
    // it has then the result is out of date and therefore of no use.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (latestReportContent !== undefined) {
      return;
    }

    reportPdfBlob.value = response.data;
    reportPdfBlobUri.value = URL.createObjectURL(response.data);
  }

  watch(isEnabled, () => {
    if (isEnabled.value) {
      void updatePdf();
    }
  });

  return { reportPdfBlob, reportPdfBlobUri, onReportHtmlUpdated };
}
