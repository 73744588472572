<template>
  <div class="password-strength-check">
    <ul>
      <li :class="{ checked: isPasswordLengthValid }" data-testid="password-length">
        <FontAwesomeIcon icon="check" size="lg" />
        <span>
          Length between {{ PASSWORD_MIN_LENGTH }} and {{ PASSWORD_MAX_LENGTH }} characters
        </span>
      </li>
      <li :class="{ checked: isStrong }" data-testid="password-strength-criteria">
        <FontAwesomeIcon icon="check" size="lg" />
        <span> At least 3 of the following: </span>
      </li>

      <ul>
        <li :class="{ checked: isContainsLowerCase }" data-testid="lowercase-characters">
          <FontAwesomeIcon icon="check" size="lg" />
          <span> Lowercase letters </span>
        </li>
        <li :class="{ checked: isContainsUpperCase }" data-testid="uppercase-characters">
          <FontAwesomeIcon icon="check" size="lg" />
          <span> Uppercase letters </span>
        </li>
        <li :class="{ checked: isContainsNumbers }" data-testid="numeric-characters">
          <FontAwesomeIcon icon="check" size="lg"></FontAwesomeIcon>
          Numbers
        </li>
        <li :class="{ checked: isContainsSpecial }" data-testid="special-characters">
          <FontAwesomeIcon icon="check" size="lg"></FontAwesomeIcon>
          Special characters, e.g. !@#$%^&*
        </li>
      </ul>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "../../../backend/src/auth/password-strength-check";

interface Props {
  password: string;
}

const props = defineProps<Props>();

const isPasswordLengthValid = computed(
  () => props.password.length >= PASSWORD_MIN_LENGTH && props.password.length <= PASSWORD_MAX_LENGTH
);
const isContainsLowerCase = computed(() => /[a-z]/.test(props.password));
const isContainsUpperCase = computed(() => /[A-Z]/.test(props.password));
const isContainsNumbers = computed(() => /[0-9]/.test(props.password));
const isContainsSpecial = computed(() => /[^a-zA-Z0-9]/.test(props.password));

const isStrong = computed(
  () =>
    [
      isContainsLowerCase.value,
      isContainsUpperCase.value,
      isContainsNumbers.value,
      isContainsSpecial.value,
    ].filter(Boolean).length >= 3
);
</script>

<style scoped lang="scss">
.password-strength-check {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

ul {
  list-style: none;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;

  ul {
    padding-left: 16px;
  }
}

li {
  display: flex;
  gap: 8px;

  svg {
    color: var(--bg-color-4);
    transition: color 100ms ease;
  }

  &.checked svg {
    color: var(--confirm-color-2);
  }
}
</style>
