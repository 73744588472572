import type { StudyReportGetOneResponseDto } from "../../../backend/src/studies/dto/study-get-one.dto";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { formatDateTime } from "../utils/date-time-utils";
import { getLatestReport } from "../utils/study-data";
import type { UserListEntry } from "../utils/users-list";

/**
 * The current state of the report for a study
 */
export enum StudyReportStatus {
  ReportInProgress = "reportInProgress",
  PreliminaryReportApproved = "preliminaryReportApproved",
  AmendmentInProgress = "amendmentInProgress",
  ReportFinalized = "reportFinalized",
}

export interface StudyListStatusFilter {
  reportStatus: StudyReportStatus | null;
  filterText: string;
  value: boolean;
}

const iconForStatus = {
  [StudyReportStatus.ReportInProgress]: "pen",
  [StudyReportStatus.PreliminaryReportApproved]: ["far", "circle-check"],
  [StudyReportStatus.AmendmentInProgress]: "pen",
  [StudyReportStatus.ReportFinalized]: "circle-check",
};

export function getStudyReportStateDetails(
  reports: StudyReportGetOneResponseDto[],
  users: UserListEntry[]
): {
  status: StudyReportStatus;
  textDescription: string;
  tooltipMessage: string[];
  icon: string | string[];
} | null {
  function getUserDisplayName(userId: string | null) {
    return users.find((u) => u.id === userId)?.name ?? "Unknown";
  }

  function formatDate(date: Date | string | null) {
    return formatDateTime(date, { includeTime: true });
  }

  const latestReport = getLatestReport(reports);

  // If the latest report is signed then show the study as signed
  if (latestReport?.isSigned === true) {
    const originalFinalReport = reports.find((r) => r.type === StudyReportType.Final && r.isSigned);

    if (latestReport.type !== StudyReportType.Amendment) {
      return {
        status: StudyReportStatus.ReportFinalized,
        textDescription: "Finalized",
        tooltipMessage: [
          `Report finalized by ${getUserDisplayName(latestReport.completedById)} at ${formatDate(latestReport.completedAt)}.`,
        ],
        icon: iconForStatus[StudyReportStatus.ReportFinalized],
      };
    }

    if (originalFinalReport === undefined) {
      throw new Error("Amendment finalized without an original final report");
    }

    const tooltipMessage = [
      `Report originally finalized by ${getUserDisplayName(originalFinalReport.completedById)} at ${formatDate(originalFinalReport.completedAt)}.`,
      `Amendment finalized by ${getUserDisplayName(latestReport.completedById)} at ${formatDate(latestReport.completedAt)}.`,
    ];

    if (latestReport.amendmentReason !== undefined) {
      tooltipMessage.push(`"${latestReport.amendmentReason}"`);
    }

    return {
      status: StudyReportStatus.ReportFinalized,
      textDescription: "Finalized (Amended)",
      tooltipMessage,
      icon: iconForStatus[StudyReportStatus.ReportFinalized],
    };
  }

  // If the latest report is an amendment then show the study as having an amendment in progress
  if (latestReport?.type === StudyReportType.Amendment) {
    return {
      status: StudyReportStatus.AmendmentInProgress,
      textDescription: "Amendment in progress",
      tooltipMessage: ["Amendment in progress. Sign off updates to finalize the amendment."],
      icon: iconForStatus[StudyReportStatus.AmendmentInProgress],
    };
  }

  // If there's a completed preliminary report then show as preliminary report approved
  const preliminaryReports = reports.filter(
    (report) => report.type === StudyReportType.Preliminary && report.completedAt !== null
  );

  if (preliminaryReports.length > 0) {
    const mostRecentPreliminaryReport = preliminaryReports.reduce((a, b) =>
      (a.completedAt ?? 0) > (b.completedAt ?? 0) ? a : b
    );

    return {
      status: StudyReportStatus.PreliminaryReportApproved,
      textDescription: "Preliminary approved",
      tooltipMessage: [
        "Report in progress.",
        `Preliminary report approved by ${getUserDisplayName(mostRecentPreliminaryReport.completedById)} at ${formatDate(mostRecentPreliminaryReport.completedAt)}.`,
      ],
      icon: iconForStatus[StudyReportStatus.PreliminaryReportApproved],
    };
  }

  // If there's any report at all then show that a report has been started
  if (reports.length !== 0) {
    return {
      status: StudyReportStatus.ReportInProgress,
      textDescription: "Report in progress",
      tooltipMessage: ["Report in progress."],
      icon: iconForStatus[StudyReportStatus.ReportInProgress],
    };
  }

  return null;
}
