<template>
  <div ref="element" @wheel="onMouseWheel">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { OverlayScrollbars } from "overlayscrollbars";
import { onMounted, onUnmounted, ref } from "vue";

interface Props {
  direction?: "x" | "y";
  scrollTarget?: HTMLElement | null;
  autoHide?: "never" | "leave";
  scrollTargetBlock?: ScrollLogicalPosition;
}

interface Emits {
  (event: "scroll"): void;
  (event: "has-overflow", hasOverflow: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  direction: "y",
  scrollTarget: null,
  autoHide: "leave",
  scrollTargetBlock: "center",
});

const emits = defineEmits<Emits>();

const element = ref<HTMLElement | null>(null);
let instance: OverlayScrollbars | null = null;

onMounted(() => {
  instance = OverlayScrollbars(
    element.value!,
    {
      overflow: {
        x: props.direction === "x" ? "scroll" : "hidden",
        y: props.direction === "y" ? "scroll" : "hidden",
      },
      scrollbars: { autoHide: props.autoHide, autoHideDelay: 250 },
    },
    {
      scroll: () => emits("scroll"),
      updated: (a) => emits("has-overflow", a.state().hasOverflow.y),
    }
  );
});

// Check whether a `WheelEvent` is actually from a physical mouse wheel (i.e. not a trackpad).
//
// NOTE: this does NOT work on Firefox, as Firefox always returns integer values for `deltaY`. There
// doesn't appear to be any way to reliably determine whether a `WheelEvent` is from an actual
// wheel or a trackpad on Firefox, so vertical scrolling mapping to horizontal scrolling on Firefox
// isn't supported. Given only about 2% of our app sessions are in Firefox, this is acceptable.
//
// See https://stackoverflow.com/questions/10744645/detect-touchpad-vs-mouse-in-javascript/62415754
function isPhysicalMouseWheelEvent(event: WheelEvent): boolean {
  return event.deltaY !== 0 && !Number.isInteger(event.deltaY);
}

function onMouseWheel(event: WheelEvent) {
  // If this is a horizontal scrollbar, map vertical mousewheel to horizontal scroll when a physical
  // mouse wheel is used (not a trackpad, as these can be properly horizontally side scrolled)
  if (props.direction === "x" && isPhysicalMouseWheelEvent(event)) {
    const viewport = instance?.elements().viewport;
    if (viewport) {
      viewport.scrollLeft += event.deltaY > 0 ? 100 : -100;
    }
  }
}

onUnmounted(() => instance?.destroy());
</script>

<style lang="scss">
@import "overlayscrollbars/overlayscrollbars.css";

.os-scrollbar {
  --os-handle-bg: var(--scrollbar-thumb-color);
  --os-handle-bg-hover: var(--scrollbar-hover-color);
  --os-handle-bg-active: var(--scrollbar-hover-color);
}
</style>
