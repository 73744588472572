import { z } from "zod";

//
// HeartBox config V1 schema
//

export const HeartBoxDimseEndpointConfigSchema = z.object({
  type: z.literal("dimse"),
  name: z.string(),
  aeTitle: z.string(),
  calledAeTitle: z.string(),
  ipAddress: z.string(),
  port: z.number().min(1).max(65535),
});

export const HeartBoxNativeUploadEndpointConfigSchema = z.object({
  name: z.string(),
  type: z.literal("direct-upload"),
});

export const HeartBoxEndpointConfigSchema = z.discriminatedUnion("type", [
  HeartBoxDimseEndpointConfigSchema,
  HeartBoxNativeUploadEndpointConfigSchema,
]);

export const HeartBoxRouterConfigSchema = z.object({
  name: z.string(),
  port: z.number().min(1).max(65535),
  targetEndpoints: z.string().array(),
});

export const HeartBoxConfigV1Schema = z.object({
  version: z.string().regex(/^1\.\d+$/),
  endpoints: HeartBoxEndpointConfigSchema.array(),
  routers: HeartBoxRouterConfigSchema.array(),
});

//
// HeartBox config deprecated schema
//

// This is the original config that did not have versioning and used `strictObject` everywhere. It
// is now deprecated in favour of config that uses semantic versioning, but is still part of the
// final config schema so that it remains valid. The current version of the HeartBox code does not
// support the deprecated config, in general it only supports the current version of the config.
const HeartBoxConfigDeprecatedSchema = z.strictObject({
  endpoints: z
    .strictObject({
      name: z.string(),
      aeTitle: z.string(),
      calledAeTitle: z.string(),
      ipAddress: z.string(),
      port: z.number().min(1).max(65535),
      useTls: z.boolean(),
      clientCertificatePem: z.string().optional(),
      clientKeyPem: z.string().optional(),
      serverCertificateAuthorityPem: z.string().optional(),
    })
    .array(),
  routers: z
    .strictObject({
      name: z.string(),
      port: z.number().min(1).max(65535),
      targetEndpoints: z.string().array(),
    })
    .array(),
});

// Allow any of the production config schemas
export const HeartBoxConfigSchema = HeartBoxConfigV1Schema.or(HeartBoxConfigDeprecatedSchema);

// The current version of the config schema
export const HeartBoxConfigCurrentVersionSchema = HeartBoxConfigV1Schema;

export type HeartBoxEndpointConfig = z.infer<typeof HeartBoxEndpointConfigSchema>;
export type HeartBoxRouterConfig = z.infer<typeof HeartBoxRouterConfigSchema>;
export type HeartBoxConfig = z.infer<typeof HeartBoxConfigSchema>;
export type HeartBoxConfigV1 = z.infer<typeof HeartBoxConfigV1Schema>;
export type HeartBoxConfigCurrentVersion = HeartBoxConfigV1;

/**
 * The default HeartBox config runs a DIMSE server on port 11112 and will forward all received
 * DICOMs directly to the cloud.
 */
export function getHeartBoxDefaultConfig(): HeartBoxConfigV1 {
  return {
    version: "1.0",
    endpoints: [{ name: "heartlab", type: "direct-upload" }],
    routers: [{ name: "main", port: 11112, targetEndpoints: ["heartlab"] }],
  };
}
