import { readAndCompressImage } from "browser-image-resizer";

/**
 * Converts an image file selected by the user into a PNG data URI and enforces maximum dimensions.
 */
export async function prepareUploadedImage(
  imageFile: File,
  maxWidth: number,
  maxHeight: number
): Promise<string> {
  const resizedImage = await readAndCompressImage(imageFile, {
    maxWidth,
    maxHeight,
    mimeType: "image/png",
  });

  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = reject;
    fileReader.onload = (): void => {
      resolve(fileReader.result as string);
    };
    fileReader.readAsDataURL(resizedImage);
  });
}

/**
 * Takes a URL to an image, loads it, and then returns a PNG data URI of the image's content. This
 * can be used to convert any supported image format, e.g. WebP, to a PNG.
 */
export async function convertImageUrlToPngDataUrl(url: string): Promise<string | null> {
  const image = new Image();
  try {
    image.src = url;

    await new Promise((resolve, reject) => {
      image.onerror = reject;
      image.onload = resolve;
    });
  } catch {
    return null;
  }

  // Render the image to a canvas
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext("2d");
  if (context === null) {
    return null;
  }

  context.drawImage(image, 0, 0);

  return canvas.toDataURL("image/png");
}
