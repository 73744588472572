<template>
  <Modal
    :title="mode === 'edit' ? 'Edit Recipient' : 'Add Recipient'"
    :activity-text="activityText"
    @header-button-click="emits('close')"
    @enter-key-press="saveRecipient"
  >
    <div class="content">
      <div>
        <div class="input-label">Recipient Details</div>
        <div class="name-input">
          <input
            ref="nameInputElement"
            v-model="recipientTitle"
            type="text"
            placeholder="Dr"
            style="width: 36px"
          />
          <input v-model="recipientFirstName" type="text" placeholder="Cardi" class="input-field" />
          <input v-model="recipientLastName" type="text" placeholder="Brown" class="input-field" />
        </div>
      </div>

      <div style="display: flex; flex-direction: column">
        <div class="input-label">Physician ID</div>
        <input v-model="physicianId" type="text" placeholder="54123" />
      </div>

      <div>
        <div class="input-label">Default Recipient</div>
        <ToggleSwitch v-model="isDefaultRecipient" />
      </div>

      <div class="buttons">
        <button class="accented" @click="saveRecipient">
          {{ mode === "add" ? "Add HL7 Recipient" : "Save HL7 Recipient" }}
        </button>
        <button v-if="props.mode === 'edit'" @click="deleteRecipient">Delete</button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { ref } from "vue";
import type { HL7IntegrationGetOneResponseDto } from "../../../../../backend/src/integrations/hl7/dto/hl7-integration-get-one.dto";

interface Props {
  integration: HL7IntegrationGetOneResponseDto;
  recipient?: HL7Recipient;
  mode: "add" | "edit";
}

interface Emits {
  (event: "close"): void;
  (event: "update:integration", integration: HL7IntegrationGetOneResponseDto): void;
}

type HL7Recipient = HL7IntegrationGetOneResponseDto["recipients"][0];

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const activityText = ref("");

const recipientTitle = ref(props.recipient?.name[3] ?? "");
const recipientFirstName = ref(props.recipient?.name[1] ?? "");
const recipientLastName = ref(props.recipient?.name[0] ?? "");
const physicianId = ref(props.recipient?.physicianId ?? "");
const isDefaultRecipient = ref(props.recipient?.default === true);

const nameInputElement = ref<HTMLInputElement>();
useFocus(nameInputElement, { initialValue: true });

function getRecipientAttributes(): Partial<HL7Recipient> {
  return {
    physicianId: physicianId.value,
    name: [recipientLastName.value, recipientFirstName.value, "", recipientTitle.value],
    default: isDefaultRecipient.value,
  };
}

async function addRecipient(): Promise<void> {
  activityText.value = "Adding recipient";

  let response: AxiosResponse<HL7Recipient[]> | undefined = undefined;
  try {
    response = await axios.post<HL7Recipient[]>(
      `/api/integrations/hl7/${props.integration.id}/recipients`,
      getRecipientAttributes()
    );
  } catch {
    addNotification({ type: "info", message: "Error adding HL7 recipient" });
    return;
  } finally {
    activityText.value = "";
  }

  updateIntegration(response.data);

  addNotification({ type: "info", message: "Added HL7 recipient" });

  emits("close");
}

async function editRecipient(): Promise<void> {
  activityText.value = "Editing recipient";

  let response: AxiosResponse<HL7Recipient[]> | undefined = undefined;
  try {
    response = await axios.patch<HL7Recipient[]>(
      `/api/integrations/hl7/${props.integration.id}/recipients/${props.recipient?.id}`,
      getRecipientAttributes()
    );
  } catch {
    addNotification({ type: "error", message: "Error updating HL7 recipient" });
    return;
  } finally {
    activityText.value = "";
  }

  updateIntegration(response.data);

  addNotification({ type: "info", message: "Updated HL7 recipient" });

  emits("close");
}

async function deleteRecipient(): Promise<void> {
  const confirmMessage = `Are you sure you want to permanently delete the recipient ${recipientTitle.value} ${recipientFirstName.value} ${recipientLastName.value}?`;
  if (!confirm(confirmMessage)) {
    return;
  }

  activityText.value = "Deleting recipient";

  let response: AxiosResponse<HL7Recipient[]> | undefined = undefined;
  try {
    response = await axios.delete<HL7Recipient[]>(
      `/api/integrations/hl7/${props.integration.id}/recipients/${props.recipient?.id}`
    );
  } catch {
    addNotification({ type: "error", message: "Error removing HL7 recipient" });
    return;
  } finally {
    activityText.value = "";
  }

  updateIntegration(response.data);

  addNotification({ type: "info", message: "Removed HL7 recipient" });

  emits("close");
}

function updateIntegration(recipients: HL7Recipient[]): void {
  emits("update:integration", { ...props.integration, recipients });
}

async function saveRecipient(): Promise<void> {
  if (props.mode === "add") {
    await addRecipient();
  }

  if (props.mode === "edit") {
    await editRecipient();
  }
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.name-input {
  display: flex;
}

.input-label {
  margin: 8px 0;
  font-weight: bold;
}

.input-field {
  margin-left: 8px;
}

input {
  align-self: stretch;
}

.buttons {
  align-self: center;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 8px;
}
</style>
