import { isEqual } from "lodash";
import { z } from "zod";
import { MeasurementName } from "../measurements/measurement-names";
import type { ReportStructure } from "./report-structure";

// The property name is the ID of the structured field that the data is for
export const ReportSectionStructuredFieldContentSchema = z.strictObject({
  // For dropdown fields, this is the ID of the selected option (or options if this is a
  // multi-select dropdown). An ID of "custom" means that a custom value has been specified.
  optionIds: z.array(z.string()),

  // For text fields, this is their content as entered by the user on the report. For dropdown
  // fields that specify a "custom" option, this is the value the user entered for that
  // custom option if it was selected by the user.
  text: z.string(),

  // For text and measurement fields, whether the user has edited the text shown by default and
  // replaced it with their own custom text as specified by the `text` value above.
  isTextOverriden: z.boolean(),
});

export const ReportSectionContentSchema = z.strictObject({
  // Whether this section is visible on the report
  visible: z.boolean(),

  // The free-text comment for this section
  comment: z.string(),

  // The width of the section in the report, expressed as a percentage of the root element.
  // As of now, this only applies to RWMA diagrams.
  width: z.optional(z.number()),

  // The structured field values selected for this section
  structuredFields: z.record(
    // The property name is the ID of the structured field that the data is for
    ReportSectionStructuredFieldContentSchema
  ),
});

export const ReportContentSchema = z.strictObject({
  sections: z.record(
    // The property name is the ID of the section that the data is for
    ReportSectionContentSchema
  ),

  // Details on the visibility status of measurements on the report
  measurementVisibilities: z.array(
    z.strictObject({
      // The name of the measurement that this visibility information relates to
      name: z.nativeEnum(MeasurementName),

      // If `name` specifies a custom measurement, this is the name of that custom measurement
      customName: z.string(),

      // Whether the raw/unindexed value of the measurement should be shown on the final report
      isUnindexedVisible: z.boolean(),

      // Whether the indexed value of the measurement should be shown on the final report
      isIndexedVisible: z.boolean(),
    })
  ),

  // When the report is an amendment to a previous signed report, contains the reason given for the
  // report being amended
  amendmentReason: z.string(),
});

export type ReportSectionStructuredFieldContent = z.infer<
  typeof ReportSectionStructuredFieldContentSchema
>;
export type ReportSectionContent = z.infer<typeof ReportSectionContentSchema>;
export type ReportContent = z.infer<typeof ReportContentSchema>;

/**
 * Returns the default/empty report content for the given report structure. All dropdown fields
 * are set to the first option, and all free text comments are set to empty strings.
 */
export function getEmptyReportContent(reportStructure?: ReportStructure): ReportContent {
  const reportContent: ReportContent = {
    sections: {},
    measurementVisibilities: [],
    amendmentReason: "",
  };

  if (reportStructure === undefined) {
    return reportContent;
  }

  const allSections = [...reportStructure.sections];

  for (const section of allSections) {
    const structuredFields: Record<string, ReportSectionStructuredFieldContent> = {};

    // Set initial value for all structured fields
    for (const field of section.structuredFieldColumns.flat()) {
      structuredFields[field.id] = { optionIds: [], text: "", isTextOverriden: false };

      if (field.type === "dropdown" && !field.isMultiSelect) {
        structuredFields[field.id].optionIds = [field.options[0].id];
      }
    }

    reportContent.sections[section.id] = {
      visible: section.type !== "rwma", //Hide RWMA sections by default
      comment: section.commentFieldDefault,
      structuredFields,
    };
  }

  return reportContent;
}

/**
 * Checks whether the given report content is valid for the given report structure and throws an
 * exception if there is invalid data.
 */
export function validateReportContent(contentValue: unknown, structure: ReportStructure): void {
  // The content must match the base report content schema
  const content = ReportContentSchema.parse(contentValue);

  function localeSort(a: string, b: string): number {
    return a.localeCompare(b);
  }

  const allSections = [...structure.sections];

  // Check that all section IDs are present, with no unexpected ones
  if (
    !isEqual(
      Object.keys(content.sections).sort(localeSort),
      allSections.map((s) => s.id).sort(localeSort)
    )
  ) {
    throw Error("Section IDs do not match the report structure");
  }

  // Check each section individually
  for (const section of allSections) {
    const structuredFields = content.sections[section.id].structuredFields;

    // Check each section has the expected structured fields
    const fieldIDs = Object.keys(content.sections[section.id].structuredFields).sort(localeSort);
    const expectedFieldIDs = section.structuredFieldColumns.flat().map((field) => field.id);
    if (!isEqual(expectedFieldIDs.sort(localeSort), fieldIDs)) {
      throw Error(`Section '${section.name}' structured fields do not match the report structure`);
    }

    // Check each structured field's selected option(s) are valid for that field type
    for (const field of section.structuredFieldColumns.flat()) {
      if (field.type !== "dropdown") {
        if (structuredFields[field.id].optionIds.length !== 0) {
          throw Error(
            `Section '${section.name}' (${section.id}) field '${field.name}' (${field.id}) does not specify an empty option IDs array`
          );
        }

        return;
      }

      for (const optionId of structuredFields[field.id].optionIds) {
        if (!field.options.find((option) => option.id === optionId)) {
          throw Error(
            `Section '${section.name}' (${section.id}) field '${field.name}' (${field.id}) has invalid option ID "${optionId}"`
          );
        }
      }

      if (!field.isMultiSelect && structuredFields[field.id].optionIds.length !== 1) {
        throw Error(
          `Section '${section.name}' (${section.id}) field '${field.name}' (${field.id}) does not specify exactly one option`
        );
      }
    }
  }
}
