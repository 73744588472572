import type { Volume } from "three/examples/jsm/Addons.js";
import { NRRDLoader } from "three/examples/jsm/Addons.js";

/**
 * Runs the Three.js NRRD loader and returns the Three.js volume object which can be used to render
 * slices in the app. This function is called from the image data store which caches the volumes.
 *
 * Note the Three.js volumes don't get unloaded when the volume is no longer being shown in a
 * viewer. There's possibly a case for this on large studies, but I don't think it's important at
 * this stage as if we were to implement that we'd make users have to wait for the entire download
 * of the NRRD from S3 + processing into a Three.js volume each time they change the active series.
 */

export function loadNrrdIntoThreeVolume(studyId: string, seriesId: string): Promise<Volume> {
  const url = `/api/studies/${studyId}/series/${seriesId}/nrrd`;

  return new Promise<Volume>((resolve, reject) => {
    const loader = new NRRDLoader();
    loader.load(url, (volume: Volume) => resolve(volume), undefined, reject);
  });
}
