import type { DateTimeOptions } from "luxon";
import { DateTime } from "luxon";
import type { StudyGetOneResponseDto } from "./dto/study-get-one.dto";

export function toDateTime(
  input: Date | string | null | undefined,
  options?: DateTimeOptions
): DateTime | undefined {
  if (input === null || input === undefined) {
    return undefined;
  }

  if (input instanceof Date) {
    return DateTime.fromJSDate(input, options);
  }

  return DateTime.fromISO(input, options);
}

export function getStudyDateTime(
  study: Pick<StudyGetOneResponseDto, "takenAt">
): DateTime | undefined {
  return toDateTime(study.takenAt, { zone: "utc" });
}

export function getPatientAgeInYearsWhenScanned(study: StudyGetOneResponseDto): number | undefined {
  // If there is a patient age provided from the DICOM then use that
  if (study.patientAge !== null && study.patientAge[3] === "Y") {
    return Number(study.patientAge.slice(0, -1));
  }

  if (study.patientBirthdate === null) {
    return undefined;
  }

  // Fall back to calculating the age in years based on the patient's birth date and the study date
  const birthdate = DateTime.fromISO(study.patientBirthdate, { zone: "utc" });
  const studyDate = getStudyDateTime(study);
  if (studyDate === undefined) {
    return undefined;
  }

  return Math.floor(studyDate.diff(birthdate, "years").years);
}
