export enum NRRDProcessState {
  NotStarted = "NotStarted",
  Processing = "Processing",
  Completed = "Completed",
}

export enum ProcessedFileExtension {
  Thumbnail = ".thumbnail.webp",
  AnimatedThumbnail = ".thumbnail-animated.webp",
  WebpPacked = ".webp-packed",
  Mp4 = ".mp4",
  Nrrd = ".nrrd",
}

export type ProcessedFileSizes = Partial<Record<ProcessedFileExtension, number>>;
