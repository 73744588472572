import { TranscribeStreamingClient } from "@aws-sdk/client-transcribe-streaming";
import axios, { type AxiosResponse } from "axios";
import { DateTime } from "luxon";
import type { TranscriptionCredentialsGetResponseDto } from "../../../backend/src/transcription/dto/transcription-credentials-get.dto";
import { currentTenant } from "../auth/current-session";
import { addNotification } from "../utils/notifications";

let transcribeStreamingClient: TranscribeStreamingClient | null = null;
let transcribeClientExpiresAt: DateTime | null = null;

export async function getTranscribeStreamingClient(): Promise<TranscribeStreamingClient | null> {
  // If there's an existing client with at least 30 seconds remaining on its credentials then
  // use that
  if (
    transcribeStreamingClient !== null &&
    transcribeClientExpiresAt !== null &&
    transcribeClientExpiresAt.diffNow("seconds").seconds > 30
  ) {
    return transcribeStreamingClient;
  }

  // Fetch AWS Transcribe credentials
  const credentials = await fetchNewCredentials();
  if (credentials === null) {
    return null;
  }

  // Create a new client with the credentials
  transcribeStreamingClient?.destroy();
  transcribeStreamingClient = new TranscribeStreamingClient({
    region: currentTenant.awsRegion,
    credentials: {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    },
  });

  transcribeClientExpiresAt = DateTime.fromISO(credentials.expiration);

  return transcribeStreamingClient;
}

async function fetchNewCredentials() {
  let response: AxiosResponse<TranscriptionCredentialsGetResponseDto> | undefined = undefined;

  try {
    response = await axios.get<TranscriptionCredentialsGetResponseDto>(
      `/api/transcription/credentials`
    );
  } catch {
    addNotification({ type: "error", message: "Failed getting transcription credentials" });
    return null;
  }

  return response.data;
}
