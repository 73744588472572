import Flatten from "@flatten-js/core";
import { isPolygonClosed } from "../shared/math-utils";
import type { StudyClipRegion } from "../studies/study-clip-region";
import type {
  AssociatedMeasurementValues,
  VolumeAssociatedMeasurementName,
} from "./associated-measurements";
import { volumeAssociatedMeasurements } from "./associated-measurements";
import { createFlattenPolygon } from "./measurement-helpers";
import type { MeasurementName } from "./measurement-names";
import { getAreaMeasurementValue, getLinearMeasurementValue } from "./measurement-tool-evaluation";

interface Point {
  x: number;
  y: number;
}

/**
 * Estimates a volume based on an outlined 2D area rotated around the specified axis.
 */
export function getVolumeFromPointsAndAxis(points: Point[], axis: Point[]): number {
  const disks = getDisksForVolume(points, axis, 100);

  let volume = 0;
  for (const disk of disks) {
    const radius = (disk.right - disk.left) * 0.5;
    volume += Math.PI * radius ** 2 * disk.height;
  }

  return volume;
}

/**
 * Translates the points and axis so that the axis[0] is at (0, 0), and rotates them so that the
 * axis line is horizontal, i.e. axis[1] is at (?, 0).
 */
export function translateAndRotateAxis(points: Point[], axis: Point[]) {
  const translatedPoints = points.map((pt) => ({ x: pt.x - axis[0].x, y: pt.y - axis[0].y }));
  const translatedAxis = axis.map((pt) => ({ x: pt.x - axis[0].x, y: pt.y - axis[0].y }));

  const angle = -Math.atan2(translatedAxis[1].y, translatedAxis[1].x);

  function rotatePoint(pt: Point): Point {
    return {
      x: pt.x * Math.cos(angle) - pt.y * Math.sin(angle),
      y: pt.y * Math.cos(angle) + pt.x * Math.sin(angle),
    };
  }

  const rotatedPoints = translatedPoints.map(rotatePoint);
  const rotatedAxis = translatedAxis.map(rotatePoint);

  // Sanity check that the rotated axis is where it should be
  if (Math.abs(rotatedAxis[1].y) > 0.001) {
    throw Error("axis[1].y is non-zero after being rotated");
  }

  return { rotatedPoints, rotatedAxis };
}

/**
 * Takes contour points and an axis and returns the disks to use for volume estimation via method
 * of disks. Each disk has an offset along the axis, and extents to each side of the axis at that
 * offset.
 */
export function getDisksForVolume(
  points: { x: number; y: number }[],
  axis: { x: number; y: number }[],
  diskCount: number
): { offset: number; height: number; left: number; right: number }[] {
  //
  // 1. Translate and rotate the axis such that the basal midpoint lies at (0, 0) and the apex
  //    point lies on the X axis.
  //
  const { rotatedPoints, rotatedAxis } = translateAndRotateAxis(points, axis);

  //
  // 2. Sample along the axis at regular intervals, determining the diameter of the shape at each
  //    step and using this to build up an approximation for the volume using the Method of Disks.
  //

  const axisLength = rotatedAxis[1].x;

  // The height of each disk is fixed
  const diskHeight = axisLength / diskCount;

  const polygon = new Flatten.Polygon(rotatedPoints.map((pt) => Flatten.point(pt.x, pt.y)));

  const disks: { offset: number; height: number; left: number; right: number }[] = [];

  for (let i = 0; i < diskCount; i++) {
    const x = (i + 0.5) * diskHeight;

    // Intersect the line segment for this disk with the drawn shape
    const intersections = polygon.intersect(
      new Flatten.Line(Flatten.point(x, -10000), Flatten.point(x, 10000))
    );

    // Did not find at least two intersections, so ignore this attempted disk
    if (intersections.length < 2) {
      continue;
    }

    // The extents of the disk are the points furthest above and below the X axis
    let left = 0;
    let right = 0;
    for (const pt of intersections) {
      left = Math.min(left, pt.y);
      right = Math.max(right, pt.y);
    }

    disks.push({ offset: x, height: diskHeight, left, right });
  }

  return disks;
}

/**
 * Returns the apex point for a volume measurement based on its external contour, basal points, and
 * the angle of the axis line with the basal line. An axis angle of zero means the axis is
 * perpendicular to the basal line.
 */
export function getApexPoint(
  points: number[],
  basalSegmentPoints: [number, number, number, number],
  axisAngle: number
): Flatten.Point | undefined {
  const basalSegment = Flatten.segment(basalSegmentPoints);
  const polygon = createFlattenPolygon(points);

  const basalMidpoint = basalSegment.middle();

  const axisVector = Flatten.vector(
    basalSegment.pe.x - basalSegment.ps.x,
    basalSegment.pe.y - basalSegment.ps.y
  )
    .rotate90CW()
    .rotate(axisAngle);

  const axisLine = Flatten.line(
    basalMidpoint,
    Flatten.point(basalMidpoint.x + axisVector.x, basalMidpoint.y + axisVector.y)
  );

  const intersections = polygon.intersect(axisLine);

  return intersections.find((ip) => axisLine.coord(ip) - axisLine.coord(basalMidpoint) > 0.001);
}

export function getVolumeAssociatedMeasurementValues(
  measurementName: MeasurementName,
  studyClip: { width: number | null; height: number | null } | undefined,
  region: StudyClipRegion | undefined,
  points: number[],
  axisPoints: number[]
): AssociatedMeasurementValues<VolumeAssociatedMeasurementName> {
  const associatedNames = volumeAssociatedMeasurements[measurementName];
  if (associatedNames === undefined) {
    return {};
  }

  if (!studyClip || !region || !isPolygonClosed(points)) {
    return {};
  }

  const result: AssociatedMeasurementValues<VolumeAssociatedMeasurementName> = {};

  const majorAxisLengthUnitAndValue = getLinearMeasurementValue(
    axisPoints.slice(0, 2),
    axisPoints.slice(2, 4),
    studyClip,
    region
  ).getInternalValueAndUnit();
  if (majorAxisLengthUnitAndValue) {
    result.majorAxisLength = {
      ...majorAxisLengthUnitAndValue,
      name: associatedNames.majorAxisLength,
      contour: axisPoints,
    };
  }

  const areaUnitAndValue = getAreaMeasurementValue(
    points,
    studyClip,
    region
  ).getInternalValueAndUnit();
  if (areaUnitAndValue) {
    result.area = {
      ...areaUnitAndValue,
      name: associatedNames.area,
      contour: points,
    };
  }

  return result;
}
