import { mean } from "lodash";
import { type StudyMeasurementResponseDto } from "../studies/dto/study-get-one.dto";
import { MeasurementName, isCustomMeasurement, measurementNames } from "./measurement-names";
import {
  MeasurementUnit,
  convertMeasurementUnit,
  getInternalUnitForStudyMeasurement,
  getMeasurementDisplayUnit,
  getMeasurementDisplayUnitText,
  getUnitDisplayText,
} from "./measurement-units";

export interface PatientMetrics {
  height: number;
  weight: number;
  bodyMassIndex: number;
  bodySurfaceArea: number;
}

export function getPatientMetrics(study: {
  patientHeight: number;
  patientWeight: number;
}): PatientMetrics | undefined {
  const height = study.patientHeight; // Meters
  const weight = study.patientWeight; // Kilograms

  if (height === 0 || weight === 0) {
    return undefined;
  }

  const bodyMassIndex = weight / height ** 2;

  // Use the 'Du Bois' method to estimate BSA from height and weight
  const bodySurfaceArea = 0.20247 * Math.pow(height, 0.725) * Math.pow(weight, 0.425);

  return {
    height,
    weight,
    bodyMassIndex,
    bodySurfaceArea,
  };
}

/**
 * Returns the display name to use for the given measurement. If there are patient metrics, and the
 * measurement is indexable, then the display name will be for the indexed variant.
 */
export function getMeasurementDisplayName(
  measurement: MeasurementName | StudyMeasurementResponseDto,
  type: "indexed" | "unindexed"
): string {
  const name: MeasurementName = typeof measurement === "string" ? measurement : measurement.name;

  if (isCustomMeasurement(name)) {
    return (measurement as StudyMeasurementResponseDto).customName;
  }

  const displayNames = measurementNames[name];

  if (type === "indexed" && displayNames.indexedName !== undefined) {
    return displayNames.indexedName;
  }

  return displayNames.name;
}

/**
 * Returns the mean value for the given measurement in internal units, or `undefined` if there are
 * no valid values for the measurement.
 */
export function getMeasurementMeanValue(
  studyMeasurement:
    | {
        values?: { value: number | null; selected: boolean }[];
      }
    | undefined
): number | undefined {
  const selectedValues = (studyMeasurement?.values ?? []).filter(
    (value): value is { value: number; selected: boolean } => value.selected && value.value !== null
  );

  if (selectedValues.length === 0) {
    return;
  }

  return mean(selectedValues.map((value) => value.value));
}

/**
 * Returns details for displaying the final value for a measurement, which may be the average of
 * multiple underlying values, and may also be indexed.
 */
export function getStudyMeasurementDisplayValue(
  studyMeasurement:
    | {
        name: MeasurementName;
        values: { value: number | null; selected: boolean }[];
        customUnit?: MeasurementUnit | null;
      }
    | undefined,
  type: "indexed" | "unindexed",
  patientMetrics?: PatientMetrics
):
  | {
      meanValue: number;
      meanValueRounded: string;
      meanValueUnit: MeasurementUnit;
      unitText: string;
      fullText: string;
    }
  | undefined {
  if (studyMeasurement === undefined) {
    return;
  }

  // Check if this measurement should be indexed
  const isIndexed = type === "indexed" && patientMetrics !== undefined;

  const customUnit =
    studyMeasurement.name === MeasurementName.CustomValue &&
    typeof studyMeasurement.customUnit === "string"
      ? studyMeasurement.customUnit
      : null;

  let unitText =
    customUnit !== null
      ? getUnitDisplayText(customUnit)
      : getMeasurementDisplayUnitText(studyMeasurement.name);

  // Adjust for indexed values
  let scale = 1;
  if (isIndexed) {
    scale = 1 / patientMetrics.bodySurfaceArea;
    unitText += "/m²";
  }

  const meanValueUnit = customUnit ?? getMeasurementDisplayUnit(studyMeasurement.name);

  const meanValue = convertMeasurementUnit(
    getMeasurementMeanValue(studyMeasurement) ?? null,
    getInternalUnitForStudyMeasurement(studyMeasurement),
    meanValueUnit
  );

  if (meanValue === null) {
    return;
  }

  const scaledMeanValue = meanValue * scale;

  // Convert from internal to display unit, then apply scale
  const meanValueRounded = scaledMeanValue.toFixed(
    getMeasurementDecimalPlaces(studyMeasurement.name, studyMeasurement.customUnit)
  );

  return {
    meanValue: scaledMeanValue,
    meanValueRounded,
    meanValueUnit,
    unitText,
    fullText: `${meanValueRounded} ${unitText}`,
  };
}

export function getMeasurementDecimalPlaces(
  measurementName: MeasurementName,
  customUnit?: MeasurementUnit | null
): number {
  const unit = customUnit ?? getMeasurementDisplayUnit(measurementName);

  // GLS measurements are always shown in 2dp
  if (
    [
      MeasurementName.GlobalPeakLongitudinalStrainA2C,
      MeasurementName.GlobalPeakLongitudinalStrainA3C,
      MeasurementName.GlobalPeakLongitudinalStrainA4C,
      MeasurementName.GlobalPeakLongitudinalStrainAverage,
      MeasurementName.RightVentricleFreeWallStrain,
    ].includes(measurementName)
  ) {
    return 2;
  }

  // The following units always use 0dp
  if (
    [
      MeasurementUnit.BeatsPerMinute,
      MeasurementUnit.Milliliters,
      MeasurementUnit.MillimetersCubed,
      MeasurementUnit.MillimetersOfMercury,
      MeasurementUnit.MillimetersSquared,
      MeasurementUnit.Milliseconds,
      MeasurementUnit.Percentage,
    ].includes(unit)
  ) {
    return 0;
  }

  // Some values need 2dp because they can be quite small
  if (
    [
      MeasurementName.MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity,
      MeasurementName.MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity,
      MeasurementName.MitralValveAnnulusEarlyDiastolicMeanVelocity,
      MeasurementName.CustomDopplerSlope,
      MeasurementName.CustomVelocity,
    ].includes(measurementName)
  ) {
    return 2;
  }

  // Use 1dp by default
  return 1;
}

export { getUnitDisplayText };
