<template>
  <div class="settings-title">Signature Settings</div>

  <div class="signature-settings">
    <div>
      <div class="subtitle">Signature</div>
      <p>This signature is included on all reports that you sign.</p>

      <SignaturePanel />

      <div class="subtitle">Signature Text</div>
      <p>This text displays below your signature on all reports you sign.</p>
      <p>
        <textarea
          v-model="currentUser.signatureText"
          class="signature-text"
          placeholder="Add signature text"
          data-testid="signature-textarea"
          rows="4"
          cols="30"
          @input="saveSignatureTextDebounced"
        />
      </p>

      <div class="subtitle">Signature Logo</div>

      <p>The signature logo will appear in the bottom left-hand corner of your reports.</p>

      <img
        v-if="currentUser.signatureLogoDataUri"
        style="max-width: 100%"
        data-testid="signature-logo"
        :src="currentUser.signatureLogoDataUri"
        @click="signatureLogoInput?.click()"
      />

      <input
        ref="signatureLogoInput"
        data-testid="signature-logo-input"
        hidden
        type="file"
        @change="onSignatureLogoSelected"
      />

      <div class="buttons">
        <button
          v-if="currentUser.signatureLogoDataUri"
          data-testid="remove-logo"
          @click="onRemoveSignatureLogo"
        >
          Remove Logo
        </button>
        <button class="accented" @click="signatureLogoInput?.click()">Upload Logo</button>
      </div>
    </div>

    <div>
      <div class="subtitle">Preview</div>
      <p>Here's what your signature will look like on reports that you sign.</p>
      <SignaturePreview />
    </div>
  </div>
</template>

<script setup lang="ts">
import SignaturePanel from "@/components/SignaturePanel.vue";
import SignaturePreview from "@/components/SignaturePreview.vue";
import { prepareUploadedImage } from "@/utils/image-utils";
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { ref } from "vue";
import { currentUser } from "../auth/current-session";

async function saveSignatureText(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { signatureText: currentUser.signatureText });
  } catch {
    addNotification({ type: "error", message: "Failed saving signature text" });
    return;
  }

  addNotification({ type: "info", message: "Saved signature text" });
}

const saveSignatureTextDebounced = useDebounceFn(() => {
  void saveSignatureText();
}, 1000);

const signatureLogoInput = ref<HTMLInputElement>();
async function onSignatureLogoSelected(): Promise<void> {
  const files = signatureLogoInput.value!.files!;
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  let imageDataURI = "";

  try {
    imageDataURI = await prepareUploadedImage(file, 640, 160);

    await axios.patch("/api/user/current", { signatureLogoDataUri: imageDataURI });
  } catch {
    addNotification({ type: "error", message: "Failed saving signature logo" });
    return;
  }

  currentUser.signatureLogoDataUri = imageDataURI;

  addNotification({ type: "info", message: "Saved signature logo" });

  // Clear out the value on the input field so that repeated selection of the same file(s)
  // triggers a re-upload
  signatureLogoInput.value!.value = "";
}

async function onRemoveSignatureLogo(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { signatureLogoDataUri: "" });
  } catch {
    addNotification({ type: "error", message: "Failed removing signature logo" });
    return;
  }

  currentUser.signatureLogoDataUri = "";

  addNotification({ type: "info", message: "Removed signature logo" });
}
</script>

<style scoped lang="scss">
.signature-settings {
  display: grid;
  grid-template-columns: 50% 50%;
}

.subtitle {
  font-weight: bold;
  margin: 16px 0 4px;
}

.signature-text {
  width: 304px;
  resize: none;
}

.buttons {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}
</style>
