<template>
  <ReportMeasurementGroupsStructure
    v-if="isViewingReportStructure"
    :report-structure="report.reportTemplateVersion.structure"
    :mode="mode"
    @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
  />

  <!-- Only show the measurements heading when there's content to show under it -->
  <div
    v-if="
      (isEditingReportContent && measurements.some((group) => group.flattened?.length !== 0)) ||
      (!isEditingReportContent && measurements.some((group) => group.compacted?.length !== 0))
    "
    class="rpt-main-header"
  >
    <span class="rpt-main-header-text">Measurements</span>
  </div>

  <template v-for="group in measurements" :key="group.title">
    <div v-if="isEditingReportContent || (group.compacted ?? []).length > 0">
      <div class="rpt-measurement-group-title">
        <span class="rpt-measurement-group-title-text"> {{ group.name }} </span>
      </div>

      <div class="rpt-measurement-table" data-testid="report-measurement-table">
        <template v-if="isGeneratingHtml">
          <template v-for="measurement in group.compacted" :key="measurement">
            <div class="rpt-measurement">
              <div class="rpt-measurement-name">{{ measurement.displayName }}</div>
              <div class="rpt-measurement-value">{{ measurement.value }}</div>
            </div>
          </template>
        </template>
        <template v-else>
          <template v-for="measurement in group.flattened" :key="measurement">
            <div
              v-if="
                measurement !== null &&
                (isEditingReportContent || isMeasurementVisible(measurement))
              "
              :data-testid="`report-measurement-${measurement.displayName}`"
              class="rpt-measurement"
              :class="{
                editable: isEditingReportContent,
                hidden: !isMeasurementVisible(measurement),
              }"
              @click="toggleMeasurementVisibility(measurement)"
            >
              <ReportCheckbox
                v-if="isEditingReportContent"
                :model-value="isMeasurementVisible(measurement)"
              />

              <div class="rpt-measurement-name">
                {{ measurement.displayName }}
              </div>

              <div
                class="rpt-measurement-value"
                :data-testid="`report-measurement-${measurement.displayName}-value`"
              >
                {{ measurement.value }}
              </div>
            </div>
            <div v-else />
          </template>
        </template>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getPatientMetrics } from "../../../backend/src/measurements/measurement-display";
import { isCustomMeasurement } from "../../../backend/src/measurements/measurement-names";
import { getMeasurementGroupItems } from "../../../backend/src/reporting/report-structure";
import type { Study, StudyReport } from "../utils/study-data";
import ReportCheckbox from "./ReportCheckbox.vue";
import ReportMeasurementGroupsStructure from "./ReportMeasurementGroupsStructure.vue";
import {
  ReportContentMode,
  ReportMeasurement,
  getReportMeasurementsForStudy,
} from "./report-content";
import type { ReportStructureMutation } from "./report-structure-mutations";

interface Props {
  study: Study;
  report: StudyReport;
  mode: ReportContentMode;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isGeneratingHtml = computed(() => props.mode === ReportContentMode.GenerateHTML);
const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);
const isViewingReportStructure = computed(
  () =>
    props.mode === ReportContentMode.ViewReportStructure ||
    props.mode === ReportContentMode.EditReportStructure
);

const reportStructure = computed(() => props.report.reportTemplateVersion.structure);

const measurements = computed(() => {
  // Measurement groupings aren't currently editable in the report structure
  if (isViewingReportStructure.value) {
    return [];
  }

  const measurementGroups = getReportMeasurementsForStudy(
    props.study.measurements,
    reportStructure.value,
    getPatientMetrics(props.study),
    false
  );

  // Assign the 'flattened' member of the measurement groups
  for (const measurementGroup of measurementGroups) {
    measurementGroup.flattened = [];

    const rowCount = Math.max(...measurementGroup.columns.map((c) => c.length));

    for (let row = 0; row < rowCount; row++) {
      for (let col = 0; col < 3; col++) {
        measurementGroup.flattened.push(measurementGroup.columns[col][row] ?? null);
      }
    }
  }

  // Assign the 'compacted' member of the measurement groups
  for (const measurementGroup of measurementGroups) {
    // Remove measurements not selected to appear on the report
    const columns = measurementGroup.columns.map((column) =>
      column.filter((mmt) => isMeasurementVisible(mmt))
    );

    // Move measurements from the tallest column over to the shortest column repeatedly until all
    // columns are about the same height
    while (true) {
      const tallestColumnLength = Math.max(...columns.map((c) => c.length));
      const shortestColumnLength = Math.min(...columns.map((c) => c.length));

      const delta = tallestColumnLength - shortestColumnLength;
      if (delta < 2) {
        break;
      }

      // Move a chunk of measurements that's half the size of the delta between tallest and shortest
      const chunkSize = Math.floor(delta / 2);
      const tallestColumnIndex = columns.findIndex((c) => c.length === tallestColumnLength);
      const shortestColumnIndex = columns.findIndex((c) => c.length === shortestColumnLength);
      columns[shortestColumnIndex].push(
        ...columns[tallestColumnIndex].splice(
          columns[tallestColumnIndex].length - chunkSize,
          chunkSize
        )
      );
    }

    // Convert to row-major layout for consumption in the template
    measurementGroup.compacted = [];
    const rowCount = Math.max(...columns.map((column) => column.length));
    for (let i = 0; i < rowCount; i++) {
      measurementGroup.compacted.push(columns[0].shift()!);
      measurementGroup.compacted.push(columns[1].shift()!);
      measurementGroup.compacted.push(columns[2].shift()!);
    }

    measurementGroup.compacted = measurementGroup.compacted.filter(Boolean);
  }

  return measurementGroups;
});

const measurementGroupItems = computed(() => getMeasurementGroupItems(reportStructure.value));

// Returns whether the given report measurement is currently visible on the report. This first
// checks the report content to see if there is an explicit visibility set there. If that isn't
// present then it looks at the default visibility for the measurement if it's placed in a
// measurement group. If the measurement is not in a measurement group, it is off by default
// unless it is a custom measurement created via a measurement tool or the custom measurement card.
function isMeasurementVisible(reportMeasurement: ReportMeasurement): boolean {
  const measurementName = reportMeasurement.measurement.name;

  const visibility = props.report.content.measurementVisibilities.find(
    (m) => m.name === measurementName && m.customName === reportMeasurement.measurement.customName
  );
  if (visibility) {
    return reportMeasurement.indexed ? visibility.isIndexedVisible : visibility.isUnindexedVisible;
  }

  const item = measurementGroupItems.value.get(measurementName);
  if (item === undefined) {
    return isCustomMeasurement(measurementName);
  }

  return reportMeasurement.indexed ? item.isIndexedVisible : item.isUnindexedVisible;
}

function toggleMeasurementVisibility(reportMeasurement: ReportMeasurement): void {
  if (!isEditingReportContent.value) {
    return;
  }

  const measurementName = reportMeasurement.measurement.name;
  const customName = reportMeasurement.measurement.customName;

  // Find this measurement in the measurement groups, if it exists there
  const measurementGroupItem = measurementGroupItems.value.get(measurementName);

  // Add an entry into the report content's measurement visibilities if one doesn't currently exist
  let visibility = props.report.content.measurementVisibilities.find(
    (m) => m.name === measurementName && m.customName === customName
  );
  if (visibility === undefined) {
    visibility = {
      name: measurementName,
      customName,
      isIndexedVisible:
        measurementGroupItem?.isIndexedVisible ?? isCustomMeasurement(measurementName),
      isUnindexedVisible:
        measurementGroupItem?.isUnindexedVisible ?? isCustomMeasurement(measurementName),
    };

    // eslint-disable-next-line vue/no-mutating-props
    props.report.content.measurementVisibilities.push(visibility);
  }

  // Toggle the relevant visibility value
  if (reportMeasurement.indexed) {
    visibility.isIndexedVisible = !visibility.isIndexedVisible;
  } else {
    visibility.isUnindexedVisible = !visibility.isUnindexedVisible;
  }

  emits("update-report-content");
}
</script>

<style scoped lang="scss">
.rpt-measurement-table {
  row-gap: 0.1em;
}

.rpt-measurement {
  cursor: pointer;
  transition: opacity 100ms ease;

  &.editable {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &.hidden {
    color: #888;
  }
}
</style>
