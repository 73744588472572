<template>
  <div
    class="report-checkbox"
    :class="{ disabled: !enabled }"
    @click="emits('update:modelValue', !props.modelValue)"
  >
    <span class="square" :class="{ checked: modelValue }">
      <FontAwesomeIcon v-if="modelValue" class="checked-icon" icon="check" data-testid="checked" />
    </span>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Props {
  modelValue: boolean;
  enabled?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: boolean): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true, shape: "square" });
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.report-checkbox {
  display: flex;
  align-items: center;
  gap: 0.6em;
  cursor: pointer;
  transition: opacity 100ms ease;

  &:hover {
    .square {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.square {
  min-height: 1.2em;
  min-width: 1.2em;
  display: grid;
  place-content: center;
  background-color: white;
  border: 0.2em solid var(--report-checkbox-unchecked-color);
  transition:
    background-color 100ms ease,
    border-color 100ms ease;
  border-radius: 0.2em;
  opacity: 0.9;

  &.checked {
    background-color: var(--report-checkbox-checked-background-color);
    border-color: var(--report-checkbox-checked-color);
  }

  .checked-icon {
    color: var(--report-checkbox-checked-color);
  }
}
</style>
