/** All DIMSE-C and DIMSE-N messages used by the DIMSE server. */
export enum DimseMessage {
  CEchoRequest = "C-ECHO-RQ",
  CEchoResponse = "C-ECHO-RSP",
  CStoreRequest = "C-STORE-RQ",
  CStoreResponse = "C-STORE-RSP",
  CFindRequest = "C-FIND-RQ",
  CFindResponse = "C-FIND-RSP",
  CGetRequest = "C-GET-RQ",
  CGetResponse = "C-GET-RSP",
  CMoveRequest = "C-MOVE-RQ",
  CMoveResponse = "C-MOVE-RSP",
  CCancelRequest = "C-CANCEL-RQ",

  NActionRequest = "N-ACTION-RQ",
}
