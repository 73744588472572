/**
 * This file holds the base contour measurement tool components, which are used in any measurement
 * type that consists of a closed polygon of points (that is area, volume, and VTI)
 */

import Flatten from "@flatten-js/core";
import { computed, reactive, ref, type ComputedRef, type Ref } from "vue";
import {
  clamp,
  isPointWithinTolerance,
  isPolygonClosed,
} from "../../../../backend/src/shared/math-utils";
import type { StudyClip, StudyClipRegion } from "../../utils/study-data";
import { createFlattenPolygon } from "../measurement-helpers";
import { calculateCatmullRomCurveThroughPoints } from "./catmull-rom-spline";
import { MeasurementCreationMethod } from "./measurement-tool";
import {
  MEASUREMENT_BASAL_COLOR,
  MEASUREMENT_COLOR,
  MEASUREMENT_INVALID_COLOR,
  checkForMeasurementPointEdits,
  drawXMark,
  getEquidistantPoints,
  isMouseNearPoints,
  useAlphaTransition,
} from "./measurement-tool-helpers";

interface ContourMeasurementBase {
  points: number[];

  method: MeasurementCreationMethod;

  isClosed: ComputedRef<boolean>;

  interactivePoints: ComputedRef<number[]>;

  redrawTriggers: ComputedRef<unknown[]>;

  editingPointIndex: Ref<number | null>;
  editPointOffset: number[];

  drawToCanvas: (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    drawScanlines?: boolean
  ) => void;
  onCanvasMouseDown: (
    pt: number[],
    options?: { editingOnly: boolean; allowDoubleClick: boolean }
  ) => void;
  onCanvasMouseMove: (pt: number[], options?: { editingOnly: boolean }) => boolean;
  onCanvasMouseUp: (pt: number[]) => void;

  resamplePoints: () => void;
  restart: () => void;
}

export function createBaseContourMeasurement(
  studyClip: StudyClip | undefined,
  region: StudyClipRegion | undefined,
  lastMousePosition: Ref<number[]>,
  isAutomaticResamplingEnabled: boolean,
  resampleOverride?: () => number[]
): ContourMeasurementBase {
  const points = reactive<number[]>([]);

  const editingPointIndex = ref<number | null>(null);
  let editPointOffset = [0, 0];
  let savedPoints: number[] = [];
  let wasContourValidBeforeEdit = false;

  let method = MeasurementCreationMethod.ClickAndDrag;

  const isClosed = computed(() => isPolygonClosed(points));

  const pointAlpha = useAlphaTransition(
    computed(() => isClosed.value && isMouseNearPoints(lastMousePosition.value, points))
  );

  const hasMovedAwayFromRecentlyPlacedPoint = ref(false);
  const removeRecentlyPlacedPointAlpha = useAlphaTransition(
    computed(
      () =>
        hasMovedAwayFromRecentlyPlacedPoint.value &&
        isPointWithinTolerance(lastMousePosition.value, points.slice(-2))
    )
  );

  const nextEdgeEndpoint = computed(() => {
    if (!isClosed.value || editingPointIndex.value !== null) {
      return lastMousePosition.value;
    }

    return null;
  });

  const interactivePoints = computed(() => {
    if (!isClosed.value) {
      return [];
    }

    return [
      ...points,
      ...getPolygonEdges(points.slice(0, -4)) // Basal midpoint shouldn't be clickable
        .map((edge) => edge.midpoint)
        .flat(),
    ];
  });

  function drawToCanvas(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    drawScanlines = true
  ): void {
    if (points.length > 0 && !isClosed.value) {
      drawContourEdges({
        canvas,
        ctx,
        points: points.concat(lastMousePosition.value),
        isInvalid: isContourMeasurementIntersecting(points, lastMousePosition.value),
        drawMidpoints: true,
        pointOpacity: 0,
        opacity: 1,
      });

      if (
        removeRecentlyPlacedPointAlpha.value !== 0 &&
        isPointWithinTolerance(lastMousePosition.value, points.slice(-2))
      ) {
        ctx.strokeStyle = MEASUREMENT_INVALID_COLOR;
        drawXMark(
          ctx,
          points.slice(-2)[0] * canvas.width,
          points.slice(-2)[1] * canvas.height,
          5,
          removeRecentlyPlacedPointAlpha.value
        );
        ctx.strokeStyle = MEASUREMENT_COLOR;
      }
    } else {
      drawContourEdges({
        canvas,
        ctx,
        points,
        drawMidpoints: isClosed.value,
        pointOpacity: isClosed.value ? pointAlpha.value : 0,
        opacity: 1,
      });

      if (drawScanlines) {
        drawContourInternalLines({ canvas, ctx, points });
      }
    }
  }

  function onCanvasMouseDown(
    pt: number[],
    options = { editingOnly: false, allowDoubleClick: true }
  ): void {
    // Check if the polygon is finished
    if (isClosed.value) {
      editingPointIndex.value = checkForAddMidpoint(points, pt);
      const edits = checkForMeasurementPointEdits(points, pt);
      if (edits !== undefined) {
        editingPointIndex.value = edits.editingPointIndex;
        editPointOffset = edits.editPointOffset;
        savedPoints = [...points];
        wasContourValidBeforeEdit = !isPolygonSelfIntersecting(points);
      }

      return;
    }

    if (!options.editingOnly) {
      if (
        hasMovedAwayFromRecentlyPlacedPoint.value &&
        isPointWithinTolerance(pt, points.slice(-2))
      ) {
        points.pop();
        points.pop();
      } else if (
        !isPointWithinTolerance(points.slice(-2), pt) &&
        !isPointWithinTolerance(points.slice(0, 2), pt)
      ) {
        // If placing this point would make the contour invalid then don't place it
        if (isContourMeasurementIntersecting(points, pt)) {
          return;
        }

        points.push(pt[0], pt[1]);
      } else if (
        method === MeasurementCreationMethod.ClickToPlacePoints &&
        points.length >= 6 &&
        (options.allowDoubleClick || isPointWithinTolerance(points.slice(0, 2), pt))
      ) {
        closeMeasurement();
      }

      hasMovedAwayFromRecentlyPlacedPoint.value = false;
    }
  }

  function onCanvasMouseMove(pt: number[], options = { editingOnly: false }): boolean {
    lastMousePosition.value = pt;

    if (!options.editingOnly) {
      if (method === MeasurementCreationMethod.ClickAndDrag) {
        if (points.length >= 6 && shouldWalkBackPoint(pt, points) && !isClosed.value) {
          points.splice(0, points.length, ...points.slice(0, points.length - 2));
        } else if (!isClosed.value && shouldPlaceNextDragPoint(points.slice(-2), pt)) {
          // Don't automatically place points when doing so would make the contour invalid
          if (!isContourMeasurementIntersecting(points, lastMousePosition.value)) {
            points.push(...pt);
          }
        }
      } else if (
        !hasMovedAwayFromRecentlyPlacedPoint.value &&
        !isPointWithinTolerance(pt, points.slice(-2), 0.01)
      ) {
        hasMovedAwayFromRecentlyPlacedPoint.value = true;
      }

      if (!isClosed.value) {
        return true;
      }
    }

    adjustPlacedMeasurementPoints(points, pt, editingPointIndex.value, editPointOffset);

    return editingPointIndex.value !== null;
  }

  function onCanvasMouseUp(pt: number[]): void {
    if (isClosed.value) {
      // When editing, prevent the user from creating an invalid contour. If the contour was already
      // invalid then any edits are allowed so that the user can actually make it valid.
      if (wasContourValidBeforeEdit) {
        revertPointsIfSelfIntersecting(points, savedPoints);
      }

      editingPointIndex.value = null;
    } else {
      if (method === MeasurementCreationMethod.ClickAndDrag && points.length === 2) {
        method = MeasurementCreationMethod.ClickToPlacePoints;
      }

      if (method === MeasurementCreationMethod.ClickAndDrag) {
        // Replace the last point if it's close to the final mouseup point
        if (isPointWithinTolerance(points.slice(-2), pt, 0.02)) {
          points.splice(points.length - 2, 2);
        }

        points.push(...pt);

        closeMeasurement();
      }
    }
  }

  // Resamples the points to ensure an evenly spaced contour.
  function resamplePoints(): void {
    if (
      studyClip === undefined ||
      region === undefined ||
      studyClip.height === null ||
      studyClip.width === null
    ) {
      return;
    }

    // Resample 5 times (arbitrary constant), as at this point it should converge to evenly spaced
    // points on any physiological contour.
    for (let i = 0; i < 5; i++) {
      const resampledPoints =
        resampleOverride?.() ??
        getEquidistantPoints(
          calculateCatmullRomCurveThroughPoints(points.slice(0, -2)),
          21,
          studyClip
        );

      if (resampledPoints.length !== 0) {
        points.splice(0, points.length, ...resampledPoints, ...resampledPoints.slice(0, 2));
      }
    }

    // Clamp smoothing result to be inside the region
    const horizontalBounds = [region.left / studyClip.width, region.right / studyClip.width];
    const verticalBounds = [region.top / studyClip.height, region.bottom / studyClip.height];

    for (let i = 0; i < points.length; i += 2) {
      points[i] = clamp(points[i], horizontalBounds[0], horizontalBounds[1]);
      points[i + 1] = clamp(points[i + 1], verticalBounds[0], verticalBounds[1]);
    }
  }

  function closeMeasurement(): void {
    if (!isWindingClockwise(points)) {
      const reversedPoints = reversePointsArray(points);
      points.splice(0, reversedPoints.length, ...reversedPoints);
    }

    if (points.length >= 6) {
      points.push(...points.slice(0, 2));

      if (isAutomaticResamplingEnabled) {
        resamplePoints();
      }
    }
  }

  function restart(): void {
    points.length = 0;
    editingPointIndex.value = null;
    method = MeasurementCreationMethod.ClickAndDrag;
  }

  const redrawTriggers = computed(() => [
    points,
    nextEdgeEndpoint,
    pointAlpha,
    removeRecentlyPlacedPointAlpha,
  ]);

  return {
    points,
    method,
    isClosed,
    interactivePoints,
    redrawTriggers,
    editingPointIndex,
    editPointOffset,
    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    resamplePoints,
    restart,
  };
}

function shouldPlaceNextDragPoint(lastPt: number[], newPoint: number[]): boolean {
  const dist = Math.sqrt(
    Math.pow(lastPt[0] - newPoint[0], 2) + Math.pow(lastPt[1] - newPoint[1], 2)
  );

  return dist > 0.025;
}

function shouldWalkBackPoint(pt: number[], points: number[]): boolean {
  const lastPoints = points.slice(-4);
  const seg = Flatten.segment(
    Flatten.point(lastPoints[0], lastPoints[1]),
    Flatten.point(lastPoints[2], lastPoints[3])
  );

  const len = seg.length;

  return Flatten.circle(Flatten.point(lastPoints[0], lastPoints[1]), len * 0.75).contains(
    Flatten.point(pt[0], pt[1])
  );
}

/**
 * Draws the edges of an contour closed polygon measurement onto a canvas.
 */
export function drawContourEdges({
  canvas,
  ctx,
  points,
  isInvalid,
  drawMidpoints,
  pointOpacity,
  opacity,
}: {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  points: number[];
  isInvalid?: boolean;
  drawMidpoints?: boolean;
  pointOpacity: number;
  opacity: number;
}): void {
  function drawEdgeLine(point1: number[], point2: number[], color: string): void {
    if (!hasError) {
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
    }

    ctx.beginPath();
    ctx.moveTo(point1[0] * canvas.width, point1[1] * canvas.height);
    ctx.lineTo(point2[0] * canvas.width, point2[1] * canvas.height);
    ctx.stroke();
  }

  function drawVertexCircle(point: number[], color: string): void {
    ctx.globalAlpha = Math.min(pointOpacity, opacity);
    if (!hasError) {
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
    }

    ctx.beginPath();
    ctx.arc(point[0] * canvas.width, point[1] * canvas.height, 2, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.stroke();
    ctx.globalAlpha = opacity;
  }

  ctx.globalAlpha = opacity;
  ctx.strokeStyle = MEASUREMENT_COLOR;

  const isClosed = isPolygonClosed(points);

  const hasError = isClosed && isPolygonSelfIntersecting(points);

  if (hasError) {
    ctx.strokeStyle = MEASUREMENT_INVALID_COLOR;
  }

  ctx.lineWidth = 2;

  for (let i = 0; i < points.length - 2; i += 2) {
    let edgeLineColor = MEASUREMENT_COLOR;
    let vertexCircleColor = MEASUREMENT_COLOR;

    // If the final line is intersecting the rest of the shape then draw it in red
    if (isInvalid === true && i >= points.length - 4) {
      edgeLineColor = MEASUREMENT_INVALID_COLOR;
      vertexCircleColor = MEASUREMENT_INVALID_COLOR;
    } else {
      // Draw the basal line in a different color
      const isLastEdge = i === points.length - 4;
      if (i === 0) {
        vertexCircleColor = MEASUREMENT_BASAL_COLOR;
      }

      if (isLastEdge && isClosed) {
        edgeLineColor = MEASUREMENT_BASAL_COLOR;
        vertexCircleColor = MEASUREMENT_BASAL_COLOR;
      }
    }

    drawEdgeLine([points[i], points[i + 1]], [points[i + 2], points[i + 3]], edgeLineColor);
    drawVertexCircle([points[i], points[i + 1]], vertexCircleColor);
  }

  if (!hasError) {
    ctx.strokeStyle = MEASUREMENT_COLOR;
    ctx.fillStyle = MEASUREMENT_COLOR;
  }

  if (drawMidpoints === true) {
    ctx.globalAlpha = pointOpacity;
    drawContourEdgeMidpoints(canvas, ctx, points);
  }

  ctx.globalAlpha = 1;
}

function drawContourEdgeMidpoints(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  points: number[]
): void {
  if (points.length < 6) {
    return;
  }

  for (const edge of getPolygonEdges(points)) {
    edge.normal[0] *= 2.5 / canvas.width;
    edge.normal[1] *= 2.5 / canvas.height;

    ctx.beginPath();
    ctx.moveTo(
      (edge.midpoint[0] + edge.normal[0]) * canvas.width,
      (edge.midpoint[1] + edge.normal[1]) * canvas.height
    );
    ctx.lineTo(
      (edge.midpoint[0] - edge.normal[0]) * canvas.width,
      (edge.midpoint[1] - edge.normal[1]) * canvas.height
    );
    ctx.stroke();
  }
}

export function drawContourInternalLines(args: {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  points: number[];
}): void {
  const { canvas, ctx, points } = args;

  // Create a flattenjs polygon from the points. The rounding performed here is necessary to ensure
  // the intersections done for the scanlines never intersect a vertex. Further details below.
  const flattenPoints = [];
  for (let i = 0; i < points.length; i += 2) {
    flattenPoints.push(
      Flatten.point(Math.round(points[i] * canvas.width), Math.round(points[i + 1] * canvas.height))
    );
  }
  const polygon = new Flatten.Polygon(flattenPoints);

  // Find Y extents
  const yValues = flattenPoints.map((pt) => pt.y);
  const yMax = Math.max(...yValues);
  const yMin = Math.floor(Math.min(...yValues) / 8) * 8;

  ctx.strokeStyle =
    isPolygonClosed(points) && isPolygonSelfIntersecting(points)
      ? MEASUREMENT_INVALID_COLOR
      : MEASUREMENT_COLOR;

  // Draw scanline every 8 pixels
  for (let scanlineY = yMin; scanlineY <= yMax; scanlineY += 8) {
    // Define a scanline at y = scanlineY. A vertical offset is added so that the intersection is
    // done through the center of pixels and will therefore never intersect a vertex, as such
    // intersections are harder to deal with and draw correct scanlines for. The vertical offset
    // ensures the number of intersection points will always be a multiple of two.
    const scanline = new Flatten.Line(
      Flatten.point(0, scanlineY + 0.5),
      Flatten.point(1, scanlineY + 0.5)
    );

    // Find the intersections of the scanline with the polygon
    const intersections = polygon.intersect(scanline);

    // If there are >=2 intersections, draw the appropriate segments on canvas between intersections
    for (let i = 0; i < intersections.length - 1; i += 2) {
      ctx.beginPath();
      ctx.lineWidth = 0.5;
      ctx.moveTo(intersections[i].x, intersections[i].y);
      ctx.lineTo(intersections[i + 1].x, intersections[i + 1].y);
      ctx.stroke();
    }
  }
}

interface PolygonEdge {
  start: number[];
  end: number[];
  midpoint: number[];
  length: number;
  normal: number[];
}

/** Returns information on the polygon edges, including midpoints, normals, and lengths. */
function getPolygonEdges(points: number[]): PolygonEdge[] {
  const result: PolygonEdge[] = [];

  for (let i = 0; i < points.length - 4; i += 2) {
    const start = [points[i], points[i + 1]];
    const end = [points[(i + 2) % points.length], points[(i + 3) % points.length]];
    const midpoint = [(start[0] + end[0]) * 0.5, (start[1] + end[1]) * 0.5];

    const normal = [end[1] - start[1], start[0] - end[0]];
    const normalLength = Math.sqrt(normal[0] ** 2 + normal[1] ** 2);
    normal[0] /= normalLength;
    normal[1] /= normalLength;

    result.push({
      start,
      end,
      midpoint,
      normal,
      length: Math.sqrt((end[0] - start[0]) ** 2 + (end[1] - start[1]) ** 2),
    });
  }

  return result;
}

/**
 * Edges have to be have a minimum length to be editable, i.e. be split into two new edges by
 * clicking on the original edge's midpoint.
 */
function isPolygonEdgeEditable(edge: PolygonEdge): boolean {
  return edge.length > 0.01;
}

export function isPolygonSelfIntersecting(points: number[]): boolean {
  return !createFlattenPolygon(points).isValid();
}

function isContourMeasurementIntersecting(
  points: number[],
  currentCanvasMousePosition: number[]
): boolean {
  if (points.length < 6 || currentCanvasMousePosition.length === 0) {
    return false;
  }

  const lines = [];
  for (let i = 0; i < points.length - 4; i += 2) {
    lines.push(
      Flatten.segment(
        Flatten.point(points[i], points[i + 1]),
        Flatten.point(points[i + 2], points[i + 3])
      )
    );
  }

  const newSegment = new Flatten.Segment(
    Flatten.point(points[points.length - 2], points[points.length - 1]),
    Flatten.point(currentCanvasMousePosition[0], currentCanvasMousePosition[1])
  );

  return lines.some((line) => line.intersect(newSegment).length > 0);
}

function adjustPlacedMeasurementPoints(
  points: number[],
  pt: number[],
  idx: number | null,
  editPointOffset: number[]
): void {
  if (idx === null) {
    return;
  }

  const offsetMouse = [pt[0] - editPointOffset[0], pt[1] - editPointOffset[1]];

  points[idx * 2] = offsetMouse[0];
  points[idx * 2 + 1] = offsetMouse[1];

  if (idx === 0) {
    const len = points.length;
    points[len - 2] = offsetMouse[0];
    points[len - 1] = offsetMouse[1];
  }
}

function revertPointsIfSelfIntersecting(points: number[], savedPoints: number[]): void {
  if (isPolygonSelfIntersecting(points)) {
    points.length = 0;
    points.push(...savedPoints);
  }
}

function checkForAddMidpoint(points: number[], inputPoint: number[]): number | null {
  for (const [i, edge] of getPolygonEdges(points).entries()) {
    if (!isPolygonEdgeEditable(edge)) {
      continue;
    }

    if (isPointWithinTolerance(inputPoint, edge.midpoint)) {
      points.splice((i + 1) * 2, 0, ...edge.midpoint);
      const pts = [...points];
      points.length = 0;
      points.push(...pts);
      return i + 1;
    }
  }

  return null;
}

function reversePointsArray(points: number[]): number[] {
  const reversedArray = [];
  for (let i = points.length - 2; i > -1; i -= 2) {
    reversedArray.push(...[points[i], points[i + 1]]);
  }
  return reversedArray;
}

function isWindingClockwise(pts: number[]): boolean {
  const polygon = createFlattenPolygon(pts);
  const signedArea = [...polygon.faces].reduce<number>(
    (acc, face) => acc + (face as Flatten.Face).signedArea(),
    0
  );
  return signedArea < 0;
}
