<template>
  <div class="settings-title">
    Manage Users

    <div style="margin-left: auto; font-size: 13px; font-weight: normal">
      <ToggleSwitch v-model="showDisabledUsers" data-testid="show-disabled-users-toggle">
        Show disabled users
      </ToggleSwitch>
    </div>
  </div>

  <div class="top-row">
    <FilterInput v-model="userSearchTerm" placeholder="Search" />

    <button
      :disabled="!hasUserInvitePermission"
      style="justify-self: end"
      class="accented"
      data-testid="invite-user-button"
      @click="isInviteUserModalVisible = true"
    >
      Invite User
    </button>

    <div hidden data-testid="invite-link">{{ inviteLink }}</div>
  </div>

  <div class="users-table">
    <div class="header">
      <div>Name</div>
      <div>Email</div>
      <div />
      <div>Last Seen</div>
      <div>Roles</div>
    </div>

    <div class="header-line" />

    <div
      v-for="user in filteredUsers"
      :key="user.id"
      class="grid-table-row"
      :class="{ invited: user.inviteExpiresAt !== null, disabled: user.disabledAt !== null }"
      :data-testid="`user-${user.email}`"
      @click="viewUser(user)"
    >
      <div>{{ user.inviteExpiresAt === null ? user.name : "Invitation pending" }}</div>
      <div>{{ user.email }}</div>
      <div>
        <Badge v-if="user.mfaEnabled" class="mfa-badge">MFA</Badge>
      </div>
      <div>{{ user.inviteExpiresAt === null ? formatRelativeTime(user.lastSeenAt) : "" }}</div>
      <BadgeList
        :badges="
          userRoleList
            .filter((role) => user.roleIds.includes(role.id))
            .map((role) => ({
              text: role.name,
              isEnabled: role.isEnabled,
              routerLinkTo: { name: 'settings-access-roles-edit', params: { id: role.id } },
            }))
        "
      />
    </div>
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />

  <Modal
    v-if="isInviteUserModalVisible"
    title="Invite User"
    :activity-text="isInvitingUser ? 'Inviting user' : ''"
    @header-button-click="isInviteUserModalVisible = false"
    @enter-key-press="inviteUser"
  >
    <div class="invite-new-user">
      <div class="field">
        <strong>Email</strong>
        <input
          ref="newUserEmailInput"
          v-model="newUserEmail"
          type="email"
          placeholder="john.doe@heartlab.com"
          data-testid="invite-user-email"
        />
      </div>

      <div class="field">
        <strong>Roles</strong>
        <UserRolesToggles v-model="newUserRoles" enable-admin-toggle />
      </div>

      <div class="field">
        <strong>Study Visibility</strong>
        <ToggleSwitch v-model="initialOnlyAssignedStudies">
          Restrict this user to only seeing studies assigned to them
        </ToggleSwitch>
      </div>

      <button
        class="invite-user accented"
        :disabled="!isNewUserEmailValid"
        data-testid="invite-user-confirm-button"
        @click="inviteUser"
      >
        Invite User
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import type { UserGetManyResponseDto } from "@/../../backend/src/tenants/dto/user-get-many.dto";
import type { UserInvitationCreateResponseDto } from "@/../../backend/src/user/dto/user-invitation-create.dto";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { useFocus, useLocalStorage } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { hasUserInvitePermission } from "../auth/authorization";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import Badge from "../components/Badge.vue";
import BadgeList from "../components/BadgeList.vue";
import FilterInput from "../components/FilterInput.vue";
import Modal from "../components/Modal.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import { formatRelativeTime } from "../utils/date-time-utils";
import { useUserRoleList } from "../utils/user-roles-list";
import UserRolesToggles from "./UserRolesToggles.vue";

const activityText = ref("");
const isInvitingUser = ref(false);

const userRoleList = useUserRoleList();

const users = ref<UserGetManyResponseDto>([]);
const showDisabledUsers = useLocalStorage("user-list-show-disabled", false);
const isInviteUserModalVisible = ref(false);
const inviteLink = ref("");

const newUserEmail = ref("");
const newUserRoles = ref<string[]>([]);
const initialOnlyAssignedStudies = ref(false);
const newUserEmailInput = ref<HTMLInputElement>();
useFocus(newUserEmailInput, { initialValue: true });

const userSearchTerm = ref("");

const isNewUserEmailValid = computed(() => newUserEmail.value.trim().length > 3);

// User search by name and email
const filteredUsers = computed(() => {
  const searchTerm = userSearchTerm.value.toLowerCase();

  return users.value
    .filter(
      (user) =>
        (showDisabledUsers.value || user.disabledAt === null) &&
        (user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm))
    )
    .sort((a, b) => `${a.name}${a.email}`.localeCompare(`${b.name}${b.email}`));
});

async function fetchUsers(): Promise<void> {
  activityText.value = "Loading";

  let usersResponse: AxiosResponse<UserGetManyResponseDto> | undefined = undefined;
  try {
    usersResponse = await axios.get<UserGetManyResponseDto>(`/api/tenants/users`);
  } catch {
    addNotification({ type: "error", message: "Failed loading user list" });
    return;
  } finally {
    activityText.value = "";
  }

  users.value = usersResponse.data;
}

onMounted(fetchUsers);

async function viewUser(user: UserGetManyResponseDto[0]): Promise<void> {
  await router.push({ name: "settings-access-users-view", params: { id: user.id } });
}

async function inviteUser(): Promise<void> {
  if (!isNewUserEmailValid.value) {
    return;
  }

  isInvitingUser.value = true;

  let response: AxiosResponse<UserInvitationCreateResponseDto> | undefined = undefined;
  try {
    response = await axios.post<UserInvitationCreateResponseDto>(`/api/users`, {
      userEmail: newUserEmail.value,
      initialRoleIds: newUserRoles.value,
      initialOnlyAssignedStudies: initialOnlyAssignedStudies.value,
    });
  } catch {
    addNotification({ type: "error", message: `Failed inviting ${newUserEmail.value}` });
    return;
  } finally {
    isInvitingUser.value = false;
  }

  if (response.data.status === "invited") {
    addNotification({ type: "info", message: `Invitation email sent to ${newUserEmail.value}` });
  } else {
    addNotification({
      type: "info",
      message: `There is already a user with the email address ${newUserEmail.value}`,
    });
  }

  isInviteUserModalVisible.value = false;
  newUserEmail.value = "";
  inviteLink.value = response.data.inviteLink ?? "";

  await fetchUsers();
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.users-table {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) max-content max-content max-content;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;
    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 5;
    border-bottom: 2px solid var(--bg-color-2);
  }
}

.grid-table-row.disabled > * {
  filter: brightness(75%);
}

.invited {
  color: #ccc;
  font-style: italic;
}

.mfa-badge {
  border: 1px solid var(--border-color-1);
  background: none;
}

.invite-new-user {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invite-user {
  margin: 0 auto;
}
</style>
