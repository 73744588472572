<template>
  <ReportTemplateEditSlider
    :name="name"
    :model-value="structure.margins[option]"
    :min="min"
    :max="max"
    :data-testid="`report-template-edit-slider-margin-${option}`"
    @update:model-value="onMarginValueUpdate"
  />
</template>

<script setup lang="ts">
import type {
  ReportMarginOption,
  ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import {
  createMarginMutation,
  type ReportStructureMutation,
} from "../reporting/report-structure-mutations";
import ReportTemplateEditSlider from "./ReportTemplateEditSlider.vue";

interface Props {
  name: string;
  structure: ReportStructure;
  option: ReportMarginOption;
}

interface Emits {
  (event: "update", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const min = 0;
const max = { top: 10, bottom: 10, horizontal: 5 }[props.option];

function onMarginValueUpdate(newValue: number): void {
  emits("update", createMarginMutation({ ...props.structure.margins, [props.option]: newValue }));
}
</script>
