import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import type { ReportStructure } from "../../../backend/src/reporting/report-structure";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { mathjsInstance } from "../../../backend/src/shared/mathjs";
import { currentTenant } from "../auth/current-session";
import { getEmptyMeasurement, getEmptyMeasurementValue, getEmptyStudy } from "../utils/study-data";
import {
  getCalculationMeasurementScope,
  getSanitizedReportStructureName,
  type CalculationPatientScope,
  type CalculationReportScope,
  type CalculationScope,
  type CalculationStudyScope,
} from "./report-calculation-scope";

// Regex to match content enclosed in double curly braces, e.g. "Hello, {{ patient.name }}"
// Ideally this would use a positive look behind here to avoid having to remove the braces manually
// after the fact, but that's currently not supported in Safari.
const CALCULATION_REGEX = /{{((.|\n)*?)}}/g;

export function isCalculation(text: string): boolean {
  return text.includes("{{");
}

/**
 * Evaluates a piece of text than may contain Math.js calculations. If any of the calculations in
 * the text fail to evaluate then the placeholder text provided will be inserted in its place, and
 * defaults to "???".
 */
export function evaluateTextContainingCalculations(
  text: string,
  scope: CalculationScope,
  options: { erroredCalculationValue: string } = { erroredCalculationValue: "???" }
): string {
  const calculations = text.match(CALCULATION_REGEX)?.map((c) => c.slice(2, c.length - 2)) ?? [];

  const calculationResults = calculations.map((c) =>
    evaluateSingleCalculation(c.replaceAll("\n", ""), scope)
  );
  const replaceGroups = text.match(CALCULATION_REGEX);

  let out = text;
  if (replaceGroups !== null) {
    for (let i = 0; i < replaceGroups.length; i++) {
      out = out.replace(replaceGroups[i], calculationResults[i] ?? options.erroredCalculationValue);
    }
  }

  return out;
}

/**
 * Evaluates a single calculation from a text field. Returns null if the calculation fails for any
 * reason. Possible reasons for failure include:
 *
 *   - A malformed calculation expression
 *   - Referencing a patient value (e.g. age, weight, height) that isn't present in the study
 *   - Referencing a measurement value that isn't present in the study
 *   - Referencing a value from a report text field that hasn't had a valid number entered
 */
function evaluateSingleCalculation(calculation: string, scope: CalculationScope): string | null {
  try {
    let result: unknown = mathjsInstance.evaluate(calculation, scope);

    if (result === undefined || result === null) {
      return null;
    }

    // If the result is a number that isn't an integer then default to showing it in 2dp
    if (typeof result === "number" && result % 1 !== 0) {
      result = result.toFixed(2);
    }

    // If the result is a string and contains the text "undefined" or "null" then it likely
    // referenced a value in the scope that wasn't set, meaning the calculation failed
    if (typeof result === "string" && (result.includes("undefined") || result.includes("null"))) {
      return null;
    }

    return String(result);
  } catch {
    // Failure to evaluate an equation is expected if it is malformed or references a variable that
    // doesn't have a value (though not all references to an undefined variable will throw)
    return null;
  }
}

/**
 * Returns details on the errors present in any calculations present in the given text field, or
 * undefined if there are no errors. This checks that all calculations are well-formed and don't
 * reference any values that will never exist when using this calculation on an actual report.
 */
export function getTextFieldCalculationErrors(
  text: string,
  reportStructure: ReportStructure
): string | undefined {
  // Create a dummy study with values for all known measurements
  const study = getEmptyStudy();
  study.measurements = Object.values(MeasurementName).map((c) =>
    getEmptyMeasurement({ name: c, values: [getEmptyMeasurementValue({ value: 999 })] })
  );

  // Create a scope with dummy study data
  const studyScope: CalculationStudyScope = {
    date: "2014-05-20",
    time: "14:10",
    traineeName: "Trainee Name",
    technicianName: "Technician Name",
    physicianName: "Physician Name",
    referringPhysicianName: "Referring Physician Name",
    reporterName: "Reporter Name",
    reportDate: "2014-05-21",
    indication: "Indication",
  };

  // Create a scope with dummy patient data
  const patientScope: CalculationPatientScope = {
    name: formatDicomName(["Doe", "John"], currentTenant.patientNameFormat),
    id: "1234",
    sex: "Male",
    birthdate: "1950-10-20",
    age: 100,
    weight: 100,
    height: 2,
    bodyMassIndex: 25,
    bodySurfaceArea: 2.37,
    medicalHistory: "Medical history",
  };

  // Create a scope with dummy values for all text fields on the report
  const reportScope: CalculationReportScope = {};
  for (const section of reportStructure.sections) {
    reportScope[getSanitizedReportStructureName(section.name)] = {};
    for (const field of section.structuredFieldColumns.flat()) {
      if (field.type === "text") {
        reportScope[getSanitizedReportStructureName(section.name)][
          getSanitizedReportStructureName(field.name)
        ] = 10;
      }
    }
  }

  // Create a final scope for the calculation which is compromised of the above scopes, using Proxy
  // to throw explicit exceptions when a non-existent property is referenced
  const scope: CalculationScope = {
    study: new Proxy<CalculationStudyScope>(studyScope, {
      get(target: Record<string, unknown>, p: string): unknown {
        if (target[p] !== undefined) {
          return target[p];
        }

        throw Error(`Invalid study attribute: ${p}`);
      },
    }),
    patient: new Proxy<CalculationPatientScope>(patientScope, {
      get(target: Record<string, unknown>, p: string): unknown {
        if (target[p] !== undefined) {
          return target[p];
        }

        throw Error(`Invalid patient attribute: ${p}`);
      },
    }),
    measurement: getCalculationMeasurementScope(study),
    report: new Proxy<CalculationReportScope>(reportScope, {
      get(target: Record<string, unknown>, p: string): unknown {
        if (target[p] !== undefined) {
          return target[p];
        }

        // TODO: throw on invalid field names as well

        throw Error(`Invalid report section name: ${p}`);
      },
    }),
  };

  const calculations = text.match(CALCULATION_REGEX) ?? [];

  for (let i = 0; i < calculations.length; i++) {
    try {
      // If the calculation evaluates in the scope created above then it is valid
      mathjsInstance.evaluate(calculations[i].slice(2, calculations[i].length - 2), scope);
    } catch (error: unknown) {
      return `Error in calculation ${i + 1}: ${String(error)}`;
    }
  }

  return undefined;
}
