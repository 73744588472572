<template>
  <div class="filter-input" :class="{ 'show-border': showBorder }">
    <FontAwesomeIcon icon="search" class="search-icon" size="lg" />
    <input
      ref="inputElement"
      v-model="filterInput"
      type="text"
      :placeholder="placeholder"
      data-testid="filter-input"
      @keydown.enter="blurOnEnterPress ? inputElement?.blur() : null"
    />
    <FontAwesomeIcon
      icon="times"
      class="delete-icon"
      data-testid="clear-filter-input"
      :class="{ hidden: filterInput === '' }"
      @click="filterInput = ''"
    />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import { computed, ref } from "vue";

interface Props {
  modelValue: string;
  placeholder?: string;
  showBorder?: boolean;
  initialFocus?: boolean;
  blurOnEnterPress?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  showBorder: true,
  initialFocus: false,
  blurOnEnterPress: false,
});
const emits = defineEmits<Emits>();

const filterInput = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: string) {
    emits("update:modelValue", newValue);
  },
});

const inputElement = ref<HTMLInputElement>();
useFocus(inputElement, { initialValue: props.initialFocus });
</script>

<style scoped lang="scss">
.filter-input {
  display: flex;
  align-items: center;
  background-color: var(--bg-color-2);
  padding: 0 8px;
  height: 32px;
  gap: 4px;
  transition: background-color 100ms ease;

  &:hover {
    background-color: var(--bg-color-3);
  }

  &:focus-within {
    border-color: var(--input-focus-border-color);
    background-color: var(--bg-color-4);
  }

  &:focus-within,
  &:hover {
    .search-icon {
      color: var(--accent-color-1);
    }
  }

  &.show-border {
    border: 1px solid var(--border-color-1);
    border-radius: var(--border-radius);
  }
}

input {
  flex: 1;
  background: none !important;
  border: none;
}

.search-icon {
  flex: 0 0 auto;
  color: var(--accent-color-1);
}

.delete-icon {
  cursor: pointer;
  transition: color 100ms ease;
  flex: 0 0 auto;

  &:hover {
    color: var(--text-color-2);
  }

  &.hidden {
    visibility: hidden;
  }
}
</style>
