<template>
  <div class="settings-title">Security Settings</div>

  <div class="settings-group-title">Change Password</div>

  <div class="field">
    <strong>Current Password</strong>

    <div style="width: 250px">
      <PasswordInput v-model="currentPassword" data-testid="current-password" initial-focus />
    </div>
  </div>

  <div class="field">
    <strong>New Password</strong>

    <div class="field" style="width: 250px">
      <PasswordInput
        v-model="newPassword"
        data-testid="new-password"
        style="width: 250px"
        show-mask-password-button
        @enter="onChangePassword"
      />
      <PasswordStrengthCheck :password="newPassword" />
    </div>
  </div>

  <button
    style="width: min-content; padding: 0 16px"
    type="submit"
    data-testid="change-password-button"
    :disabled="!isPasswordChangeValid"
    @click="onChangePassword"
  >
    Change Password
  </button>

  <div class="field" style="max-width: 500px">
    <div class="settings-group-title">
      Multifactor Authentication
      <FontAwesomeIcon v-if="currentUser.mfaEnabled" icon="check" class="mfa-enabled-icon" />
    </div>

    <div class="mfa-details">
      <div>
        Multifactor authentication adds an extra layer of security to your account. It is currently
        <b>{{ currentUser.mfaEnabled ? "enabled" : "disabled" }} </b>.
      </div>

      <button data-testid="open-mfa-modal" style="padding: 0 24px" @click="openMfaModal">
        {{ currentUser.mfaEnabled ? "Remove" : "Setup" }} MFA
      </button>
    </div>
  </div>

  <TOTPSetupModal v-if="isTotpSetupModalVisible" @close="isTotpSetupModalVisible = false" />

  <TOTPDeleteModal v-if="isTotpDeleteModalVisible" @close="isTotpDeleteModalVisible = false" />

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { computed, ref } from "vue";
import {
  isPasswordStrong,
  PASSWORD_MIN_LENGTH,
} from "../../../backend/src/auth/password-strength-check";
import PasswordStrengthCheck from "../auth/PasswordStrengthCheck.vue";
import TOTPDeleteModal from "../auth/TOTPDeleteModal.vue";
import TOTPSetupModal from "../auth/TOTPSetupModal.vue";
import { currentUser } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import PasswordInput from "../components/PasswordInput.vue";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";

const activityText = ref("");

const currentPassword = ref("");
const newPassword = ref("");

const isTotpSetupModalVisible = ref(false);
const isTotpDeleteModalVisible = ref(false);

const isPasswordChangeValid = computed(
  () => currentPassword.value.length >= PASSWORD_MIN_LENGTH && isPasswordStrong(newPassword.value)
);

function openMfaModal(): void {
  if (currentUser.mfaEnabled) {
    isTotpDeleteModalVisible.value = true;
  } else {
    isTotpSetupModalVisible.value = true;
  }
}

async function onChangePassword(): Promise<void> {
  if (!isPasswordChangeValid.value) {
    return;
  }

  activityText.value = "Changing password";

  try {
    await axios.patch("/api/user/current/password", {
      user: currentUser,
      password: currentPassword.value,
      newPassword: newPassword.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed setting password",
    });
    return;
  } finally {
    activityText.value = "";
  }

  newPassword.value = "";
  currentPassword.value = "";

  addNotification({ type: "info", message: "New password has been set" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

.visibility-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--border-color-1);
  border-left: none;
  height: 100%;
}

.password-mismatch {
  color: red;
  font-weight: bold;
  font-size: 0.9em;
  transition: opacity 100ms ease;
}

ul {
  list-style: none;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ul {
    padding-left: 16px;
  }
}

li {
  svg {
    color: var(--bg-color-4);
    transition: color 100ms ease;
  }

  &.checked svg {
    color: var(--confirm-color-2);
  }
}

.mfa-enabled-icon {
  margin-left: auto;
  color: var(--confirm-color-2);
}

.mfa-details {
  display: flex;
  gap: 64px;
  align-items: center;
  margin-right: auto;
}
</style>
