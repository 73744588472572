<template>
  <div class="settings-title">Personal Settings</div>

  <div class="field">
    <strong>Name</strong>

    <input v-model="userName" type="text" class="profile-input" data-testid="username" />
  </div>

  <div class="field">
    <strong>Email</strong>
    <div class="selectable-text" data-testid="email">{{ currentUser.email }}</div>
  </div>

  <div class="field">
    <strong>Roles</strong>
    <BadgeList :badges="currentUser.roleNames.map((name) => ({ text: name }))" />
  </div>

  <template v-if="currentTenant.isDictationPermitted">
    <div class="field">
      <div style="display: flex; gap: 16px; align-items: center">
        <b>Enable Dictation</b>
        <ToggleSwitch
          v-model="currentUser.isDictationEnabled"
          data-testid="user-dictation-switch"
          @update:model-value="updateDictationEnabled"
        />
        <Badge>Beta Feature</Badge>
      </div>

      <p>
        Whether to enable access to dictation features when reporting. When enabled, a microphone
        button will be shown while reporting.
      </p>
    </div>

    <div class="field" :class="{ disabled: !currentUser.isDictationEnabled }">
      <div style="display: flex; gap: 16px; align-items: center">
        <b>Enable Spoken Punctuation</b>
        <ToggleSwitch
          v-model="currentUser.isSpokenPunctuationEnabled"
          data-cy="user-spoken-punctuation-switch"
          @update:model-value="updateSpokenPunctuationEnabled"
        />
      </div>

      <p>
        Whether punctuation must be spoken when dictating into reports. This disables automatic
        detection and insertion of punctuation based on the natural flow of words.
      </p>
      <p class="punctuation">
        The available spoken punctuation is: <span>"full stop"</span>, <span>"comma"</span>,
        <span>"semicolon"</span>, <span>"question mark"</span>, <span>"exclamation point"</span>,
        and <span>"exclamation mark"</span>.
      </p>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import { currentTenant, currentUser } from "../auth/current-session";
import Badge from "../components/Badge.vue";
import BadgeList from "../components/BadgeList.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import { addNotification } from "../utils/notifications";

const nameText = ref("");

const userName = computed({
  get: () => currentUser.name,
  set: (newValue: string) => {
    nameText.value = newValue;
    void updateUserNameDebounced();
  },
});

async function updateDictationEnabled(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { isDictationEnabled: currentUser.isDictationEnabled });
  } catch {
    addNotification({ type: "error", message: "Failed updating dictation setting" });
    return;
  }

  addNotification({ type: "info", message: "Updated dictation setting" });
}

async function updateSpokenPunctuationEnabled(): Promise<void> {
  try {
    await axios.patch("/api/user/current", {
      isSpokenPunctuationEnabled: currentUser.isSpokenPunctuationEnabled,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating spoken punctuation setting" });
    return;
  }

  addNotification({ type: "info", message: "Updated spoken punctuation setting" });
}

const updateUserNameDebounced = useDebounceFn(() => {
  void updateUserName();
}, 1000);

async function updateUserName(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { name: nameText.value });
  } catch {
    addNotification({ type: "error", message: "Failed updating name" });
    return;
  }

  currentUser.name = nameText.value;
  addNotification({ type: "info", message: "Updated user name" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
  transition: opacity 100ms ease;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

input {
  width: 300px;
}

.punctuation span {
  font-family: monospace;
  font-size: 0.9em;
  background: var(--bg-color-2);
  padding: 0 2px;
}
</style>
