<template>
  <Modal title="Study Audit Logs" centered @header-button-click="emits('close')">
    <div class="audit-logs">
      <div class="audit-logs-table">
        <div class="header-cell" />

        <div class="header-cell">
          <div class="filter">
            <DatePicker
              v-model="dateRangeFilter"
              mode="date"
              :is-range="true"
              :max-date="new Date()"
              placeholder="Date Range"
            />
          </div>
          <div class="title">Date</div>
        </div>

        <div class="header-cell" data-testid="audit-log-action-filter">
          <DropdownInputCheckbox
            v-model="actionFilter"
            :text="actionSelectText"
            :options="
              Object.values(AuditAction).map((action) => ({
                value: action,
                text: getAuditLogActionDescription(action),
              }))
            "
          />
          <div class="title">Action</div>
        </div>

        <div class="header-cell">
          <FilterInput
            v-model="userFilter"
            placeholder="User"
            data-testid="audit-log-user-filter"
          />
          <div class="title">User</div>
        </div>

        <div class="header-cell">
          <div class="filter" />
          <div class="title">IP</div>
        </div>

        <div class="header-cell">
          <div class="filter" />
          <div class="title">Session</div>
        </div>

        <AuditLogRow
          v-for="auditLogEvent in auditLogEvents"
          :key="auditLogEvent.id"
          :audit-log-event="auditLogEvent"
          :is-tenant-column-visible="false"
          is-study-view
        />

        <VueEternalLoading
          v-model:is-initial="isReloadRequired"
          class="eternal-loading-status"
          :load="loadAuditLogEvents"
        >
          <template #loading>
            <Transition name="fade">
              <LoadingIndicator size="2x" />
            </Transition>
          </template>

          <template #no-more> &nbsp; </template>
          <template #no-results>
            <div class="loading-message">No matching log records found</div>
          </template>

          <template #error>
            <div class="loading-error">Error loading audit logs</div>
          </template>
        </VueEternalLoading>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { VueEternalLoading, type LoadAction } from "@ts-pro/vue-eternal-loading";
import { useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { computed, ref, watch } from "vue";
import { AuditAction } from "../../../backend/src/audit/audit-actions";
import { AuditLogEventGetManyResponseDto } from "../../../backend/src/tenants/dto/audit-log-event-get-many.dto";
import { getAuditLogActionDescription } from "../../../frontend/src/audit-logs/audit-action-descriptions";
import AuditLogRow from "../audit-logs/AuditLogRow.vue";
import { currentTenant } from "../auth/current-session";
import DatePicker from "../components/DatePicker.vue";
import DropdownInputCheckbox from "../components/DropdownInputCheckbox.vue";
import FilterInput from "../components/FilterInput.vue";
import { Study } from "../utils/study-data";
import LoadingIndicator from "./LoadingIndicator.vue";
import Modal from "./Modal.vue";

interface Props {
  study: Study;
}

interface Emits {
  (event: "close"): void;
}

const dateRangeFilter = ref<{ start: Date | null; end: Date | null } | null>(null);
const userFilter = ref("");
const actionFilter = ref<AuditAction[]>([]);

const auditLogEvents = ref<AuditLogEventGetManyResponseDto>([]);

const actionSelectText = computed(() => {
  if (actionFilter.value.length === 0) {
    return "Action";
  }

  return actionFilter.value.map((action) => getAuditLogActionDescription(action)).join(", ");
});

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

let page = 0;

async function loadAuditLogEvents({ loaded, error }: LoadAction): Promise<void> {
  const PAGE_SIZE = 50;

  let dateFromFilter = "";
  let dateToFilter = "";
  if (dateRangeFilter.value?.start && dateRangeFilter.value.end) {
    dateRangeFilter.value.start.setHours(0, 0, 0, 0);
    dateRangeFilter.value.end.setHours(23, 59, 59, 999);
    dateFromFilter = dateRangeFilter.value.start.toISOString();
    dateToFilter = dateRangeFilter.value.end.toISOString();
  }

  const query = new URLSearchParams({
    tenantId: currentTenant.id,
    limit: PAGE_SIZE.toString(),
    offset: (page * PAGE_SIZE).toString(),
    patientIdFilter: "",
    dateFromFilter,
    dateToFilter,
    userFilter: userFilter.value,
    actionFilter: actionFilter.value.join(),
    authMethodFilter: "",
    studyIdFilter: props.study.id,
  });

  let response: AxiosResponse<AuditLogEventGetManyResponseDto> | undefined = undefined;

  try {
    response = await axios.get<AuditLogEventGetManyResponseDto>(
      `/api/tenants/audit-log-events?${query.toString()}`
    );
  } catch (exception) {
    error();
    return;
  }

  auditLogEvents.value.push(...response.data);

  page += 1;
  loaded(response.data.length, PAGE_SIZE);
}

const isReloadRequired = ref(true);

function reloadAuditLogEvents(): void {
  auditLogEvents.value = [];
  page = 0;
  isReloadRequired.value = true;
}

watch([userFilter, actionFilter, dateRangeFilter], useDebounceFn(reloadAuditLogEvents, 500));
</script>

<style scoped lang="scss">
:deep(.date-picker) {
  .content {
    background-color: var(--bg-color-2) !important;

    &:hover {
      background-color: var(--bg-color-3) !important;
    }
  }
}

:deep(.dropdown-input-checkbox) {
  input {
    background: none !important;
  }
}

.audit-logs {
  display: flex;
  flex: 1;
  min-height: 0;
  width: 90vw;
  height: 70vh;
}

.audit-logs-table {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  margin: 12px 0 0 8px;
  padding-right: 8px;

  display: grid;
  grid-template-columns: 36px 280px 280px 1fr auto auto;
  grid-template-rows: min-content;
  grid-auto-rows: min-content;
  row-gap: 4px;
}

.header-cell {
  top: 0;
  padding-top: 1px;
  position: sticky;
  display: grid;
  grid-template-areas: "filter" "title";
  grid-template-rows: 32px 32px;
  gap: 4px;
  padding-right: 16px;
  align-items: center;
  background-color: var(--bg-color-2);
  border-bottom: 1px solid var(--accent-color-1);

  .filter {
    grid-area: filter;
  }

  .title {
    grid-area: title;
    font-weight: bold;
  }
}

.eternal-loading-status {
  padding-top: 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  place-self: center;
}

.loading-message {
  padding-top: 20px;
  display: flex;
}

.loading-error {
  font-weight: bold;
  color: red;
  padding-top: 20px;
  display: flex;
}
</style>
