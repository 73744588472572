export function abs(a: number[]): number[] {
  return a.map((n) => Math.abs(n));
}

export function add(a: number[], b: number[]): number[] {
  return a.map((n, index) => n + b[index]);
}

export function sub(a: number[], b: number[] | number): number[] {
  if (Array.isArray(b)) {
    if (a.length !== b.length) {
      throw Error("arrays have different length");
    }

    return a.map((n, index) => n - b[index]);
  }

  return a.map((n) => n - b);
}

export function scale(a: number[], t: number): number[] {
  return a.map((n) => n * t);
}

export function distance(a: number[], b: number[]): number {
  return ((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2) ** 0.5;
}

export function clamp(value: number, minValue: number, maxValue: number): number {
  return Math.max(Math.min(value, maxValue), minValue);
}

export function isPointWithinTolerance(
  pointA: number[],
  pointB: number[],
  tolerance = 0.005
): boolean {
  return (
    pointA[0] < pointB[0] + tolerance &&
    pointA[0] > pointB[0] - tolerance &&
    pointA[1] < pointB[1] + tolerance &&
    pointA[1] > pointB[1] - tolerance
  );
}

export function arePointsEqual(pointA: number[], pointB: number[]): boolean {
  return pointA[0] === pointB[0] && pointA[1] === pointB[1];
}

export function isPolygonClosed(points: number[]): boolean {
  return points.length >= 8 && arePointsEqual(points.slice(0, 2), points.slice(-2));
}

/** Computes a modulus, ensuring that the result is a positive number. */
export function positiveModulo(dividend: number, divisor: number): number {
  return ((dividend % divisor) + divisor) % divisor;
}
