export enum StudyMeasurementValueSource {
  Manual = "manual",
  DICOM = "dicom",
  External = "external",
  Calculated = "calculated",
}

export enum StudyMeasurementDisplayOption {
  Unindexed = "unindexed",
  PreferIndexed = "preferIndexed",
  UnindexedAndIndexed = "unindexedAndIndexed",
}
