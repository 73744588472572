<template>
  <div class="pin-field" :class="`size-${size}`">
    <input
      v-for="index in Array(size)
        .fill(0)
        .map((_, i) => i)"
      :key="index"
      ref="inputElements"
      :value="modelValue[index] ?? ''"
      :data-testid="`pin-field-${index}`"
      type="text"
      maxlength="1"
      :class="{ password }"
      @keypress="onKeypress"
      @input="onUpdateDigit($event, index)"
      @keydown.delete="onUpdateDigit($event, index)"
      @keydown.enter="emits('enter')"
      @paste="onPaste($event)"
      @mousedown="onMouseDown($event, index)"
    />

    <!-- This hidden button is used to clear the PIN value in the E2E tests -->
    <button hidden data-testid="clear-pin" @click="clearPinValue" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

interface Props {
  modelValue: string;
  password?: boolean;
  size?: number;
  autoFocus?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
  (event: "enter"): void;
}

const props = withDefaults(defineProps<Props>(), { autoFocus: true, password: true, size: 4 });

const emits = defineEmits<Emits>();

const inputElements = ref<HTMLInputElement[]>([]);

function clearPinValue(): void {
  emits("update:modelValue", "");
}

function onUpdateDigit(event: Event, index: number): void {
  let newValue = props.modelValue;

  // Append new digit to end of the current value
  if (event instanceof InputEvent && newValue.length < props.size) {
    newValue += inputElements.value[index].value;
  }

  // Remove last digit on backspace
  else if (event instanceof KeyboardEvent && event.key === "Backspace") {
    newValue = newValue.slice(0, -1);
  }

  emits("update:modelValue", newValue);
}

function onPaste(event: ClipboardEvent): void {
  event.stopPropagation();
  event.preventDefault();

  const clipboardData = event.clipboardData?.getData("Text");

  // Check that the clipboard data contains data and that all characters are digits.
  if (clipboardData === undefined || !/^[0-9]{1,}$/.test(clipboardData)) {
    return;
  }

  let newValue = props.modelValue;
  for (let i = 0; i < props.size; i++) {
    // Terminate early if there are fewer characters than needed in the clipboard, or if the entered
    // pin length is now the correct passcode length.
    if (typeof clipboardData[i] === "undefined" || newValue.length === props.size) {
      break;
    }
    newValue += clipboardData[i];
  }

  emits("update:modelValue", newValue);
}

// Only allow numbers to be entered
function onKeypress(event: Event): void {
  if (event instanceof KeyboardEvent && !/^[0-9]$/i.test(event.key)) {
    event.preventDefault();
  }
}

// Prevent clicking back to input fields that have values
function onMouseDown(event: Event, index: number): void {
  if (index !== props.modelValue.length && index !== props.modelValue.length - 1) {
    event.preventDefault();
  }
}

// Focuses the next relevant input field
function updateFocusedInput(): void {
  inputElements.value[Math.min(props.modelValue.length, props.size - 1)]?.focus();
}

defineExpose({ focusInput: updateFocusedInput });

// When the PIN value changes externally, if this PIN field has focus then ensure that the correct
// input element has focus
watch(
  () => [props.modelValue],
  () => {
    const isFocused = inputElements.value.some((input) => input === document.activeElement);
    if (isFocused) {
      updateFocusedInput();
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (props.autoFocus) {
    updateFocusedInput();
  }
});
</script>

<style scoped lang="scss">
.pin-field {
  display: flex;
  gap: 4px;

  // For 6-digit fields, split into two groups of three digits
  &.size-6 :nth-child(3) {
    margin-right: 8px;
  }
}

input {
  width: 34px;
  height: 32px;
  box-sizing: border-box;

  font-size: 12px;
  padding-top: 8px;
  text-align: center;

  &.password {
    font-family: "password-dots";
  }
}
</style>
