<template>
  <!--
    The inline styles below are because the DOM content for the report header is used by Puppeteer,
    and in that context page header content can only be styled with inline styles.
  -->
  <div
    class="report-logo"
    :class="{ editable: isEditingReportStructure }"
    style="height: 7.4em"
    @click="fileInputElement?.click()"
  >
    <template v-if="isEditingReportStructure">
      <template v-if="props.modelValue">
        <Tooltip content="Click to update report logo" placement="bottom">
          <img :src="props.modelValue" class="logo-img" />
        </Tooltip>
        <div class="delete-logo-btn">
          <Tooltip content="Remove report logo" placement="right">
            <FontAwesomeIcon
              icon="trash"
              size="lg"
              data-testid="remove-report-logo"
              @click.stop="onRemoveLogo"
            />
          </Tooltip>
        </div>
      </template>
      <div v-else class="add-logo-prompt"><FontAwesomeIcon icon="plus" /> Add Logo</div>

      <input
        ref="fileInputElement"
        data-testid="report-logo-input"
        hidden
        type="file"
        @change="onLogoSelected()"
      />
    </template>

    <!-- The inline style here is identical to the logo-img class. See comment at top. -->
    <img
      v-else-if="props.modelValue"
      :src="props.modelValue"
      style="
        height: 7.4em;
        max-width: 100%;
        object-fit: contain;
        object-position: left center;
        border-radius: 0.2em;
      "
      data-testid="report-logo"
      @click="fileInputElement?.click()"
    />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import Tooltip from "../components/Tooltip.vue";
import { prepareUploadedImage } from "../utils/image-utils";
import { ReportContentMode } from "./report-content";

interface Props {
  modelValue: string;
  mode: ReportContentMode;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isEditingReportStructure = computed(
  () => props.mode === ReportContentMode.EditReportStructure
);

const fileInputElement = ref<HTMLInputElement>();

async function onLogoSelected(): Promise<void> {
  const files = fileInputElement.value!.files!;
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  emits("update:modelValue", await prepareUploadedImage(file, 400, 100));

  // Clear out the value on the input field so that repeated selection of the same file
  // triggers a re-upload
  fileInputElement.value!.value = "";
}

function onRemoveLogo(): void {
  emits("update:modelValue", "");
}
</script>

<style scoped lang="scss">
.report-logo {
  &.editable {
    cursor: pointer;
    position: relative;
    font-weight: bold;
    transition:
      color 100ms ease,
      background-color 100ms ease;
    background-color: var(--report-section-bg-color);
    display: grid;
    color: var(--report-accent-color-1);
    border: 0.1em solid var(--report-widget-border-color);
    border-radius: 0.2em;

    &:hover {
      color: var(--report-accent-color-2);
      background-color: var(--report-widget-bg-color-hover);
    }
  }
}

.add-logo-prompt {
  place-self: center;
  display: flex;
  gap: 0.4em;
  padding: 0 1.6em;
  white-space: nowrap;
  font-size: 1.2em;
}

.delete-logo-btn {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  color: var(--report-accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--report-accent-color-2);
  }
}

.logo-img {
  height: 7.4em;
  max-width: 100%;
  object-fit: contain;
  object-position: left center;
  border-radius: 0.2em;
}
</style>
