<template>
  <Popper
    class="dropdown-popper"
    placement="bottom-start"
    :offset-distance="2"
    interactive
    @open="isDropdownOpen = true"
    @close="isDropdownOpen = false"
  >
    <div
      class="dropdown-input-checkbox"
      :class="{ open: isDropdownOpen }"
      data-testid="dropdown-input-checkbox"
      @click="onClickInput"
    >
      <FontAwesomeIcon icon="search" class="search-icon" size="lg" />

      <input
        v-model="searchTerm"
        name="action-input"
        :placeholder="text"
        autocomplete="off"
        @keydown.enter="onEnterPressed"
      />

      <FontAwesomeIcon
        :style="{ visibility: modelValue.length === 0 ? 'hidden' : 'visible' }"
        icon="times"
        class="clear-icon"
        @mousedown.stop
        @click="onClear"
      />
    </div>

    <template #content>
      <div class="dropdown">
        <div
          v-for="option in sortedOptions"
          :key="option.index"
          class="dropdown-item"
          data-testid="dropdown-option"
          :class="{ matched: option.matched }"
          @click="onClickOption(option.value)"
        >
          <Checkbox :model-value="modelValue.includes(option.value)">
            {{ option.text }}
          </Checkbox>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import Checkbox from "./Checkbox.vue";

interface Props {
  text: string;
  options: { value: string; text: string }[];
  modelValue: string[];
}

interface Emits {
  (event: "update:modelValue", newValue: string[]): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const modelValue = computed({
  get: () => props.modelValue,
  set: (event) => emits("update:modelValue", event),
});

const isDropdownOpen = ref(false);

const searchTerm = ref("");

const sortedOptions = computed(() => {
  const lowerSearchTerm = searchTerm.value.toLowerCase();

  return props.options
    .map((option, index) => ({
      ...option,
      index,
      matched: option.text.toLowerCase().includes(lowerSearchTerm),
    }))
    .sort((a, b) => Number(b.matched) - Number(a.matched));
});

function onClear(): void {
  modelValue.value = [];
  searchTerm.value = "";
}

function onClickInput(event: MouseEvent): void {
  if (isDropdownOpen.value) {
    event.stopPropagation();
  }
}

function onClickOption(value: string): void {
  if (props.modelValue.includes(value)) {
    modelValue.value = props.modelValue.filter((v) => v !== value);
  } else {
    modelValue.value = [...props.modelValue, value];
  }
}

function onEnterPressed(): void {
  modelValue.value = sortedOptions.value
    .filter((option) => option.matched)
    .map((option) => option.value);
}
</script>

<style scoped lang="scss">
:deep(.dropdown-popper) {
  background-color: var(--bg-color-3);
  padding: 0;
}

.dropdown-input-checkbox {
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--bg-color-2);
  transition: background-color 100ms ease;
  padding: 0 8px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-1);

  &:focus-within {
    border-color: var(--input-focus-border-color);
  }

  &:hover,
  &.open {
    background-color: var(--bg-color-3);
  }

  .search-icon {
    color: var(--accent-color-1);
  }

  input {
    cursor: text;
    flex: 1;
    background: none;
    border: none;
  }

  .clear-icon {
    cursor: pointer;
    transition: color 100ms ease;

    &:hover {
      color: var(--text-color-2);
    }
  }
}

.dropdown {
  display: grid;
  gap: 8px;
  padding: 8px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item {
  transition:
    color 100ms ease,
    opacity 100ms ease;
  opacity: 0.7;

  &:hover {
    color: var(--text-color-2);
  }

  &.matched {
    opacity: 1;
  }
}
</style>
