import {
  ItemType,
  StartMedicalStreamTranscriptionCommand,
} from "@aws-sdk/client-transcribe-streaming";
import { createAudioStream } from "./audio-stream";
import type { AudioStreamWorkletProcessorMessage } from "./audio-stream-worklet-processor";
import { getTranscribeStreamingClient } from "./aws-transcribe-client";
import { transcriptionCapitalizedWords } from "./transcription-capitalized-words";

/**
 * Starts a new transcription session with AWS Transcribe. The `onOutput` callback is called when
 * there is a result from the transcription itself. An `abortSignal` must be passed and is used to
 * asynchronously stop the ongoing transcription process.
 */
// eslint-disable-next-line max-statements
export async function* transcribeAudioStreamToText(
  isSpokenPunctuationEnabled: boolean,
  maxDurationInSeconds: number,
  abortSignal: AbortSignal
): AsyncGenerator<{ text: string; isPartial: boolean }> {
  function isAborted(): boolean {
    return abortSignal.aborted;
  }

  // Get an AWS Transcribe client to use
  const transcribeClient = await getTranscribeStreamingClient();
  if (transcribeClient === null || isAborted()) {
    return;
  }

  // Create a new incoming audio stream
  const audioStream = await createAudioStream(maxDurationInSeconds);

  try {
    // Start a new transcription stream session with AWS connected to the audio stream
    const command = new StartMedicalStreamTranscriptionCommand({
      LanguageCode: "en-US",
      AudioStream: getAudioStream(audioStream.workletMessageIterator, abortSignal),
      MediaEncoding: "pcm",
      MediaSampleRateHertz: audioStream.sampleRate,
      Specialty: "CARDIOLOGY",
      Type: "DICTATION",
      VocabularyName: isSpokenPunctuationEnabled ? "spoken-punctuation" : undefined,
    });

    const data = await transcribeClient.send(command);

    if (data.TranscriptResultStream === undefined || isAborted()) {
      return;
    }

    let isCurrentSentenceComplete = true;
    let removeCapitalizationFromNextWord = false;

    // Iterate over transcription results as they become available
    for await (const event of data.TranscriptResultStream) {
      if (event.TranscriptEvent?.Transcript === undefined) {
        continue;
      }

      for (const result of event.TranscriptEvent.Transcript.Results ?? []) {
        if (result.Alternatives?.[0].Items === undefined || result.IsPartial === undefined) {
          continue;
        }

        let text = "";

        for (const item of result.Alternatives[0].Items) {
          if (item.Content === undefined) {
            continue;
          }

          let itemContent = item.Content;

          if (isSpokenPunctuationEnabled) {
            if (item.Type === ItemType.PUNCTUATION) {
              removeCapitalizationFromNextWord =
                detectSpokenPunctuation(itemContent)?.isEndOfSentence ?? false;
              continue;
            }

            const processed = handleSpokenPunctuation(
              itemContent,
              isCurrentSentenceComplete,
              removeCapitalizationFromNextWord
            );
            itemContent = processed.text;

            if (!result.IsPartial) {
              removeCapitalizationFromNextWord = false;
              isCurrentSentenceComplete = processed.isEndOfSentence;
            }
          } else if (item.Type !== ItemType.PUNCTUATION) {
            text += " ";
          }

          text += itemContent;
        }

        yield { text, isPartial: result.IsPartial };
      }
    }

    await audioStream.workletMessageIterator.return?.();
  } finally {
    audioStream.destroy();
  }
}

function handleSpokenPunctuation(
  text: string,
  isCurrentSentenceComplete: boolean,
  removeCapitalizationFromNextWord: boolean
): { text: string; isEndOfSentence: boolean } {
  // If the item is spoken punctuation then use it instead of the dictated text
  const punctuation = detectSpokenPunctuation(text);
  if (punctuation !== undefined) {
    return punctuation;
  }

  // If the item is not spoken punctuation then it is a word, so check if this is the start of a new
  // sentence and capitalize appropriately
  else if (isCurrentSentenceComplete) {
    return { text: ` ${capitalizeFirstLetter(text)}`, isEndOfSentence: false };
  }

  // Force lower case on the item if we previously ignored punctuation that ends a sentence
  // from AWS Transcribe, and the item is not an acronym or a word that should be capitalized
  else if (removeCapitalizationFromNextWord) {
    return {
      text: ` ${isAcronym(text) || transcriptionCapitalizedWords.has(text) ? text : text.toLowerCase()}`,
      isEndOfSentence: false,
    };
  }

  // This is dictated text in the middle of a sentence, so append without alteration
  return { text: ` ${text}`, isEndOfSentence: false };
}

function detectSpokenPunctuation(
  text: string
): { text: string; isEndOfSentence: boolean } | undefined {
  if ([".", "!", "?"].includes(text)) {
    return { text, isEndOfSentence: true };
  } else if (text === "," || text === ";") {
    return { text, isEndOfSentence: false };
  }

  return undefined;
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isAcronym(text: string): boolean {
  return text.length > 1 && text.toUpperCase() === text;
}

// Generator function to get audio stream in correct format for AWS Transcribe
async function* getAudioStream(
  audioDataIterator: AsyncIterable<MessageEvent<AudioStreamWorkletProcessorMessage>>,
  abortSignal: AbortSignal
) {
  for await (const { data } of audioDataIterator) {
    if (abortSignal.aborted) {
      yield { AudioEvent: {} };
    } else {
      yield { AudioEvent: { AudioChunk: data.pcmData } };
    }
  }
}
