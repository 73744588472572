import {
  arePointsEqual,
  clamp,
  isPointWithinTolerance,
} from "@/../../backend/src/shared/math-utils";
import Flatten from "@flatten-js/core";
import { computed, ref, watch } from "vue";
import type { VolumeAssociatedMeasurementName } from "../../../../backend/src/measurements/associated-measurements";
import { volumeAssociatedMeasurements } from "../../../../backend/src/measurements/associated-measurements";
import { getDrawableVolumeMeasurements } from "../../../../backend/src/measurements/drawable-measurements";
import { MeasuredValue } from "../../../../backend/src/measurements/measured-value";
import { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import { getVolumeMeasurementValue } from "../../../../backend/src/measurements/measurement-tool-evaluation";
import {
  getApexPoint,
  getDisksForVolume,
  getVolumeAssociatedMeasurementValues,
} from "../../../../backend/src/measurements/measurement-tool-evaluation-volume-helpers";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import { RegionUnit } from "../../../../backend/src/studies/study-clip-region";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { currentTenant } from "../../auth/current-session";
import type { Study, StudyClip, StudyClipRegion } from "../../utils/study-data";
import { findSavedAssociatedMeasurements } from "../measurement-helpers";
import {
  buildMeasurementToolBatchChangeRequest,
  generateAssociatedMeasurementRefs,
  setAssociatedMeasurementDetailsOnEdit,
  setDefaultEnabledForAssociatedMeasurements,
} from "../measurement-tool-helpers";
import { calculateCatmullRomCurveThroughPoints } from "./catmull-rom-spline";
import { getLinearMeasurementLabelPosition } from "./linear/measurement-base-linear";
import {
  createBaseContourMeasurement,
  isPolygonSelfIntersecting,
} from "./measurement-base-contour";
import type {
  AssociatedMeasurement,
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "./measurement-tool";
import { getAreaMeasurementLabelPosition } from "./measurement-tool-area";
import {
  MEASUREMENT_COLOR,
  MEASUREMENT_INVALID_COLOR,
  RESTART_MEASUREMENT_ICON,
  createTransientValue,
  getEquidistantPoints,
} from "./measurement-tool-helpers";
import {
  measurementIncompleteTooltipText,
  measurementIntersectingTooltipText,
} from "./measurement-tooltips";

enum VolumeMeasurementState {
  PlacingPoints = 0,
  Editing = 1,
}

/** Creates a new volume measurement tool instance for the given study and region. */
// eslint-disable-next-line max-statements
export function createVolumeMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomVolume;
  const customName = ref("");

  const baseMeasurementCreationRequest = {
    tool: MeasurementToolName.Volume,
    studyClipId,
  };

  const frame = ref(0);

  const state = ref(VolumeMeasurementState.PlacingPoints);

  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const lastMousePosition = ref<number[]>([]);

  const isEditingAxisAngle = ref(false);

  const isProcessing = ref(false);

  const axisAngle = ref(0);

  const leftBasalPoint = computed(() =>
    baseContourMeasurement.isClosed.value
      ? Flatten.point(baseContourMeasurement.points[0], baseContourMeasurement.points[1])
      : undefined
  );

  const rightBasalPoint = computed(() =>
    baseContourMeasurement.isClosed.value
      ? Flatten.point(
          baseContourMeasurement.points[baseContourMeasurement.points.length - 4],
          baseContourMeasurement.points[baseContourMeasurement.points.length - 3]
        )
      : undefined
  );

  const basalSegment = computed(() => {
    if (leftBasalPoint.value === undefined || rightBasalPoint.value === undefined) {
      return undefined;
    }

    return Flatten.segment(leftBasalPoint.value, rightBasalPoint.value);
  });

  const basalMidpoint = computed(() => basalSegment.value?.middle());

  const apexPoint = computed(() => {
    if (basalSegment.value === undefined || basalMidpoint.value === undefined) {
      return undefined;
    }

    const basalSegmentPoints: [number, number, number, number] = [
      basalSegment.value.ps.x,
      basalSegment.value.ps.y,
      basalSegment.value.pe.x,
      basalSegment.value.pe.y,
    ];

    return getApexPoint(baseContourMeasurement.points, basalSegmentPoints, axisAngle.value);
  });

  const baseContourMeasurement = createBaseContourMeasurement(
    studyClip,
    region,
    lastMousePosition,
    currentTenant.isVolumeMeasurementAutomaticResamplingEnabled,
    () => {
      if (studyClip === undefined || apexPoint.value === undefined) {
        return [];
      }

      return getResampledVolumePoints(
        baseContourMeasurement.points.slice(0, -2),
        [apexPoint.value.x, apexPoint.value.y],
        studyClip
      );
    }
  );

  const axisPoints = computed(() => {
    if (basalMidpoint.value === undefined || apexPoint.value === undefined) {
      return undefined;
    }

    return [basalMidpoint.value.x, basalMidpoint.value.y, apexPoint.value.x, apexPoint.value.y];
  });

  const contour = computed(() => {
    if (axisPoints.value === undefined) {
      return undefined;
    }

    return [...baseContourMeasurement.points, ...axisPoints.value];
  });

  const volumeMeasuredValue = computed(() => {
    if (axisPoints.value === undefined || studyClip === undefined || region === undefined) {
      return MeasuredValue.Null;
    }

    return getVolumeMeasurementValue(
      baseContourMeasurement.points,
      axisPoints.value,
      studyClip,
      region
    );
  });

  const interactivePoints = computed(() =>
    baseContourMeasurement.isClosed.value
      ? [
          ...baseContourMeasurement.interactivePoints.value,
          ...(apexPoint.value === undefined ? [] : [apexPoint.value.x, apexPoint.value.y]),
        ]
      : []
  );

  const helpText = computed(
    () =>
      ({
        [VolumeMeasurementState.PlacingPoints]: "Start and end on basal points",
        [VolumeMeasurementState.Editing]: "Adjust points and axis as needed",
      })[state.value]
  );

  const mousePositionIfUsed = computed(() => {
    if (state.value !== VolumeMeasurementState.PlacingPoints) {
      return null;
    }

    return lastMousePosition.value;
  });

  const toolbarItems = computed(() => {
    const items: ToolbarItem[] = [
      {
        text: volumeMeasuredValue.value.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
      {
        text: "Resample",
        enabled: state.value === VolumeMeasurementState.Editing,
        onClick: baseContourMeasurement.resamplePoints,
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: restart,
      });
    }

    return items;
  });

  const isSaveButtonEnabled = computed(() => {
    if (
      state.value === VolumeMeasurementState.PlacingPoints ||
      volumeMeasuredValue.value.isNull() ||
      volumeMeasuredValue.value.isZero()
    ) {
      return false;
    }

    if (isPolygonSelfIntersecting(baseContourMeasurement.points)) {
      return false;
    }

    return true;
  });

  const saveButtonTooltip = computed(() => {
    if (!baseContourMeasurement.isClosed.value) {
      return measurementIncompleteTooltipText;
    }

    if (isPolygonSelfIntersecting(baseContourMeasurement.points)) {
      return measurementIntersectingTooltipText;
    }

    return "";
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (state.value === VolumeMeasurementState.PlacingPoints || contour.value === undefined) {
      return [];
    }

    const position = getAreaMeasurementLabelPosition({
      canvas,
      points: baseContourMeasurement.points,
    });

    const labels: MeasurementLabel[] = [];

    labels.push({
      measurementName: measurementName.value ?? customMeasurementName,
      measurementValue: volumeMeasuredValue.value.createTransientValue(
        measurementName.value ?? customMeasurementName,
        contour.value,
        frame.value
      ),
      x: position.x,
      y: position.y,
    });

    const majorAxisMeasurement = associatedMeasurements.value.majorAxisLength;
    if (majorAxisMeasurement?.enabled.value === true && axisPoints.value?.length === 4) {
      const majorAxisPosition = getLinearMeasurementLabelPosition({
        canvas,
        from: axisPoints.value.slice(0, 2),
        to: axisPoints.value.slice(2, 4),
      });

      labels.push({
        measurementName: majorAxisMeasurement.name,
        measurementValue: createTransientValue(
          majorAxisMeasurement.name,
          majorAxisMeasurement.contour,
          majorAxisMeasurement.value,
          majorAxisMeasurement.unit,
          frame.value
        ),
        x: majorAxisPosition.x,
        y: majorAxisPosition.y,
      });
    }

    const areaMeasurement = associatedMeasurements.value.area;
    if (areaMeasurement?.enabled.value === true) {
      labels.push({
        measurementName: areaMeasurement.name,
        measurementValue: createTransientValue(
          areaMeasurement.name,
          areaMeasurement.contour,
          areaMeasurement.value,
          areaMeasurement.unit,
          frame.value
        ),
        x: position.x,
        y: position.y + 20,
      });
    }

    return labels;
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    baseContourMeasurement.drawToCanvas(canvas, ctx, false);

    drawVolumeMeasurementPerpendicularScanlines(
      canvas,
      ctx,
      baseContourMeasurement.points,
      axisPoints.value
    );
    drawVolumeMeasurementAxis(canvas, ctx, axisPoints.value);
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (state.value === VolumeMeasurementState.PlacingPoints) {
      baseContourMeasurement.onCanvasMouseDown(pt);
    } else {
      onEditingPointsMouseDown(pt);
    }

    if (
      baseContourMeasurement.isClosed.value &&
      state.value === VolumeMeasurementState.PlacingPoints
    ) {
      state.value = VolumeMeasurementState.Editing;
    }
  }

  function onEditingPointsMouseDown(pt: number[]): void {
    if (leftBasalPoint.value === undefined || rightBasalPoint.value === undefined) {
      return;
    }

    if (
      apexPoint.value !== undefined &&
      isPointWithinTolerance(pt, [apexPoint.value.x, apexPoint.value.y])
    ) {
      isEditingAxisAngle.value = true;
      return;
    }

    // Also allow changing main points in this state
    baseContourMeasurement.onCanvasMouseDown(pt, { editingOnly: true, allowDoubleClick: true });
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    lastMousePosition.value = pt;

    if (isEditingAxisAngle.value) {
      if (basalMidpoint.value === undefined || rightBasalPoint.value === undefined) {
        return true;
      }
      const basalLine = Flatten.line(leftBasalPoint.value, rightBasalPoint.value);

      let mousePosition = Flatten.point(pt[0], pt[1]);

      if (mousePosition.leftTo(basalLine)) {
        const projectedPt = mousePosition.projectionOn(basalLine);

        const vec = Flatten.vector(mousePosition, projectedPt);
        mousePosition = Flatten.point(projectedPt.x + vec.x, projectedPt.y + vec.y);
      }

      const midpointToMouse = Flatten.vector(basalMidpoint.value, mousePosition);
      const midpointToEnd = Flatten.vector(basalMidpoint.value, rightBasalPoint.value);

      axisAngle.value = clamp(Math.PI * 0.5 - midpointToMouse.angleTo(midpointToEnd), -1, 1);

      return true;
    }

    return baseContourMeasurement.onCanvasMouseMove(pt) || basalMidpoint.value !== undefined;
  }

  function onCanvasMouseUp(pt: number[]): void {
    lastMousePosition.value = pt;

    baseContourMeasurement.onCanvasMouseUp(pt);
    isEditingAxisAngle.value = false;

    if (
      baseContourMeasurement.isClosed.value &&
      state.value === VolumeMeasurementState.PlacingPoints
    ) {
      state.value = VolumeMeasurementState.Editing;
    }
  }

  const associatedAreaRefs = generateAssociatedMeasurementRefs();
  const associatedMajorAxisLengthRefs = generateAssociatedMeasurementRefs();

  const associatedMeasurements = computed(() => {
    if (measurementName.value === undefined) {
      return {};
    }

    const associatedNames = volumeAssociatedMeasurements[measurementName.value];

    if (associatedNames === undefined) {
      return {};
    }

    const result: Partial<Record<VolumeAssociatedMeasurementName, AssociatedMeasurement>> = {};

    const associatedMeasurementValues = getVolumeAssociatedMeasurementValues(
      measurementName.value,
      studyClip,
      region,
      baseContourMeasurement.points,
      axisPoints.value ?? []
    );

    if (associatedMeasurementValues.majorAxisLength) {
      result.majorAxisLength = {
        ...associatedMajorAxisLengthRefs,
        ...baseMeasurementCreationRequest,
        ...associatedMeasurementValues.majorAxisLength,
        frame: frame.value,
      };
    }

    if (associatedMeasurementValues.area) {
      result.area = {
        ...associatedAreaRefs,
        ...baseMeasurementCreationRequest,
        ...associatedMeasurementValues.area,
        frame: frame.value,
      };
    }

    if (savedMeasurementBeingEdited.value === undefined) {
      setDefaultEnabledForAssociatedMeasurements(result, associatedNames);
    }

    return result;
  });

  function toggleAssociatedMeasurement(name: string): void {
    const associatedMeasurement =
      associatedMeasurements.value[name as VolumeAssociatedMeasurementName];

    if (associatedMeasurement !== undefined) {
      associatedMeasurement.enabled.value = !associatedMeasurement.enabled.value;

      requestRedrawHandlers.forEach((fn) => fn());
    }
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const volumeUnitAndValue = volumeMeasuredValue.value.getInternalValueAndUnit() ?? null;

    if (
      measurementName.value === undefined ||
      volumeUnitAndValue === null ||
      contour.value === undefined
    ) {
      return {};
    }

    const mainMeasurementDetails = {
      ...baseMeasurementCreationRequest,
      ...volumeUnitAndValue,
      name: measurementName.value,
      customName: customName.value,
      frame: frame.value,
      contour: contour.value,
    };

    return buildMeasurementToolBatchChangeRequest(
      mainMeasurementDetails,
      savedMeasurementBeingEdited.value,
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);
  const previouslySavedAssociatedMeasurements = ref<
    (MeasurementToolRecreationDetails & { measurementId: string })[]
  >([]);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    state.value = VolumeMeasurementState.Editing;
    baseContourMeasurement.points.splice(
      0,
      baseContourMeasurement.points.length,
      ...value.contour.slice(0, -4)
    );
    frame.value = value.frame ?? 0;
    axisAngle.value = getAxisAngleFromSavedContour(value.contour);

    previouslySavedAssociatedMeasurements.value = findSavedAssociatedMeasurements(
      study,
      value,
      volumeAssociatedMeasurements
    );

    setAssociatedMeasurementDetailsOnEdit(
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  function restart(): void {
    axisAngle.value = 0;
    state.value = VolumeMeasurementState.PlacingPoints;
    baseContourMeasurement.restart();
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([...baseContourMeasurement.redrawTriggers.value, axisPoints, mousePositionIfUsed], () =>
    requestRedrawHandlers.forEach((fn) => fn())
  );

  return {
    displayName: "Volume Measurement",
    studyClipId,
    region,
    measurementName,
    customName,
    customMeasurementName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled,
    saveButtonTooltip,
    helpText,
    toolbarItems,
    isProcessing,
    shouldPassMousedownAfterRegionSelection: true,

    interactivePoints,
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),

    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (frameNumber: number) => (frame.value = frameNumber),
    isChangeAllowedOf: (_target: "clip" | "frame") => baseContourMeasurement.points.length === 0,

    associatedMeasurements,
    toggleAssociatedMeasurement,
    getMeasurementChangeRequests,
    getCreatableMeasurementNames: getDrawableVolumeMeasurements,
    isMeasurableOnStudyClip: isVolumeMeasurableOnStudyClip,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function drawVolumeMeasurementAxis(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  axisPoints: number[] | undefined,
  opacity = 1
): void {
  if (axisPoints === undefined || axisPoints.length !== 4) {
    return;
  }

  ctx.strokeStyle = MEASUREMENT_COLOR;
  ctx.lineWidth = 3;
  ctx.globalAlpha = opacity;

  const axisPointsAsPixels = scalePoints(axisPoints, canvas);

  // Draw the line
  ctx.beginPath();
  ctx.moveTo(axisPointsAsPixels[0].x, axisPointsAsPixels[0].y);
  ctx.lineTo(axisPointsAsPixels[1].x, axisPointsAsPixels[1].y);
  ctx.stroke();

  // Draw axis handle (don't draw basal)
  ctx.beginPath();
  ctx.arc(axisPointsAsPixels[1].x, axisPointsAsPixels[1].y, 4, 0, 2 * Math.PI, false);
  ctx.fillStyle = "#44f";
  ctx.fill();
  ctx.stroke();

  ctx.globalAlpha = 1;
}

export function drawVolumeMeasurementPerpendicularScanlines(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  points: number[],
  axisPoints: number[] | undefined
): void {
  if (axisPoints === undefined || axisPoints.length !== 4) {
    return;
  }

  const axisPointsAsPixels = scalePoints(axisPoints, canvas);

  // Calculate the axis direction
  const axisOrigin = axisPointsAsPixels[0];

  let axisDirection = Flatten.vector(
    axisPointsAsPixels[axisPointsAsPixels.length - 1].x - axisOrigin.x,
    axisPointsAsPixels[axisPointsAsPixels.length - 1].y - axisOrigin.y
  );

  // Check axis is well-defined, i.e. at least one pixel in length
  if (axisDirection.length < 1) {
    return;
  }

  // Put scanlines roughly every 10 pixels
  const scanlineCount = Math.ceil(axisDirection.length / 10);

  // The color depends on whether the contour is valid
  ctx.strokeStyle = isPolygonSelfIntersecting(points)
    ? MEASUREMENT_INVALID_COLOR
    : MEASUREMENT_COLOR;

  axisDirection = axisDirection.normalize();

  const disks = getDisksForVolume(scalePoints(points, canvas), axisPointsAsPixels, scanlineCount);

  // Draw scanlines perpendicular to the axis
  for (const disk of disks) {
    const f = axisDirection.multiply(disk.offset);
    const pointOnAxis = axisOrigin.translate(f);

    const left = pointOnAxis.translate(axisDirection.rotate90CCW().multiply(disk.left));
    const right = pointOnAxis.translate(axisDirection.rotate90CCW().multiply(disk.right));

    if (!(left instanceof Flatten.Point) || !(right instanceof Flatten.Point)) {
      continue;
    }

    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(left.x, left.y);
    ctx.lineTo(right.x, right.y);
    ctx.stroke();
  }
}

function scalePoints(points: number[], canvas: HTMLCanvasElement): Flatten.Point[] {
  const result: Flatten.Point[] = [];

  for (let i = 0; i < points.length; i += 2) {
    result.push(Flatten.point(points[i] * canvas.width, points[i + 1] * canvas.height));
  }

  return result;
}

/**
 * Resamples points equally distanced along the volume points in the half they belong to. Midpoint
 * is determined by the apex, and there are 10 points on each side, giving a total of 21 points.
 * Basal points are determined by the first and last points clicked and its edge is not included in
 * the resampling.
 */
export function getResampledVolumePoints(
  inputPoints: number[],
  apexPoint: number[],
  studyClip: { width: number | null; height: number | null }
): number[] {
  const apexEdgeIndex = getEdgeIndexContainingApexPoint(inputPoints, apexPoint);

  if (!isApexPointInContour(inputPoints, apexPoint)) {
    inputPoints.splice((apexEdgeIndex + 1) * 2, 0, ...apexPoint);
  }

  const resampledPoints = calculateCatmullRomCurveThroughPoints(inputPoints);

  let apexPointIndex = resampledPoints.length / 2;

  for (let i = 0; i < resampledPoints.length; i += 2) {
    if (resampledPoints[i] === apexPoint[0] && resampledPoints[i + 1] === apexPoint[1]) {
      apexPointIndex = i;
      break;
    }
  }

  const result = [
    ...getEquidistantPoints(resampledPoints.slice(0, apexPointIndex + 2), 11, studyClip),
    ...getEquidistantPoints(resampledPoints.slice(apexPointIndex), 11, studyClip).slice(2),
  ];

  if (result.length !== 42) {
    alert("Could not resample points properly");
    return [];
  }

  return result;
}

function isApexPointInContour(points: number[], apexPoint: number[]): boolean {
  for (let i = 0; i < points.length; i += 2) {
    if (arePointsEqual(apexPoint, [points[i], points[i + 1]])) {
      return true;
    }
  }

  return false;
}

function getEdgeIndexContainingApexPoint(points: number[], apexPoint: number[]): number {
  let closestEdgeIndex = -1;
  let closestEdgeDistanceToApex = Infinity;

  for (let i = 0; i < points.length; i += 2) {
    const edge = Flatten.line(
      Flatten.point(points[i], points[i + 1]),
      Flatten.point(points[(i + 2) % points.length], points[(i + 3) % points.length])
    );

    const distanceFromApexToEdge = edge.distanceTo(Flatten.point(apexPoint[0], apexPoint[1]))[0];
    if (distanceFromApexToEdge < closestEdgeDistanceToApex) {
      closestEdgeDistanceToApex = distanceFromApexToEdge;
      closestEdgeIndex = i / 2;
    }
  }

  return closestEdgeIndex;
}

// Computes the angle of the axis line from a saved volume contour
function getAxisAngleFromSavedContour(contour: number[]): number {
  const axisVector = Flatten.vector(
    Flatten.point(...contour.slice(-4, -2)),
    Flatten.point(...contour.slice(-2))
  );

  const basalSegmentStart = Flatten.point(...contour.slice(0, 2));
  const basalSegmentEnd = Flatten.point(...contour.slice(contour.length - 8, contour.length - 6));

  const idealAxisVector = Flatten.vector(basalSegmentStart, basalSegmentEnd).rotate90CW();

  return -axisVector.angleTo(idealAxisVector);
}

export function isVolumeMeasurableOnStudyClip(clip: StudyClip, region?: StudyClipRegion): boolean {
  const regions = region ? [region] : clip.regions;

  return (
    clip.modality === "US" &&
    regions.some(
      (r) =>
        r.xDirectionUnit === RegionUnit.Centimeters && r.yDirectionUnit === RegionUnit.Centimeters
    )
  );
}
