import { MeasurementName } from "./measurement-names";
import { MeasurementToolName } from "./measurement-tool-names";

/**
 * Returns the list of measurements that can be drawn with the specified measurement tool.
 */
export function getDrawableMeasurementsForTool(tool: MeasurementToolName): MeasurementName[] {
  switch (tool) {
    case MeasurementToolName.Angle:
      return getDrawableAngleMeasurements();
    case MeasurementToolName.Distance:
      return getDrawableDistanceMeasurements();
    case MeasurementToolName.Time:
      return [...getDrawableSpectralTimeMeasurements(), ...getDrawableMModeTimeMeasurements()];
    case MeasurementToolName.Displacement:
      return getDrawableDisplacementMeasurements();
    case MeasurementToolName.Area:
      return getDrawableAreaMeasurements();
    case MeasurementToolName.Volume:
      return getDrawableVolumeMeasurements();
    case MeasurementToolName.EjectionFraction:
      return getDrawableUniplaneEjectionFractionMeasurements();
    case MeasurementToolName.VelocityTimeIntegral:
      return getDrawableVTIMeasurements();
    case MeasurementToolName.DopplerSlope:
      return getDrawableDopplerSlopeMeasurements();
    case MeasurementToolName.MModeSlope:
      return getDrawableMModeSlopeMeasurements();
    case MeasurementToolName.Velocity:
      return getDrawableVelocityMeasurements();
  }
}

/**
 * Returns the names of the measurements that can be drawn with the angle measurement tool.
 */
export function getDrawableAngleMeasurements(): MeasurementName[] {
  return [MeasurementName.CustomAngle];
}

/**
 * Returns the names of the measurements that can be drawn with the distance measurement tool.
 */
export function getDrawableDistanceMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomDistance,
    MeasurementName.AbdominalAortaDiameter,
    MeasurementName.AorticAnnulusDiameter,
    MeasurementName.AorticArchDiameter,
    MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceAreaRadius,
    MeasurementName.AorticRegurgitationVenaContractaWidth,
    MeasurementName.AorticRootDiameter,
    MeasurementName.AorticSinotubularJunctionDiameter,
    MeasurementName.AscendingAortaDiameter,
    MeasurementName.AtrioseptalDefectDiameterMajorAxis,
    MeasurementName.AtrioseptalDefectDiameterMinorAxis,
    MeasurementName.DescendingAortaDiameter,
    MeasurementName.InferiorVenaCavaDiameter,
    MeasurementName.InferiorVenaCavaDiameterExpiration,
    MeasurementName.InferiorVenaCavaDiameterInspiration,
    MeasurementName.InterventricularSeptumDiastolicThickness,
    MeasurementName.InterventricularSeptumSystolicThickness,
    MeasurementName.LeftAtrialAppendageOcclusionDiscDepth,
    MeasurementName.LeftAtriumDiameter,
    MeasurementName.LeftAtriumDiameterA2C,
    MeasurementName.LeftAtriumDiameterA4C,
    MeasurementName.LeftAtriumDiastolicMajorAxisA2C,
    MeasurementName.LeftAtriumDiastolicMajorAxisA4C,
    MeasurementName.LeftAtriumSystolicMajorAxisA2C,
    MeasurementName.LeftAtriumSystolicMajorAxisA4C,
    MeasurementName.LeftPulmonaryArteryDiameter,
    MeasurementName.LeftVentricleInternalEndDiastolicDimension,
    MeasurementName.LeftVentricleInternalSystolicDimension,
    MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA2C,
    MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA4C,
    MeasurementName.LeftVentricleMajorAxisSystolicDimensionA2C,
    MeasurementName.LeftVentricleMajorAxisSystolicDimensionA4C,
    MeasurementName.LeftVentricleOutflowTractDiameter,
    MeasurementName.LeftVentriclePosteriorWallDiastolicThickness,
    MeasurementName.LeftVentriclePosteriorWallSystolicThickness,
    MeasurementName.MainPulmonaryArteryDiameter,
    MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceAreaRadius,
    MeasurementName.MitralValveAnnulusDiameter,
    MeasurementName.PatentDuctusArteriosusDiameter,
    MeasurementName.ProximalInterventricularSeptumThickness,
    MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius,
    MeasurementName.PulmonaryRootDiameter,
    MeasurementName.RightAtriumDiameterDiastole,
    MeasurementName.RightAtriumDiameterSystole,
    MeasurementName.RightAtriumSystolicMajorAxisA4C,
    MeasurementName.RightPulmonaryArteryDiameter,
    MeasurementName.RightVentricleBaseMinorAxisDiastoleA4C,
    MeasurementName.RightVentricleInternalDiastolicDimension,
    MeasurementName.RightVentricleInternalSystolicDimension,
    MeasurementName.RightVentricleMajorAxis,
    MeasurementName.RightVentricleMidMinorAxisDiastoleA4C,
    MeasurementName.RightVentricleOutflowTractDiameter,
    MeasurementName.TricuspidAnnularPlaneSystolicExcursion,
    MeasurementName.TricuspidAnnulusDiameter,
    MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius,
    MeasurementName.VentricularSeptalDefectDiameter,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the area measurement tool.
 */
export function getDrawableAreaMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomArea,
    MeasurementName.AorticValveAreaPlanimetry,
    MeasurementName.LeftAtriumAreaDiastolicA2C,
    MeasurementName.LeftAtriumAreaDiastolicA4C,
    MeasurementName.LeftAtriumAreaSystolicA2C,
    MeasurementName.LeftAtriumAreaSystolicA4C,
    MeasurementName.LeftVentricleDiastolicAreaA2C,
    MeasurementName.LeftVentricleDiastolicAreaA4C,
    MeasurementName.LeftVentricleOutflowTractArea,
    MeasurementName.LeftVentricleSystolicAreaA2C,
    MeasurementName.LeftVentricleSystolicAreaA4C,
    MeasurementName.MitralValveAreaByPlanimetry,
    MeasurementName.RightAtriumDiastolicArea,
    MeasurementName.RightAtriumSystolicArea,
    MeasurementName.RightVentricleEndDiastolicAreaA4C,
    MeasurementName.RightVentricleEndSystolicAreaA4C,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the volume measurement tool.
 */
export function getDrawableVolumeMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomVolume,
    MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA2C,
    MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA4C,
    MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA2C,
    MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA4C,
    MeasurementName.LeftVentricularCavityVolume,
    MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C,
    MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA3C,
    MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C,
    MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C,
    MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA3C,
    MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C,
    MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA2C,
    MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA4C,
    MeasurementName.RightAtriumEndSystolicVolumeUniplaneA2C,
    MeasurementName.RightAtriumEndSystolicVolumeUniplaneA4C,
    MeasurementName.RightVentricleEndDiastolicVolumeUniplaneA4C,
    MeasurementName.RightVentricleEndSystolicVolumeUniplaneA4C,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the uniplane ejection fraction
 * measurement tool.
 */
export function getDrawableUniplaneEjectionFractionMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomEjectionFraction,
    MeasurementName.LeftVentricleEjectionFractionUniplaneA2C,
    MeasurementName.LeftVentricleEjectionFractionUniplaneA4C,
    MeasurementName.RightVentricleEjectionFractionUniplaneA4C,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the VTI measurement tool.
 */
export function getDrawableVTIMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomVelocityTimeIntegral,
    MeasurementName.AorticRegurgitationVelocityTimeIntegral,
    MeasurementName.AorticValveVelocityTimeIntegral,
    MeasurementName.LeftVentricleOutflowTractVelocityTimeIntegral,
    MeasurementName.MitralRegurgitationVelocityTimeIntegral,
    MeasurementName.MitralValveVelocityTimeIntegral,
    MeasurementName.PulmonaryRegurgitationVelocityTimeIntegral,
    MeasurementName.PulmonaryValveVelocityTimeIntegral,
    MeasurementName.RightVentricleOutflowTractVelocityTimeIntegral,
    MeasurementName.TricuspidRegurgitationVelocityTimeIntegral,
    MeasurementName.TricuspidValveVelocityTimeIntegral,
  ];
}

export function getDrawableVelocityMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomVelocity,
    MeasurementName.AorticRegurgitationPeakVelocity,
    MeasurementName.AorticValvePeakVelocity,
    MeasurementName.AscendingAortaPeakVelocity,
    MeasurementName.LeftAtrialAppendageExitVelocity,
    MeasurementName.LeftPulmonaryArteryPeakVelocity,
    MeasurementName.LeftVentricleEarlyDiastolicTissuePeakVelocity,
    MeasurementName.LeftVentricleOutflowTractPeakVelocity,
    MeasurementName.MitralRegurgitationPeakVelocity,
    MeasurementName.MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity,
    MeasurementName.MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity,
    MeasurementName.MitralValveAPrimeVelocityLateral,
    MeasurementName.MitralValveAPrimeVelocitySeptal,
    MeasurementName.MitralValveAWavePeakVelocity,
    MeasurementName.MitralValveEWavePeakVelocity,
    MeasurementName.MitralValvePeakVelocity,
    MeasurementName.PulmonaryRegurgitationPeakVelocity,
    MeasurementName.PulmonaryValvePeakVelocity,
    MeasurementName.PulmonaryVeinAtrialContractionReversalPeakVelocity,
    MeasurementName.PulmonaryVeinDiastolicPeakVelocity,
    MeasurementName.PulmonaryVeinSystolicPeakVelocity,
    MeasurementName.RightPulmonaryArteryPeakVelocity,
    MeasurementName.RightVentricleFreeWallSPrime,
    MeasurementName.RightVentricleOutflowTractPeakVelocity,
    MeasurementName.TricuspidRegurgitationPeakVelocity,
    MeasurementName.TricuspidValveAWavePeakVelocity,
    MeasurementName.TricuspidValveEWavePeakVelocity,
    MeasurementName.TricuspidValvePeakVelocity,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the doppler slope measurement tool.
 */
export function getDrawableDopplerSlopeMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomDopplerSlope,
    MeasurementName.AorticRegurgitationDecelerationSlope,
    MeasurementName.AorticValveAccelerationSlope,
    MeasurementName.AorticValveDecelerationSlope,
    MeasurementName.MitralValveDecelerationSlope,
    MeasurementName.PulmonaryRegurgitationAccelerationSlope,
    MeasurementName.PulmonaryRegurgitationDecelerationSlope,
    MeasurementName.PulmonaryValveAccelerationSlope,
    MeasurementName.PulmonaryValveDecelerationSlope,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the M-mode slope measurement tool.
 */
export function getDrawableMModeSlopeMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomMModeSlope,
    MeasurementName.LeftVentricleInflowVelocityPropagationSlope,
    MeasurementName.MitralValveDESlopeMMode,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the M-mode displacement measurement
 * tool.
 */
export function getDrawableDisplacementMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomDisplacement,
    MeasurementName.AorticArchDiameter,
    MeasurementName.AorticRootDiameter,
    MeasurementName.AorticRootDiameterMMode,
    MeasurementName.AorticValveCuspSeparationMMode,
    MeasurementName.InferiorVenaCavaDiameter,
    MeasurementName.InterventricularSeptumDiastolicThickness,
    MeasurementName.LeftAtriumDiameter,
    MeasurementName.LeftVentricleInternalEndDiastolicDimension,
    MeasurementName.LeftVentricleInternalSystolicDimension,
    MeasurementName.LeftVentriclePosteriorWallDiastolicThickness,
    MeasurementName.MitralAnnularPlaneSystolicExcursion,
    MeasurementName.MitralValveDEExcursionMMode,
    MeasurementName.MitralValveEPointSeptalSeparation,
    MeasurementName.RightVentricleInternalDiastolicDimension,
    MeasurementName.RightVentricleInternalSystolicDimension,
    MeasurementName.RightVentricleOutflowTractDiameter,
    MeasurementName.TricuspidAnnularPlaneSystolicExcursion,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the time measurement tool on
 * spectral doppler clips.
 */
export function getDrawableSpectralTimeMeasurements(): MeasurementName[] {
  return [
    MeasurementName.CustomTime,
    MeasurementName.AorticRegurgitationAccelerationTime,
    MeasurementName.AorticRegurgitationDecelerationTime,
    MeasurementName.AorticRegurgitationPressureHalfTime,
    MeasurementName.AorticValveAccelerationTime,
    MeasurementName.AorticValveDecelerationTime,
    MeasurementName.LeftVentricleChangeInTime,
    MeasurementName.LeftVentricleOutflowTractAccelerationTime,
    MeasurementName.LeftVentricularIsovolumetricContractionTime,
    MeasurementName.LeftVentricularIsovolumetricRelaxationTime,
    MeasurementName.MitralRegurgitationAccelerationTime,
    MeasurementName.MitralValveAccelerationTime,
    MeasurementName.MitralValveAWaveDuration,
    MeasurementName.MitralValveDecelerationTime,
    MeasurementName.MitralValveEWaveDecelerationTime,
    MeasurementName.MitralValvePressureHalfTime,
    MeasurementName.PulmonaryRegurgitationAccelerationTime,
    MeasurementName.PulmonaryRegurgitationDecelerationTime,
    MeasurementName.PulmonaryRegurgitationPressureHalfTime,
    MeasurementName.PulmonaryValveAccelerationTime,
    MeasurementName.PulmonaryValveDecelerationTime,
    MeasurementName.PulmonaryValvePressureHalfTime,
    MeasurementName.PulmonaryVeinAWaveDuration,
    MeasurementName.RightVentricleChangeInTime,
    MeasurementName.RightVentricleOutflowTractAccelerationTime,
    MeasurementName.TricuspidRegurgitationAccelerationTime,
    MeasurementName.TricuspidValveAccelerationTime,
  ];
}

/**
 * Returns the names of the measurements that can be drawn with the time measurement tool on
 * M-mode doppler clips.
 */
export function getDrawableMModeTimeMeasurements(): MeasurementName[] {
  return [MeasurementName.CustomTime, MeasurementName.SeptalPosteriorWallMotionDelay];
}

/**
 * The set of all measurements that can be taken with a measurement tool in the application.
 */
export const allDrawableMeasurements = new Set([
  ...getDrawableAngleMeasurements(),
  ...getDrawableDistanceMeasurements(),
  ...getDrawableAreaMeasurements(),
  ...getDrawableVolumeMeasurements(),
  ...getDrawableUniplaneEjectionFractionMeasurements(),
  ...getDrawableVTIMeasurements(),
  ...getDrawableDopplerSlopeMeasurements(),
  ...getDrawableMModeSlopeMeasurements(),
  ...getDrawableDisplacementMeasurements(),
  ...getDrawableSpectralTimeMeasurements(),
  ...getDrawableMModeTimeMeasurements(),
  ...getDrawableVelocityMeasurements(),
]);
