import { pEventIterator } from "p-event";
import { addNotification } from "../utils/notifications";
import type {
  AudioStreamWorkletProcessorMessage,
  AudioStreamWorkletProcessorOptions,
} from "./audio-stream-worklet-processor";
import audioStreamWorkletProcessorUrl from "./audio-stream-worklet-processor?worker&url";

/**
 * Creates a new asynchronous audio stream from the user's microphone. A maximum duration of the
 * audio stream in seconds should be specified.
 *
 * The returned object contains the audio sample rate in hertz, an async iterable that will yield
 * with new audio samples, and a destroy function that should be called when the stream is no longer
 * needed.
 */
export async function createAudioStream(maxDurationInSeconds: number): Promise<{
  sampleRate: number;
  workletMessageIterator: AsyncIterableIterator<MessageEvent<AudioStreamWorkletProcessorMessage>>;
  destroy: () => void;
}> {
  const { audioContext, audioSource } = await useSharedAudioContext();

  // AWS recommends a chunk duration of 50-200ms, we'll go with 50ms
  const sampleEmitFrequency = 1000 / 50;

  // Create an audio worklet node running our processor code
  const processorOptions: AudioStreamWorkletProcessorOptions = {
    sampleBufferSize: audioContext.sampleRate / sampleEmitFrequency,
  };
  let workletNode: AudioWorkletNode | null = new AudioWorkletNode(
    audioContext,
    "AudioStreamWorkletProcessor",
    { processorOptions }
  );

  // Report any errors coming from the worklet
  workletNode.port.onmessageerror = (error) => {
    addNotification({ type: "error", message: "Error streaming audio" });
    console.error(error);
  };

  // Create an async iterable for all of the messages coming from the worklet. These messages will
  // contain PCM audio data.
  const workletMessageIterator = pEventIterator<
    "message",
    MessageEvent<AudioStreamWorkletProcessorMessage>
  >(
    workletNode.port,
    "message",

    // Enforce maximum length
    { limit: sampleEmitFrequency * maxDurationInSeconds }
  );

  // Safari and Firefox require a value for `onmessage` in order to actually run the worklet.
  // Presumably when this isn't set they assume that the output from the worklet isn't being used
  // and so don't run it, even though in this case it *is* being used by the pEventIterator() above,
  // but they fail to notice this (likely because the pEventIterator() call uses addEventListener).
  //
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  workletNode.port.onmessage = () => {};

  // Connect the worklet to an empty destination node
  const destination = audioContext.createMediaStreamDestination();
  audioSource.connect(workletNode).connect(destination);

  // Create destroy function that cleans everything up
  function destroy(): void {
    if (workletNode === null) {
      return;
    }

    workletNode.disconnect(destination);
    audioSource.disconnect(workletNode);
    workletNode.port.onmessage = null;
    workletNode = null;
  }

  return { sampleRate: audioContext.sampleRate, workletMessageIterator, destroy };
}

//
// The audio context and audio source are shared by all created audio streams
//

let sharedAudioContext: AudioContext | null = null;
let sharedAudioSource: MediaStreamAudioSourceNode | null = null;

async function useSharedAudioContext(): Promise<{
  audioContext: AudioContext;
  audioSource: MediaStreamAudioSourceNode;
}> {
  if (sharedAudioContext !== null && sharedAudioSource !== null) {
    return { audioContext: sharedAudioContext, audioSource: sharedAudioSource };
  }

  // Prefer a 16 kHz audio stream where supported. This is what's recommended by AWS as the best
  // speed/quality tradeoff. Firefox doesn't currently seem to support this though, so if it fails
  // then we fall back to the audio context's default sample rate.
  try {
    sharedAudioContext = new AudioContext({ sampleRate: 16000 });
    sharedAudioSource = await setupAudioSource(sharedAudioContext);
  } catch {
    sharedAudioContext = new AudioContext();
    sharedAudioSource = await setupAudioSource(sharedAudioContext);
  }

  return { audioContext: sharedAudioContext, audioSource: sharedAudioSource };
}

async function setupAudioSource(audioContext: AudioContext): Promise<MediaStreamAudioSourceNode> {
  try {
    await audioContext.audioWorklet.addModule(audioStreamWorkletProcessorUrl);
  } catch (error) {
    addNotification({ type: "error", message: "Failed registering audio worklet module" });
  }

  // Get microphone audio stream
  const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
  const audioSource = audioContext.createMediaStreamSource(stream);

  return audioSource;
}
