<template>
  <BaseClipViewer
    :canvas-rect="canvasRect"
    :study="study"
    :grid-item="gridItem"
    :canvas-height="canvasElement?.height"
    :canvas-width="canvasElement?.width"
    :clip-aspect-ratio="gridItem.clip ? gridItem.clip.width! / gridItem.clip.height! : 1"
    @update:canvas-rect="updateCanvasRect"
    @set-canvas-dimensions="setCanvasDimensions"
    @step-frame="onStepFrame"
    @scrub="onScrub"
  >
    <template #canvases>
      <canvas
        ref="webglCanvasElement"
        data-testid="webgl-canvas"
        style="z-index: 1"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
        }"
      />

      <canvas
        ref="canvasElement"
        data-testid="canvas"
        style="z-index: 2"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
        }"
        @mousedown="onCanvasMouseDown"
        @mousemove="onCanvasMouseMove"
      />
    </template>

    <template #imageControls>
      <span>
        <strong>WL</strong>:
        <input
          :value="gridItem.windowLevel.value"
          class="windowing-input"
          data-testid="window-level-input"
          type="number"
          @input="updateWindowLevel"
        />
      </span>
      <span>
        <strong>WW</strong>:
        <input
          :value="gridItem.windowWidth.value"
          class="windowing-input"
          data-testid="window-width-input"
          type="number"
          @update:value="updateWindowWidth"
        />
      </span>

      <DropdownWidget
        class="slice-direction-dropdown"
        data-testid="slice-direction-dropdown"
        :model-value="gridItem.sliceDirection.value"
        :items="[
          { value: CTSliceDirection.Axial, text: 'Axial' },
          { value: CTSliceDirection.Coronal, text: 'Coronal' },
          { value: CTSliceDirection.Sagittal, text: 'Sagittal' },
        ]"
        @update:model-value="updateSliceDirection"
      />
    </template>

    <template #overlays>
      <div class="ct-mode-wrapper">
        <div
          class="ct-mode-btn selected"
          :class="{
            processing: gridItem.series.nrrdProcessState === NRRDProcessState.Processing,
          }"
          @click="emits('leave-ct-mode')"
        >
          CT Mode
        </div>
      </div>
    </template>
  </BaseClipViewer>
</template>

<script setup lang="ts">
import { useEventListener } from "@vueuse/core";
import { WebGLRenderer } from "three";
import { onMounted, ref, watch } from "vue";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import DropdownWidget from "../../components/DropdownWidget.vue";
import { Study } from "../../utils/study-data";
import { CTSliceDirection } from "./../ct/ct-model";
import BaseClipViewer from "./BaseClipViewer.vue";
import { CanvasContainerRect } from "./clip-renderer-2d";
import { CTClipsGridItem } from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: CTClipsGridItem;
}

interface Emits {
  (event: "scrub", newSliceNumber: number): void;
  (event: "leave-ct-mode"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const canvasElement = ref<HTMLCanvasElement | null>(null);
const webglCanvasElement = ref<HTMLCanvasElement | null>(null);

let webglRenderer: WebGLRenderer | null = null;

const canvasRect = ref({ top: 0, left: 0, width: 0, height: 0 });

function setupRenderer(): void {
  if (webglCanvasElement.value) {
    if (webglRenderer === null) {
      webglRenderer = new WebGLRenderer({ canvas: webglCanvasElement.value });
    }

    props.gridItem.provideRenderer(webglRenderer, canvasRect.value);
  }
}

function updateCanvasRect(rect: CanvasContainerRect): void {
  // Shift the canvas off the top by 32px when in CT viewers so the controls don't overlap any clinical
  // content. The same distance is taken off the width to preserve the canvas aspect ratio.
  canvasRect.value = {
    top: rect.top + 32,
    left: rect.left + 16,
    width: rect.width - 32,
    height: rect.height - 32,
  };
}

function setCanvasDimensions(dims: { width: number; height: number }): void {
  if (canvasElement.value === null) {
    return;
  }

  canvasElement.value.width = dims.width;
  canvasElement.value.height = dims.height;
}

function updateWindowLevel(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  const gridItem = props.gridItem;
  const newWindowLevel = parseInt(event.target.value);
  gridItem.windowLevel.value = !isNaN(newWindowLevel) ? newWindowLevel : 0;
}

function updateWindowWidth(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  const gridItem = props.gridItem;
  const newWindowWidth = parseInt(event.target.value);
  gridItem.windowWidth.value = !isNaN(newWindowWidth) ? newWindowWidth : 0;
}

function updateSliceDirection(newSliceDirection: string): void {
  const gridItem = props.gridItem;
  gridItem.sliceDirection.value = newSliceDirection as CTSliceDirection;
}

function onStepFrame(delta: number): void {
  const gridItem = props.gridItem;
  const newSliceNumber = (gridItem.sliceNumber.value + delta) % gridItem.maxSliceNumber.value;

  gridItem.sliceNumber.value =
    newSliceNumber < 0 ? newSliceNumber + gridItem.maxSliceNumber.value : newSliceNumber;
}

function onScrub(xFraction: number): void {
  const gridItem = props.gridItem;
  gridItem.sliceNumber.value = Math.floor(xFraction * gridItem.maxSliceNumber.value);
}

let canvasMouseDownPosition = [-1, -1];
let canvasMouseDownInitialWindowLevel = -1;
let canvasMouseDownInitialWindowWidth = -1;

function onCanvasMouseDown(event: MouseEvent): void {
  canvasMouseDownPosition = [event.clientX, event.clientY];
  canvasMouseDownInitialWindowLevel = props.gridItem.windowLevel.value;
  canvasMouseDownInitialWindowWidth = props.gridItem.windowWidth.value;
}

function onCanvasMouseMove(event: MouseEvent): void {
  if (event.buttons === 1 && canvasMouseDownInitialWindowLevel !== -1) {
    const model = props.gridItem;

    model.windowLevel.value =
      canvasMouseDownInitialWindowLevel + (canvasMouseDownPosition[1] - event.clientY);
    model.windowLevel.value = Math.min(model.windowLevel.value, 4096);
    model.windowLevel.value = Math.max(model.windowLevel.value, -1024);

    model.windowWidth.value =
      canvasMouseDownInitialWindowWidth - (canvasMouseDownPosition[0] - event.clientX);
    model.windowWidth.value = Math.min(model.windowWidth.value, 2048);
    model.windowWidth.value = Math.max(model.windowWidth.value, 0);
  }
}

// Stop any windowing adjustment when a mouseup event occurs
useEventListener(document, "mouseup", () => (canvasMouseDownInitialWindowLevel = -1));

onMounted(() => {
  setupRenderer();
});

watch(
  () => props.gridItem,
  () => {
    setupRenderer();
  }
);
</script>

<style scoped lang="scss">
canvas {
  position: absolute;
}

.ct-mode-wrapper {
  grid-area: 1 / 1;
}

.ct-mode-btn {
  display: flex;
  z-index: 3;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--accent-color-1);
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  height: 12px;
  width: 60px;
  padding: 4px 8px;
  margin: 8px 8px 8px auto;

  &.processing {
    width: 80px;
  }

  &:hover,
  &.selected {
    color: var(--accent-color-2);
    background-color: var(--bg-color-3);
  }

  &.selected {
    border: 1px solid var(--accent-color-2);
  }
}

.windowing-input {
  height: 20px;
  width: 30px;
  padding: 0px 4px;
  margin-left: 2px;
}

.slice-direction-dropdown {
  height: 20px;

  :deep(select) {
    height: 12px;
  }
}
</style>
