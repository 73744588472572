<template>
  <Tooltip content="Select background" placement="left">
    <Popper class="study-list-background-popper">
      <FontAwesomeIcon icon="image" size="lg" class="background-select-icon" />

      <template #content>
        <div class="study-list-backgrounds">
          <div
            v-for="background in allBackgrounds"
            :key="background.id"
            class="study-list-background"
            @click="emits('update:modelValue', background.id)"
          >
            <div v-if="background.id === 'solid'" class="background-solid-color" />
            <img v-else :src="`${BASE_URL}backgrounds/${background.id}.thumbnail.png`" />
            {{ background.name }}
          </div>
        </div>
      </template>
    </Popper>
  </Tooltip>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Tooltip from "../components/Tooltip.vue";
import { BASE_URL } from "../environment";

interface Props {
  modelValue: string;
}

interface Emits {
  (name: "update:modelValue", newValue: string): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();

const allBackgrounds = [
  { id: "solid", name: "Solid" },
  { id: "forest-0", name: "Forest" },
  { id: "water-0", name: "Water" },
  { id: "stairs-0", name: "Stairs" },
];
</script>

<style scoped lang="scss">
.background-select-icon {
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.study-list-backgrounds {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
}

.study-list-background {
  padding: 4px;
  display: grid;
  gap: 8px;
  text-align: center;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  cursor: pointer;
  font-weight: bold;
  border-radius: var(--border-radius);

  img {
    width: 200px;
    height: 120px;
    object-fit: cover;
  }

  .background-solid-color {
    width: 200px;
    height: 120px;
    background: var(--bg-color-3);
  }

  &:hover {
    background-color: var(--bg-color-4);
    color: var(--text-color-2);
  }
}
</style>
