<template>
  <div class="settings-title">Measurement Tools</div>

  <div class="settings-group-title">Area Measurements</div>

  <div class="field">
    <div style="display: flex; gap: 16px; align-items: center">
      <b>Automatically Resample Area Measurements</b>
      <ToggleSwitch
        v-model="currentTenant.isAreaMeasurementAutomaticResamplingEnabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether area measurements should be automatically resampled to 21 evenly spaced points when
      they are created.
    </p>
  </div>

  <div class="settings-group-title">Volume Measurements</div>

  <div class="field">
    <div style="display: flex; gap: 16px; align-items: center">
      <b>Automatically Resample Volume Measurements</b>
      <ToggleSwitch
        v-model="currentTenant.isVolumeMeasurementAutomaticResamplingEnabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether volume measurements should be automatically resampled to 21 points when they are
      created. Resampling volume measurements ensures there are exactly 10 evenly spaced points on
      each side of the axis point.
    </p>
  </div>

  <div class="settings-group-title">VTI Measurements</div>

  <div class="field">
    <div style="display: flex; gap: 16px; align-items: center">
      <b>Automatically Resample VTI Measurements</b>
      <ToggleSwitch
        v-model="currentTenant.isVTIMeasurementAutomaticResamplingEnabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether VTI measurements should be automatically resampled to 21 points when they are created.
    </p>
  </div>
</template>

<script setup lang="ts">
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import axios from "axios";
import { currentTenant } from "../../auth/current-session";
import { addNotification } from "../../utils/notifications";

async function updateCurrentTenant(): Promise<void> {
  try {
    await axios.patch(`/api/tenants/current`, {
      isAreaMeasurementAutomaticResamplingEnabled:
        currentTenant.isAreaMeasurementAutomaticResamplingEnabled,
      isVolumeMeasurementAutomaticResamplingEnabled:
        currentTenant.isVolumeMeasurementAutomaticResamplingEnabled,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating advanced settings" });
    return;
  }

  addNotification({ type: "info", message: "Updated advanced settings" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}
</style>
