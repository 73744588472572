import { watch } from "vue";
import { currentThemeIndex } from "./current-theme";
import type { ThemeProperty } from "./theme";
import { charcoalTheme } from "./theme-charcoal";

/** The list of available themes. */
export const themes = [charcoalTheme];

export function getCurrentThemeName(): string {
  return themes[currentThemeIndex.value].name;
}

/**
 * Returns a value from the current theme. This value is reactive and so will re-evaluate if the
 * underlying theme is changed.
 */
export function getThemeValue(property: ThemeProperty): string {
  return themes[currentThemeIndex.value].values[property];
}

/**
 * Returns a value from the current theme with the inclusion of the specified alpha value. This is
 * only relevant to color properties. This value is reactive and so will re-evaluate if the
 * underlying theme is changed.
 */
export function getThemeValueWithAlpha(property: ThemeProperty, alpha: number): string {
  return `${getThemeValue(property)}${Math.round(alpha * 255).toString(16)}`;
}

/**
 * Sets the CSS variables for the current theme onto the passed HTML element. This is used both
 * for controlling the theme on the main application, as well as applying CSS variables to the
 * report.
 */
export function setThemeCssVariablesOnElement(element: HTMLElement): void {
  const values = themes[currentThemeIndex.value].values;
  for (const [key, value] of Object.entries(values)) {
    element.style.setProperty(`--${key}`, value);
  }
}

// Update the theme CSS variables on <body> when the theme changes
watch(
  currentThemeIndex,
  () => setThemeCssVariablesOnElement(document.getElementsByTagName("body")[0]),
  { deep: true, immediate: typeof window === "object" }
);
