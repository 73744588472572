<template>
  <div
    ref="rootElement"
    class="clip-layout-selector"
    data-testid="clip-layout-selector"
    @mousemove="onMouseMove"
    @mouseleave="resetHighlightSize"
    @click="emits('update:modelValue', { width: highlightWidth, height: highlightHeight })"
  >
    <div
      ref="highlightElement"
      class="highlight"
      :style="{
        width: `${((highlightWidth / 3) * 100).toFixed(2)}%`,
        height: `${((highlightHeight / 3) * 100).toFixed(2)}%`,
      }"
    />

    <div class="vertical-bar" style="left: 33.33%" />
    <div class="vertical-bar" style="left: 66.66%" />
    <div class="horizontal-bar" style="top: 33.33%" />
    <div class="horizontal-bar" style="top: 66.66%" />
  </div>
</template>

<script setup lang="ts">
import { clamp } from "lodash";
import { ref, watch } from "vue";

interface Props {
  modelValue: { width: number; height: number };
}

interface Emits {
  (event: "update:modelValue", newValue: { width: number; height: number }): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const rootElement = ref<HTMLElement>();
const highlightElement = ref<HTMLElement>();

const highlightWidth = ref(0);
const highlightHeight = ref(0);

function onMouseMove(event: MouseEvent): void {
  const x = event.clientX - rootElement.value!.getBoundingClientRect().left;
  const y = event.clientY - highlightElement.value!.getBoundingClientRect().top;

  highlightWidth.value = clamp(Math.floor(x / (rootElement.value!.clientWidth / 3)), 0, 2) + 1;
  highlightHeight.value = clamp(Math.floor(y / (rootElement.value!.clientHeight / 3)), 0, 2) + 1;
}

function resetHighlightSize(): void {
  highlightWidth.value = props.modelValue.width;
  highlightHeight.value = props.modelValue.height;
}

watch(() => [props.modelValue], resetHighlightSize, { immediate: true });
</script>

<style scoped lang="scss">
.clip-layout-selector {
  position: relative;
  cursor: pointer;
  background: var(--bg-color-2);
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  overflow: hidden;
}

.highlight {
  position: absolute;
  background: var(--bg-color-4);
  transition:
    width 100ms ease,
    height 100ms ease;
}

.vertical-bar,
.horizontal-bar {
  position: absolute;
  background-color: var(--accent-color-1);
}

.vertical-bar {
  width: 1px;
  height: 100%;
  margin-left: -0.5px;
}

.horizontal-bar {
  width: 100%;
  height: 1px;
  margin-top: -0.5px;
}
</style>
