<template>
  <Tooltip content="Adjust video playback speed" :visible="!isSliderVisible">
    <div
      class="playback-speed-slider"
      :class="{ closed: !isSliderVisible }"
      @click="isSliderVisible = true"
    >
      <div class="speedo-container" @click.stop="isSliderVisible = !isSliderVisible">
        <div class="speedo">
          <FontAwesomeIcon icon="circle" size="lg" />
          <div class="circle" />
          <div class="line" />
        </div>
      </div>

      <Slider
        v-if="isSliderVisible"
        :model-value="modelValue"
        :min="0.1"
        :max="2"
        @update:model-value="onSliderUpdate"
      />

      <div class="readout">{{ modelValue.toFixed(1) }}x</div>
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import Slider from "../components/Slider.vue";
import Tooltip from "../components/Tooltip.vue";

interface Props {
  modelValue: number;
}

interface Emits {
  (event: "update:modelValue", newValue: number): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();

const isSliderVisible = ref(false);

function onSliderUpdate(newValue: number): void {
  if (newValue >= 0.9 && newValue <= 1.1) {
    emits("update:modelValue", 1);
  } else {
    emits("update:modelValue", newValue);
  }
}
</script>

<style scoped lang="scss">
.playback-speed-slider {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  background-color: var(--bg-color-3);
  height: 32px;
  padding-right: 8px;
  transition: background-color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
    background-color: var(--bg-color-4);

    .line,
    .circle {
      background-color: var(--bg-color-4);
    }

    .scrubber {
      background-color: var(--accent-color-2);
    }
  }

  &.closed {
    cursor: pointer;
  }
}

.speedo-container {
  align-self: stretch;
  padding: 0 8px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.speedo {
  position: relative;

  svg {
    transition: color 100ms ease;
  }

  .circle,
  .line {
    position: absolute;
    left: 50%;
    background-color: var(--bg-color-3);
  }

  .circle {
    width: 5px;
    height: 5px;
    margin-left: -2.5px;
    top: calc(55%);
    border-radius: 50%;
  }

  .line {
    width: 2px;
    height: 10px;
    margin-left: -1px;
    bottom: calc(45% - 2.5px);
    border-radius: 2px;

    transform: v-bind("`rotateZ(${(modelValue - 1) * 0.5}rad)`");
    transform-origin: bottom center;
    transition: transform 100ms ease;
  }
}

.readout {
  font-weight: bold;
  transition: color 100ms ease;
}
</style>
