<template>
  <div class="signature-panel">
    <canvas ref="canvas" data-testid="signature-canvas" />

    <button
      class="clear-signature"
      data-testid="clear-signature"
      :disabled="currentUser.signatureDataUri === ''"
      @click="clearSignature"
    >
      <FontAwesomeIcon v-if="onboarding" icon="undo" @click="clearSignature" />
      <template v-else> Clear </template>
    </button>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import SignaturePad from "signature_pad";
import { onMounted, onUnmounted, ref } from "vue";
import { currentUser } from "../auth/current-session";
import { addNotification } from "../utils/notifications";

interface Props {
  onboarding?: boolean;
}

const props = withDefaults(defineProps<Props>(), { onboarding: false });

const canvas = ref<HTMLCanvasElement>();

let signaturePad: SignaturePad | undefined = undefined;

onMounted(async () => {
  signaturePad = new SignaturePad(canvas.value!);
  signaturePad.on();

  // Save signature when a stroke ends
  signaturePad.addEventListener("endStroke", updateUserSignature);

  // Set initial state of signature pad
  if (currentUser.signatureDataUri !== "") {
    await signaturePad.fromDataURL(currentUser.signatureDataUri, {
      width: canvas.value!.width,
      height: canvas.value!.height,
    });
  }
});

onUnmounted(() => {
  signaturePad?.off();
  signaturePad = undefined;
});

function updateUserSignature(): void {
  const newSignatureDataURI = signaturePad!.isEmpty() ? "" : signaturePad?.toDataURL() ?? "";

  currentUser.signatureDataUri = newSignatureDataURI;

  if (!props.onboarding) {
    void saveUserSignatureDebounced();
  }
}

function clearSignature(): void {
  signaturePad?.clear();
  updateUserSignature();
}

async function saveUserSignature(): Promise<void> {
  try {
    await axios.patch("/api/user/current", { signatureDataUri: currentUser.signatureDataUri });
  } catch {
    addNotification({ type: "error", message: "Failed saving signature" });
    return;
  }

  addNotification({ type: "info", message: "Saved signature" });
}

const saveUserSignatureDebounced = useDebounceFn(() => {
  void saveUserSignature();
}, 1000);
</script>

<style scoped lang="scss">
.signature-panel {
  display: grid;
  gap: 8px;
  width: 320px;
}

canvas {
  background: #f8f8f8;
  width: 320px;
  height: 140px;
  border-radius: var(--border-radius);
}

.clear-signature {
  justify-self: end;
}
</style>
