<template>
  <div class="back-btn" @click="navigateBack">
    <FontAwesomeIcon icon="arrow-left" />
    Back to DICOM Endpoints
  </div>

  <div class="settings-title">
    DICOM Endpoint: {{ dicomEndpoint.name }}

    <button style="margin-left: auto; font-size: 13px" type="button" @click="deleteDicomEndpoint">
      Delete DICOM Endpoint
    </button>
  </div>

  <div class="field">
    <b>Name</b>
    <input
      v-model="dicomEndpoint.name"
      type="text"
      @update:model-value="updateDicomEndpointDebounced"
    />
  </div>

  <div class="field">
    <b>SCP AE Title</b>
    <input
      v-model="dicomEndpoint.scpAeTitle"
      type="text"
      maxlength="16"
      @update:model-value="updateDicomEndpointDebounced"
    />
  </div>

  <div class="field">
    <b>SCU AE Title</b>
    <input
      v-model="dicomEndpoint.scuAeTitle"
      type="text"
      maxlength="16"
      @update:model-value="updateDicomEndpointDebounced"
    />
    <p>
      This sets the AE title used to identify the SCU on outgoing DIMSE requests. If it is left
      blank then the tenant's default AE title will be used.
    </p>
  </div>

  <div class="field">
    <b>Address</b>
    <input
      v-model="dicomEndpoint.address"
      type="text"
      @update:model-value="updateDicomEndpointDebounced"
    />
  </div>

  <div class="field">
    <b>Port</b>
    <input
      v-model="dicomEndpoint.port"
      type="number"
      @update:model-value="updateDicomEndpointDebounced"
    />
  </div>

  <button class="accented" style="width: min-content" @click="testDicomEndpoint">
    Test DICOM Endpoint
  </button>

  <div>Last used: {{ formatRelativeTime(dicomEndpoint.lastUsedAt) }}</div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import { DicomEndpointGetOneResponseDto } from "../../../../../backend/src/tenants/global-admin/dto/dicom-endpoint-get-one.dto";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import { formatRelativeTime } from "../../../utils/date-time-utils";
import { addNotification } from "../../../utils/notifications";
import { getRequestErrorMessage } from "../../../utils/request-helpers";

interface Props {
  id: string;
}

const props = defineProps<Props>();
const activityText = ref("");
const dicomEndpoint = ref<DicomEndpointGetOneResponseDto>({
  id: "",
  tenantId: "",
  name: "",
  address: "",
  port: 0,
  scpAeTitle: "",
  scuAeTitle: "",
  lastUsedAt: null,
});

onMounted(async () => {
  activityText.value = "Loading";

  await loadEndpointData();
});

async function loadEndpointData(): Promise<void> {
  let response: AxiosResponse<DicomEndpointGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<DicomEndpointGetOneResponseDto>(
      `/api/global/dicom-endpoints/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading DICOM endpoint" });
    return;
  } finally {
    activityText.value = "";
  }

  dicomEndpoint.value = response.data;
}

async function deleteDicomEndpoint(): Promise<void> {
  if (
    !confirm(`Are you sure you want to delete the DICOM endpoint "${dicomEndpoint.value.name}"?`)
  ) {
    return;
  }

  activityText.value = "Deleting DICOM endpoint";

  try {
    await axios.delete(`/api/global/dicom-endpoints/${props.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting DICOM endpoint" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Deleted DICOM endpoint" });

  await navigateBack();
}

const updateDicomEndpointDebounced = useDebounceFn(() => {
  void updateDicomEndpoint();
}, 1000);

async function updateDicomEndpoint(): Promise<void> {
  try {
    await axios.patch(`/api/global/dicom-endpoints/${props.id}`, dicomEndpoint.value);
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Error updating DICOM endpoint",
    });
    return;
  }

  addNotification({ type: "info", message: "Updated DICOM endpoint" });
}

async function testDicomEndpoint(): Promise<void> {
  activityText.value = "Testing endpoint";

  try {
    const response = await axios.post<number>(`/api/global/dicom-endpoints/${props.id}/test`);

    addNotification({
      type: "info",
      message: `DICOM endpoint responded to C-ECHO in ${response.data.toFixed()} ms`,
    });

    await loadEndpointData();
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Test of DICOM endpoint failed",
    });
  } finally {
    activityText.value = "";
  }
}

async function navigateBack(): Promise<void> {
  await router.push({ name: "settings-dicom-endpoints" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 300px;
}

p {
  margin: 0;
}
</style>
