import { ref } from "vue";

export enum UploadJobState {
  Pending = "pending",
  InProgress = "inProgress",
  Completed = "completed",
  Error = "error",
  Cancelled = "cancelled",
  Info = "info",
}

export interface UploadJob {
  id: string;
  title: string;
  progress?: number;
  state: UploadJobState;
  message?: string;
  isZip?: boolean;
  abortController?: AbortController;
}

export const uploadJobs = ref<UploadJob[]>([]);

export function getActiveUploadJobCount(): number {
  return uploadJobs.value.filter(
    (uploadJob) =>
      (uploadJob.state === UploadJobState.Pending ||
        uploadJob.state === UploadJobState.InProgress) &&
      !(uploadJob.isZip === true)
  ).length;
}

export async function cancelInProgressUploadJobs(): Promise<void> {
  for (const uploadJob of uploadJobs.value) {
    uploadJob.abortController?.abort();
    uploadJob.state = UploadJobState.Cancelled;
  }

  // Wait for upload jobs to no longer be in progress
  while (getActiveUploadJobCount() !== 0) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
}
