<template>
  <div class="calculation-grid">
    <template v-for="[variableIdx, variable] in calculation.variables.entries()" :key="variable.id">
      <span style="padding-right: 20px">
        {{ getVariableLabel(variable) }}
      </span>

      <input
        :id="variable.id"
        v-model="inputValues[variable.id]"
        type="number"
        :data-testid="`try-it-out-input-${variableIdx}`"
      />

      <span>{{ variable.unit !== undefined ? getUnitDisplayText(variable.unit) : "" }}</span>
    </template>

    <div />
    <div class="result-divider" />

    <span>
      {{
        getMeasurementDisplayName(
          calculation.outputMeasurementName ?? MeasurementName.CustomValue,
          "unindexed"
        )
      }}
    </span>

    <span style="text-align: right; grid-column: 2 / span 2" data-testid="calculation-result">
      {{ calculationResult?.toFixed(2) ?? "—" }}
      {{
        calculation.outputMeasurementUnit !== null
          ? getUnitDisplayText(calculation.outputMeasurementUnit)
          : ""
      }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { MeasurementCalculationScope } from "../../../../backend/src/measurements/measurement-calculation-evaluation";
import { getVariableLabel } from "../../../../backend/src/measurements/measurement-calculation-variable";
import {
  getMeasurementDisplayName,
  getUnitDisplayText,
} from "../../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import {
  MeasurementCalculation,
  evaluateMeasurementCalculation,
} from "../../measurements/calculations/measurement-calculations";

interface Props {
  calculation: MeasurementCalculation;
}

const props = defineProps<Props>();

const inputValues = reactive<Record<string, string>>({});

const calculationResult = computed(() => {
  const scope: Record<string, number | undefined> = {};

  // Build the Math.js scope in which to evaluate the calculation
  for (const variable of props.calculation.variables) {
    scope[variable.variableName] = parseFloat(inputValues[variable.id]);
  }

  return evaluateMeasurementCalculation(
    props.calculation.formula,
    scope as MeasurementCalculationScope
  );
});
</script>

<style scoped lang="scss">
.calculation-grid {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  gap: 4px 6px;
  align-items: center;

  input {
    width: 80px;
    height: 24px;
    padding: 0 4px;
    text-align: right;
  }
}

.result-divider {
  grid-column: 2 / span 2;
  margin: 8px 0;
  height: 1px;
  background-color: var(--input-focus-border-color);
}
</style>
