<template>
  <Modal
    title="Download Study DICOMs"
    :activity-text="activityText"
    @header-button-click="closeModal"
  >
    <div class="dicom-download-modal">
      <template v-if="isDownloadComplete">
        <div class="message-container">
          <FontAwesomeIcon icon="check" size="lg" class="download-ready-icon" />
          Download complete. Total size: {{ prettyBytes(parseInt(study.dicomSize)) }}.
        </div>
        <button class="accented" data-testid="save-dicom-btn" @click="saveDicomZipFile">
          Save ZIP file
        </button>
      </template>

      <template v-else>
        <div class="message-container">
          <LoadingIndicator />
          Downloading {{ prettyBytes(parseInt(study.dicomSize)) }} …
        </div>

        <div class="download-progress">
          <div
            class="download-progress-bar"
            :style="{
              width: `${
                (downloadProgress.reduce((total, value) => total + value, 0) /
                  (1 + study.clipsCount)) *
                100
              }%`,
            }"
          />
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import prettyBytes from "pretty-bytes";
import { onMounted, reactive, ref } from "vue";
import { StudyGetOneResponseDto } from "../../../backend/src/studies/dto/study-get-one.dto";
import { getClips } from "../../../backend/src/studies/study-helpers";
import { addNotification } from "../utils/notifications.js";
import { Study, getEmptyStudy } from "../utils/study-data.js";
import LoadingIndicator from "./LoadingIndicator.vue";

interface Props {
  study: Study;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const activityText = ref("");

const fullStudy = ref(getEmptyStudy());

// One entry per DICOM, with an extra entry for the initial fetch of the full study definition
const downloadProgress = reactive(new Array(props.study.clipsCount + 1).fill(0));
const isDownloadComplete = ref(false);

// Abort controller used to stop any ongoing DICOM download when this modal is closed
const abortController = new AbortController();

async function getStudy(): Promise<void> {
  let response: AxiosResponse<StudyGetOneResponseDto> | undefined = undefined;

  try {
    response = await axios.get<StudyGetOneResponseDto>(`/api/studies/${props.study.id}`, {
      signal: abortController.signal,
    });
  } catch {
    if (!abortController.signal.aborted) {
      addNotification({ type: "error", message: "Failed getting study information" });
    }

    closeModal();
    return;
  }

  fullStudy.value = response.data;
  downloadProgress[0] = 1;

  if (getClips(fullStudy.value).some((clip) => clip.deidentify)) {
    addNotification({
      type: "error",
      message: "Study cannot be downloaded because it is deidentified",
    });
    closeModal();
  }
}

async function downloadStudyDicomFiles(): Promise<void> {
  // Generate DICOMDIR file
  const dicomdirBlob = await fetchStudyDicomDir();
  if (dicomdirBlob === undefined) {
    return;
  }

  zipDicomFolder.file("DICOMDIR", dicomdirBlob);

  // Sort the files by SeriesInstanceUID then SOP instance UID which is what the DICOMDIR expects
  const clips = fullStudy.value.series
    .sort((a, b) => a.seriesInstanceUid.localeCompare(b.seriesInstanceUid))
    .flatMap((s) => s.clips.sort((a, b) => a.sopInstanceUid.localeCompare(b.sopInstanceUid)));

  // Handle faked CT/MR clips
  const clipsToDownload: string[] = clips.flatMap((c) => {
    if (c.frameStudyClipDetails === undefined) {
      return [c.id];
    } else {
      return [...c.frameStudyClipDetails]
        .sort((a, b) => a.sopInstanceUid.localeCompare(b.sopInstanceUid))
        .map((d) => d.studyClipId);
    }
  });

  for (const [clipIndex, studyClipId] of clipsToDownload.entries()) {
    const blob = await downloadStudyClipDicomFile(studyClipId, clipIndex);
    if (blob === undefined) {
      if (!abortController.signal.aborted) {
        addNotification({ type: "error", message: "Failed downloading DICOM file" });
      }

      closeModal();
      return;
    }

    zipDicomFolder.file(`${clipIndex}`.padStart(16, "0"), blob);
  }

  isDownloadComplete.value = true;
}

let zipDicomFolder = new JSZip(); // The zip file for user to download

async function downloadStudyClipDicomFile(
  clipId: string,
  clipIndex: number
): Promise<Blob | undefined> {
  let response: AxiosResponse<Blob> | undefined = undefined;

  try {
    response = await axios.get<Blob>(
      `/api/studies/${props.study.id}/clips/${clipId}/image-data?type=dicom`,
      {
        onDownloadProgress(progressEvent) {
          if (progressEvent.total === undefined) {
            return;
          }

          downloadProgress[1 + clipIndex] = progressEvent.loaded / progressEvent.total;
        },
        timeout: 0,
        responseType: "blob",
        signal: abortController.signal,
      }
    );
  } catch {
    return undefined;
  }

  return response.data;
}

async function fetchStudyDicomDir(): Promise<Blob | undefined> {
  let response: AxiosResponse<Blob> | undefined = undefined;

  try {
    response = await axios.get<Blob>(`/api/studies/${props.study.id}/dicomdir`, {
      responseType: "blob",
    });
  } catch {
    addNotification({ type: "error", message: "Failed generating DICOMDIR for study" });
    return undefined;
  }

  return response.data;
}

onMounted(async () => {
  await getStudy();
  await downloadStudyDicomFiles();
});

async function saveDicomZipFile(): Promise<void> {
  activityText.value = "Saving";

  try {
    const blob = await zipDicomFolder.generateAsync({ type: "blob" });

    saveAs(blob, `HeartLab - DICOMs for ${fullStudy.value.studyInstanceUid}.zip`);
  } catch {
    addNotification({ type: "error", message: "Failed saving DICOMs to ZIP file" });
    return;
  } finally {
    activityText.value = "";
  }
}

function closeModal(): void {
  abortController.abort();
  emits("close");
}
</script>

<style scoped lang="scss">
.dicom-download-modal {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.message-container {
  display: flex;
  gap: 12px;
  align-items: center;
  line-height: 1em;
  white-space: nowrap;
  margin: 12px 0;
}

.download-progress {
  height: 6px;
  border-radius: var(--border-radius);
  background-color: var(--bg-color-4);
  overflow: hidden;
  align-self: stretch;
}

.download-progress-bar {
  background-color: var(--button-accented-bg-color);
  height: 6px;
  transition: width 100ms linear;
}

.download-ready-icon {
  align-self: center;
  color: var(--confirm-color-2);
}
</style>
