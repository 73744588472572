<template>
  <div class="settings-title">
    User Roles

    <div class="add-role-button">
      <button
        v-if="hasUserRoleManagePermission"
        class="accented"
        data-testid="add-role-button"
        @click="isCreateRoleModalVisible = true"
      >
        Add Role
      </button>
      <div v-else class="view-only" data-testid="view-only">Viewing only</div>
    </div>
  </div>

  <div class="user-roles">
    <div class="role-list">
      <RouterLink
        v-for="role in userRoleList"
        :key="role.id"
        :to="{ name: 'settings-access-roles-edit', params: { id: role.id } }"
        class="role-link"
      >
        <button :class="{ disabled: !role.isEnabled }" :data-testid="`role-${role.name}`">
          {{ role.name === "" ? "(no name)" : role.name }}
        </button>
      </RouterLink>
    </div>

    <RouterView />
  </div>

  <Modal
    v-if="isCreateRoleModalVisible"
    title="Create Role"
    @header-button-click="isCreateRoleModalVisible = false"
    @enter-key-press="createUserRole"
  >
    <div class="field">
      <b>Name</b>
      <input
        ref="newRoleNameElement"
        v-model="newRoleName"
        maxlength="100"
        data-testid="create-role-name-input"
      />
    </div>

    <button
      class="accented"
      style="width: min-content; margin: 0 auto"
      data-testid="create-role-button"
      :disabled="newRoleName.trim().length === 0"
      @click="createUserRole"
    >
      Create
    </button>
  </Modal>
</template>

<script setup lang="ts">
import router from "@/router";
import { useFocus } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import { UserRoleCreateResponseDto } from "../../../backend/src/user/dto/user-role-create.dto";
import { RoleType } from "../../../backend/src/user/user-role-type";
import { hasUserRoleManagePermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import Modal from "../components/Modal.vue";
import { addNotification } from "../utils/notifications";
import { useUserRoleList } from "../utils/user-roles-list";

const userRoleList = useUserRoleList();

// Open the built-in system admin role by default
onMounted(async () => {
  if (router.currentRoute.value.name === "settings-access-roles") {
    const adminRole = currentTenant.roles.find((r) => r.type === RoleType.SystemControlledAdmin);
    if (adminRole !== undefined) {
      await openRole(adminRole.id);
    }
  }
});

async function openRole(roleId: string): Promise<void> {
  await router.push({ name: "settings-access-roles-edit", params: { id: roleId } });
}

const isCreateRoleModalVisible = ref(false);
const newRoleName = ref("");

const newRoleNameElement = ref<HTMLInputElement | null>(null);
useFocus(newRoleNameElement, { initialValue: true });

const activityText = ref("");

async function createUserRole(): Promise<void> {
  activityText.value = "Creating role";

  let response: AxiosResponse<UserRoleCreateResponseDto> | undefined = undefined;
  try {
    response = await axios.post<UserRoleCreateResponseDto>(`/api/user-roles`, {
      name: newRoleName.value.trim(),
    });
  } catch {
    addNotification({ type: "error", message: "Failed creating new role" });
    return;
  } finally {
    addNotification({ type: "info", message: "Created new role" });

    activityText.value = "";
  }

  isCreateRoleModalVisible.value = false;
  userRoleList.value.push(response.data);

  await openRole(response.data.id);

  newRoleName.value = "";
}
</script>

<style scoped lang="scss">
.add-role-button {
  margin-left: auto;
  font-size: 13px;
}

.view-only {
  margin-left: 8px;
  border-radius: var(--border-radius);
  line-height: 1em;
  width: 140px;
  height: 32px;
  font-weight: bold;
  background-color: var(--bg-color-4);
  display: grid;
  place-content: center;
}

.user-roles {
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 64px;
  min-height: 0;
}

.role-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    width: 300px;
  }
}

.role-link {
  display: contents;

  button {
    overflow: hidden;
    white-space: normal;
    place-content: normal;
    text-align: left;

    &.disabled {
      background-color: var(--bg-color-2);
      filter: brightness(85%);
    }
  }

  &.router-link-active {
    button {
      outline: 1px solid var(--accent-color-1);
    }
  }
}
</style>
