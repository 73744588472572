<template>
  <ReportTemplateEditSlider
    :name="name"
    :model-value="structure.fontSizes[option]"
    :min="min"
    :max="max"
    :data-testid="`report-template-edit-slider-font-size-${option}`"
    @update:model-value="onFontSizeValueUpdate"
  />
</template>

<script setup lang="ts">
import {
  ReportFontSizeOption,
  type ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import {
  createFontSizeMutation,
  type ReportStructureMutation,
} from "../reporting/report-structure-mutations";
import ReportTemplateEditSlider from "./ReportTemplateEditSlider.vue";

interface Props {
  name: string;
  structure: ReportStructure;
  option: ReportFontSizeOption;
}

interface Emits {
  (event: "update", mutation: ReportStructureMutation): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const min = 0.8;
const max = props.option === ReportFontSizeOption.Title ? 3 : 2.5;

function onFontSizeValueUpdate(newValue: number): void {
  emits(
    "update",
    createFontSizeMutation({ ...props.structure.fontSizes, [props.option]: newValue })
  );
}
</script>
