<template>
  <DatePicker
    v-model="date"
    class="date-picker"
    v-bind="$attrs"
    :is-range="isRange"
    :popover="popover"
  >
    <template #default="{ togglePopover, inputValue, inputEvents }">
      <div v-if="!slots.default" class="content" @click="togglePopover">
        <FontAwesomeIcon :icon="icon" class="filter-search" />

        <template v-if="isRange">
          <span v-if="dateStartString === ''" class="placeholder">
            {{ placeholder }}
          </span>
          <template v-else>
            {{ dateStartString }}
            <span v-if="dateEndString" class="to-separator">to</span>
            {{ dateEndString }}
          </template>
        </template>

        <template v-else>
          <span v-if="dateString === ''" class="placeholder">
            {{ placeholder }}
          </span>
          <template v-else>
            {{ dateString }}
          </template>
        </template>

        <FontAwesomeIcon
          v-if="isRange && (dateRange?.start || dateRange?.end)"
          icon="times"
          class="clear-date"
          data-testid="clear-date-picker"
          @click.stop="date = null"
        />
      </div>
      <slot :toggle-popover="togglePopover" :input-value="inputValue" :input-events="inputEvents" />
    </template>
  </DatePicker>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { DateTime } from "luxon";
import { DatePicker } from "v-calendar";
import { computed, useSlots } from "vue";
import { DATE_FORMAT_STRING } from "../utils/date-time-utils";

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface Props {
  modelValue: Date | DateRange | string | null;
  isRange?: boolean;
  format?: string;
  placeholder?: string;
  popover?: { visibility?: string; label?: string };
  icon?: string;
  backgroundColor?: string;
}

interface Emits {
  (event: "update:modelValue", newValue: Date | DateRange | string | null): void;
}

const props = withDefaults(defineProps<Props>(), {
  isRange: false,
  format: DATE_FORMAT_STRING,
  placeholder: "",
  popover: () => ({ visibility: "click" }),
  icon: "calendar-days",
  backgroundColor: "var(--bg-color-2)",
});

const emits = defineEmits<Emits>();

const slots = useSlots();

const date = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: Date | string | { start: Date | null; end: Date | null } | null) {
    emits("update:modelValue", newValue);
  },
});

const dateRange = computed(() => {
  if (!props.isRange) {
    return null;
  }

  return props.modelValue as DateRange | null;
});

function formatDate(d: Date | null | undefined): string {
  if (d === null || d === undefined) {
    return "";
  }

  return DateTime.fromJSDate(d).toFormat(props.format);
}

const dateString = computed(() => {
  if (props.isRange) {
    return "";
  }

  return formatDate(props.modelValue as Date | null);
});

const dateStartString = computed(() => {
  if (!props.isRange) {
    return "";
  }

  return formatDate(dateRange.value?.start);
});

const dateEndString = computed(() => {
  if (!props.isRange) {
    return "";
  }

  return formatDate(dateRange.value?.end);
});
</script>

<style scoped lang="scss">
.date-picker {
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--accent-color-1);
  background-color: v-bind("backgroundColor");
  transition:
    background-color 100ms ease,
    filter 100ms ease;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-1);
  height: 32px;
  padding: 0 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--bg-color-3);
  }

  .filter-search {
    color: var(--accent-color-1);
    margin: 0 6px 0 2px;
  }

  .to-separator {
    font-weight: bold;
    margin: 0 2px 0 4px;
  }

  .clear-date {
    margin-left: auto;
    transition: color 100ms ease;

    &:hover {
      color: var(--text-color-2);
    }
  }
}

.placeholder {
  font-style: italic;
}

:deep(.vc-popover-content-wrapper) {
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.5em 1.5em;

  button {
    background: var(--bg-color-2);
    color: var(--accent-color-1);
    height: 32px;
  }

  .vc-popover-content {
    border: 1px solid var(--border-color-1) !important;
  }

  .vc-container {
    background-color: var(--bg-color-3);
    border-radius: var(--border-radius);
    color: white;

    .vc-time-date {
      display: none;
    }
  }

  .vc-title {
    color: var(--text-color-1);
  }

  .vc-arrow {
    color: var(--accent-color-1);
    transition: color 100ms ease;

    &:hover {
      color: var(--accent-color-2);
      background: transparent;
    }
  }

  .vc-highlight {
    background-color: var(--accent-color-1) !important;
  }

  .vc-day-content:hover {
    background-color: var(--accent-color-1);
  }

  .vc-day-content.is-disabled {
    cursor: not-allowed;
    color: grey;
  }

  .vc-weekday,
  .vc-time-weekday .vc-day-layer .vc-nav-title {
    color: var(--accent-color-1);
  }

  .vc-nav-popover-container {
    background-color: var(--bg-color-4);
    border: none;
    font-size: 1em;
  }
}
</style>
