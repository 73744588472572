import { computed, ref, type ComputedRef, type Ref } from "vue";
import type { ReportStructure } from "../../../backend/src/reporting/report-structure";
import {
  getSentenceGroupsForSection,
  type ReportSectionCommentFieldEventDetails,
} from "./report-content";

interface FocusedSectionDetails {
  id: string;
  focusTextarea: () => void;
  insertText: (text: string) => void;
}

export function useReportFocusedSection(getReportStructure: () => ReportStructure | undefined): {
  focusedSection: Ref<FocusedSectionDetails | null>;
  focusedSectionSentenceGroups: ComputedRef<{ id: string; name: string; sentences: string[] }[]>;
  onSectionCommentFieldAction: (details: ReportSectionCommentFieldEventDetails) => void;
  onCloseSentenceLibrary: () => void;
} {
  // The focused section on the report is used to know what sentence library content to show
  const focusedSection = ref<FocusedSectionDetails | null>(null);

  // The sentence groups for the focused section. If there are no sentence groups then the library
  // won't be shown.
  const focusedSectionSentenceGroups = computed(() => {
    const reportStructure = getReportStructure();
    if (focusedSection.value === null || reportStructure === undefined) {
      return [];
    }

    return getSentenceGroupsForSection(reportStructure, focusedSection.value.id);
  });

  function onSectionCommentFieldAction(details: ReportSectionCommentFieldEventDetails): void {
    if (details.action === "click-out") {
      if (details.sectionId === focusedSection.value?.id) {
        focusedSection.value = null;
      }

      lastClosedSectionId = "";

      return;
    }

    // If the sentence library for this section was closed then don't re-open it when clicking back
    // into the same section.
    if (lastClosedSectionId === details.sectionId) {
      return;
    }

    focusedSection.value = {
      id: details.sectionId,
      focusTextarea: details.focusTextarea,
      insertText: details.insertText,
    };
  }

  let lastClosedSectionId = "";

  function onCloseSentenceLibrary(): void {
    if (focusedSection.value === null) {
      return;
    }

    lastClosedSectionId = focusedSection.value.id;

    focusedSection.value.focusTextarea();
    focusedSection.value = null;
  }

  return {
    focusedSection,
    focusedSectionSentenceGroups,
    onSectionCommentFieldAction,
    onCloseSentenceLibrary,
  };
}
