<template>
  <!-- Show a logo if there is no study loaded to show extra clips for -->
  <div v-if="study.id === ''" class="logo-container">
    <img src="/logo-with-name.png" data-testid="secondary-placeholder-img" />
  </div>

  <template v-else>
    <div class="top-line">
      <Branding :study="study" />
      <KeyPatientInfo :study="study" :show-participants="false" />
    </div>

    <div v-if="isStressModeEnabled" class="stress-mode-container">
      <StressModeHeader
        v-model:stress-mode-layout="stressModeLayout"
        v-model:is-clip-sync-enabled="isClipSyncEnabled"
        v-model:stress-mode-selected-view="stressModeSelectedView"
        v-model:stress-mode-show-all-clips-in-by-view="stressModeShowAllClipsInByView"
        :study="study"
        @disable-stress-mode="onDisableStressMode"
      />

      <StressLayoutTable
        v-if="stressModeLayout === 'table'"
        v-model:focused-clip-index="focusedClipIndex"
        v-model:is-clip-sync-enabled="isClipSyncEnabled"
        v-model:show-measurements="showMeasurements"
        :study="study"
        :clips-grid-items="clipsGridItems"
        :selected-clip-ids="selectedClipIds"
        @update:is-stress-mode-enabled="isStressModeEnabled = $event"
      />

      <StressLayoutSingleView
        v-else
        v-model:focused-clip-index="focusedClipIndex"
        v-model:is-clip-sync-enabled="isClipSyncEnabled"
        v-model:stress-mode-selected-view="stressModeSelectedView"
        v-model:show-measurements="showMeasurements"
        :study="study"
        :clips-grid-items="clipsGridItems"
        :selected-clip-ids="selectedClipIds"
        :stress-mode-show-all-clips-in-by-view="stressModeShowAllClipsInByView"
        @update:is-stress-mode-enabled="isStressModeEnabled = $event"
      />
    </div>

    <ClipsAreaDefault
      v-else
      v-model:focused-clip-index="focusedClipIndex"
      v-model:is-stress-mode-enabled="isStressModeEnabled"
      v-model:is-clip-sync-enabled="isClipSyncEnabled"
      v-model:show-measurements="showMeasurements"
      :selected-clip-ids="selectedClipIds"
      :study="study"
      :clips-grid-items="clipsGridItems"
      @scroll-to-measurement="scrollToAndHighlightMeasurementCard"
    />
  </template>
</template>

<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { computed, onUnmounted, ref, watch } from "vue";
import Branding from "../../components/Branding.vue";
import { MAX_SELECTED_CLIPS } from "../../study-view/clip-model";
import { onKeyboardShortcut } from "../../utils/keyboard-shortcut";
import { getEmptyStudy } from "../../utils/study-data";
import ClipsAreaDefault from "../ClipsAreaDefault.vue";
import KeyPatientInfo from "../KeyPatientInfo.vue";
import {
  createClipsGridItemsArray,
  updateClipsGridItemsArrayFromSelectedClips,
} from "../clip-viewer/clips-grid-item";
import StressLayoutSingleView from "../stress-mode/StressLayoutSingleView.vue";
import StressLayoutTable from "../stress-mode/StressLayoutTable.vue";
import StressModeHeader from "../stress-mode/StressModeHeader.vue";
import { getStressViewNames } from "../study-clip-helpers";
import { useStudyViewImageData } from "../study-view-image-data";
import { postMessageToPrimaryWindow } from "./primary-window-messages";
import { onSecondaryWindowMessageReceived } from "./secondary-window-messages";

const study = ref(getEmptyStudy());
const selectedClipIds = ref<string[]>([]);
const isClipSyncEnabled = ref(false);
const focusedClipIndex = ref(0);

const isStressModeEnabled = ref(false);
const stressModeLayout = useStorage<"byView" | "table">("stress-mode-layout", "table");
const stressModeSelectedView = ref("");
const stressModeShowAllClipsInByView = useStorage("stress-mode-show-all-clips-in-by-view", false);

onSecondaryWindowMessageReceived("set-study", (message) => {
  Object.assign(study.value, JSON.parse(message.studyJson));
  updateClipsGridItems();
});

const clipsGridItems = createClipsGridItemsArray(MAX_SELECTED_CLIPS);

function updateClipsGridItems(): void {
  updateClipsGridItemsArrayFromSelectedClips(
    clipsGridItems,
    study.value,
    selectedClipIds.value,
    isClipSyncEnabled.value
  );
}

watch(selectedClipIds, updateClipsGridItems, { deep: true });

const viewNames = computed(() => getStressViewNames(study.value));
watch(
  viewNames,
  () => {
    if (viewNames.value.includes(stressModeSelectedView.value)) {
      return;
    }

    // Default to the second view for secondary windows
    stressModeSelectedView.value = viewNames.value[1] ?? "";
  },
  { immediate: true }
);

const showMeasurements = ref(true);

onSecondaryWindowMessageReceived("set-measurement-visibility", (message) => {
  showMeasurements.value = message.isVisible;
});

onSecondaryWindowMessageReceived("set-clip-sync-enabled", (message) => {
  isClipSyncEnabled.value = message.isClipSyncEnabled;
});

onSecondaryWindowMessageReceived("set-stress-mode-enabled", (message) => {
  isStressModeEnabled.value = message.isStressModeEnabled;
});

onSecondaryWindowMessageReceived("set-stress-mode-view", (message) => {
  stressModeSelectedView.value = message.viewName;
});

// Forward through the following keyboard shortcuts to the primary window so that they work when
// this secondary window is focused
for (const key of ["m", "r", "c", "s", " ", ",", ".", "v"]) {
  onKeyboardShortcut(key, () =>
    postMessageToPrimaryWindow({ type: "secondary-window-key-pressed", key })
  );
}

function onDisableStressMode() {
  postMessageToPrimaryWindow({ type: "set-stress-mode-enabled", isStressModeEnabled: false });
}

onUnmounted(() => {
  useStudyViewImageData().unloadImageData();
});

function scrollToAndHighlightMeasurementCard(measurementId: string) {
  postMessageToPrimaryWindow({ type: "scroll-and-highlight-measurement", measurementId });
}
</script>

<style scoped lang="scss">
.logo-container {
  flex: 1;
  display: grid;
  place-content: center;

  img {
    height: 64px;
  }
}

.top-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  background-color: var(--bg-color-2);
  border-bottom: 1px solid var(--border-color-1);
}

.stress-mode-container {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}
</style>
