import { Permission } from "../../../backend/src/auth/permissions/permissions";

export interface PermissionInfo {
  value: Permission;
  name?: string;
  description?: string;
  requires?: Permission[];
}

export interface PermissionGroup {
  name: string;
  description?: string;
  permissions: PermissionInfo[];
}

export const PERMISSION_GROUPS: PermissionGroup[] = [
  {
    name: "Activity Charts",
    description: "Viewing of activity charts",
    permissions: [{ name: "View", value: Permission.ChartingView }],
  },
  {
    name: "Audit Logs",
    description: "Viewing and searching of audit logs",
    permissions: [{ value: Permission.AuditLogView }],
  },
  {
    name: "Billing",
    description: "Access to billing information",
    permissions: [{ value: Permission.BillingManage }],
  },
  {
    name: "DICOM Endpoints",
    permissions: [
      {
        name: "Query",
        description: "Querying of DICOM endpoints and importing of found studies",
        value: Permission.DicomEndpointQuery,
      },
      {
        name: "Send",
        description: "Initiate sending of studies to DICOM endpoints",
        value: Permission.DicomEndpointSend,
      },
    ],
  },
  {
    name: "DICOM Worklist Entries",
    description: "Management of DICOM worklist entries",
    permissions: [{ value: Permission.DicomScheduledProcedureStepManage }],
  },
  {
    name: "Integrations",
    permissions: [
      // {
      //   name: "Agent",
      //   description: "Management of HeartLab Agent integrations",
      //   value: Permission.AgentIntegrationManage,
      // },
      {
        name: "DICOM",
        description: "Management of DICOM client identities used by connected modalities",
        value: Permission.DicomClientIdentityManage,
      },
      {
        name: "HeartBox",
        description: "Management of HeartBox integrations",
        value: Permission.HeartBoxIntegrationManage,
      },
      {
        name: "HL7",
        description: "Management of HL7 integrations for automated report sending",
        value: Permission.HL7IntegrationManage,
      },
      {
        name: "HL7 Message Download",
        description: "Downloading of HL7 messages as a file",
        value: Permission.HL7MessageDownload,
      },
      {
        name: "HL7 Message Send",
        description: "Sending of study reports to a configured HL7 integration",
        value: Permission.HL7MessageSend,
      },
      {
        name: "SFTP",
        description: "Management of SFTP integrations for automated report sending",
        value: Permission.SftpIntegrationManage,
      },
      {
        name: "Webhooks",
        description: "Management of webhooks used by 3rd party integrations",
        value: Permission.WebhookIntegrationManage,
      },
    ],
  },
  {
    name: "Measurement Calculations",
    description: "Management of measurement calculations",
    permissions: [{ value: Permission.MeasurementCalculationManage }],
  },
  {
    name: "Measurement Sequences",
    description: "Management of measurement sequences",
    permissions: [{ value: Permission.MeasurementSequenceManage }],
  },
  {
    name: "Report Templates",
    description: "Management of report templates and the sentence library",
    permissions: [{ value: Permission.ReportTemplateManage }],
  },
  {
    name: "Studies",
    permissions: [
      {
        name: "View",
        description: "Viewing of studies",
        value: Permission.StudyView,
      },
      {
        name: "Edit",
        description: "Editing of studies and measurements",
        value: Permission.StudyUpdate,
        requires: [Permission.StudyView],
      },
      {
        name: "Delete study",
        description: "Deletion of whole studies",
        value: Permission.StudyDelete,
        requires: [Permission.StudyView],
      },
      {
        name: "Delete clips",
        description: "Deletion of individual clips in a study",
        value: Permission.StudyClipDelete,
        requires: [Permission.StudyView],
      },
      {
        name: "Change patient identity",
        description: "Changing the patient identity for studies",
        value: Permission.StudyPatientIdentityUpdate,
        requires: [Permission.StudyView],
      },
      {
        name: "Reprocess",
        description: "Manual reprocessing of studies (use with caution)",
        value: Permission.StudyReprocess,
        requires: [Permission.StudyView],
      },
      {
        name: "Share",
        description: "Sharing of studies using a share link",
        value: Permission.StudyShare,
        requires: [Permission.StudyView],
      },
      {
        name: "Upload",
        description:
          "Uploading of DICOM files that may create new studies or add to existing studies",
        value: Permission.StudyUpload,
      },
      {
        name: "Webhook activate",
        description: "Requesting to send a study to a webhook integration",
        value: Permission.StudyWebhookActivate,
        requires: [Permission.StudyView],
      },
    ],
  },
  {
    name: "Study Reports",
    permissions: [
      {
        name: "Create initial preliminary",
        description: "Creating the initial preliminary report",
        value: Permission.StudyReportPreliminaryCreateInitial,
      },
      {
        name: "Create preliminary",
        description: "Creating preliminary reports",
        value: Permission.StudyReportPreliminaryCreate,
        requires: [Permission.StudyReportPreliminaryCreateInitial],
      },
      {
        name: "Approve preliminary",
        description: "Approving preliminary reports",
        value: Permission.StudyReportPreliminaryComplete,
        requires: [
          Permission.StudyReportPreliminaryCreateInitial,
          Permission.StudyReportPreliminaryCreate,
        ],
      },
      {
        name: "Finalize preliminary",
        description: "Finalizing preliminary reports by signing it off",
        value: Permission.StudyReportPreliminaryFinalize,
        requires: [
          Permission.StudyReportPreliminaryCreateInitial,
          Permission.StudyReportPreliminaryCreate,
        ],
      },
      {
        name: "Create amendments",
        description: "Creating report amendments",
        value: Permission.StudyReportAmendmentCreate,
      },
      {
        name: "Approve simple amendments",
        description:
          "Approving simple report amendments that do not change the body of the report, e.g. when only the patient details have changed",
        value: Permission.StudyReportAmendmentComplete,
        requires: [Permission.StudyReportAmendmentCreate],
      },
      {
        name: "Approve amendments to report body",
        description: "Approving report amendments that change any of the report's content",
        value: Permission.StudyReportAmendmentCompleteWithUpdatedContent,
        requires: [Permission.StudyReportAmendmentCreate, Permission.StudyReportAmendmentComplete],
      },
      {
        name: "Delete unsigned reports",
        description: "Deleting reports that have not yet been signed off",
        value: Permission.StudyReportDeleteUnsigned,
      },
      {
        name: "Delete signed reports",
        description: "Deleting reports that have been finalized or signed off",
        value: Permission.StudyReportDeleteSigned,
        requires: [Permission.StudyReportDeleteUnsigned],
      },
    ],
  },
  {
    name: "Study List",
    description: "Viewing of the study list",
    permissions: [{ name: "View", value: Permission.StudyListView }],
  },
  {
    name: "Users",
    permissions: [
      {
        name: "View",
        description: "Viewing of users and user details",
        value: Permission.UserView,
        requires: [Permission.UserRoleView],
      },
      {
        name: "Invite",
        description: "Inviting of users by email and canceling of pending invitations",
        value: Permission.UserInvite,
        requires: [Permission.UserView],
      },
      {
        name: "Update",
        description: "Updating of the details for users, exclusive of roles",
        value: Permission.UserUpdate,
        requires: [Permission.UserView],
      },
      {
        name: "Update roles",
        description: "Updating of the roles assigned to users",
        value: Permission.UserRolesUpdate,
        requires: [Permission.UserView],
      },
    ],
  },
  {
    name: "User Roles",
    permissions: [
      {
        name: "View",
        description: "Viewing of the roles that can be assigned to users",
        value: Permission.UserRoleView,
      },
      {
        name: "Manage",
        description: "Management of the roles that can be assigned to users",
        value: Permission.UserRoleManage,
        requires: [Permission.UserRoleView],
      },
    ],
  },
  {
    name: "Other Admin Tasks",
    description: "Management of global settings not covered by other permissions",
    permissions: [{ value: Permission.TenantManage }],
  },
];
