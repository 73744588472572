<template>
  <div ref="rootElement">
    <div ref="referenceElement" @keyup.esc="closePopper" @mousedown="togglePopper">
      <!-- The default slot to trigger the popper  -->
      <slot />
    </div>

    <Transition name="fade">
      <div
        v-show="isOpen"
        v-bind="$attrs"
        ref="popperElement"
        class="popper"
        :style="popperPosition"
        @click="!interactive && closePopper()"
      >
        <slot name="content">
          {{ content }}
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { Placement, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";
import { onClickOutside } from "@vueuse/core";
import { nextTick, onBeforeUnmount, reactive, ref, watch } from "vue";

interface Props {
  content?: string;
  placement?: Placement;
  offsetDistance?: number;
  offsetDistanceCrossAxis?: number;
  show?: boolean;
  disabled?: boolean;
  interactive?: boolean;
}

interface Emits {
  (event: "open"): void;
  (event: "close"): void;
}

const props = withDefaults(defineProps<Props>(), {
  content: undefined,
  disabled: false,
  placement: "bottom",
  offsetDistance: 12,
  offsetDistanceCrossAxis: 0,

  // The following cast should be unnecessary, but it seems to fix a spurious error from ESlint in
  // the code below
  show: undefined as boolean | undefined,

  interactive: true,
});

const emits = defineEmits<Emits>();

defineOptions({ inheritAttrs: false });

let cleanupPopper: (() => void) | undefined = undefined;
const popperPosition = reactive({ left: "0px", top: "0px" });

const rootElement = ref<HTMLElement | null>(null);
const referenceElement = ref<HTMLElement | null>(null);
const popperElement = ref<HTMLElement | null>(null);

const isOpen = ref(false);

async function openPopper(): Promise<void> {
  if (props.disabled) {
    return;
  }

  // Wait for the template refs to be set to handle the case where this Popper has just mounted
  await nextTick();

  cleanupPopper = autoUpdate(referenceElement.value!, popperElement.value!, () => {
    if (referenceElement.value && popperElement.value) {
      void computePosition(referenceElement.value, popperElement.value, {
        placement: props.placement,
        middleware: [
          offset({ mainAxis: props.offsetDistance, crossAxis: props.offsetDistanceCrossAxis }),
          shift({ padding: 4 }),
          flip(),
        ],
      }).then(({ x, y }) => {
        popperPosition.left = `${x}px`;
        popperPosition.top = `${y}px`;
      });
    }
  });

  isOpen.value = true;
  emits("open");
}

function closePopper(): void {
  if (props.show || props.disabled) {
    return;
  }

  cleanupPopper?.();

  isOpen.value = false;
  emits("close");
}

async function togglePopper(): Promise<void> {
  if (props.disabled) {
    return;
  }

  if (isOpen.value) {
    closePopper();
  } else {
    await openPopper();
  }
}

onClickOutside(rootElement, closePopper);

watch(
  () => props.show,
  async () => {
    if (props.show) {
      await openPopper();
    } else {
      cleanupPopper?.();
      isOpen.value = false;
    }
  },
  { immediate: true }
);

defineExpose({ close: closePopper });

onBeforeUnmount(() => {
  emits("close");
  cleanupPopper?.();
});
</script>

<style scoped lang="scss">
.popper {
  position: absolute;
  animation: popper-fade-in 200ms ease 0s 1;
  z-index: 100;

  background-color: var(--bg-color-1);
  color: var(--text-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  padding: 8px;

  // Taken from https://getcssscan.com/css-box-shadow-examples
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@keyframes popper-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
