import axios from "axios";
import type { Ref } from "vue";
import { ref } from "vue";
import type { TenantGetManyResponseDto } from "../../../backend/src/tenants/global-admin/dto/tenant-get-many.dto";
import { currentTenant } from "../auth/current-session";
import { addNotification } from "./notifications";

const tenants = ref<TenantGetManyResponseDto>([]);

export function useTenants(): Ref<TenantGetManyResponseDto> {
  if (currentTenant.isGlobalAdmin) {
    void loadTenants();
  }

  return tenants;
}

let isLoading = false;

async function loadTenants(): Promise<void> {
  if (isLoading) {
    return;
  }

  isLoading = true;

  try {
    tenants.value = (await axios.get<TenantGetManyResponseDto>(`/api/global/tenants`)).data;
  } catch {
    addNotification({ type: "error", message: "Failed loading list of tenants" });
  } finally {
    isLoading = false;
  }
}
