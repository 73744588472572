<template>
  <div class="back-btn" @click="router.push({ name: 'settings-integrations-heartbox-all' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to HeartBox Settings
  </div>

  <div class="settings-title">
    HeartBox: {{ heartbox.displayName }}

    <div style="font-size: 13px; font-weight: normal; display: contents">
      <button
        style="margin-left: auto"
        type="button"
        data-testid="delete-heartbox-btn"
        @click="deleteHeartBoxIntegration"
      >
        Delete HeartBox
      </button>
    </div>
  </div>

  <div class="field">
    <b>Display Name</b>
    <input
      v-model="heartbox.displayName"
      type="text"
      :readonly="!hasHeartBoxIntegrationManagePermission"
      data-testid="display-name-input"
      @update:model-value="updateHeartBoxDebounced"
    />
  </div>

  <div class="field">
    <b>Device Name</b>
    <code class="selectable-text" data-testid="device-name"> {{ heartbox.deviceName }} </code>
  </div>

  <div class="field">
    <b>Device URL</b>
    <code class="selectable-text" data-testid="device-url"> {{ deviceUrl }} </code>
  </div>

  <div class="field">
    <b>Last Seen</b>
    <p>{{ formatRelativeTime(heartbox.lastSeenAt) }}</p>
  </div>

  <div class="field">
    <b>Config</b>
    <textarea
      v-model="heartbox.config"
      class="selectable-text"
      :disabled="!hasHeartBoxIntegrationManagePermission"
      data-testid="device-config-input"
      @update:model-value="updateHeartBoxDebounced"
    />

    <div style="justify-self: right">
      <Tooltip placement="left">
        <div class="config-invalid" :class="{ visible: configErrors.length !== 0 }">
          Config is invalid
        </div>

        <template #content>
          <div class="config-errors">
            <b>Location</b>
            <b>Details</b>

            <template v-for="(error, index) in configErrors" :key="index">
              <code>{{ error.path }}</code>
              <div>{{ error.message }}</div>
            </template>
          </div>
        </template>
      </Tooltip>
    </div>
  </div>

  <div class="field">
    <b>Release Version</b>
    <pre class="selectable-text">{{ heartbox.releaseVersion }}</pre>
  </div>

  <div class="field">
    <b>Release Build Date</b>
    <pre class="selectable-text">{{ heartbox.releaseBuildDate }}</pre>
  </div>

  <div class="field">
    <b>Release SHA</b>
    <pre class="selectable-text">{{ heartbox.releaseSha.substring(0, 7) }}</pre>
  </div>

  <div class="field">
    <b>Jump Server Details</b>

    <span>
      IP address: <code>{{ heartbox.jumpServerIpAddress }}</code> , Port:
      <code>{{ heartbox.jumpServerPort }}</code>
    </span>
  </div>

  <div class="field">
    <b>Status</b>
    <pre class="selectable-text">{{ heartbox.status }}</pre>
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/shared";
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { HeartBoxIntegrationGetOneResponseDto } from "../../../../../backend/src/integrations/heartbox/dto/heartbox-integration-get-one.dto";
import { HeartBoxConfigSchema } from "../../../../../heartbox/src/heartbox-config";
import { hasHeartBoxIntegrationManagePermission } from "../../../auth/authorization";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import Tooltip from "../../../components/Tooltip.vue";
import { formatRelativeTime } from "../../../utils/date-time-utils";
import { addNotification } from "../../../utils/notifications";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const activityText = ref("");

const heartbox = ref<HeartBoxIntegrationGetOneResponseDto>({
  id: "",
  deviceName: "",
  displayName: "",
  lastSeenAt: null,
  config: '{"endpoints": [], "routers": []}',
  status: "",
  releaseSha: "",
  releaseBuildDate: "",
  releaseVersion: "",
  jumpServerIpAddress: "",
  jumpServerPort: 0,
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<HeartBoxIntegrationGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<HeartBoxIntegrationGetOneResponseDto>(
      `/api/integrations/heartbox/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: `Failed loading HeartBox` });
    return;
  } finally {
    activityText.value = "";
  }

  heartbox.value = response.data;
});

const deviceUrl = computed(
  () => `${window.location.origin}/api/integrations/heartbox/${heartbox.value.id}`
);

const configErrors = computed((): { path: string; message: string }[] => {
  // Check that the config string is valid JSON
  let parsedJson: unknown = undefined;
  try {
    parsedJson = JSON.parse(heartbox.value.config);
  } catch {
    return [{ path: "JSON", message: "Invalid JSON" }];
  }

  // Check that the config string is a valid HeartBox config, returning details on the
  // errors if there are any.
  const parseResult = HeartBoxConfigSchema.safeParse(parsedJson);
  if (!parseResult.success) {
    return parseResult.error.issues
      .map((issue) => ({
        path: issue.path.join("."),
        message: issue.message,
      }))
      .sort((a, b) => a.path.localeCompare(b.path));
  }

  return [];
});

async function updateHeartBox(): Promise<void> {
  if (configErrors.value.length !== 0) {
    return;
  }

  try {
    await axios.patch(`/api/integrations/heartbox/${heartbox.value.id}`, {
      displayName: heartbox.value.displayName,
      config: HeartBoxConfigSchema.parse(JSON.parse(heartbox.value.config)),
    });
  } catch {
    addNotification({ type: "error", message: "Error updating HeartBox" });
    return;
  }

  addNotification({ type: "info", message: "Updated HeartBox" });
}

const updateHeartBoxDebounced = useDebounceFn(() => {
  void updateHeartBox();
}, 1000);

async function deleteHeartBoxIntegration(): Promise<void> {
  if (!confirm(`Are you sure you want to delete this HeartBox integration?`)) {
    return;
  }

  activityText.value = "Deleting";

  try {
    await axios.delete(`/api/integrations/heartbox/${heartbox.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting HeartBox integration" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Deleted HeartBox integration" });

  await router.push({ name: "settings-integrations-heartbox-all" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 300px;
}

textarea {
  min-height: 250px;
  font-family: monospace;
}

.config-invalid {
  color: red;
  opacity: 0;
  transition: 100ms opacity ease;

  &.visible {
    opacity: 1;
  }
}

.config-errors {
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px 32px;

  code {
    font-size: 0.9em;
  }
}

pre {
  margin: 0;
}
</style>
