import axios, { type AxiosResponse } from "axios";
import { ref, type Ref } from "vue";
import type { WebhookIntegrationGetManyResponseDto } from "../../../backend/src/integrations/webhook/dto/webhook-integration-get-many.dto";
import { hasStudyWebhookActivatePermission } from "../auth/authorization";
import { addNotification } from "./notifications";

const webhookIntegrations = ref<WebhookIntegrationGetManyResponseDto>([]);
let isLoading = false;

export function useStudyDropdownWebhookIntegrations(): Ref<WebhookIntegrationGetManyResponseDto> {
  if (hasStudyWebhookActivatePermission.value && !isLoading) {
    void fetchWebhookIntegrations();
  }

  return webhookIntegrations;
}

async function fetchWebhookIntegrations() {
  isLoading = true;

  let response: AxiosResponse<WebhookIntegrationGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<WebhookIntegrationGetManyResponseDto>(
      `/api/integrations/webhook/study-dropdown-items`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading webhooks" });
    return;
  } finally {
    isLoading = false;
  }

  webhookIntegrations.value = response.data;
  webhookIntegrations.value.sort((a, b) => a.name.localeCompare(b.name));
}
