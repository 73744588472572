import { computed, reactive, ref, watch } from "vue";
import type { VelocityAssociatedMeasurementName } from "../../../../../backend/src/measurements/associated-measurements";
import { velocityAssociatedMeasurements } from "../../../../../backend/src/measurements/associated-measurements";
import { getDrawableVelocityMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasuredValue } from "../../../../../backend/src/measurements/measured-value";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import {
  MeasurementPhysicalQuantity,
  MeasurementUnit,
} from "../../../../../backend/src/measurements/measurement-units";
import { isPointWithinTolerance } from "../../../../../backend/src/shared/math-utils";
import { getClips } from "../../../../../backend/src/studies/study-helpers";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import { findSavedAssociatedMeasurements } from "../../measurement-helpers";
import {
  buildMeasurementToolBatchChangeRequest,
  generateAssociatedMeasurementRefs,
  setAssociatedMeasurementDetailsOnEdit,
  setDefaultEnabledForAssociatedMeasurements,
} from "../../measurement-tool-helpers";
import type {
  AssociatedMeasurement,
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "../measurement-tool";
import { drawPlusMark, isRegionSpectral } from "../measurement-tool-helpers";
import { measurementIncompleteTooltipText } from "../measurement-tooltips";
import { createBaseDopplerMeasurement } from "./measurement-base-doppler";

/**
 * Creates a new velocity measurement tool instance for the given study.
 */
// eslint-disable-next-line max-statements
export function createVelocityMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomVelocity;
  const customName = ref("");

  const baseMeasurementCreationRequest = {
    tool: MeasurementToolName.Velocity,
    studyClipId,
  };

  const frame = ref(0);
  const point = reactive<number[]>([]);
  const isEditing = ref(true);
  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const baseDopplerMeasurement = createBaseDopplerMeasurement(studyClip, region);

  const measuredValue = computed(() => baseDopplerMeasurement.getYPositionOfPoint(point).abs());

  const isComplete = computed(() => point.length === 2 && !isEditing.value);

  const saveButtonTooltip = computed(() =>
    isComplete.value ? "" : measurementIncompleteTooltipText
  );

  const helpText = computed(() =>
    point.length === 2
      ? "Click to place a velocity measurement"
      : "Select a region to measure velocity"
  );

  const toolbarItems = computed((): ToolbarItem[] => [
    {
      text: measuredValue.value.formatAsStringForName(
        measurementName.value ?? customMeasurementName
      ),
    },
  ]);

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (point.length !== 2) {
      return [];
    }

    const labels: MeasurementLabel[] = [];

    if (!measuredValue.value.isNull()) {
      labels.push({
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: measuredValue.value.createTransientValue(
          customMeasurementName,
          point,
          frame.value
        ),
        x: point[0] * canvas.width + 40,
        y: point[1] * canvas.height,
      });
    }

    return labels;
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    if (point.length === 0 || region === undefined || studyClip === undefined) {
      return;
    }

    drawPlusMark(ctx, point[0] * canvas.width, point[1] * canvas.height, 5);
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (baseDopplerMeasurement.midlineY.value === undefined) {
      return;
    }

    if (isEditing.value && point.length === 2) {
      isEditing.value = false;
    } else if (isPointWithinTolerance(pt, point)) {
      isEditing.value = true;
    }

    point.splice(0, point.length, ...pt);
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    if (baseDopplerMeasurement.midlineY.value === undefined || !isEditing.value) {
      return false;
    }

    point.splice(0, point.length, ...pt);

    return true;
  }

  function onCanvasMouseUp(pt: number[]): void {
    if (isEditing.value && point.length === 2) {
      isEditing.value = false;
      point.splice(0, point.length, ...pt);
    }
    return;
  }

  const associatedGradientRefs = generateAssociatedMeasurementRefs();

  // eslint-disable-next-line max-statements
  const associatedMeasurements = computed(() => {
    if (measurementName.value === undefined) {
      return {};
    }

    const result: Partial<Record<VelocityAssociatedMeasurementName, AssociatedMeasurement>> = {};
    const associatedNames = velocityAssociatedMeasurements[measurementName.value];

    if (!measuredValue.value.isNull()) {
      if (associatedNames !== undefined) {
        const gradientValueAndUnit = calculateGradientFromVelocity(
          measuredValue.value
        ).getInternalValueAndUnit();

        if (gradientValueAndUnit) {
          result.gradient = {
            ...associatedGradientRefs,
            ...baseMeasurementCreationRequest,
            ...gradientValueAndUnit,
            name: associatedNames.gradient,
            frame: frame.value,
          };
        }
      }
    }

    if (savedMeasurementBeingEdited.value === undefined && associatedNames !== undefined) {
      setDefaultEnabledForAssociatedMeasurements(result, associatedNames);
    }

    return result;
  });

  function toggleAssociatedMeasurement(name: string): void {
    const associatedMeasurement =
      associatedMeasurements.value[name as VelocityAssociatedMeasurementName];

    if (associatedMeasurement !== undefined) {
      associatedMeasurement.enabled.value = !associatedMeasurement.enabled.value;

      requestRedrawHandlers.forEach((fn) => fn());
    }
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const velocityUnitAndValue = measuredValue.value.getInternalValueAndUnit();

    if (measurementName.value === undefined || velocityUnitAndValue === null) {
      return {};
    }

    const mainMeasurementDetails = {
      ...baseMeasurementCreationRequest,
      ...velocityUnitAndValue,
      name: measurementName.value,
      customName: customName.value,
      frame: frame.value,
      contour: point,
    };

    return buildMeasurementToolBatchChangeRequest(
      mainMeasurementDetails,
      savedMeasurementBeingEdited.value,
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const editingMeasurementBatchId = ref<string | null>(null);
  const previouslySavedAssociatedMeasurements = ref<
    (MeasurementToolRecreationDetails & { measurementId: string })[]
  >([]);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    point.splice(0, point.length, ...value.contour);
    frame.value = value.frame ?? 0;
    isEditing.value = false;

    previouslySavedAssociatedMeasurements.value = findSavedAssociatedMeasurements(
      study,
      value,
      velocityAssociatedMeasurements
    );

    setAssociatedMeasurementDetailsOnEdit(
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([point], () => requestRedrawHandlers.forEach((fn) => fn()));

  return {
    displayName: "Velocity Measurement",
    studyClipId,
    region,
    measurementName,
    customName,
    customMeasurementName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled: isComplete,
    saveButtonTooltip,
    helpText,
    toolbarItems,

    interactivePoints: computed(() => point),
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),
    shouldPassMousedownAfterRegionSelection: true,
    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (newFrame: number) => (frame.value = newFrame),
    isChangeAllowedOf: (_target: "clip" | "frame") => point.length === 0,

    associatedMeasurements,
    toggleAssociatedMeasurement,
    getMeasurementChangeRequests,
    getCreatableMeasurementNames: getDrawableVelocityMeasurements,
    isMeasurableOnStudyClip: isVelocityMeasurableOnStudyClip,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function isVelocityMeasurableOnStudyClip(
  clip: StudyClip,
  region?: StudyClipRegion
): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some((r) => isRegionSpectral(r));
}

/**
 * Calculates an estimated pressure gradient from a velocity using the simplified Bernoulli
 * equation. Note that this is an **estimate** based on several assumptions about the nature of the
 * measured velocity, and is commonly used in clinical practice.
 *
 * See https://ecgwaves.com/topic/the-bernoulli-principle-and-calculation-of-pressure-difference-pressure-gradient/
 * for details on the derivation.
 */
export function calculateGradientFromVelocity(velocity: MeasuredValue): MeasuredValue {
  if (velocity.physicalQuantity !== MeasurementPhysicalQuantity.Velocity) {
    throw Error("Invalid physical quantity to calculate gradient with");
  }

  const velocityInMetersPerSecond = velocity.getValueAsUnit(MeasurementUnit.MetersPerSecond);

  return new MeasuredValue(
    MeasurementPhysicalQuantity.Pressure,
    velocityInMetersPerSecond !== null
      ? {
          value: 4 * velocityInMetersPerSecond ** 2,
          unit: MeasurementUnit.MillimetersOfMercury,
        }
      : null
  );
}
