<template>
  <AuthPage
    auth-button-visible
    :auth-button-text="isInvitationAccepted ? 'Sign In' : 'Accept Invitation'"
    :auth-button-enabled="isInvitationAccepted || isComplete"
    :auth-button-spinner="isAcceptingInvitation"
    @submit="onSubmit"
  >
    <template v-if="isInvitationAccepted">
      <div class="accepted-message">
        <FontAwesomeIcon icon="check" size="lg" />
        Invitation accepted
      </div>
    </template>
    <template v-else>
      <div v-if="isPasswordFieldVisible" class="instructions">
        <FontAwesomeIcon icon="circle-info" />
        Set a password to accept your invitation
      </div>

      <div class="field">
        <b>Email</b>
        <input v-model="email" disabled data-testid="email" />
      </div>

      <div v-if="isPasswordFieldVisible" class="field">
        <b>Password</b>
        <PasswordInput
          v-model="password"
          initial-focus
          show-mask-password-button
          @enter="onSubmit"
        />
        <PasswordStrengthCheck :password="password" />
      </div>
    </template>
  </AuthPage>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRouteQuery } from "@vueuse/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { isPasswordStrong } from "../../../backend/src/auth/password-strength-check";
import PasswordInput from "../components/PasswordInput.vue";
import router from "../router";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import AuthPage from "./AuthPage.vue";
import PasswordStrengthCheck from "./PasswordStrengthCheck.vue";
import { signOut } from "./authentication";

const userId = useRouteQuery("userId");
const email = useRouteQuery("email");
const token = useRouteQuery("token");
const setPassword = useRouteQuery("setPassword");

const password = ref("");

const isPasswordFieldVisible = computed(() => setPassword.value === "1");
const isAcceptingInvitation = ref(false);
const isInvitationAccepted = ref(false);

const isComplete = computed(
  () => !isPasswordFieldVisible.value || isPasswordStrong(password.value)
);

// Destroy any current session
onMounted(async () => signOut({ redirectToSignIn: false }));

async function onSubmit(): Promise<void> {
  if (isInvitationAccepted.value) {
    await router.push({ name: "sign-in" });
    return;
  }

  if (!isComplete.value) {
    return;
  }

  isAcceptingInvitation.value = true;

  try {
    await axios.post(`/api/users/${userId.value as string}/accept-invitation`, {
      token: token.value,
      password: password.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message:
        getRequestErrorMessage(error) ??
        "Failed accepting invitation, it may have expired or already been used",
    });
    return;
  } finally {
    isAcceptingInvitation.value = false;
  }

  isInvitationAccepted.value = true;
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    width: 100%;
    box-sizing: border-box;

    &:disabled {
      opacity: 0.85;
    }
  }
}

.instructions {
  background: var(--bg-color-3);
  border: 1px solid var(--border-color-1);
  padding: 8px;
  border-radius: var(--border-radius);
  font-weight: bold;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.accepted-message {
  font-weight: bold;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 16px auto;

  svg {
    color: var(--confirm-color-2);
  }
}
</style>
