import Flatten from "@flatten-js/core";
import {
  dopplerSlopeAssociatedMeasurements,
  efAssociatedMeasurements,
  velocityAssociatedMeasurements,
  volumeAssociatedMeasurements,
  vtiAssociatedMeasurements,
} from "../../../backend/src/measurements/associated-measurements";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import {
  isCustomMeasurement,
  measurementNames,
  type MeasurementName,
} from "../../../backend/src/measurements/measurement-names";
import { formatDateTime } from "../utils/date-time-utils";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "../utils/study-data";
import type { UserListEntry } from "../utils/users-list";
import type { MeasurementToolRecreationDetails } from "./tools/measurement-tool";

/**
 * Returns whether the given measurement is able to be indexed.
 */
export function isMeasurementIndexable(measurementName: MeasurementName): boolean {
  return measurementNames[measurementName].indexedName !== undefined;
}

/** The distance away from measurements to place their labels, in pixels */
export const MEASUREMENT_LABEL_OFFSET = 15;

/**
 * Returns the subset of the passed measurement names that match the given filter string. This tries
 * a couple of different methods for matching the measurement names to the filter string.
 */
export function filterMeasurementNames(
  names: MeasurementName[],
  filter: string
): MeasurementName[] {
  const tokens = filter
    .split(" ")
    .filter((token) => token.length !== 0)
    .map((token) => token.toLowerCase());

  if (tokens.length === 0) {
    return names.filter((m) => !isCustomMeasurement(m));
  }

  return names.filter((name) => {
    // Custom measurements are ignored
    if (isCustomMeasurement(name)) {
      return false;
    }

    for (const token of tokens) {
      // Try and match against the enum value directly as this has a lot of useful words in it
      // written out in full
      if (name.toLowerCase().includes(token)) {
        continue;
      }

      // Try and match against the display name of the measurement, which is typically an
      // abbreviated name more commonly used in clinical practice than the measurement enum name
      if (getMeasurementDisplayName(name, "unindexed").toLowerCase().includes(token)) {
        continue;
      }

      return false;
    }

    return true;
  });
}

/**
 * Given a measurement creation batch ID, returns the measurement value in that batch that was the
 * main measurement value, i.e. it was not an associated measurement.
 */
export function findMainMeasurementAndValueForBatch(
  study: Study,
  creationBatchId: string
): { measurement: StudyMeasurement; value: StudyMeasurementValue } | undefined {
  const valuesInBatch = study.measurements.flatMap((m) =>
    m.values
      .filter((v) => v.measurementCreationBatchId === creationBatchId)
      .map((v) => ({ ...v, name: m.name, measurementId: m.id }))
  );

  // If there's only one measurement in the batch, it is the main measurement.
  if (valuesInBatch.length === 1) {
    const measurement = study.measurements.find((m) => m.id === valuesInBatch[0].measurementId);
    if (measurement === undefined) {
      return undefined;
    }

    return { measurement, value: valuesInBatch[0] };
  }

  const associatedMeasurementSets = [
    efAssociatedMeasurements,
    volumeAssociatedMeasurements,
    vtiAssociatedMeasurements,
    dopplerSlopeAssociatedMeasurements,
    velocityAssociatedMeasurements,
  ] as Partial<Record<MeasurementName, Record<string, MeasurementName>>>[];

  const associatedMeasurementKeys = associatedMeasurementSets.map((s) => Object.keys(s));

  for (const keys of associatedMeasurementKeys) {
    const mainMeasurementValue = valuesInBatch.find((v) => keys.includes(v.name));

    const mainMeasurement = study.measurements.find(
      (m) => m.id === mainMeasurementValue?.measurementId
    );

    if (mainMeasurement && mainMeasurementValue) {
      return { measurement: mainMeasurement, value: mainMeasurementValue };
    }
  }

  // If there's multiple measurements in the batch and we can't find one in the batch that has a
  // measurement name that can create associated measurements, that means this measurement is an
  // orphaned associated measurement and can't be edited. These are possible in historical data, but
  // aren't possible moving forward.

  return undefined;
}

export function getValuesInBatch(study: Study, batchId: string): StudyMeasurementValue[] {
  return study.measurements
    .flatMap((m) => m.values)
    .filter((v) => v.measurementCreationBatchId === batchId);
}

/**
 * Find the saved associated measurements for a given study and main measurement value. This
 * looks in the main measurement's creation batch and matches these against the provided set of
 * potentially creatable associated measurements.
 */
export function findSavedAssociatedMeasurements(
  study: Study,
  mainMeasurementValue: MeasurementToolRecreationDetails,
  associatedMeasurementsToNameMap: Partial<Record<MeasurementName, Record<string, MeasurementName>>>
): (MeasurementToolRecreationDetails & { measurementId: string })[] {
  const associatedMeasurementsForThisName = Object.values(
    associatedMeasurementsToNameMap[mainMeasurementValue.measurementName] ?? {}
  );

  // Get all measurement values in the batch
  const measurementValueCandidates = study.measurements.flatMap((m) =>
    m.values
      .filter(
        (v) => v.measurementCreationBatchId === mainMeasurementValue.measurementCreationBatchId
      )
      .map((v) => ({ ...v, measurementName: m.name }))
  );

  return measurementValueCandidates.filter((v) =>
    Object.values(associatedMeasurementsForThisName).includes(v.measurementName)
  );
}

/**
 * Editing an EF measurement is only possible when both of its two associated volume measurements
 * are present.
 */
export function isEjectionFractionMeasurementWithoutAssociatedVolumes(
  study: Study,
  measurement: StudyMeasurement,
  value: StudyMeasurementValue
): boolean {
  const associatedMeasurements = efAssociatedMeasurements[measurement.name];
  if (associatedMeasurements === undefined) {
    return false;
  }

  const measurementsInBatch = getValuesInBatch(study, value.measurementCreationBatchId).map((v) =>
    study.measurements.find((m) => m.id === v.measurementId)
  );

  return !(
    measurementsInBatch.some((m) => m?.name === associatedMeasurements.endDiastolicVolume) &&
    measurementsInBatch.some((m) => m?.name === associatedMeasurements.endSystolicVolume)
  );
}

export function getLastUpdateTooltipTextForMeasurementValue(
  userList: UserListEntry[],
  value: StudyMeasurementValue
): string {
  const date = value.lastUpdatedAt ?? value.createdAt;
  const userId = value.lastUpdatedById ?? value.createdById;
  const startText = value.lastUpdatedById !== null ? "Last updated" : "Taken";

  return `${startText} ${formatDateTime(date, { includeTime: true })} by ${
    userList.find((c) => c.id === userId)?.name ?? "Unknown"
  }`;
}

/** Creates a Flatten.js polygon from the given set of points. */
export function createFlattenPolygon(points: number[]): Flatten.Polygon {
  const flattenPoints = [];
  for (let i = 0; i < points.length; i += 2) {
    flattenPoints.push(Flatten.point(points[i], points[i + 1]));
  }

  return new Flatten.Polygon(flattenPoints);
}
