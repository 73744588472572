<template>
  <div
    class="toggle-switch"
    :class="{ disabled: !enabled }"
    :data-test-toggle-state="value ? 'on' : 'off'"
    @click="value = !value"
  >
    <div class="switch" :class="{ on: value }">
      <div class="slider" :class="{ on: value }" />
    </div>
    <div v-if="slots.default" class="label">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from "vue";

const slots = useSlots();

interface Props {
  modelValue: boolean;
  enabled?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: boolean): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true });
const emits = defineEmits<Emits>();

const value = computed({
  get: () => props.modelValue,
  set: (event) => emits("update:modelValue", event),
});
</script>

<style scoped lang="scss">
.toggle-switch {
  height: 17px;
  min-height: 17px;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
  width: fit-content;

  &:hover {
    .label {
      color: var(--text-color-2);
    }

    .switch {
      filter: brightness(1);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: inherit;
  }
}

.switch {
  position: relative;
  width: 30px;
  background-color: var(--bg-color-4);
  border-radius: 15px;
  transition:
    background-color 0.4s ease,
    filter 200ms ease;
  filter: brightness(0.85);

  &.on {
    background-color: var(--confirm-color-1);
    filter: brightness(0.9);

    .slider {
      background-color: var(--confirm-color-2);
      transform: translateX(15px);
    }
  }
}

.slider {
  border-radius: 50%;
  position: absolute;
  top: 2px;
  bottom: 2px;
  width: 13px;
  left: 0;
  opacity: 0.9;
  background-color: var(--accent-color-1);
  transform: translateX(2px);
  transition:
    transform 0.4s ease,
    opacity 0.4s ease,
    background-color 0.4s ease;
}

.label {
  align-self: center;
  transition: color 0.4s ease;
}
</style>
