import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import type { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import { RWMAFields } from "../../../backend/src/reporting/report-content-rwma";
import type {
  ReportComponent,
  ReportSectionDropdownFieldStructure,
  ReportSectionStructure,
  ReportSectionStructuredField,
  ReportSectionType,
  ReportStructure,
  ReportTemplateFont,
  ReportTemplateHeaderTextAlignment,
} from "../../../backend/src/reporting/report-structure";
import { getDefaultFieldStyling } from "../../../backend/src/reporting/report-structure";

/**
 * Describes a mutation to perform on the structure of a report template. These mutations perform
 * tasks related to editing the sections, fields, and options present in a report template, and are
 * generated in the functions below.
 *
 * These mutations are then used as the basis for undo/redo steps in the report template editor.
 */
export interface ReportStructureMutation {
  /**
   * The title for this mutation to show in the undo/redo history. E.g. if this is "add section"
   * then the message displayed in the UI might be "Undo add section".
   */
  title: string;

  /**
   * The function that actually performs the described mutation on the passed report structure, e.g.
   * adds a section.
   */
  perform: (structure: ReportStructure) => void;

  /**
   * Key used to merge mutations in the undo/redo history where desirable. This is only used for
   * mutations that relate to input fields being typed in, to ensure that there aren't separate
   * entries in the undo/redo history for each character that's typed.
   */
  undoRedoMergeKey?: string;
}

export function createFontMutation(font: ReportTemplateFont): ReportStructureMutation {
  return {
    title: "update report font",
    perform: (structure: ReportStructure): void => {
      structure.font = font;
    },
  };
}

export function createFontSizeMutation(fontSizes: {
  title: number;
  header: number;
  footer: number;
  normal: number;
  heading: number;
  measurement: number;
  table: number;
}): ReportStructureMutation {
  return {
    title: "update report font sizes",
    perform: (structure: ReportStructure): void => {
      structure.fontSizes.title = fontSizes.title;
      structure.fontSizes.header = fontSizes.header;
      structure.fontSizes.footer = fontSizes.footer;
      structure.fontSizes.normal = fontSizes.normal;
      structure.fontSizes.heading = fontSizes.heading;
      structure.fontSizes.measurement = fontSizes.measurement;
      structure.fontSizes.table = fontSizes.table;
    },
  };
}

export function createMarginMutation(margins: {
  top: number;
  bottom: number;
  horizontal: number;
}): ReportStructureMutation {
  return {
    title: "update report margins",
    perform: (structure: ReportStructure): void => {
      structure.margins.top = margins.top;
      structure.margins.bottom = margins.bottom;
      structure.margins.horizontal = margins.horizontal;
    },
    undoRedoMergeKey: "report-margins",
  };
}

export function createSpacingMutation(spacing: {
  section: number;
  heading: number;
  field: number;
  table: number;
}): ReportStructureMutation {
  return {
    title: "update report spacing",
    perform: (structure: ReportStructure): void => {
      structure.spacing.section = spacing.section;
      structure.spacing.heading = spacing.heading;
      structure.spacing.field = spacing.field;
      structure.spacing.table = spacing.table;
    },
    undoRedoMergeKey: "report-spacing",
  };
}

export function createTitleMutation(): ReportStructureMutation {
  return {
    title: "update report title",
    perform: () => null,
    undoRedoMergeKey: "report-title",
  };
}

export function createHeaderTextMutation(): ReportStructureMutation {
  return {
    title: "update header text",
    perform: () => null,
    undoRedoMergeKey: "report-header-text",
  };
}

export function createHeaderTextAlignmentMutation(
  alignment: ReportTemplateHeaderTextAlignment
): ReportStructureMutation {
  return {
    title: "update header text alignment",
    perform: (structure: ReportStructure): void => {
      structure.headerTextAlignment = alignment;
    },
  };
}

export function createFooterTextMutation(): ReportStructureMutation {
  return {
    title: "update footer text",
    perform: () => null,
    undoRedoMergeKey: "report-footer-text",
  };
}

export function createLogoMutation(): ReportStructureMutation {
  return {
    title: "update report logo",
    perform: () => null,
  };
}

export function createLayoutMutation(): ReportStructureMutation {
  return {
    title: "update report layout",
    perform: () => null,
  };
}

export function createLayoutComponentMoveMutation(
  oldIndex: number,
  newIndex: number
): ReportStructureMutation {
  return {
    title: "move report component",
    perform: (structure: ReportStructure): void => {
      const [component] = structure.layout.components.splice(oldIndex, 1);
      structure.layout.components.splice(newIndex, 0, component);
    },
  };
}

export function createLayoutComponentVisibilityToggleMutation(
  name: ReportComponent
): ReportStructureMutation {
  return {
    title: "toggle report component visibility",
    perform: (structure: ReportStructure): void => {
      const component = structure.layout.components.find((c) => c.name === name);
      if (component !== undefined) {
        component.isVisible = !component.isVisible;
      }
    },
  };
}

export function createSectionAddMutation(
  index: number,
  type: ReportSectionType
): ReportStructureMutation {
  return {
    title: `add ${type}`,

    perform(structure: ReportStructure): void {
      structure.sections.splice(index, 0, {
        id: uuidv4(),
        name: "",
        type,
        isCommentFieldVisible: type !== "rwma",
        commentFieldDefault: "",
        isFieldCompactionEnabled: type !== "table",
        isFormattingEnabled: true,
        sentenceGroupIds: [],
        structuredFieldColumns: type !== "rwma" ? [[], []] : [RWMAFields, []],
      });
    },
  };
}

export function createSectionRemoveMutation(sectionId: string): ReportStructureMutation {
  return {
    title: "remove section",

    perform(structure: ReportStructure): void {
      const index = structure.sections.findIndex((section) => section.id === sectionId);
      if (index === -1) {
        throw Error(`No section with id '${sectionId}'`);
      }

      structure.sections.splice(index, 1);
    },
  };
}

export function createSectionDuplicateMutation(sectionId: string): ReportStructureMutation {
  return {
    title: "duplicate section",

    perform(structure: ReportStructure): void {
      const index = structure.sections.findIndex((section) => section.id === sectionId);
      if (index === -1) {
        throw Error(`No section with id '${sectionId}'`);
      }

      structure.sections.splice(index + 1, 0, {
        ...cloneDeep(structure.sections[index]),
        id: uuidv4(),
      });
    },
  };
}

export function createSectionMoveMutation(
  oldIndex: number,
  newIndex: number
): ReportStructureMutation {
  return {
    title: "move section",

    perform(structure: ReportStructure): void {
      const [section] = structure.sections.splice(oldIndex, 1);
      structure.sections.splice(newIndex, 0, section);
    },
  };
}

export function createSectionNameChangeMutation(sectionId: string): ReportStructureMutation {
  return {
    title: "update section name",
    perform: () => null,
    undoRedoMergeKey: `section-name-${sectionId}`,
  };
}

export function createSectionSentenceDefaultTextMutation(
  sectionId: string
): ReportStructureMutation {
  return {
    title: "update section sentence default text",
    perform: () => null,
    undoRedoMergeKey: `section-comment-default-${sectionId}`,
  };
}

export function createSectionCommentFieldVisibleToggleMutation(
  sectionId: string
): ReportStructureMutation {
  return {
    title: "toggle section comment field visibility",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);
      section.isCommentFieldVisible = !section.isCommentFieldVisible;
    },
  };
}

export function createSectionFieldCompactionEnabledToggleMutation(
  sectionId: string
): ReportStructureMutation {
  return {
    title: "toggle field compaction",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);
      section.isFieldCompactionEnabled = !section.isFieldCompactionEnabled;
    },
  };
}

export function createSectionFieldFormattingEnabledToggleMutation(
  sectionId: string
): ReportStructureMutation {
  return {
    title: "toggle table formatting",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);
      section.isFormattingEnabled = !section.isFormattingEnabled;
    },
  };
}

export function createSectionColumnAddMutation(sectionId: string): ReportStructureMutation {
  return {
    title: "add column to section",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);
      section.structuredFieldColumns.push([]);
    },
  };
}

export function createSectionColumnRemoveMutation(
  sectionId: string,
  columnIndex: number
): ReportStructureMutation {
  return {
    title: "remove column from section",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);
      section.structuredFieldColumns.splice(columnIndex, 1);
    },
  };
}

export function createFieldAddMutation(
  sectionId: string,
  columnIndex: number,
  onFieldAdded: (newFieldId: string) => void
): ReportStructureMutation {
  return {
    title: "add field",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      const newFieldId = uuidv4();

      const newField: ReportSectionStructuredField = {
        id: newFieldId,
        name: "",
        type: "text",
        text: "",
        isEditable: true,
        styling: getDefaultFieldStyling(),
      };

      if (section.type === "table") {
        newField.cellAlignment = { horizontal: "left", vertical: "center" };
      }

      section.structuredFieldColumns[columnIndex].push(newField);

      onFieldAdded(newFieldId);
    },
  };
}

export function createFieldRemoveMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "remove field",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      for (const column of section.structuredFieldColumns) {
        const index = column.findIndex((field) => field.id === fieldId);
        if (index === -1) {
          continue;
        }

        column.splice(index, 1);

        break;
      }
    },
  };
}

export function createFieldDuplicateMutation(
  sectionId: string,
  fieldId: string,
  onFieldAdded: (newFieldId: string) => void
): ReportStructureMutation {
  return {
    title: "duplicate field",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      for (const column of section.structuredFieldColumns) {
        const index = column.findIndex((field) => field.id === fieldId);
        if (index === -1) {
          continue;
        }

        const newFieldId = uuidv4();

        column.splice(index + 1, 0, { ...cloneDeep(column[index]), id: newFieldId });
        onFieldAdded(newFieldId);
      }
    },
  };
}

export function createFieldMoveMutation(
  sectionId: string,
  fromColumnIndex: number,
  toColumnIndex: number,
  fromFieldIndex: number,
  toFieldIndex: number
): ReportStructureMutation {
  return {
    title: "move field",

    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      const fromColumn = section.structuredFieldColumns[fromColumnIndex];
      const toColumn = section.structuredFieldColumns[toColumnIndex];

      const [field] = fromColumn.splice(fromFieldIndex, 1);
      toColumn.splice(toFieldIndex, 0, field);
    },
  };
}

export function createFieldNameMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "update field title",
    perform: () => null,
    undoRedoMergeKey: `field-name-${sectionId}-${fieldId}`,
  };
}

export function createFieldTypeMutation(
  sectionId: string,
  fieldId: string,
  newField: ReportSectionStructuredField
): ReportStructureMutation {
  return {
    title: "change field type",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      // Remove all properties from the field as they're all going to be replaced when setting the
      // new field type
      for (const property of Object.keys(field)) {
        delete (field as Record<string, unknown>)[property];
      }

      Object.assign(field, newField);

      if (field.type === "dropdown") {
        updateDropdownFieldEmptyOption(field);
      }
    },
  };
}

export function createTextMutation(
  sectionId: string,
  fieldId: string,
  newText: string
): ReportStructureMutation {
  return {
    title: "change field text",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);
      if (field.type === "text") {
        field.text = newText;
      }
    },
    undoRedoMergeKey: `field-text-${sectionId}-${fieldId}`,
  };
}

export function createMeasurementMutation(
  sectionId: string,
  fieldId: string,
  name: MeasurementName,
  isIndexed: boolean
): ReportStructureMutation {
  return {
    title: "select measurement",

    perform: (structure: ReportStructure): void => {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "measurement") {
        // Automatically set the name of this field to the display name of the selected measurement
        field.name = getMeasurementDisplayName(name, isIndexed ? "indexed" : "unindexed");

        field.measurementName = name;
        field.isIndexed = isIndexed;
      }
    },
  };
}

export function createOptionAddMutation(
  sectionId: string,
  fieldId: string,
  index: number,
  onOptionAdded: (newOption: { id: string; name: string }) => void
): ReportStructureMutation {
  return {
    title: "add option",

    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "dropdown") {
        const newOption = { id: uuidv4(), name: "", sentence: "" };
        field.options.splice(index, 0, newOption);

        onOptionAdded(newOption);
      }
    },
  };
}

export function createOptionRemoveMutation(
  sectionId: string,
  fieldId: string,
  optionId: string
): ReportStructureMutation {
  return {
    title: "add option",

    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "dropdown") {
        const index = field.options.findIndex((option) => option.id === optionId);
        if (index === -1) {
          throw Error(`No option with id '${optionId}'`);
        }

        field.options.splice(index, 1);
      }
    },
  };
}

export function createOptionMoveMutation(
  sectionId: string,
  fieldId: string,
  oldIndex: number,
  newIndex: number
): ReportStructureMutation {
  return {
    title: "move option",

    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "dropdown") {
        const [option] = field.options.splice(oldIndex, 1);
        field.options.splice(newIndex, 0, option);
      }
    },
  };
}

export function createOptionNameMutation(
  sectionId: string,
  fieldId: string,
  optionId: string
): ReportStructureMutation {
  return {
    title: "update option title",
    perform: () => null,
    undoRedoMergeKey: `option-name-${sectionId}-${fieldId}-${optionId}`,
  };
}

export function createDropdownSentenceToggleMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "toggle sentence",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "dropdown") {
        field.isSentence = !field.isSentence;
      }
    },
  };
}

export function createDropdownMultiSelectToggleMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "toggle multiselect",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "dropdown") {
        field.isMultiSelect = !field.isMultiSelect;

        updateDropdownFieldEmptyOption(field);
      }
    },
  };
}

export function createEditableToggleMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "toggle editable",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.type === "text" || field.type === "measurement") {
        field.isEditable = !field.isEditable;
      }
    },
  };
}

export function createTableHorizontalAlignmentMutation(
  sectionId: string,
  fieldId: string,
  alignment: "center" | "left" | "right"
): ReportStructureMutation {
  return {
    title: "change horizontal alignment",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.cellAlignment !== undefined) {
        field.cellAlignment.horizontal = alignment;
      }
    },
  };
}

export function createTableVerticalAlignmentMutation(
  sectionId: string,
  fieldId: string,
  alignment: "bottom" | "center" | "top"
): ReportStructureMutation {
  return {
    title: "change vertical alignment",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);

      if (field.cellAlignment !== undefined) {
        field.cellAlignment.vertical = alignment;
      }
    },
  };
}

export function createFieldBoldToggleMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "toggle field bold",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);
      field.styling.bold = !field.styling.bold;
    },
  };
}

export function createFieldItalicToggleMutation(
  sectionId: string,
  fieldId: string
): ReportStructureMutation {
  return {
    title: "toggle field italic",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);
      field.styling.italic = !field.styling.italic;
    },
  };
}

export function createFieldColorMutation(
  sectionId: string,
  fieldId: string,
  color: string
): ReportStructureMutation {
  return {
    title: "change field color",
    perform(structure: ReportStructure): void {
      const field = getField(structure, sectionId, fieldId);
      field.styling.color = color;
    },
  };
}

export function createSentenceGroupIdToggleMutation(
  sectionId: string,
  sentenceGroupId: string
): ReportStructureMutation {
  return {
    title: "change recommended sentence groups",
    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      if (section.sentenceGroupIds.includes(sentenceGroupId)) {
        section.sentenceGroupIds.splice(section.sentenceGroupIds.indexOf(sentenceGroupId), 1);
      } else {
        section.sentenceGroupIds.push(sentenceGroupId);
      }
    },
  };
}

export function createSentenceGroupReorderMutation(
  sectionId: string,
  oldIndex: number,
  newIndex: number
): ReportStructureMutation {
  return {
    title: "change recommended sentence group ordering",
    perform(structure: ReportStructure): void {
      const section = getSection(structure, sectionId);

      const [group] = section.sentenceGroupIds.splice(oldIndex, 1);
      section.sentenceGroupIds.splice(newIndex, 0, group);
    },
  };
}

function updateDropdownFieldEmptyOption(field: ReportSectionDropdownFieldStructure): void {
  // Ensure that single-select dropdowns have the empty "" option as the first option
  if (!field.isMultiSelect && field.options[0]?.id !== "") {
    field.options.unshift({ id: "", name: "", sentence: "" });
  }

  // Ensure that multi-select dropdowns don't have the empty "" as a first option because it's
  // not needed (the user can just select none of the options when multi-select is on)
  if (field.isMultiSelect && field.options[0]?.id === "") {
    field.options.splice(0, 1);
  }
}

export function createOptionSentenceMutation(
  sectionId: string,
  fieldId: string,
  optionId: string
): ReportStructureMutation {
  return {
    title: "update option sentence",
    perform: () => null,
    undoRedoMergeKey: `option-sentence-${sectionId}-${fieldId}-${optionId}`,
  };
}

export function createMeasurementGroupToggleMeasurementVisibilityMutation(
  name: MeasurementName,
  isIndexed: boolean
): ReportStructureMutation {
  return {
    title: "toggle measurement visibility",
    perform(structure: ReportStructure): void {
      const measurements = structure.measurementGroups.map((g) => g.columns).flat(2);
      const measurement = measurements.find((m) => m.name === name);
      if (measurement === undefined) {
        return;
      }

      if (isIndexed) {
        measurement.isIndexedVisible = !measurement.isIndexedVisible;
      } else {
        measurement.isUnindexedVisible = !measurement.isUnindexedVisible;
      }
    },
  };
}

function getSection(structure: ReportStructure, sectionId: string): ReportSectionStructure {
  const section = structure.sections.find((s) => s.id === sectionId);
  if (section === undefined) {
    throw Error(`No section with id '${sectionId}'`);
  }

  return section;
}

function getField(
  structure: ReportStructure,
  sectionId: string,
  fieldId: string
): ReportSectionStructuredField {
  const section = getSection(structure, sectionId);

  const field = section.structuredFieldColumns.flat().find((f) => f.id === fieldId);
  if (field === undefined) {
    throw Error(`No field with id '${fieldId}'`);
  }

  return field;
}
