<template>
  <Popper
    class="measurement-calculation-value-selection-popper"
    placement="right-end"
    :offset-distance="2"
    :interactive="false"
    :disabled="!isEnabled"
    @open="isPopperOpen = true"
    @close="isPopperOpen = false"
  >
    <div
      :data-testid="`calculation-value-selection-${measurement?.name}`"
      class="selected-value"
      :class="{ 'popper-open': isPopperOpen, disabled: !isEnabled }"
    >
      <template v-if="isEnabled">
        {{
          selectedMeasurementValue === null && !doesCalculationContainAdvancedFormula(formula)
            ? meanValue
            : formatValue(selectedMeasurementValue)
        }}
        <FontAwesomeIcon style="font-size: 0.8em" icon="chevron-down" />
      </template>
      <template v-else>No values</template>
    </div>

    <template #content>
      <div class="values-grid">
        <div
          v-for="value in measurement?.values.filter(
            (v) => !doesCalculationContainAdvancedFormula(formula) || v.contour !== null
          ) ?? []"
          :key="value.id"
          class="value-row"
          :class="{ selected: value === selectedMeasurementValue }"
          :data-testid="`calculation-value-${formatValue(value)}`"
          @click="onSelectValue(value)"
        >
          <div class="icon-container">
            <FontAwesomeIcon
              :style="{ visibility: value === selectedMeasurementValue ? 'visible' : 'hidden' }"
              icon="check"
            />
          </div>

          <div class="value-container">
            {{ formatValue(value) }} {{ getUnitDisplayText(props.variableUnit) }}
          </div>

          <div class="badge-container">
            <MeasurementBadge :source="value.source" />
          </div>
        </div>

        <template v-if="!doesCalculationContainAdvancedFormula(formula)">
          <div class="divider" />

          <div
            class="value-row mean"
            :class="{ selected: selectedMeasurementValue === null }"
            :data-testid="`calculation-mean-input-${meanValue}`"
            @click="emits('select-value', null)"
          >
            <div class="icon-container">
              <FontAwesomeIcon
                :style="{ visibility: selectedMeasurementValue === null ? 'visible' : 'hidden' }"
                icon="check"
              />
            </div>

            <div class="value-container">
              {{ meanValue }} {{ getUnitDisplayText(props.variableUnit) }}
            </div>

            <div class="badge-container">
              <MeasurementBadge source="mean" force-default-formatting />
            </div>
          </div>
        </template>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, watch } from "vue";
import { doesCalculationContainAdvancedFormula } from "../../../../backend/src/measurements/measurement-calculation-evaluation";
import { getMeasurementMeanValue } from "../../../../backend/src/measurements/measurement-display";
import {
  MeasurementUnit,
  convertMeasurementUnit,
  getInternalUnitForStudyMeasurement,
  getUnitDisplayText,
} from "../../../../backend/src/measurements/measurement-units";
import { Study, StudyMeasurement, StudyMeasurementValue } from "../../utils/study-data";
import MeasurementBadge from "../MeasurementBadge.vue";

interface Props {
  study: Study;
  measurement: StudyMeasurement | undefined;
  formula: string;
  selectedMeasurementValue: StudyMeasurementValue | null;
  variableUnit: MeasurementUnit;
}

interface Emits {
  (event: "select-value", value: StudyMeasurementValue | null): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isPopperOpen = ref(false);

const isEnabled = computed(() => (props.measurement?.values.length ?? 0) !== 0);

const meanValue = computed(() =>
  props.measurement
    ? convertMeasurementUnit(
        getMeasurementMeanValue(props.measurement),
        getInternalUnitForStudyMeasurement(props.measurement),
        props.variableUnit
      )?.toPrecision(3) ?? "—"
    : "—"
);

function formatValue(value: StudyMeasurementValue | null) {
  let formattedValue = "";

  if (props.measurement !== undefined && value !== null && value.value !== null) {
    const convertedValue = convertMeasurementUnit(
      value.value,
      getInternalUnitForStudyMeasurement(props.measurement),
      props.variableUnit
    );

    formattedValue = convertedValue?.toPrecision(3) ?? "";
  }

  return formattedValue;
}

function onSelectValue(value: StudyMeasurementValue | null) {
  emits("select-value", props.selectedMeasurementValue === value ? null : value);
}

// Handle the case where a measurement value is selected, but then the measurement is deleted or
// toggled off in the measurements pane.
watch(
  () => props.measurement,
  () => {
    if (
      props.selectedMeasurementValue !== null &&
      (props.measurement === undefined ||
        !props.measurement.values.includes(props.selectedMeasurementValue))
    ) {
      emits("select-value", null);
    }
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
:deep(.measurement-calculation-value-selection-popper) {
  width: max-content;
  background-color: var(--bg-color-2);
  padding: 0;
}

.selected-value {
  height: 22px;
  padding: 0 4px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-1);

  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  line-height: 1em;

  transition:
    background-color 100ms ease,
    color 100ms ease;

  &.popper-open,
  &:hover {
    background-color: var(--bg-color-3);
    color: var(--text-color-2);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.values-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  line-height: 1em;
  align-items: stretch;
}

.value-row {
  cursor: pointer;
  display: contents;

  > * {
    display: flex;
    align-items: center;
    padding-right: 8px;

    transition:
      filter 100ms ease,
      background-color 100ms ease;
  }

  &:hover > * {
    filter: brightness(125%);
    background-color: var(--bg-color-3);
  }

  .icon-container {
    padding-left: 8px;
  }

  .value-container {
    justify-content: flex-end;
  }

  .badge-container {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &:first-child > .badge-container {
    padding-top: 4px;
  }

  &:nth-last-child(3) > .badge-container {
    padding-bottom: 4px;
  }

  &.mean .badge-container {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.divider {
  grid-column: 1 / span 3;
  height: 1px;
  background-color: var(--border-color-1);
}
</style>
