<template>
  <div class="settings-title">Advanced Settings</div>

  <div class="settings-group-title">Reporting</div>

  <div class="field">
    <div class="toggle">
      <b>Require Reason When Amending Reports</b>
      <ToggleSwitch
        v-model="currentTenant.isAmendmentReasonRequired"
        data-testid="is-amendment-reason-required-toggle"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether a reason must be given in order to sign a report amendment. If this is disabled then a
      reason for the amendment can still be entered, but is not mandatory.
    </p>
  </div>

  <div class="settings-group-title">Measurements</div>

  <div class="field">
    <div class="toggle">
      <b>Select Externally Created Measurement Values By Default</b>
      <ToggleSwitch
        v-model="currentTenant.isExternallyCreatedMeasurementValueSelectedByDefault"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether a measurement value created by an external integration using an API key that doesn't
      explicitly specify its initial selected state should be selected by default.
    </p>
  </div>

  <div class="settings-group-title">Study Assignment</div>

  <div class="field">
    <div class="toggle">
      <b>Require Assignee</b>
      <ToggleSwitch
        v-model="currentTenant.isStudyAssigneeRequired"
        data-testid="is-study-assignee-required-toggle"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether it is mandatory that studies have an assignee. Turning this on removes the 'Not
      assigned' option when selecting the assignee for a study, but does not affect any existing
      studies that don't have an assignee.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Default Assignee</b>
    </div>

    <p>
      The user to set as the assignee for new studies if automatic assignment based on the study's
      <i>"PerformingPhysicianName"</i> or <i>"OperatorsName"</i> DICOM tags does not occur.
    </p>

    <UserDropdown
      v-model="defaultStudyAssignedUserId"
      allow-empty
      empty-text="None"
      class="user-dropdown"
    />
  </div>

  <div class="field">
    <div class="toggle">
      <b>Clear Study Assignee When Final Report Completed</b>
      <ToggleSwitch
        v-model="currentTenant.isStudyAssigneeClearedWhenFinalReportCompleted"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether a study's assignee should be automatically cleared when its final report is signed.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Email Sending Enabled</b>
      <ToggleSwitch
        v-model="currentTenant.isEmailSendingEnabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether users should be notified by email when they are assigned studies or have been assigned
      studies recently that they haven't yet reported.
    </p>
  </div>

  <div class="settings-group-title">Security</div>

  <div class="field">
    <b>Maximum Session Age</b>
    <p>
      The maximum length of time that a user can stay signed in without having to re-authenticate.
    </p>
    <DropdownWidget
      :model-value="currentTenant.sessionMaximumAge.toString()"
      :items="sessionTimePeriods.filter((item) => Number(item.value) >= 60 * 60)"
      style="width: 316px"
      @update:model-value="
        currentTenant.sessionMaximumAge = Number($event);
        updateCurrentTenant();
      "
    />
  </div>

  <div class="field">
    <b>Session Inactivity Timeout</b>
    <p>
      The time period after which an inactive user's session will expire and they will be
      automatically signed out.
    </p>
    <DropdownWidget
      :model-value="currentTenant.sessionInactivityExpirationTime.toString()"
      :items="sessionTimePeriods"
      style="width: 316px"
      @update:model-value="
        currentTenant.sessionInactivityExpirationTime = Number($event);
        updateCurrentTenant();
      "
    />
  </div>

  <div class="field">
    <div class="toggle">
      <b>Study Sharing Enabled</b>
      <ToggleSwitch
        v-model="currentTenant.isStudySharingEnabled"
        data-testid="study-sharing-enabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether users can share studies with external people via share links and emails. Shared
      studies are accessed via a secure link and require a 6-digit passcode. Share links expire
      after 7 days.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>User API Keys Enabled</b>
      <ToggleSwitch
        v-model="currentTenant.isApiKeyAccessEnabled"
        data-testid="api-key-access-enabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether users can have API key access enabled on their accounts so that they can access
      patient and study data programmatically. To use API keys, this setting must be enabled and
      each user must also have API keys enabled on their user account.
    </p>
  </div>

  <div class="settings-group-title">Other</div>

  <div class="field">
    <div style="display: flex; gap: 16px; align-items: center">
      <b>Verbose DICOM Logging</b>
      <ToggleSwitch
        v-model="currentTenant.isDicomVerboseLoggingEnabled"
        @update:model-value="updateCurrentTenant"
      />
    </div>

    <p>
      Whether to enable verbose logging of DICOM traffic in the audit logs. This is useful for
      troubleshooting as it causes C-ECHO requests to be logged, and adds details about associations
      accepted and released, transfer speeds, any TLS handshake, and other usage statistics.
    </p>
  </div>

  <div class="field">
    <strong>HL7 Message Format</strong>
    <p>The format to use for HL7 messages that are sent or downloaded.</p>

    <DropdownWidget
      v-model="currentTenant.hl7MessageFormat"
      :items="[
        { value: HL7MessageFormat.NewZealand, text: 'New Zealand (REF)' },
        { value: HL7MessageFormat.Australia, text: 'Australia (ORU)' },
      ]"
      style="width: 316px"
      @update:model-value="updateCurrentTenant"
    />
  </div>

  <div class="field">
    <strong>Patient ID Label</strong>
    <p>
      The label to give the patient ID field. Set this appropriately for your health system and
      jurisdiction. The default is "<i>Patient ID</i>".
    </p>
    <input
      v-model="currentTenant.patientIdLabel"
      maxlength="100"
      @update:model-value="updateCurrentTenantDebounced"
    />
  </div>

  <div class="field">
    <strong>Patient Name Format</strong>
    <p>How to format patient names for display.</p>

    <DropdownWidget
      v-model="currentTenant.patientNameFormat"
      :items="[
        { value: PatientNameFormat.FirstNameMiddleNameLastName, text: 'First Middle Last' },
        { value: PatientNameFormat.LastNameFirstNameMiddleName, text: 'Last, First Middle' },
      ]"
      style="width: 316px"
      data-testid="patient-name-format-dropdown"
      @update:model-value="updateCurrentTenant"
    />
  </div>

  <div class="field">
    <strong>Ethnicity Options </strong>
    <p>
      Choose one of the provided lists of ethnicities, or configure a custom list below. This list
      is shown as part of the patient ethnicity field. An ethnicity of "-" will be shown as a
      horizontal divider in that list.
    </p>
    <DropdownWidget
      v-model="selectedEthnicityGroup"
      :items="[
        { value: 'au', text: 'Australia' },
        { value: 'nz', text: 'New Zealand' },
        { value: 'usa', text: 'US' },
        { value: 'custom', text: 'Custom' },
      ]"
      style="width: 316px; margin-bottom: 16px"
      @update:model-value="onSelectEthinicityGroup"
    />

    <div v-if="selectedEthnicityGroup === 'custom'" class="ethnicity-input">
      <input v-model="ethnicityOptionText" placeholder="New ethnicity option" maxlength="16" />
      <button :disabled="ethnicityOptionText.trim().length === 0" @click="onAddEthnicityOption">
        <FontAwesomeIcon icon="plus" />
      </button>
    </div>

    <div
      ref="ethnicityList"
      class="ethnicity-list"
      :class="{ disabled: selectedEthnicityGroup !== 'custom' }"
    >
      <DraggableList @reorder="onReorderEthnicities">
        <div
          v-for="(ethnicity, index) in currentTenant.ethnicityOptions"
          :key="ethnicity"
          class="ethnicity-item drag-handle"
        >
          {{ ethnicity }}
          <FontAwesomeIcon
            v-if="selectedEthnicityGroup === 'custom'"
            icon="times"
            style="margin-left: auto"
            @click="
              currentTenant.ethnicityOptions.splice(index, 1);
              updateCurrentTenant();
            "
          />
        </div>
      </DraggableList>
    </div>
  </div>
</template>

<script setup lang="ts">
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { isEqual } from "lodash";
import type { SortableEvent } from "sortablejs";
import { computed, onMounted, ref } from "vue";
import { HL7MessageFormat } from "../../../backend/src/integrations/hl7/hl7-message-format";
import { PatientNameFormat } from "../../../backend/src/tenants/patient-name-format";
import { currentTenant } from "../auth/current-session";
import DraggableList from "../components/DraggableList.vue";
import DropdownWidget from "../components/DropdownWidget.vue";
import UserDropdown from "../components/UserDropdown.vue";

type CountryOption = "au" | "custom" | "nz" | "usa";

const ethnicityOptionText = ref("");
const ethnicityList = ref<HTMLElement>();
const selectedEthnicityGroup = ref<CountryOption>("custom");

const defaultStudyAssignedUserId = computed({
  get() {
    return currentTenant.defaultStudyAssignedUserId ?? "";
  },
  set(newValue: string) {
    currentTenant.defaultStudyAssignedUserId = newValue === "" ? null : newValue;

    void updateCurrentTenant();
  },
});

const sessionTimePeriods = computed(() => [
  ...[1, 2, 5, 10, 15, 30].map((minutes) => ({
    value: (minutes * 60).toString(),
    text: minutes === 1 ? "1 minute" : `${minutes} minutes`,
  })),
  ...[1, 2, 4, 8, 12, 24].map((hours) => ({
    value: (hours * 60 * 60).toString(),
    text: hours === 1 ? "1 hour" : `${hours} hours`,
  })),
]);

onMounted(() => {
  // Determine which country option is in use, if any
  for (const [key, value] of Object.entries(countryEthnicities)) {
    if (isEqual(value, currentTenant.ethnicityOptions)) {
      selectedEthnicityGroup.value = key as CountryOption;
    }
  }
});

function onReorderEthnicities(event: SortableEvent): void {
  if (event.oldIndex === undefined || event.newIndex === undefined) {
    return;
  }

  const [item] = currentTenant.ethnicityOptions.splice(event.oldIndex, 1);
  currentTenant.ethnicityOptions.splice(event.newIndex, 0, item);

  void updateCurrentTenant();
}

async function updateCurrentTenant(): Promise<void> {
  try {
    await axios.patch(`/api/tenants/current`, {
      patientIdLabel: currentTenant.patientIdLabel,
      ethnicityOptions: currentTenant.ethnicityOptions,
      patientNameFormat: currentTenant.patientNameFormat,
      isStudySharingEnabled: currentTenant.isStudySharingEnabled,
      isApiKeyAccessEnabled: currentTenant.isApiKeyAccessEnabled,
      isEmailSendingEnabled: currentTenant.isEmailSendingEnabled,
      isStudyAssigneeRequired: currentTenant.isStudyAssigneeRequired,
      defaultStudyAssignedUserId: currentTenant.defaultStudyAssignedUserId,
      isAmendmentReasonRequired: currentTenant.isAmendmentReasonRequired,
      hl7MessageFormat: currentTenant.hl7MessageFormat,
      sessionMaximumAge: currentTenant.sessionMaximumAge,
      sessionInactivityExpirationTime: currentTenant.sessionInactivityExpirationTime,
      isDicomVerboseLoggingEnabled: currentTenant.isDicomVerboseLoggingEnabled,
      isExternallyCreatedMeasurementValueSelectedByDefault:
        currentTenant.isExternallyCreatedMeasurementValueSelectedByDefault,
      isStudyAssigneeClearedWhenFinalReportCompleted:
        currentTenant.isStudyAssigneeClearedWhenFinalReportCompleted,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating advanced settings" });
    return;
  }

  addNotification({ type: "info", message: "Updated advanced settings" });
}

const updateCurrentTenantDebounced = useDebounceFn(() => {
  void updateCurrentTenant();
}, 1000);

async function onAddEthnicityOption(): Promise<void> {
  currentTenant.ethnicityOptions.unshift(ethnicityOptionText.value);
  ethnicityOptionText.value = "";

  await updateCurrentTenant();
}

async function onSelectEthinicityGroup(): Promise<void> {
  if (selectedEthnicityGroup.value !== "custom") {
    currentTenant.ethnicityOptions = countryEthnicities[selectedEthnicityGroup.value];
  }

  await updateCurrentTenant();
}

const countryEthnicities: Record<CountryOption, string[]> = {
  au: [
    "English",
    "Australian",
    "Irish",
    "Scottish",
    "Italian",
    "German",
    "Chinese",
    "Indian",
    "Greek",
    "Dutch",
    "Aboriginal",
    "Other",
  ],
  nz: [
    "European",
    "Māori",
    "Pacific Peoples",
    "Asian",
    "Middle Eastern",
    "Latin American",
    "African",
    "Other",
  ],
  usa: [
    "American Indian",
    "Alaska Native",
    "Asian",
    "African American",
    "European",
    "Hispanic/Latino",
    "Middle Eastern",
    "North African",
    "Native Hawaiian",
    "Pacific Islander",
    "White",
    "Other",
  ],
  custom: [],
};
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

.ethnicity-list {
  max-height: 400px;
  width: 316px;
  overflow-y: auto;
  margin: 4px 0;

  &.disabled {
    opacity: 0.7;

    .ethnicity-item {
      pointer-events: none;
    }
  }
}

.ethnicity-item {
  display: flex;
  cursor: pointer;
  transition: color 100ms ease;
  border: 1px solid var(--bg-color-4);

  padding: 8px;
  margin: 0 10px 8px 0;
  border-radius: var(--border-radius);

  &:hover {
    color: var(--text-color-2);
  }
}

.ethnicity-input {
  display: flex;
  gap: 8px;
}

input {
  width: 300px;
}

.toggle {
  display: flex;
  gap: 16px;
  align-items: center;
}

.user-dropdown {
  max-width: 250px;
}
</style>
