import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import {
  onPrimaryWindowMessageReceived,
  postMessageToPrimaryWindow,
} from "../study-view/multi-window/primary-window-messages";
import {
  onSecondaryWindowMessageReceived,
  postMessageToSecondaryWindow,
} from "../study-view/multi-window/secondary-window-messages";

interface Notification {
  id: string;

  type: "error" | "info";
  message: string;

  timeout?: number;

  // For internal use, the id of the timer that will hide the notification
  hideTimer?: number;
}

export const notifications = ref<Notification[]>([]);

export function addNotification({
  type,
  message,
  id,
  timeout,
}: {
  type: "error" | "info";
  message: string;
  id?: string;
  timeout?: number;
}): string {
  const notification: Notification = { id: id ?? uuidv4(), type, message, timeout };

  if (notification.type === "info") {
    notification.timeout ??= 3000;
  } else {
    notification.timeout ??= 10000;
  }

  // Tell any other windows, primary or secondary, to add the notification too
  if (id === undefined) {
    postMessageToPrimaryWindow({ type: "notification-added", notification });
    postMessageToSecondaryWindow({ type: "notification-added", notification });
  }

  // If this notificaiton exactly matches the content of an existing notification then reset the
  // hideTimer for that existing notifcation and don't add a new one
  const existingNotification = notifications.value.find(
    (n) => n.type === notification.type && n.message === notification.message
  );
  if (existingNotification) {
    clearTimeout(existingNotification.hideTimer);
  } else {
    notifications.value.push(notification);
  }

  // Get rid of the notification after the timeout has passed
  const n = existingNotification ?? notification;
  n.hideTimer = window.setTimeout(() => removeNotification(n.id), n.timeout);

  return n.id;
}

export function removeNotification(notificationId: string): void {
  if (!notifications.value.some((n) => n.id === notificationId)) {
    return;
  }

  notifications.value = notifications.value.filter((n) => n.id !== notificationId);

  // Tell any other windows, primary or secondary, to remove the notification too
  postMessageToPrimaryWindow({ type: "notification-removed", notificationId });
  postMessageToSecondaryWindow({ type: "notification-removed", notificationId });
}

export function clearNotifications(): void {
  notifications.value = [];
}

// Listen for the messages that sync notifications across all open windows
onPrimaryWindowMessageReceived("notification-added", (message) =>
  addNotification(message.notification)
);
onSecondaryWindowMessageReceived("notification-added", (message) =>
  addNotification(message.notification)
);
onPrimaryWindowMessageReceived("notification-removed", ({ notificationId }) =>
  removeNotification(notificationId)
);
onSecondaryWindowMessageReceived("notification-removed", ({ notificationId }) =>
  removeNotification(notificationId)
);
