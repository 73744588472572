import { useRafFn } from "@vueuse/core";
import type { Ref, ShallowReactive } from "vue";
import { computed, watch } from "vue";
import { activeMeasurement, isMeasuring } from "../../measurements/measurement-tool-state";
import { onKeyboardShortcut } from "../../utils/keyboard-shortcut";
import type { ClipModel } from "../clip-model";
import {
  onPrimaryWindowMessageReceived,
  postMessageToPrimaryWindow,
} from "../multi-window/primary-window-messages";
import { getWindowType, WindowType } from "../multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "../multi-window/secondary-window-messages";
import type { ClipsGridItem, RegularClipsGridItem } from "./clips-grid-item";
import { ClipsGridItemType, getRegularClipsGridItems } from "./clips-grid-item";

/**
 * A object for controlling the playback of regular clips in the ClipsGrid. This manages playback,
 * scrubbing, and clip synchronization.
 */
export interface RegularClipPlaybackController {
  onPlayPauseButtonClick(index: number): void;
  onScrub(index: number, newTime: number): void;
}

export function createRegularClipPlaybackController(
  gridItems: ShallowReactive<ClipsGridItem[]>,
  playbackSpeedFactor: Ref<number>,
  isClipSyncEnabled: Ref<boolean>,
  opts: { isClipInModal: boolean } = { isClipInModal: false }
): RegularClipPlaybackController {
  const regularGridItems = computed(() => getRegularClipsGridItems(gridItems));

  function isClipModelFrameChangeAllowed(clipModel: ClipModel): boolean {
    return (
      activeMeasurement.value.studyClipId !== clipModel.clip?.id ||
      activeMeasurement.value.isChangeAllowedOf("frame")
    );
  }

  // Whether the given clip model's playback is being synced with other clips. Sync only applies when
  // clip syncing is enabled, when a clip isn't in the middle of a measurement that disallows frame
  // changes, and when either the clip has a selected heartbeat, or none of the selected clips have a
  // selected heartbeat. The latter case also occurs when the study doesn't define any heartbeats, and
  // results in the full duration of the clips being synced.
  function isClipModelPlaybackSynced(clipModel: ClipModel): boolean {
    if (!isClipSyncEnabled.value) {
      return false;
    }

    if (!isClipModelFrameChangeAllowed(clipModel)) {
      return false;
    }

    if (
      clipModel.getSelectedHeartbeat() === undefined &&
      regularGridItems.value.some((c) => c.getSelectedHeartbeat() !== undefined)
    ) {
      return false;
    }

    return true;
  }

  function getClipWithLongestHeartbeatSelected(): ClipModel | undefined {
    let result: ClipModel | undefined = undefined;

    for (const item of regularGridItems.value) {
      if (!isClipModelPlaybackSynced(item)) {
        continue;
      }

      const heartbeat = item.getSelectedHeartbeat();
      if (heartbeat === undefined) {
        continue;
      }

      if (heartbeat.length > (result?.getSelectedHeartbeat()?.length ?? 0)) {
        result = item;
      }
    }

    return result;
  }

  // Synchronizes all clips' current time to the time of the given clip. This is used to synchronize
  // clips during both playback and scrubbing.
  function setSyncedClipsCurrentTime(primaryClipModel: ClipModel): void {
    // First try to sync based on the primary clip's selected heartbeat
    let primaryClipSyncRange: { startTime: number; length: number } | undefined =
      primaryClipModel.getSelectedHeartbeat();

    // If there's no selected heartbeat but this clip is synced then treat the entire clip length as
    // the range to sync with
    if (primaryClipSyncRange === undefined) {
      if (
        !isClipModelPlaybackSynced(primaryClipModel) ||
        primaryClipModel.clip === undefined ||
        primaryClipModel.clip.frameCount === null
      ) {
        return;
      }

      primaryClipSyncRange = { startTime: 0, length: primaryClipModel.clipDuration };
    }

    // Get the fraction of the way through the beat the primary clip currently is
    const syncPosition =
      (primaryClipModel.getCurrentTime() - primaryClipSyncRange.startTime) /
      primaryClipSyncRange.length;

    for (const item of regularGridItems.value) {
      if (!isClipModelPlaybackSynced(item)) {
        continue;
      }

      const heartbeat = item.getSelectedHeartbeat();
      if (heartbeat === undefined) {
        // If this clip has no selected heartbeat but is synced then synchronize to the full duration
        item.setCurrentTime(syncPosition * item.clipDuration);
      } else {
        // Set this clip to be the same fraction of the way through its heartbeat as the primary clip
        item.setCurrentTime(heartbeat.startTime + syncPosition * heartbeat.length);
      }
    }
  }

  function setupClipPlaybackRaf(): void {
    let lastUpdateTime: DOMHighResTimeStamp | undefined = undefined;

    useRafFn(() => {
      const currentTimestamp = performance.now();

      // Calculate the time in microseconds since the last update, scaled by the playback speed factor
      const elapsedTime =
        Math.floor(1000 * (currentTimestamp - (lastUpdateTime ?? currentTimestamp))) *
        playbackSpeedFactor.value;

      for (const gridItem of regularGridItems.value) {
        if (gridItem.isPlaying.value) {
          gridItem.stepCurrentTime(elapsedTime);
        }
      }

      // When clip sync is enabled, find the clip with the longest heartbeat selected and sync other
      // playing clips to it. If there are no heartbeats selected on any clips then sync to the longest
      // clip.
      if (isClipSyncEnabled.value) {
        let primaryClipModel = getClipWithLongestHeartbeatSelected();

        if (primaryClipModel === undefined) {
          primaryClipModel = regularGridItems.value.reduce<RegularClipsGridItem | undefined>(
            (a, b) => (a && a.clipDuration > b.clipDuration ? a : b),
            undefined
          );

          if (primaryClipModel === undefined) {
            return;
          }
        }

        setSyncedClipsCurrentTime(primaryClipModel);
      }

      lastUpdateTime = currentTimestamp;
    });
  }

  function onPlayPauseButtonClick(clipIndex: number): void {
    const gridItem = gridItems[clipIndex];

    if (gridItem.type !== ClipsGridItemType.RegularClip) {
      return;
    }

    if (!isClipModelPlaybackSynced(gridItem)) {
      gridItem.isPlaying.value = !gridItem.isPlaying.value;
      return;
    }

    // This clip is being synced, so toggle play/pause on all synced clips as a group
    const newValue = !gridItem.isPlaying.value;
    for (const gridItem of regularGridItems.value) {
      if (!isClipModelPlaybackSynced(gridItem)) {
        continue;
      }

      gridItem.isPlaying.value = newValue;
    }
  }

  function onScrub(clipIndex: number, newTime: number): void {
    const gridItem = gridItems[clipIndex];
    if (gridItem.type !== ClipsGridItemType.RegularClip) {
      return;
    }

    gridItem.setCurrentTime(newTime);

    if (isClipModelPlaybackSynced(gridItem)) {
      // Pause all the synced clips when scrubbing on one of them
      for (const clipModel of regularGridItems.value) {
        if (!isClipModelPlaybackSynced(clipModel)) {
          continue;
        }

        clipModel.isPlaying.value = false;
      }

      // Align all synced clips to the scrubbed clip
      setSyncedClipsCurrentTime(gridItem);
    }
  }

  function stepCurrentFrames(delta: number): void {
    for (const item of gridItems) {
      item.onStepFrame(delta);
    }

    postMessageToSecondaryWindow({ type: "step-frames", frameStepDelta: delta });
  }

  function setupClipSyncWatcher(): void {
    // Automatically update selected heartbeats when clip sync is turned on/off. When clip sync is
    // turned on the first heartbeat is automatically selected, and when clip sync is turned off all
    // heartbeats are deselected.
    watch(isClipSyncEnabled, () => {
      for (const item of regularGridItems.value) {
        // Automatically select the first heartbeat when clip sync is enabled
        if (isClipSyncEnabled.value) {
          if (item.getSelectedHeartbeat() === undefined && item.heartbeats.length > 0) {
            item.setSelectedHeartbeatIndex(0);
          }
        }

        // Deselect any selected heartbeats when clip sync is disabled
        else {
          item.setSelectedHeartbeatIndex(undefined);
        }
      }
    });
  }

  function setupKeyboardShortcuts(): void {
    // Pressing space toggles all clips playing/paused
    onKeyboardShortcut(
      " ",
      (event: KeyboardEvent) => {
        // Prevent the default behavior of spacebar also pressing the currently focused button. This stops
        // unwanted interactions between this keyboard shortcut and any currently focused toolbar
        // button.
        event.preventDefault();

        if (isMeasuring.value) {
          return;
        }

        const isAnyClipScrubbing = regularGridItems.value.some((c) => c.isScrubbing.value);

        if (isAnyClipScrubbing) {
          return;
        }

        const isAnyClipPaused = regularGridItems.value.some((c) => !c.isPlaying.value);
        for (const item of regularGridItems.value) {
          item.isPlaying.value = isAnyClipPaused;
        }

        if (getWindowType() === WindowType.Primary) {
          postMessageToSecondaryWindow({
            type: "set-clips-playing",
            isPlaying: !regularGridItems.value.some((c) => !c.isPlaying.value),
          });
        } else if (getWindowType() === WindowType.SerialStudy) {
          postMessageToPrimaryWindow({
            type: "set-clips-playing",
            isPlaying: !regularGridItems.value.some((c) => !c.isPlaying.value),
          });
        }
      },
      { isAllowedInModal: opts.isClipInModal }
    );

    if (getWindowType() === WindowType.Primary) {
      // The comma and period keys step back/forward a frame on all clips
      onKeyboardShortcut(",", () => stepCurrentFrames(-1), {
        isAllowedInModal: opts.isClipInModal,
      });
      onKeyboardShortcut(".", () => stepCurrentFrames(1), { isAllowedInModal: opts.isClipInModal });
    }
  }

  function setupWindowMessageHandlers(): void {
    onPrimaryWindowMessageReceived("set-clips-playing", (message) => {
      for (const item of regularGridItems.value) {
        item.isPlaying.value = message.isPlaying;
      }
    });
  }

  setupClipPlaybackRaf();
  setupClipSyncWatcher();
  setupKeyboardShortcuts();
  setupWindowMessageHandlers();

  return { onPlayPauseButtonClick, onScrub };
}
