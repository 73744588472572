import { getDrawableDisplacementMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import type { MeasurementTool } from "../measurement-tool";
import { isRegionMMode } from "../measurement-tool-helpers";
import { createBaseLinearMeasurementTool } from "./measurement-base-linear";

export function createDisplacementMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  return {
    displayName: "Displacement Measurement",

    isMeasurableOnStudyClip: isDisplacementMeasurableOnStudyClip,
    getCreatableMeasurementNames: getDrawableDisplacementMeasurements,

    ...createBaseLinearMeasurementTool(study, studyClipId, region, {
      customMeasurementName: MeasurementName.CustomDisplacement,
      tool: MeasurementToolName.Displacement,
      isXAxisLocked: true,
      isYAxisLocked: false,
    }),
  };
}

export function isDisplacementMeasurableOnStudyClip(
  clip: StudyClip,
  region?: StudyClipRegion
): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some((r) => isRegionMMode(r));
}
