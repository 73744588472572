import { isEqual } from "lodash";

/**
 * Uses the Window Management API to find the largest second screen attached to this system and
 * returns its position information. This is only supported in Chrome 100+ at present.
 * See https://www.w3.org/TR/window-management/ for details.
 */
export async function getLargestSecondScreenLocation(): Promise<{
  top: number;
  left: number;
  width: number;
  height: number;
} | null> {
  // We need to wrap the permissions request in a top-level try in case they close the permission
  // prompt with the "x" which results in a "Permission decision deferred" error.
  try {
    if (!(await isWindowManagementAPISupported())) {
      return null;
    }

    const screenDetails = await window.getScreenDetails?.();
    if (screenDetails === undefined || screenDetails.screens.length < 2) {
      return null;
    }

    const sortedScreens = screenDetails.screens
      .filter((s) => !isEqual(screenDetails.currentScreen, s))
      .sort((a, b) => b.width * b.height - a.width * a.height);

    return sortedScreens[0] ?? null;
  } catch (error) {
    console.error(`Error looking for second screen: ${String(error)}`);

    return null;
  }
}

/**
 * Returns whether the window managment API is supported in this browser. Code adapted from
 * https://developer.chrome.com/articles/multi-screen-window-placement/
 */
async function isWindowManagementAPISupported(): Promise<boolean> {
  let state: PermissionState = "denied";

  // There are two relevant permissions. The casts are necessary because the API has not yet been
  // finalised by the W3C.
  const newPermissionName = "window-management" as PermissionName;
  const oldPermissionName = "window-placement" as PermissionName;

  // Try the new permission name
  try {
    ({ state } = await navigator.permissions.query({ name: newPermissionName }));
  } catch (error) {
    if (error instanceof Error && error.name !== "TypeError") {
      console.warn(`Window placement API error: ${error.name}, ${error.message}`);
    }

    // Try the old permission name
    try {
      ({ state } = await navigator.permissions.query({ name: oldPermissionName }));
    } catch (error2) {
      if (error instanceof Error) {
        if (error.name === "TypeError") {
          console.warn(`Window management API not supported`);
        } else {
          console.warn(`Window placement API error: ${error.name}, ${error.message}`);
        }
      }
    }
  }

  return state === "granted" || state === "prompt";
}
