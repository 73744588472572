<template>
  <AuthPage
    auth-button-visible
    :auth-button-text="isPasswordReset ? 'Sign In' : 'Reset Password'"
    :auth-button-enabled="isPasswordReset || isComplete"
    :auth-button-spinner="isResettingPassword"
    @submit="onSubmit"
  >
    <template v-if="isPasswordReset">
      <div class="password-reset-message" data-testid="password-reset-message">
        <FontAwesomeIcon icon="check" size="lg" />
        Your password has been reset
      </div>
    </template>
    <template v-else>
      <div class="instructions">Enter a new password for your HeartLab account</div>

      <div class="field">
        <b>Email</b>
        <input v-model="email" disabled data-testid="email" />
      </div>

      <div class="field">
        <b>Password</b>
        <PasswordInput
          v-model="password"
          initial-focus
          show-mask-password-button
          data-testid="password"
        />
        <PasswordStrengthCheck :password="password" />
      </div>
    </template>
  </AuthPage>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRouteQuery } from "@vueuse/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { isPasswordStrong } from "../../../backend/src/auth/password-strength-check";
import PasswordInput from "../components/PasswordInput.vue";
import router from "../router";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import AuthPage from "./AuthPage.vue";
import PasswordStrengthCheck from "./PasswordStrengthCheck.vue";
import { signOut } from "./authentication";

const email = useRouteQuery("email");
const token = useRouteQuery("token");

const password = ref("");

const isResettingPassword = ref(false);
const isPasswordReset = ref(false);

const isComplete = computed(() => isPasswordStrong(password.value));

// Destroy any current session
onMounted(async () => signOut({ redirectToSignIn: false }));

async function onSubmit(): Promise<void> {
  if (isPasswordReset.value) {
    await router.push({ name: "sign-in" });
    return;
  }

  if (!isComplete.value) {
    return;
  }

  isResettingPassword.value = true;

  try {
    await axios.post(`/api/users/reset-password`, {
      email: email.value,
      token: token.value,
      password: password.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message:
        getRequestErrorMessage(error) ?? "Failed resetting password, the link may have expired",
    });
    return;
  } finally {
    isResettingPassword.value = false;
  }

  isPasswordReset.value = true;
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    width: 100%;
    box-sizing: border-box;

    &:disabled {
      opacity: 0.85;
    }
  }
}

.instructions {
  font-weight: bold;
  display: flex;
  margin-bottom: 12px;
  align-self: center;
}

.password-reset-message {
  font-weight: bold;
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 16px auto;

  svg {
    color: var(--confirm-color-2);
  }
}
</style>
