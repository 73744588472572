<template>
  <div class="settings-title">Measurement Calculations</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />
    <button
      class="add-calculation-button accented"
      data-testid="add-calculation"
      :disabled="!isSearchTermEmpty"
      @click="createCalculation"
    >
      Create Calculation
    </button>
  </div>

  <div class="calculations">
    <OverlayScrollbar>
      <div class="calculation-list" data-testid="calculation-list">
        <MeasurementCalculationBlock
          v-for="(calculation, calculationIndex) in filteredCalculations"
          :key="calculation.id"
          :calculation="calculation"
          :calculation-index="calculationIndex"
          @delete="deleteCalculation(calculation)"
          @save="
            calculationsToSave.add(calculation.id);
            saveCalculationsDebounced();
          "
        />

        <div v-if="calculations.length === 0" class="no-calculations-text">
          No measurement calculations have been created
        </div>
        <div v-else-if="filteredCalculations.length == 0" class="no-calculations-text">
          No results found
        </div>
      </div>
    </OverlayScrollbar>

    <OverlayScrollbar>
      <div class="side-pane">
        <div
          v-for="calculation in filteredCalculations"
          :key="calculation.id"
          class="jump-to-calculation-button"
          :class="{ disabled: !filteredCalculations.includes(calculation) }"
          @click="focusCalculation(calculation.id)"
        >
          <template v-if="calculation.name.length > 0">
            {{ calculation.name }}
          </template>
          <i v-else> (no name) </i>

          <Tooltip
            content="Delete measurement calculation"
            style="margin-right: 4px; margin-left: auto"
          >
            <FontAwesomeIcon
              class="side-delete-button"
              icon="trash"
              size="sm"
              @click="deleteCalculation(calculation)"
            />
          </Tooltip>
        </div>
      </div>
    </OverlayScrollbar>

    <ActivityOverlay v-if="activityText" :text="activityText" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { computed, ref } from "vue";
import { MeasurementCalculationCreateResponseDto } from "../../../../backend/src/measurements/dto/measurement-calculation-create.dto";
import ActivityOverlay from "../../components/ActivityOverlay.vue";
import FilterInput from "../../components/FilterInput.vue";
import OverlayScrollbar from "../../components/OverlayScrollbar.vue";
import Tooltip from "../../components/Tooltip.vue";
import { MeasurementCalculation } from "../../measurements/calculations/measurement-calculations";
import { addNotification } from "../../utils/notifications";
import MeasurementCalculationBlock from "./MeasurementCalculation.vue";

const searchTerm = ref("");
const isSearchTermEmpty = computed(() => searchTerm.value.trim() === "");

const activityText = ref("");

const calculations = ref<MeasurementCalculation[]>([]);

const filteredCalculations = computed(() =>
  calculations.value.filter((calc) =>
    calc.name.toLowerCase().includes(searchTerm.value.toLowerCase().trim())
  )
);

async function createCalculation(): Promise<void> {
  let response: AxiosResponse<MeasurementCalculationCreateResponseDto> | undefined = undefined;

  try {
    response = await axios.post<MeasurementCalculationCreateResponseDto>(
      `/api/measurement-calculations`,
      { name: "" }
    );
  } catch {
    addNotification({ type: "error", message: "Error adding measurement calculation" });
    return;
  }

  // Put the new calculation at the top
  calculations.value.splice(0, 0, response.data);
}

const calculationsToSave = new Set<string>();

async function saveCalculations(): Promise<void> {
  try {
    const savePromises = [...calculationsToSave].map(async (calculationId) => {
      const calculation = calculations.value.find((c) => c.id === calculationId);
      if (calculation === undefined) {
        return;
      }

      return axios.patch(`/api/measurement-calculations/${calculation.id}`, {
        name: calculation.name,
        enabled: calculation.enabled,
        formula: calculation.formula,
        variables: calculation.variables,
        outputMeasurementName: calculation.outputMeasurementName,
        outputMeasurementUnit: calculation.outputMeasurementUnit,
      });
    });

    calculationsToSave.clear();

    await Promise.all(savePromises);
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving calculations" });
    return;
  }

  addNotification({ type: "info", message: "Measurement calculations saved" });
}

function focusCalculation(id: string): void {
  document.getElementById(`measurement-calculation-${id}`)?.scrollIntoView();
  document.querySelector<HTMLInputElement>(`#measurement-calculation-name-${id} input`)?.focus();
}

async function fetchMeasurementCalculations(): Promise<void> {
  activityText.value = "Loading";

  let response: AxiosResponse<MeasurementCalculationCreateResponseDto[]> | undefined = undefined;
  try {
    response = await axios.get<MeasurementCalculationCreateResponseDto[]>(
      "/api/measurement-calculations"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading measurement calculations" });
    return;
  } finally {
    activityText.value = "";
  }

  calculations.value = response.data;
}

void fetchMeasurementCalculations();

const saveCalculationsDebounced = useDebounceFn(() => void saveCalculations(), 500);

async function deleteCalculation(calculation: { id: string; name: string }): Promise<void> {
  if (!confirm(`Are you sure you want to delete the calculation '${calculation.name}'`)) {
    return;
  }

  activityText.value = "Deleting calculation";

  try {
    await axios.delete(`/api/measurement-calculations/${calculation.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting calculation" });
    return;
  } finally {
    activityText.value = "";
  }

  // Remove the deleted calculation from the list
  const index = calculations.value.findIndex((g) => g.id === calculation.id);
  calculations.value.splice(index, 1);
  calculationsToSave.delete(calculation.id);
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.calculations {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  flex: 1;
}

.side-pane {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  max-width: 300px;
}

.calculation-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 12px;
}

.jump-to-calculation-button {
  justify-content: left;
  border-radius: var(--border-radius);
  width: 100%;
  font-weight: bold;
  color: var(--text-color-1);
  cursor: pointer;
  transition: color 100ms ease;
  padding: 4px 0;
  display: flex;

  &:hover {
    color: var(--text-color-2);

    .side-delete-button {
      color: var(--text-color-2);
    }
  }
}

.add-calculation-button {
  padding: 0 12px;
  margin-left: auto;
}

.no-calculations-text {
  padding-top: 20px;
  font-style: italic;
}
</style>
