<template>
  <div :id="`measurement-sequence-${sequence.id}`" ref="rootElement" class="measurement-sequence">
    <div :id="`measurement-sequence-name-${sequence.id}`" class="name">
      <input
        :value="sequence.name"
        placeholder="Measurement sequence name"
        :data-testid="`measurement-sequence-${sequenceIndex}-name`"
        @input="updateName($event)"
      />

      <Tooltip content="Delete measurement sequence">
        <button
          tabindex="-1"
          class="side-button"
          :data-testid="`measurement-sequence-${sequenceIndex}-delete`"
          @click="emits('delete')"
        >
          <FontAwesomeIcon icon="trash" size="sm" />
        </button>
      </Tooltip>
    </div>

    <DraggableList class="sequence-list" group="sequences" @reorder="emits('reorder', $event)">
      <div
        v-for="(step, index) in sequence.steps"
        :key="step.id"
        class="step"
        :data-testid="`sequence-step-${index}`"
        :class="{
          'has-associated-measurements':
            step.measurementName &&
            getAssociatedMeasurementsForMeasurement(step.measurementName).length > 0,
        }"
      >
        <div class="drag-handle">
          <FontAwesomeIcon icon="grip-lines-vertical" />
        </div>

        <DropdownWidget
          class="dropdown"
          data-testid="tool-type-dropdown"
          style="border-right: 0"
          placeholder="Select measurement tool"
          :model-value="step.measurementTool ?? ''"
          :items="measurementToolNameDropdownItems"
          @update:model-value="($event) => updateStepMeasurementTool(step, $event)"
        />

        <DropdownWidget
          class="dropdown"
          data-testid="measurement-name-dropdown"
          placeholder="Select measurement name"
          :model-value="step.measurementName ?? ''"
          :disabled="step.measurementTool === null"
          :items="
            step.measurementTool === null
              ? []
              : getDrawableMeasurementsForTool(step.measurementTool)
                  .filter((m) => !isCustomMeasurement(m))
                  .map((m) => ({
                    value: m,
                    text: getMeasurementDisplayName(m, 'unindexed'),
                  }))
          "
          @update:model-value="
            (e) => {
              step.measurementName = e === '' ? null : (e as MeasurementName);
              step.enabledAssociatedMeasurements = [];
              emits('save');
            }
          "
        />

        <Tooltip content="Delete step" placement="top">
          <button
            class="side-button del"
            tabindex="-1"
            :data-testid="`measurement-sequence-${sequenceIndex}-${index}-delete`"
            @click="deleteStep(index)"
          >
            <FontAwesomeIcon icon="trash" size="sm" />
          </button>
        </Tooltip>

        <template
          v-if="
            step.measurementName !== null &&
            getAssociatedMeasurementsForMeasurement(step.measurementName).length > 0
          "
        >
          <div class="associated-mmts">
            <div>Include related measurements:</div>
            <Checkbox
              v-for="(
                associatedMeasurement, associatedMmtIndex
              ) in getAssociatedMeasurementsForMeasurement(step.measurementName)"
              :key="associatedMmtIndex"
              :data-testid="`associated-mmt-${associatedMmtIndex}`"
              :model-value="step.enabledAssociatedMeasurements.includes(associatedMeasurement)"
              @update:model-value="onToggleAssociatedMeasurement(step, associatedMeasurement)"
            >
              {{ getMeasurementDisplayName(associatedMeasurement, "unindexed") }}
            </Checkbox>
          </div>
        </template>
      </div>
    </DraggableList>

    <Tooltip
      content="Add step to this measurement sequence"
      placement="left"
      style="margin-left: auto"
    >
      <button
        class="add-step-button"
        tabindex="-1"
        :data-testid="`measurement-sequence-${sequenceIndex}-add-step`"
        @click="
          addStep(sequence.steps.length, {
            id: uuidv4(),
            measurementTool: null,
            measurementName: null,
            enabledAssociatedMeasurements: [],
          })
        "
      >
        +
      </button>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke } from "@vueuse/core";
import type { SortableEvent } from "sortablejs";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import { getAssociatedMeasurementsForMeasurement } from "../../../../backend/src/measurements/associated-measurements";
import { getDrawableMeasurementsForTool } from "../../../../backend/src/measurements/drawable-measurements";
import { getMeasurementDisplayName } from "../../../../backend/src/measurements/measurement-display";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../../backend/src/measurements/measurement-names";
import { MeasurementSequenceStep } from "../../../../backend/src/measurements/measurement-sequence-step";
import { MeasurementSequence } from "../../../../backend/src/measurements/measurement-sequence.entity";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import Checkbox from "../../components/Checkbox.vue";
import DraggableList from "../../components/DraggableList.vue";
import DropdownWidget from "../../components/DropdownWidget.vue";
import Tooltip from "../../components/Tooltip.vue";

interface Props {
  sequence: Pick<MeasurementSequence, "id" | "name" | "steps">;
  sequenceIndex: number;
}

interface Emits {
  (name: "delete"): void;
  (name: "reorder", event: SortableEvent): void;
  (name: "save"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const rootElement = ref<HTMLDivElement | null>(null);

const focusedFieldIndex = ref<number | null>();

const measurementToolNameDropdownItems = [
  { value: MeasurementToolName.Area, text: "Area" },
  { value: MeasurementToolName.Distance, text: "Distance" },
  { value: MeasurementToolName.DopplerSlope, text: "Doppler Slope" },
  { value: MeasurementToolName.EjectionFraction, text: "Ejection Fraction" },
  { value: MeasurementToolName.Displacement, text: "M-Mode Displacement" },
  { value: MeasurementToolName.MModeSlope, text: "M-Mode Slope" },
  { value: MeasurementToolName.Time, text: "Time" },
  { value: MeasurementToolName.Velocity, text: "Velocity" },
  { value: MeasurementToolName.Volume, text: "Volume" },
  { value: MeasurementToolName.VelocityTimeIntegral, text: "VTI" },
];

function updateName(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.sequence.name = event.target.value;

  emits("save");
}

function deleteStep(index: number): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.sequence.steps.splice(index, 1);

  emits("save");
}

function addStep(index: number, step: MeasurementSequenceStep): void {
  // eslint-disable-next-line vue/no-mutating-props
  props.sequence.steps.splice(index, 0, step);

  emits("save");
}

function updateStepMeasurementTool(step: MeasurementSequenceStep, measurementTool: string): void {
  if (measurementTool === (step.measurementTool as string)) {
    return;
  }

  step.measurementTool = measurementTool === "" ? null : (measurementTool as MeasurementToolName);
  step.measurementName = null;
  step.enabledAssociatedMeasurements = [];

  emits("save");
}

function onToggleAssociatedMeasurement(
  step: MeasurementSequenceStep,
  associatedMeasurement: MeasurementName
) {
  const idx = step.enabledAssociatedMeasurements.indexOf(associatedMeasurement);
  if (idx === -1) {
    step.enabledAssociatedMeasurements.push(associatedMeasurement);
  } else {
    step.enabledAssociatedMeasurements.splice(idx, 1);
  }

  emits("save");
}

onKeyStroke("Escape", () => (focusedFieldIndex.value = undefined));
</script>

<style scoped lang="scss">
.measurement-sequence {
  display: flex;
  flex-direction: column;
  transition: filter 100ms ease;
}

.name {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border: 1px solid var(--border-color-1);
  overflow: hidden;

  &:hover,
  &:focus-within {
    .side-button {
      visibility: visible;
      opacity: 1;
    }
  }

  input {
    font-weight: bold;
    padding: 4px 8px;
    height: 20px;
    border-radius: 0;
    border: none;

    &::placeholder {
      font-weight: normal;
    }
  }
}

.side-button {
  height: 28px;
  width: 24px;
  background-color: var(--bg-color-3);
  opacity: 0;
  transition: opacity 100ms ease;
  border-radius: 0 !important;
}

.sequence-list {
  &:first-child {
    border-top: none;
  }
}

.step {
  background-color: var(--bg-color-2);
  display: grid;
  grid-template-columns: 1.8em 1fr 1fr auto auto;
  grid-template-areas:
    "drag-handle dropdown dropdown btn btn"
    "drag-handle . associated-mmts btn btn";
  border: 1px solid var(--border-color-1);
  border-top: none;

  .associated-mmts {
    grid-area: associated-mmts;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;

    :not(:first-child) {
      padding-left: 2px;
    }
  }

  &:hover,
  &:focus-within {
    .side-button {
      opacity: 1;
    }
  }

  &:last-child {
    border-radius: 0 0 0 var(--border-radius);
  }
}

.drag-handle {
  grid-area: drag-handle;
  color: var(--accent-color-1);
  transition: color 100ms ease;
  display: grid;
  place-content: center;
  cursor: grab;
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius) 0 0 var(--border-radius);

  &:hover {
    color: var(--accent-color-2);
  }
}

.add-step-button {
  margin-top: 2px;
  height: 28px;
  width: 28px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.dropdown {
  height: 28px;
  border: none;
  border-radius: 0;
}
</style>
