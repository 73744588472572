/**
 * The set of different clinical states that can be indicated by a reference range.
 */
export enum ReferenceRangeName {
  Unknown = "unknown",
  Normal = "normal",
  Mild = "mild",
  Moderate = "moderate",
  Severe = "severe",
  Abnormal = "abnormal",
}

/**
 * Returns the display name for the given reference range name.
 */
export function getReferenceRangeDisplayName(referenceRangeName: ReferenceRangeName): string {
  switch (referenceRangeName) {
    case ReferenceRangeName.Unknown:
      return "Unknown";
    case ReferenceRangeName.Normal:
      return "Normal";
    case ReferenceRangeName.Mild:
      return "Mildly abnormal";
    case ReferenceRangeName.Moderate:
      return "Moderately abnormal";
    case ReferenceRangeName.Severe:
      return "Severely abnormal";
    case ReferenceRangeName.Abnormal:
      return "Abnormal";
  }
}

/**
 * Returns the CSS color for the given reference range name, with optional alpha.
 */
export function getReferenceRangeColor(referenceRangeName?: ReferenceRangeName, alpha = 1): string {
  if (referenceRangeName === undefined) {
    return "";
  }

  switch (referenceRangeName) {
    case ReferenceRangeName.Unknown:
      return `rgba(119, 119, 119, ${alpha})`;
    case ReferenceRangeName.Normal:
      return `rgba(100, 235, 87, ${alpha})`;
    case ReferenceRangeName.Mild:
      return `rgba(247, 237, 0, ${alpha})`;
    case ReferenceRangeName.Moderate:
      return `rgba(248, 158, 42, ${alpha})`;
    case ReferenceRangeName.Severe:
      return `rgba(250, 2, 2, ${alpha})`;
    case ReferenceRangeName.Abnormal:
      return `rgba(248, 148, 32, ${alpha})`;
  }
}
