import { computed } from "vue";
import { getDrawableMModeSlopeMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import type { MeasurementTool } from "../measurement-tool";
import { isRegionMMode } from "../measurement-tool-helpers";
import { createSlopeMeasurementTool } from "./measurement-base-slope";

export function createMModeSlopeMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  return {
    displayName: "M-Mode Slope Measurement",

    isMeasurableOnStudyClip: isMModeSlopeMeasurableOnStudyClip,
    getCreatableMeasurementNames: getDrawableMModeSlopeMeasurements,

    associatedMeasurements: computed(() => ({})),
    toggleAssociatedMeasurement: () => null,

    ...createSlopeMeasurementTool(study, studyClipId, region, {
      customMeasurementName: MeasurementName.CustomMModeSlope,
      yAxisCustomMeasurementName: MeasurementName.CustomDisplacement,
      isFixSlopeToMidlineCheckboxVisible: false,
      measurementTool: MeasurementToolName.MModeSlope,
    }).slopeTool,
  };
}

export function isMModeSlopeMeasurableOnStudyClip(
  clip: StudyClip,
  region?: StudyClipRegion
): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some((r) => isRegionMMode(r));
}
