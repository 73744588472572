import {
  getDrawableMModeTimeMeasurements,
  getDrawableSpectralTimeMeasurements,
} from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import type { MeasurementTool } from "../measurement-tool";
import { isRegionMMode, isRegionSpectral } from "../measurement-tool-helpers";
import { createBaseLinearMeasurementTool } from "./measurement-base-linear";

export function createTimeMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  return {
    displayName: "Time Measurement",

    isMeasurableOnStudyClip: isTimeMeasurableOnStudyClip,
    getCreatableMeasurementNames: (): MeasurementName[] => {
      if (region === undefined) {
        return [];
      }

      return isRegionMMode(region)
        ? getDrawableMModeTimeMeasurements()
        : getDrawableSpectralTimeMeasurements();
    },

    ...createBaseLinearMeasurementTool(study, studyClipId, region, {
      customMeasurementName: MeasurementName.CustomTime,
      tool: MeasurementToolName.Time,
      isXAxisLocked: false,
      isYAxisLocked: true,
    }),
  };
}

export function isTimeMeasurableOnStudyClip(clip: StudyClip, region?: StudyClipRegion): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some((r) => isRegionSpectral(r) || isRegionMMode(r));
}
