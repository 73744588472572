/** Names of all the measurement tools that are available in the application. */
export enum MeasurementToolName {
  Distance = "distance",
  Time = "time",
  Displacement = "displacement",
  Area = "area",
  Volume = "volume",
  EjectionFraction = "ejectionFraction",
  VelocityTimeIntegral = "velocityTimeIntegral",
  DopplerSlope = "dopplerSlope",
  MModeSlope = "mModeSlope",
  Velocity = "velocity",
  Angle = "angle",
}
