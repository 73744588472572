<template>
  <div class="settings-title">Sessions</div>

  <div class="explanation">
    <p>
      Your recent sessions are listed below. Each session shows the address it is for, its observed
      public IP address, and details of the associated device and browser.
      <br />
      <br />
      Sessions should be deleted if you don't recognize them.
    </p>

    <button data-testid="delete-all-sessions-button" @click="deleteAllSessions">
      Sign Out Everywhere
    </button>
  </div>

  <div class="sessions-table" data-testid="sessions-table">
    <div class="header">
      <div class="header-item">
        <div class="title">Last Used</div>
      </div>
      <div class="header-item">
        <div class="title">Created At</div>
      </div>
      <div />
      <div />
    </div>

    <div class="header-line" />

    <div
      v-for="session in sessions"
      :key="session.id"
      class="grid-table-row"
      :class="{ expired: isSessionExpired(session.lastUsedAt) }"
      data-testid="grid-table-row"
    >
      <div>{{ formatDateTime(session.lastUsedAt, { includeTime: true }) }}</div>
      <div>{{ formatDateTime(session.createdAt, { includeTime: true }) }}</div>
      <div>
        <Badge v-if="session.isCurrent">Current</Badge>
        <Badge v-if="isSessionExpired(session.lastUsedAt)" style="background: none">Expired</Badge>
      </div>
      <div>
        <Tooltip
          v-if="!session.isCurrent"
          content="Delete session"
          data-testid="delete-session"
          @click="deleteSession(session.id)"
        >
          <FontAwesomeIcon icon="trash" class="delete-icon" />
        </Tooltip>
      </div>
    </div>

    <ActivityOverlay v-if="activityText" :text="activityText" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { ref } from "vue";
import { toDateTime } from "../../../backend/src/studies/study-time";
import { CurrentUserSessionGetManyResponseDto } from "../../../backend/src/user/dto/current-user-session-get-many.dto";
import { signOut } from "../auth/authentication";
import { currentTenant } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import Badge from "../components/Badge.vue";
import Tooltip from "../components/Tooltip.vue";
import { formatDateTime } from "../utils/date-time-utils";
import { addNotification } from "../utils/notifications";

const activityText = ref("");

const sessions = ref<CurrentUserSessionGetManyResponseDto>([]);

async function fetchSessions(): Promise<void> {
  let response: AxiosResponse<CurrentUserSessionGetManyResponseDto> | undefined = undefined;

  activityText.value = "Loading sessions";

  try {
    response = await axios.get<CurrentUserSessionGetManyResponseDto>("/api/user/current/sessions");
  } catch {
    addNotification({ type: "error", message: "Failed loading sessions" });
    return;
  } finally {
    activityText.value = "";
  }

  // Sort with most recently used at at top
  sessions.value = response.data.sort((a, b) =>
    b.lastUsedAt.toString().localeCompare(a.lastUsedAt.toString())
  );
}

void fetchSessions();

function isSessionExpired(lastUsedAt: Date | string): boolean {
  const dt = toDateTime(lastUsedAt);
  if (dt === undefined) {
    return false;
  }

  return (
    dt.plus({ seconds: currentTenant.sessionInactivityExpirationTime }).diffNow().toMillis() < 0
  );
}

async function deleteSession(sessionId: string): Promise<void> {
  if (!confirm("Are you sure you want to delete this session?")) {
    return;
  }

  try {
    await axios.delete(`/api/user/current/sessions/${sessionId}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting session" });
    return;
  }

  addNotification({ type: "info", message: "Session deleted" });

  // Remove deleted session from the list
  const index = sessions.value.findIndex((s) => s.id === sessionId);
  sessions.value.splice(index, 1);
}

async function deleteAllSessions(): Promise<void> {
  if (!confirm("Are you sure you want to sign out everywhere on all devices?")) {
    return;
  }

  activityText.value = "Deleting sessions";

  try {
    await axios.delete(`/api/user/current/sessions`);

    // Now head to the sign-out page
    await signOut();
  } catch {
    addNotification({ type: "error", message: "Failed deleting sessions" });

    activityText.value = "";

    return;
  }
}
</script>

<style scoped lang="scss">
.explanation {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.sessions-table {
  display: grid;
  grid-template-columns: auto auto max-content max-content;
  row-gap: 4px;

  .header {
    display: contents;
    column-gap: 8px;

    .header-item {
      margin-right: 8px;

      .title {
        font-weight: bold;
      }
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 4;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.grid-table-row {
  &.expired {
    > * {
      opacity: 0.5;
    }
  }

  &:hover .delete-icon {
    opacity: 1;
  }
}

.delete-icon {
  opacity: 0;
  cursor: pointer;
  color: var(--accent-color-2);
  transition:
    color 100ms ease,
    opacity 100ms ease;
}
</style>
