import type { MathJsInstance } from "mathjs";
import type {
  StudyGetOneResponseDto,
  StudyMeasurementResponseDto,
  StudyMeasurementValueResponseDto,
} from "../studies/dto/study-get-one.dto";
import { StudyMeasurementValueSource } from "../studies/study-measurement-enums";
import { getPatientAgeInYearsWhenScanned } from "../studies/study-time";
import type {
  CalculationPatientMetric,
  MeasurementCalculationVariable,
} from "./measurement-calculation-variable";
import { MeasurementCalculationVariableType } from "./measurement-calculation-variable";
import { getMeasurementMeanValue, getPatientMetrics } from "./measurement-display";
import { calculateBiplaneVolume } from "./measurement-evaluation-biplane";
import { MeasurementName } from "./measurement-names";
import {
  convertMeasurementUnit,
  getInternalUnitForMeasurementName,
  getInternalUnitForPhysicalQuantity,
  getUnitPhysicalQuantity,
} from "./measurement-units";

/**
 * Evaluates a measurement calculation formula with the specified scope. If there is any kind of
 * error in the evaluation then `undefined` is returned.
 */
export function evaluateCalculationFormula(
  mathjsInstance: MathJsInstance,
  formula: string,
  scope: MeasurementCalculationScope
): number | undefined {
  if (
    Object.values(scope).some((n) => n === undefined || (typeof n === "number" && !isFinite(n)))
  ) {
    return undefined;
  }

  try {
    const result: unknown = mathjsInstance.evaluate(formula, scope);

    return typeof result !== "number" || !isFinite(result) ? undefined : result;
  } catch (error: unknown) {
    return undefined;
  }
}

/**
 * Finds the measurement in the list that the specified variable is taking its value from. This is
 * only relevant for measurement variables.
 * */
export function findMeasurementForVariableName(
  variableName: string | null,
  allMeasurements: StudyMeasurementResponseDto[],
  allVariables: MeasurementCalculationVariable[] | null
): StudyMeasurementResponseDto | undefined {
  if (allVariables === null || variableName === null) {
    return undefined;
  }

  for (const variable of allVariables) {
    if (
      variable.type === MeasurementCalculationVariableType.Measurement &&
      variable.variableName === variableName
    ) {
      return allMeasurements.find((m) => m.name === variable.measurementName);
    }
  }

  return undefined;
}

/** Returns the calculated values that use the provided measurement value in their calculation. */
export function getDependentMeasurementValues(
  value: StudyMeasurementValueResponseDto,
  allCalculatedValues: StudyMeasurementValueResponseDto[]
): StudyMeasurementValueResponseDto[] {
  const values = [];

  for (const calculatedValue of allCalculatedValues) {
    if (calculatedValue.calculationInputs.some((i) => i.inputMeasurementValueId === value.id)) {
      values.push(
        calculatedValue,
        ...getDependentMeasurementValues(calculatedValue, allCalculatedValues)
      );
    }
  }

  return values;
}

/**
 * Evaluates the values for all calculated measurements in the study using a bottom-up approach to
 * attempt to evaluate all values each cycle until no more values are updated and hence all values
 * have been calculated.
 */
export function evaluateCalculatedMeasurements(
  mathjsInstance: MathJsInstance,
  study: StudyGetOneResponseDto
): void {
  // Get a list of all of the calculated measurement values and set their value to null. Their value
  // will then be recalculated by this function.
  const calculatedMeasurementValues: StudyMeasurementValueResponseDto[] = [];
  for (const mmt of study.measurements) {
    for (const val of mmt.values) {
      if (val.source === StudyMeasurementValueSource.Calculated) {
        val.value = null;
        calculatedMeasurementValues.push(val);
      }
    }
  }

  // Attempt to evaluate every null calculated value until no more values can be updated. While this
  // can lead to some values being attempted to be evaluated multiple times, it ensures that all
  // dependencies are evaluated before the values that depend on them without having to do any
  // manual determination of dependencies. A maximum of 10 iterations is done to protect against
  // excessive nesting of calculations.
  for (let i = 0; i < 10; i++) {
    let didUpdate = false;

    for (const value of calculatedMeasurementValues) {
      const measurement = study.measurements.find((m) => m.id === value.measurementId);

      if (
        value.value !== null ||
        value.calculationFormula === null ||
        value.calculationOutputUnit === null ||
        measurement === undefined
      ) {
        continue;
      }

      const scope = buildScopeForCalculation(study, value);
      const calculationResult = evaluateCalculationFormula(
        mathjsInstance,
        value.calculationFormula,
        scope
      );

      if (calculationResult === undefined) {
        continue;
      }

      const unit =
        measurement.name === MeasurementName.CustomValue && measurement.customUnit !== null
          ? measurement.customUnit
          : getInternalUnitForMeasurementName(measurement.name);

      value.value = convertMeasurementUnit(calculationResult, value.calculationOutputUnit, unit);

      didUpdate = true;
    }

    if (!didUpdate) {
      break;
    }
  }
}

export type MeasurementCalculationScope = Record<string, unknown>;

/**
 * Returns the Math.js scope to use for calculating the provided calculated measurement value.
 */
function buildScopeForCalculation(
  study: StudyGetOneResponseDto,
  calculationValue: StudyMeasurementValueResponseDto
): MeasurementCalculationScope {
  const scope: Record<string, number | undefined> = {};

  const allMeasurementValues = study.measurements.flatMap((m) => m.values);

  const measurementValueInputs = calculationValue.calculationInputs
    .filter((c) => c.inputMeasurementValueId !== null)
    .map((i) => ({
      ...allMeasurementValues.find((v) => v.id === i.inputMeasurementValueId),
      variableName: i.variableName,
    }));

  const meanMeasurementInputs = calculationValue.calculationInputs
    .filter((c) => c.inputMeasurementValueId === null)
    .map((i) => ({
      ...findMeasurementForVariableName(
        i.variableName,
        study.measurements,
        calculationValue.calculationVariables
      ),
      variableName: i.variableName,
    }));

  const patientMetrics: Partial<Record<CalculationPatientMetric, number>> = {
    ...getPatientMetrics(study),
    age: getPatientAgeInYearsWhenScanned(study),
  };

  for (const variable of calculationValue.calculationVariables ?? []) {
    if (variable.type === MeasurementCalculationVariableType.PatientMetric) {
      scope[variable.variableName] = patientMetrics[variable.patientMetric];
      continue;
    }

    const valueForVariable = measurementValueInputs.find(
      (v) => v.variableName === variable.variableName
    );

    const measurement =
      valueForVariable !== undefined
        ? study.measurements.find((m) => m.id === valueForVariable.measurementId)
        : meanMeasurementInputs.find((i) => i.variableName === variable.variableName);

    if (measurement?.name !== undefined && variable.unit !== undefined) {
      const internalUnit =
        measurement.name === MeasurementName.CustomValue &&
        measurement.customUnit !== null &&
        measurement.customUnit !== undefined
          ? getInternalUnitForPhysicalQuantity(getUnitPhysicalQuantity(measurement.customUnit))
          : getInternalUnitForMeasurementName(measurement.name);

      // If this calculation input is a mean we don't want to include it in the scope if it contains
      // a null value (i.e. a unevaluated calculation). This protects against circular dependencies
      // when evaluating calculated values. We also don't calculate measurements depending on
      // measurement values that have been deselected.
      if (
        measurement.values === undefined ||
        valueForVariable?.selected === false ||
        (valueForVariable === undefined && measurement.values.some((v) => v.value === null))
      ) {
        scope[variable.variableName] = undefined;
        continue;
      }

      const value = valueForVariable?.value ?? getMeasurementMeanValue(measurement);

      scope[variable.variableName] =
        convertMeasurementUnit(value, internalUnit, variable.unit) ?? undefined;
    }
  }

  const scopeInputsAndFormulas = {
    inputs: calculationValue.calculationInputs.reduce<Record<string, string | null>>(
      (acc, i) => ({ ...acc, [i.variableName ?? ""]: i.inputMeasurementValueId }),
      {}
    ),
    calculateBiplaneVolume: (args: unknown) => calculateBiplaneVolume(study, args),
  };

  return { ...scope, ...scopeInputsAndFormulas };
}

// The set of advanced calculations which can be used in the calculation formulas, which are
// calculations we control that perform advanced calculations using the contours or other details
// outside of pure measurement values. Advanced calculations can't be assessed in the try it out
// popup and must not use mean measurement value calculation inputs.
export const advancedCalculationFormulas = {
  calculateBiplaneVolume,
};

export function doesCalculationContainAdvancedFormula(formula: string): boolean {
  return Object.keys(advancedCalculationFormulas).some((f) => formula.includes(f));
}
