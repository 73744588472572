<template>
  <Teleport to="#modal-target">
    <div class="fullscreen-dimmer" @click="onClose" />

    <div class="modal-container" :class="{ centered }" :style="{ top: topPosition }">
      <div class="modal" v-bind="$attrs">
        <button
          v-if="buttonTextLeft"
          class="blue"
          style="grid-area: header"
          data-testid="modal-header-left-button"
          @click="emits('header-button-click', 'left')"
        >
          {{ buttonTextLeft }}
        </button>

        <div class="title">{{ title }}</div>

        <button
          v-if="showCloseButton"
          class="close-button"
          data-testid="close-modal"
          @click="onClose"
        >
          <FontAwesomeIcon icon="times" />
        </button>
        <slot />

        <ActivityOverlay v-if="activityText" :text="activityText" />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke } from "@vueuse/core";
import ActivityOverlay from "./ActivityOverlay.vue";

export type HeaderButton = "left" | "right";

interface Props {
  title?: string;
  showCloseButton?: boolean;
  buttonTextLeft?: string;
  centered?: boolean;
  activityText?: string;
  topPosition?: string;
}

interface Emits {
  (event: "header-button-click", button: HeaderButton): void;
  (event: "enter-key-press"): void;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  showCloseButton: true,
  buttonTextLeft: "",
  centered: false,
  activityText: "",
  topPosition: "15vh",
});

const emits = defineEmits<Emits>();

function onClose(): void {
  if (props.activityText !== "") {
    return;
  }

  emits("header-button-click", "right");
}

onKeyStroke("Escape", onClose);
onKeyStroke("Enter", () => emits("enter-key-press"));
</script>

<style scoped lang="scss">
.fullscreen-dimmer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.modal-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  color: var(--text-color-1);
  pointer-events: none;

  &.centered {
    top: 0;
    display: grid;
    place-content: center;
  }
}

.modal {
  background: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-1);

  display: grid;
  grid-template-areas: "header" "content";
  grid-template-rows: 32px 1fr;
  place-content: stretch;
  gap: 16px;
  padding: 4px 10px 10px;
  pointer-events: auto;
  position: relative;
}

.title {
  grid-area: header;
  font-weight: bold;
  font-size: 1.3em;
  place-self: center;
  padding: 0 100px;
}

.close-button {
  grid-area: header;
  justify-self: end;
  align-self: center;
}

button {
  max-width: max-content;
  height: 28px;
  background: transparent;
}
</style>
