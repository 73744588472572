<template>
  <span
    class="measurement-badge"
    :class="{ mean: source === 'mean', 'force-default': forceDefaultFormatting }"
  >
    {{
      source === StudyMeasurementValueSource.External
        ? measurementValue?.apiKeyName
        : badgeText[source]
    }}
  </span>
</template>

<script setup lang="ts">
import { StudyMeasurementValueSource } from "@/../../backend/src/studies/study-measurement-enums";
import { StudyMeasurementValue } from "../utils/study-data";

interface Props {
  source: StudyMeasurementValueSource | "mean" | "study";
  measurementValue?: StudyMeasurementValue | undefined;
  forceDefaultFormatting?: boolean;
}

withDefaults(defineProps<Props>(), { forceDefaultFormatting: false, measurementValue: undefined });

const badgeText: Record<StudyMeasurementValueSource | "mean" | "study", string> = {
  manual: "Manual",
  dicom: "DICOM SR",
  mean: "Mean",
  study: "Study",
  external: "",
  calculated: "Calculated",
};
</script>

<style scoped lang="scss">
.measurement-badge {
  padding: 2px 4px;
  border-radius: var(--border-radius);
  font-size: 11px;
  height: min-content;
  display: grid;
  place-content: center;
  line-height: 1em;
  color: lightgray;
  border: 1px solid gray;

  &.mean:not(.force-default) {
    color: var(--accent-color-1);
    border: 1px solid var(--border-color-1);
    font-weight: bold;
  }
}
</style>
