<template>
  <div
    v-for="label in positionedMeasurementLabels"
    :key="label.measurementValue.id"
    class="measurement-label"
    :class="`align-${label.alignment ?? 'left'}`"
    :style="{
      left: `${(canvasRect.left + label.x).toFixed(1)}px`,
      top: `${(canvasRect.top + label.y).toFixed(1)}px`,
      pointerEvents:
        isMeasuring && label.measurementValue.id === TRANSIENT_MEASUREMENT_VALUE_ID
          ? 'none'
          : 'auto',
    }"
    :data-testid="`measurement-label-${label.measurement.name}`"
  >
    <Tooltip
      v-if="!isMeasuring || label.measurementValue.id === TRANSIENT_MEASUREMENT_VALUE_ID"
      placement="top"
      :offset-distance="12"
    >
      <span
        class="label-content"
        :style="{
          opacity:
            isMeasuring && label.measurementValue.id !== TRANSIENT_MEASUREMENT_VALUE_ID ? 0.5 : 1,
        }"
        :data-testid="`clip-canvas-label-${label.measurement.name}`"
        :data-test-label-value="getDisplayValue(label)"
        @click="
          emits('scroll-to-measurement', label.measurement.id, true);
          emits('highlight-measurement-card', label.measurement.id);
        "
        @mouseover="emits('measurement-value-hovered', label.measurementValue.id)"
        @mouseleave="emits('measurement-value-hovered', null)"
      >
        {{ getDisplayValue(label) }}
        <ToggleSwitch
          v-if="!isMeasuring"
          data-testid="selected-toggle"
          :model-value="label.measurementValue.selected"
          @update:model-value="
            (newValue) => updateMeasurementValueSelected(study, label.measurementValue, newValue)
          "
          @click.stop
        />
      </span>

      <template v-if="!isMeasuring" #content>
        <div
          class="measurement-tooltip"
          :data-testid="`measurement-value-tooltip-${label.measurement.name}`"
        >
          <span class="tooltip-top-row">
            <b>{{ getMeasurementDisplayName(label.measurement, "unindexed") }}</b>
            {{ getDisplayValue(label) }}
            <MeasurementBadge
              :source="label.measurementValue.source"
              :measurement-value="label.measurementValue"
            />
          </span>

          <span
            v-if="
              label.measurementValue.source === StudyMeasurementValueSource.Manual &&
              label.measurementValue.createdAt !== null &&
              label.measurementValue.createdById !== null
            "
          >
            {{ getLastUpdateTooltipTextForMeasurementValue(userList, label.measurementValue) }}
          </span>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import { computed } from "vue";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
} from "../../../backend/src/measurements/measurement-display";
import { StudyMeasurementValueSource } from "../../../backend/src/studies/study-measurement-enums";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import MeasurementBadge from "../measurements/MeasurementBadge.vue";
import { getLastUpdateTooltipTextForMeasurementValue } from "../measurements/measurement-helpers";
import { updateMeasurementValueSelected } from "../measurements/measurement-save-helpers";
import { activeMeasurement, isMeasuring } from "../measurements/measurement-tool-state";
import { TRANSIENT_MEASUREMENT_VALUE_ID } from "../measurements/tools/measurement-tool-helpers";
import { Study, StudyMeasurement } from "../utils/study-data";
import { useUserList } from "../utils/users-list";
import type { ClipViewerMeasurementLabel } from "./clip-viewer-label";

interface Props {
  study: Study;
  studyClipId: string;
  measurementLabels: ClipViewerMeasurementLabel[];
  canvasElement: HTMLCanvasElement;
  canvasRect: { left: number; top: number };
}

interface Emits {
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "measurement-value-hovered", measurementValueId: string | null): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { userList } = useUserList();

// Returns whether two measurement labels are overlapping. This assumes that labels are 120px by
// 25px in size.
const MEASUREMENT_LABEL_HEIGHT = 25;
function isLabelOverlapping(
  labelA: { x: number; y: number },
  labelB: { x: number; y: number }
): boolean {
  return (
    Math.abs(labelA.x - labelB.x) < 120 && Math.abs(labelA.y - labelB.y) < MEASUREMENT_LABEL_HEIGHT
  );
}

type ClipViewerMeasurementLabelWithAlignment = ClipViewerMeasurementLabel & {
  alignment?: "left" | "center-above" | "center-below" | "right";
};

// Takes the provided measurement labels and attempts to reposition any overlapping ones
const positionedMeasurementLabels = computed((): ClipViewerMeasurementLabelWithAlignment[] => {
  const placedLabels: ClipViewerMeasurementLabelWithAlignment[] = [];

  if (props.measurementLabels.length !== 0) {
    placedLabels.push(props.measurementLabels[0]);
  }

  for (const label of props.measurementLabels.slice(1)) {
    const overlaps = placedLabels.filter((l) => isLabelOverlapping(label, l));

    // If this label doesn't overlap with any of the already placed labels then add it to the list
    // of placed labels
    if (overlaps.length === 0) {
      placedLabels.push(label);
      continue;
    }

    // This label overlaps, so find the nearest Y position for it that doesn't overlap any existing
    // label. The most likely candidates are directly above or below any of the labels that it
    // overlaps.
    const candidateYPositions = placedLabels
      .map((l) => [l.y - MEASUREMENT_LABEL_HEIGHT, l.y + MEASUREMENT_LABEL_HEIGHT])
      .flat()
      .filter((y) => y >= 0 && y <= props.canvasElement.height - MEASUREMENT_LABEL_HEIGHT)
      .sort((a, b) => Math.abs(a - label.y) - Math.abs(b - label.y));

    const bestYPosition = candidateYPositions.find(
      (yPosition) => !placedLabels.some((l) => isLabelOverlapping({ ...label, y: yPosition }, l))
    );
    if (bestYPosition !== undefined) {
      placedLabels.push({ ...label, y: bestYPosition });
      continue;
    }

    // If we couldn't find a non-overlapping Y position, which is very unlikely, then give up on
    // moving the label and put it at its original position
    placedLabels.push(label);
  }

  if (props.studyClipId === activeMeasurement.value.studyClipId) {
    const activeMeasurementLabels = activeMeasurement.value.getMeasurementLabels(
      props.canvasElement
    );

    placedLabels.push(
      ...activeMeasurementLabels.map((label) => ({
        ...label,
        measurement: { name: label.measurementName } as StudyMeasurement,
        opacity: 1,
      }))
    );
  }

  return placedLabels;
});

function getDisplayValue(label: ClipViewerMeasurementLabel): string {
  return (
    getStudyMeasurementDisplayValue(
      {
        name: label.measurement.name,
        values: [{ ...label.measurementValue, selected: true }],
      },
      "unindexed"
    )?.fullText ?? ""
  );
}
</script>

<style scoped lang="scss">
.measurement-label {
  position: absolute;
  z-index: 10;

  &.align-center-above {
    transform: translateX(-50%) translateY(-50%);
  }

  &.align-center-below {
    transform: translateX(-50%) translateY(200%);
  }

  &.align-right {
    transform: translateX(-100%) translateY(-50%);
  }
}

.measurement-tooltip {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tooltip-top-row {
  display: flex;
  gap: 6px;
  align-items: center;
}

.label-content {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  padding: 2px 4px;
  line-height: 1em;
  white-space: nowrap;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-1);
  font-weight: bold;
  background-color: #555;
  transform: translateY(-50%);
  opacity: 1;
  transition:
    color 100ms ease,
    opacity 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
