<template>
  <div class="settings-title">PIN Settings</div>

  <div class="field">
    <div class="pin-info-message">
      <FontAwesomeIcon icon="circle-question" size="lg" />
      Your PIN is used to securely sign reports.
    </div>
  </div>

  <div class="field">
    <template v-if="currentUser.hasPin">
      <strong>Update PIN</strong>
      <p>If you have forgotten your PIN, please contact an administrator to reset it.</p>
    </template>
    <template v-else>
      <strong>Set PIN</strong>
      <p>Use the fields below to set your PIN.</p>
    </template>

    <strong>{{ currentUser.hasPin ? "Current PIN" : "New PIN" }} </strong>
    <PINField
      ref="pinField1Ref"
      v-model="pinField1"
      :data-testid="currentUser.hasPin ? 'current-pin' : 'new-pin'"
      auto-focus
    />

    <strong>{{ currentUser.hasPin ? "New PIN" : "Confirm PIN" }} </strong>
    <PINField
      v-model="pinField2"
      :data-testid="currentUser.hasPin ? 'new-pin' : 'confirm-pin'"
      :auto-focus="false"
      @enter="onUpdatePIN"
    />

    <button
      class="accented"
      data-testid="pin-submit"
      :disabled="!arePINFieldsComplete"
      @click="onUpdatePIN"
    >
      Set New PIN
    </button>

    <ActivityOverlay v-if="isUpdatingPIN" text="Updating PIN" />
  </div>
</template>

<script setup lang="ts">
import PINField from "@/components/PINField.vue";
import { addNotification } from "@/utils/notifications";
import { getRequestErrorMessage } from "@/utils/request-helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { computed, nextTick, ref } from "vue";
import { isPINValid } from "../auth/authentication";
import { currentUser } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";

const isUpdatingPIN = ref(false);

const pinField1 = ref("");
const pinField2 = ref("");
const pinField1Ref = ref<{ focusInput: () => void } | null>(null);

const arePINFieldsComplete = computed(() => {
  if (!isPINValid(pinField1.value) || !isPINValid(pinField2.value)) {
    return false;
  }

  return currentUser.hasPin || pinField1.value === pinField2.value;
});

async function onUpdatePIN(): Promise<void> {
  if (!arePINFieldsComplete.value) {
    return;
  }

  isUpdatingPIN.value = true;

  try {
    await axios.patch("/api/user/current", {
      currentPin: pinField1.value,
      newPin: pinField2.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed setting PIN",
    });

    return;
  } finally {
    isUpdatingPIN.value = false;
    pinField1.value = "";
    pinField2.value = "";

    // Focus the first PIN field after the PIN has been updated
    void nextTick(() => pinField1Ref.value?.focusInput());
  }

  currentUser.hasPin = true;

  addNotification({ type: "info", message: "New PIN has been set" });
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

.pin-info-message {
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  padding: 12px;
  font-weight: bold;
  width: 550px;
  display: flex;
  align-items: center;
  gap: 12px;
}

button {
  width: min-content;
  margin-top: 8px;
}
</style>
