<template>
  <Popper
    class="dropdown-input"
    placement="bottom-start"
    :offset-distance="2"
    :interactive="false"
    :disabled="!enabled"
    :show="show !== undefined ? show && filteredItems.length > 0 : undefined"
  >
    <slot />

    <template v-if="filteredItems.length > 0" #content>
      <div class="dropdown">
        <template v-for="(item, index) in filteredItems" :key="index">
          <template v-if="item === '-'">
            <div class="divider" />
          </template>
          <template v-else>
            <div
              class="dropdown-option"
              :data-testid="`dropdown-option-${item}`"
              :class="{ selected: currentItem === item }"
              @click="onDropdownClick(item)"
            >
              {{ item }}
            </div>
          </template>
        </template>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { computed } from "vue";

interface Props {
  currentItem: string;
  items: string[];
  enabled?: boolean;
  show?: boolean;
}

interface Emits {
  (event: "select-item", item: string): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true, show: undefined });
const emits = defineEmits<Emits>();

const filteredItems = computed(() =>
  props.items.filter((item) => item.toLowerCase().includes(props.currentItem.toLowerCase()))
);

function onDropdownClick(item: string): void {
  emits("select-item", item);
}
</script>

<style scoped lang="scss">
:deep(.dropdown-input) {
  background-color: var(--bg-color-3);
  padding: 0;
}

.dropdown {
  max-height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  padding: 4px;
}

.dropdown-option {
  border-radius: var(--border-radius);
  padding: 4px;
  margin-right: 4px;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;

  &:hover,
  &.selected {
    color: var(--text-color-2);
    background-color: var(--bg-color-4);
  }
}

.divider {
  background-color: var(--accent-color-1);
  min-height: 1px;
  margin: 8px 4px;
}
</style>
