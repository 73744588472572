import { z } from "zod";

/**
 * The messages used to synchronize notifications are sent and received to/from both the secondary
 * and primary windows. They are used to keep the exact same set of notifications visible on both.
 */
export const notificationSyncMessages = [
  z.strictObject({
    type: z.literal("notification-added"),
    notification: z.strictObject({
      id: z.string(),
      message: z.string(),
      type: z.enum(["info", "error"]),
      timeout: z.number().optional(),
    }),
  }),
  z.strictObject({
    type: z.literal("notification-removed"),
    notificationId: z.string(),
  }),
];
