export enum RegionType {
  None = "none",
  Tissue = "tissue",
  ColorFlow = "colorFlow",
  PWSpectralDoppler = "pwSpectralDoppler",
  CWSpectralDoppler = "cwSpectralDoppler",
  DopplerMeanTrace = "dopplerMeanTrace",
  DopplerModeTrace = "dopplerModeTrace",
  DopplerMaxTrace = "dopplerMaxTrace",
  VolumeTrace = "volumeTrace",
  ECGTrace = "ecgTrace",
  PulseTrace = "pulseTrace",
  PhonocardiogramTrace = "phonocardiogramTrace",
  GrayBar = "grayBar",
  ColorBar = "colorBar",
  IntegratedBackscatter = "integratedBackscatter",
  AreaTrace = "areaTrace",
  AreaOverTimeDerivative = "areaOverTimeDerivative",
  Other = "other",
}

export enum SpatialFormat {
  None = "none",
  TwoDimensional = "twoDimensional",
  MMode = "mMode",
  Spectral = "spectral",
  Waveform = "waveform",
  Graphics = "graphics",
}

export enum RegionUnit {
  None = "none",
  Percent = "percent",
  Decibels = "decibels",
  Centimeters = "centimeters",
  Seconds = "seconds",
  Hertz = "hertz",
  DecibelsPerSecond = "decibelsPerSecond",
  CentimetersPerSecond = "centimetersPerSecond",
  CentimetersSquared = "centimetersSquared",
  CentimetersSquaredPerSecond = "centimetersSquaredPerSecond",
  CentimetersCubed = "centimetersCubed",
  CentimetersCubedPerSecond = "centimetersCubedPerSecond",
  Degrees = "degrees",
}

export interface StudyClipRegion {
  type: RegionType;
  spatialFormat: SpatialFormat;
  flags: number;
  referencePixelX?: number;
  referencePixelY?: number;
  xDirectionUnit: RegionUnit;
  yDirectionUnit: RegionUnit;
  physicalDeltaX: number;
  physicalDeltaY: number;
  left: number;
  top: number;
  right: number;
  bottom: number;
}
