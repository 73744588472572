import axios from "axios";
import type { Ref } from "vue";
import { ref } from "vue";
import type { Permission } from "../../../backend/src/auth/permissions/permissions";
import { RoleType } from "../../../backend/src/user/user-role-type";

export interface UserRoleListEntry {
  id: string;
  name: string;
  type: RoleType;
  permissions: Permission[];
  isEnabled: boolean;
}

const userRoleList = ref<UserRoleListEntry[]>([]);
let isLoadInProgress = false;
const isLoaded = ref(false);

export function useUserRoleList(): Ref<UserRoleListEntry[]> {
  if (!isLoaded.value && !isLoadInProgress) {
    void loadUserRoles();
  }

  return userRoleList;
}

async function loadUserRoles(): Promise<void> {
  isLoadInProgress = true;

  try {
    userRoleList.value = (await axios.get<UserRoleListEntry[]>(`/api/user-roles`)).data;

    userRoleList.value = userRoleList.value.filter(
      (role) => role.type !== RoleType.SystemControlledGuest
    );

    isLoaded.value = true;
  } finally {
    isLoadInProgress = false;
  }
}
