<template>
  <Popper
    ref="popper"
    class="attached-files-popper"
    placement="bottom"
    :offset-distance="4"
    :disabled="study.attachedFiles.length === 0"
    @open="openPopper"
    @close="closePopper"
  >
    <Tooltip :visible="!isPopperOpen" :content="tooltipContent">
      <div @click="openPopper">
        <slot :is-popper-open="isPopperOpen" />
      </div>
    </Tooltip>

    <template #content>
      <div class="attached-files-table">
        <b>Attached Files</b>
        <div></div>

        <template v-for="file in study.attachedFiles" :key="file.id">
          <a
            :href="`/api/studies/${props.study.id}/attached-files/${file.id}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ file.fileName.substring(0, file.fileName.lastIndexOf(".")) }}
          </a>

          <Tooltip
            :visible="isLatestReportSigned"
            placement="left"
            content="Cannot delete this file from study because the latest report is signed"
          >
            <button
              class="delete-uploaded-file"
              :disabled="isLatestReportSigned"
              @click="deleteFile(file)"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </Tooltip>
        </template>

        <Tooltip
          :visible="isLatestReportSigned"
          content="Cannot attach files to study because the latest report is signed"
        >
          <button
            v-if="hasStudyUpdatePermission"
            :disabled="isLatestReportSigned"
            class="upload-file"
            data-testid="upload-file-button"
            @click="fileInputElement?.click()"
          >
            Upload file
          </button>
        </Tooltip>

        <input ref="fileInputElement" hidden type="file" accept=".pdf" @change="uploadFile" />
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import { hasStudyUpdatePermission } from "@/auth/authorization";
import Popper from "@/components/Popper.vue";
import Tooltip from "@/components/Tooltip.vue";
import { getRequestErrorMessage } from "@/utils/request-helpers";
import { getLatestReport } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { computed, ref } from "vue";
import { StudyAttachedFileResponseDto } from "../../../backend/src/studies/attached-files/dto/study-attached-file-response.dto";
import { addNotification } from "../utils/notifications";
import { Study } from "../utils/study-data";

interface Props {
  study: Study;
}

const props = defineProps<Props>();

const isPopperOpen = ref(false);

const popper = ref<{ close: () => void } | null>(null);
const fileInputElement = ref<HTMLInputElement | null>(null);

const tooltipContent = computed(() => {
  if (props.study.attachedFiles.length === 0) {
    if (isLatestReportSigned.value) {
      return "Cannot attach new files to this study because the latest report is signed";
    }

    return "Attach files to study";
  }

  return "View and attach files";
});

const isLatestReportSigned = computed(
  () => getLatestReport(props.study.reports)?.isSigned === true
);

function openPopper(): void {
  if (props.study.attachedFiles.length === 0) {
    if (isLatestReportSigned.value) {
      return;
    }

    isPopperOpen.value = false;
    fileInputElement.value?.click();
    return;
  }

  isPopperOpen.value = true;
}

function closePopper(): void {
  isPopperOpen.value = false;
}

async function uploadFile(): Promise<void> {
  const files = fileInputElement.value?.files;
  if (files === null || files === undefined || files.length === 0) {
    return;
  }

  const formData = new FormData();
  formData.append("file", files[0]);

  try {
    const newFile = await axios.post<StudyAttachedFileResponseDto>(
      `/api/studies/${props.study.id}/attached-files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // eslint-disable-next-line vue/no-mutating-props
    props.study.attachedFiles.push(newFile.data);

    addNotification({
      type: "info",
      message: "File attached to study successfully",
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed to upload file",
    });
  }

  if (fileInputElement.value) {
    fileInputElement.value.value = "";
  }
}

async function deleteFile(file: StudyAttachedFileResponseDto): Promise<void> {
  if (!confirm(`Are you sure you want to delete ${file.fileName}?`)) {
    return;
  }

  try {
    await axios.delete(`/api/studies/${props.study.id}/attached-files/${file.id}`);

    // eslint-disable-next-line vue/no-mutating-props
    props.study.attachedFiles = props.study.attachedFiles.filter((f) => f.id !== file.id);

    addNotification({
      type: "info",
      message: "File deleted successfully",
    });

    isPopperOpen.value = false;
    popper.value?.close();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      addNotification({
        type: "error",
        message: "Failed deleting file",
      });
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.attached-files-popper) {
  min-width: 200px;
  background-color: var(--bg-color-4);
  padding: 12px 8px 8px;
}

.attached-files-table {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;

  a {
    align-self: center;
  }

  .delete-uploaded-file {
    font-size: 0.8em;
    background: none;
    border: none;
    padding: 0 0;
    appearance: none;
    margin: 0;
    background-color: none;

    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }

  .upload-file {
    grid-column: 1 / 3;
    justify-self: end;
    margin-left: auto;
    padding: 4px 8px;
    background-color: var(--bg-color-3);
    border: 1px solid var(--border-color-1);
    border-radius: var(--border-radius);
    min-width: max-content;
    transition: filter 100ms ease;

    &:hover:not(:disabled) {
      cursor: pointer;
      filter: brightness(200%);
    }
  }
}
</style>
