export enum ColorMap {
  Aqua = "Aqua",
  Purple = "Purple",
  Sepia = "Sepia",
  Grey = "Grey",
}

export const colorMapMatrix = {
  Grey: `1 0 0 0 0
  0 1 0 0 0
  0 0 1 0 0
  0 0 0 1 0`,
  Aqua: `0 0 0 0 0
  0 1 0 0 0
  0 0 1 0 0
  0 0 0 1 0`,
  Purple: `1 0 1 0 0
  0 1 0 0 0
  1 0 1 0 0
  0 0 0 1 0`,
  Sepia: `0.393 0.769 0.189 0 0
  0.349 0.686 0.168 0 0
  0.272 0.534 0.131 0 0
  0 0 0 1 0`,
};
