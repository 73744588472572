/**
 * Names of all known measurements. All measurements that come from SRs or are created in the UI
 * must map through to one of these named measurements. Custom measurements are provided that allow
 * a measurement of any type (area, volume, etc.) to be given an arbitrary name.
 */
export enum MeasurementName {
  CustomValue = "customValue",

  CustomAngle = "customAngle",
  CustomArea = "customArea",
  CustomDopplerSlope = "customDopplerSlope",
  CustomMModeSlope = "customMModeSlope",
  CustomEjectionFraction = "customEjectionFraction",
  CustomDistance = "customDistance",
  CustomTime = "customTime",
  CustomVelocity = "customVelocity",
  CustomVelocityTimeIntegral = "customVelocityTimeIntegral",
  CustomVolume = "customVolume",
  CustomDisplacement = "customDisplacement",

  AbdominalAortaDiameter = "abdominalAortaDiameter",
  AorticAnnulusDiameter = "aorticAnnulusDiameter",
  AorticArchDiameter = "aorticArchDiameter",
  AorticRegurgitationAccelerationTime = "aorticRegurgitationAccelerationTime",
  AorticRegurgitationAliasingVelocity = "aorticRegurgitationAliasingVelocity",
  AorticRegurgitationDecelerationSlope = "aorticRegurgitationDecelerationSlope",
  AorticRegurgitationDecelerationTime = "aorticRegurgitationDecelerationTime",
  AorticRegurgitationEffectiveRegurgitantOrificeArea = "aorticRegurgitationEffectiveRegurgitantOrificeArea",
  AorticRegurgitationMeanGradient = "aorticRegurgitationMeanGradient",
  AorticRegurgitationMeanVelocity = "aorticRegurgitationMeanVelocity",
  AorticRegurgitationPeakGradient = "aorticRegurgitationPeakGradient",
  AorticRegurgitationPeakVelocity = "aorticRegurgitationPeakVelocity",
  AorticRegurgitationPressureHalfTime = "aorticRegurgitationPressureHalfTime",
  AorticRegurgitationProximalIsovelocitySurfaceArea = "aorticRegurgitationProximalIsovelocitySurfaceArea",
  AorticRegurgitationProximalIsovelocitySurfaceAreaRadius = "aorticRegurgitationProximalIsovelocitySurfaceAreaRadius",
  AorticRegurgitationVelocityTimeIntegral = "aorticRegurgitationVelocityTimeIntegral",
  AorticRegurgitationVenaContractaWidth = "aorticRegurgitationVenaContractaWidth",
  AorticRegurgitationVolume = "aorticRegurgitationVolume",

  AorticRootDiameter = "aorticRootDiameter",
  AorticRootDiameterMMode = "aorticRootDiameterMMode",
  AorticSinotubularJunctionDiameter = "aorticSinotubularJunctionDiameter",
  AorticValveAccelerationSlope = "aorticValveAccelerationSlope",
  AorticValveAccelerationTime = "aorticValveAccelerationTime",
  AorticValveArea = "aorticValveArea",
  AorticValveAreaByVelocityTimeIntegral = "aorticValveAreaByVelocityTimeIntegral",
  AorticValveAreaPlanimetry = "aorticValveAreaPlanimetry",
  AorticValveClosure = "aorticValveClosure",
  AorticValveCuspSeparationMMode = "aorticValveCuspSeparationMMode",
  AorticValveDecelerationTime = "aorticValveDecelerationTime",
  AorticValveDecelerationSlope = "aorticValveDecelerationSlope",
  AorticValveDimensionlessPerformanceIndex = "aorticValveDimensionlessPerformanceIndex",
  AorticValveEjectionTime = "aorticValveEjectionTime",
  AorticValveMeanGradient = "aorticValveMeanGradient",
  AorticValveMeanVelocity = "aorticValveMeanVelocity",
  AorticValvePeakGradient = "aorticValvePeakGradient",
  AorticValvePeakVelocity = "aorticValvePeakVelocity",
  AorticValvePressureHalfTime = "aorticValvePressureHalfTime",
  AorticValveVelocityRatio = "aorticValveVelocityRatio",
  AorticValveVelocityTimeIntegral = "aorticValveVelocityTimeIntegral",

  AscendingAortaDiameter = "ascendingAortaDiameter",
  AscendingAortaMeanGradient = "ascendingAortaMeanGradient",
  AscendingAortaMeanVelocity = "ascendingAortaMeanVelocity",
  AscendingAortaPeakGradient = "ascendingAortaPeakGradient",
  AscendingAortaPeakVelocity = "ascendingAortaPeakVelocity",
  AscendingAortaVelocityTimeIntegral = "ascendingAortaVelocityTimeIntegral",
  AtrioseptalDefectDiameterMajorAxis = "atrioseptalDefectDiameterMajorAxis",
  AtrioseptalDefectDiameterMinorAxis = "atrioseptalDefectDiameterMinorAxis",
  CardiacOutput = "cardiacOutput",
  DescendingAortaDiameter = "descendingAortaDiameter",
  DiastolicBloodPressure = "diastolicBloodPressure",

  DoseLengthProduct = "doseLengthProduct",

  // This measurement was incorrect and is no longer used as of PULSE-980 (Dec 2022). Prior to then,
  // all of the three E/e' ratio measurements (lateral, medial, and average) from GE machines were
  // being grouped together under this one measurement despite the fact that they are distinct. They
  // are now split out into separate mitral valve measurements below.
  EVelocityToAnnulusEVelocityRatio = "eVelocityToAnnulusEVelocityRatio",

  GlobalPeakLongitudinalStrainAverage = "globalPeakLongitudinalStrainAverage",
  GlobalPeakLongitudinalStrainA2C = "globalPeakLongitudinalStrainA2C",
  GlobalPeakLongitudinalStrainA3C = "globalPeakLongitudinalStrainA3C",
  GlobalPeakLongitudinalStrainA4C = "globalPeakLongitudinalStrainA4C",

  HeartRate = "heartRate",

  InferiorVenaCavaDiameter = "inferiorVenaCavaDiameter",
  InferiorVenaCavaDiameterExpiration = "inferiorVenaCavaDiameterExpiration",
  InferiorVenaCavaDiameterInspiration = "inferiorVenaCavaDiameterInspiration",

  InterventricularSeptumDiastolicThickness = "interventricularSeptumDiastolicThickness",
  InterventricularSeptumSystolicThickness = "interventricularSeptumSystolicThickness",
  InterventricularSeptumToPosteriorWallThicknessRatio = "interventricularSeptumToPosteriorWallThicknessRatio",

  LeftAtrialAppendageExitVelocity = "leftAtrialAppendageExitVelocity",
  LeftAtrialAppendageOcclusionDiscDepth = "leftAtrialAppendageOcclusionDiscDepth",
  LeftAtriumAorticRootRatio = "leftAtriumAorticRootRatio",
  LeftAtriumAreaDiastolicA2C = "leftAtriumAreaDiastolicA2C",
  LeftAtriumAreaDiastolicA4C = "leftAtriumAreaDiastolicA4C",
  LeftAtriumAreaSystolicA2C = "leftAtriumAreaA2CView",
  LeftAtriumAreaSystolicA4C = "leftAtriumAreaA4CView",
  LeftAtriumDiameter = "leftAtriumDiameter",
  LeftAtriumDiameterA2C = "leftAtriumDiameterA2C",
  LeftAtriumDiameterA4C = "leftAtriumDiameterA4C",
  LeftAtriumDiastolicMajorAxisA2C = "leftAtriumDiastolicMajorAxisA2C",
  LeftAtriumDiastolicMajorAxisA4C = "leftAtriumDiastolicMajorAxisA4C",
  LeftAtriumEndDiastolicVolumeBiplaneAreaLength = "leftAtriumEndDiastolicVolumeBiplaneAreaLength",
  LeftAtriumEndDiastolicVolumeBiplane = "leftAtriumEndDiastolicVolumeBiplane",
  LeftAtriumEndDiastolicVolumeUniplaneA2C = "leftAtriumEndDiastolicVolumeUniplaneA2C",
  LeftAtriumEndDiastolicVolumeUniplaneA4C = "leftAtriumEndDiastolicVolumeUniplaneA4C",
  LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA2C = "leftAtriumEndDiastolicVolumeUniplaneAreaLengthA2C",
  LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA4C = "LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA4C",
  LeftAtriumEndSystolicVolumeBiplane = "leftAtriumEndSystolicVolumeBiplane",
  LeftAtriumEndSystolicVolumeBiplaneAreaLength = "leftAtriumEndSystolicVolumeBiplaneAreaLength",
  LeftAtriumEndSystolicVolumeUniplaneA2C = "leftAtriumEndSystolicVolumeUniplaneA2C",
  LeftAtriumEndSystolicVolumeUniplaneA4C = "leftAtriumEndSystolicVolumeUniplaneA4C",
  LeftAtriumEndSystolicVolumeUniplaneAreaLengthA2C = "leftAtriumEndSystolicVolumeUniplaneAreaLengthA2C",
  LeftAtriumEndSystolicVolumeUniplaneAreaLengthA4C = "leftAtriumEndSystolicVolumeUniplaneAreaLengthA4C",

  // This measurement is deprecated and should not be used, it only exists for backwards
  // compatibility with old SR mappings that mapped both A2C & A4C to the same measurement.
  LeftAtriumSystolicMajorAxis = "leftAtriumSystolicMajorAxis",

  LeftAtriumSystolicMajorAxisA2C = "leftAtriumSystolicMajorAxisA2C",
  LeftAtriumSystolicMajorAxisA4C = "leftAtriumSystolicMajorAxisA4C",
  LeftAtriumSystolicVolumeUniplaneAreaLength = "leftAtriumSystolicVolumeUniplaneAreaLength",

  // The following measurement is deprecated and no longer mapped
  LeftAtriumSystolicVolumeUniplaneAreaLengthA4C = "leftAtriumSystolicVolumeUniplaneAreaLengthA4C",

  LeftCarotidArteryDistalEndDiastolicVelocity = "leftCarotidArteryDistalEndDiastolicVelocity",
  LeftCarotidArteryDistalMinimumDiastolicVelocity = "leftCarotidArteryDistalMinimumDiastolicVelocity",
  LeftCarotidArteryDistalPeakSystolicVelocity = "leftCarotidArteryDistalPeakSystolicVelocity",
  LeftCarotidArteryMidLongitudinalEndDiastolicVelocity = "leftCarotidArteryMidLongitudinalEndDiastolicVelocity",
  LeftCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "leftCarotidArteryMidLongitudinalMinimumDiastolicVelocity",
  LeftCarotidArteryMidLongitudinalPeakSystolicVelocity = "leftCarotidArteryMidLongitudinalPeakSystolicVelocity",
  LeftCarotidArteryProximalEndDiastolicVelocity = "leftCarotidArteryProximalEndDiastolicVelocity",
  LeftCarotidArteryProximalMinimumDiastolicVelocity = "leftCarotidArteryProximalMinimumDiastolicVelocity",
  LeftCarotidArteryProximalPeakSystolicVelocity = "leftCarotidArteryProximalPeakSystolicVelocity",

  LeftCommonCarotidArteryDistalEndDiastolicVelocity = "leftCommonCarotidArteryDistalEndDiastolicVelocity",
  LeftCommonCarotidArteryDistalMinimumDiastolicVelocity = "leftCommonCarotidArteryDistalMinDiastolicVelocity",
  LeftCommonCarotidArteryDistalPeakSystolicVelocity = "leftCommonCarotidArteryDistalPeakSystolicVelocity",
  LeftCommonCarotidArteryMidLongitudinalEndDiastolicVelocity = "leftCommonCarotidArteryMidLongitudinalEndDiastolicVelocity",
  LeftCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "leftCommonCarotidArteryMidLongitudinalMinDiastolicVelocity",
  LeftCommonCarotidArteryMidLongitudinalPeakSystolicVelocity = "leftCommonCarotidArteryMidLongitudinalPeakSystolicVelocity",
  LeftCommonCarotidArteryProximalEndDiastolicVelocity = "leftCommonCarotidArteryProximalEndDiastolicVelocity",
  LeftCommonCarotidArteryProximalMinimumDiastolicVelocity = "leftCommonCarotidArteryProximalMinDiastolicVelocity",
  LeftCommonCarotidArteryProximalPeakSystolicVelocity = "leftCommonCarotidArteryProximalPeakSystolicVelocity",

  LeftExternalCarotidArteryDistalEndDiastolicVelocity = "leftExternalCarotidArteryDistalEndDiastolicVelocity",
  LeftExternalCarotidArteryDistalMinimumDiastolicVelocity = "leftExternalCarotidArteryDistalMinDiastolicVelocity",
  LeftExternalCarotidArteryDistalPeakSystolicVelocity = "leftExternalCarotidArteryDistalPeakSystolicVelocity",
  LeftExternalCarotidArteryMidLongitudinalEndDiastolicVelocity = "leftExternalCarotidArteryMidLongitudinalEndDiastolicVelocity",
  LeftExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "leftExternalCarotidArteryMidLongitudinalMinDiastolicVelocity",
  LeftExternalCarotidArteryMidLongitudinalPeakSystolicVelocity = "leftExternalCarotidArteryMidLongitudinalPeakSystolicVelocity",
  LeftExternalCarotidArteryProximalEndDiastolicVelocity = "leftExternalCarotidArteryProximalEndDiastolicVelocity",
  LeftExternalCarotidArteryProximalMinimumDiastolicVelocity = "leftExternalCarotidArteryProximalMinDiastolicVelocity",
  LeftExternalCarotidArteryProximalPeakSystolicVelocity = "leftExternalCarotidArteryProximalPeakSystolicVelocity",

  LeftInternalCarotidArteryDistalEndDiastolicVelocity = "leftInternalCarotidArteryDistalEndDiastolicVelocity",
  LeftInternalCarotidArteryDistalMinimumDiastolicVelocity = "leftInternalCarotidArteryDistalMinDiastolicVelocity",
  LeftInternalCarotidArteryDistalPeakSystolicVelocity = "leftInternalCarotidArteryDistalPeakSystolicVelocity",
  LeftInternalCarotidArteryMidLongitudinalEndDiastolicVelocity = "leftInternalCarotidArteryMidLongitudinalEndDiastolicVelocity",
  LeftInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "leftInternalCarotidArteryMidLongitudinalMinDiastolicVelocity",
  LeftInternalCarotidArteryMidLongitudinalPeakSystolicVelocity = "leftInternalCarotidArteryMidLongitudinalPeakSystolicVelocity",
  LeftInternalCarotidArteryProximalEndDiastolicVelocity = "leftInternalCarotidArteryProximalEndDiastolicVelocity",
  LeftInternalCarotidArteryProximalMinimumDiastolicVelocity = "leftInternalCarotidArteryProximalMinDiastolicVelocity",
  LeftInternalCarotidArteryProximalPeakSystolicVelocity = "leftInternalCarotidArteryProximalPeakSystolicVelocity",
  LeftInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio = "leftInternalToCommonCarotidArteryPeakSystolicVelocityRatio",

  LeftVertebralArteryEndDiastolicVelocity = "leftVertebralArteryEndDiastolicVelocity",
  LeftVertebralArteryMinimumDiastolicVelocity = "leftVertebralArteryMinDiastolicVelocity",
  LeftVertebralArteryPeakSystolicVelocity = "leftVertebralArteryPeakSystolicVelocity",

  LeftPulmonaryArteryDiameter = "leftPulmonaryArteryDiameter",
  LeftPulmonaryArteryPeakGradient = "leftPulmonaryArteryPeakGradient",
  LeftPulmonaryArteryPeakVelocity = "leftPulmonaryArteryPeakVelocity",

  LeftVentricleDiameterMethodOfDisks = "leftVentricleDiameterMethodOfDisks",
  LeftVentricleDiastolicAreaA2C = "leftVentricleDiastolicAreaA2C",
  LeftVentricleDiastolicAreaA4C = "leftVentricleDiastolicAreaA4C",

  LeftVentricleChangeInTime = "leftVentricleChangeInTime",
  LeftVentricleContractilityFromMitralRegurgitationVelocity = "leftVentricleContractilityFromMitralRegurgitationVelocity",

  // This measurement is deprecated and should not be used, it only exists for backwards
  // compatibility with old SR mappings that mapped the non location specific peak velocity.
  LeftVentricleEarlyDiastolicTissuePeakVelocity = "leftVentricleEarlyDiastolicTissuePeakVelocity",

  LeftVentricleEToATissueVelocityRatioLateralMitralAnnulus = "leftVentricleEtoATissueVelocityRatioLateraMitralAnnulus",
  LeftVentricleEToATissueVelocityRatioMedialMitralAnnulus = "leftVentricleEtoATissueVelocityRatioMedialMitralAnnulus",
  LeftVentricleEjectionFraction4DEF = "leftVentricleEjectionFraction4DEF",
  LeftVentricleEjectionFractionAutoEF = "leftVentricleEjectionFractionAutoEF",
  LeftVentricleEjectionFractionBiplane = "leftVentricleEjectionFractionBiplane",
  LeftVentricleEjectionFractionCube = "leftVentricleEjectionFractionCube",
  LeftVentricleEjectionFractionTeichholz = "leftVentricleEjectionFractionTeichholz",
  LeftVentricleEjectionFractionUniplaneA2C = "leftVentricleEjectionFractionUniplaneA2C",
  LeftVentricleEjectionFractionUniplaneA4C = "leftVentricleEjectionFractionUniplaneA4C",
  LeftVentricleEndDiastolicVolume4DEF = "leftVentricleEndDiastolicVolume4DEF",
  LeftVentricleEndDiastolicVolumeAutoEF = "leftVentricleEndDiastolicVolumeAutoEF",
  LeftVentricleEndDiastolicVolumeBiplane = "leftVentricleEndDiastolicVolumeBiplane",
  LeftVentricleEndDiastolicVolumeCubeMethod = "leftVentricleEndDiastolicVolumeCubeMethod",
  LeftVentricleEndDiastolicVolumeTeichholz = "leftVentricleEndDiastolicVolumeTeichholz",
  LeftVentricleEndDiastolicVolumeUniplaneA2C = "leftVentricleEndDiastolicVolumeUniplaneA2C",
  LeftVentricleEndDiastolicVolumeUniplaneA3C = "leftVentricleEndDiastolicVolumeUniplaneA3C",
  LeftVentricleEndDiastolicVolumeUniplaneA4C = "leftVentricleEndDiastolicVolumeUniplaneA4C",
  LeftVentricleEndSystolicVolume4DEF = "leftVentricleEndSystolicVolume4DEF",
  LeftVentricleEndSystolicVolumeAutoEF = "leftVentricleEndSystolicVolumeAutoEF",
  LeftVentricleEndSystolicVolumeBiplane = "leftVentricleEndSystolicVolumeBiplane",
  LeftVentricleEndSystolicVolumeCubeMethod = "leftVentricleEndSystolicVolumeCubeMethod",
  LeftVentricleEndSystolicVolumeTeichholz = "leftVentricleEndSystolicVolumeTeichholz",
  LeftVentricleEndSystolicVolumeUniplaneA2C = "leftVentricleEndSystolicVolumeUniplaneA2C",
  LeftVentricleEndSystolicVolumeUniplaneA3C = "leftVentricleEndSystolicVolumeUniplaneA3C",
  LeftVentricleEndSystolicVolumeUniplaneA4C = "leftVentricleEndSystolicVolumeUniplaneA4C",
  LeftVentricleFractionalAreaChange = "leftVentricleFractionalAreaChange",
  LeftVentricleFractionalShortening = "leftVentricleFractionalShortening",
  LeftVentricleFractionalShorteningCubeMethod = "leftVentricleFractionalShorteningCubeMethod",
  LeftVentricleFractionalShorteningTeichholz = "leftVentricleFractionalShorteningTeichholz",
  LeftVentricleInflowVelocityPropagationSlope = "leftVentricleInflowVelocityPropagationSlope",
  LeftVentricleInternalEndDiastolicDimension = "leftVentricleInternalEndDiastolicDimension",
  LeftVentricleInternalSystolicDimension = "leftVentricleInternalSystolicDimension",
  LeftVentricleMajorAxisDiastolicDimensionA2C = "leftVentricleMajorAxisDiastolicDimensionA2C",
  LeftVentricleMajorAxisDiastolicDimensionA4C = "leftVentricleMajorAxisDiastolicDimensionA4C",
  LeftVentricleMajorAxisSystolicDimensionA2C = "leftVentricleMajorAxisSystolicDimensionA2C",
  LeftVentricleMajorAxisSystolicDimensionA4C = "leftVentricleMajorAxisSystolicDimensionA4C",
  LeftVentricleMass = "leftVentricleMass",
  LeftVentricleOutflowTractAccelerationTime = "leftVentricleOutflowTractAccelerationTime",
  LeftVentricleOutflowTractArea = "leftVentricleOutflowTractArea",
  LeftVentricleOutflowTractDiameter = "leftVentricleOutflowTractDiameter",
  LeftVentricleOutflowTractHeight = "leftVentricleOutflowTractHeight",
  LeftVentricleOutflowTractMeanGradient = "leftVentricleOutflowTractMeanGradient",
  LeftVentricleOutflowTractMeanVelocity = "leftVentricleOutflowTractMeanVelocity",
  LeftVentricleOutflowTractPeakGradient = "leftVentricleOutflowTractPeakGradient",
  LeftVentricleOutflowTractPeakVelocity = "leftVentricleOutflowTractPeakVelocity",
  LeftVentricleOutflowTractVelocityTimeIntegral = "leftVentricleOutflowTractVelocityTimeIntegral",
  LeftVentriclePeakStrainDispersion = "leftVentriclePeakStrainDispersion",
  LeftVentriclePosteriorWallDiastolicThickness = "leftVentriclePosteriorWallDiastolicThickness",
  LeftVentriclePosteriorWallSystolicThickness = "leftVentriclePosteriorWallSystolicThickness",
  LeftVentricleSystolicAreaA2C = "leftVentricleSystolicAreaA2C",
  LeftVentricleSystolicAreaA4C = "leftVentricleSystolicAreaA4C",
  LeftVentricleTotalVolume = "leftVentricleTotalVolume",

  LeftVentricularCavityVolume = "leftVentricularCavityVolume",
  LeftVentricularIsovolumetricContractionTime = "leftVentricularIsovolumetricContractionTime",
  LeftVentricularIsovolumetricRelaxationTime = "leftVentricularIsovolumetricRelaxationTime",

  MainPulmonaryArteryDiameter = "mainPulmonaryArteryDiameter",

  MitralAnnularPlaneSystolicExcursion = "mitralAnnularPlaneSystolicExcursion",

  MitralRegurgitationAccelerationTime = "mitralRegurgitationAccelerationTime",
  MitralRegurgitationAliasingVelocity = "mitralRegurgitationAliasingVelocity",
  MitralRegurgitationEffectiveRegurgitantOrificeArea = "mitralRegurgitationEffectiveRegurgitantOrificeArea",
  MitralRegurgitationMeanGradient = "mitralRegurgitationMeanGradient",
  MitralRegurgitationMeanVelocity = "mitralRegurgitationMeanVelocity",
  MitralRegurgitationPeakGradient = "mitralRegurgitationPeakGradient",
  MitralRegurgitationPeakVelocity = "mitralRegurgitationPeakVelocity",
  MitralRegurgitationProximalIsovelocitySurfaceArea = "mitralRegurgitationProximalIsovelocitySurfaceArea",
  MitralRegurgitationProximalIsovelocitySurfaceAreaRadius = "mitralRegurgitationProximalIsovelocitySurfaceAreaRadius",
  MitralRegurgitationVelocityTimeIntegral = "mitralRegurgitationVelocityTimeIntegral",
  MitralRegurgitationVolume = "mitralRegurgitationVolume",

  MitralValveAccelerationTime = "mitralValveAccelerationTime",
  MitralValveAccelerationTimeToDecelerationTimeRatio = "mitralValveAccelerationTimeToDecelerationTimeRatio",
  MitralValveAnnulusDiameter = "mitralValveAnnulusDiameter",
  MitralValveAnnulusEarlyDiastolicMeanVelocity = "mitralValveAnnulusEarlyDiastolicMeanVelocity",
  MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity = "mitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity",
  MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity = "mitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity",
  MitralValveAPrimeVelocityLateral = "mitralValveAPrimeVelocityLateral",
  MitralValveAPrimeVelocitySeptal = "mitralValveAPrimeVelocitySeptal",
  MitralValveAreaByPlanimetry = "mitralValveAreaByPlanimetry",
  MitralValveAreaByPressureHalfTime = "mitralValveAreaPressureHalfTime",
  MitralValveAreaByVelocityTimeIntegral = "mitralValveAreaByVelocityTimeIntegral",
  MitralValveAToERatio = "mitralValveAToERatio",
  MitralValveAWaveDuration = "mitralValveAWaveDuration",
  MitralValveAWavePeakVelocity = "mitralValveAWavePeakVelocity",
  MitralValveAWavePressureGradient = "mitralValveAWavePressureGradient",
  MitralValveDecelerationSlope = "mitralValveDecelerationSlope",
  MitralValveDecelerationTime = "mitralValveDecelerationTime",
  MitralValveDEExcursionMMode = "mitralValveDEExcursionMMode",
  MitralValveDESlopeMMode = "mitralValveDESlopeMMode",
  MitralValveEPointSeptalSeparation = "mitralValveEPointSeptalSeparation",
  MitralValveEToARatio = "mitralValveEToARatio",
  MitralValveEWaveDecelerationTime = "mitralValveEWaveDecelerationTime",
  MitralValveEWavePeakVelocity = "mitralValveEWavePeakVelocity",
  MitralValveEWavePressureGradient = "mitralValveEWavePressureGradient",
  MitralValveMeanGradient = "mitralValveMeanGradient",
  MitralValveMeanVelocity = "mitralValveMeanVelocity",
  MitralValvePeakGradient = "mitralValvePeakGradient",
  MitralValvePeakVelocity = "mitralValvePeakVelocity",
  MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityAverageEWaveRatio = "mitralValvePeakVelocityToLVPeakTissueVelocityAverageEWaveRatio",
  MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityLateralEWaveRatio = "mitralValvePeakVelocityToLVPeakTissueVelocityLateralEWaveRatio",
  MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityMedialEWaveRatio = "mitralValvePeakVelocityToLVPeakTissueVelocityMedialEWaveRatio",
  MitralValvePressureHalfTime = "mitralValvePressureHalfTime",
  MitralValvePressureHalfTimePeakVelocity = "mitralValvePressureHalfTimePeakVelocity",
  MitralValveRegurgitantFraction = "mitralValveRegurgitantFraction",
  MitralValveVelocityTimeIntegral = "mitralValveVelocityTimeIntegral",

  MyocardialPerformanceIndex = "myocardialPerformanceIndex",

  PatentDuctusArteriosusDiameter = "patentDuctusArteriosusDiameter",

  ProximalInterventricularSeptumThickness = "proximalInterventricularSeptumThickness",

  PulmonaryArterySystolicPressureCorrectedForPulmonaryValve = "pulmonaryArterySystolicPressureCorrectedForPulmonaryValve",

  PulmonaryRegurgitationAccelerationSlope = "pulmonaryRegurgitationAccelerationSlope",
  PulmonaryRegurgitationAccelerationTime = "pulmonaryValveRegurgitantAccelerationTime",
  PulmonaryRegurgitationAliasingVelocity = "pulmonaryRegurgitationAliasingVelocity",
  PulmonaryRegurgitationDecelerationTime = "pulmonaryRegurgitationDecelerationTime",
  PulmonaryRegurgitationDecelerationSlope = "pulmonaryRegurgitationDecelerationSlope",
  PulmonaryRegurgitationEffectiveRegurgitantOrificeArea = "pulmonaryRegurgitationEffectiveRegurgitantOrificeArea",
  PulmonaryRegurgitationMeanGradient = "pulmonaryValveRegurgitantMeanGradient",
  PulmonaryRegurgitationMeanVelocity = "pulmonaryValveRegurgitantMeanVelocity",
  PulmonaryRegurgitationPeakGradient = "pulmonaryValveRegurgitantPeakGradient",
  PulmonaryRegurgitationPeakVelocity = "PulmonaryValveRegurgitantPeakVelocity",
  PulmonaryRegurgitationPressureHalfTime = "pulmonaryValveRegurgitantPressureHalfTime",
  PulmonaryRegurgitationProximalIsovelocitySurfaceArea = "pulmonaryRegurgitationProximalIsovelocitySurfaceArea",
  PulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius = "pulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius",
  PulmonaryRegurgitationVelocityTimeIntegral = "pulmonaryValveRegurgitantVelocityTimeIntegral",
  PulmonaryRegurgitationVolume = "pulmonaryRegurgitationVolume",
  PulmonaryRootDiameter = "pulmonaryRootDiameter",

  PulmonaryValveAreaByVelocityTimeIntegral = "pulmonaryValveAreaByVelocityTimeIntegral",
  PulmonaryValveAccelerationSlope = "pulmonaryValveAccelerationSlope",
  PulmonaryValveAccelerationTime = "pulmonaryValveAccelerationTime",
  PulmonaryValveDecelerationTime = "pulmonaryValveDecelerationTime",
  PulmonaryValveDecelerationSlope = "pulmonaryValveDecelerationSlope",
  PulmonaryValveMeanGradient = "pulmonaryValveMeanGradient",
  PulmonaryValveMeanVelocity = "pulmonaryValveMeanVelocity",
  PulmonaryValvePeakGradient = "pulmonaryValvePeakGradient",
  PulmonaryValvePeakVelocity = "pulmonaryValvePeakVelocity",
  PulmonaryValvePressureHalfTime = "pulmonaryValvePressureHalfTime",
  PulmonaryValveVelocityTimeIntegral = "pulmonaryValveVelocityTimeIntegral",

  PulmonaryVascularResistance = "pulmonaryVascularResistance",

  PulmonaryVeinAtrialContractionReversalPeakVelocity = "pulmonaryVeinAtrialContractionReversalPeakVelocity",
  PulmonaryVeinAWaveDuration = "pulmonaryVeinAWaveDuration",
  PulmonaryVeinDiastolicPeakVelocity = "pulmonaryVeinDiastolicPeakVelocity",
  PulmonaryVeinSystolicPeakVelocity = "pulmonaryVeinSystolicPeakVelocity",
  PulmonaryVeinSystolicToDiastolicRatio = "pulmonaryVeinSystolicToDiastolicRatio",

  QpToQsRatio = "qpToQsRatio",

  RelativeWallThickness = "relativeWallThickness",

  RightAtriumDiameterDiastole = "rightAtriumDiameterDiastole",
  RightAtriumDiameterSystole = "rightAtriumDiameterSystole",
  RightAtriumDiastolicArea = "rightAtriumDiastolicArea",
  RightAtriumEndDiastolicVolumeBiplane = "rightAtriumEndDiastolicVolumeBiplane",
  RightAtriumEndDiastolicVolumeUniplaneA2C = "rightAtriumEndDiastolicVolumeUniplaneA2C",
  RightAtriumEndDiastolicVolumeUniplaneA4C = "rightAtriumEndDiastolicVolumeUniplaneA4C",
  RightAtriumEndSystolicVolumeBiplane = "rightAtriumEndSystolicVolumeBiplane",
  RightAtriumEndSystolicVolumeBiplaneAreaLength = "rightAtriumEndSystolicVolumeBiplaneAreaLength",
  RightAtriumEndSystolicVolumeUniplaneA2C = "rightAtriumEndSystolicVolumeUniplaneA2C",
  RightAtriumEndSystolicVolumeUniplaneA4C = "rightAtriumEndSystolicVolumeUniplaneA4C",
  RightAtriumEndSystolicVolumeUniplaneAreaLengthA4C = "rightAtriumEndSystolicVolumeUniplaneAreaLengthA4C",
  RightAtriumSystolicArea = "rightAtriumSystolicArea",
  RightAtriumSystolicPressure = "rightAtriumSystolicPressure",
  RightAtriumSystolicMajorAxisA4C = "rightAtriumSystolicMajorAxisA4C",

  RightCarotidArteryDistalEndDiastolicVelocity = "rightCarotidArteryDistalEndDiastolicVelocity",
  RightCarotidArteryDistalMinimumDiastolicVelocity = "rightCarotidArteryDistalMinimumDiastolicVelocity",
  RightCarotidArteryDistalPeakSystolicVelocity = "rightCarotidArteryDistalPeakSystolicVelocity",
  RightCarotidArteryMidLongitudinalEndDiastolicVelocity = "rightCarotidArteryMidLongitudinalEndDiastolicVelocity",
  RightCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "rightCarotidArteryMidLongitudinalMinimumDiastolicVelocity",
  RightCarotidArteryMidLongitudinalPeakSystolicVelocity = "rightCarotidArteryMidLongitudinalPeakSystolicVelocity",
  RightCarotidArteryProximalEndDiastolicVelocity = "rightCarotidArteryProximalEndDiastolicVelocity",
  RightCarotidArteryProximalMinimumDiastolicVelocity = "rightCarotidArteryProximalMinimumDiastolicVelocity",
  RightCarotidArteryProximalPeakSystolicVelocity = "rightCarotidArteryProximalPeakSystolicVelocity",

  RightCommonCarotidArteryDistalEndDiastolicVelocity = "rightCommonCarotidArteryDistalEndDiastolicVelocity",
  RightCommonCarotidArteryDistalMinimumDiastolicVelocity = "rightCommonCarotidArteryDistalMinDiastolicVelocity",
  RightCommonCarotidArteryDistalPeakSystolicVelocity = "rightCommonCarotidArteryDistalPeakSystolicVelocity",
  RightCommonCarotidArteryMidLongitudinalEndDiastolicVelocity = "rightCommonCarotidArteryMidLongitudinalEndDiastolicVelocity",
  RightCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "rightCommonCarotidArteryMidLongitudinalMinDiastolicVelocity",
  RightCommonCarotidArteryMidLongitudinalPeakSystolicVelocity = "rightCommonCarotidArteryMidLongitudinalPeakSystolicVelocity",
  RightCommonCarotidArteryProximalEndDiastolicVelocity = "rightCommonCarotidArteryProximalEndDiastolicVelocity",
  RightCommonCarotidArteryProximalMinimumDiastolicVelocity = "rightCommonCarotidArteryProximalMinDiastolicVelocity",
  RightCommonCarotidArteryProximalPeakSystolicVelocity = "rightCommonCarotidArteryProximalPeakSystolicVelocity",

  RightExternalCarotidArteryDistalEndDiastolicVelocity = "rightExternalCarotidArteryDistalEndDiastolicVelocity",
  RightExternalCarotidArteryDistalMinimumDiastolicVelocity = "rightExternalCarotidArteryDistalMinDiastolicVelocity",
  RightExternalCarotidArteryDistalPeakSystolicVelocity = "rightExternalCarotidArteryDistalPeakSystolicVelocity",
  RightExternalCarotidArteryMidLongitudinalEndDiastolicVelocity = "rightExternalCarotidArteryMidLongitudinalEndDiastolicVelocity",
  RightExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "rightExternalCarotidArteryMidLongitudinalMinDiastolicVelocity",
  RightExternalCarotidArteryMidLongitudinalPeakSystolicVelocity = "rightExternalCarotidArteryMidLongitudinalPeakSystolicVelocity",
  RightExternalCarotidArteryProximalEndDiastolicVelocity = "rightExternalCarotidArteryProximalEndDiastolicVelocity",
  RightExternalCarotidArteryProximalMinimumDiastolicVelocity = "rightExternalCarotidArteryProximalMinDiastolicVelocity",
  RightExternalCarotidArteryProximalPeakSystolicVelocity = "rightExternalCarotidArteryProximalPeakSystolicVelocity",

  RightInternalCarotidArteryDistalEndDiastolicVelocity = "rightInternalCarotidArteryDistalEndDiastolicVelocity",
  RightInternalCarotidArteryDistalMinimumDiastolicVelocity = "rightInternalCarotidArteryDistalMinDiastolicVelocity",
  RightInternalCarotidArteryDistalPeakSystolicVelocity = "rightInternalCarotidArteryDistalPeakSystolicVelocity",
  RightInternalCarotidArteryMidLongitudinalEndDiastolicVelocity = "rightInternalCarotidArteryMidLongitudinalEndDiastolicVelocity",
  RightInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity = "rightInternalCarotidArteryMidLongitudinalMinDiastolicVelocity",
  RightInternalCarotidArteryMidLongitudinalPeakSystolicVelocity = "rightInternalCarotidArteryMidLongitudinalPeakSystolicVelocity",
  RightInternalCarotidArteryProximalEndDiastolicVelocity = "rightInternalCarotidArteryProximalEndDiastolicVelocity",
  RightInternalCarotidArteryProximalMinimumDiastolicVelocity = "rightInternalCarotidArteryProximalMinDiastolicVelocity",
  RightInternalCarotidArteryProximalPeakSystolicVelocity = "rightInternalCarotidArteryProximalPeakSystolicVelocity",
  RightInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio = "rightInternalToCommonCarotidArteryPeakSystolicVelocityRatio",

  RightVertebralArteryEndDiastolicVelocity = "rightVertebralArteryEndDiastolicVelocity",
  RightVertebralArteryMinimumDiastolicVelocity = "rightVertebralArteryMinDiastolicVelocity",
  RightVertebralArteryPeakSystolicVelocity = "rightVertebralArteryPeakSystolicVelocity",

  RightPulmonaryArteryDiameter = "rightPulmonaryArteryDiameter",
  RightPulmonaryArteryPeakGradient = "rightPulmonaryArteryPeakGradient",
  RightPulmonaryArteryPeakVelocity = "rightPulmonaryArteryPeakVelocity",

  RightVentricleBaseMinorAxisDiastoleA4C = "rightVentricleBaseMinorAxisDiastoleA4C",
  RightVentricleChangeInTime = "rightVentricleChangeInTime",
  RightVentricleContractilityFromTricuspidRegurgitationVelocity = "rightVentricleContractilityFromTricuspidRegurgitationVelocity",
  RightVentricleMidMinorAxisDiastoleA4C = "rightVentricleMidMinorAxisDiastoleA4C",
  RightVentricleEjectionFractionUniplaneA4C = "rightVentricleEjectionFractionUniplaneA4C",
  RightVentricleEndDiastolicAreaA4C = "rightVentricleEndDiastolicAreaA4C",
  RightVentricleEndDiastolicVolumeUniplaneA4C = "rightVentricleEndDiastolicVolumeUniplaneA4C",
  RightVentricleEndSystolicAreaA4C = "rightVentricleEndSystolicAreaA4C",
  RightVentricleEndSystolicVolumeUniplaneA4C = "rightVentricleEndSystolicVolumeUniplaneA4C",
  RightVentricleFractionalAreaChange = "rightVentricleFractionalAreaChange",
  RightVentricleFreeWallSPrime = "rightVentricleFreeWallSPrime",
  RightVentricleFreeWallStrain = "rightVentricleFreeWallStrain",
  RightVentricleInternalDiastolicDimension = "rightVentricleInternalDiastolicDimension",
  RightVentricleInternalSystolicDimension = "rightVentricleInternalSystolicDimension",
  RightVentricleMajorAxis = "rightVentricleMajorAxis",
  RightVentricleOutflowTractAccelerationTime = "rightVentricleOutflowTractAccelerationTime",
  RightVentricleOutflowTractArea = "rightVentricleOutflowTractArea",
  RightVentricleOutflowTractDiameter = "rightVentricleOutflowTractDiameter",
  RightVentricleOutflowTractMeanGradient = "rightVentricleOutflowTractMeanGradient",
  RightVentricleOutflowTractMeanVelocity = "rightVentricleOutflowTractMeanVelocity",
  RightVentricleOutflowTractPeakGradient = "rightVentricleOutflowTractPeakGradient",
  RightVentricleOutflowTractPeakVelocity = "rightVentricleOutflowTractPeakVelocity",
  RightVentricleOutflowTractVelocityTimeIntegral = "rightVentricleOutflowTractVelocityTimeIntegral",
  RightVentriclePeakSystolicPressure = "rightVentriclePeakSystolicPressure",
  RightVentricleStrokeVolume = "rightVentricleStrokeVolume",

  SeptalPosteriorWallMotionDelay = "septalPosteriorWallMotionDelay",

  StrokeVolume = "strokeVolume",
  SystolicBloodPressure = "systolicBloodPressure",

  TricuspidAnnularPlaneSystolicExcursion = "tricuspidAnnularPlaneSystolicExcursion",
  TricuspidAnnulusDiameter = "tricuspidAnnulusDiameter",

  TricuspidRegurgitationAccelerationTime = "tricuspidRegurgitationAccelerationTime",
  TricuspidRegurgitationAliasingVelocity = "tricuspidRegurgitationAliasingVelocity",
  TricuspidRegurgitationEffectiveRegurgitantOrificeArea = "tricuspidRegurgitationEffectiveRegurgitantOrificeArea",
  TricuspidRegurgitationMeanGradient = "tricuspidRegurgitationMeanGradient",
  TricuspidRegurgitationMeanVelocity = "tricuspidRegurgitationMeanVelocity",
  TricuspidRegurgitationPeakGradient = "tricuspidRegurgitationPeakGradient",
  TricuspidRegurgitationPeakVelocity = "tricuspidRegurgitationPeakVelocity",
  TricuspidRegurgitationProximalIsovelocitySurfaceArea = "tricuspidRegurgitationProximalIsovelocitySurfaceArea",
  TricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius = "tricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius",
  TricuspidRegurgitationVelocityTimeIntegral = "tricuspidRegurgitationVelocityTimeIntegral",
  TricuspidRegurgitationVolume = "tricuspidRegurgitationVolume",

  TricuspidValveAccelerationTime = "tricuspidValveAccelerationTime",
  TricuspidValveAreaByVelocityTimeIntegral = "tricuspidValveAreaByVelocityTimeIntegral",
  TricuspidValveAWavePeakVelocity = "tricuspidValveAWavePeakVelocity",
  TricuspidValveEWavePeakVelocity = "tricuspidValveEWavePeakVelocity",
  TricuspidValveMeanGradient = "tricuspidValveMeanGradient",
  TricuspidValveMeanVelocity = "tricuspidValveMeanVelocity",
  TricuspidValvePeakGradient = "tricuspidValvePeakGradient",
  TricuspidValvePeakVelocity = "tricuspidValvePeakVelocity",
  TricuspidValvePressureHalfTime = "tricuspidValvePressureHalfTime",
  TricuspidValveVelocityTimeIntegral = "tricuspidValveVelocityTimeIntegral",

  VentricularSeptalDefectDiameter = "ventricularSeptalDefectDiameter",
}

/**
 * The names for all supported measurements.
 */
export const measurementNames: Record<MeasurementName, { name: string; indexedName?: string }> = {
  [MeasurementName.CustomValue]: { name: "-" },
  [MeasurementName.CustomDistance]: { name: "-" },
  [MeasurementName.CustomArea]: { name: "-" },
  [MeasurementName.CustomVolume]: { name: "-" },
  [MeasurementName.CustomEjectionFraction]: { name: "-" },
  [MeasurementName.CustomVelocityTimeIntegral]: { name: "-" },
  [MeasurementName.CustomDopplerSlope]: { name: "-" },
  [MeasurementName.CustomMModeSlope]: { name: "-" },
  [MeasurementName.CustomVelocity]: { name: "-" },
  [MeasurementName.CustomTime]: { name: "-" },
  [MeasurementName.CustomDisplacement]: { name: "-" },
  [MeasurementName.CustomAngle]: { name: "-" },

  [MeasurementName.AbdominalAortaDiameter]: { name: "Abd Ao Diameter" },
  [MeasurementName.AorticAnnulusDiameter]: { name: "Ao Annulus Diameter" },
  [MeasurementName.AorticArchDiameter]: { name: "Ao Arch Diameter" },
  [MeasurementName.AorticRegurgitationAccelerationTime]: { name: "AR Acceleration Time" },
  [MeasurementName.AorticRegurgitationAliasingVelocity]: { name: "AR Aliasing Velocity" },
  [MeasurementName.AorticRegurgitationDecelerationSlope]: { name: "AR Deceleration Slope" },
  [MeasurementName.AorticRegurgitationDecelerationTime]: { name: "AR Deceleration Time" },
  [MeasurementName.AorticRegurgitationEffectiveRegurgitantOrificeArea]: { name: "AR EROA" },
  [MeasurementName.AorticRegurgitationMeanGradient]: { name: "AR Mean Gradient" },
  [MeasurementName.AorticRegurgitationMeanVelocity]: { name: "AR Mean Velocity" },
  [MeasurementName.AorticRegurgitationPeakGradient]: { name: "AR Peak Gradient" },
  [MeasurementName.AorticRegurgitationPeakVelocity]: { name: "AR Peak Velocity" },
  [MeasurementName.AorticRegurgitationPressureHalfTime]: { name: "AR PHT" },
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceArea]: { name: "AR PISA" },
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    name: "AR PISA Radius",
  },
  [MeasurementName.AorticRegurgitationVelocityTimeIntegral]: { name: "AR VTI" },
  [MeasurementName.AorticRegurgitationVenaContractaWidth]: { name: "AR Vena Contracta" },
  [MeasurementName.AorticRegurgitationVolume]: { name: "AR Volume" },
  [MeasurementName.AorticRootDiameter]: { name: "Ao Root Diameter" },
  [MeasurementName.AorticRootDiameterMMode]: { name: "Ao Root Diameter M-Mode" },
  [MeasurementName.AorticSinotubularJunctionDiameter]: { name: "Ao STJ Diameter" },
  [MeasurementName.AorticValveAccelerationSlope]: { name: "AV Acceleration Slope" },
  [MeasurementName.AorticValveAccelerationTime]: { name: "AV AT" },
  [MeasurementName.AorticValveArea]: {
    name: "AV Area (by Vmax)",
    indexedName: "AV Area (by Vmax) Indexed",
  },
  [MeasurementName.AorticValveAreaByVelocityTimeIntegral]: {
    name: "AV Area (by VTI)",
    indexedName: "AV Area (by VTI) Indexed",
  },
  [MeasurementName.AorticValveAreaPlanimetry]: { name: "AV Area (Planimetry)" },
  [MeasurementName.AorticValveClosure]: { name: "AVC" },
  [MeasurementName.AorticValveCuspSeparationMMode]: { name: "AV Cusp Sep (M-Mode)" },
  [MeasurementName.AorticValveDecelerationTime]: { name: "AV Deceleration Time" },
  [MeasurementName.AorticValveDecelerationSlope]: { name: "AV Deceleration Slope" },
  [MeasurementName.AorticValveDimensionlessPerformanceIndex]: { name: "AV DPI" },
  [MeasurementName.AorticValveEjectionTime]: { name: "AV ET" },
  [MeasurementName.AorticValveMeanGradient]: { name: "AV Mean Gradient" },
  [MeasurementName.AorticValveMeanVelocity]: { name: "AV Mean Velocity" },
  [MeasurementName.AorticValvePeakGradient]: { name: "AV Peak Gradient" },
  [MeasurementName.AorticValvePeakVelocity]: { name: "AV Peak Velocity" },
  [MeasurementName.AorticValvePressureHalfTime]: { name: "AV PHT" },
  [MeasurementName.AorticValveVelocityTimeIntegral]: { name: "AV VTI" },
  [MeasurementName.AorticValveVelocityRatio]: { name: "AVVR" },

  [MeasurementName.AscendingAortaDiameter]: {
    name: "Asc Ao Diameter",
    indexedName: "Asc Ao Diameter Indexed",
  },
  [MeasurementName.AscendingAortaMeanGradient]: { name: "Asc Ao Mean Gradient" },
  [MeasurementName.AscendingAortaMeanVelocity]: { name: "Asc Ao Mean Velocity" },
  [MeasurementName.AscendingAortaPeakGradient]: { name: "Asc Ao Peak Gradient" },
  [MeasurementName.AscendingAortaPeakVelocity]: { name: "Asc Ao Peak Velocity" },
  [MeasurementName.AscendingAortaVelocityTimeIntegral]: { name: "Asc Ao VTI" },
  [MeasurementName.AtrioseptalDefectDiameterMajorAxis]: { name: "ASD Diameter Major" },
  [MeasurementName.AtrioseptalDefectDiameterMinorAxis]: { name: "ASD Diameter Minor" },
  [MeasurementName.CardiacOutput]: { name: "CO", indexedName: "CO Indexed" },
  [MeasurementName.DescendingAortaDiameter]: { name: "Desc Ao Diameter" },
  [MeasurementName.DiastolicBloodPressure]: { name: "Diastolic Blood Pressure" },
  [MeasurementName.EVelocityToAnnulusEVelocityRatio]: { name: "E/e' (Annular)" },

  [MeasurementName.DoseLengthProduct]: { name: "Radiation Dose (DLP)" },

  [MeasurementName.GlobalPeakLongitudinalStrainAverage]: { name: "GLS (Avg)" },
  [MeasurementName.GlobalPeakLongitudinalStrainA2C]: { name: "GLS A2C" },
  [MeasurementName.GlobalPeakLongitudinalStrainA3C]: { name: "GLS A3C" },
  [MeasurementName.GlobalPeakLongitudinalStrainA4C]: { name: "GLS A4C" },

  [MeasurementName.HeartRate]: { name: "Heart Rate" },

  [MeasurementName.InferiorVenaCavaDiameter]: { name: "IVC Diameter" },
  [MeasurementName.InferiorVenaCavaDiameterExpiration]: { name: "IVC Diameter (Exp)" },
  [MeasurementName.InferiorVenaCavaDiameterInspiration]: { name: "IVC Diameter (Insp)" },

  [MeasurementName.InterventricularSeptumDiastolicThickness]: { name: "IVSd" },
  [MeasurementName.InterventricularSeptumSystolicThickness]: { name: "IVSs" },
  [MeasurementName.InterventricularSeptumToPosteriorWallThicknessRatio]: { name: "IVS/PWT" },

  [MeasurementName.LeftAtrialAppendageExitVelocity]: { name: "LA Appendage Exit Velocity" },
  [MeasurementName.LeftAtrialAppendageOcclusionDiscDepth]: { name: "LAAO Disc Depth" },
  [MeasurementName.LeftAtriumAorticRootRatio]: { name: "LA/Ao" },
  [MeasurementName.LeftAtriumAreaDiastolicA2C]: { name: "LAAd A2C", indexedName: "LAAdi A2C" },
  [MeasurementName.LeftAtriumAreaDiastolicA4C]: { name: "LAAd A4C", indexedName: "LAAdi A4C" },
  [MeasurementName.LeftAtriumAreaSystolicA2C]: { name: "LAAs A2C", indexedName: "LAAsi A2C" },
  [MeasurementName.LeftAtriumAreaSystolicA4C]: { name: "LAAs A4C", indexedName: "LAAsi A4C" },
  [MeasurementName.LeftAtriumDiameter]: { name: "LA Diameter" },
  [MeasurementName.LeftAtriumDiameterA2C]: { name: "LA Diameter A2C" },
  [MeasurementName.LeftAtriumDiameterA4C]: { name: "LA Diameter A4C" },
  [MeasurementName.LeftAtriumDiastolicMajorAxisA2C]: { name: "LA Major Axis Diastole A2C" },
  [MeasurementName.LeftAtriumDiastolicMajorAxisA4C]: { name: "LA Major Axis Diastole A4C" },
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplane]: {
    name: "LAEDV BP",
    indexedName: "LAEDV BP Indexed",
  },
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplaneAreaLength]: { name: "LAEDV A-L BP" },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA2C]: { name: "LAEDV A2C" },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA4C]: { name: "LAEDV A4C" },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA2C]: { name: "LAEDV A-L A2C" },
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA4C]: { name: "LAEDV A-L A4C" },
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplane]: {
    name: "LAESV BP",
    indexedName: "LAESVi BP",
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplaneAreaLength]: { name: "LAESV A-L BP" },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA2C]: {
    name: "LAESV A2C",
    indexedName: "LAESV A2C Indexed",
  },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA4C]: { name: "LAESV A4C" },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA2C]: { name: "LAESV A-L A2C" },
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: { name: "LAESV A-L A4C" },

  [MeasurementName.LeftAtriumSystolicMajorAxis]: { name: "LA Maj Axis Systole" },
  [MeasurementName.LeftAtriumSystolicMajorAxisA2C]: { name: "LA Major Axis Systole A2C" },
  [MeasurementName.LeftAtriumSystolicMajorAxisA4C]: { name: "LA Major Axis Systole A4C" },
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLength]: { name: "LASV A-L Uniplane" },
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLengthA4C]: { name: "LASV A-L A4C" },

  [MeasurementName.LeftCarotidArteryDistalEndDiastolicVelocity]: {
    name: "Carotid Left Dist EDV",
  },
  [MeasurementName.LeftCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "Carotid Left Dist Min DV",
  },
  [MeasurementName.LeftCarotidArteryDistalPeakSystolicVelocity]: {
    name: "Carotid Left Dist PSV",
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "Carotid Left Mid EDV",
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "Carotid Left Mid Min DV",
  },
  [MeasurementName.LeftCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "Carotid Left Mid PSV",
  },
  [MeasurementName.LeftCarotidArteryProximalEndDiastolicVelocity]: {
    name: "Carotid Left Prox EDV",
  },
  [MeasurementName.LeftCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "Carotid Left Prox Min DV",
  },
  [MeasurementName.LeftCarotidArteryProximalPeakSystolicVelocity]: {
    name: "Carotid Left Prox PSV",
  },

  [MeasurementName.LeftCommonCarotidArteryDistalEndDiastolicVelocity]: {
    name: "L CCA Dist EDV",
  },
  [MeasurementName.LeftCommonCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "L CCA Dist Min DV",
  },
  [MeasurementName.LeftCommonCarotidArteryDistalPeakSystolicVelocity]: {
    name: "L CCA Dist PSV",
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "L CCA Mid EDV",
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "L CCA Mid Min DV",
  },
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "L CCA Mid PSV",
  },
  [MeasurementName.LeftCommonCarotidArteryProximalEndDiastolicVelocity]: {
    name: "L CCA Prox EDV",
  },
  [MeasurementName.LeftCommonCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "L CCA Prox Min DV",
  },
  [MeasurementName.LeftCommonCarotidArteryProximalPeakSystolicVelocity]: {
    name: "L CCA Prox PSV",
  },

  [MeasurementName.LeftExternalCarotidArteryDistalEndDiastolicVelocity]: {
    name: "L ECA Dist EDV",
  },
  [MeasurementName.LeftExternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "L ECA Dist Min DV",
  },
  [MeasurementName.LeftExternalCarotidArteryDistalPeakSystolicVelocity]: {
    name: "L ECA Dist PSV",
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "L ECA Mid EDV",
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "L ECA Mid Min DV",
  },
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "L ECA Mid PSV",
  },
  [MeasurementName.LeftExternalCarotidArteryProximalEndDiastolicVelocity]: {
    name: "L ECA Prox EDV",
  },
  [MeasurementName.LeftExternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "L ECA Prox Min DV",
  },
  [MeasurementName.LeftExternalCarotidArteryProximalPeakSystolicVelocity]: {
    name: "L ECA Prox PSV",
  },

  [MeasurementName.LeftVertebralArteryEndDiastolicVelocity]: {
    name: "L Vert EDV",
  },
  [MeasurementName.LeftVertebralArteryMinimumDiastolicVelocity]: {
    name: "L Vert Min DV",
  },
  [MeasurementName.LeftVertebralArteryPeakSystolicVelocity]: {
    name: "L Vert PSV",
  },

  [MeasurementName.LeftInternalCarotidArteryDistalEndDiastolicVelocity]: {
    name: "L ICA Dist EDV",
  },
  [MeasurementName.LeftInternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "L ICA Dist Min DV",
  },
  [MeasurementName.LeftInternalCarotidArteryDistalPeakSystolicVelocity]: {
    name: "L ICA Dist PSV",
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "L ICA Mid EDV",
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "L ICA Mid Min DV",
  },
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "L ICA Mid PSV",
  },
  [MeasurementName.LeftInternalCarotidArteryProximalEndDiastolicVelocity]: {
    name: "L ICA Prox EDV",
  },
  [MeasurementName.LeftInternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "L ICA Prox Min DV",
  },
  [MeasurementName.LeftInternalCarotidArteryProximalPeakSystolicVelocity]: {
    name: "L ICA Prox PSV",
  },
  [MeasurementName.LeftInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]: {
    name: "L ICA/CCA PSV Ratio",
  },

  [MeasurementName.LeftPulmonaryArteryDiameter]: { name: "LPA Diameter" },
  [MeasurementName.LeftPulmonaryArteryPeakGradient]: { name: "LPA Peak Gradient" },
  [MeasurementName.LeftPulmonaryArteryPeakVelocity]: { name: "LPA Peak Velocity" },

  [MeasurementName.LeftVentricleDiameterMethodOfDisks]: { name: "LV MoD Diameter" },
  [MeasurementName.LeftVentricleDiastolicAreaA2C]: { name: "LV Area A2Cd" },
  [MeasurementName.LeftVentricleDiastolicAreaA4C]: { name: "LV Area A4Cd" },
  [MeasurementName.LeftVentricleChangeInTime]: { name: "LV dt" },
  [MeasurementName.LeftVentricleContractilityFromMitralRegurgitationVelocity]: {
    name: "LV Contractility (dP/dt) derived from MR",
  },
  [MeasurementName.LeftVentricleEToATissueVelocityRatioLateralMitralAnnulus]: {
    name: "LV E/A Lateral",
  },
  [MeasurementName.LeftVentricleEToATissueVelocityRatioMedialMitralAnnulus]: {
    name: "LV E/A Medial",
  },
  [MeasurementName.LeftVentricleEarlyDiastolicTissuePeakVelocity]: {
    name: "LV Early Diastolic Peak Velocity",
  },
  [MeasurementName.LeftVentricleEjectionFraction4DEF]: { name: "LVEF 4D" },
  [MeasurementName.LeftVentricleEjectionFractionAutoEF]: { name: "LVEF Auto EF" },
  [MeasurementName.LeftVentricleEjectionFractionBiplane]: { name: "LVEF BP" },
  [MeasurementName.LeftVentricleEjectionFractionCube]: { name: "LVEF (Cube)" },
  [MeasurementName.LeftVentricleEjectionFractionTeichholz]: { name: "LVEF (Teichholz)" },
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA2C]: { name: "LVEF A2C" },
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA4C]: { name: "LVEF A4C" },
  [MeasurementName.LeftVentricleEndDiastolicVolume4DEF]: {
    name: "LVEDV 4D",
    indexedName: "LVEDVi 4D",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeAutoEF]: {
    name: "LVEDV Auto EF",
    indexedName: "LVEDVi Auto EF",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeBiplane]: {
    name: "LVEDV BP",
    indexedName: "LVEDVi BP",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeCubeMethod]: {
    name: "LVEDV (Cube)",
    indexedName: "LVEDVi (Cube)",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeTeichholz]: {
    name: "LVEDV (Teichholz)",
    indexedName: "LVEDVi (Teichholz)",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C]: {
    name: "LVEDV A2C",
    indexedName: "LVEDVi A2C",
  },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA3C]: { name: "LVEDV A3C" },
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C]: {
    name: "LVEDV A4C",
    indexedName: "LVEDVi A4C",
  },
  [MeasurementName.LeftVentricleEndSystolicVolume4DEF]: {
    name: "LVESV 4D",
    indexedName: "LVESVi 4D",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeAutoEF]: {
    name: "LVESV Auto EF",
    indexedName: "LVESVi Auto EF",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeBiplane]: {
    name: "LVESV BP",
    indexedName: "LVESVi BP",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeCubeMethod]: {
    name: "LVESV (Cube)",
    indexedName: "LVESVi (Cube)",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeTeichholz]: {
    name: "LVESV (Teichholz)",
    indexedName: "LVESVi (Teichholz)",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C]: {
    name: "LVESV A2C",
    indexedName: "LVESVi A2C",
  },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA3C]: { name: "LVESV A3C" },
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C]: {
    name: "LVESV A4C",
    indexedName: "LVESVi A4C",
  },
  [MeasurementName.LeftVentricleFractionalShortening]: { name: "FS" },
  [MeasurementName.LeftVentricleFractionalShorteningCubeMethod]: { name: "FS (Cube)" },
  [MeasurementName.LeftVentricleFractionalShorteningTeichholz]: { name: "FS (Teichholz)" },
  [MeasurementName.LeftVentricleFractionalAreaChange]: { name: "LV FAC" },
  [MeasurementName.LeftVentricleInflowVelocityPropagationSlope]: { name: "LV Inflow VP" },
  [MeasurementName.LeftVentricleInternalEndDiastolicDimension]: {
    name: "LVIDd",
    indexedName: "LVIDdi",
  },
  [MeasurementName.LeftVentricleInternalSystolicDimension]: {
    name: "LVIDs",
    indexedName: "LVIDsi",
  },
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA2C]: {
    name: "LV Major Axis Diastole A2C",
  },
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA4C]: {
    name: "LV Major Axis Diastole A4C",
  },
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA2C]: {
    name: "LV Major Axis Systole A2C",
  },
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA4C]: {
    name: "LV Major Axis Systole A4C",
  },

  [MeasurementName.LeftVentricleMass]: { name: "LV Mass", indexedName: "LV Mass Indexed" },
  [MeasurementName.LeftVentricleOutflowTractAccelerationTime]: { name: "LVOT Acceleration Time" },
  [MeasurementName.LeftVentricleOutflowTractArea]: { name: "LVOT Area" },
  [MeasurementName.LeftVentricleOutflowTractDiameter]: { name: "LVOT Diameter" },
  [MeasurementName.LeftVentricleOutflowTractHeight]: { name: "LVOT Height" },
  [MeasurementName.LeftVentricleOutflowTractMeanGradient]: { name: "LVOT Mean Gradient" },
  [MeasurementName.LeftVentricleOutflowTractMeanVelocity]: { name: "LVOT Mean Velocity" },
  [MeasurementName.LeftVentricleOutflowTractPeakGradient]: { name: "LVOT Peak Gradient" },
  [MeasurementName.LeftVentricleOutflowTractPeakVelocity]: { name: "LVOT Peak Velocity" },
  [MeasurementName.LeftVentricleOutflowTractVelocityTimeIntegral]: { name: "LVOT VTI" },
  [MeasurementName.LeftVentriclePeakStrainDispersion]: { name: "LV Peak Strain Dispersion" },
  [MeasurementName.LeftVentriclePosteriorWallDiastolicThickness]: { name: "LVPWd" },
  [MeasurementName.LeftVentriclePosteriorWallSystolicThickness]: { name: "LVPWs" },
  [MeasurementName.LeftVentricleSystolicAreaA2C]: { name: "LV Area A2Cs" },
  [MeasurementName.LeftVentricleSystolicAreaA4C]: { name: "LV Area A4Cs" },
  [MeasurementName.LeftVentricleTotalVolume]: { name: "LV Total Vol" },
  [MeasurementName.LeftVentricularCavityVolume]: { name: "LV Cavity Vol" },
  [MeasurementName.LeftVentricularIsovolumetricContractionTime]: { name: "LV IVCT" },
  [MeasurementName.LeftVentricularIsovolumetricRelaxationTime]: { name: "LV IVRT" },

  [MeasurementName.MainPulmonaryArteryDiameter]: { name: "MPA Diameter" },

  [MeasurementName.MitralAnnularPlaneSystolicExcursion]: { name: "MAPSE" },

  [MeasurementName.MitralRegurgitationAccelerationTime]: { name: "MR Acceleration Time" },
  [MeasurementName.MitralRegurgitationAliasingVelocity]: { name: "MR Aliasing Velocity" },
  [MeasurementName.MitralRegurgitationEffectiveRegurgitantOrificeArea]: { name: "MR EROA" },
  [MeasurementName.MitralRegurgitationMeanGradient]: { name: "MR Mean Gradient" },
  [MeasurementName.MitralRegurgitationMeanVelocity]: { name: "MR Mean Velocity" },
  [MeasurementName.MitralRegurgitationPeakGradient]: { name: "MR Peak Gradient" },
  [MeasurementName.MitralRegurgitationPeakVelocity]: { name: "MR Peak Velocity" },
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceArea]: { name: "MR PISA" },
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    name: "MR PISA Radius",
  },
  [MeasurementName.MitralRegurgitationVelocityTimeIntegral]: { name: "MR VTI" },
  [MeasurementName.MitralRegurgitationVolume]: { name: "MR Volume" },

  [MeasurementName.MitralValveAccelerationTime]: { name: "MV Acceleration Time" },
  [MeasurementName.MitralValveAccelerationTimeToDecelerationTimeRatio]: { name: "MV AT/DT" },
  [MeasurementName.MitralValveAnnulusDiameter]: { name: "MV Annulus Diameter" },
  [MeasurementName.MitralValveAnnulusEarlyDiastolicMeanVelocity]: { name: "E' Avg" },
  [MeasurementName.MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity]: { name: "E' Sept" },
  [MeasurementName.MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity]: { name: "E' Lat" },
  [MeasurementName.MitralValveAPrimeVelocityLateral]: { name: "A' Lat" },
  [MeasurementName.MitralValveAPrimeVelocitySeptal]: { name: "A' Sept" },
  [MeasurementName.MitralValveAreaByPlanimetry]: { name: "MV Area (by planimetry)" },
  [MeasurementName.MitralValveAreaByPressureHalfTime]: { name: "MV Area (by PHT)" },
  [MeasurementName.MitralValveAreaByVelocityTimeIntegral]: { name: "MV Area (by VTI)" },
  [MeasurementName.MitralValveAWaveDuration]: { name: "MV A-Wave Duration" },
  [MeasurementName.MitralValveAWavePeakVelocity]: { name: "MV A-Wave Peak Velocity" },
  [MeasurementName.MitralValveAWavePressureGradient]: { name: "MV A-Wave Pressure Gradient" },
  [MeasurementName.MitralValveDecelerationTime]: { name: "MV Deceleration Time" },
  [MeasurementName.MitralValveDEExcursionMMode]: { name: "MV D-E Excursion (M-Mode)" },
  [MeasurementName.MitralValveDESlopeMMode]: { name: "MV D-E Slope (M-Mode)" },
  [MeasurementName.MitralValveAToERatio]: { name: "MV A/E" },
  [MeasurementName.MitralValveEPointSeptalSeparation]: { name: "MV EPSS" },
  [MeasurementName.MitralValveEToARatio]: { name: "MV E/A" },
  [MeasurementName.MitralValveEWaveDecelerationTime]: { name: "MV E-Wave Deceleration Time" },
  [MeasurementName.MitralValveEWavePeakVelocity]: { name: "MV E-Wave Peak Velocity" },
  [MeasurementName.MitralValveEWavePressureGradient]: { name: "MV E-Wave Peak Gradient" },
  [MeasurementName.MitralValveMeanGradient]: { name: "MV Mean Gradient" },
  [MeasurementName.MitralValveMeanVelocity]: { name: "MV Mean Velocity" },
  [MeasurementName.MitralValvePeakGradient]: { name: "MV Peak Gradient" },
  [MeasurementName.MitralValvePeakVelocity]: { name: "MV Peak Velocity" },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityAverageEWaveRatio]: {
    name: "E/E' Avg",
  },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityLateralEWaveRatio]: {
    name: "E/E' Lat",
  },
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityMedialEWaveRatio]: {
    name: "E/E' Sept",
  },
  [MeasurementName.MitralValvePressureHalfTime]: { name: "MV PHT" },
  [MeasurementName.MitralValvePressureHalfTimePeakVelocity]: { name: "MV PHT Peak Velocity" },
  [MeasurementName.MitralValveRegurgitantFraction]: { name: "MV RF" },
  [MeasurementName.MitralValveVelocityTimeIntegral]: { name: "MV VTI" },
  [MeasurementName.MyocardialPerformanceIndex]: { name: "MPI" },
  [MeasurementName.MitralValveDecelerationSlope]: { name: "MV Decleration Slope" },

  [MeasurementName.PatentDuctusArteriosusDiameter]: { name: "PDA Diameter" },

  [MeasurementName.ProximalInterventricularSeptumThickness]: { name: "Prox IVS" },

  [MeasurementName.PulmonaryArterySystolicPressureCorrectedForPulmonaryValve]: {
    name: "PASP (corrected for PV)",
  },

  [MeasurementName.PulmonaryRegurgitationAccelerationSlope]: { name: "PR Acceleration Slope" },
  [MeasurementName.PulmonaryRegurgitationAccelerationTime]: { name: "PR Acceleration Time" },
  [MeasurementName.PulmonaryRegurgitationAliasingVelocity]: { name: "PR Aliasing Velocity" },
  [MeasurementName.PulmonaryRegurgitationEffectiveRegurgitantOrificeArea]: { name: "PR EROA" },
  [MeasurementName.PulmonaryRegurgitationDecelerationTime]: { name: "PR Deceleration Time" },
  [MeasurementName.PulmonaryRegurgitationDecelerationSlope]: { name: "PR Deceleration Slope" },
  [MeasurementName.PulmonaryRegurgitationMeanGradient]: { name: "PR Mean Gradient" },
  [MeasurementName.PulmonaryRegurgitationMeanVelocity]: { name: "PR Mean Velocity" },
  [MeasurementName.PulmonaryRegurgitationPeakGradient]: { name: "PR Peak Gradient" },
  [MeasurementName.PulmonaryRegurgitationPeakVelocity]: { name: "PR Peak Velocity" },
  [MeasurementName.PulmonaryRegurgitationPressureHalfTime]: { name: "PR PHT" },
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceArea]: { name: "PR PISA" },
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    name: "PR PISA Radius",
  },
  [MeasurementName.PulmonaryRegurgitationVelocityTimeIntegral]: { name: "PR VTI" },
  [MeasurementName.PulmonaryRegurgitationVolume]: { name: "PR Volume" },

  [MeasurementName.PulmonaryRootDiameter]: { name: "Pulm Root Diameter" },

  [MeasurementName.PulmonaryValveAreaByVelocityTimeIntegral]: { name: "PV Area (by VTI)" },
  [MeasurementName.PulmonaryValveAccelerationSlope]: { name: "PV Acceleration Slope" },
  [MeasurementName.PulmonaryValveAccelerationTime]: { name: "PV Acceleration Time" },
  [MeasurementName.PulmonaryValveDecelerationTime]: { name: "PV Deceleration Time" },
  [MeasurementName.PulmonaryValveDecelerationSlope]: { name: "PV Deceleration Slope" },
  [MeasurementName.PulmonaryValveMeanGradient]: { name: "PV Mean Gradient" },
  [MeasurementName.PulmonaryValveMeanVelocity]: { name: "PV Mean Velocity" },
  [MeasurementName.PulmonaryValvePeakGradient]: { name: "PV Peak Gradient" },
  [MeasurementName.PulmonaryValvePeakVelocity]: { name: "PV Peak Velocity" },
  [MeasurementName.PulmonaryValvePressureHalfTime]: { name: "PV PHT" },

  [MeasurementName.PulmonaryVeinAtrialContractionReversalPeakVelocity]: {
    name: "Pulvn A-Wave Reversal Peak Velocity",
  },
  [MeasurementName.PulmonaryVeinAWaveDuration]: { name: "Pulvn A-Wave Reversal Duration" },
  [MeasurementName.PulmonaryVeinDiastolicPeakVelocity]: { name: "Pulvn d Velocity" },
  [MeasurementName.PulmonaryVeinSystolicPeakVelocity]: { name: "Pulvn s Velocity" },
  [MeasurementName.PulmonaryVeinSystolicToDiastolicRatio]: { name: "Pulvn s/d" },
  [MeasurementName.PulmonaryValveVelocityTimeIntegral]: { name: "PV VTI" },
  [MeasurementName.PulmonaryVascularResistance]: { name: "PVR (Wood Units)" },

  [MeasurementName.QpToQsRatio]: { name: "Qp:Qs" },
  [MeasurementName.RelativeWallThickness]: { name: "RWT" },

  [MeasurementName.RightAtriumDiameterDiastole]: { name: "RA Diameter Diastole" },
  [MeasurementName.RightAtriumDiameterSystole]: { name: "RA Diameter Systole" },
  [MeasurementName.RightAtriumDiastolicArea]: { name: "RAAd", indexedName: "RAAdi" },
  [MeasurementName.RightAtriumEndDiastolicVolumeBiplane]: {
    name: "RAEDV BP",
    indexedName: "RAEDV BP Indexed",
  },
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA2C]: { name: "RAEDV A2C" },
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA4C]: { name: "RAEDV A4C" },
  [MeasurementName.RightAtriumEndSystolicVolumeBiplane]: {
    name: "RAESV BP",
    indexedName: "RAESV BP Indexed",
  },
  [MeasurementName.RightAtriumEndSystolicVolumeBiplaneAreaLength]: { name: "RAESV A-L BP" },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA2C]: { name: "RAESV A2C" },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA4C]: {
    name: "RAESV A4C",
    indexedName: "RAESV A4C Indexed",
  },
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: { name: "RAESV A-L A4C" },
  [MeasurementName.RightAtriumSystolicPressure]: { name: "RAP" },
  [MeasurementName.RightAtriumSystolicArea]: { name: "RAAs", indexedName: "RAAsi" },
  [MeasurementName.RightAtriumSystolicMajorAxisA4C]: { name: "RA Major Axis Systole A4C" },

  [MeasurementName.RightCarotidArteryDistalEndDiastolicVelocity]: {
    name: "Carotid Right Dist EDV",
  },
  [MeasurementName.RightCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "Carotid Right Dist Min DV",
  },
  [MeasurementName.RightCarotidArteryDistalPeakSystolicVelocity]: {
    name: "Carotid Right Dist PSV",
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "Carotid Right Mid EDV",
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "Carotid Right Mid Min DV",
  },
  [MeasurementName.RightCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "Carotid Right Mid PSV",
  },
  [MeasurementName.RightCarotidArteryProximalEndDiastolicVelocity]: {
    name: "Carotid Right Prox EDV",
  },
  [MeasurementName.RightCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "Carotid Right Prox Min DV",
  },
  [MeasurementName.RightCarotidArteryProximalPeakSystolicVelocity]: {
    name: "Carotid Right Prox PSV",
  },

  [MeasurementName.RightCommonCarotidArteryDistalEndDiastolicVelocity]: {
    name: "R CCA Dist EDV",
  },
  [MeasurementName.RightCommonCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "R CCA Dist Min DV",
  },
  [MeasurementName.RightCommonCarotidArteryDistalPeakSystolicVelocity]: {
    name: "R CCA Dist PSV",
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "R CCA Mid EDV",
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "R CCA Mid Min DV",
  },
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "R CCA Mid PSV",
  },
  [MeasurementName.RightCommonCarotidArteryProximalEndDiastolicVelocity]: {
    name: "R CCA Prox EDV",
  },
  [MeasurementName.RightCommonCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "R CCA Prox Min DV",
  },
  [MeasurementName.RightCommonCarotidArteryProximalPeakSystolicVelocity]: {
    name: "R CCA Prox PSV",
  },

  [MeasurementName.RightExternalCarotidArteryDistalEndDiastolicVelocity]: {
    name: "R ECA Dist EDV",
  },
  [MeasurementName.RightExternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "R ECA Dist Min DV",
  },
  [MeasurementName.RightExternalCarotidArteryDistalPeakSystolicVelocity]: {
    name: "R ECA Dist PSV",
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "R ECA Mid EDV",
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "R ECA Mid Min DV",
  },
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "R ECA Mid PSV",
  },
  [MeasurementName.RightExternalCarotidArteryProximalEndDiastolicVelocity]: {
    name: "R ECA Prox EDV",
  },
  [MeasurementName.RightExternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "R ECA Prox Min DV",
  },
  [MeasurementName.RightExternalCarotidArteryProximalPeakSystolicVelocity]: {
    name: "R ECA Prox PSV",
  },

  [MeasurementName.RightVertebralArteryEndDiastolicVelocity]: {
    name: "R Vert EDV",
  },
  [MeasurementName.RightVertebralArteryMinimumDiastolicVelocity]: {
    name: "R Vert Min DV",
  },
  [MeasurementName.RightVertebralArteryPeakSystolicVelocity]: {
    name: "R Vert PSV",
  },

  [MeasurementName.RightInternalCarotidArteryDistalEndDiastolicVelocity]: {
    name: "R ICA Dist EDV",
  },
  [MeasurementName.RightInternalCarotidArteryDistalMinimumDiastolicVelocity]: {
    name: "R ICA Dist Min DV",
  },
  [MeasurementName.RightInternalCarotidArteryDistalPeakSystolicVelocity]: {
    name: "R ICA Dist PSV",
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]: {
    name: "R ICA Mid EDV",
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]: {
    name: "R ICA Mid Min DV",
  },
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]: {
    name: "R ICA Mid PSV",
  },
  [MeasurementName.RightInternalCarotidArteryProximalEndDiastolicVelocity]: {
    name: "R ICA Prox EDV",
  },
  [MeasurementName.RightInternalCarotidArteryProximalMinimumDiastolicVelocity]: {
    name: "R ICA Prox Min DV",
  },
  [MeasurementName.RightInternalCarotidArteryProximalPeakSystolicVelocity]: {
    name: "R ICA Prox PSV",
  },
  [MeasurementName.RightInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]: {
    name: "R ICA/CCA PSV Ratio",
  },
  [MeasurementName.RightPulmonaryArteryDiameter]: { name: "RPA Diameter" },
  [MeasurementName.RightPulmonaryArteryPeakGradient]: { name: "RPA Peak Gradient" },
  [MeasurementName.RightPulmonaryArteryPeakVelocity]: { name: "RPA Peak Velocity" },

  [MeasurementName.RightVentricleBaseMinorAxisDiastoleA4C]: { name: "RV Base Diameter" },
  [MeasurementName.RightVentricleMidMinorAxisDiastoleA4C]: { name: "RV Mid Diameter" },
  [MeasurementName.RightVentricleEjectionFractionUniplaneA4C]: { name: "RVEF A4C" },
  [MeasurementName.RightVentricleEndDiastolicAreaA4C]: { name: "RVEDA A4C" },
  [MeasurementName.RightVentricleEndDiastolicVolumeUniplaneA4C]: {
    name: "RVEDV A4C",
    indexedName: "RVEDVi A4C",
  },
  [MeasurementName.RightVentricleEndSystolicAreaA4C]: { name: "RVESA A4C" },
  [MeasurementName.RightVentricleEndSystolicVolumeUniplaneA4C]: {
    name: "RVESV A4C",
    indexedName: "RVESVi A4C",
  },
  [MeasurementName.RightVentricleFractionalAreaChange]: { name: "RV FAC" },
  [MeasurementName.RightVentricleFreeWallSPrime]: { name: "RV Free Wall S'" },
  [MeasurementName.RightVentricleFreeWallStrain]: { name: "RV Free Wall Strain" },
  [MeasurementName.RightVentricleInternalDiastolicDimension]: { name: "RVIDd" },
  [MeasurementName.RightVentricleInternalSystolicDimension]: { name: "RVIDs" },
  [MeasurementName.RightVentricleMajorAxis]: { name: "RV Major Axis" },
  [MeasurementName.RightVentricleOutflowTractAccelerationTime]: { name: "RVOT Acceleration Time" },
  [MeasurementName.RightVentricleOutflowTractArea]: { name: "RVOT Area" },
  [MeasurementName.RightVentricleOutflowTractDiameter]: { name: "RVOT Diameter" },
  [MeasurementName.RightVentricleOutflowTractMeanGradient]: { name: "RVOT Mean Gradient" },
  [MeasurementName.RightVentricleOutflowTractMeanVelocity]: { name: "RVOT Mean Velocity" },
  [MeasurementName.RightVentricleOutflowTractPeakGradient]: { name: "RVOT Peak Gradient" },
  [MeasurementName.RightVentricleOutflowTractPeakVelocity]: { name: "RVOT Peak Velocity" },
  [MeasurementName.RightVentricleOutflowTractVelocityTimeIntegral]: { name: "RVOT VTI" },
  [MeasurementName.RightVentriclePeakSystolicPressure]: { name: "RVSP" },
  [MeasurementName.RightVentricleStrokeVolume]: { name: "RV SV" },
  [MeasurementName.RightVentricleChangeInTime]: { name: "RV dt" },
  [MeasurementName.RightVentricleContractilityFromTricuspidRegurgitationVelocity]: {
    name: "RV Contractility (dP/dt) derived from TR",
  },

  [MeasurementName.SeptalPosteriorWallMotionDelay]: { name: "SPWMD" },

  [MeasurementName.StrokeVolume]: { name: "SV", indexedName: "SV Indexed" },

  [MeasurementName.SystolicBloodPressure]: { name: "Systolic Blood Pressure" },

  [MeasurementName.TricuspidAnnularPlaneSystolicExcursion]: { name: "TAPSE" },
  [MeasurementName.TricuspidAnnulusDiameter]: { name: "Tricuspid Annulus Diameter" },

  [MeasurementName.TricuspidRegurgitationAccelerationTime]: { name: "TR Acceleration Time" },
  [MeasurementName.TricuspidRegurgitationAliasingVelocity]: { name: "TR Aliasing Velocity" },
  [MeasurementName.TricuspidRegurgitationEffectiveRegurgitantOrificeArea]: { name: "TR EROA" },
  [MeasurementName.TricuspidRegurgitationMeanGradient]: { name: "TR Mean Gradient" },
  [MeasurementName.TricuspidRegurgitationMeanVelocity]: { name: "TR Mean Velocity" },
  [MeasurementName.TricuspidRegurgitationPeakGradient]: { name: "TR Peak Gradient" },
  [MeasurementName.TricuspidRegurgitationPeakVelocity]: { name: "TR Peak Velocity" },
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceArea]: { name: "TR PISA" },
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius]: {
    name: "TR PISA Radius",
  },
  [MeasurementName.TricuspidRegurgitationVelocityTimeIntegral]: { name: "TR VTI" },
  [MeasurementName.TricuspidRegurgitationVolume]: { name: "TR Volume" },

  [MeasurementName.TricuspidValveAccelerationTime]: { name: "TV Acceleration Time" },
  [MeasurementName.TricuspidValveAreaByVelocityTimeIntegral]: { name: "TV Area (by VTI)" },
  [MeasurementName.TricuspidValveAWavePeakVelocity]: { name: "TV A-Wave Peak Velocity" },
  [MeasurementName.TricuspidValveEWavePeakVelocity]: { name: "TV E-Wave Peak Velocity" },
  [MeasurementName.TricuspidValveMeanGradient]: { name: "TV Mean Gradient" },
  [MeasurementName.TricuspidValveMeanVelocity]: { name: "TV Mean Velocity" },
  [MeasurementName.TricuspidValvePeakGradient]: { name: "TV Peak Gradient" },
  [MeasurementName.TricuspidValvePeakVelocity]: { name: "TV Peak Velocity" },
  [MeasurementName.TricuspidValvePressureHalfTime]: { name: "TV PHT" },
  [MeasurementName.TricuspidValveVelocityTimeIntegral]: { name: "TV VTI" },

  [MeasurementName.VentricularSeptalDefectDiameter]: { name: "VSD Diameter" },
};

/**
 * Returns whether the specified measurement is a custom measurement, i.e. it uses the "customName"
 * value to specify its name.
 */
export function isCustomMeasurement(measurementName: MeasurementName): boolean {
  return (
    measurementName === MeasurementName.CustomValue ||
    measurementName === MeasurementName.CustomAngle ||
    measurementName === MeasurementName.CustomDistance ||
    measurementName === MeasurementName.CustomArea ||
    measurementName === MeasurementName.CustomVolume ||
    measurementName === MeasurementName.CustomEjectionFraction ||
    measurementName === MeasurementName.CustomVelocityTimeIntegral ||
    measurementName === MeasurementName.CustomDopplerSlope ||
    measurementName === MeasurementName.CustomMModeSlope ||
    measurementName === MeasurementName.CustomVelocity ||
    measurementName === MeasurementName.CustomTime ||
    measurementName === MeasurementName.CustomDisplacement
  );
}
