import axios, { type AxiosResponse } from "axios";
import type { Ref } from "vue";
import { ref } from "vue";
import { addNotification } from "../utils/notifications";
import type { MeasurementSequence } from "../utils/study-data";

const measurementSequences = ref<MeasurementSequence[]>([]);
let isLoadInProgress = false;
const isLoaded = ref(false);

export function useMeasurementSequenceList(): Ref<MeasurementSequence[]> {
  if (!isLoaded.value && !isLoadInProgress) {
    void load();
  }

  return measurementSequences;
}

async function load(): Promise<void> {
  isLoadInProgress = true;

  try {
    let response: AxiosResponse<MeasurementSequence[]> | undefined = undefined;

    try {
      response = await axios.get<MeasurementSequence[]>("/api/measurement-sequences");
    } catch (error) {
      addNotification({ type: "error", message: "Failed loading measurement sequences" });
      return;
    }

    measurementSequences.value = response.data;

    isLoaded.value = true;
  } finally {
    isLoadInProgress = false;
  }
}
