<template>
  <!-- It's mandatory to use inline styles for the footer content. -->
  <div
    ref="footer"
    :style="{
      fontFamily,
      width: `calc(100% - ${reportStructure.margins.horizontal * 2}em)`,
      padding: `${reportStructure.margins.bottom}em ${reportStructure.margins.horizontal}em 0px`,
      display: 'grid',
      gridTemplateColumns: '18% 60% 18%',
      gap: '2%',
      ...(mode === ReportContentMode.GenerateHTML ? { fontSize: '10px' } : {}),
    }"
    data-testid="report-template-footer"
  >
    <!-- Empty space on the left to match where the page number sits on the right -->
    <div />

    <div
      v-if="mode === ReportContentMode.EditReportStructure"
      class="rpt-fancy-input footer-center-content"
    >
      <!-- eslint-disable vue/no-mutating-props -->
      <ResizingTextbox
        v-model="reportStructure.footerText"
        class="footer-input"
        data-testid="report-template-footer-input"
        :font-size="`${reportStructure.fontSizes.footer}em`"
        font-weight="bold"
        text-align="center"
        placeholder="Footer text"
        allow-newlines
        :scale-factor="scaleFactor"
        @update:model-value="emits('mutate-structure', createFooterTextMutation())"
      />

      <Tooltip
        v-if="isCalculation(reportStructure.footerText)"
        :content="`${
          getTextFieldCalculationErrors(reportStructure.footerText, getEmptyReportStructure()) ??
          'The calculation is valid'
        }. Click to view documentation.`"
        placement="right"
      >
        <RouterLink
          :to="{ name: 'settings-reporting-calculations' }"
          class="calculation-status"
          target="_blank"
        >
          <FontAwesomeIcon
            v-if="
              getTextFieldCalculationErrors(reportStructure.footerText, getEmptyReportStructure())
            "
            class="invalid"
            icon="xmark"
          />
          <FontAwesomeIcon v-else icon="check" class="valid" />
        </RouterLink>
      </Tooltip>
    </div>

    <template v-else>
      <strong
        :style="{
          textAlign: 'center',
          fontSize: `${reportStructure.fontSizes.footer}em`,
        }"
        data-testid="report-template-footer-text"
      >
        <TextWithLineBreaks
          :text="evaluateTextContainingCalculations(reportStructure.footerText, calculationScope)"
        />
      </strong>

      <div
        v-if="mode === ReportContentMode.GenerateHTML"
        :style="{
          fontSize: `${reportStructure.fontSizes.footer}em`,
          placeSelf: 'end end',
        }"
      >
        <div>Page <span class="pageNumber" /> / <span class="totalPages" /></div>
      </div>
      <div v-else />
    </template>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, watch } from "vue";
import {
  ReportStructure,
  getEmptyReportStructure,
} from "../../../backend/src/reporting/report-structure";
import ResizingTextbox from "../components/ResizingTextbox.vue";
import Tooltip from "../components/Tooltip.vue";
import type { Study, StudyReport } from "../utils/study-data";
import TextWithLineBreaks from "./TextWithLineBreaks.vue";
import {
  evaluateTextContainingCalculations,
  getTextFieldCalculationErrors,
  isCalculation,
} from "./report-calculation";
import { getCalculationScope } from "./report-calculation-scope";
import { ReportContentMode } from "./report-content";
import { ReportStructureMutation, createFooterTextMutation } from "./report-structure-mutations";

interface Props {
  mode: ReportContentMode;
  study: Study;
  report: StudyReport;
  reportStructure: ReportStructure;
  fontFamily: string;
  scaleFactor: number;
}

interface Emits {
  (event: "update-footer-element", footer: HTMLElement | null): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const footer = ref<HTMLElement | null>(null);
watch(footer, () => emits("update-footer-element", footer.value));

const calculationScope = computed(() => getCalculationScope(props.study, props.report));
</script>

<style scoped lang="scss">
.footer-center-content {
  display: flex;
  gap: 4px;
}

.footer-input {
  width: 100%;
}

.calculation-status {
  background-color: transparent;

  svg {
    transition: color 100ms ease;

    &.valid {
      color: green;
    }

    &.invalid {
      color: red;
    }
  }
}
</style>
