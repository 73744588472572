import type { MeasurementName } from "@/../../backend/src/measurements/measurement-names";
import type { StudyClip, StudyMeasurementValue } from "@/utils/study-data";
import type { Ref } from "vue";
import { ref } from "vue";
import { MeasurementToolName } from "../../../backend/src/measurements/measurement-tool-names";
import { removeNullish } from "../utils/array-helpers";
import {
  activeMeasurementSequence,
  type MeasurementToolCreationFunction,
} from "./measurement-tool-state";
import {
  createDopplerSlopeMeasurementTool,
  isDopplerSlopeMeasurableOnStudyClip,
} from "./tools/doppler/measurement-tool-doppler-slope";
import {
  createMModeSlopeMeasurementTool,
  isMModeSlopeMeasurableOnStudyClip,
} from "./tools/doppler/measurement-tool-m-mode-slope";
import {
  createVelocityMeasurementTool,
  isVelocityMeasurableOnStudyClip,
} from "./tools/doppler/measurement-tool-velocity";
import {
  createVTIMeasurementTool,
  isVTIMeasurableOnStudyClip,
} from "./tools/doppler/measurement-tool-vti";
import {
  createDisplacementMeasurementTool,
  isDisplacementMeasurableOnStudyClip,
} from "./tools/linear/measurement-tool-displacement";
import {
  createDistanceMeasurementTool,
  isDistanceMeasurableOnStudyClip,
} from "./tools/linear/measurement-tool-distance";
import {
  createTimeMeasurementTool,
  isTimeMeasurableOnStudyClip,
} from "./tools/linear/measurement-tool-time";
import type {
  AssociatedMeasurement,
  MeasurementToolBatchChangeRequest,
  MeasurementToolMeasurementCreationRequest,
  MeasurementToolMeasurementDeleteRequest,
  MeasurementToolMeasurementUpdateRequest,
  MeasurementToolRecreationDetails,
  MeasurementValueWithMeasurementNameAndId,
} from "./tools/measurement-tool";
import {
  createAngleMeasurementTool,
  isAngleMeasurableOnStudyClip,
} from "./tools/measurement-tool-angle";
import {
  createAreaMeasurementTool,
  isAreaMeasurableOnStudyClip,
} from "./tools/measurement-tool-area";
import { createEjectionFractionTool } from "./tools/measurement-tool-ef";
import {
  createVolumeMeasurementTool,
  isVolumeMeasurableOnStudyClip,
} from "./tools/measurement-tool-volume";

/** Returns the function that creates the specified measurement tool name. */
export function getCreationFunctionForMeasurementToolName(
  type: MeasurementToolName
): MeasurementToolCreationFunction {
  switch (type) {
    case MeasurementToolName.Angle:
      return createAngleMeasurementTool;
    case MeasurementToolName.Area:
      return createAreaMeasurementTool;
    case MeasurementToolName.Distance:
      return createDistanceMeasurementTool;
    case MeasurementToolName.Time:
      return createTimeMeasurementTool;
    case MeasurementToolName.Displacement:
      return createDisplacementMeasurementTool;
    case MeasurementToolName.Volume:
      return createVolumeMeasurementTool;
    case MeasurementToolName.EjectionFraction:
      return createEjectionFractionTool;
    case MeasurementToolName.VelocityTimeIntegral:
      return createVTIMeasurementTool;
    case MeasurementToolName.DopplerSlope:
      return createDopplerSlopeMeasurementTool;
    case MeasurementToolName.MModeSlope:
      return createMModeSlopeMeasurementTool;
    case MeasurementToolName.Velocity:
      return createVelocityMeasurementTool;
  }
}

/** Returns the list of measurements that can be drawn with the specified measurement tool. */
export function isToolMeasurableOnClip(tool: MeasurementToolName, clip: StudyClip): boolean {
  switch (tool) {
    case MeasurementToolName.Angle:
      return isAngleMeasurableOnStudyClip(clip);
    case MeasurementToolName.Distance:
      return isDistanceMeasurableOnStudyClip(clip);
    case MeasurementToolName.Time:
      return isTimeMeasurableOnStudyClip(clip);
    case MeasurementToolName.Displacement:
      return isDisplacementMeasurableOnStudyClip(clip);
    case MeasurementToolName.Area:
      return isAreaMeasurableOnStudyClip(clip);
    case MeasurementToolName.Volume:
      return isVolumeMeasurableOnStudyClip(clip);
    case MeasurementToolName.EjectionFraction:
      return isVolumeMeasurableOnStudyClip(clip);
    case MeasurementToolName.VelocityTimeIntegral:
      return isVTIMeasurableOnStudyClip(clip);
    case MeasurementToolName.DopplerSlope:
      return isDopplerSlopeMeasurableOnStudyClip(clip);
    case MeasurementToolName.MModeSlope:
      return isMModeSlopeMeasurableOnStudyClip(clip);
    case MeasurementToolName.Velocity:
      return isVelocityMeasurableOnStudyClip(clip);
  }
}

/**
 * Sets the initial enabled state & the underlying measurement value ID for associated measurements
 * when a saved measurement is loaded into the measurement tool on editing. The correct associated
 * measurements are enabled based on the measurement name.
 */
export function setAssociatedMeasurementDetailsOnEdit(
  associatedMeasurements: Partial<Record<string, AssociatedMeasurement>>,
  measurementsToEnable: MeasurementToolRecreationDetails[]
): void {
  for (const measurement of Object.values(associatedMeasurements)) {
    if (measurement?.enabled === undefined) {
      continue;
    }

    // Match the measurement value in the the current set of associated measurements to the
    // measurement value in the set of measurements to enable
    const matchedValue = measurementsToEnable.find((m) => m.measurementName === measurement.name);

    if (matchedValue === undefined) {
      measurement.enabled.value = false;
    } else {
      measurement.enabled.value = true;
      measurement.measurementValueId.value = matchedValue.id;
    }
  }
}

/**
 * Builds the batch measurement change requests for the measurement tool given a main measurement
 * creation request and its associated measurements. This function handles creating & editing saved
 * measurements, including the appropriate create/updates/deletes as required for associated
 * measurements which can be created/deleted by being ticked on/off.
 */
export function buildMeasurementToolBatchChangeRequest(
  mainPayload: MeasurementToolMeasurementCreationRequest & { value: number },
  savedMeasurementBeingEdited: StudyMeasurementValue | undefined,
  associatedMeasurements: Partial<Record<string, AssociatedMeasurement>> = {},
  previouslySavedAssociatedMeasurements: MeasurementValueWithMeasurementNameAndId[] = []
): MeasurementToolBatchChangeRequest {
  const creationRequests: MeasurementToolMeasurementCreationRequest[] = [];
  const updateRequests: MeasurementToolMeasurementUpdateRequest[] = [];
  const deleteRequests: MeasurementToolMeasurementDeleteRequest[] = [];

  const associatedMeasurementValues = removeNullish(Object.values(associatedMeasurements));

  // All measurements that are enabled are created when we're not editing
  if (savedMeasurementBeingEdited === undefined) {
    creationRequests.push(
      mainPayload,
      ...associatedMeasurementValues.filter((m) => m.enabled.value)
    );

    return { creates: creationRequests };
  }

  // If we're editing, we need to figure out which measurements have been added, updated, or
  // deleted. The main measurement is always updated
  updateRequests.push({
    ...mainPayload,
    measurementId: savedMeasurementBeingEdited.measurementId,
    measurementValueId: savedMeasurementBeingEdited.id,
  });

  const previouslySavedAssociatedValueIds = previouslySavedAssociatedMeasurements.map((m) => m.id);

  function findPreviouslySavedMeasurement(id: string): MeasurementValueWithMeasurementNameAndId {
    const saved = previouslySavedAssociatedMeasurements.find((m) => m.id === id);
    if (saved === undefined) {
      throw Error("Unable to find saved measurement");
    }

    return saved;
  }

  for (const measurement of associatedMeasurementValues) {
    // Measurements that are now enabled that weren't before are created.
    if (
      measurement.enabled.value &&
      !previouslySavedAssociatedValueIds.includes(measurement.measurementValueId.value)
    ) {
      creationRequests.push(measurement);
    }

    // Measurements that were enabled before and are still enabled are updated
    if (
      measurement.enabled.value &&
      previouslySavedAssociatedValueIds.includes(measurement.measurementValueId.value)
    ) {
      const savedMeasurement = findPreviouslySavedMeasurement(measurement.measurementValueId.value);

      updateRequests.push({
        ...measurement,
        frame: measurement.frame,
        contour: measurement.contour,
        measurementId: savedMeasurement.measurementId,
        measurementValueId: savedMeasurement.id,
      });
    }

    // Measurements that are now disabled that were enabled before are deleted
    if (
      !measurement.enabled.value &&
      previouslySavedAssociatedValueIds.includes(measurement.measurementValueId.value)
    ) {
      const savedMeasurement = findPreviouslySavedMeasurement(measurement.measurementValueId.value);

      deleteRequests.push({
        measurementId: savedMeasurement.measurementId,
        measurementValueId: savedMeasurement.id,
      });
    }
  }

  // Delete any associated measurements that previously existed but don't appear at all in the
  // current associated measurements. This occurs when the measurement has been renamed to one that
  // doesn't support that type of associated measurement (e.g. AV Area by PHT in non MV-slopes)
  deleteRequests.push(
    ...previouslySavedAssociatedMeasurements
      .filter((m) => !associatedMeasurementValues.some((v) => v.measurementValueId.value === m.id))
      .map((m) => ({ measurementId: m.measurementId, measurementValueId: m.id }))
  );

  return { creates: creationRequests, updates: updateRequests, deletes: deleteRequests };
}

/**
 * Create the default refs for enabled state & underlying measurement value ID in editing for
 * associated measurements. These need to be generated outside the computed so the enabled & value
 * ID persists between re-computations of the computed.
 */
export function generateAssociatedMeasurementRefs(): {
  enabled: Ref<boolean>;
  measurementValueId: Ref<string>;
} {
  return {
    enabled: ref(false),
    measurementValueId: ref(""),
  };
}

/** Sets the default enabled state for the given set of associated measurements. */
export function setDefaultEnabledForAssociatedMeasurements(
  associatedMeasurements: Partial<Record<string, AssociatedMeasurement>>,
  associatedNames: Partial<Record<string, MeasurementName>>
): void {
  for (const [key, associatedMeasurement] of Object.entries(associatedMeasurements)) {
    const name = associatedNames[key];

    if (associatedMeasurement !== undefined && name !== undefined) {
      associatedMeasurement.enabled.value = getAssociatedMeasurementDefaultEnabledState(name);
    }
  }
}

function getAssociatedMeasurementDefaultEnabledState(name: MeasurementName): boolean {
  if (activeMeasurementSequence.value) {
    return activeMeasurementSequence.value.sequence.steps[
      activeMeasurementSequence.value.currentStepIndex
    ].enabledAssociatedMeasurements.includes(name);
  }

  // Currently all associated measurements are enabled by default when there is no active
  // measurement sequence

  return true;
}
