export enum WebhookStudySendMode {
  SendAutomatically = "sendAutomatically",
  SendOnRequest = "sendOnRequest",
  Disabled = "disabled",
}

export function getWebhookStudySendModeDisplayText(mode: WebhookStudySendMode): string {
  switch (mode) {
    case WebhookStudySendMode.SendAutomatically:
      return "Send Automatically";
    case WebhookStudySendMode.SendOnRequest:
      return "Send On Request";
    case WebhookStudySendMode.Disabled:
      return "Disabled";
  }
}
