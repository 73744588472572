<template>
  <div class="back-btn" @click="router.push({ name: 'settings-tenants' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to Tenants
  </div>

  <div class="settings-title" data-testid="tenant-view-title" :data-test-is-loading="isLoading">
    Tenant: {{ tenant.name }}
  </div>

  <div class="field">
    <b>Name</b>
    <p>The display name for this tenant that is shown in the top-left corner.</p>
    <input v-model="tenant.name" type="text" @update:model-value="updateTenantDebounced" />
  </div>

  <div class="field">
    <b>AE Title</b>
    <p>
      The AE title for this tenant that identifies its DIMSE traffic:
      <code class="selectable-text" style="font-weight: bold">
        {{ tenant.aeTitle }}
      </code>
    </p>
  </div>

  <div class="field">
    <b>Salesforce Account ID</b>
    <p>The ID of the Salesforce account to create this tenant's support cases in.</p>
    <input
      v-model="tenant.salesforceAccountId"
      type="text"
      data-testid="salesforce-id-input"
      @update:model-value="updateTenantDebounced"
    />
  </div>

  <div class="field">
    <b>Stripe Customer ID</b>
    <p>The ID of the Stripe customer to bill this tenant's usage against.</p>
    <input
      v-model="tenant.stripeCustomerId"
      type="text"
      data-testid="stripe-customer-id-input"
      @update:model-value="updateTenantDebounced"
    />
  </div>

  <div class="field">
    <div class="toggle">
      <b>Bill for Studies Uploaded in the Browser</b>
      <ToggleSwitch
        v-model="tenant.isWebUploadBilled"
        data-testid="tenant-web-upload-billing-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether this tenant should be billed for studies that were created by uploading DICOM files in
      the web browser. Some customers have negotiated this to be excluded from their bill.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Bill Split Studies twice</b>
      <ToggleSwitch
        v-model="tenant.isSplitStudyBilledTwice"
        data-testid="tenant-split-study-billing-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether or not split studies will be billed twice for this tenant. If this option is turned
      off, this tenant will only be billed once for every two studies happening within a two hours
      window for the same patient.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Show "Not For Clinical Use" Banner</b>
      <ToggleSwitch
        v-model="tenant.isNotForClinicalUseBannerVisible"
        @update:model-value="updateTenantDebounced"
      />
    </div>
    <p>Whether to show a <i>"Not For Clinical Use"</i> banner at the top of the application.</p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>High Priority Support Cases Allowed</b>
      <ToggleSwitch
        v-model="tenant.isHighPrioritySupportCaseCreationAllowed"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether high priority support cases can be created by users of this tenant. High priority
      support cases result in an on-call person being paged out of hours. This is generally only
      allowed on tenants who are on a paid plan and are using the software in a clinical setting.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Include In Stats</b>
      <ToggleSwitch v-model="tenant.includeInStats" @update:model-value="updateTenantDebounced" />
    </div>

    <p>
      Whether this tenant should be included when calculating overall statistics. This is used to
      exclude demo tenants, testing/internal tenants, etc.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Dictation Permitted</b>
      <ToggleSwitch
        v-model="tenant.isDictationPermitted"
        data-testid="tenant-dictation-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether users in this tenant can use dictation features. When this is enabled, individual
      users of the tenant can enable dictation in their personal settings.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Deidentification Permitted</b>
      <ToggleSwitch
        v-model="tenant.isDeidentificationPermitted"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>Whether users in this tenant can use the deidentification feature.</p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>CT Mode Permitted</b>
      <ToggleSwitch
        v-model="tenant.isCTModePermitted"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether users in this tenant can use advanced CT features, such as the ability to enter the 3D
      NRRD-based CT viewer with windowing and slicing by plane enabled.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Microsoft SSO Enabled</b>
      <ToggleSwitch
        v-model="tenant.isMicrosoftSsoEnabled"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>Whether Microsoft SSO is enabled for this tenant.</p>
  </div>

  <ActivityOverlay v-if="isLoading" text="Loading" />
</template>

<script setup lang="ts">
import ActivityOverlay from "@/components/ActivityOverlay.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import type { TenantGetOneResponseDto } from "../../../backend/src/tenants/dto/tenant-get-one.dto";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const isLoading = ref(true);
const tenant = ref<TenantGetOneResponseDto>({
  id: "",
  name: "",
  aeTitle: "",
  isNotForClinicalUseBannerVisible: false,
  salesforceAccountId: "",
  stripeCustomerId: "",
  isHighPrioritySupportCaseCreationAllowed: false,
  includeInStats: false,
  isDictationPermitted: false,
  isCTModePermitted: false,
  isWebUploadBilled: false,
  isSplitStudyBilledTwice: false,
  isDeidentificationPermitted: false,
  isMicrosoftSsoEnabled: false,
});

onMounted(async () => {
  isLoading.value = true;

  let response: AxiosResponse<TenantGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<TenantGetOneResponseDto>(`/api/global/tenants/${props.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed loading tenant" });
    return;
  } finally {
    isLoading.value = false;
  }

  tenant.value = response.data;
});

const updateTenantDebounced = useDebounceFn(() => {
  void updateTenant();
}, 1000);

async function updateTenant(): Promise<void> {
  try {
    await axios.patch(`/api/global/tenants/${tenant.value.id}`, tenant.value);
  } catch {
    addNotification({ type: "error", message: "Failed updating tenant" });
    return;
  }

  addNotification({ type: "info", message: "Updated tenant" });
}
</script>

<style scoped lang="scss">
.settings-title {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  display: flex;
  gap: 16px;
  align-items: center;
}

.field {
  display: grid;
  gap: 8px;
}

input {
  width: 220px;
}

p {
  max-width: 500px;
  margin: 0 0 4px;
}
</style>
