<template>
  <div class="settings-title">Worklists</div>

  <div class="top-row">
    <FilterInput v-model="worklistFilter" data-testid="worklist-filter" placeholder="Search" />

    <button
      class="accented"
      style="justify-self: end"
      data-testid="open-entry-modal"
      @click="showAddEntryModal"
    >
      Create Worklist Entry
    </button>
  </div>

  <div class="worklist-table">
    <div class="header">
      <div class="header-item">
        <div />
      </div>

      <div class="header-item">
        <div class="title">Patient Name</div>
      </div>

      <div class="header-item">
        <div class="title">{{ currentTenant.patientIdLabel }}</div>
      </div>

      <div class="header-item">
        <div class="title">Birthdate</div>
      </div>

      <div class="header-item">
        <div class="title">Date</div>
      </div>

      <div class="header-item">
        <div class="title">Station Names</div>
      </div>

      <div class="header-item">
        <div class="title">Sex</div>
      </div>

      <div class="header-item">
        <div class="title">Ethnicity</div>
      </div>

      <div class="header-item">
        <div class="title">Description</div>
      </div>

      <div class="header-item">
        <div class="title">Modality</div>
      </div>

      <div />
    </div>

    <div class="header-line" />

    <div
      v-for="(sps, index) in scheduledProcedureSteps"
      :key="sps.id"
      class="grid-table-row"
      data-testid="grid-table-row"
    >
      <div>
        <FontAwesomeIcon
          v-if="sps.completed"
          icon="check"
          class="check-icon"
          data-testid="worklist-completed"
        />
      </div>
      <div :data-testid="`patient-name-${index}`">
        {{ formatDicomName(sps.patientName, currentTenant.patientNameFormat) }}
      </div>
      <div :data-testid="`patient-id-${index}`">{{ sps.patientId }}</div>
      <div :data-testid="`patient-birthdate-${index}`">
        {{ formatDateTime(sps.patientBirthdate) }}
      </div>
      <div :data-testid="`scheduled-procedure-step-start-date-time-${index}`">
        {{
          formatDateTime(
            DateTime.fromFormat(
              `${sps.scheduledProcedureStepStartDate} ${sps.scheduledProcedureStepStartTime}`,
              "yyyy-MM-dd HH:mm:ss"
            )
          )
        }}
      </div>
      <div :data-testid="`scheduled-station-ae-title-${index}`">
        {{ sps.scheduledStationAETitles.sort().join(", ") }}
      </div>
      <div :data-testid="`patient-sex-${index}`">{{ sps.patientSex }}</div>
      <div :data-testid="`patient-ethnicity-${index}`">{{ sps.patientEthnicity }}</div>
      <div :data-testid="`scheduled-procedure-step-description-${index}`">
        {{ sps.scheduledProcedureStepDescription }}
      </div>
      <div :data-testid="`modality-${index}`">{{ sps.modality }}</div>
      <div :data-testid="`delete-entry-${index}`" @click="deleteScheduledProcedureStep(sps.id)">
        <Tooltip content="Delete entry">
          <FontAwesomeIcon icon="trash" class="icon opacity-0" />
        </Tooltip>
      </div>
    </div>

    <VueEternalLoading
      v-model:is-initial="isReloadRequired"
      class="eternal-loading-status"
      :load="fetchScheduledProcedureSteps"
    >
      <template #loading>
        <Transition name="fade">
          <LoadingIndicator size="2x" />
        </Transition>
      </template>

      <template #no-more> &nbsp; </template>
      <template #no-results> &nbsp; </template>

      <template #error>
        <div class="loading-error" data-testid="loading-error">Error loading worklist entries</div>
      </template>
    </VueEternalLoading>
  </div>

  <Modal
    v-if="isAddWorklistEntryModalVisible"
    title="New Worklist Entry"
    :activity-text="''"
    @header-button-click="isAddWorklistEntryModalVisible = false"
    @enter-key-press="addScheduledProcedureStep"
  >
    <div class="worklist-entry-modal">
      <div class="field">
        <b>Patient Details</b>
        <div class="modal-section">
          <input
            ref="firstNameInputElement"
            v-model="patientFirstName"
            data-testid="patient-first-name-input"
            placeholder="First name"
          />

          <input
            v-model="patientLastName"
            data-testid="patient-last-name-input"
            placeholder="Last name"
          />

          <input
            v-model="patientId"
            data-testid="patient-id-input"
            :placeholder="currentTenant.patientIdLabel"
          />
        </div>
        <div class="modal-section">
          <DatePicker
            v-model="patientBirthdate"
            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
            data-testid="patient-birthdate-picker"
            :popover="{ visibility: 'none' }"
            mode="date"
          >
            <template #default="{ inputValue, inputEvents, togglePopover }">
              <div class="icon-box" @click="togglePopover">
                <FontAwesomeIcon icon="calendar-days" class="icon" />
              </div>
              <input
                class="input-with-icon"
                :value="formatDate(inputValue)"
                data-testid="patient-birthdate-input"
                placeholder="Birthdate (YYYY-MM-DD)"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>

          <DropdownWidget
            v-model="patientSex"
            :items="
              Object.values(PatientSex)
                .filter((s) => s !== '')
                .map((s) => ({ value: s, text: s }))
            "
            data-testid="patient-sex-dropdown"
            placeholder="Sex"
          />

          <DropdownInput
            :current-item="patientEthnicity"
            :items="currentTenant.ethnicityOptions"
            @select-item="(item: string) => (patientEthnicity = item)"
          >
            <input
              v-model="patientEthnicity"
              name="ethnicities"
              autocomplete="off"
              placeholder="Ethnicity"
              maxlength="16"
              style="width: 100%; box-sizing: border-box"
              data-testid="patient-ethnicity-input"
            />
          </DropdownInput>
        </div>
      </div>

      <div class="field">
        <b>Appointment Details</b>
        <div class="modal-section">
          <DatePicker
            v-model="scheduledStartDate"
            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
            :popover="{ visibility: 'none' }"
            mode="date"
            data-testid="scheduled-start-date-picker"
            :min-date="new Date()"
          >
            <template #default="{ inputValue, inputEvents, togglePopover }">
              <div class="icon-box" @click="togglePopover">
                <FontAwesomeIcon icon="calendar-days" class="icon" />
              </div>
              <input
                class="input-with-icon"
                :value="formatDate(inputValue)"
                autocomplete="off"
                placeholder="Start date (YYYY-MM-DD)"
                data-testid="scheduled-start-date-input"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>

          <DatePicker
            v-model="scheduledStartTime"
            :model-config="{ type: 'string', mask: 'HH:mm' }"
            :minute-increment="5"
            :popover="{ visibility: 'none' }"
            icon="clock"
            mode="time"
            data-testid="scheduled-start-time-picker"
            is24hr
          >
            <template #default="{ inputValue, inputEvents, togglePopover }">
              <div class="icon-box" @click="togglePopover">
                <FontAwesomeIcon icon="clock" class="icon" />
              </div>
              <input
                class="input-with-icon"
                :value="formatTime(inputValue)"
                data-testid="scheduled-start-time-input"
                placeholder="Start Time (HH:MM)"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>

          <DropdownWidget
            v-model="modality"
            :items="[
              { value: 'US', text: 'US' },
              { value: 'XA', text: 'XA' },
              { value: 'MR', text: 'MR' },
              { value: 'CT', text: 'CT' },
            ]"
            data-testid="modality-dropdown"
            placeholder="Modality"
          />
        </div>

        <div class="modal-section">
          <DropdownWidget
            v-model="scheduledStationAETitle"
            :items="scheduledStationOptions"
            data-testid="scheduled-station-ae-title-dropdown"
            placeholder="Station name"
          />

          <input
            v-model="scheduledProcedureStepDescription"
            placeholder="Description"
            style="grid-column-end: span 2"
            maxlength="64"
            data-testid="scheduled-procedure-step-description-input"
          />
        </div>
      </div>

      <button
        :disabled="!isNewWorklistEntryComplete"
        class="add-entry accented"
        data-testid="add-entry"
        @click="addScheduledProcedureStep"
      >
        <Tooltip :content="isNewWorklistEntryComplete ? '' : 'Please fill in the missing fields'">
          Create Worklist Entry
        </Tooltip>
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import type { DicomClientIdentityGetManyResponseDto } from "@/../../backend/src/dicom/dto/dicom-client-identity-get-many.dto";
import DatePicker from "@/components/DatePicker.vue";
import DropdownInput from "@/components/DropdownInput.vue";
import DropdownWidget from "@/components/DropdownWidget.vue";
import FilterInput from "@/components/FilterInput.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { LoadAction, VueEternalLoading } from "@ts-pro/vue-eternal-loading";
import { useFocus } from "@vueuse/core";
import { useDebounceFn } from "@vueuse/shared";
import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { computed, ref, watch } from "vue";
import { DimseMessage } from "../../../backend/src/dicom/dimse-message";
import type { DicomScheduledProcedureStepCreateResponseDto } from "../../../backend/src/dicom/dto/dicom-scheduled-procedure-step-create.dto";
import {
  DicomScheduledProcedureStepGetManyItemResponseDto,
  type DicomScheduledProcedureStepGetManyResponseDto,
} from "../../../backend/src/dicom/dto/dicom-scheduled-procedure-step-get-many.dto";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { PatientSex } from "../../../backend/src/studies/patient-sex";
import { hasDicomScheduledProcedureStepManagePermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import Modal from "../components/Modal.vue";
import { formatDateTime } from "../utils/date-time-utils";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";

const isAddWorklistEntryModalVisible = ref(false);
const worklistFilter = ref("");
const isReloadRequired = ref(true);

const patientFirstName = ref("");
const patientLastName = ref("");
const patientId = ref("");
const patientBirthdate = ref("");
const scheduledStartDate = ref("");
const scheduledStartTime = ref("");
const scheduledStationAETitle = ref("");
const modality = ref("");
const patientEthnicity = ref("");
const scheduledProcedureStepDescription = ref("");
const patientSex = ref("");

const scheduledProcedureSteps = ref<DicomScheduledProcedureStepGetManyItemResponseDto[]>([]);
const scheduledStationOptions = ref<{ value: string; text: string }[]>([]);

const firstNameInputElement = ref<HTMLInputElement>();
useFocus(firstNameInputElement, { initialValue: true });

let page = 0;

function reloadWorklists(): void {
  scheduledProcedureSteps.value = [];
  page = 0;
  isReloadRequired.value = true;
}

watch(worklistFilter, useDebounceFn(reloadWorklists, 500));

function formatTime(input: string | undefined): string {
  if (input === undefined) {
    return "";
  }

  return input;
}

function formatDate(input: string | undefined): string {
  if (input === undefined) {
    return "";
  }

  if (input.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    const timePieces = input.split("/");
    const formattedDate = `${timePieces[2]}-${timePieces[1]}-${timePieces[0]}`;
    return formattedDate;
  }

  return input;
}

const isNewWorklistEntryComplete = computed(
  () =>
    patientFirstName.value.length > 0 &&
    patientLastName.value.length > 0 &&
    patientId.value.length > 0 &&
    /^\d{4}-\d{2}-\d{2}$/.test(patientBirthdate.value) &&
    DateTime.fromISO(patientBirthdate.value).isValid &&
    scheduledStationAETitle.value.length > 0 &&
    /^\d{4}-\d{2}-\d{2}$/.test(scheduledStartDate.value) &&
    /^\d{2}:\d{2}$/.test(scheduledStartTime.value) &&
    modality.value.length > 0 &&
    scheduledProcedureStepDescription.value.length < 64
);

async function addScheduledProcedureStep(): Promise<void> {
  if (!isNewWorklistEntryComplete.value) {
    return;
  }

  try {
    await axios.post<DicomScheduledProcedureStepCreateResponseDto>(
      "/api/dicom-scheduled-procedure-step",
      {
        patientName: [patientLastName.value, patientFirstName.value],
        patientId: patientId.value,
        patientBirthdate: patientBirthdate.value,
        scheduledStationAETitles: [scheduledStationAETitle.value],
        scheduledProcedureStepStartDate: scheduledStartDate.value,
        scheduledProcedureStepStartTime: `${scheduledStartTime.value}:00`,
        modality: modality.value,
        patientEthnicity: patientEthnicity.value,
        scheduledProcedureStepDescription: scheduledProcedureStepDescription.value,
        patientSex: patientSex.value,
      }
    );
  } catch (error) {
    addNotification({ type: "error", message: "Failed creating worklist entry. Please try again" });
    return;
  }

  addNotification({ type: "info", message: "Added worklist entry" });

  isAddWorklistEntryModalVisible.value = false;

  reloadWorklists();
}

async function deleteScheduledProcedureStep(id: string): Promise<void> {
  if (!confirm("Are you sure you want to delete this worklist entry?")) {
    return;
  }

  try {
    await axios.delete(`/api/dicom-scheduled-procedure-step/${id}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting worklist entry. Please try again" });
    return;
  }

  addNotification({ type: "info", message: "Deleted worklist entry" });

  reloadWorklists();
}

async function fetchScheduledProcedureSteps({ loaded, error }: LoadAction): Promise<void> {
  if (!hasDicomScheduledProcedureStepManagePermission.value) {
    error();
    return;
  }

  const PAGE_SIZE = 25;

  const query = new URLSearchParams({
    limit: PAGE_SIZE.toString(),
    offset: (page * PAGE_SIZE).toString(),
    filter: worklistFilter.value,
  });

  let response: AxiosResponse<DicomScheduledProcedureStepGetManyResponseDto> | undefined =
    undefined;

  try {
    response = await axios.get<DicomScheduledProcedureStepGetManyResponseDto>(
      `/api/dicom-scheduled-procedure-step?${query.toString()}`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading worklist entries" });
    error();
    return;
  } finally {
    scheduledProcedureSteps.value.push(...(response?.data.steps ?? []));

    page += 1;
    loaded(response?.data.steps.length, PAGE_SIZE);
  }
}

async function fetchDicomClientIdentities(): Promise<void> {
  if (!hasDicomScheduledProcedureStepManagePermission.value) {
    return;
  }

  try {
    const response = (
      await axios.get<DicomClientIdentityGetManyResponseDto>(`/api/dicom-client-identities`)
    ).data;

    scheduledStationOptions.value = response
      .filter((item) => item.allowedDimseRequests.includes(DimseMessage.CFindRequest))
      .map((item) => ({ value: item.aeTitle, text: item.aeTitle }))
      .sort((a, b) => a.text.localeCompare(b.text));
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed loading list of Station Names",
    });
  }
}

function showAddEntryModal(): void {
  isAddWorklistEntryModalVisible.value = true;

  patientFirstName.value = "";
  patientLastName.value = "";
  patientId.value = "";
  patientBirthdate.value = "";
  patientEthnicity.value = "";
  scheduledStationAETitle.value = "";
  scheduledStartDate.value = "";
  scheduledStartTime.value = DateTime.now().toFormat("HH:mm");
  modality.value = "";
  scheduledProcedureStepDescription.value = "";
  patientSex.value = "";
}

void fetchDicomClientIdentities();
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.worklist-table {
  display: grid;
  grid-template-columns: 36px minmax(min-content, auto) repeat(8, auto) min-content;
  row-gap: 4px;

  .header {
    display: contents;
    column-gap: 8px;

    .header-item {
      margin-right: 8px;

      .title {
        font-weight: bold;
      }
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 11;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.grid-table-row {
  cursor: auto;

  &:hover .icon {
    opacity: 1;
  }
}

.check-icon {
  color: var(--confirm-color-2);
}

.icon {
  cursor: pointer;
  color: var(--accent-color-1);
  transition:
    color 100ms ease,
    opacity 100ms ease;
  align-self: center;

  &.opacity-0 {
    opacity: 0;
  }
}

.add-entry {
  margin: 0 auto;
}

.eternal-loading-status {
  padding-top: 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  place-content: center;
}

.loading-error {
  font-weight: bold;
  color: red;
  padding-top: 20px;
}

.worklist-entry-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-section {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  gap: 8px;
}

.icon-box {
  display: grid;
  height: 34px;
  width: 32px;
  outline: 1px solid var(--bg-color-4);
  outline-offset: -1px;
  place-content: center;
  cursor: pointer;
  border-radius: var(--border-radius) 0 0 var(--border-radius);

  &:active,
  &:hover {
    background: var(--bg-color-4);
    filter: brightness(90%);
  }
}

.input-with-icon {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  flex: 1;
}
</style>
