import type { PatientMetrics } from "../../../backend/src/measurements/measurement-display";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { StudyMeasurementDisplayOption } from "../../../backend/src/studies/study-measurement-enums";
import type { StudyMeasurement } from "../utils/study-data";

export function getMatchingMeasurements(
  measurements: StudyMeasurement[],
  searchTerm: string,
  patientMetrics: PatientMetrics | undefined
) {
  // Early out for when there is no search term
  if (searchTerm === "") {
    return [...measurements];
  }

  const searchTokens = searchTerm.trim().toLowerCase().split(" ");

  return measurements.filter((measurement) => {
    const measurementNames = [measurement.name.toLowerCase()];

    // Also try to match against the unindexed and indexed names, depending on the display option
    // selected for the measurement
    if (
      patientMetrics === undefined ||
      measurement.displayOption !== StudyMeasurementDisplayOption.PreferIndexed
    ) {
      measurementNames.push(getMeasurementDisplayName(measurement, "unindexed").toLowerCase());
    }
    if (
      patientMetrics !== undefined &&
      measurement.displayOption !== StudyMeasurementDisplayOption.Unindexed
    ) {
      measurementNames.push(getMeasurementDisplayName(measurement, "indexed").toLowerCase());
    }

    // Every search token must match to some part of the measurement names in order for it to match
    return !searchTokens.some((token) => !measurementNames.some((n) => n.includes(token)));
  });
}
