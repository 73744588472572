<!--
  This component draws gridlines on a report table by overlaying horizontal, vertical, and external
  lines onto the grid with explicit positioning using `grid-area`. This approach gives more
  consistent visual results on the final PDF than putting border or box-shadow styles on individual
  cell elements.
-->

<template v-if="section.type === 'table'">
  <!-- Internal horizontal gridlines -->
  <div
    v-for="index of rowIndices"
    :key="index"
    class="rpt-table-gridline horizontal"
    :style="{ gridArea: `${index} / 1 / ${index} / ${columnCount + 1}` }"
  />

  <!-- Internal vertical gridlines -->
  <div
    v-for="index of columnIndices"
    :key="index"
    class="rpt-table-gridline vertical"
    :style="{ gridArea: `1 / ${index} / ${rowCount + 1} / ${index}` }"
  />

  <!-- External border around the table -->
  <div
    class="rpt-table-gridline external"
    :style="{ gridArea: `1 / 1 / ${rowCount + 1} / ${columnCount + 1}` }"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  columnCount: number;
  rowCount: number;
}

const props = defineProps<Props>();

const columnIndices = computed(() => [...Array(props.columnCount - 1).keys()].map((i) => i + 2));
const rowIndices = computed(() => [...Array(props.rowCount - 1).keys()].map((i) => i + 2));
</script>

<style scoped lang="scss">
.rpt-table-gridline {
  pointer-events: none;
}
</style>
