<template>
  <Tooltip :disabled="activeRange === undefined" style="min-width: max-content">
    <div style="display: flex; gap: 8px">
      <div
        v-if="activeRange !== undefined"
        class="status-dot"
        :style="{ 'background-color': getReferenceRangeColor(activeRange) }"
      />
      <span class="selectable-text" :data-testid="`mmt-card-overall-value-${measurement.name}`">
        {{ displayValue?.fullText }}
      </span>
    </div>

    <template v-if="activeRange !== undefined" #content>
      <div class="status-dot-tooltip">
        <div class="top-line">
          <span class="name">
            <b>{{ displayName }}</b>
          </span>

          <MeasurementBadge v-if="showMeanBadge" source="mean" />

          <span style="margin-left: auto">{{ displayValue?.fullText }}</span>
        </div>

        <div
          class="active-range-name"
          :style="{
            'background-color': getReferenceRangeColor(activeRange, 0.2),
            color: getReferenceRangeColor(activeRange),
          }"
        >
          {{ getReferenceRangeDisplayName(activeRange) }}
        </div>

        <ReferenceRangeBar
          v-if="displayValue"
          :reference-range-set-name="referenceRangeSetName"
          :measurement-name="measurement.name"
          :indexed="indexed"
          :value="displayValue.meanValue"
        />

        <ReferenceRangeList
          v-if="displayValue"
          :reference-range-set-name="referenceRangeSetName"
          :measurement-name="measurement.name"
          :indexed="indexed"
          :unit="displayValue.unitText"
          :highlighted-range="activeRange"
        />

        <span class="reference-ranges-set-name">
          Reference range source:
          {{ getReferenceRangeSetDisplayName(referenceRangeSetName) }}
        </span>
      </div>
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import MeasurementBadge from "@/measurements/MeasurementBadge.vue";
import ReferenceRangeBar from "@/reference-ranges/ReferenceRangeBar.vue";
import ReferenceRangeList from "@/reference-ranges/ReferenceRangeList.vue";
import {
  getReferenceRangeColor,
  getReferenceRangeDisplayName,
} from "@/reference-ranges/reference-range";
import {
  ReferenceRangeSetName,
  getReferenceRangeSetData,
  getReferenceRangeSetDisplayName,
} from "@/reference-ranges/reference-range-sets";
import type { StudyMeasurement } from "@/utils/study-data";
import { computed } from "vue";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
  type PatientMetrics,
} from "../../../backend/src/measurements/measurement-display";

interface Props {
  measurement: StudyMeasurement;
  referenceRangeSetName: ReferenceRangeSetName;
  indexed: boolean;
  patientMetrics: PatientMetrics | undefined;
  showMeanBadge: boolean;
}

const props = defineProps<Props>();

const displayName = computed(() =>
  getMeasurementDisplayName(props.measurement, props.indexed ? "indexed" : "unindexed")
);

const displayValue = computed(() =>
  getStudyMeasurementDisplayValue(
    props.measurement,
    props.indexed ? "indexed" : "unindexed",
    props.patientMetrics
  )
);

const activeRange = computed(() => {
  const meanValue = displayValue.value?.meanValue;
  if (meanValue === undefined) {
    return;
  }

  const referenceRanges = getReferenceRangeSetData(
    props.referenceRangeSetName,
    props.measurement.name,
    props.indexed
  );

  return referenceRanges.find((range) => range.from <= meanValue && range.to > meanValue)?.name;
});
</script>

<style scoped lang="scss">
.top-line {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.status-dot-tooltip {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.status-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  font-weight: bold;
  margin: auto 0;
}

.active-range-name {
  align-self: flex-start;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  font-weight: bold;
}

.reference-ranges-set-name {
  font-style: italic;
  font-size: 0.8em;
}
</style>
