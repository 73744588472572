<template>
  <img src="/logo.png" />
</template>

<style scoped lang="scss">
img {
  width: 100%;
  height: 100%;
}
</style>
