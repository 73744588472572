<template>
  <div
    v-if="sectionContent.visible"
    ref="rootElement"
    style="position: relative"
    :data-testid="'rwma-root'"
  >
    <div
      ref="resizableElement"
      class="resizable"
      :data-testid="`rwma-section-${sectionStructure.name}`"
      :style="{
        width: rwmaWidth,
        resize: mode === ReportContentMode.EditReportContent ? 'horizontal' : 'none',
      }"
      @mousedown="onResize"
    >
      <div class="rpt-rwma-svgs">
        <!-- eslint-disable vue/no-v-html -->
        <div data-testid="rwma-svg-bullseye" v-html="bullseyeHTML" />
        <div data-testid="rwma-svg-a4c" v-html="a4cSVG" />
        <div data-testid="rwma-svg-a2c" v-html="a2cSVG" />
        <div data-testid="rwma-svg-plax" v-html="plaxSVG" />
        <div ref="psaxElement" data-testid="rwma-svg-psax" v-html="psaxSVG" />
      </div>
    </div>

    <div
      v-if="editable || sectionContent.comment.length > 0"
      :class="{ 'rpt-fancy-input': editable }"
      style="margin-bottom: 1em; max-width: 50%"
    >
      <ResizingTextbox
        v-if="editable"
        v-model="sectionContent.comment"
        :data-testid="`rwma-comments-${sectionStructure.name}`"
        placeholder="RWMA comments"
        class="rpt-text-normal"
        allow-newlines
        :scale-factor="scaleFactor"
        @update:model-value="emits('update-report-content')"
      />
      <div v-else class="rpt-text-normal">
        <TextWithLineBreaks :text="sectionContent.comment" />
      </div>
    </div>

    <div
      v-if="regionBeingEdited !== undefined"
      ref="abnormalitySelectorPopper"
      class="abnormality-selector-popper"
      :style="{
        left: `${abnormalitySelectorPopperLeft}em`,
        top: `${abnormalitySelectorPopperTop}em`,
      }"
    >
      <strong class="rpt-text-normal">{{ regionLabels[regionBeingEdited] }}</strong>

      <template v-for="rwmaOption of RWMAOptions" :key="rwmaOption.name">
        <div
          class="abnormality-item"
          :data-testid="`rwma-selector-${rwmaOption.id}`"
          @click="onAbnormalityClicked(rwmaOption.id)"
        >
          <div class="box" :style="`background-color: var(--rwma-${rwmaOption.id}-color)`" />
          <b style="font-size: 1.1em">{{ rwmaOption.name }}</b>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, onKeyStroke } from "@vueuse/core";
import { round } from "lodash";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import {
  RWMAFields,
  RWMAOptions,
  RWMARegion,
} from "../../../../backend/src/reporting/report-content-rwma";
import { isStudyUpdatePermitted } from "../../auth/authorization";
import ResizingTextbox from "../../components/ResizingTextbox.vue";
import { ReportContentMode } from "../../reporting/report-content";
import TextWithLineBreaks from "../../reporting/TextWithLineBreaks.vue";
import type { Study, StudyReport } from "../../utils/study-data";
import {
  RWMAStructuredFields,
  getA2CSVG,
  getA4CSVG,
  getBullseyeHTML,
  getPLAXSVG,
  getPSAXSVG,
} from "./rwma-svgs";

interface Props {
  study: Study;
  report: StudyReport;
  mode: ReportContentMode;
  editable: boolean;
  scaleFactor: number;
  sectionId: string;
}

interface Emits {
  (event: "update-report-content"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const reportStructure = computed(() => props.report.reportTemplateVersion.structure);

const sectionStructure = computed(
  () => reportStructure.value.sections.find((s) => s.id === props.sectionId)!
);

const sectionContent = computed(() => props.report.content.sections[props.sectionId]);

const rootElement = ref<HTMLElement | null>(null);
const psaxElement = ref<HTMLElement | null>(null);

const resizableElement = ref<HTMLElement | null>(null);

const regionLabels: Record<RWMARegion, string> = {
  [RWMARegion.BasalAnterior]: "Basal Anterior",
  [RWMARegion.BasalAnteroseptal]: "Basal Anteroseptal",
  [RWMARegion.BasalInferoseptal]: "Basal Inferoseptal",
  [RWMARegion.BasalInferior]: "Basal Inferior",
  [RWMARegion.BasalInferolateral]: "Basal Inferolateral",
  [RWMARegion.BasalAnterolateral]: "Basal Anterolateral",
  [RWMARegion.MidAnterior]: "Mid Anterior",
  [RWMARegion.MidAnteroseptal]: "Mid Anteroseptal",
  [RWMARegion.MidInferoseptal]: "Mid Inferoseptal",
  [RWMARegion.MidInferior]: "Mid Inferior",
  [RWMARegion.MidInferolateral]: "Mid Inferolateral",
  [RWMARegion.MidAnterolateral]: "Mid Anterolateral",
  [RWMARegion.ApicalAnterior]: "Apical Anterior",
  [RWMARegion.ApicalSeptal]: "Apical Septal",
  [RWMARegion.ApicalInferior]: "Apical Inferior",
  [RWMARegion.ApicalLateral]: "Apical Lateral",
};

const rwmaReportSectionFields = computed(
  () => sectionContent.value.structuredFields as RWMAStructuredFields
);

const a2cSVG = computed(() => getA2CSVG(rwmaReportSectionFields.value));
const a4cSVG = computed(() => getA4CSVG(rwmaReportSectionFields.value));
const plaxSVG = computed(() => getPLAXSVG(rwmaReportSectionFields.value));
const psaxSVG = computed(() => getPSAXSVG(rwmaReportSectionFields.value));
const bullseyeHTML = computed(() => getBullseyeHTML(rwmaReportSectionFields.value));

const regionBeingEdited = ref<RWMARegion>();

const rwmaWidth = computed(() => `${sectionContent.value.width}%`);

function onResize(): void {
  // Only update the dimensions when the mouse is released
  window.addEventListener("mouseup", updateDimensions, { once: true });
}

function updateDimensions(): void {
  if (props.mode === ReportContentMode.GenerateHTML || !resizableElement.value) {
    return;
  }

  sectionContent.value.width = round(
    (resizableElement.value.offsetWidth / rootElement.value!.offsetWidth) * 100
  );

  emits("update-report-content");
}

onMounted(() => {
  updateDimensions();
});

async function initializeClickableRWMARegions(): Promise<void> {
  if (!isStudyUpdatePermitted(props.study)) {
    return;
  }

  await nextTick(); // Wait for the RWMA SVGs to render before adding listeners

  for (const rwmaField of RWMAFields) {
    function activateRegion(e: Event): void {
      if (!(e instanceof MouseEvent)) {
        return;
      }

      regionBeingEdited.value = rwmaField.id;

      // Reposition the popper after it's had a chance to be instantiated
      void nextTick().then(() => repositionPopperForMouseEvent(e));
    }

    const sectionElements = rootElement.value?.querySelectorAll(`#rwma-region-${rwmaField.id}`);
    for (const el of sectionElements ?? []) {
      el.addEventListener("click", activateRegion);
    }
  }
}

watch([sectionContent, a2cSVG, a4cSVG, plaxSVG, psaxSVG], initializeClickableRWMARegions, {
  immediate: true,
  deep: true,
});

const abnormalitySelectorPopper = ref<HTMLDivElement>();
const abnormalitySelectorPopperTop = ref(0);
const abnormalitySelectorPopperLeft = ref(0);

function repositionPopperForMouseEvent(e: MouseEvent): void {
  const rect = rootElement.value!.getBoundingClientRect();

  if (!abnormalitySelectorPopper.value) {
    return;
  }

  const popperWidth = abnormalitySelectorPopper.value.offsetWidth;
  const popperHeight = abnormalitySelectorPopper.value.offsetHeight;

  // Center the popper vertically on the click position
  const popperTop = e.clientY - rect.top - popperHeight / 2;

  // The horizontal offset depends on whether the right-most PSAX diagram is being edited. If
  // is is, the popper is shown to the left of the mouse click position instead of to the right.
  let horizontalOffset = 1;
  const isPsaxActive = psaxElement.value?.contains(e.target as HTMLElement) === true;
  if (isPsaxActive) {
    horizontalOffset = -horizontalOffset - popperWidth;
  }

  const popperLeft = e.clientX - rect.left + horizontalOffset;

  abnormalitySelectorPopperTop.value = popperTop / props.scaleFactor / 10;
  abnormalitySelectorPopperLeft.value = popperLeft / props.scaleFactor / 10;
}

onClickOutside(abnormalitySelectorPopper, () => (regionBeingEdited.value = undefined));

function onAbnormalityClicked(optionId: string): void {
  if (regionBeingEdited.value === undefined) {
    return;
  }

  rwmaReportSectionFields.value[regionBeingEdited.value] = {
    optionIds: [optionId],
    text: "",
    isTextOverriden: false,
  };
  emits("update-report-content");

  regionBeingEdited.value = undefined;
}

onKeyStroke("Escape", () => (regionBeingEdited.value = undefined));
</script>

<style scoped lang="scss">
.abnormality-selector-popper {
  display: flex;
  flex-direction: column;
  row-gap: 0.6em;
  width: 12em;
  background-color: var(--bg-color-2);
  border-radius: 0.2em;
  padding: 0.8em;
  padding-top: 0.4em;
  color: white;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.5em 1em;
  z-index: 1;

  .abnormality-item {
    display: flex;
    align-items: center;
    gap: 0.8em;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--text-color-1);
    transition: color 100ms ease;
    line-height: 1em;

    .box {
      border-radius: 0.2em;
      width: 1.6em;
      height: 1.6em;
      filter: brightness(90%);
      transition: filter 100ms ease;
    }

    &:hover {
      color: var(--text-color-2);

      .box {
        filter: brightness(100%);
      }
    }
  }
}

.resizable {
  overflow: hidden;
  max-width: 100%;
  min-width: 30%; // Prevent the RWMA SVGs from being too small
  min-height: fit-content;
}

// Highlight RWMA regions on hover
:deep(g[id^="rwma-region-"]) {
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
}
</style>
