/**
 * This enum defines all of the individual permissions that can be granted to users to allow them to
 * perform various tasks in the application and to control what data and settings they do and do not
 * have access to. Users are granted permissions via the set of roles assigned to them, where each
 * role specifies a set of permissions that it grants to the users it is assigned to.
 *
 * Note that any changes to permissions should also be reflected in `permission-toggle.ts` so that
 * they are shown in the role management UI in the frontend.
 */
export enum Permission {
  /** Allows the user to manage HeartLab Agent integrations */
  AgentIntegrationManage = "agent-integration:manage",

  /** Allows the user to view audit logs. */
  AuditLogView = "audit-log:view",

  /** Allows the user access to billing information for the tenant. */
  BillingManage = "billing:manage",

  /** Allows the user access to view the activity charts. */
  ChartingView = "charting:view",

  /**
   * Allows the user access to update their own user details, including their name, signature, and
   * PIN.
   */
  CurrentUserUpdate = "current-user:update",

  /** Allows the user to manage DICOM client identities. */
  DicomClientIdentityManage = "dicom-client-identity:manage",

  /** Allows the user to query DICOM endpoints and import any returned studies. */
  DicomEndpointQuery = "dicom-endpoint:query",

  /** Allows the user to send a study to a DICOM endpoint. */
  DicomEndpointSend = "dicom-endpoint:send",

  /** Allows the user to manage DICOM scheduled procedure steps, also called worklist items. */
  DicomScheduledProcedureStepManage = "dicom-scheduled-procedure-step:manage",

  /** Allows the user to manage HeartBox integrations. */
  HeartBoxIntegrationManage = "heartbox-integration:manage",

  /** Allows the user to manage HL7 integrations. */
  HL7IntegrationManage = "hl7-integration:manage",

  /** Allows the user to download HL7 messages as a file to check the content prior to sending. */
  HL7MessageDownload = "hl7-message:download",

  /** Allows the user to send study reports using a configured HL7 integration. */
  HL7MessageSend = "hl7-message:send",

  /** Allows the user to create, update and delete measurement calculations. */
  MeasurementCalculationManage = "measurement-calculation:manage",

  /** Allows the user to create, update and delete measurement sequences. */
  MeasurementSequenceManage = "measurement-sequence:manage",

  /** Allows the user to create, update, delete, and publish report templates. */
  ReportTemplateManage = "report-template:manage",

  /** Allows the user to manage SFTP integrations used for automated report sending. */
  SftpIntegrationManage = "sftp-integration:manage",

  /** Allows the user to delete studies they have access to. */
  StudyDelete = "study:delete",

  /** Allows the user to delete clips from studies they have access to. */
  StudyClipDelete = "study-clip:delete",

  /** Allows the user to update the patient identity for studies they have access to. */
  StudyPatientIdentityUpdate = "study:patient-identity-update",

  /** Allows the user to reprocess studies they have access to. */
  StudyReprocess = "study:reprocess",

  /** Allows the user to share studies they have access to using a share link. */
  StudyShare = "study:share",

  /** Allows the user to update studies they have access to. */
  StudyUpdate = "study:update",

  /**
   * Allows the user to upload DICOM files that may create studies or update content in existing
   * studies.
   */
  StudyUpload = "study:upload",

  /** Allows the user to view studies they have access to. */
  StudyView = "study:view",

  /**
   * Allows the user to request specific studies be sent to webhooks that are configured for
   * on-request study sending.
   */
  StudyWebhookActivate = "study:webhook:activate",

  /** Allows the user to create preliminary reports. */
  StudyReportPreliminaryCreate = "study-report:preliminary:create",

  /**
   * Allows the user to create the first preliminary report only, but does not allow creation of any
   * other reports after the first one has been created.
   */
  StudyReportPreliminaryCreateInitial = "study-report:preliminary:create-initial",

  /** Allows the user to mark a preliminary report as complete. */
  StudyReportPreliminaryComplete = "study-report:preliminary:complete",

  /**
   * Allows the user to change a preliminary study report's type to final when they complete it.
   * This permission should only be given to users with relevant medical qualifications.
   */
  StudyReportPreliminaryFinalize = "study-report:preliminary:finalize",

  /** Allows the user to create an amendment to a final report. */
  StudyReportAmendmentCreate = "study-report:amendment:create",

  /**
   * Allows the user to complete an amendment to a report. This only allows the user to complete an
   * amendment which has no changes to the report's content, and there is a separate permission for
   * allowing amendments that change the report's content.
   */
  StudyReportAmendmentComplete = "study-report:amendment:complete",

  /**
   * Allows the user to complete an amendment to a report that includes updates to the report's
   * content. This permission should only be given to users with relevant medical qualifications.
   */
  StudyReportAmendmentCompleteWithUpdatedContent = "study-report:amendment:complete-with-updated-content",

  /** Allows the user to delete an unsigned study report. */
  StudyReportDeleteUnsigned = "study-report:delete-unsigned",

  /** Allows the user to delete a signed study report. */
  StudyReportDeleteSigned = "study-report:delete-signed",

  /** Allows the user to view the study list and see studies they have access to. */
  StudyListView = "study-list:view",

  /** Allows the user to manage tenant-wide settings not covered by other more specific permissions. */
  TenantManage = "tenant:manage",

  /** Allows the user to invite other users by email and to cancel pending invitations. */
  UserInvite = "user:invite",

  /** Allows the user to update roles for existing users. */
  UserRolesUpdate = "user:roles-update",

  /** Allows the user to update details for existing users, except for their roles. */
  UserUpdate = "user:update",

  /** Allows the user to view details and roles for existing users. */
  UserView = "user:view",

  /** Allows the user to manage webhook integrations. */
  WebhookIntegrationManage = "webhook-integration:manage",

  /** Allows the user to manage the set of roles that can be given to users. */
  UserRoleManage = "user-role:manage",

  /** Allows the user to view the roles that can be given to users. */
  UserRoleView = "user-role:view",
}
