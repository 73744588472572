import axios from "axios";
import type { Ref } from "vue";
import { ref } from "vue";
import type { UserNamesGetResponseDto } from "../../../backend/src/tenants/dto/user-names-get.dto";

export interface UserListEntry {
  id: string;
  name: string;
  roleIds: string[];
  isEnabled: boolean;
  isVisible: boolean;
}

const userList = ref<UserListEntry[]>([]);
let isLoadInProgress = false;
const isLoaded = ref(false);

export function useUserList(options: { load: boolean } = { load: true }): {
  userList: Ref<UserListEntry[]>;
  isUserListLoaded: Ref<boolean>;
} {
  if (!isLoaded.value && !isLoadInProgress && options.load) {
    void loadUserList();
  }

  return { userList, isUserListLoaded: isLoaded };
}

export async function loadUserList(): Promise<void> {
  isLoadInProgress = true;

  try {
    userList.value = (await axios.get<UserNamesGetResponseDto>(`/api/tenants/user-names`)).data;

    isLoaded.value = true;
  } finally {
    isLoadInProgress = false;
  }
}
