import type { WebGLRenderer } from "three";
import type { ComputedRef, Ref } from "vue";
import { computed, ref, watch } from "vue";
import type { Study, StudyClip, StudySeries } from "../../utils/study-data";
import type { CanvasContainerRect } from "../clip-viewer/clip-renderer-2d";
import { useStudyViewImageData } from "../study-view-image-data";
import type { CTRenderer } from "./ct-renderer";
import { createCTRenderer } from "./ct-renderer";

export enum CTSliceDirection {
  Axial = "Axial",
  Coronal = "Coronal",
  Sagittal = "Sagittal",
}

/**
 * A CTModel is responsible for managing the state of a CT viewer. On creation it requests a volume
 * to be loaded via the study view image data store as well as a CTRenderer which will be assigned
 * to the WebGL canvas to display the slices on. A CTModel holds all information about the current
 * state of the slice being displayed such as windowing & slice number/direction and requests a
 * redraw from the CTRenderer when these change.
 */
export interface CTModel {
  /** The series of the CT that is being displayed */
  readonly series: StudySeries;

  /** The faked clip representing the series that is being displayed. */
  readonly clip: StudyClip;

  /** The slice plane that is being displayed from this CT. */
  sliceDirection: Ref<CTSliceDirection>;

  /** The current slice number being displayed in the LPS coordinate system. */
  sliceNumber: Ref<number>;

  /** The window level in Hounsfield Units (HU) that the intensity is scaled around. */
  windowLevel: Ref<number>;

  /** The width of the intensity window that is being used. */
  windowWidth: Ref<number>;

  /**
   * The maximum slice number that can be obtained from this CT & slice direction, i.e. the value
   * of the dimension that is being sliced (e.g. xy slice: size in the z-direction).
   */
  maxSliceNumber: ComputedRef<number>;

  /** Whether the CT series is loaded and ready to view. */
  isLoading: Ref<boolean>;

  /** Steps the slice number by the given number of frames/slices to step. */
  onStepFrame(delta: number): void;

  /**
   * Provides a Three WebGLRenderer to this model. This is so the renderer can be given after the
   * model is created, which instantiates the CTViewer and creates the canvas that the renderer
   * is linked to.
   */
  provideRenderer(webglRenderer: WebGLRenderer, canvasRect: CanvasContainerRect): void;
}

export function createCTModel(study: Study, series: StudySeries, startingFrame = 0): CTModel {
  const studyViewImageData = useStudyViewImageData();

  const sliceDirection = ref<CTSliceDirection>(CTSliceDirection.Axial);
  const sliceNumber = ref<number>(startingFrame);
  const windowLevel = ref<number>(300);
  const windowWidth = ref<number>(800);

  const isLoading = ref(true);

  const maxSliceNumber = computed(() => calculateMaxSliceNumber(series, sliceDirection.value));

  const clip = series.clips[0];

  let renderer: CTRenderer | null = null;
  let onProvideRenderer: (() => void) | null = null;

  function onStepFrame(delta: number): void {
    sliceNumber.value = Math.max(0, Math.min(sliceNumber.value + delta, maxSliceNumber.value - 1));
  }

  const model = {
    clip,
    series,
    sliceDirection,
    sliceNumber,
    windowLevel,
    windowWidth,
    maxSliceNumber,
    isLoading,

    onStepFrame,
  };

  function provideRenderer(webglRenderer: WebGLRenderer, canvasRect: CanvasContainerRect) {
    renderer = createCTRenderer({
      model,
      webglRenderer,
      canvasRect,
    });

    onProvideRenderer?.();
  }

  void studyViewImageData.getThreeVolumeForSeries(study.id, series.id).then((volume) => {
    onProvideRenderer = () => {
      renderer?.loadVolume(volume);
      isLoading.value = false;
      onProvideRenderer = null;
    };

    if (renderer) {
      onProvideRenderer();
    }
  });

  watch([sliceNumber, windowLevel, windowWidth], () => {
    renderer?.displayCurrentSlice();
  });

  watch(sliceDirection, (newDirection, oldDirection) => {
    sliceNumber.value =
      (sliceNumber.value / calculateMaxSliceNumber(series, oldDirection)) *
      calculateMaxSliceNumber(series, newDirection);

    renderer?.updateCameraPositionAndOrientation();
    renderer?.displayCurrentSlice();
  });

  return { ...model, provideRenderer };
}

function calculateMaxSliceNumber(series: StudySeries, sliceDirection: CTSliceDirection): number {
  if (sliceDirection === CTSliceDirection.Axial) {
    return series.clips[0].frameCount ?? 0;
  }

  return sliceDirection === CTSliceDirection.Coronal
    ? series.clips[0].height ?? 0
    : series.clips[0].width ?? 0;
}
