import { useIdle, useStorage } from "@vueuse/core";
import { watch } from "vue";
import { signOut } from "./authentication";
import { currentTenant } from "./current-session";

const lastActivityTimestamp = useStorage("last-activity-timestamp", 0);

// Setup automatic sign-out if the user is idle for the specified period of time
export function setupAutoSignOut(): void {
  const timeoutInSeconds = currentTenant.sessionInactivityExpirationTime;

  const { idle, lastActive } = useIdle(timeoutInSeconds * 1000);

  // When this window/tab becomes idle, automatically log out as long as there's been no recent
  // activity on another window/tab
  watch(idle, async () => {
    const timeSinceLastActivity = (Date.now() - lastActivityTimestamp.value) / 1000;

    // Leave a two-second grace period to avoid timing edge cases
    if (timeSinceLastActivity < timeoutInSeconds - 2) {
      return;
    }

    await signOut();
  });

  // Update activity timestamp whenever anything happens on the page
  watch(lastActive, updateLastActivityTimestamp);
}

export function updateLastActivityTimestamp(): void {
  lastActivityTimestamp.value = Date.now();
}
