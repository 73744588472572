<template>
  <img :src="currentUser.signatureDataUri" />
  <textarea
    ref="textareaElement"
    v-model="currentUser.signatureText"
    placeholder="Add signature text"
    data-testid="onboarding-signature-text"
  />
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import { ref } from "vue";
import { currentUser } from "../auth/current-session";

const textareaElement = ref<HTMLTextAreaElement>();

useFocus(textareaElement, { initialValue: true });
</script>

<style scoped lang="scss">
img {
  filter: invert(1);
  width: 320px;
  height: 140px;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}

textarea {
  height: 48px;
  width: 300px;
  resize: none;
  border: 1px solid var(--accent-color-1);
}
</style>
