import {
  getPatientMetrics,
  getStudyMeasurementDisplayValue,
} from "@/../../backend/src/measurements/measurement-display";
import { z, type ZodError } from "zod";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import {
  MeasurementUnit,
  convertMeasurementUnit,
  getMeasurementDisplayUnit,
  getUnitDisplayText,
} from "../../../backend/src/measurements/measurement-units";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { currentTenant, currentUser } from "../auth/current-session";
import { formatDateTime } from "../utils/date-time-utils";
import {
  getPatientAgeInYearsWhenScanned,
  getPatientSexDisplayText,
  getSortedReports,
  type Study,
  type StudyReport,
} from "../utils/study-data";
import { useUserList } from "../utils/users-list";
import { getLatestReportReporterUserId } from "./report-content";

/** The whole scope in which calculations are evaluated. */
export interface CalculationScope {
  study: CalculationStudyScope;
  patient: CalculationPatientScope;
  measurement: CalculationMeasurementScope;
  report: CalculationReportScope;
}

/** The study part of the scope in which calculations are evaluated. */
export interface CalculationStudyScope {
  date?: string;
  time?: string;
  traineeName?: string;
  technicianName?: string;
  physicianName?: string;
  referringPhysicianName?: string;
  preliminaryReporterName?: string;
  reporterName?: string;
  reportDate?: string;
  indication?: string;
}

/** The patient part of the scope in which calculations are evaluated. */
export interface CalculationPatientScope {
  name?: string;
  id?: string;
  sex?: string;
  birthdate?: string;
  age?: number;
  weight?: number;
  height?: number;
  bodyMassIndex?: number;
  bodySurfaceArea?: number;
  medicalHistory?: string;
}

/** The measurement part of the scope in which calculations are evaluated. */
export interface CalculationMeasurementScope {
  getValue: (args: unknown) => number | null;
  format: (args: unknown) => string | null;
}

/** The report part of the scope in which calculations are evaluated. */
export type CalculationReportScope = Record<string, Record<string, number>>;

const { userList } = useUserList({ load: false });

/** Returns the study scope to use for the given study. */
export function getCalculationStudyScope(study: Study, report: StudyReport): CalculationStudyScope {
  // Find the user who approved the preliminary report, if any
  const preliminaryReport = getSortedReports(study.reports).find(
    (r) => report.completedAt !== null && r.type === StudyReportType.Preliminary
  );

  const reporterId = report.completedById ?? getLatestReportReporterUserId(study);

  return {
    date: formatDateTime(getStudyDateTime(study)),
    time: formatDateTime(getStudyDateTime(study), { includeDate: false, includeTime: true }),
    traineeName: userList.value.find((u) => u.id === study.traineeUserId)?.name ?? "",
    technicianName: userList.value.find((u) => u.id === study.technicianUserId)?.name ?? "",
    physicianName: userList.value.find((u) => u.id === study.physicianUserId)?.name ?? "",
    referringPhysicianName: formatDicomName(study.referringPhysician),
    preliminaryReporterName:
      userList.value.find((u) => u.id === preliminaryReport?.completedById)?.name ?? "",
    reporterName: userList.value.find((u) => u.id === reporterId)?.name ?? currentUser.name,
    reportDate: formatDateTime(report.completedAt, { includeTime: true }),
    indication: study.indication.trim(),
  };
}

/** Returns the patient scope to use for the given study. */
export function getCalculationPatientScope(study: Study): CalculationPatientScope {
  const patientMetrics = getPatientMetrics(study);

  return {
    name: formatDicomName(study.patientName, currentTenant.patientNameFormat),
    id: study.patientId,
    sex: getPatientSexDisplayText(study.patientSex),
    birthdate: formatDateTime(study.patientBirthdate),
    age: getPatientAgeInYearsWhenScanned(study),
    weight: patientMetrics?.weight,
    height: patientMetrics?.height,
    bodyMassIndex: patientMetrics?.bodyMassIndex,
    bodySurfaceArea: patientMetrics?.bodySurfaceArea,
    medicalHistory: study.patientMedicalHistory.trim(),
  };
}

/**
 * Measurement scope
 */

// The schema for arguments to the `measurement.getValue()` function
const getMeasurementValueArgsSchema = z.strictObject({
  name: z.nativeEnum(MeasurementName),
  customName: z.string().optional(),
  unit: z.string(),
  indexed: z.boolean().optional(),
});

// The schema for arguments to the `measurement.format()` function
const formatMeasurementArgsSchema = getMeasurementValueArgsSchema.extend({
  decimalPlaces: z.number().int().optional(),
  appendUnit: z.boolean().optional(),
});

// Check the unit against both the full name and the display text (ex: allow "cm" & "centimeters")
function getUnitFromFunctionArgument(unit: string): MeasurementUnit {
  if (Object.values(MeasurementUnit).includes(unit as MeasurementUnit)) {
    return unit as MeasurementUnit;
  } else {
    for (const measurementUnit of Object.values(MeasurementUnit)) {
      if (
        unit === getUnitDisplayText(measurementUnit).replaceAll(/²/g, "2").replaceAll(/³/g, "3")
      ) {
        return measurementUnit;
      }
    }
  }

  throw Error(`Unrecognized unit: ${unit}`);
}

/** Implementation of measurement.getValue() in report calculations. */
function getMeasurementValue(study: Study, args: unknown): number | null {
  const parsedArgs = getMeasurementValueArgsSchema.safeParse(args);
  if (!parsedArgs.success) {
    throw Error(
      `Invalid inputs to measurement function: ${formatArgsSchemaError(parsedArgs.error)}`
    );
  }

  const { name: measurementName, unit, indexed, customName } = parsedArgs.data;

  const lowercaseCustomName = (customName ?? "").trim().toLowerCase();
  const measurement = study.measurements.find(
    (m) => m.name === measurementName && m.customName.toLowerCase() === lowercaseCustomName
  );

  const patientMetrics = getPatientMetrics(study);
  const value = getStudyMeasurementDisplayValue(
    measurement,
    indexed === true ? "indexed" : "unindexed",
    patientMetrics
  );

  // If we can't get the patient metrics to index the measurement, then we shouldn't show the value
  // in report calculations either as by default getStudyMeasurementDisplayValue() returns unindexed
  // value if patientMetrics is not available.
  if (value === undefined || (indexed === true && patientMetrics === undefined)) {
    return null;
  }

  const targetUnit = getUnitFromFunctionArgument(unit);

  const targetValue = convertMeasurementUnit(value.meanValue, value.meanValueUnit, targetUnit);
  if (targetValue === null) {
    throw Error(
      `Can't convert unit from ${getMeasurementDisplayUnit(measurementName)} to ${targetUnit}`
    );
  }

  return targetValue;
}

/** Implementation of measurement.format() in report calculations. */
function formatMeasurement(study: Study, args: unknown): string | null {
  const parsedArgs = formatMeasurementArgsSchema.safeParse(args);
  if (!parsedArgs.success) {
    throw Error(
      `Invalid inputs to measurement.format(): ${formatArgsSchemaError(parsedArgs.error)}`
    );
  }

  const {
    name: measurementName,
    unit,
    indexed,
    decimalPlaces,
    appendUnit,
    customName,
  } = parsedArgs.data;

  const convertedValue = getMeasurementValue(study, {
    name: measurementName,
    unit,
    indexed,
    customName,
  });
  if (convertedValue === null) {
    return null;
  }

  const formattedValue = convertedValue.toFixed(decimalPlaces);

  if (appendUnit !== false) {
    const targetUnit = getUnitFromFunctionArgument(unit);

    return `${formattedValue} ${getUnitDisplayText(targetUnit)}${indexed === true ? "/m²" : ""}`;
  }

  return formattedValue;
}

function formatArgsSchemaError(e: ZodError): string {
  const issues = e.issues.map((issue) => {
    const path = issue.path.join(".");

    return `${path !== "" ? `Argument ${path}:` : ""} ${issue.message}`;
  });

  return issues.join(", ");
}

/** Returns the measurement scope to use for the given study. */
export function getCalculationMeasurementScope(study: Study): CalculationMeasurementScope {
  return {
    getValue: (args: unknown) => getMeasurementValue(study, args),
    format: (args: unknown) => formatMeasurement(study, args),
  };
}

/** Returns the section scope to use for the given study. */
export function getCalculationReportScope(report: StudyReport): CalculationReportScope {
  const scope: CalculationReportScope = {};

  for (const section of report.reportTemplateVersion.structure.sections) {
    const sanitizedSectionName = getSanitizedReportStructureName(section.name);
    if (!(sanitizedSectionName in scope)) {
      scope[sanitizedSectionName] = {};
    }

    for (const field of section.structuredFieldColumns.flat()) {
      if (field.type !== "text") {
        continue;
      }

      const value = parseFloat(report.content.sections[section.id].structuredFields[field.id].text);

      // If there isn't a valid value then don't add this field to the scope
      if (!isFinite(value)) {
        continue;
      }

      scope[sanitizedSectionName][getSanitizedReportStructureName(field.name)] = value;
    }
  }

  return scope;
}

/**
 * Returns the complete calculation scope to use when evaluating calculations for the given study
 * and report.
 */
export function getCalculationScope(study: Study, report: StudyReport): CalculationScope {
  return {
    study: getCalculationStudyScope(study, report),
    patient: getCalculationPatientScope(study),
    measurement: getCalculationMeasurementScope(study),
    report: getCalculationReportScope(report),
  };
}

/**
 * Helper function that sanitizes a section or field name on a report so it can be safely included
 * in the report scope used to evaluation calculations. Sanitized names have spaces replaced
 * with underscores, non-alphanumeric characters stripped, and then finally leading digits and
 * underscores are stripped as well in order to ensure the name is valid.
 */
export function getSanitizedReportStructureName(name: string): string {
  return name
    .replace(/ /g, "_")
    .replace(/[^a-zA-Z0-9_]/gi, "")
    .replace(/^[0-9_]+/, "");
}
