<template>
  <div v-if="measurement.customUnit" class="measurement-card-edit-custom-value">
    <input v-model="editingCustomMeasurementValue" data-testid="custom-mmt-edit-input" />

    <span>{{ getUnitDisplayText(measurement.customUnit) }}</span>

    <div class="cancel-button">
      <Tooltip content="Cancel editing" @click="emits('close')">
        <FontAwesomeIcon icon="xmark" style="display: block" />
      </Tooltip>
    </div>

    <button
      class="save-button"
      data-testid="custom-mmt-edit-save-btn"
      @click="onSaveCustomValueEdit"
    >
      Save
    </button>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import { getUnitDisplayText } from "../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import {
  convertMeasurementUnit,
  getInternalUnitForPhysicalQuantity,
  getUnitPhysicalQuantity,
} from "../../../backend/src/measurements/measurement-units";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";
import { Study, StudyMeasurement, StudyMeasurementValue } from "../utils/study-data";
import { changeBatchMeasurements } from "./measurement-save-helpers";

interface Props {
  study: Study;
  measurement: StudyMeasurement;
  measurementValue: StudyMeasurementValue;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

// There always will be a custom unit set for custom value measurements
if (props.measurement.customUnit === null) {
  throw Error("Custom unit is undefined");
}
const unit = props.measurement.customUnit;

const editingCustomMeasurementValue = ref(
  convertMeasurementUnit(
    props.measurementValue.value,
    getInternalUnitForPhysicalQuantity(getUnitPhysicalQuantity(unit)),
    unit
  )?.toString() ?? ""
);

async function onSaveCustomValueEdit(): Promise<void> {
  try {
    await changeBatchMeasurements(props.study, {
      updates: [
        {
          name: MeasurementName.CustomValue,
          customName: props.measurement.customName,
          unit,
          measurementId: props.measurement.id,
          measurementValueId: props.measurementValue.id,
          value: parseInt(editingCustomMeasurementValue.value),
        },
      ],
    });

    emits("close");
  } catch {
    addNotification({ type: "error", message: "Failed saving measurement" });
  }
}
</script>

<style scoped lang="scss">
.measurement-card-edit-custom-value {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 16px;

  input {
    width: 48px;
    height: 20px;
    padding: 0 4px;
  }
}

.cancel-button {
  margin-left: auto;
  cursor: pointer;
  color: var(--text-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}

.save-button {
  padding: 0 8px;
  background-color: var(--bg-color-2);
  border: 1px solid var(--border-color-1);
  height: 22px;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
