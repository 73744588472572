import { onKeyStroke, type KeyFilter } from "@vueuse/core";
import { isInputElementFocused } from "./dom-utils";
import { isModalActive } from "./modal";

/**
 * Adds a keyboard shortcut handler for the given key that run when that key is pressed and the user
 * isn't currently focused in an input field and there is no modal currently active.
 */
export function onKeyboardShortcut(
  key: KeyFilter,
  handler: (event: KeyboardEvent) => void,
  opts: { isAllowedInModal: boolean } = { isAllowedInModal: false }
): void {
  onKeyStroke(key, (event) => {
    if (isInputElementFocused()) {
      return;
    }

    if (isModalActive() && !opts.isAllowedInModal) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    handler(event);
  });
}
