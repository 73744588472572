/**
 * The authentication methods that can be used. Incoming requests are tagged with the auth method
 * that they used and this is also included in the audit logs wherever possible. This means that,
 * for example, access via a standard login can be distinguished in the audit logs from access via
 * an API key.
 */
export enum AuthMethod {
  /** Authenticated with an API key. */
  ApiKey = "apiKey",

  /** Authenticated with a DICOM AE title and/or DICOM TLS. */
  Dicom = "dicom",

  /** @deprecated Authenticated via email and password. Use Session instead. */
  EmailAndPassword = "emailAndPassword",

  /** Authenticated with a HeartBox device token. */
  HeartBoxDeviceToken = "heartBoxDeviceToken",

  /** Authenticated via a session. */
  Session = "session",

  /** @deprecated Authenticated via study share link. Use Session instead. */
  StudyShareLink = "studyShareLink",

  /** @deprecated Authenticated with an User API key. Use ApiKey instead. */
  UserApiKey = "userApiKey",
}

/** Returns the full text to show in the UI when displaying the specified auth method. */
export function getAuthMethodDisplayName(authMethod: AuthMethod): string {
  return {
    [AuthMethod.ApiKey]: "API key",
    [AuthMethod.Dicom]: "DICOM",
    [AuthMethod.EmailAndPassword]: "Email and Password",
    [AuthMethod.HeartBoxDeviceToken]: "HeartBox device token",
    [AuthMethod.Session]: "Session",
    [AuthMethod.StudyShareLink]: "Study share Link",
    [AuthMethod.UserApiKey]: "User API key",
  }[authMethod];
}
