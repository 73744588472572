<template>
  <div ref="rootElement" class="stress-layout-table-column-headings">
    <div />

    <div
      v-for="(stage, index) of stages"
      :key="stage"
      class="column-heading"
      :data-testid="`column-heading-${index}`"
    >
      <div class="stage-dropdown">
        <DropdownWidget
          :style="{ maxWidth: `${stageDropdownMaxWidth}px`, overflow: 'hidden' }"
          :model-value="stages[index]"
          :items="stressStageNames.map((stageName) => ({ value: stageName, text: stageName }))"
          :data-testid="`stage-dropdown-${index}`"
          @update:model-value="emits('change-stage', index, $event)"
        />
      </div>

      <div class="controls">
        <Tooltip
          v-if="stages.length < 4"
          content="Add stage column to the right"
          @click="emits('add-stage', index, getUnusedStage() ?? stage)"
        >
          <FontAwesomeIcon icon="plus" :data-testid="`stage-column-add-${index}`" />
        </Tooltip>

        <Tooltip
          v-if="stages.length > 1"
          content="Remove this stage columm"
          @click="emits('remove-stage', index)"
        >
          <FontAwesomeIcon icon="xmark" :data-testid="`stage-column-remove-${index}`" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DropdownWidget from "@/components/DropdownWidget.vue";
import { Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useElementSize } from "@vueuse/core";
import { computed, ref } from "vue";
import Tooltip from "../../components/Tooltip.vue";
import { getStressStageNames } from "../study-clip-helpers";

interface Props {
  study: Study;
  stages: string[];
}

interface Emits {
  (event: "add-stage", index: number, newStage: string): void;
  (event: "remove-stage", index: number): void;
  (event: "change-stage", index: number, newStage: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const stressStageNames = computed(() => getStressStageNames(props.study));

function getUnusedStage(): string | undefined {
  return stressStageNames.value.find((stageName) => !props.stages.includes(stageName));
}

const rootElement = ref(null);

const { width: rootElementWidth } = useElementSize(rootElement);

const stageDropdownMaxWidth = computed(() => {
  // We subtract 36px because of the empty space in the corner
  const columnWidth = (rootElementWidth.value - 36) / props.stages.length;

  // There are two buttons placed in the right side of the column header when there are fewer than
  // 4 stages selected
  const columnHorizontalPadding = props.stages.length === 4 ? 28 : 48;

  return columnWidth - columnHorizontalPadding * 2;
});
</script>

<style scoped lang="scss">
.stress-layout-table-column-headings {
  background-color: var(--bg-color-2);

  display: grid;
  grid-template-columns: v-bind("`36px repeat(${stages.length}, minmax(0, 1fr))`");

  &:hover {
    .controls {
      opacity: 1;
    }
  }
}

.column-heading {
  display: grid;
  grid-template-areas: "main";
  padding: 4px 8px;

  > * {
    grid-area: main;
  }
}

.stage-dropdown {
  place-self: center;

  :deep(.dropdown-widget) {
    height: min-content;
  }
}

.controls {
  place-self: center end;
  transform: translateY(2px);

  opacity: 0;
  transition: opacity 100ms ease;

  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    cursor: pointer;
    color: var(--text-color-1);
    transition: color 100ms ease;

    &:hover {
      color: var(--text-color-2);
    }
  }
}
</style>
