<template>
  <div
    class="checkbox-container"
    :class="{ enabled, [variant]: variant }"
    :data-test-checked="modelValue"
    @click="emits('update:modelValue', !props.modelValue)"
  >
    <span class="checkbox" :class="{ checked: modelValue, [variant]: variant }">
      <FontAwesomeIcon icon="check" data-testid="checked" />
    </span>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Props {
  modelValue: boolean;
  enabled?: boolean;
  variant?: "default" | "blue";
}

interface Emits {
  (event: "update:modelValue", newValue: boolean): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true, variant: "default" });
const emits = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 1em;
  transition:
    color 100ms ease,
    border-color 100ms ease;
  color: #888;
  pointer-events: none;

  &.enabled {
    color: var(--text-color-1);
    pointer-events: auto;

    &:hover {
      color: var(--text-color-2);

      .checkbox:not(.checked) {
        border-color: var(--accent-color-2);
      }

      .checkbox.checked {
        filter: brightness(110%);
      }
    }
  }

  svg {
    opacity: 0;
    color: var(--confirm-color-2);
    transition: opacity 100ms ease;
  }

  &.blue {
    &:hover {
      .checkbox:not(.checked) {
        border-color: var(--accent-color-blue);
      }

      svg {
        color: white;
      }
    }
  }
}

.checkbox {
  min-height: 16px;
  min-width: 16px;
  border: 1px solid var(--accent-color-1);
  border-radius: var(--border-radius);
  display: grid;
  place-content: center;
  transition:
    border-color 100ms ease,
    background-color 100ms ease,
    filter 100ms ease;

  &.checked {
    color: var(--text-color-3);
    border-color: var(--confirm-color-2);

    svg {
      opacity: 1;
    }
  }

  &.blue {
    &.checked {
      color: black;
      border-color: var(--accent-color-blue);
      background-color: var(--accent-color-blue);

      svg {
        opacity: 1;
        color: white;
      }
    }
  }
}
</style>
