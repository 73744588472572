<template>
  <div class="settings-title">
    API Keys

    <a class="api-button" href="/api/docs" target="blank"> View API Documentation </a>
  </div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button
      class="accented"
      style="justify-self: end"
      data-testid="create-api-key-button"
      @click="showCreateApiKeyModal"
    >
      Create API Key
    </button>
  </div>

  <div class="api-keys-table">
    <div class="header">
      <div>Name</div>
      <div>Token</div>
      <div>Created</div>
      <div>Last Used</div>
      <div>Enabled</div>
      <div />
    </div>

    <div class="header-line" />

    <div
      v-for="apiKey in filteredApiKeys"
      :key="apiKey.id"
      class="grid-table-row"
      :data-testid="`api-key-${apiKey.name}`"
    >
      <div>{{ apiKey.name }}</div>
      <div>
        <code>{{ apiKey.tokenInitialCharacters }}</code> …
      </div>
      <div>{{ formatRelativeTime(apiKey.createdAt) }}</div>
      <div>{{ formatRelativeTime(apiKey.lastUsedAt) }}</div>
      <div style="display: grid; padding: 0; place-content: center">
        <ToggleSwitch
          v-model="apiKey.enabled"
          :data-testid="`api-key-${apiKey.name}-enabled`"
          @update:model-value="onUpdateApiKey(apiKey.id, apiKey.enabled)"
        />
      </div>
      <div style="align-self: stretch; display: grid; place-content: center">
        <Tooltip content="Delete API key" @click="onDeleteApiKey(apiKey.id)">
          <FontAwesomeIcon
            icon="trash"
            class="delete-icon"
            :data-testid="`api-key-${apiKey.name}-delete`"
          />
        </Tooltip>
      </div>
    </div>
  </div>

  <Modal
    v-if="isCreateApiKeyModalVisible"
    :title="newApiKeyToken === '' ? 'Create API Key' : 'Created API Key'"
    :activity-text="isCreatingApiKey ? 'Creating API key' : ''"
    @header-button-click="isCreateApiKeyModalVisible = false"
    @enter-key-press="createApiKey"
  >
    <div class="modal-container">
      <div class="field">
        <b>Name</b>
        <input
          ref="nameInputElement"
          v-model="newApiKeyName"
          data-testid="api-key-name-input"
          type="text"
          :disabled="newApiKeyToken !== ''"
        />
      </div>

      <SingleViewToken
        v-if="newApiKeyToken"
        header="Token"
        token-type="token"
        :token="newApiKeyToken"
      >
        To use this API key put this token into the <b><code>X-Api-Key</code></b> request header.
      </SingleViewToken>

      <button
        v-else
        class="create-api-key-button accented"
        :disabled="!isNewApiKeyValid"
        data-testid="create-api-key-button-modal"
        @click="createApiKey"
      >
        Create API Key
      </button>
    </div>
  </Modal>

  <ActivityOverlay v-if="isLoading" text="Loading" />
</template>

<script setup lang="ts">
import ActivityOverlay from "@/components/ActivityOverlay.vue";
import Tooltip from "@/components/Tooltip.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import type { UserApiKeyGetManyResponseDto } from "../../../backend/src/user/dto/user-api-key-get-many.dto";
import FilterInput from "../components/FilterInput.vue";
import Modal from "../components/Modal.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import { formatRelativeTime } from "../utils/date-time-utils";
import { getRequestErrorMessage } from "../utils/request-helpers";
import SingleViewToken from "./SingleViewToken.vue";

const apiKeys = ref<UserApiKeyGetManyResponseDto>([]);

const isCreateApiKeyModalVisible = ref(false);
const isCreatingApiKey = ref(false);

const searchTerm = ref("");
const filteredApiKeys = computed(() => {
  const lowercaseSearchTerm = searchTerm.value.toLowerCase();

  return apiKeys.value.filter((apiKey) => apiKey.name.toLowerCase().includes(lowercaseSearchTerm));
});

const isLoading = ref(false);

async function fetchApiKeys(): Promise<void> {
  isLoading.value = true;

  try {
    apiKeys.value = (await axios.get<UserApiKeyGetManyResponseDto>(`/api/user-api-keys`)).data;
  } catch {
    addNotification({ type: "error", message: "Error loading API keys" });
  } finally {
    isLoading.value = false;
  }

  // Sort list so most recently created API key is first
  apiKeys.value.sort((a, b) => (b.createdAt as string).localeCompare(a.createdAt as string));
}

const nameInputElement = ref<HTMLInputElement>();
useFocus(nameInputElement, { initialValue: true });

const newApiKeyName = ref("");
const newApiKeyToken = ref("");

function showCreateApiKeyModal(): void {
  newApiKeyName.value = "";
  newApiKeyToken.value = "";
  isCreateApiKeyModalVisible.value = true;
}

const isNewApiKeyValid = computed(() => newApiKeyName.value.trim().length !== 0);

async function createApiKey(): Promise<void> {
  if (!isNewApiKeyValid.value || newApiKeyToken.value !== "") {
    return;
  }

  isCreatingApiKey.value = true;

  try {
    const response = await axios.post<string>(`/api/user-api-keys`, { name: newApiKeyName.value });

    // Store the token for the new API key so it can be shown to the user prior to closing the modal
    newApiKeyToken.value = response.data;
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Error creating API key",
    });
    return;
  } finally {
    isCreatingApiKey.value = false;
  }

  addNotification({ type: "info", message: "Created new API key" });
  await fetchApiKeys();
}

async function onUpdateApiKey(apiKeyId: string, enabled: boolean): Promise<void> {
  try {
    await axios.patch(`/api/user-api-keys/${apiKeyId}`, { enabled });
  } catch {
    addNotification({ type: "error", message: "Failed updating API key" });
    return;
  }

  addNotification({ type: "info", message: "Updated API key" });
}

async function onDeleteApiKey(apiKeyId: string): Promise<void> {
  if (!confirm("Are you sure you want to delete this API key?")) {
    return;
  }

  try {
    await axios.delete(`/api/user-api-keys/${apiKeyId}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting API key" });
    return;
  }

  addNotification({ type: "info", message: "Deleted API key" });
  await fetchApiKeys();
}

void fetchApiKeys();
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.api-keys-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto auto;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;
    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 6;
    border-bottom: 2px solid var(--bg-color-2);
  }
}

.grid-table-row:hover .delete-icon {
  opacity: 1;
}

.modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.create-api-key-button {
  margin: 0px auto;
}

.delete-icon {
  opacity: 0;
  cursor: pointer;
  color: var(--accent-color-1);
  transition:
    color 100ms ease,
    opacity 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.api-button {
  margin-left: auto !important;
  font-size: 13px;
  text-decoration: underline;
}

input[type="text"]:disabled {
  opacity: 1;
}
</style>
