<template>
  <a @click="fileInputElement?.click()">
    <FontAwesomeIcon data-testid="upload-file-button" icon="upload" />
    <b>Upload</b>

    <input
      ref="fileInputElement"
      data-testid="upload-file-input"
      multiple
      hidden
      type="file"
      @change="
        isUploadFilesModalVisible = true;
        selectedFileCount = fileInputElement?.files?.length ?? 0;
      "
    />

    <Modal
      v-if="isUploadFilesModalVisible"
      title="Upload Files"
      @header-button-click="hideUploadFilesModal"
    >
      <div class="deidentify-container">
        <div v-if="currentTenant.isDeidentificationPermitted">
          <div class="toggle">
            <b>Enable Deidentification</b>
            <ToggleSwitch
              v-model="isDeidentificationEnabled"
              data-testid="enable-deidentification"
            />
          </div>

          <p>
            Deidentification means certain patient and study fields will not be shown, such as the
            patient name, ID, and birthdate.
          </p>
        </div>

        <button class="accented" data-testid="upload-files-button" @click="onUploadFiles()">
          Upload {{ selectedFileCount }} File{{ selectedFileCount !== 1 ? "s" : "" }}
        </button>
      </div>
    </Modal>
  </a>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useEventListener } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";
import { reactive, ref } from "vue";
import type { StorageGetPresignedUrlResponseDto } from "../../../backend/src/storage/dto/get-presigned-url.dto";
import { ObjectType } from "../../../backend/src/storage/object-type";
import { UPLOAD_SIZE_LIMIT } from "../../../backend/src/storage/upload-size-limit";
import type { IngestUploadedFileResponseDto } from "../../../backend/src/studies/dto/ingest-uploaded-file.dto";
import { updateLastActivityTimestamp } from "../auth/auto-sign-out";
import { currentTenant } from "../auth/current-session";
import {
  UploadJobState,
  getActiveUploadJobCount,
  uploadJobs,
  type UploadJob,
} from "../utils/upload-jobs";

const UPLOAD_CONCURRENCY = 8;

const fileInputElement = ref<HTMLInputElement | null>(null);

const isUploadFilesModalVisible = ref(false);

function hideUploadFilesModal(): void {
  // Clear out the value on the input field so that repeated selection of the same file(s)
  // triggers a re-upload
  fileInputElement.value!.value = "";

  isUploadFilesModalVisible.value = false;
}

const isDeidentificationEnabled = ref(false);

const selectedFileCount = ref(0);

async function onUploadFiles(): Promise<void> {
  isUploadFilesModalVisible.value = false;

  await uploadFiles(fileInputElement.value!.files!, isDeidentificationEnabled.value);

  // Clear out the value on the input field so that repeated selection of the same file(s)
  // triggers a re-upload
  fileInputElement.value!.value = "";
}

async function uploadFiles(selectedFiles: FileList, deidentify: boolean): Promise<File[]> {
  let files: File[] = [];

  for (let i = 0; i < selectedFiles.length; i++) {
    const file = fileInputElement.value!.files!.item(i);
    if (file === null) {
      continue;
    }

    if (file.type === "application/zip" || file.type === "application/x-zip-compressed") {
      await uploadZipDicomEntries(file, deidentify);
    } else if (isDicom(new Uint8Array(await file.arrayBuffer()))) {
      if (file.size > UPLOAD_SIZE_LIMIT) {
        addFileTooLargeEntry(file.name);
        continue;
      }

      startFileUpload(file.name, file, deidentify);
    } else {
      uploadJobs.value.push({
        id: uuidv4(),
        title: file.name,
        state: UploadJobState.Error,
        message: "This file is neither a DICOM nor a ZIP",
      });

      addNotification({
        type: "error",
        message: "One or more files were not of a supported type",
      });
    }
  }

  return files;
}

function addFileTooLargeEntry(filename: string): void {
  uploadJobs.value.push({
    id: uuidv4(),
    title: filename,
    state: UploadJobState.Error,
    message: "This file exceeds the upload size limit of 1GB",
  });

  addNotification({
    type: "error",
    message: "One or more files exceeded the upload size limit",
  });
}

function startFileUpload(title: string, file: File, deidentifyPatient: boolean): void {
  const uploadJob: UploadJob = reactive({
    id: uuidv4(),
    title,
    uploadProgress: 0,
    state: UploadJobState.Pending,
    size: file.size,
    abortController: new AbortController(),
    message: "Upload is pending",
  });

  uploadJobs.value.push(uploadJob);

  const timer = setInterval(() => {
    // Limit the number of upload POST requests that are run concurrently
    if (
      uploadJobs.value.filter(
        (uploadJob) => uploadJob.state === UploadJobState.InProgress && !(uploadJob.isZip === true)
      ).length >= UPLOAD_CONCURRENCY
    ) {
      return;
    }

    clearInterval(timer);

    uploadJob.state = UploadJobState.InProgress;

    async function performUpload() {
      try {
        const { presignedUrl, uploadedFileName } = await getPresignedUrl(file.size);

        await uploadFile(presignedUrl, file, uploadJob);

        if (uploadJob.state !== UploadJobState.Cancelled) {
          await ingestUploadedFile(uploadedFileName, uploadJob, deidentifyPatient);
        }
      } catch (error) {
        console.error(error);

        if (uploadJob.state === UploadJobState.Error) {
          addNotification({ type: "error", message: "One or more files failed to upload" });
        }
      }
    }

    void performUpload();
  }, 100);
}

/**
 * Returns whether the passed file is a DICOM by checking its header matches.
 */
function isDicom(fileBuffer: Uint8Array): boolean {
  return (
    fileBuffer.length >= 132 &&
    fileBuffer[128] === 68 &&
    fileBuffer[129] === 73 &&
    fileBuffer[130] === 67 &&
    fileBuffer[131] === 77
  );
}

/**
 * Uploads DICOM files from the ZIP archive.
 */
async function uploadZipDicomEntries(file: File, deidentify: boolean): Promise<void> {
  const zipExtraction: UploadJob = reactive({
    id: uuidv4(),
    title: `Extracting ${file.name} ...`,
    progress: 0,
    state: UploadJobState.InProgress,
    isZip: true,
  });

  uploadJobs.value.push(zipExtraction);

  let zip: JSZip | undefined = undefined;
  try {
    zip = await JSZip.loadAsync(file);
  } catch {
    zipExtraction.state = UploadJobState.Error;
    zipExtraction.message = "Failed reading this file as a ZIP";

    addNotification({ type: "error", message: "Error reading ZIP file" });
    return;
  }

  let dicomCount = 0;
  const filenames = Object.keys(zip.files);

  for (let i = 0; i < filenames.length; i++) {
    const name = filenames[i];

    const blob = await getZipObjectBlob(zip.files[name]);
    if (blob === null) {
      continue;
    }

    dicomCount++;
    startFileUpload(`${file.name}: ${name}`, new File([blob], name), deidentify);

    zipExtraction.progress = i / filenames.length;
  }

  if (dicomCount === 0) {
    zipExtraction.state = UploadJobState.Info;
    zipExtraction.message = "This ZIP file contained no DICOMs";

    addNotification({ type: "error", message: "ZIP file contained no DICOMs" });
    return;
  }

  zipExtraction.title = `Extracted ${file.name}`;
  zipExtraction.state = UploadJobState.Completed;
  zipExtraction.message = `Found ${dicomCount} DICOM files in this ZIP to upload`;
}

async function getZipObjectBlob(zipObject: JSZip.JSZipObject): Promise<Blob | null> {
  // Ignore OS metadata files
  if (
    zipObject.dir ||
    zipObject.name.startsWith("__MACOSX/") ||
    zipObject.name.endsWith(".DS_Store") ||
    zipObject.name.endsWith("desktop.ini") ||
    zipObject.name.toLowerCase().endsWith("dicomdir")
  ) {
    return null;
  }

  const blob = await zipObject.async("blob");
  if (blob.size > UPLOAD_SIZE_LIMIT) {
    addFileTooLargeEntry(zipObject.name);
    return null;
  }

  const zipObjectUint8Array = new Uint8Array(await blob.arrayBuffer());
  if (!isDicom(zipObjectUint8Array)) {
    return null;
  }

  return blob;
}

// If there are uploads in progress when the user tries to close the page then prompt them that they
// may want to wait for the uploads to complete. Unfortunately it's not possible to control the text
// of the prompt, so they'll likely just get a generic "you have unsaved changes" message.
function onBeforeUnload(e: Event): void {
  if (getActiveUploadJobCount() !== 0) {
    // Both of the following are needed for it to work in Firefox, Safari, and Chrome
    e.preventDefault();
    e.returnValue = true;
  }
}

async function getPresignedUrl(contentLength: number): Promise<StorageGetPresignedUrlResponseDto> {
  const queryParams = new URLSearchParams({
    contentLength: String(contentLength),
  });

  let response: AxiosResponse<StorageGetPresignedUrlResponseDto> | undefined = undefined;

  try {
    response = await axios.get<StorageGetPresignedUrlResponseDto>(
      `/api/storage/presigned-url?${queryParams.toString()}`
    );
  } catch (error) {
    throw Error("Failed getting presigned URL");
  }

  return response.data;
}

async function uploadFile(url: string, file: File, uploadJob: UploadJob): Promise<void> {
  try {
    await axios.put(url, file, {
      onUploadProgress(progressEvent) {
        if (progressEvent.total === undefined) {
          return;
        }

        uploadJob.progress = progressEvent.loaded / progressEvent.total;
        uploadJobs.value = [...uploadJobs.value];

        // Prevent automatic sign out while uploads are occurring
        updateLastActivityTimestamp();
      },
      timeout: 0,
      signal: uploadJob.abortController?.signal,
      headers: { "x-amz-tagging": `Type=${ObjectType.UploadedFile}` },
    });
  } catch (error) {
    if (uploadJob.abortController?.signal.aborted === true) {
      uploadJob.state = UploadJobState.Cancelled;
    } else {
      throw Error("Failed uploading file");
    }
  }
}

async function ingestUploadedFile(
  uploadedFileName: string,
  uploadJob: UploadJob,
  deidentify: boolean
): Promise<void> {
  let response: AxiosResponse<IngestUploadedFileResponseDto> | undefined = undefined;
  try {
    response = await axios.post<IngestUploadedFileResponseDto>(
      `/api/studies/ingest-uploaded-file`,
      { uploadedFileName, deidentify }
    );
  } catch {
    throw Error("Failed ingesting DICOM file");
  }

  if (response.data.isDuplicate) {
    uploadJob.state = UploadJobState.Info;
    uploadJob.message = "This DICOM is already present";
  } else {
    uploadJob.state = UploadJobState.Completed;
    uploadJob.message = undefined;
  }
}

useEventListener(window, "beforeunload", onBeforeUnload);
</script>

<style scoped lang="scss">
.deidentify-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 440px;
}

.toggle {
  display: flex;
  gap: 16px;
  align-items: center;
}

button {
  width: max-content;
  align-self: center;
}
</style>
