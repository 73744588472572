import { addNotification } from "@/utils/notifications";
import axios, { type AxiosResponse } from "axios";
import { ref, watch, type Ref } from "vue";
import type { PatientStudiesGetManyResponseDto } from "../../../backend/src/studies/dto/patient-studies-get-many.dto";
import type { Study } from "../utils/study-data";

/**
 * Returns a ref containing all of the studies for the patient in the passed study. The returned ref
 * will automatically update when the passed study is changed.
 */
export function usePatientStudies(study: Ref<Study>): Ref<PatientStudiesGetManyResponseDto> {
  const studies = ref<PatientStudiesGetManyResponseDto>([]);

  async function fetchData(): Promise<void> {
    // Avoid making a request if the study doesn't have enough information to sufficiently identify
    // the patient
    if (
      study.value.patientId.trim().length === 0 ||
      study.value.patientBirthdate === null ||
      (study.value.patientName[0] ?? "").trim().length === 0
    ) {
      return;
    }

    let response: AxiosResponse<PatientStudiesGetManyResponseDto> | undefined = undefined;

    try {
      response = await axios.post<PatientStudiesGetManyResponseDto>(
        `/api/studies/${study.value.id}/related-studies`
      );

      studies.value = response.data;
    } catch (error) {
      addNotification({ type: "error", message: "Failed loading other studies for this patient" });
    }
  }

  watch(
    () => [study.value.patientId, study.value.patientBirthdate, study.value.patientName],
    fetchData,
    { immediate: true }
  );

  return studies;
}
