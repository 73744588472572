<template>
  <Popper
    placement="top-end"
    :show="isPopperOpen"
    :offset-distance="4"
    class="measurement-name-selector-popper"
  >
    <button
      ref="selectMeasurementButton"
      data-testid="select-measurement-btn"
      :class="{ accented, active: isPopperOpen }"
      @click="togglePopper"
    >
      {{
        activeMeasurement.measurementName.value === undefined
          ? "Select Measurement"
          : customMeasurementName ??
            getMeasurementDisplayName(activeMeasurement.measurementName.value, "unindexed")
      }}
    </button>

    <template #content>
      <div
        ref="popperContentElement"
        class="measurement-name-selector"
        :class="{ 'measurement-list-overflowing': isMeasurementListOverflowing }"
      >
        <div class="measurement-list">
          <OverlayScrollbar
            :style="{ flex: filteredMeasurementNames.length + 1 }"
            :scroll-target="measurementListScrollTarget"
            auto-hide="never"
            scroll-target-block="nearest"
            @has-overflow="isMeasurementListOverflowing = $event"
          >
            <template
              v-for="(measurementName, index) in filteredMeasurementNames"
              :key="measurementName"
            >
              <div
                v-if="!isCustomMeasurement(measurementName)"
                :id="`measurements-list-item-${measurementName}`"
                class="measurement-list-item"
                :class="{ selected: index === selectedMeasurementIndex }"
                :data-testid="`select-measurement-${measurementName}`"
                @click="onSelectMeasurement(measurementName)"
                @mouseover="onMeasurementNameMouseOver(index)"
              >
                {{ getMeasurementDisplayName(measurementName, "unindexed") }}
              </div>
            </template>
          </OverlayScrollbar>

          <div
            v-if="filteredMeasurementNames.length === 0"
            style="text-align: center; padding: 8px"
          >
            No search results
          </div>
        </div>

        <div
          v-if="isCustomItemVisible && activeMeasurement.customMeasurementName !== undefined"
          data-testid="custom-measurement-name"
          class="measurement-list-item custom"
          :class="{ selected: selectedMeasurementIndex === filteredMeasurementNames.length - 1 }"
          @mouseover="onMeasurementNameMouseOver(filteredMeasurementNames.length - 1)"
          @click="onSelectMeasurement(activeMeasurement.customMeasurementName, measurementFilter)"
        >
          <b>Custom:</b> "{{ measurementFilter }}"
        </div>

        <div class="filter-input">
          <input
            ref="measurementFilterInput"
            v-model="measurementFilter"
            data-testid="measurement-name-filter"
            placeholder="Enter measurement name"
            @keydown.arrow-down.stop.prevent="onInputArrowKeyPressed(1)"
            @keydown.arrow-up.stop.prevent="onInputArrowKeyPressed(-1)"
            @keydown.enter="onEnterPressed"
            @input="selectedMeasurementIndex = null"
          />

          <FontAwesomeIcon
            icon="times"
            class="delete-icon"
            :class="{ hidden: measurementFilter === '' }"
            @click="clearFilter"
          />
        </div>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onClickOutside, useDebounceFn } from "@vueuse/core";
import { computed, ref } from "vue";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../backend/src/measurements/measurement-names";
import { positiveModulo } from "../../../backend/src/shared/math-utils";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import { filterMeasurementNames } from "./measurement-helpers";
import { activeMeasurement } from "./measurement-tool-state";

interface Props {
  customName: string | null;
  accented?: boolean;
}

interface Emits {
  (event: "update:customName", value: string): void;
}

const props = withDefaults(defineProps<Props>(), { accented: false });
const emits = defineEmits<Emits>();

const isPopperOpen = ref(false);

const selectMeasurementButton = ref<HTMLButtonElement | null>(null);
const popperContentElement = ref<HTMLDivElement | null>(null);

const selectedMeasurementIndex = ref<number | null>(null);
const measurementListScrollTarget = ref<HTMLElement | null>(null);

const isMeasurementListOverflowing = ref(false);

function togglePopper() {
  isPopperOpen.value = !isPopperOpen.value;

  if (
    activeMeasurement.value.measurementName.value !== undefined &&
    isCustomMeasurement(activeMeasurement.value.measurementName.value)
  ) {
    measurementFilter.value = activeMeasurement.value.customName.value;
  } else {
    measurementFilter.value = "";
  }

  selectedMeasurementIndex.value = null;

  const name = activeMeasurement.value.measurementName.value;
  if (name !== undefined) {
    const index = filteredMeasurementNames.value.indexOf(name);
    if (index !== -1) {
      selectedMeasurementIndex.value = index;
    }
  }

  measurementFilterInput.value?.focus();
}

// Close the popper on clicks outside it, except for clicks on the main button
onClickOutside(popperContentElement, (e) => {
  if (selectMeasurementButton.value === e.target) {
    return;
  }

  isPopperOpen.value = false;
});

const customMeasurementName = computed(() =>
  activeMeasurement.value.measurementName.value !== undefined &&
  isCustomMeasurement(activeMeasurement.value.measurementName.value)
    ? props.customName
    : undefined
);

const measurementFilter = ref("");
const measurementFilterInput = ref<HTMLInputElement | null>(null);

const isCustomItemVisible = computed(() => measurementFilter.value.trim() !== "");

function clearFilter() {
  measurementFilter.value = "";
  measurementFilterInput.value?.focus();
}

const filteredMeasurementNames = computed(() => {
  const names = filterMeasurementNames(
    activeMeasurement.value.getCreatableMeasurementNames(),
    measurementFilter.value
  );

  // Put the custom option at the end
  names.push(activeMeasurement.value.customMeasurementName!);

  return names;
});

async function onEnterPressed() {
  if (selectedMeasurementIndex.value === null) {
    return;
  }

  await onSelectMeasurement(
    filteredMeasurementNames.value[selectedMeasurementIndex.value],
    measurementFilter.value
  );
}

async function onSelectMeasurement(
  name: MeasurementName | undefined,
  customName?: string
): Promise<void> {
  if (name === undefined && (customName === undefined || measurementFilter.value === "")) {
    isPopperOpen.value = true;
    return;
  }

  isPopperOpen.value = false;

  // Wait for the popper to fade out before performing the change. This makes the transition more
  // aesthetically pleasing because otherwise the popper jumps briefly to the left/right before
  // fading out, due to the different width of the newly selected measurement name.
  await new Promise((resolve) => setTimeout(resolve, 100));

  activeMeasurement.value.measurementName.value = name;

  if (
    activeMeasurement.value.measurementName.value !== undefined &&
    isCustomMeasurement(activeMeasurement.value.measurementName.value)
  ) {
    emits("update:customName", measurementFilter.value);
  }

  selectedMeasurementIndex.value = null;
}

let isMouseOverEnabled = true;
const enableMouseOverDebounced = useDebounceFn(() => (isMouseOverEnabled = true), 250);

function onMeasurementNameMouseOver(index: number) {
  if (isMouseOverEnabled) {
    selectedMeasurementIndex.value = index;
  }
}

function onInputArrowKeyPressed(delta: number) {
  // Disable mouseover updating of the selected measurement index briefly following a press of the
  // up/down arrows. This avoids it triggering on mouseover events fired immediately after scrolling
  // that can be caused by the change to `measurementListScrollTarget`.
  isMouseOverEnabled = false;
  void enableMouseOverDebounced();

  const names = filteredMeasurementNames.value;
  const nameCount = isCustomItemVisible.value ? names.length : names.length - 1;

  if (selectedMeasurementIndex.value === null) {
    if (delta === 1) {
      selectedMeasurementIndex.value = 0;
    } else {
      selectedMeasurementIndex.value = nameCount - 1;
    }
  } else {
    selectedMeasurementIndex.value = positiveModulo(
      selectedMeasurementIndex.value + delta,
      nameCount
    );
  }

  // Scroll to the measurement that's now selected
  measurementListScrollTarget.value = document.getElementById(
    `measurements-list-item-${names[selectedMeasurementIndex.value]}`
  );
}
</script>

<style scoped lang="scss">
.measurement-name-selector {
  // Put a shadow at the bottom of the measurement list for clearer visual separation
  &.measurement-list-overflowing {
    .measurement-list + * {
      box-shadow: 0 0 8px 4px rgba(black, 0.5);
      clip-path: inset(-15px 0 0 0);
      z-index: 1;
    }
  }
}

.measurement-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: min(300px, 50vh);
  overflow-y: auto;
  font-weight: normal;
  min-width: 240px;
  padding: 0px 0px 0px;
}

.measurement-list-item {
  border-radius: var(--border-radius);
  padding: 6px 8px;
  cursor: pointer;
  transition:
    background-color 100ms ease,
    color 100ms ease;
  line-height: 1em;
  border-radius: 0;

  &.selected {
    background-color: var(--bg-color-4);
    color: var(--text-color-2);
  }

  &.custom {
    padding: 8px;
    border-top: v-bind(
      "filteredMeasurementNames.length > 0 ? '1px solid var(--border-color-1)' : ''"
    );
  }
}

.filter-input {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--bg-color-3);

  input {
    flex: 1;
    border-radius: 0;
    border: none;
    background: none;
    line-height: 1em;

    &:focus {
      background: none;
    }
  }

  .delete-icon {
    cursor: pointer;
    transition: color 100ms ease;
    padding: 0 8px;

    &:hover {
      color: var(--text-color-2);
    }

    &.hidden {
      visibility: hidden;
    }
  }
}

:deep(.measurement-name-selector-popper) {
  padding: 0;
}
</style>
