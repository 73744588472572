<template>
  <div class="settings-title">Tenant Settings</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button class="accented" style="justify-self: end" @click="showAddTenantModal">
      Create Tenant
    </button>
  </div>

  <div v-if="!isLoading" class="tenants-table">
    <div class="header">
      <div class="header-item" @click="onSortColumn('name')">
        <div class="title">Name</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'name' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('aeTitle')">
        <div class="title">AE Title</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'aeTitle' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('studyCount')">
        <div class="title">Studies</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'studyCount' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('recentStudyCount')">
        <Tooltip content="Number of studies added in the past week">
          <div class="title">Recent Studies</div>
        </Tooltip>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'recentStudyCount' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('userCount')">
        <div class="title">Users</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'userCount' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('recentActiveUserCount')">
        <Tooltip content="Number of active users in the past week">
          <div class="title">Recent Active Users</div>
        </Tooltip>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'recentActiveUserCount' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('dicomSize')">
        <div class="title">DICOM Size</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'dicomSize' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('lastActivityAt')">
        <div class="title">Last Active</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'lastActivityAt' }"
          :icon="sortIconName"
        />
      </div>

      <div class="header-item" @click="onSortColumn('includeInStats')">
        <div class="title">Include in Stats</div>
        <FontAwesomeIcon
          class="sort-icon"
          :class="{ visible: sortColumnName === 'includeInStats' }"
          :icon="sortIconName"
        />
      </div>
    </div>

    <div class="header-line" />

    <div
      v-for="tenant in tenants"
      :key="tenant.id"
      class="grid-table-row"
      @click="onViewTenant(tenant.id)"
    >
      <div :data-testid="`view-tenant-${tenant.name}`">{{ tenant.name }}</div>
      <code>{{ tenant.aeTitle }}</code>
      <div>{{ tenant.studyCount }}</div>
      <div>{{ tenant.recentStudyCount }}</div>
      <div>{{ tenant.userCount }}</div>
      <div>{{ tenant.recentActiveUserCount }}</div>
      <div>{{ prettyBytes(tenant.dicomSize) }}</div>
      <div>{{ formatRelativeTime(tenant.lastActivityAt) }}</div>
      <div>{{ tenant.includeInStats ? "Yes" : "No" }}</div>
    </div>
  </div>

  <LoadingIndicator v-else style="align-self: center" size="2x" />

  <Modal
    v-if="isAddTenantModalVisible"
    title="Create Tenant"
    :activity-text="isCreatingTenant ? 'Creating tenant' : ''"
    @header-button-click="isAddTenantModalVisible = false"
    @enter-key-press="onCreateTenant"
  >
    <div class="create-tenant-modal">
      <div class="field">
        <b>Name</b>
        <input ref="newTenantNameInputElement" v-model="newTenantName" />
      </div>

      <div class="field">
        <b>AE Title Prefix</b>
        <input v-model="newTenantAeTitlePrefix" maxlength="9" />
      </div>

      <div class="field">
        <b>Salesforce ID (Optional)</b>
        <input v-model="newSalesforceId" />
      </div>

      <button class="accented" :disabled="!isNewTenantComplete" @click="onCreateTenant">
        Create Tenant
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import { computed, ref } from "vue";
import type { TenantGetManyResponseDto } from "../../../backend/src/tenants/global-admin/dto/tenant-get-many.dto";
import FilterInput from "../components/FilterInput.vue";
import Modal from "../components/Modal.vue";
import router from "../router";
import { useTenants } from "../utils/all-tenants";
import { formatRelativeTime } from "../utils/date-time-utils";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";

const allTenants = useTenants();
const searchTerm = ref("");
const isAddTenantModalVisible = ref(false);

type SortColumnName = keyof TenantGetManyResponseDto[0];

const sortColumnName = ref<SortColumnName>("name");
const sortDirection = ref<"ASC" | "DESC">("ASC");
const sortIconName = computed(() =>
  sortDirection.value === "ASC" ? "chevron-up" : "chevron-down"
);

const isLoading = ref(false);

const tenants = computed(() =>
  allTenants.value
    .filter((tenant) => tenant.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
    .sort((tenant0, tenant1) => {
      let items = [tenant0[sortColumnName.value], tenant1[sortColumnName.value]];

      if (sortDirection.value === "DESC") {
        items = [items[1], items[0]];
      }

      if (typeof items[0] === "number") {
        return Number(items[0]) - Number(items[1]);
      }

      return (items[0]?.toString() ?? "").localeCompare(items[1]?.toString() ?? "");
    })
);

function onSortColumn(columnName: SortColumnName): void {
  if (sortColumnName.value === columnName) {
    sortDirection.value = sortDirection.value === "DESC" ? "ASC" : "DESC";
  } else {
    sortColumnName.value = columnName;
    sortDirection.value = "ASC";
  }
}

async function onViewTenant(tenantId: string): Promise<void> {
  await router.push({ name: "settings-tenants-view", params: { id: tenantId } });
}

function showAddTenantModal(): void {
  isAddTenantModalVisible.value = true;
  newTenantName.value = "";
}

const newTenantName = ref("");
const newTenantAeTitlePrefix = ref("");
const newSalesforceId = ref("");

const newTenantNameInputElement = ref<HTMLInputElement>();
useFocus(newTenantNameInputElement, { initialValue: true });

const isCreatingTenant = ref(false);

const isNewTenantComplete = computed(
  () =>
    newTenantName.value.trim().length > 0 && /^[A-Z0-9-]{2,9}$/.test(newTenantAeTitlePrefix.value)
);

async function onCreateTenant(): Promise<void> {
  if (!isNewTenantComplete.value) {
    return;
  }

  isCreatingTenant.value = true;

  try {
    await axios.post(`/api/global/tenants`, {
      name: newTenantName.value,
      aeTitlePrefix: newTenantAeTitlePrefix.value,
      salesforceAccountId: newSalesforceId.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed creating tenant",
    });
    return;
  } finally {
    isCreatingTenant.value = false;
  }

  addNotification({ type: "info", message: "Created new tenant" });

  useTenants(); // Force a refresh of the tenant list

  isAddTenantModalVisible.value = false;
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.tenants-table {
  display: grid;
  grid-template-columns: repeat(8, 1fr) min-content;
  row-gap: 8px;

  .header {
    display: contents;

    .header-item {
      display: flex;
      gap: 12px;
      cursor: pointer;
      transition: color 100ms ease;

      .title {
        font-weight: bold;
      }

      .sort-icon {
        color: var(--accent-color-1);
        transition: color 100ms ease;
        visibility: hidden;

        &.visible {
          visibility: visible;
        }
      }

      &:hover {
        color: var(--text-color-2);

        .sort-icon {
          color: var(--accent-color-2);
        }
      }
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 9;
    border-bottom: 2px solid var(--bg-color-3);
  }
}

.create-tenant-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  button {
    width: max-content;
    margin: 0 auto;
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
