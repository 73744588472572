<template>
  <div ref="el" @mousedown="onElementMouseDown" @touchstart="onElementTouchStart">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Props {
  enabled?: boolean;
}

interface Emits {
  (event: "start"): void;
  (event: "move", args: { xFraction: number; xDelta: number }): void;
  (event: "end"): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true });
const emits = defineEmits<Emits>();

const el = ref<HTMLDivElement>();

let initialPosition: number[] = [];

function onElementMouseDown(event: MouseEvent): void {
  if (!props.enabled || event.button !== 0) {
    return;
  }

  initialPosition = [event.screenX, event.screenY];

  emits("start");
  emitMove(event);

  addGlobalMouseEventListeners();
}

function onElementTouchStart(event: TouchEvent): void {
  if (!props.enabled) {
    return;
  }

  emits("start");
  emitMove(event.touches[0]);
  addGlobalTouchEventListeners();
}

function addGlobalMouseEventListeners(): void {
  document.addEventListener("mousemove", onDocumentMouseMove, true);
  document.addEventListener("mouseup", onDocumentMouseUp, true);
}

function removeGlobalMouseEventListeners(): void {
  document.removeEventListener("mousemove", onDocumentMouseMove, true);
  document.removeEventListener("mouseup", onDocumentMouseUp, true);
}

function addGlobalTouchEventListeners(): void {
  document.addEventListener("touchmove", onDocumentTouchMove, true);
  document.addEventListener("touchend", onDocumentTouchEnd, true);
}

function removeGlobalTouchEventListeners(): void {
  document.removeEventListener("touchmove", onDocumentTouchMove, true);
  document.removeEventListener("touchend", onDocumentTouchEnd, true);
}

function onDocumentMouseMove(event: MouseEvent): void {
  if (event.buttons === 1) {
    emitMove(event);
  } else {
    emits("end");
    removeGlobalMouseEventListeners();
  }

  event.preventDefault();
  event.stopPropagation();
}

function onDocumentMouseUp(event: MouseEvent): void {
  onDocumentMouseMove(event);
}

function onDocumentTouchMove(event: TouchEvent): void {
  emitMove(event.touches[0]);
  event.preventDefault();
  event.stopPropagation();
}

function onDocumentTouchEnd(event: TouchEvent): void {
  emits("end");
  removeGlobalTouchEventListeners();
  event.cancelable && event.preventDefault();
  event.stopPropagation();
}

function emitMove(event: MouseEvent | Touch): void {
  const clientRect = el.value!.getBoundingClientRect();

  const x = event.clientX - clientRect.left;
  const xFraction = Math.min(Math.max(0, x / el.value!.offsetWidth), 1);

  const xDelta = initialPosition[0] - event.screenX;

  emits("move", { xFraction, xDelta });
}
</script>
