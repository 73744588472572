<template>
  <div
    ref="rootElement"
    class="structured-report-section"
    :class="{ heading: section.type === 'heading' }"
  >
    <ReportSectionHeader
      :study="study"
      :report="report"
      :mode="mode"
      :section-id="sectionId"
      @mutate-structure="emits('mutate-structure', $event)"
      @done="emits('done')"
    />
    <template v-if="section.type !== 'heading'">
      <div
        v-if="isEditingReportContent && section.structuredFieldColumns.flat().length > 0"
        :class="{
          [`rpt-section-${section.type}`]: true,
          'formatting-enabled': section.type === 'table' && section.isFormattingEnabled,
        }"
        :style="{ '--rpt-table-column-count': columns.length }"
      >
        <div
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :class="{ 'rpt-section-fields-column': section.type === 'fields' }"
        >
          <div
            v-for="(field, fieldIndex) in column"
            :key="fieldIndex"
            :class="`field-type-${field.type}`"
            :style="{
              gridArea: `${fieldIndex + 1} / ${columnIndex + 1} / ${fieldIndex + 2} / ${
                columnIndex + 2
              }`,
              display: 'grid',
              alignContent: getFieldAlignmentCSS(section, field).alignSelf,
            }"
          >
            <ReportFieldContent
              :key="field.id"
              :study="study"
              :report="report"
              :calculation-scope="calculationScope"
              :section-id="section.id"
              :field="field"
              :section-content="sectionContent"
              :scale-factor="scaleFactor"
              :initial-focus="
                columnIndex === firstEditableFieldPosition?.column &&
                fieldIndex === firstEditableFieldPosition?.row
              "
              @update-report-content="emits('update-report-content')"
              @enter="focusNextField(field)"
            />
          </div>
        </div>

        <ReportTableGridLines
          v-if="section.type === 'table' && section.isFormattingEnabled"
          :row-count="rowCount"
          :column-count="columns.length"
        />
      </div>

      <div v-else-if="isViewingReportStructure" class="columns">
        <div
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          ref="columnElements"
          class="column"
        >
          <div
            v-if="isEditingReportStructure && column.length === 0 && columns.length > 1"
            :options="[{ key: '', text: 'Remove column' }]"
            class="add-remove-column-button"
            @click="
              emits('mutate-structure', createSectionColumnRemoveMutation(section.id, columnIndex))
            "
          >
            Remove column
          </div>

          <DraggableList
            class="column"
            :class="{ 'ensure-drop-area-exists': columns.flat().length !== 0 }"
            :enabled="isEditingReportStructure"
            :group="sectionId"
            @reorder="onReorderFields"
          >
            <ReportFieldStructure
              v-for="field in column"
              :key="field.id"
              :section-id="section.id"
              :field="field"
              :field-id="field.id"
              :mode="mode"
              :scale-factor="scaleFactor"
              :structure="structure!"
              @duplicate="onDuplicateField(field.id)"
              @name-input-enter-pressed="onFieldNameEnterPressed(columnIndex, field.id)"
              @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
            />
          </DraggableList>

          <AddReportStructureButton
            v-if="isEditingReportStructure"
            :options="[{ key: 'field', text: 'Add field' }]"
            @click="onAddField(columnIndex)"
          />
        </div>
      </div>
    </template>

    <div v-if="isFooterVisible" class="footer" :data-testid="`rpt-section-footer-${section.name}`">
      <FontAwesomeIcon
        v-if="isEditingReportContent && hasSentenceFields"
        icon="arrow-turn-up"
        rotation="90"
        size="lg"
        class="footer-arrow"
      />

      <div class="footer-content">
        <template v-if="isEditingReportContent && hasSentenceFields">
          <div
            v-if="getFinalReportSectionText(report, sectionId, calculationScope) !== ''"
            class="generated-text"
          >
            <ReportSectionContent
              :study="study"
              :report="report"
              :calculation-scope="calculationScope"
              :section-id="sectionId"
              :show-fields="false"
              :data-testid="`rpt-section-content-${section.name}`"
            />
          </div>
          <div v-else class="generated-text placeholder rpt-text-normal">Generated text</div>
        </template>

        <template v-if="isEditingReportStructure">
          <div style="display: flex; gap: 0.8em; align-items: center; margin-top: 0.4em">
            <ReportCheckbox
              :model-value="section.isCommentFieldVisible"
              @update:model-value="
                emits('mutate-structure', createSectionCommentFieldVisibleToggleMutation(sectionId))
              "
            >
              Show free-text comment field
            </ReportCheckbox>

            <ReportCheckbox
              v-if="section.type !== 'table'"
              :model-value="section.isFieldCompactionEnabled"
              @update:model-value="
                emits(
                  'mutate-structure',
                  createSectionFieldCompactionEnabledToggleMutation(sectionId)
                )
              "
            >
              Compact fields on final report
            </ReportCheckbox>

            <ReportCheckbox
              v-if="section.type === 'table'"
              :model-value="section.isFormattingEnabled"
              @update:model-value="
                emits(
                  'mutate-structure',
                  createSectionFieldFormattingEnabledToggleMutation(sectionId)
                )
              "
            >
              Enable table formatting
            </ReportCheckbox>

            <Popper placement="bottom-start" :offset-distance="0" locked class="sentences-popper">
              <div
                v-if="isEditingReportStructure"
                class="sentences-button"
                :data-testid="`sentences-button-${section.name}`"
              >
                <FontAwesomeIcon icon="book" />
                <b>Sentence Groups</b> ({{ section.sentenceGroupIds.length }} selected)
              </div>

              <template #content>
                <ReportSectionSentenceSelection
                  :structure="structure"
                  :section="section"
                  @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
                />
              </template>
            </Popper>

            <div
              class="add-remove-column-button"
              :class="{
                disabled:
                  section.structuredFieldColumns.length >=
                  REPORT_TEMPLATE_SECTION_COLUMNS_MAX_COUNT,
              }"
              style="margin-left: auto"
              @click="emits('mutate-structure', createSectionColumnAddMutation(section.id))"
            >
              Add column
            </div>
          </div>

          <div
            v-if="section.isCommentFieldVisible"
            class="rpt-fancy-input rpt-text-normal"
            style="background: white"
          >
            <ResizingTextbox
              v-model="section.commentFieldDefault"
              placeholder="Enter default free-text comment here"
              allow-newlines
              :scale-factor="scaleFactor"
              :data-testid="`comment-box-default-${section.name}`"
              @update:model-value="
                emits('mutate-structure', createSectionSentenceDefaultTextMutation(section.id))
              "
            />
          </div>
        </template>

        <div
          v-else-if="isEditingReportContent && section.isCommentFieldVisible"
          style="background: white; display: grid; grid-template-columns: 1fr auto; gap: 0.6em"
          class="rpt-fancy-input rpt-text-normal"
        >
          <ResizingTextbox
            ref="commentTextbox"
            v-model="sectionContent.comment"
            placeholder="Enter details here"
            allow-newlines
            :disabled="!isStudyUpdatePermitted(study)"
            :scale-factor="scaleFactor"
            :data-testid="`comment-box-${section.name}`"
            @update:model-value="emits('update-report-content')"
            @focus-changed="onCommentTextboxFocused"
            @click-outside="onCommentTextboxClickOutside"
            @keydown.tab="
              emits('section-comment-field-action', {
                sectionId: props.sectionId,
                action: 'click-out',
              })
            "
          />

          <TranscriptionButton
            v-if="currentTenant.isDictationPermitted && currentUser.isDictationEnabled"
            ref="transcriptionButton"
            data-testid="transcription-button"
            @transcription-output="onTranscriptionOutput"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DraggableList from "@/components/DraggableList.vue";
import Popper from "@/components/Popper.vue";
import ReportFieldStructure from "@/reporting/ReportFieldStructure.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { SortableEvent } from "sortablejs";
import { computed, nextTick, onMounted, ref } from "vue";
import {
  REPORT_TEMPLATE_SECTION_COLUMNS_MAX_COUNT,
  ReportSectionStructuredField,
} from "../../../backend/src/reporting/report-structure";
import { isStudyUpdatePermitted } from "../auth/authorization";
import { currentTenant, currentUser } from "../auth/current-session";
import ResizingTextbox from "../components/ResizingTextbox.vue";
import TranscriptionButton from "../transcription/TranscriptionButton.vue";
import type { Study, StudyReport } from "../utils/study-data";
import AddReportStructureButton from "./AddReportStructureButton.vue";
import ReportCheckbox from "./ReportCheckbox.vue";
import ReportFieldContent from "./ReportFieldContent.vue";
import ReportSectionContent from "./ReportSectionContent.vue";
import ReportSectionHeader from "./ReportSectionHeader.vue";
import ReportSectionSentenceSelection from "./ReportSectionSentenceSelector.vue";
import ReportTableGridLines from "./ReportTableGridlines.vue";
import { CalculationScope } from "./report-calculation-scope";
import {
  ReportContentMode,
  getFieldAlignmentCSS,
  getFinalReportSectionText,
  isFieldEditable,
  type ReportSectionCommentFieldEventDetails,
} from "./report-content";
import {
  createFieldAddMutation,
  createFieldDuplicateMutation,
  createFieldMoveMutation,
  createSectionColumnAddMutation,
  createSectionColumnRemoveMutation,
  createSectionCommentFieldVisibleToggleMutation,
  createSectionFieldCompactionEnabledToggleMutation,
  createSectionFieldFormattingEnabledToggleMutation,
  createSectionSentenceDefaultTextMutation,
  type ReportStructureMutation,
} from "./report-structure-mutations";

interface Props {
  study: Study;
  report: StudyReport;
  calculationScope: CalculationScope;
  sectionId: string;
  mode: ReportContentMode;
  scaleFactor: number;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "done"): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
  (event: "section-comment-field-action", details: ReportSectionCommentFieldEventDetails): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);
const isViewingReportStructure = computed(
  () =>
    props.mode === ReportContentMode.ViewReportStructure ||
    props.mode === ReportContentMode.EditReportStructure
);
const isEditingReportStructure = computed(
  () => props.mode === ReportContentMode.EditReportStructure
);

const structure = computed(() => props.report.reportTemplateVersion.structure);

const sections = computed(() => structure.value.sections);

const section = computed(() => {
  const result = sections.value.find((s) => s.id === props.sectionId);

  if (result === undefined) {
    throw Error(`Section with id ${props.sectionId} is not present in the report template`);
  }

  return result;
});

const sectionContent = computed(() => {
  if (!(props.sectionId in props.report.content.sections)) {
    throw Error(`Section content for section ${props.sectionId} is missing`);
  }

  return props.report.content.sections[props.sectionId];
});

const hasSentenceFields = computed(
  () =>
    section.value.structuredFieldColumns
      .flat()
      .filter((field) => field.type === "dropdown" && field.isSentence).length > 0
);

const isFooterVisible = computed(
  () =>
    section.value.type !== "heading" &&
    (isEditingReportStructure.value ||
      (isEditingReportContent.value &&
        (hasSentenceFields.value || section.value.isCommentFieldVisible)))
);

const columns = computed(() => section.value.structuredFieldColumns);
const rowCount = computed(() => Math.max(...columns.value.map((column) => column.length)));

const firstEditableFieldPosition = computed(() => {
  for (let i = 0; i < columns.value.length; i++) {
    for (let j = 0; j < columns.value[i].length; j++) {
      if (isFieldEditable(columns.value[i][j])) {
        return { column: i, row: j };
      }
    }
  }

  return null;
});

const columnElements = ref<HTMLElement[]>([]);

function onReorderFields(event: SortableEvent): void {
  if (event.oldIndex === undefined || event.newIndex === undefined) {
    return;
  }

  // Handle dragging and dropping fields between columns
  const fromColumnIndex = columnElements.value.findIndex((el) => el.contains(event.from));
  const toColumnIndex = columnElements.value.findIndex((el) => el.contains(event.to));

  if (fromColumnIndex === -1 || toColumnIndex === -1) {
    return;
  }

  emits(
    "mutate-structure",
    createFieldMoveMutation(
      props.sectionId,
      fromColumnIndex,
      toColumnIndex,
      event.oldIndex,
      event.newIndex
    )
  );
}

function onAddField(columnIndex: number): void {
  emits(
    "mutate-structure",
    createFieldAddMutation(section.value.id, columnIndex, (newFieldId) =>
      setFocusToFieldId(newFieldId)
    )
  );
}

function onDuplicateField(fieldId: string): void {
  emits(
    "mutate-structure",
    createFieldDuplicateMutation(section.value.id, fieldId, (newFieldId) =>
      setFocusToFieldId(newFieldId)
    )
  );
}

// Creates a new empty field when enter is pressed while editing the last field of a column
function onFieldNameEnterPressed(columnIndex: number, fieldId: string): void {
  const fields = section.value.structuredFieldColumns[columnIndex];
  const fieldIndex = fields.findIndex((field) => field.id === fieldId);

  if (fieldIndex !== fields.length - 1) {
    return;
  }

  const isFieldNameEmpty = fields[fieldIndex].name.trim().length === 0;
  if (isFieldNameEmpty) {
    return;
  }

  emits(
    "mutate-structure",
    createFieldAddMutation(section.value.id, columnIndex, (newFieldId) =>
      setFocusToFieldId(newFieldId)
    )
  );
}

function setFocusToFieldId(id: string): void {
  void nextTick(() => {
    for (const columnElement of columnElements.value) {
      const inputEl = columnElement.querySelector<HTMLInputElement>(`[field-id="${id}"] input`);
      inputEl?.focus();
      inputEl?.select();
    }
  });
}

const commentTextbox = ref<{
  focus: () => void;
  insertText: (text: string, replacePartial?: string) => void;
} | null>(null);

onMounted(() => {
  // Focus comment textbox if there are no editable fields in the section
  if (firstEditableFieldPosition.value === null) {
    commentTextbox.value?.focus();
  }
});

let lastPartialTranscriptionText = "";

function onTranscriptionOutput(text: string, isPartial: boolean) {
  commentTextbox.value?.insertText(text, lastPartialTranscriptionText);
  lastPartialTranscriptionText = isPartial ? text : "";
}

function onCommentTextboxFocused(focused: boolean): void {
  if (commentTextbox.value === null || !focused) {
    return;
  }

  emits("section-comment-field-action", {
    sectionId: props.sectionId,
    action: "focus",
    focusTextarea: commentTextbox.value.focus,
    insertText: commentTextbox.value.insertText,
  });
}

function onCommentTextboxClickOutside(event: PointerEvent): void {
  if (!(event.target instanceof Node)) {
    return;
  }

  const isSentenceLibraryClick =
    document.querySelector("#report-sentence-library")?.contains(event.target) === true;

  const isTranscriptionButtonClick =
    document.querySelector("#transcription-button")?.contains(event.target) === true;

  // If the click outside was on the library content or the transcription button then don't emit it
  // because we want the library popover to stay open
  if (!isSentenceLibraryClick && !isTranscriptionButtonClick) {
    emits("section-comment-field-action", { sectionId: props.sectionId, action: "click-out" });
  }
}

const rootElement = ref<HTMLDivElement | null>(null);

function focusNextField(field: ReportSectionStructuredField): void {
  if (rootElement.value === null || document.activeElement === null) {
    return;
  }

  if (field.type === "dropdown" && field.isMultiSelect) {
    return;
  }

  const focusableElements = Array.from(
    rootElement.value.querySelectorAll('[tabindex]:not([tabindex="-1"])')
  );

  const nextFocusableElement =
    focusableElements[focusableElements.indexOf(document.activeElement) + 1];
  if (nextFocusableElement instanceof HTMLElement) {
    nextFocusableElement.focus();
  }
}
</script>

<style scoped lang="scss">
.structured-report-section {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  background-color: var(--report-section-bg-color);
  padding: 0.6em;
  border-radius: 0.2em;
  margin: var(--report-spacing-section) 0;

  &.heading {
    margin-top: calc(var(--report-spacing-heading) + var(--report-spacing-section));
    height: 0.6em;
  }
}

.columns {
  display: flex;
  align-items: stretch;
  gap: 0.4em;
  position: relative;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--report-spacing-field);

  &.ensure-drop-area-exists {
    min-height: 2.2em;
  }
}

.generated-text {
  &.placeholder {
    font-style: italic;
    color: var(--report-accent-color-1);
  }
}

.footer {
  display: flex;
  gap: 0.8em;

  .footer-arrow {
    color: var(--report-placeholder-text-color-focused);
    margin-left: 0.4em;
    margin-top: 0.4em;
  }

  .footer-content {
    margin-top: 0.4em;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.4em;
  }
}

.add-remove-column-button {
  place-self: center;
  cursor: pointer;
  text-align: center;
  border: 0.1em solid var(--report-box-border-color);
  border-radius: 0.2em;
  padding: 0.2em 0.4em;
  background-color: var(--report-widget-bg-color);
  transition:
    opacity 100ms ease,
    background-color 100ms ease;
  color: var(--report-text-color-1);

  &:hover {
    background-color: var(--report-widget-bg-color-hover);
    color: var(--report-text-color-2);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.sentences-button {
  display: flex;
  gap: 0.6em;
  align-items: center;
  line-height: 1em;
  padding: 0.8em;
  background-color: var(--report-widget-bg-color);
  border-radius: var(--border-radius);
  color: var(--report-text-color-1);
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;

  &:hover {
    color: var(--report-text-color-2);
    background-color: var(--report-widget-bg-color-hover);
  }
}

:deep(.sentences-popper) {
  background-color: var(--report-widget-bg-color);
  border-width: 0;
  padding: 0.8em;
}
</style>
