import axios from "axios";
import { ref, type Ref } from "vue";
import type { DicomEndpointGetManyResponseDto } from "../../../backend/src/dicom/dto/dicom-endpoint-get-many.dto";
import {
  hasDicomEndpointQueryPermission,
  hasDicomEndpointSendPermission,
} from "../auth/authorization";
import { addNotification } from "./notifications";

const dicomEndpoints = ref<DicomEndpointGetManyResponseDto>([]);

export function useDicomEndpoints(): Ref<DicomEndpointGetManyResponseDto> {
  if (hasDicomEndpointQueryPermission.value || hasDicomEndpointSendPermission.value) {
    void loadDicomEndpoints();
  }

  return dicomEndpoints;
}

let isLoading = false;

async function loadDicomEndpoints(): Promise<void> {
  if (isLoading) {
    return;
  }

  isLoading = true;

  try {
    dicomEndpoints.value = (
      await axios.get<DicomEndpointGetManyResponseDto>(`/api/dicom-endpoints`)
    ).data;
  } catch {
    addNotification({ type: "error", message: "Failed loading list of DICOM endpoints" });
  } finally {
    isLoading = false;
  }
}
