<template>
  <div class="rpt-sections">
    <template v-for="(section, sectionIndex) in reportStructure.sections" :key="section.id">
      <template
        v-if="
          !isEditingReportStructure && sectionIndex === indicationAndMedicalHistoryInsertionIndex
        "
      >
        <div
          v-if="
            reportStructure.layout.isIndicationFieldVisible &&
            (isEditingReportContent || study.indication)
          "
          class="rpt-section-content editable"
        >
          <div v-if="!isGeneratingHtml" class="highlight-background" />
          <strong class="rpt-text-normal">Indication</strong>

          <div
            data-testid="indication-textfield"
            :class="{ 'rpt-fancy-input': isEditingReportContent }"
          >
            <!-- eslint-disable vue/no-mutating-props -->
            <ResizingTextbox
              v-if="isEditingReportContent"
              v-model="study.indication"
              placeholder="Enter the indication here"
              class="rpt-text-normal"
              :scale-factor="scaleFactor"
              @update:model-value="emits('update-patient-info')"
            />
            <div v-else class="rpt-text-normal">
              <TextWithLineBreaks :text="study.indication" :italics="isViewingReportStructure" />
            </div>
          </div>
        </div>

        <div
          v-if="
            reportStructure.layout.isMedicalHistoryFieldVisible &&
            (isEditingReportContent || study.patientMedicalHistory)
          "
          class="rpt-section-content editable"
        >
          <div v-if="!isGeneratingHtml" class="highlight-background" />
          <strong class="rpt-text-normal">Medical History</strong>

          <div :class="{ 'rpt-fancy-input': isEditingReportContent }">
            <!-- eslint-disable vue/no-mutating-props -->
            <ResizingTextbox
              v-if="isEditingReportContent"
              v-model="study.patientMedicalHistory"
              placeholder="Enter the patient's medical history here"
              class="rpt-text-normal"
              :scale-factor="scaleFactor"
              allow-newlines
              @update:model-value="emits('update-patient-info')"
            />
            <div v-else class="rpt-text-normal">
              <TextWithLineBreaks
                :text="study.patientMedicalHistory"
                :italics="isViewingReportStructure"
              />
            </div>
          </div>
        </div>
      </template>

      <AddReportStructureButton
        v-if="isEditingReportStructure"
        text=""
        :options="newSectionOptions"
        @click="(key) => onAddSection(sectionIndex, key)"
      />

      <div
        v-if="!isEditingReportStructure && isSectionHorizontalDivider(section)"
        class="rpt-horizontal-divider"
      />
      <div
        v-else-if="
          section.type === 'heading' &&
          !isViewingReportStructure &&
          (!isGeneratingHtml ||
            isSectionHeadingVisibleOnFinalReport(study, report, calculationScope, section.id))
        "
        class="rpt-section-heading"
      >
        <span class="name-text">{{ section.name }}</span>
      </div>

      <template v-else>
        <ReportSection
          v-if="
            (sectionsBeingEdited.includes(section.id) || isViewingReportStructure) &&
            section.type !== 'rwma'
          "
          :study="study"
          :report="report"
          :calculation-scope="calculationScope"
          :section-id="section.id"
          :mode="mode"
          :scale-factor="scaleFactor"
          @update-report-content="emits('update-report-content')"
          @keydown.escape.stop="closeAndFocusSection(section.id)"
          @done="closeAndFocusSection(section.id)"
          @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
          @section-comment-field-action="
            (details) => emits('section-comment-field-action', details)
          "
        />

        <RWMASection
          v-else-if="section.type === 'rwma'"
          :section-id="section.id"
          :study="study"
          :report="report"
          :mode="mode"
          :scale-factor="scaleFactor"
          @update-report-content="emits('update-report-content')"
          @mutate-structure="(mutation) => emits('mutate-structure', mutation)"
        />

        <div
          v-else-if="
            !isGeneratingHtml || isSectionReported(study, report, calculationScope, section.id)
          "
          :id="`rpt-section-${section.id}`"
          class="rpt-section-content"
          :class="{
            editable: isSectionEditable(report.reportTemplateVersion.structure, section.id),
            'no-name': section.name === '',
          }"
          :data-testid="`rpt-section-${section.name}`"
          tabindex="0"
          @click="sectionsBeingEdited.push(section.id)"
          @keypress.enter.prevent="openSectionAndClosePrevious(section.id)"
        >
          <div v-if="!isGeneratingHtml" class="highlight-background" />
          <span v-if="section.name" class="rpt-comment-heading rpt-text-normal">
            {{ section.name }}
          </span>

          <ReportSectionContent
            :study="study"
            :report="report"
            :calculation-scope="calculationScope"
            :section-id="section.id"
            show-fields
            :data-testid="`rpt-section-content-${section.name}`"
          />

          <span
            v-if="!isSectionReported(study, report, calculationScope, section.id)"
            class="section-not-reported"
          >
            Not reported
          </span>
        </div>
      </template>
    </template>
  </div>

  <AddReportStructureButton
    v-if="isEditingReportStructure"
    :options="newSectionOptions"
    @click="(key) => onAddSection(reportStructure.sections.length, key)"
  />
</template>

<script setup lang="ts">
import ResizingTextbox from "@/components/ResizingTextbox.vue";
import { computed, nextTick, ref } from "vue";
import {
  ReportSectionTypeSchema,
  isSectionHorizontalDivider,
} from "../../../backend/src/reporting/report-structure";
import { Study, StudyReport } from "../utils/study-data";
import AddReportStructureButton from "./AddReportStructureButton.vue";
import ReportSection from "./ReportSection.vue";
import ReportSectionContent from "./ReportSectionContent.vue";
import TextWithLineBreaks from "./TextWithLineBreaks.vue";
import { getCalculationScope } from "./report-calculation-scope";
import {
  ReportContentMode,
  isSectionEditable,
  isSectionHeadingVisibleOnFinalReport,
  isSectionReported,
  type ReportSectionCommentFieldEventDetails,
} from "./report-content";
import {
  createSectionAddMutation,
  type ReportStructureMutation,
} from "./report-structure-mutations";
import RWMASection from "./rwma/RWMASection.vue";

interface Props {
  study: Study;
  report: StudyReport;
  mode: ReportContentMode;
  scaleFactor: number;
}

interface Emits {
  (event: "update-report-content"): void;
  (event: "update-patient-info"): void;
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
  (event: "section-comment-field-action", details: ReportSectionCommentFieldEventDetails): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

// Returns the index in the list of sections to insert the indication and medical history UI. This
// UI is inserted above the first editable section on the report, or below the first horizontal
// divider, whichever comes first. This system is necessary because these two pieces of UI are
// currently not configured as part of the report structure, but in future it may be a good idea to
// turn them each into a specific type of field that can be put into a section as this would give
// more configuration of positioning and layout.
const indicationAndMedicalHistoryInsertionIndex = computed(() => {
  for (let i = 0; i < reportStructure.value.sections.length; i++) {
    const section = reportStructure.value.sections[i];

    if (isSectionHorizontalDivider(section)) {
      continue;
    }

    if (isSectionEditable(reportStructure.value, section.id)) {
      return i;
    }
  }

  return 0;
});

const isGeneratingHtml = computed(() => props.mode === ReportContentMode.GenerateHTML);
const isEditingReportContent = computed(() => props.mode === ReportContentMode.EditReportContent);
const isViewingReportStructure = computed(
  () =>
    props.mode === ReportContentMode.ViewReportStructure ||
    props.mode === ReportContentMode.EditReportStructure
);
const isEditingReportStructure = computed(
  () => props.mode === ReportContentMode.EditReportStructure
);

const reportStructure = computed(() => props.report.reportTemplateVersion.structure);

const newSectionOptions = [
  { key: "heading", text: "Add Heading" },
  { key: "fields", text: "Add Fields" },
  { key: "table", text: "Add Table" },
  { key: "rwma", text: "Add RWMA Diagram" },
];

const sectionsBeingEdited = ref<string[]>([]);

function onAddSection(sectionIndex: number, type: string): void {
  const parsedType = ReportSectionTypeSchema.parse(type);

  emits("mutate-structure", createSectionAddMutation(sectionIndex, parsedType));
}

const calculationScope = computed(() => getCalculationScope(props.study, props.report));

function getSectionMargins(): string {
  return `${reportStructure.value.spacing.section}em 0`;
}

function openSectionAndClosePrevious(sectionId: string): void {
  const sections = reportStructure.value.sections;

  // Collapse the previous section if it's currently expanded
  const indexOfPreviousSection = sections.findIndex(
    (_section, index) => sections[index + 1].id === sectionId
  );
  if (indexOfPreviousSection !== -1) {
    const previousSectionId = sections[indexOfPreviousSection].id;
    const indexToRemove = sectionsBeingEdited.value.indexOf(previousSectionId);
    if (indexToRemove !== -1) {
      sectionsBeingEdited.value.splice(indexToRemove, 1);
    }
  }

  sectionsBeingEdited.value.push(sectionId);
}

async function closeAndFocusSection(sectionId: string): Promise<void> {
  sectionsBeingEdited.value.splice(sectionsBeingEdited.value.indexOf(sectionId), 1);

  // Focus the collapsed section once it has been rendered
  await nextTick();
  document.getElementById(`rpt-section-${sectionId}`)?.focus();
}
</script>

<style scoped lang="scss">
.rpt-section-content {
  pointer-events: none;
  margin: v-bind("getSectionMargins()");
  position: relative;

  &.editable {
    pointer-events: auto;
    cursor: pointer;
    transition: background-color 100ms ease;
  }

  > * {
    position: relative;
  }

  .highlight-background {
    position: absolute;
    pointer-events: none;
    border-radius: 0.2em;
  }

  &:hover,
  &:focus {
    .highlight-background {
      top: -0.4em;
      right: -0.4em;
      bottom: -0.4em;
      left: -0.4em;
    }
  }

  &:hover .highlight-background {
    background-color: var(--report-section-bg-color);
  }

  &:focus {
    outline: none;

    .highlight-background {
      border: 0.1em solid var(--report-widget-outline-color-focused);
    }
  }
}

.section-not-reported {
  color: #888;
  font-style: italic;
  font-size: 1.2em;
}

.rpt-sections {
  position: relative;
}
</style>
