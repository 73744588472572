/** Returns a Base 16 encoded string of the SHA256 hash of the provided data. */
export async function getSHA256Hash(data: string): Promise<string> {
  // Get hash as ArrayBuffer
  const hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(data));

  // Convert to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}
