<template>
  <div
    v-for="(_, beatIndex) in clipModel.heartbeats"
    :key="beatIndex"
    class="heartbeat-extents"
    :data-testid="`heartbeat-${beatIndex}`"
    :class="{
      colored: clipModel.getSelectedHeartbeatIndex() !== undefined,
      selected: clipModel.getSelectedHeartbeatIndex() === beatIndex,
    }"
    :style="getHeartbeatExtentsStyles(beatIndex)"
    @mousedown.stop
  >
    <Tooltip
      :content="
        clipModel.getSelectedHeartbeatIndex() === beatIndex
          ? `Deselect heartbeat`
          : `Select heartbeat`
      "
      :offset-distance="24"
    >
      <div
        class="heartbeat-button"
        :class="{ selected: clipModel.getSelectedHeartbeatIndex() === beatIndex }"
        :data-testid="`heartbeat-select-btn-${beatIndex}`"
        @click="
          clipModel.setSelectedHeartbeatIndex(
            clipModel.getSelectedHeartbeatIndex() === beatIndex ? undefined : beatIndex
          )
        "
        @dblclick.stop
      >
        {{ beatIndex + 1 }}
      </div>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import Tooltip from "../../components/Tooltip.vue";
import { ClipModel } from "../clip-model";

interface Props {
  clipModel: ClipModel;
}

const props = defineProps<Props>();

function getHeartbeatExtentsStyles(beatIndex: number): { left: string; width: string } {
  const clip = props.clipModel.clip;
  if (clip === undefined || clip.frameCount === null) {
    return { left: "", width: "" };
  }

  const heartbeat = props.clipModel.heartbeats[beatIndex];

  const left = heartbeat.firstFrame / clip.frameCount;
  const width = (heartbeat.lastFrame - heartbeat.firstFrame + 1) / clip.frameCount;

  return { left: `${left * 100}%`, width: `${width * 100}%` };
}
</script>

<style scoped lang="scss">
.heartbeat-extents {
  position: absolute;
  top: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  border-radius: var(--border-radius);
  pointer-events: none;

  &.colored {
    background-color: #445;

    &.selected {
      background-color: #557;
    }
  }
}

.heartbeat-button {
  font-weight: bold;
  padding: 2px 6px 1px;
  color: var(--accent-color-1);
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  transform: translateY(-20px);
  pointer-events: auto;
  transition:
    color 100ms ease,
    border-color 100ms ease,
    background-color 100ms ease;

  &.selected,
  &:hover {
    color: var(--accent-color-2);
    border-color: var(--accent-color-2);
  }

  &:hover {
    background-color: var(--bg-color-3);
  }

  &.selected {
    background-color: #557;
  }
}
</style>
