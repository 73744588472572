import { computed, nextTick, ref, watch } from "vue";
import type { EFAssociatedMeasurementName } from "../../../../backend/src/measurements/associated-measurements";
import { efAssociatedMeasurements } from "../../../../backend/src/measurements/associated-measurements";
import { getDrawableUniplaneEjectionFractionMeasurements } from "../../../../backend/src/measurements/drawable-measurements";
import { getMeasurementDecimalPlaces } from "../../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../../backend/src/measurements/measurement-names";
import { calculateEjectionFractionFromVolumes } from "../../../../backend/src/measurements/measurement-tool-evaluation";
import { MeasurementToolName } from "../../../../backend/src/measurements/measurement-tool-names";
import {
  MeasurementUnit,
  getUnitDisplayText,
} from "../../../../backend/src/measurements/measurement-units";
import { RegionUnit } from "../../../../backend/src/studies/study-clip-region";
import type { Study, StudyClip, StudyClipRegion } from "../../utils/study-data";
import { findSavedAssociatedMeasurements } from "../measurement-helpers";
import {
  buildMeasurementToolBatchChangeRequest,
  generateAssociatedMeasurementRefs,
  setAssociatedMeasurementDetailsOnEdit,
  setDefaultEnabledForAssociatedMeasurements,
} from "../measurement-tool-helpers";
import type {
  AssociatedMeasurement,
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ScrubberIndicator,
  ToolbarItem,
} from "./measurement-tool";
import { createVolumeMeasurementTool } from "./measurement-tool-volume";

enum EFMeasurementState {
  MeasuringEndDiastole = 0,
  MeasuringEndSystole = 1,
  Reviewing = 2,
}

/** Creates a new EF measurement tool instance for the given study and region. */
// eslint-disable-next-line max-statements
export function createEjectionFractionTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const unit = MeasurementUnit.Percentage;
  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customMeasurementName = MeasurementName.CustomEjectionFraction;
  const customName = ref("");

  const frame = ref(0);

  const state = ref<EFMeasurementState>(EFMeasurementState.MeasuringEndDiastole);

  const edvMainChangeRequest = computed(
    () =>
      edvMeasurement.getMeasurementChangeRequests().creates?.[0] ??
      edvMeasurement.getMeasurementChangeRequests().updates?.[0]
  );

  const esvMainChangeRequest = computed(
    () =>
      esvMeasurement.getMeasurementChangeRequests().creates?.[0] ??
      esvMeasurement.getMeasurementChangeRequests().updates?.[0]
  );

  const ejectionFraction = computed(() => {
    const edv = edvMainChangeRequest.value?.value ?? 0;
    const esv = esvMainChangeRequest.value?.value ?? 0;

    return calculateEjectionFractionFromVolumes(edv, esv);
  });

  const edvMeasurement = createVolumeMeasurementTool(study, studyClipId, region);
  const esvMeasurement = createVolumeMeasurementTool(study, studyClipId, region);

  const edvSelected = computed(
    () => state.value === EFMeasurementState.Reviewing && frame.value === edvFrame.value
  );
  const esvSelected = computed(
    () => state.value === EFMeasurementState.Reviewing && frame.value === esvFrame.value
  );

  const toolbarItems = computed((): ToolbarItem[] => {
    if (state.value === EFMeasurementState.MeasuringEndDiastole) {
      return edvMeasurement.toolbarItems.value;
    } else if (state.value === EFMeasurementState.MeasuringEndSystole) {
      return esvMeasurement.toolbarItems.value;
    }

    return [
      {
        ...edvMeasurement.toolbarItems.value[0],
        label: "EDV",
        highlighted: edvSelected.value,
        onClick: (): void => {
          onFrameChange(edvFrame.value);
          runFrameChangeHandlers(edvFrame.value);
        },
      },
      {
        ...esvMeasurement.toolbarItems.value[0],
        label: "ESV",
        highlighted: esvSelected.value,
        onClick: (): void => {
          onFrameChange(esvFrame.value);
          runFrameChangeHandlers(esvFrame.value);
        },
      },
      {
        label: "EF",
        text: `${ejectionFraction.value.toFixed(
          getMeasurementDecimalPlaces(MeasurementName.CustomEjectionFraction, unit)
        )} ${getUnitDisplayText(unit)}`,
        invalid: ejectionFraction.value < 0 || ejectionFraction.value > 100,
      },
    ];
  });

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      edvMeasurement.drawToCanvas(canvas, ctx);
    } else if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      esvMeasurement.drawToCanvas(canvas, ctx);
    }
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      edvMeasurement.onCanvasMouseDown(pt);
    } else if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      esvMeasurement.onCanvasMouseDown(pt);
    }
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      return edvMeasurement.onCanvasMouseMove(pt);
    } else if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      return esvMeasurement.onCanvasMouseMove(pt);
    }
    return false;
  }

  function onCanvasMouseUp(pt: number[]): void {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      edvMeasurement.onCanvasMouseUp(pt);
    } else if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      esvMeasurement.onCanvasMouseUp(pt);
    }
  }

  const associatedEdvRefs = generateAssociatedMeasurementRefs();
  const associatedEsvRefs = generateAssociatedMeasurementRefs();

  const associatedMeasurements = computed(() => {
    if (
      measurementName.value === undefined ||
      measurementName.value === customMeasurementName ||
      edvMainChangeRequest.value === undefined ||
      esvMainChangeRequest.value === undefined
    ) {
      return {};
    }

    const result: Partial<Record<EFAssociatedMeasurementName, AssociatedMeasurement>> = {
      endDiastolicVolume: {
        ...edvMainChangeRequest.value,
        ...associatedEdvRefs,
        tool: MeasurementToolName.EjectionFraction,
      },
      endSystolicVolume: {
        ...esvMainChangeRequest.value,
        ...associatedEsvRefs,
        tool: MeasurementToolName.EjectionFraction,
      },
    };

    const associatedNames = efAssociatedMeasurements[measurementName.value];
    if (savedMeasurementBeingEdited.value === undefined && associatedNames !== undefined) {
      setDefaultEnabledForAssociatedMeasurements(result, associatedNames);
    }

    return result;
  });

  function toggleAssociatedMeasurement(name: string): void {
    const associatedMeasurement = associatedMeasurements.value[name as EFAssociatedMeasurementName];

    if (associatedMeasurement !== undefined) {
      associatedMeasurement.enabled.value = !associatedMeasurement.enabled.value;

      runRequestRedrawHandlers();
    }
  }

  const isBackButtonVisible = computed(
    () => state.value !== EFMeasurementState.MeasuringEndDiastole
  );

  const isSaveButtonVisible = computed(() => state.value === EFMeasurementState.Reviewing);

  const isSaveButtonEnabled = computed(
    () => esvMeasurement.isSaveButtonEnabled.value && edvMeasurement.isSaveButtonEnabled.value
  );

  const isNextButtonEnabled = computed(() => {
    if (state.value === EFMeasurementState.MeasuringEndSystole) {
      return esvMeasurement.isSaveButtonEnabled.value && esvFrame.value !== edvFrame.value;
    } else if (state.value === EFMeasurementState.MeasuringEndDiastole) {
      return edvMeasurement.isSaveButtonEnabled.value;
    }

    return true;
  });

  const nextButtonTooltip = computed(() => {
    if (state.value === EFMeasurementState.MeasuringEndDiastole) {
      return "Measure ESV";
    } else if (state.value === EFMeasurementState.MeasuringEndSystole) {
      return "Review measurements";
    }

    return "";
  });

  function onBackButtonClick(): void {
    if (state.value === EFMeasurementState.MeasuringEndSystole) {
      state.value = EFMeasurementState.MeasuringEndDiastole;
      runFrameChangeHandlers(edvFrame.value);
    } else if (state.value === EFMeasurementState.Reviewing) {
      state.value = EFMeasurementState.MeasuringEndSystole;
      runFrameChangeHandlers(esvFrame.value);
    }
  }

  function onNextButtonClick(): void {
    if (
      state.value === EFMeasurementState.MeasuringEndDiastole &&
      edvMeasurement.isSaveButtonEnabled.value
    ) {
      state.value = EFMeasurementState.MeasuringEndSystole;
      edvFrame.value = frame.value;

      if (esvFrame.value === -1) {
        esvFrame.value = edvFrame.value + 1;
        esvMeasurement.onFrameChange(esvFrame.value);
      }

      runFrameChangeHandlers(esvFrame.value);
    } else if (
      state.value === EFMeasurementState.MeasuringEndSystole &&
      esvMeasurement.isSaveButtonEnabled.value
    ) {
      state.value = EFMeasurementState.Reviewing;
    }
  }

  const interactivePoints = computed(() => {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      return edvMeasurement.interactivePoints.value;
    } else if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      return esvMeasurement.interactivePoints.value;
    }

    return [];
  });

  const helpText = computed(() => {
    if (state.value === EFMeasurementState.MeasuringEndDiastole) {
      return "Measuring: EDV";
    }

    if (state.value === EFMeasurementState.MeasuringEndSystole) {
      return "Measuring: ESV";
    }

    return "Reviewing";
  });

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    if (measurementName.value === undefined) {
      return {};
    }

    const mainMeasurementDetails = {
      tool: MeasurementToolName.EjectionFraction,
      name: measurementName.value,
      customName: customName.value,
      value: ejectionFraction.value,
      unit: MeasurementUnit.Percentage,
      studyClipId,
    };

    return buildMeasurementToolBatchChangeRequest(
      mainMeasurementDetails,
      savedMeasurementBeingEdited.value,
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const editingMeasurementBatchId = ref<string | null>(null);
  const previouslySavedAssociatedMeasurements = ref<
    (MeasurementToolRecreationDetails & { measurementId: string })[]
  >([]);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";

    previouslySavedAssociatedMeasurements.value = findSavedAssociatedMeasurements(
      study,
      value,
      efAssociatedMeasurements
    );

    const associatedMeasurementsForThisName = efAssociatedMeasurements[measurementName.value];

    for (const measurement of previouslySavedAssociatedMeasurements.value) {
      if (measurement.measurementName === associatedMeasurementsForThisName?.endDiastolicVolume) {
        edvMeasurement.loadSavedMeasurement(measurement);
        edvFrame.value = measurement.frame ?? 0;
      } else if (
        measurement.measurementName === associatedMeasurementsForThisName?.endSystolicVolume
      ) {
        esvMeasurement.loadSavedMeasurement(measurement);
        esvFrame.value = measurement.frame ?? 0;
      }
    }

    state.value = EFMeasurementState.Reviewing;

    setAssociatedMeasurementDetailsOnEdit(
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );

    // Jump to the frame that the ESV was taken on
    onFrameChange(esvFrame.value);
    void nextTick(() => runFrameChangeHandlers(esvFrame.value));
  }

  const scrubberIndicators = computed((): ScrubberIndicator[] => {
    const indicators: ScrubberIndicator[] = [];

    if (edvFrame.value !== -1) {
      indicators.push({ frame: edvFrame.value, label: "ED", color: "#f49a76" });
    }

    if (esvFrame.value !== -1) {
      indicators.push({ frame: esvFrame.value, label: "ES", color: "#f4d876" });
    }

    return indicators;
  });

  const edvFrame = ref(-1);
  const esvFrame = ref(-1);

  function onFrameChange(frameNumber: number): void {
    frame.value = frameNumber;

    if (state.value === EFMeasurementState.MeasuringEndDiastole) {
      edvMeasurement.onFrameChange(frameNumber);
      edvFrame.value = frameNumber;
    } else if (state.value === EFMeasurementState.MeasuringEndSystole) {
      esvMeasurement.onFrameChange(frameNumber);
      esvFrame.value = frameNumber;
    }
  }

  const requestRedrawHandlers = new Set<() => void>();
  const frameChangeHandlers = new Set<(frameNumber: number) => void>();

  function runRequestRedrawHandlers(): void {
    requestRedrawHandlers.forEach((fn) => fn());
  }

  function runFrameChangeHandlers(frameNumber: number): void {
    frameChangeHandlers.forEach((fn) => fn(frameNumber));
  }

  esvMeasurement.requestRedrawHandlers.add(runRequestRedrawHandlers);
  edvMeasurement.requestRedrawHandlers.add(runRequestRedrawHandlers);

  watch([state, esvSelected, edvSelected], runRequestRedrawHandlers);

  watch(
    measurementName,
    () => {
      const efMeasurements =
        efAssociatedMeasurements[measurementName.value ?? MeasurementName.CustomEjectionFraction];

      edvMeasurement.measurementName.value = efMeasurements?.endDiastolicVolume;
      esvMeasurement.measurementName.value = efMeasurements?.endSystolicVolume;
    },
    { immediate: true }
  );

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (state.value === EFMeasurementState.MeasuringEndDiastole || edvSelected.value) {
      return edvMeasurement.getMeasurementLabels(canvas);
    }

    if (state.value === EFMeasurementState.MeasuringEndSystole || esvSelected.value) {
      return esvMeasurement.getMeasurementLabels(canvas);
    }

    return [];
  }

  function isChangeAllowedOf(target: "clip" | "frame"): boolean {
    if (target === "frame") {
      return (
        (state.value === EFMeasurementState.MeasuringEndDiastole &&
          edvMeasurement.isChangeAllowedOf("frame")) ||
        (state.value === EFMeasurementState.MeasuringEndSystole &&
          esvMeasurement.isChangeAllowedOf("frame"))
      );
    }

    return edvMeasurement.isChangeAllowedOf("clip");
  }

  return {
    displayName: "Ejection Fraction",
    studyClipId,
    region,
    measurementName,
    customName,
    customMeasurementName,

    isSaveButtonVisible,
    isSaveButtonEnabled,
    isNextButtonEnabled,
    nextButtonTooltip,
    isBackButtonVisible,
    helpText,
    toolbarItems,
    onNextButtonClick,
    onBackButtonClick,

    scrubberIndicators,
    getMeasurementLabels,
    interactivePoints,

    requestRedrawHandlers,
    frameChangeHandlers,
    shouldPassMousedownAfterRegionSelection: true,
    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange,
    isChangeAllowedOf,

    associatedMeasurements,
    toggleAssociatedMeasurement,
    getCreatableMeasurementNames: getDrawableUniplaneEjectionFractionMeasurements,
    getMeasurementChangeRequests,
    isMeasurableOnStudyClip: isEFMeasurableOnStudyClip,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

export function isEFMeasurableOnStudyClip(clip: StudyClip, region?: StudyClipRegion): boolean {
  const regions = region ? [region] : clip.regions;

  return (
    clip.modality === "US" &&
    regions.some(
      (r) =>
        r.xDirectionUnit === RegionUnit.Centimeters && r.yDirectionUnit === RegionUnit.Centimeters
    ) &&
    (clip.frameCount ?? 0) > 1
  );
}
