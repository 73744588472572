import type { Optional } from "utility-types";
import { MeasurementName } from "./measurement-names";
import { MeasurementToolName } from "./measurement-tool-names";
import type { MeasurementUnit } from "./measurement-units";

/**
 * This type represents the calculation result of a measurement's associated measurements, which
 * contains the value, contour, and unit that the calculator functions evaluated for the measurement
 */
export type AssociatedMeasurementValues<T extends string> = Partial<
  Record<T, { value: number; name: MeasurementName; unit: MeasurementUnit; contour?: number[] }>
>;

export type DopplerSlopeAssociatedMeasurementName =
  | "peakVelocity"
  | "time"
  | "pressureHalfTime"
  | "areaByPressureHalfTime";

export const dopplerSlopeAssociatedMeasurements: Partial<
  Record<
    MeasurementName,
    Optional<
      Record<DopplerSlopeAssociatedMeasurementName, MeasurementName>,
      "pressureHalfTime" | "areaByPressureHalfTime"
    >
  >
> = {
  [MeasurementName.AorticRegurgitationDecelerationSlope]: {
    peakVelocity: MeasurementName.AorticRegurgitationPeakVelocity,
    time: MeasurementName.AorticRegurgitationDecelerationTime,
    pressureHalfTime: MeasurementName.AorticRegurgitationPressureHalfTime,
  },

  [MeasurementName.PulmonaryValveAccelerationSlope]: {
    peakVelocity: MeasurementName.PulmonaryValvePeakVelocity,
    time: MeasurementName.PulmonaryValveAccelerationTime,
  },

  [MeasurementName.AorticValveAccelerationSlope]: {
    peakVelocity: MeasurementName.AorticValvePeakVelocity,
    time: MeasurementName.AorticValveAccelerationTime,
  },

  [MeasurementName.MitralValveDecelerationSlope]: {
    peakVelocity: MeasurementName.MitralValvePeakVelocity,
    time: MeasurementName.MitralValveDecelerationTime,
    pressureHalfTime: MeasurementName.MitralValvePressureHalfTime,
    areaByPressureHalfTime: MeasurementName.MitralValveAreaByPressureHalfTime,
  },
};

export type EFAssociatedMeasurementName = "endDiastolicVolume" | "endSystolicVolume";

export const efAssociatedMeasurements: Partial<
  Record<MeasurementName, Record<EFAssociatedMeasurementName, MeasurementName>>
> = {
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA2C]: {
    endDiastolicVolume: MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C,
    endSystolicVolume: MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C,
  },

  [MeasurementName.LeftVentricleEjectionFractionUniplaneA4C]: {
    endDiastolicVolume: MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C,
    endSystolicVolume: MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C,
  },

  [MeasurementName.RightVentricleEjectionFractionUniplaneA4C]: {
    endDiastolicVolume: MeasurementName.RightVentricleEndDiastolicVolumeUniplaneA4C,
    endSystolicVolume: MeasurementName.RightVentricleEndSystolicVolumeUniplaneA4C,
  },

  [MeasurementName.CustomEjectionFraction]: {
    endDiastolicVolume: MeasurementName.CustomVolume,
    endSystolicVolume: MeasurementName.CustomVolume,
  },
};

export type VolumeAssociatedMeasurementName = "area" | "majorAxisLength";

export const volumeAssociatedMeasurements: Partial<
  Record<MeasurementName, Record<VolumeAssociatedMeasurementName, MeasurementName>>
> = {
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA2C]: {
    area: MeasurementName.LeftAtriumAreaDiastolicA2C,
    majorAxisLength: MeasurementName.LeftAtriumDiastolicMajorAxisA2C,
  },

  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA4C]: {
    area: MeasurementName.LeftAtriumAreaDiastolicA4C,
    majorAxisLength: MeasurementName.LeftAtriumDiastolicMajorAxisA4C,
  },

  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA2C]: {
    area: MeasurementName.LeftAtriumAreaSystolicA2C,
    majorAxisLength: MeasurementName.LeftAtriumSystolicMajorAxisA2C,
  },

  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA4C]: {
    area: MeasurementName.LeftAtriumAreaSystolicA4C,
    majorAxisLength: MeasurementName.LeftAtriumSystolicMajorAxisA4C,
  },

  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C]: {
    area: MeasurementName.LeftVentricleDiastolicAreaA2C,
    majorAxisLength: MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA2C,
  },

  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C]: {
    area: MeasurementName.LeftVentricleSystolicAreaA2C,
    majorAxisLength: MeasurementName.LeftVentricleMajorAxisSystolicDimensionA2C,
  },

  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C]: {
    area: MeasurementName.LeftVentricleDiastolicAreaA4C,
    majorAxisLength: MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA4C,
  },

  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C]: {
    area: MeasurementName.LeftVentricleSystolicAreaA4C,
    majorAxisLength: MeasurementName.LeftVentricleMajorAxisSystolicDimensionA4C,
  },

  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA4C]: {
    area: MeasurementName.RightAtriumSystolicArea,
    majorAxisLength: MeasurementName.RightAtriumSystolicMajorAxisA4C,
  },
};

export type VelocityAssociatedMeasurementName = "gradient";

export const velocityAssociatedMeasurements: Partial<
  Record<MeasurementName, { gradient: MeasurementName }>
> = {
  [MeasurementName.AorticRegurgitationPeakVelocity]: {
    gradient: MeasurementName.AorticRegurgitationPeakGradient,
  },
  [MeasurementName.AorticValvePeakVelocity]: {
    gradient: MeasurementName.AorticValvePeakGradient,
  },
  [MeasurementName.LeftVentricleOutflowTractPeakVelocity]: {
    gradient: MeasurementName.LeftVentricleOutflowTractPeakGradient,
  },
  [MeasurementName.MitralRegurgitationPeakVelocity]: {
    gradient: MeasurementName.MitralRegurgitationPeakGradient,
  },
  [MeasurementName.MitralValvePeakVelocity]: {
    gradient: MeasurementName.MitralValvePeakGradient,
  },
  [MeasurementName.PulmonaryRegurgitationPeakVelocity]: {
    gradient: MeasurementName.PulmonaryRegurgitationPeakGradient,
  },
  [MeasurementName.PulmonaryValvePeakVelocity]: {
    gradient: MeasurementName.PulmonaryValvePeakGradient,
  },
  [MeasurementName.RightVentricleOutflowTractPeakVelocity]: {
    gradient: MeasurementName.RightVentricleOutflowTractPeakGradient,
  },
  [MeasurementName.TricuspidRegurgitationPeakVelocity]: {
    gradient: MeasurementName.TricuspidRegurgitationPeakGradient,
  },
  [MeasurementName.TricuspidValvePeakVelocity]: {
    gradient: MeasurementName.TricuspidValvePeakGradient,
  },
};

export type VTIAssociatedMeasurementName =
  | "accelerationTime"
  | "meanGradient"
  | "meanVelocity"
  | "peakGradient"
  | "peakVelocity";

export const vtiAssociatedMeasurements: Partial<
  Record<MeasurementName, Record<VTIAssociatedMeasurementName, MeasurementName>>
> = {
  [MeasurementName.AorticRegurgitationVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.AorticRegurgitationAccelerationTime,
    meanGradient: MeasurementName.AorticRegurgitationMeanGradient,
    meanVelocity: MeasurementName.AorticRegurgitationMeanVelocity,
    peakGradient: MeasurementName.AorticRegurgitationPeakGradient,
    peakVelocity: MeasurementName.AorticRegurgitationPeakVelocity,
  },
  [MeasurementName.AorticValveVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.AorticValveAccelerationTime,
    meanGradient: MeasurementName.AorticValveMeanGradient,
    meanVelocity: MeasurementName.AorticValveMeanVelocity,
    peakGradient: MeasurementName.AorticValvePeakGradient,
    peakVelocity: MeasurementName.AorticValvePeakVelocity,
  },
  [MeasurementName.LeftVentricleOutflowTractVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.LeftVentricleOutflowTractAccelerationTime,
    meanGradient: MeasurementName.LeftVentricleOutflowTractMeanGradient,
    meanVelocity: MeasurementName.LeftVentricleOutflowTractMeanVelocity,
    peakGradient: MeasurementName.LeftVentricleOutflowTractPeakGradient,
    peakVelocity: MeasurementName.LeftVentricleOutflowTractPeakVelocity,
  },
  [MeasurementName.MitralValveVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.MitralValveAccelerationTime,
    meanGradient: MeasurementName.MitralValveMeanGradient,
    meanVelocity: MeasurementName.MitralValveMeanVelocity,
    peakGradient: MeasurementName.MitralValvePeakGradient,
    peakVelocity: MeasurementName.MitralValvePeakVelocity,
  },
  [MeasurementName.MitralRegurgitationVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.MitralRegurgitationAccelerationTime,
    meanGradient: MeasurementName.MitralRegurgitationMeanGradient,
    meanVelocity: MeasurementName.MitralRegurgitationMeanVelocity,
    peakGradient: MeasurementName.MitralRegurgitationPeakGradient,
    peakVelocity: MeasurementName.MitralRegurgitationPeakVelocity,
  },
  [MeasurementName.PulmonaryRegurgitationVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.PulmonaryRegurgitationAccelerationTime,
    meanGradient: MeasurementName.PulmonaryRegurgitationMeanGradient,
    meanVelocity: MeasurementName.PulmonaryRegurgitationMeanVelocity,
    peakGradient: MeasurementName.PulmonaryRegurgitationPeakGradient,
    peakVelocity: MeasurementName.PulmonaryRegurgitationPeakVelocity,
  },
  [MeasurementName.PulmonaryValveVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.PulmonaryValveAccelerationTime,
    meanGradient: MeasurementName.PulmonaryValveMeanGradient,
    meanVelocity: MeasurementName.PulmonaryValveMeanVelocity,
    peakGradient: MeasurementName.PulmonaryValvePeakGradient,
    peakVelocity: MeasurementName.PulmonaryValvePeakVelocity,
  },
  [MeasurementName.RightVentricleOutflowTractVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.RightVentricleOutflowTractAccelerationTime,
    meanGradient: MeasurementName.RightVentricleOutflowTractMeanGradient,
    meanVelocity: MeasurementName.RightVentricleOutflowTractMeanVelocity,
    peakGradient: MeasurementName.RightVentricleOutflowTractPeakGradient,
    peakVelocity: MeasurementName.RightVentricleOutflowTractPeakVelocity,
  },
  [MeasurementName.TricuspidRegurgitationVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.TricuspidRegurgitationAccelerationTime,
    meanGradient: MeasurementName.TricuspidRegurgitationMeanGradient,
    meanVelocity: MeasurementName.TricuspidRegurgitationMeanVelocity,
    peakGradient: MeasurementName.TricuspidRegurgitationPeakGradient,
    peakVelocity: MeasurementName.TricuspidRegurgitationPeakVelocity,
  },
  [MeasurementName.TricuspidValveVelocityTimeIntegral]: {
    accelerationTime: MeasurementName.TricuspidValveAccelerationTime,
    meanGradient: MeasurementName.TricuspidValveMeanGradient,
    meanVelocity: MeasurementName.TricuspidValveMeanVelocity,
    peakGradient: MeasurementName.TricuspidValvePeakGradient,
    peakVelocity: MeasurementName.TricuspidValvePeakVelocity,
  },
};

export const allAssociatedMeasurements = {
  ...efAssociatedMeasurements,
  ...dopplerSlopeAssociatedMeasurements,
  ...volumeAssociatedMeasurements,
  ...velocityAssociatedMeasurements,
  ...vtiAssociatedMeasurements,
};

/**
 * Returns the associated measurements that can be created for the specified measurement name, if
 * any.
 */
export function getAssociatedMeasurementsForMeasurement(name: MeasurementName): MeasurementName[] {
  return Object.values(allAssociatedMeasurements[name] ?? {});
}

/** Returns the associated measurements that can be created using the specified measurement tool. */
export function getAssociatedMeasurementsForTool(tool: MeasurementToolName): MeasurementName[] {
  let associatedMeasurements: Partial<Record<MeasurementName, Record<string, MeasurementName>>> =
    {};

  if (tool === MeasurementToolName.EjectionFraction) {
    associatedMeasurements = efAssociatedMeasurements;
  } else if (tool === MeasurementToolName.Volume) {
    associatedMeasurements = volumeAssociatedMeasurements;
  } else if (tool === MeasurementToolName.Velocity) {
    associatedMeasurements = velocityAssociatedMeasurements;
  } else if (tool === MeasurementToolName.VelocityTimeIntegral) {
    associatedMeasurements = vtiAssociatedMeasurements;
  } else if (tool === MeasurementToolName.DopplerSlope) {
    associatedMeasurements = dopplerSlopeAssociatedMeasurements;
  }

  return Object.values(associatedMeasurements).flatMap((m) => Object.values(m));
}
