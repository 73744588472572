<template>
  <Modal title="Send HL7 Message" :activity-text="activityText" @header-button-click="exitModal">
    <div class="content">
      <div class="field">
        <b>Provider</b>
        <DropdownWidget v-model="selectedHL7IntegrationId" :items="hl7IntegrationNames" />
      </div>

      <div class="field">
        <b>Recipient</b>
        <DropdownWidget v-model="selectedRecipientId" :items="hl7IntegrationRecipients" />
      </div>

      <div class="button-container">
        <button :disabled="!sendEnabled" @click="sendMessage">Send</button>

        <a
          v-if="hasHL7MessageDownloadPermission"
          :href="`/api/integrations/hl7/${selectedHL7IntegrationId}/recipients/${selectedRecipientId}/message?studyReportId=${studyReportId}`"
          class="download-hl7"
          :class="{ disabled: selectedHL7IntegrationId === '' || selectedRecipientId === '' }"
        >
          Download HL7
        </a>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import type { HL7IntegrationGetManyResponseDto } from "../../../backend/src/integrations/hl7/dto/hl7-integration-get-many.dto";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { hasHL7MessageDownloadPermission } from "../auth/authorization";
import { addNotification } from "../utils/notifications";
import DropdownWidget from "./DropdownWidget.vue";
import Modal from "./Modal.vue";

interface Props {
  studyReportId: string;
}

const props = defineProps<Props>();

const emits = defineEmits(["close"]);

const selectedHL7IntegrationId = ref("");
const selectedRecipientId = ref("");

const activityText = ref("");

const hl7Integrations = ref<HL7IntegrationGetManyResponseDto>([]);
const hl7IntegrationNames = computed(() =>
  hl7Integrations.value.map((x) => ({ value: x.id, text: x.name }))
);

const sendEnabled = computed(() => selectedHL7IntegrationId.value && selectedRecipientId.value);

const hl7IntegrationRecipients = computed(() => {
  const selectedIntegration = hl7Integrations.value.find(
    (x) => x.id === selectedHL7IntegrationId.value
  );
  if (!selectedIntegration) {
    return [];
  }

  return selectedIntegration.recipients.map((recipient) => ({
    value: recipient.id,
    text: formatDicomName(recipient.name),
  }));
});

function exitModal(): void {
  emits("close");
}

async function sendMessage(): Promise<void> {
  activityText.value = "Sending";

  try {
    await axios.post<{ message: string }>(
      `/api/integrations/hl7/${selectedHL7IntegrationId.value}/recipients/${selectedRecipientId.value}/send-message`,
      { studyReportId: props.studyReportId }
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error sending HL7 message" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Sent HL7 message" });

  emits("close");
}

onMounted(async () => {
  activityText.value = "Loading";

  try {
    hl7Integrations.value = (
      await axios.get<HL7IntegrationGetManyResponseDto>(`/api/integrations/hl7`)
    ).data;
  } catch {
    addNotification({ type: "error", message: "Error loading HL7 integrations" });
  } finally {
    activityText.value = "";
  }
});
</script>

<style scoped lang="scss">
.content {
  display: grid;
  gap: 16px;
  width: 420px;
}

.field {
  display: grid;
  gap: 8px;
}

.button-container {
  display: grid;
  place-content: center;
  position: relative;
}

.download-hl7 {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-decoration: underline;

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
</style>
