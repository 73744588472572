import { distance } from "@/../../backend/src/shared/math-utils";
import { MEASUREMENT_LABEL_OFFSET } from "@/measurements/measurement-helpers";
import type { Study, StudyClipRegion } from "@/utils/study-data";
import { computed, ref, watch } from "vue";
import { MeasuredValue } from "../../../../../backend/src/measurements/measured-value";
import {
  isCustomMeasurement,
  type MeasurementName,
} from "../../../../../backend/src/measurements/measurement-names";
import { getLinearMeasurementValue } from "../../../../../backend/src/measurements/measurement-tool-evaluation";
import type { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { getClips } from "../../../../../backend/src/studies/study-helpers";
import { buildMeasurementToolBatchChangeRequest } from "../../measurement-tool-helpers";
import type {
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "../measurement-tool";
import {
  MEASUREMENT_COLOR,
  RESTART_MEASUREMENT_ICON,
  checkForMeasurementPointEdits,
} from "../measurement-tool-helpers";
import { measurementIncompleteTooltipText } from "../measurement-tooltips";

/**
 * Creates a linear measurement tool for the given study and region. This is reused by the several
 * measurement tools that essentially just draw a straight line, albeit with different units being
 * measured or axes being locked etc.
 */
export function createBaseLinearMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined,
  options: {
    customMeasurementName: MeasurementName;
    tool:
      | MeasurementToolName.Distance
      | MeasurementToolName.Displacement
      | MeasurementToolName.Time;
    isXAxisLocked: boolean;
    isYAxisLocked: boolean;
  }
): Readonly<
  Omit<MeasurementTool, "displayName" | "getCreatableMeasurementNames" | "isMeasurableOnStudyClip">
> {
  const { customMeasurementName, tool, isXAxisLocked, isYAxisLocked } = options;

  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customName = ref("");

  const frame = ref(0);

  const from = ref<number[] | null>(null);
  const to = ref<number[] | null>(null);
  const lastMousePosition = ref<number[] | null>(null);

  let editingPointIndex: number | null = null;
  let editPointOffset = [0, 0];

  const actualTo = computed(() => to.value ?? lastMousePosition.value);

  const contour = computed(() => {
    if (from.value !== null && to.value !== null) {
      return [...from.value, ...to.value];
    }

    return [];
  });

  const measuredValue = computed(() => {
    const clip = getClips(study).find((c) => c.id === studyClipId);

    if (
      from.value === null ||
      actualTo.value === null ||
      clip === undefined ||
      region === undefined
    ) {
      return MeasuredValue.Null;
    }

    return getLinearMeasurementValue(from.value, actualTo.value, clip, region);
  });

  const isSaveButtonEnabled = computed(() => from.value?.length === 2 && to.value?.length === 2);

  const saveButtonTooltip = computed(() => {
    if (from.value?.length !== 2 || to.value?.length !== 2) {
      return measurementIncompleteTooltipText;
    }

    return "";
  });

  const helpText = computed(() => {
    if (!from.value) {
      return "Click to place the first point";
    }

    if (!to.value) {
      return "Click to place the second point";
    }

    return "";
  });

  const toolbarItems = computed((): ToolbarItem[] => {
    const items: ToolbarItem[] = [
      {
        text: measuredValue.value.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: (): void => {
          from.value = null;
          to.value = null;
          lastMousePosition.value = null;
          editingPointIndex = null;
          editPointOffset = [0, 0];
        },
      });
    }

    return items;
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    if (from.value === null || actualTo.value === null) {
      return [];
    }

    const position = getLinearMeasurementLabelPosition({
      canvas,
      from: from.value,
      to: actualTo.value,
    });

    return [
      {
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: measuredValue.value.createTransientValue(
          measurementName.value ?? customMeasurementName,
          contour.value,
          frame.value
        ),
        x: position.x,
        y: position.y,
      },
    ];
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    if (!from.value || !actualTo.value) {
      return;
    }

    drawLinearMeasurement({
      canvas,
      ctx,
      from: from.value,
      to: actualTo.value,
      drawEditHandles: false,
    });
  }

  function onCanvasMouseDown(pt: number[]): void {
    if (from.value && to.value) {
      const edit = checkForMeasurementPointEdits(contour.value, pt);
      if (edit !== undefined) {
        editingPointIndex = edit.editingPointIndex;
        editPointOffset = edit.editPointOffset;
      }

      return;
    }

    if (from.value === null) {
      from.value = pt;
    } else if (to.value === null) {
      if (isXAxisLocked) {
        pt[0] = from.value[0];
      }

      if (isYAxisLocked) {
        pt[1] = from.value[1];
      }

      to.value = pt;
    }
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    if (to.value === null && from.value !== null) {
      if (isXAxisLocked) {
        pt[0] = from.value[0];
      }

      if (isYAxisLocked) {
        pt[1] = from.value[1];
      }
    }

    lastMousePosition.value = pt;
    const offsetMouse = [pt[0] - editPointOffset[0], pt[1] - editPointOffset[1]];

    if (editingPointIndex !== null) {
      const editingPoint = editingPointIndex === 0 ? from : to;
      const otherPoint = editingPointIndex === 1 ? from : to;

      if (editingPoint.value !== null && otherPoint.value !== null) {
        editingPoint.value = offsetMouse;

        if (isXAxisLocked) {
          otherPoint.value[0] = offsetMouse[0];
        }

        if (isYAxisLocked) {
          otherPoint.value[1] = offsetMouse[1];
        }
      }
    }

    return true;
  }

  function onCanvasMouseUp(pt: number[]): void {
    const rawLength =
      from.value && to.value
        ? Math.sqrt(
            Math.abs(from.value[0] - to.value[0]) ** 2 + Math.abs(from.value[1] - to.value[1]) ** 2
          )
        : 0;

    if (to.value === null && rawLength > 0.02) {
      to.value = pt;
    } else if (editingPointIndex !== null) {
      editingPointIndex = null;
      editPointOffset = [0, 0];
    }
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null || value.contour.length !== 4) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    from.value = [value.contour[0], value.contour[1]];
    to.value = [value.contour[2], value.contour[3]];
    frame.value = value.frame ?? 0;
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const measuredValueUnitAndValue = measuredValue.value.getInternalValueAndUnit() ?? null;
    if (measurementName.value === undefined || measuredValueUnitAndValue === null) {
      return {};
    }

    const measurementDetails = {
      ...measuredValueUnitAndValue,
      name: measurementName.value,
      tool,
      customName: isCustomMeasurement(measurementName.value) ? customName.value : "",
      frame: frame.value,
      contour: contour.value,
      studyClipId,
    };

    return buildMeasurementToolBatchChangeRequest(
      measurementDetails,
      savedMeasurementBeingEdited.value
    );
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([from, actualTo], () => requestRedrawHandlers.forEach((fn) => fn()));

  return {
    studyClipId,
    region,
    measurementName,
    customMeasurementName,
    customName,

    isSaveButtonVisible: computed(() => true),
    isSaveButtonEnabled,
    saveButtonTooltip,
    helpText,
    toolbarItems,

    interactivePoints: contour,
    getMeasurementLabels,
    requestRedrawHandlers,
    frameChangeHandlers: new Set(),
    shouldPassMousedownAfterRegionSelection: true,

    drawToCanvas,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasMouseUp,
    onFrameChange: (newFrame: number) => (frame.value = newFrame),
    isChangeAllowedOf: (_target: "clip" | "frame") => from.value === null,

    associatedMeasurements: computed(() => ({})),
    toggleAssociatedMeasurement: () => null,
    getMeasurementChangeRequests,

    editingMeasurementBatchId,
    loadSavedMeasurement,

    isMeasurementNameFixed: ref(false),
    onSaved: [],
    onDestroy: [],
  };
}

/** Draws a single linear measurement onto a canvas. */
export function drawLinearMeasurement({
  canvas,
  ctx,
  from,
  to,
  drawEditHandles,
  opacity = 1,
  dotted = false,
}: {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  from: number[];
  to: number[];
  drawEditHandles: boolean;
  opacity?: number;
  dotted?: boolean;
}): void {
  ctx.strokeStyle = MEASUREMENT_COLOR;
  ctx.globalAlpha = opacity;
  ctx.lineWidth = 2;

  if (dotted) {
    ctx.setLineDash([5, 5]);
  }

  ctx.beginPath();
  ctx.moveTo(from[0] * canvas.width, from[1] * canvas.height);
  ctx.lineTo(to[0] * canvas.width, to[1] * canvas.height);
  ctx.stroke();
  ctx.setLineDash([]);

  if (drawEditHandles) {
    drawCircularHandles(ctx, from, canvas, to);
  } else {
    let { nx, ny } = getLinearMeasurementLabelPosition({ canvas, from, to });

    nx /= canvas.width / 5;
    ny /= canvas.height / 5;

    drawPerpendicularLineHandles(canvas, ctx, from, to, nx, ny);
  }

  ctx.globalAlpha = 1;
}

function drawCircularHandles(
  ctx: CanvasRenderingContext2D,
  from: number[],
  canvas: HTMLCanvasElement,
  to: number[]
): void {
  ctx.beginPath();
  ctx.arc(from[0] * canvas.width, from[1] * canvas.height, 3, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.stroke();

  ctx.beginPath();
  ctx.arc(to[0] * canvas.width, to[1] * canvas.height, 3, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.stroke();
}

function drawPerpendicularLineHandles(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  from: number[],
  to: number[],
  nx: number,
  ny: number
): void {
  ctx.beginPath();
  ctx.moveTo((from[0] + nx) * canvas.width, (from[1] + ny) * canvas.height);
  ctx.lineTo((from[0] - nx) * canvas.width, (from[1] - ny) * canvas.height);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo((to[0] + nx) * canvas.width, (to[1] + ny) * canvas.height);
  ctx.lineTo((to[0] - nx) * canvas.width, (to[1] - ny) * canvas.height);
  ctx.stroke();
}

export function getLinearMeasurementLabelPosition({
  canvas,
  from,
  to,
  pixelOffsetFromMidpoint = MEASUREMENT_LABEL_OFFSET,
}: {
  canvas: HTMLCanvasElement;
  from: number[];
  to: number[];
  pixelOffsetFromMidpoint?: number;
}): { x: number; y: number; nx: number; ny: number } {
  // Use a simple offset along the perpendicular vector to keep the text from intersecting the line

  let nx = to[1] - from[1];
  let ny = from[0] - to[0];

  const length = distance(from, to);
  if (length > 0.001) {
    nx /= distance(from, to);
    ny /= distance(from, to);

    if (to[1] < from[1]) {
      nx = -nx;
      ny = -ny;
    }
  } else {
    nx = 1;
    ny = 0;
  }

  return {
    x: ((from[0] + to[0]) / 2) * canvas.width + nx * pixelOffsetFromMidpoint,
    y: ((from[1] + to[1]) / 2) * canvas.height + ny * pixelOffsetFromMidpoint,
    nx,
    ny,
  };
}
