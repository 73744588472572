<template>
  <div class="settings-title">HL7 Settings</div>

  <div class="top-row">
    <FilterInput v-model="integrationSearchTerm" placeholder="Search" />
    <button class="accented" style="margin-left: auto" @click="showHL7IntegrationModal = true">
      Create HL7 Integration
    </button>
  </div>

  <div class="integrations-table">
    <div class="header">
      <div>Name</div>
      <div>Endpoint</div>
      <div>Sending Facility</div>
      <div>Provider Details</div>
      <div>Receiving Facility</div>
      <div>Recipients</div>
    </div>

    <div class="header-line" />

    <template v-for="integration in filteredHl7Integrations" :key="integration.id">
      <div class="grid-table-row" @click="viewIntegration(integration.id)">
        <div>{{ integration.name }}</div>
        <div>{{ integration.endpoint }}</div>
        <div>{{ integration.sendingFacility }}</div>
        <div>{{ formatDicomName(integration.providerDetails) }}</div>

        <div>{{ integration.receivingFacility }}</div>

        <div class="badge-list">
          <Badge v-for="recipient in integration.recipients" :key="recipient.id">
            {{ formatDicomName(recipient.name) }}
            <template v-if="recipient.default"> (Default) </template>
          </Badge>
        </div>
      </div>
    </template>
  </div>

  <ActivityOverlay v-if="isLoading" text="Loading" />

  <AddHL7IntegrationModal
    v-if="showHL7IntegrationModal"
    v-model:integrations="hl7Integrations"
    @close="showHL7IntegrationModal = false"
    @integration-added="
      showHL7IntegrationModal = false;
      fetchHL7Integrations();
    "
  />
</template>

<script setup lang="ts">
import type { HL7IntegrationGetManyResponseDto } from "@/../../backend/src/integrations/hl7/dto/hl7-integration-get-many.dto";
import { formatDicomName } from "@/../../backend/src/shared/dicom-helpers";
import Badge from "@/components/Badge.vue";
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import FilterInput from "../../../components/FilterInput.vue";
import router from "../../../router";
import { addNotification } from "../../../utils/notifications";
import AddHL7IntegrationModal from "./AddHL7IntegrationModal.vue";

const isLoading = ref(false);
const showHL7IntegrationModal = ref(false);
const hl7Integrations = ref<HL7IntegrationGetManyResponseDto>([]);
const integrationSearchTerm = ref<string>("");

async function fetchHL7Integrations(): Promise<void> {
  isLoading.value = true;

  let response: AxiosResponse<HL7IntegrationGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<HL7IntegrationGetManyResponseDto>(`/api/integrations/hl7`);
  } catch {
    addNotification({ type: "error", message: `Failed loading HL7 integration data` });
    return;
  } finally {
    isLoading.value = false;
  }

  hl7Integrations.value = response.data;
}

onMounted(fetchHL7Integrations);

// Integration search by name, endpoint, sending & receiving facility,
// provider details, and recipients' names
const filteredHl7Integrations = computed(() => {
  const searchTerm = integrationSearchTerm.value.toLowerCase();

  return hl7Integrations.value.filter((integration) => {
    if (
      integration.name.toLowerCase().includes(searchTerm) ||
      integration.endpoint.toLowerCase().includes(searchTerm) ||
      integration.sendingFacility.toLowerCase().includes(searchTerm) ||
      integration.receivingFacility.toLowerCase().includes(searchTerm)
    ) {
      return true;
    }

    for (const providerDetail of integration.providerDetails) {
      if (providerDetail.toLowerCase().includes(searchTerm)) {
        return true;
      }
    }

    for (const recipients of integration.recipients) {
      for (const recipientName of recipients.name) {
        if (recipientName.toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
    }

    return false;
  });
});

async function viewIntegration(integrationId: string): Promise<void> {
  await router.push({
    name: "settings-integrations-hl7-view",
    params: { id: integrationId },
  });
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.badge-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 4px;
  text-transform: capitalize;
}

.integrations-table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 6;
    border-bottom: 2px solid var(--bg-color-4);
  }
}
</style>
