import { z } from "zod";
import { getMeasurementDisplayName } from "./measurement-display";
import { MeasurementName } from "./measurement-names";
import { MeasurementUnit } from "./measurement-units";

/**
 * The type of a measurement calculation variable that controls where its value comes from. This
 * can be either a value manually entered by the user when they use the calculation, or be the final
 * value of another measurement in the study.
 */
export enum MeasurementCalculationVariableType {
  Measurement = "measurement",
  ManuallyEntered = "manuallyEntered",
  PatientMetric = "patientMetric",
}

/**
 * The type of a patient metric that can be used as a variable in a measurement calculation.
 */
export enum CalculationPatientMetric {
  Age = "age",
  Weight = "weight",
  Height = "height",
  BodyMassIndex = "bodyMassIndex",
  BodySurfaceArea = "bodySurfaceArea",
}

const MeasurementCalculationVariableCommonSchema = z.strictObject({
  // The ID of this measurement calculation variable
  id: z.string().uuid(),

  // The name of this measurement calculation variable
  variableName: z.string(),

  // The type of this measurement calculation variable
  type: z.nativeEnum(MeasurementCalculationVariableType),

  // The unit that this measurement calculation variable is in
  unit: z.nativeEnum(MeasurementUnit).optional(),
});

// Schema for measurement calculation variables that take their value from another measurement in
// the study
const MeasurementCalculationVariableMeasurementSchema =
  MeasurementCalculationVariableCommonSchema.merge(
    z.strictObject({
      type: z.literal(MeasurementCalculationVariableType.Measurement),
      measurementName: z.nativeEnum(MeasurementName).optional(),
    })
  );

// Schema for measurement calculation variables that have their value manually entered by the user
// when using the calculation
const MeasurementCalculationVariableManuallyEnteredSchema =
  MeasurementCalculationVariableCommonSchema.merge(
    z.strictObject({
      type: z.literal(MeasurementCalculationVariableType.ManuallyEntered),
      label: z.string(),
    })
  );

// Schema for measurement calculation variables that take their value from a patient metric
const MeasurementCalculationVariablePatientMetricSchema =
  MeasurementCalculationVariableCommonSchema.merge(
    z.strictObject({
      type: z.literal(MeasurementCalculationVariableType.PatientMetric),
      patientMetric: z.nativeEnum(CalculationPatientMetric),
    })
  );

export const MeasurementCalculationVariableSchema = z.discriminatedUnion("type", [
  MeasurementCalculationVariableMeasurementSchema,
  MeasurementCalculationVariableManuallyEnteredSchema,
  MeasurementCalculationVariablePatientMetricSchema,
]);

export type MeasurementCalculationVariable = z.infer<typeof MeasurementCalculationVariableSchema>;

export function getCalculationMetricDisplayText(metric: CalculationPatientMetric): string {
  return {
    [CalculationPatientMetric.Age]: "Age",
    [CalculationPatientMetric.Weight]: "Weight",
    [CalculationPatientMetric.Height]: "Height",
    [CalculationPatientMetric.BodyMassIndex]: "Body mass index (BMI)",
    [CalculationPatientMetric.BodySurfaceArea]: "Body surface area (BSA)",
  }[metric];
}

export function getCalculationMetricDisplayUnit(metric: CalculationPatientMetric): string {
  return {
    [CalculationPatientMetric.Age]: "years",
    [CalculationPatientMetric.Weight]: "kg",
    [CalculationPatientMetric.Height]: "m",
    [CalculationPatientMetric.BodyMassIndex]: "kg/m²",
    [CalculationPatientMetric.BodySurfaceArea]: "m²",
  }[metric];
}

export function getVariableLabel(variable: MeasurementCalculationVariable) {
  if (variable.type === MeasurementCalculationVariableType.Measurement) {
    return getMeasurementDisplayName(
      variable.measurementName ?? MeasurementName.CustomValue,
      "unindexed"
    );
  }

  return variable.type === MeasurementCalculationVariableType.PatientMetric
    ? getCalculationMetricDisplayText(variable.patientMetric)
    : variable.label;
}
