<template>
  <Popper
    v-if="calculations.length > 0"
    class="measurement-calculations-popper"
    :offset-distance="6"
    :interactive="false"
    placement="top-start"
    @open="isCalculationsPopperOpen = true"
    @close="isCalculationsPopperOpen = false"
  >
    <Tooltip content="Calculate a measurement" :visible="!isCalculationsPopperOpen">
      <button
        data-testid="open-calculation-popper-btn"
        :class="{ active: isCalculationsPopperOpen }"
      >
        <FontAwesomeIcon icon="calculator" />
      </button>
    </Tooltip>

    <template #content>
      <OverlayScrollbar class="calculation-options">
        <template v-if="validCalculations.length > 0">
          <div
            v-for="calculation in validCalculations"
            :key="calculation.id"
            class="calculation-option"
            style="min-width: auto; width: max-content; max-width: 300px"
            :data-testid="`mmt-calculation-btn-${calculation.name.replace(' ', '-').toLowerCase()}`"
            @click="
              activeMeasurementCalculation = calculation;
              emits('open-measurement-pane');
            "
          >
            {{ calculation.name }}
          </div>
        </template>
        <i v-else> No measurement calculations have been defined </i>
      </OverlayScrollbar>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios, { AxiosResponse } from "axios";
import { computed, ref } from "vue";
import { MeasurementCalculationCreateResponseDto } from "../../../backend/src/measurements/dto/measurement-calculation-create.dto";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import Popper from "../components/Popper.vue";
import Tooltip from "../components/Tooltip.vue";
import {
  MeasurementCalculation,
  activeMeasurementCalculation,
  getMeasurementCalculationErrors,
} from "../measurements/calculations/measurement-calculations";
import { addNotification } from "../utils/notifications";

interface Emits {
  (event: "open-measurement-pane"): void;
}

const emits = defineEmits<Emits>();

const isCalculationsPopperOpen = ref(false);

const calculations = ref<MeasurementCalculation[]>([]);

const validCalculations = computed(() =>
  calculations.value.filter((c) => getMeasurementCalculationErrors(c) === undefined && c.enabled)
);

async function fetchMeasurementCalculations(): Promise<void> {
  let response: AxiosResponse<MeasurementCalculationCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<MeasurementCalculationCreateResponseDto[]>(
      "/api/measurement-calculations"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading measurement calculations" });
    return;
  }

  calculations.value = response.data;
}

void fetchMeasurementCalculations();
</script>

<style scoped lang="scss">
:deep(.measurement-calculations-popper) {
  background-color: var(--bg-color-4);
  box-shadow: none;
  padding: 0;
}

.calculation-options {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: calc(100vh - 150px);
  padding: 4px;
}

.calculation-option {
  padding: 4px 8px;
  cursor: pointer;
  background-color: var(--bg-color-3);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  min-width: max-content;
  transition: filter 100ms ease;

  &:hover {
    filter: brightness(200%);
  }
}
</style>
