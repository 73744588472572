<template>
  <Tooltip
    :visible="isPatientStudiesTooltipVisible && selectedStudy === undefined"
    :content="tooltipContent"
  >
    <Popper
      ref="popper"
      class="patient-studies-popper"
      placement="bottom-start"
      :offset-distance="4"
      :disabled="!isAvailable || selectedStudy !== undefined"
      @open="isPopperOpen = true"
      @close="isPopperOpen = false"
    >
      <div
        @click="isPopperOpen = !isPopperOpen"
        @mouseover="isPatientStudiesTooltipVisible = true"
        @mouseleave="isPatientStudiesTooltipVisible = false"
      >
        <slot :is-popper-open="isPopperOpen" :is-available="isAvailable" />
      </div>

      <template #content>
        <div class="studies-table">
          <div class="header">
            <div>Study Date</div>
            <div>Modality</div>
            <div>Type</div>
            <div>Institution</div>
            <div />
            <div />
            <div />
          </div>

          <div class="header-line" />

          <template v-for="(relatedStudy, index) of patientStudies" :key="relatedStudy.id">
            <template v-if="relatedStudy.id !== excludedStudyId">
              <RouterLink
                class="grid-table-row"
                :to="`/study-view/${relatedStudy.id}`"
                target="_blank"
              >
                <div v-if="relatedStudy.takenAt">
                  {{ formatDateTime(getStudyDateTime(relatedStudy)) }}
                </div>
                <i v-else style="opacity: 0.5">Unknown</i>
                <div>{{ relatedStudy.modality ?? "-" }}</div>
                <div>{{ studyTypeDisplayName[relatedStudy.type] ?? "-" }}</div>
                <div>{{ relatedStudy.institution }}</div>
              </RouterLink>

              <div
                v-if="relatedStudy.reports.some((report) => report.completedAt !== null)"
                class="view-report-button"
                style="margin: 0 8px"
                @click="viewReport(relatedStudy)"
              >
                View Report
              </div>
              <i v-else style="margin: 0 8px; opacity: 0.5; white-space: nowrap">Not reported</i>

              <IconButton
                v-if="!hideComparison"
                tooltip="Compare historical study"
                icon="film"
                :data-testid="`open-comparison-study-${index}`"
                @click="onOpenComparison(relatedStudy.id)"
              />

              <OpenStudyInSecondaryWindow
                v-if="isSecondaryWindowOpenAllowed"
                :study-id="relatedStudy.id"
              />
              <div v-else />
            </template>
          </template>
        </div>

        <ViewReportsModal
          v-if="selectedStudy !== undefined"
          :study-id="selectedStudy.id"
          :reports="selectedStudy.reports"
          @close="selectedStudy = undefined"
        />
      </template>
    </Popper>
  </Tooltip>
</template>

<script setup lang="ts">
import IconButton from "@/components/IconButton.vue";
import Popper from "@/components/Popper.vue";
import { computed, nextTick, ref } from "vue";
import type { PatientStudiesGetManyResponseDto } from "../../../backend/src/studies/dto/patient-studies-get-many.dto";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { studyTypeDisplayName } from "../../../backend/src/studies/study-type";
import Tooltip from "../components/Tooltip.vue";
import ViewReportsModal from "../reporting/ViewReportsModal.vue";
import { formatDateTime } from "../utils/date-time-utils";
import OpenStudyInSecondaryWindow from "./multi-window/OpenStudyInSecondaryWindow.vue";

interface Props {
  patientStudies: PatientStudiesGetManyResponseDto;
  excludedStudyId: string;
  showTooltip?: boolean;
  isSecondaryWindowOpenAllowed?: boolean;
}

interface Emits {
  (event: "open-comparison", comparedStudyId: string): void;
}

const props = withDefaults(defineProps<Props>(), {
  showTooltip: false,
  isSecondaryWindowOpenAllowed: true,
});

const emits = defineEmits<Emits>();

const popper = ref<{ close: () => void } | null>(null);

const isPopperOpen = ref(false);
const selectedStudy = ref<PatientStudiesGetManyResponseDto[0] | undefined>();

const isPatientStudiesTooltipVisible = ref(false);
const hideComparison = ref(false);

const isAvailable = computed(() => props.patientStudies.length > 1);

const tooltipContent = computed(() => {
  if (!props.showTooltip) {
    return "";
  }

  if (props.patientStudies.length === 0) {
    return "No other studies found because this study is missing an ID, birthdate, or name";
  }

  if (props.patientStudies.length === 1) {
    return "No other studies found for this patient";
  }

  const otherStudyCount = props.patientStudies.length - 1;

  return `Found ${otherStudyCount} other ${
    otherStudyCount === 1 ? "study" : "studies"
  } for this patient`;
});

function viewReport(relatedStudy: PatientStudiesGetManyResponseDto[0]): void {
  popper.value?.close();
  selectedStudy.value = relatedStudy;
}

function onOpenComparison(comparedStudyId: string): void {
  hideComparison.value = true;
  popper.value?.close();
  emits("open-comparison", comparedStudyId);

  // Temporarily hide the comparison button when we open a comparison view as this makes the tooltip
  // unmount immediately instead of jumping around the page as otherwise the comparison view mounts
  // in before the patient studies popper closes, which causes the tooltip to jump to the top left
  // of the page before disappearing on the next tick.
  void nextTick(() => {
    hideComparison.value = false;
  });
}
</script>

<style scoped lang="scss">
:deep(.patient-studies-popper) {
  background-color: var(--bg-color-4);
  padding: 12px 8px 8px;
}

.studies-table {
  display: grid;
  grid-template-columns: 120px 80px minmax(120px, max-content) minmax(120px, max-content) auto min-content min-content;
  gap: 8px;

  .header {
    display: contents;
    font-weight: bold;
    padding: 0 20px 4px 0;

    > :first-child {
      padding-left: 8px;
    }

    > * {
      padding-right: 20px;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 7;
    border-bottom: 1px solid var(--accent-color-1);
  }

  .grid-table-row {
    > * {
      padding: 0;
    }

    > :first-child {
      margin-left: 8px;
    }

    > :last-child {
      margin-right: 8px;
    }

    &:hover > * {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.view-report-button {
  font-weight: bold;
  cursor: pointer;
  align-self: center;
  transition: color 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}

.serial-window-btn {
  cursor: pointer;
  transition: color 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
