export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 128;

/**
 * Returns whether the given password is considered strong-enough to be used. Strong passwords
 * are at least eight characters long, and have three of the following types of characters:
 * lower-case, upper-case, numbers, special (non-alphanumeric) characters.
 */
export function isPasswordStrong(password: string): boolean {
  if (password.length < PASSWORD_MIN_LENGTH) {
    return false;
  }

  if (password.length > PASSWORD_MAX_LENGTH) {
    return false;
  }

  const isLowerCaseCharacterPresent = /[a-z]/.test(password);
  const isUpperCaseCharacterPresent = /[A-Z]/.test(password);
  const isNumberPresent = /[0-9]/.test(password);
  const isSpecialCharacterPresent = /[^a-zA-Z0-9]/.test(password);

  return (
    [
      isLowerCaseCharacterPresent,
      isUpperCaseCharacterPresent,
      isNumberPresent,
      isSpecialCharacterPresent,
    ].filter(Boolean).length >= 3
  );
}
