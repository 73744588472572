import { MeasurementName } from "@/../../backend/src/measurements/measurement-names";
import type { ReferenceRangeName } from "./reference-range";
import { ASE_FEMALE } from "./reference-range-set-ase-female";
import { ASE_MALE } from "./reference-range-set-ase-male";

/**
 * The names of the supported reference range sets.
 */
export enum ReferenceRangeSetName {
  ASEMale = "aseMale",
  ASEFemale = "aseFemale",
}

/**
 * Returns the display name to use for the given reference range set.
 */
export function getReferenceRangeSetDisplayName(
  referenceRangeSetName: ReferenceRangeSetName
): string {
  switch (referenceRangeSetName) {
    case ReferenceRangeSetName.ASEMale:
      return "ASE Male";
    case ReferenceRangeSetName.ASEFemale:
      return "ASE Female";
  }
}

/**
 * Returns the data from the given reference range set for the given measurement, or an empty array
 * if there is no reference range data available.
 */
export function getReferenceRangeSetData(
  referenceRangeSetName: ReferenceRangeSetName,
  measurementName: MeasurementName,
  isIndexed: boolean
): { from: number; to: number; name: ReferenceRangeName }[] {
  let referenceRanges: ReferenceRangeEndpoints | undefined = undefined;

  switch (referenceRangeSetName) {
    case ReferenceRangeSetName.ASEMale:
      referenceRanges = ASE_MALE[measurementName]?.[isIndexed ? "indexed" : "unindexed"];
      break;
    case ReferenceRangeSetName.ASEFemale:
      referenceRanges = ASE_FEMALE[measurementName]?.[isIndexed ? "indexed" : "unindexed"];
      break;
  }

  if (referenceRanges === undefined) {
    return [];
  }

  const result: { from: number; to: number; name: ReferenceRangeName }[] = [];

  for (let i = 0; i < referenceRanges.length; i++) {
    result.push({
      from: i === 0 ? getLeftAnchor(measurementName) : referenceRanges[i - 1].to,
      to: referenceRanges[i].to,
      name: referenceRanges[i].name,
    });
  }

  return result;
}

/**
 * Most measurements have a minimum meaningful value of zero, but those that need a different
 * left-most point for their reference ranges will have that value returned by this function.
 */
function getLeftAnchor(measurementName: MeasurementName): number {
  switch (measurementName) {
    case MeasurementName.AorticRootDiameter:
      return 1;
    case MeasurementName.GlobalPeakLongitudinalStrainA2C:
    case MeasurementName.GlobalPeakLongitudinalStrainA3C:
    case MeasurementName.GlobalPeakLongitudinalStrainA4C:
      return -30;
    case MeasurementName.LeftVentricleOutflowTractMeanVelocity:
      return -1;
    case MeasurementName.LeftVentricleOutflowTractPeakVelocity:
      return -1;
    default:
      return 0;
  }
}

/**
 * Describes a set of reference range values for a measurement. These start from the measurement's
 * left anchor value.
 */
type ReferenceRangeEndpoints = {
  to: number;
  name: ReferenceRangeName;
}[];

/**
 * Type for a set of reference ranges, e.g. ASE male, ASE female.
 */
export type ReferenceRangeSet = Partial<
  Record<
    MeasurementName,
    {
      unindexed?: ReferenceRangeEndpoints;
      indexed?: ReferenceRangeEndpoints;
    }
  >
>;
