<template>
  <div class="password-input" :class="{ 'show-mask-password-button': showMaskPasswordButton }">
    <div style="display: flex">
      <input
        ref="inputElement"
        v-model="password"
        :type="`${isPasswordVisible ? 'text' : 'password'}`"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
        data-testid="password-input"
        @keydown.enter="emits('enter')"
      />

      <Tooltip
        v-if="showMaskPasswordButton"
        style="display: flex"
        :content="`Click to ${isPasswordVisible ? 'hide' : 'show'} password`"
      >
        <div class="mask-password-icon" @click="isPasswordVisible = !isPasswordVisible">
          <FontAwesomeIcon :icon="`${isPasswordVisible ? 'eye' : 'eye-slash'}`" />
        </div>
      </Tooltip>
    </div>

    <Transition name="fade">
      <div
        v-if="isCapsLockMessageVisible"
        class="caps-lock-warning"
        data-testid="caps-lock-warning"
      >
        Caps lock is on
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFocus, useKeyModifier } from "@vueuse/core";
import { computed, ref } from "vue";
import Tooltip from "../components/Tooltip.vue";

interface Props {
  modelValue: string;
  placeholder?: string;
  initialFocus?: boolean;
  showMaskPasswordButton?: boolean;
  disabled?: boolean;
}

interface Emits {
  (event: "update:modelValue", newValue: string): void;
  (event: "enter"): void;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  initialFocus: false,
  showMaskPasswordButton: false,
});

const emits = defineEmits<Emits>();

const password = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: string) {
    emits("update:modelValue", newValue);
  },
});

const inputElement = ref<HTMLInputElement>();
const { focused: isFocused } = useFocus(inputElement, { initialValue: props.initialFocus });

const isPasswordVisible = ref(false);

const isCapsLockOn = useKeyModifier("CapsLock", { initial: false });
const isCapsLockMessageVisible = computed(() => isFocused.value && isCapsLockOn.value);
</script>

<style scoped lang="scss">
.password-input {
  display: grid;

  input {
    flex: 1;
  }

  &.show-mask-password-button {
    input {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      border-right: none;
    }

    &:focus-within {
      .mask-password-icon {
        border-color: var(--input-focus-border-color);
      }
    }
  }
}

.mask-password-icon {
  cursor: pointer;

  width: 32px;
  display: grid;
  place-content: center;
  border: 1px solid var(--border-color-1);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-left: none;
  background: var(--bg-color-2);
  transition:
    color 100ms ease,
    border-color 100ms ease;

  color: var(--accent-color-1);

  &:hover {
    background-color: var(--bg-color-3);
    color: var(--accent-color-2);
  }
}

.caps-lock-warning {
  margin-left: auto;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.8em;
  max-height: 0;
}
</style>
