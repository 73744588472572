import { entries } from "lodash";
import { MeasurementName } from "./measurement-names";

/** The measurable physical quantities. Each measurement unit is paired with one of these values. */
export enum MeasurementPhysicalQuantity {
  None = "none",
  Volume = "volume",
  Length = "length",
  Velocity = "velocity",
  Acceleration = "acceleration",
  Angle = "angle",
  Area = "area",
  Time = "time",
  FlowRate = "flowRate",
  RadiationDoseLength = "radiationDoseLength",
  RatePerMinute = "ratePerMinute",
  Weight = "weight",
  Pressure = "pressure",
  PressurePerSecond = "pressurePerSecond",
  PressurePerLiter = "pressurePerLiter",
}

/**
 * The supported units that can be used by a measurement.
 */
export enum MeasurementUnit {
  Ratio = "ratio",
  Percentage = "percentage",
  Degrees = "degrees",
  MilligrayCentimeters = "milligrayCentimeters",
  Milliliters = "milliliters",
  MillilitersPerSecond = "millilitersPerSecond",
  Millimeters = "millimeters",
  MillimetersSquared = "millimetersSquared",
  MillimetersCubed = "millimetersCubed",
  Centimeters = "centimeters",
  CentimetersSquared = "centimetersSquared",
  CentimetersPerSecond = "centimetersPerSecond",
  CentimetersPerSecondSquared = "centimetersPerSecondSquared",
  Meters = "meters",
  MetersSquared = "metersSquared",
  MetersPerSecond = "metersPerSecond",
  MetersPerSecondSquared = "metersPerSecondSquared",
  MillimetersPerSecond = "millimetersPerSecond",
  Seconds = "seconds",
  Milliseconds = "milliseconds",
  LitersPerSecond = "litersPerSecond",
  LitersPerMinute = "litersPerMinute",
  Liters = "liters",
  Grams = "grams",
  Kilograms = "kilograms",
  MillimetersOfMercury = "millimetersOfMercury",
  MillimetersOfMercuryPerSecond = "millimetersOfMercuryPerSecond",
  MillimetersOfMercuryPerLiter = "millimetersOfMercuryPerLiter",
  BeatsPerMinute = "beatsPerMinute",
}

/**
 * The units used internally. These are generally SI units, however some cases such as volume
 * use a derived unit because the SI unit quantity is unreasonably large to use for medical
 * purposes.
 */
export const internalUnitForPhysicalQuantity: Record<MeasurementPhysicalQuantity, MeasurementUnit> =
  {
    [MeasurementPhysicalQuantity.None]: MeasurementUnit.Ratio,
    [MeasurementPhysicalQuantity.Angle]: MeasurementUnit.Degrees,
    [MeasurementPhysicalQuantity.Volume]: MeasurementUnit.Liters,
    [MeasurementPhysicalQuantity.Length]: MeasurementUnit.Meters,
    [MeasurementPhysicalQuantity.Velocity]: MeasurementUnit.MetersPerSecond,
    [MeasurementPhysicalQuantity.Acceleration]: MeasurementUnit.MetersPerSecondSquared,
    [MeasurementPhysicalQuantity.Area]: MeasurementUnit.MetersSquared,
    [MeasurementPhysicalQuantity.Time]: MeasurementUnit.Seconds,
    [MeasurementPhysicalQuantity.FlowRate]: MeasurementUnit.LitersPerSecond,
    [MeasurementPhysicalQuantity.RatePerMinute]: MeasurementUnit.BeatsPerMinute,
    [MeasurementPhysicalQuantity.RadiationDoseLength]: MeasurementUnit.MilligrayCentimeters,
    [MeasurementPhysicalQuantity.Weight]: MeasurementUnit.Kilograms,
    [MeasurementPhysicalQuantity.Pressure]: MeasurementUnit.MillimetersOfMercury,
    [MeasurementPhysicalQuantity.PressurePerSecond]: MeasurementUnit.MillimetersOfMercuryPerSecond,
    [MeasurementPhysicalQuantity.PressurePerLiter]: MeasurementUnit.MillimetersOfMercuryPerLiter,
  };

/** The physical quantity that's measured by each measurement unit, e.g. time for milliseconds. */
export const physicalQuantityForUnit: Record<MeasurementUnit, MeasurementPhysicalQuantity> = {
  [MeasurementUnit.Ratio]: MeasurementPhysicalQuantity.None,
  [MeasurementUnit.Percentage]: MeasurementPhysicalQuantity.None,
  [MeasurementUnit.Degrees]: MeasurementPhysicalQuantity.Angle,
  [MeasurementUnit.MilligrayCentimeters]: MeasurementPhysicalQuantity.RadiationDoseLength,
  [MeasurementUnit.Milliliters]: MeasurementPhysicalQuantity.Volume,
  [MeasurementUnit.MillilitersPerSecond]: MeasurementPhysicalQuantity.FlowRate,
  [MeasurementUnit.Millimeters]: MeasurementPhysicalQuantity.Length,
  [MeasurementUnit.MillimetersSquared]: MeasurementPhysicalQuantity.Area,
  [MeasurementUnit.MillimetersCubed]: MeasurementPhysicalQuantity.Volume,
  [MeasurementUnit.Centimeters]: MeasurementPhysicalQuantity.Length,
  [MeasurementUnit.CentimetersPerSecond]: MeasurementPhysicalQuantity.Velocity,
  [MeasurementUnit.CentimetersPerSecondSquared]: MeasurementPhysicalQuantity.Acceleration,
  [MeasurementUnit.CentimetersSquared]: MeasurementPhysicalQuantity.Area,
  [MeasurementUnit.MetersSquared]: MeasurementPhysicalQuantity.Area,
  [MeasurementUnit.Meters]: MeasurementPhysicalQuantity.Length,
  [MeasurementUnit.MetersPerSecond]: MeasurementPhysicalQuantity.Velocity,
  [MeasurementUnit.MetersPerSecondSquared]: MeasurementPhysicalQuantity.Acceleration,
  [MeasurementUnit.MillimetersPerSecond]: MeasurementPhysicalQuantity.Velocity,
  [MeasurementUnit.Seconds]: MeasurementPhysicalQuantity.Time,
  [MeasurementUnit.Milliseconds]: MeasurementPhysicalQuantity.Time,
  [MeasurementUnit.LitersPerMinute]: MeasurementPhysicalQuantity.FlowRate,
  [MeasurementUnit.BeatsPerMinute]: MeasurementPhysicalQuantity.RatePerMinute,
  [MeasurementUnit.Grams]: MeasurementPhysicalQuantity.Weight,
  [MeasurementUnit.MillimetersOfMercury]: MeasurementPhysicalQuantity.Pressure,
  [MeasurementUnit.MillimetersOfMercuryPerSecond]: MeasurementPhysicalQuantity.PressurePerSecond,
  [MeasurementUnit.MillimetersOfMercuryPerLiter]: MeasurementPhysicalQuantity.PressurePerLiter,
  [MeasurementUnit.LitersPerSecond]: MeasurementPhysicalQuantity.FlowRate,
  [MeasurementUnit.Liters]: MeasurementPhysicalQuantity.Volume,
  [MeasurementUnit.Kilograms]: MeasurementPhysicalQuantity.Weight,
};

/**
 * The factors that convert each physical quantity into a base value for its physical quantity. The
 * ratio between two of these conversion factors for the same measurement quantity is used to
 * convert between units.
 */
export const measurementUnitConversionFactor: Record<MeasurementUnit, number> = {
  [MeasurementUnit.Ratio]: 1,
  [MeasurementUnit.Percentage]: 1,
  [MeasurementUnit.Degrees]: 1,
  [MeasurementUnit.MilligrayCentimeters]: 0.001 * 0.01,
  [MeasurementUnit.Milliliters]: 0.001,
  [MeasurementUnit.MillilitersPerSecond]: 0.001,
  [MeasurementUnit.Millimeters]: 0.001,
  [MeasurementUnit.MillimetersSquared]: 0.000001,
  [MeasurementUnit.MillimetersCubed]: 0.000001,
  [MeasurementUnit.Centimeters]: 0.01,
  [MeasurementUnit.CentimetersSquared]: 0.0001,
  [MeasurementUnit.CentimetersPerSecond]: 0.01,
  [MeasurementUnit.CentimetersPerSecondSquared]: 0.01,
  [MeasurementUnit.Meters]: 1,
  [MeasurementUnit.MetersSquared]: 1,
  [MeasurementUnit.MetersPerSecond]: 1,
  [MeasurementUnit.MetersPerSecondSquared]: 1,
  [MeasurementUnit.MillimetersPerSecond]: 0.001,
  [MeasurementUnit.Seconds]: 1,
  [MeasurementUnit.Milliseconds]: 0.001,
  [MeasurementUnit.LitersPerMinute]: 1 / 60,
  [MeasurementUnit.Grams]: 0.001,
  [MeasurementUnit.MillimetersOfMercury]: 133.322,
  [MeasurementUnit.MillimetersOfMercuryPerSecond]: 1,
  [MeasurementUnit.MillimetersOfMercuryPerLiter]: 1,
  [MeasurementUnit.LitersPerSecond]: 1,
  [MeasurementUnit.Liters]: 1,
  [MeasurementUnit.Kilograms]: 1,
  [MeasurementUnit.BeatsPerMinute]: 1,
};

/**
 * The measurement units that are converted to from the internal unit for displaying in the UI.
 */
const displayMeasurementUnits: Record<MeasurementName, MeasurementUnit> = {
  // This unit won't actually be used because it comes from the `customUnit` that's specified
  [MeasurementName.CustomValue]: MeasurementUnit.Ratio,

  [MeasurementName.CustomAngle]: MeasurementUnit.Degrees,
  [MeasurementName.CustomDistance]: MeasurementUnit.Centimeters,
  [MeasurementName.CustomArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.CustomVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.CustomEjectionFraction]: MeasurementUnit.Percentage,
  [MeasurementName.CustomVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.CustomDopplerSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.CustomMModeSlope]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.CustomVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.CustomTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.CustomDisplacement]: MeasurementUnit.Centimeters,

  [MeasurementName.AbdominalAortaDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticAnnulusDiameter]: MeasurementUnit.Millimeters,
  [MeasurementName.AorticArchDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticRegurgitationAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticRegurgitationAliasingVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.AorticRegurgitationDecelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.AorticRegurgitationDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticRegurgitationEffectiveRegurgitantOrificeArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.AorticRegurgitationMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AorticRegurgitationMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AorticRegurgitationPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AorticRegurgitationPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AorticRegurgitationPressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.AorticRegurgitationProximalIsovelocitySurfaceAreaRadius]:
    MeasurementUnit.Centimeters,
  [MeasurementName.AorticRegurgitationVenaContractaWidth]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticRegurgitationVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticRegurgitationVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.AorticRootDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticRootDiameterMMode]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticSinotubularJunctionDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.AorticValveAccelerationSlope]: MeasurementUnit.CentimetersPerSecondSquared,
  [MeasurementName.AorticValveAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticValveArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.AorticValveAreaByVelocityTimeIntegral]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.AorticValveAreaPlanimetry]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.AorticValveClosure]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticValveCuspSeparationMMode]: MeasurementUnit.Millimeters,
  [MeasurementName.AorticValveDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticValveDecelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.AorticValveDimensionlessPerformanceIndex]: MeasurementUnit.Ratio,
  [MeasurementName.AorticValveEjectionTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticValveMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AorticValveMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AorticValvePeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AorticValvePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AorticValvePressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.AorticValveVelocityRatio]: MeasurementUnit.Ratio,
  [MeasurementName.AorticValveVelocityTimeIntegral]: MeasurementUnit.Centimeters,

  [MeasurementName.AscendingAortaDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.AscendingAortaMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AscendingAortaMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AscendingAortaPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.AscendingAortaPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.AscendingAortaVelocityTimeIntegral]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.AtrioseptalDefectDiameterMajorAxis]: MeasurementUnit.Centimeters,
  [MeasurementName.AtrioseptalDefectDiameterMinorAxis]: MeasurementUnit.Centimeters,

  [MeasurementName.CardiacOutput]: MeasurementUnit.LitersPerMinute,
  [MeasurementName.DescendingAortaDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.DiastolicBloodPressure]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.EVelocityToAnnulusEVelocityRatio]: MeasurementUnit.Ratio,

  [MeasurementName.DoseLengthProduct]: MeasurementUnit.MilligrayCentimeters,

  [MeasurementName.GlobalPeakLongitudinalStrainAverage]: MeasurementUnit.Percentage,
  [MeasurementName.GlobalPeakLongitudinalStrainA2C]: MeasurementUnit.Percentage,
  [MeasurementName.GlobalPeakLongitudinalStrainA3C]: MeasurementUnit.Percentage,
  [MeasurementName.GlobalPeakLongitudinalStrainA4C]: MeasurementUnit.Percentage,

  [MeasurementName.HeartRate]: MeasurementUnit.BeatsPerMinute,

  [MeasurementName.InferiorVenaCavaDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.InferiorVenaCavaDiameterExpiration]: MeasurementUnit.Centimeters,
  [MeasurementName.InferiorVenaCavaDiameterInspiration]: MeasurementUnit.Centimeters,

  [MeasurementName.InterventricularSeptumDiastolicThickness]: MeasurementUnit.Centimeters,
  [MeasurementName.InterventricularSeptumSystolicThickness]: MeasurementUnit.Centimeters,
  [MeasurementName.InterventricularSeptumToPosteriorWallThicknessRatio]: MeasurementUnit.Ratio,

  [MeasurementName.LeftAtrialAppendageExitVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftAtrialAppendageOcclusionDiscDepth]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumAorticRootRatio]: MeasurementUnit.Ratio,
  [MeasurementName.LeftAtriumAreaDiastolicA2C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftAtriumAreaDiastolicA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftAtriumAreaSystolicA2C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftAtriumAreaSystolicA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftAtriumDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumDiameterA2C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumDiameterA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumDiastolicMajorAxisA2C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumDiastolicMajorAxisA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeBiplaneAreaLength]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndDiastolicVolumeUniplaneAreaLengthA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeBiplaneAreaLength]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: MeasurementUnit.Milliliters,

  [MeasurementName.LeftAtriumSystolicMajorAxis]: MeasurementUnit.Millimeters,
  [MeasurementName.LeftAtriumSystolicMajorAxisA2C]: MeasurementUnit.Millimeters,
  [MeasurementName.LeftAtriumSystolicMajorAxisA4C]: MeasurementUnit.Millimeters,
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLength]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftAtriumSystolicVolumeUniplaneAreaLengthA4C]: MeasurementUnit.Milliliters,

  [MeasurementName.LeftCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.LeftCommonCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftCommonCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.LeftExternalCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftExternalCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.LeftVertebralArteryEndDiastolicVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftVertebralArteryMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftVertebralArteryPeakSystolicVelocity]: MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.LeftInternalCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]:
    MeasurementUnit.Ratio,
  [MeasurementName.LeftPulmonaryArteryDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftPulmonaryArteryPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.LeftPulmonaryArteryPeakVelocity]: MeasurementUnit.MetersPerSecond,

  [MeasurementName.LeftVentricleDiameterMethodOfDisks]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleDiastolicAreaA2C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftVentricleDiastolicAreaA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftVentricleChangeInTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.LeftVentricleContractilityFromMitralRegurgitationVelocity]:
    MeasurementUnit.MillimetersOfMercuryPerSecond,
  [MeasurementName.LeftVentricleEToATissueVelocityRatioMedialMitralAnnulus]: MeasurementUnit.Ratio,
  [MeasurementName.LeftVentricleEToATissueVelocityRatioLateralMitralAnnulus]: MeasurementUnit.Ratio,
  [MeasurementName.LeftVentricleEarlyDiastolicTissuePeakVelocity]:
    MeasurementUnit.MillimetersPerSecond,
  [MeasurementName.LeftVentricleEjectionFraction4DEF]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionAutoEF]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionBiplane]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionCube]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionTeichholz]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA2C]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEjectionFractionUniplaneA4C]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleEndDiastolicVolume4DEF]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeAutoEF]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeCubeMethod]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeTeichholz]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA3C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndDiastolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolume4DEF]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeAutoEF]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeCubeMethod]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeTeichholz]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA3C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleEndSystolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricleFractionalAreaChange]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleFractionalShortening]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleFractionalShorteningCubeMethod]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleFractionalShorteningTeichholz]: MeasurementUnit.Percentage,
  [MeasurementName.LeftVentricleInflowVelocityPropagationSlope]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.LeftVentricleInternalEndDiastolicDimension]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleInternalSystolicDimension]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA2C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleMajorAxisDiastolicDimensionA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA2C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleMajorAxisSystolicDimensionA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleMass]: MeasurementUnit.Grams,
  [MeasurementName.LeftVentricleOutflowTractAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.LeftVentricleOutflowTractArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftVentricleOutflowTractDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleOutflowTractHeight]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleOutflowTractMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.LeftVentricleOutflowTractMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.LeftVentricleOutflowTractPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.LeftVentricleOutflowTractPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.LeftVentricleOutflowTractVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentriclePeakStrainDispersion]: MeasurementUnit.Milliseconds,
  [MeasurementName.LeftVentriclePosteriorWallDiastolicThickness]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentriclePosteriorWallSystolicThickness]: MeasurementUnit.Centimeters,
  [MeasurementName.LeftVentricleSystolicAreaA2C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftVentricleSystolicAreaA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.LeftVentricleTotalVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricularCavityVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.LeftVentricularIsovolumetricContractionTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.LeftVentricularIsovolumetricRelaxationTime]: MeasurementUnit.Milliseconds,

  [MeasurementName.MainPulmonaryArteryDiameter]: MeasurementUnit.Centimeters,

  [MeasurementName.MitralAnnularPlaneSystolicExcursion]: MeasurementUnit.Centimeters,

  [MeasurementName.MitralRegurgitationAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralRegurgitationAliasingVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralRegurgitationEffectiveRegurgitantOrificeArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.MitralRegurgitationMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralRegurgitationMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.MitralRegurgitationPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralRegurgitationPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.MitralRegurgitationProximalIsovelocitySurfaceAreaRadius]:
    MeasurementUnit.Centimeters,
  [MeasurementName.MitralRegurgitationVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.MitralRegurgitationVolume]: MeasurementUnit.Milliliters,

  [MeasurementName.MitralValveAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralValveAccelerationTimeToDecelerationTimeRatio]: MeasurementUnit.Ratio,
  [MeasurementName.MitralValveAreaByPlanimetry]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.MitralValveAreaByPressureHalfTime]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.MitralValveAreaByVelocityTimeIntegral]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.MitralValveAnnulusDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.MitralValveAnnulusEarlyDiastolicMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.MitralValveAnnulusMedialEarlyDiastolicTissuePeakVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveAnnulusLateralEarlyDiastolicTissuePeakVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveAPrimeVelocityLateral]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveAPrimeVelocitySeptal]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveAWaveDuration]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralValveAWavePeakVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveAWavePressureGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralValveDecelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.MitralValveDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralValveDEExcursionMMode]: MeasurementUnit.Millimeters,
  [MeasurementName.MitralValveDESlopeMMode]: MeasurementUnit.MillimetersPerSecond,
  [MeasurementName.MitralValveAToERatio]: MeasurementUnit.Ratio,
  [MeasurementName.MitralValveEPointSeptalSeparation]: MeasurementUnit.Centimeters,
  [MeasurementName.MitralValveEToARatio]: MeasurementUnit.Ratio,
  [MeasurementName.MitralValveEWaveDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralValveEWavePeakVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.MitralValveEWavePressureGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralValveMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralValveMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.MitralValvePeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.MitralValvePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityAverageEWaveRatio]:
    MeasurementUnit.Ratio,
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityLateralEWaveRatio]:
    MeasurementUnit.Ratio,
  [MeasurementName.MitralValvePeakVelocityToLeftVentriclePeakTissueVelocityMedialEWaveRatio]:
    MeasurementUnit.Ratio,
  [MeasurementName.MitralValvePressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.MitralValvePressureHalfTimePeakVelocity]: MeasurementUnit.MillimetersPerSecond,
  [MeasurementName.MitralValveVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.MitralValveRegurgitantFraction]: MeasurementUnit.Percentage,
  [MeasurementName.MyocardialPerformanceIndex]: MeasurementUnit.Ratio,

  [MeasurementName.PatentDuctusArteriosusDiameter]: MeasurementUnit.Centimeters,

  [MeasurementName.ProximalInterventricularSeptumThickness]: MeasurementUnit.Centimeters,

  [MeasurementName.PulmonaryArterySystolicPressureCorrectedForPulmonaryValve]:
    MeasurementUnit.MillimetersOfMercury,

  [MeasurementName.PulmonaryRegurgitationAccelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.PulmonaryRegurgitationAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryRegurgitationAliasingVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.PulmonaryRegurgitationDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryRegurgitationDecelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.PulmonaryRegurgitationEffectiveRegurgitantOrificeArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.PulmonaryRegurgitationMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.PulmonaryRegurgitationMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryRegurgitationPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.PulmonaryRegurgitationPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.PulmonaryRegurgitationProximalIsovelocitySurfaceAreaRadius]:
    MeasurementUnit.Centimeters,
  [MeasurementName.PulmonaryRegurgitationPressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryRegurgitationVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.PulmonaryRegurgitationVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.PulmonaryRootDiameter]: MeasurementUnit.Centimeters,

  [MeasurementName.PulmonaryValveAreaByVelocityTimeIntegral]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.PulmonaryValveAccelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.PulmonaryValveAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryValveDecelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryValveDecelerationSlope]: MeasurementUnit.MetersPerSecondSquared,
  [MeasurementName.PulmonaryValveMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.PulmonaryValveMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryValvePeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.PulmonaryValvePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryValvePressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryValveVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.PulmonaryVascularResistance]: MeasurementUnit.MillimetersOfMercuryPerLiter,

  [MeasurementName.QpToQsRatio]: MeasurementUnit.Ratio,
  [MeasurementName.RelativeWallThickness]: MeasurementUnit.Ratio,

  [MeasurementName.PulmonaryVeinAtrialContractionReversalPeakVelocity]:
    MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryVeinAWaveDuration]: MeasurementUnit.Milliseconds,
  [MeasurementName.PulmonaryVeinDiastolicPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryVeinSystolicPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.PulmonaryVeinSystolicToDiastolicRatio]: MeasurementUnit.Ratio,

  [MeasurementName.RightAtriumDiameterDiastole]: MeasurementUnit.Centimeters,
  [MeasurementName.RightAtriumDiameterSystole]: MeasurementUnit.Centimeters,
  [MeasurementName.RightAtriumDiastolicArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.RightAtriumEndDiastolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndDiastolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndSystolicVolumeBiplane]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndSystolicVolumeBiplaneAreaLength]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA2C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumEndSystolicVolumeUniplaneAreaLengthA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightAtriumSystolicArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.RightAtriumSystolicPressure]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.RightAtriumSystolicMajorAxisA4C]: MeasurementUnit.Centimeters,

  [MeasurementName.RightCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.RightCommonCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightCommonCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.RightExternalCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightExternalCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.RightVertebralArteryEndDiastolicVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightVertebralArteryMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightVertebralArteryPeakSystolicVelocity]: MeasurementUnit.CentimetersPerSecond,

  [MeasurementName.RightInternalCarotidArteryDistalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryDistalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryDistalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryMidLongitudinalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryProximalEndDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryProximalMinimumDiastolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryProximalPeakSystolicVelocity]:
    MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightInternalCarotidArteryToCommonCarotidArteryPeakSystolicVelocityRatio]:
    MeasurementUnit.Ratio,

  [MeasurementName.RightPulmonaryArteryDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.RightPulmonaryArteryPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.RightPulmonaryArteryPeakVelocity]: MeasurementUnit.MetersPerSecond,

  [MeasurementName.RightVentricleBaseMinorAxisDiastoleA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleChangeInTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.RightVentricleContractilityFromTricuspidRegurgitationVelocity]:
    MeasurementUnit.MillimetersOfMercuryPerSecond,
  [MeasurementName.RightVentricleMidMinorAxisDiastoleA4C]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleEjectionFractionUniplaneA4C]: MeasurementUnit.Percentage,
  [MeasurementName.RightVentricleEndDiastolicAreaA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.RightVentricleEndDiastolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightVentricleEndSystolicAreaA4C]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.RightVentricleEndSystolicVolumeUniplaneA4C]: MeasurementUnit.Milliliters,
  [MeasurementName.RightVentricleFractionalAreaChange]: MeasurementUnit.Percentage,
  [MeasurementName.RightVentricleFreeWallSPrime]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.RightVentricleFreeWallStrain]: MeasurementUnit.Percentage,
  [MeasurementName.RightVentricleInternalDiastolicDimension]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleInternalSystolicDimension]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleMajorAxis]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleOutflowTractAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.RightVentricleOutflowTractArea]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.RightVentricleOutflowTractDiameter]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentricleOutflowTractMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.RightVentricleOutflowTractMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.RightVentricleOutflowTractPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.RightVentricleOutflowTractPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.RightVentricleOutflowTractVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.RightVentriclePeakSystolicPressure]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.RightVentricleStrokeVolume]: MeasurementUnit.Milliliters,

  [MeasurementName.SeptalPosteriorWallMotionDelay]: MeasurementUnit.Milliseconds,

  [MeasurementName.StrokeVolume]: MeasurementUnit.Milliliters,
  [MeasurementName.SystolicBloodPressure]: MeasurementUnit.MillimetersOfMercury,

  [MeasurementName.TricuspidAnnularPlaneSystolicExcursion]: MeasurementUnit.Centimeters,
  [MeasurementName.TricuspidAnnulusDiameter]: MeasurementUnit.Centimeters,

  [MeasurementName.TricuspidRegurgitationAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.TricuspidRegurgitationAliasingVelocity]: MeasurementUnit.CentimetersPerSecond,
  [MeasurementName.TricuspidRegurgitationEffectiveRegurgitantOrificeArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.TricuspidRegurgitationMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.TricuspidRegurgitationMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidRegurgitationPeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.TricuspidRegurgitationPeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceArea]:
    MeasurementUnit.CentimetersSquared,
  [MeasurementName.TricuspidRegurgitationProximalIsovelocitySurfaceAreaRadius]:
    MeasurementUnit.Centimeters,
  [MeasurementName.TricuspidRegurgitationVelocityTimeIntegral]: MeasurementUnit.Centimeters,
  [MeasurementName.TricuspidRegurgitationVolume]: MeasurementUnit.Milliliters,

  [MeasurementName.TricuspidValveAccelerationTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.TricuspidValveAreaByVelocityTimeIntegral]: MeasurementUnit.CentimetersSquared,
  [MeasurementName.TricuspidValveAWavePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidValveEWavePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidValveMeanGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.TricuspidValveMeanVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidValvePeakGradient]: MeasurementUnit.MillimetersOfMercury,
  [MeasurementName.TricuspidValvePeakVelocity]: MeasurementUnit.MetersPerSecond,
  [MeasurementName.TricuspidValvePressureHalfTime]: MeasurementUnit.Milliseconds,
  [MeasurementName.TricuspidValveVelocityTimeIntegral]: MeasurementUnit.Centimeters,

  [MeasurementName.VentricularSeptalDefectDiameter]: MeasurementUnit.Centimeters,
};

/** The physical quantities associated with each measurement name. */
const measurementPhysicalQuantities = new Map<MeasurementName, MeasurementPhysicalQuantity>(
  entries(displayMeasurementUnits).map((row) => [
    row[0] as MeasurementName,
    getUnitPhysicalQuantity(row[1]),
  ])
);

/** Returns the physical quantity the measurement measures. */
export function getMeasurementPhysicalQuantity(
  measurementName: MeasurementName
): MeasurementPhysicalQuantity {
  // Note that the nullish coalescing operator here can't trigger here as
  // measurementPhysicalQuantities is exhaustive
  return measurementPhysicalQuantities.get(measurementName) ?? MeasurementPhysicalQuantity.None;
}

/** Returns the physical quantity the unit represents. */
export function getUnitPhysicalQuantity(
  measurementUnit: MeasurementUnit
): MeasurementPhysicalQuantity {
  return physicalQuantityForUnit[measurementUnit];
}

/** Returns the unit that's used internally for the specified physical quantity. */
export function getInternalUnitForPhysicalQuantity(quantity: MeasurementPhysicalQuantity) {
  return internalUnitForPhysicalQuantity[quantity];
}

/** Returns the unit that's used internally for the specified measurement name. */
export function getInternalUnitForMeasurementName(name: MeasurementName) {
  return internalUnitForPhysicalQuantity[getMeasurementPhysicalQuantity(name)];
}

/**
 * Returns the internal unit for a study measurement value. This handles getting the right unit
 * for custom value measurements, which is based on the physical quantity of the custom unit.
 */
export function getInternalUnitForStudyMeasurement(measurement: {
  name: MeasurementName;
  customUnit?: MeasurementUnit | null;
}): MeasurementUnit {
  if (measurement.name !== MeasurementName.CustomValue) {
    return getInternalUnitForMeasurementName(measurement.name);
  }

  if (measurement.customUnit === null || measurement.customUnit === undefined) {
    throw Error("Attempted to get unit for custom value measurement without customUnit set");
  }

  return getInternalUnitForPhysicalQuantity(getUnitPhysicalQuantity(measurement.customUnit));
}

/** Returns the unit to use for the specified measurement. */
export function getMeasurementDisplayUnit(measurementName: MeasurementName): MeasurementUnit {
  return displayMeasurementUnits[measurementName];
}

/** Returns the text to display for the unit for the specified measurement. */
export function getMeasurementDisplayUnitText(measurementName: MeasurementName): string {
  return getUnitDisplayText(getMeasurementDisplayUnit(measurementName));
}

/** Returns the display text for the specified measurement unit. */
export function getUnitDisplayText(unit: MeasurementUnit): string {
  switch (unit) {
    case MeasurementUnit.Ratio:
      return "";
    case MeasurementUnit.Percentage:
      return "%";
    case MeasurementUnit.Degrees:
      return "°";
    case MeasurementUnit.MilligrayCentimeters:
      return "mGy⋅cm";
    case MeasurementUnit.Milliliters:
      return "mL";
    case MeasurementUnit.MillilitersPerSecond:
      return "mL/s";
    case MeasurementUnit.Millimeters:
      return "mm";
    case MeasurementUnit.MillimetersSquared:
      return "mm²";
    case MeasurementUnit.MillimetersCubed:
      return "mm³";
    case MeasurementUnit.Centimeters:
      return "cm";
    case MeasurementUnit.CentimetersPerSecond:
      return "cm/s";
    case MeasurementUnit.CentimetersPerSecondSquared:
      return "cm/s²";
    case MeasurementUnit.CentimetersSquared:
      return "cm²";
    case MeasurementUnit.Meters:
      return "m";
    case MeasurementUnit.MetersSquared:
      return "m²";
    case MeasurementUnit.MetersPerSecond:
      return "m/s";
    case MeasurementUnit.MetersPerSecondSquared:
      return "m/s²";
    case MeasurementUnit.MillimetersPerSecond:
      return "mm/s";
    case MeasurementUnit.Seconds:
      return "s";
    case MeasurementUnit.Milliseconds:
      return "ms";
    case MeasurementUnit.LitersPerMinute:
      return "L/min";
    case MeasurementUnit.BeatsPerMinute:
      return "bpm";
    case MeasurementUnit.Grams:
      return "g";
    case MeasurementUnit.MillimetersOfMercury:
      return "mmHg";
    case MeasurementUnit.MillimetersOfMercuryPerSecond:
      return "mmHg/s";
    case MeasurementUnit.MillimetersOfMercuryPerLiter:
      return "mmHg/L";
    case MeasurementUnit.LitersPerSecond:
      return "L/s";
    case MeasurementUnit.Liters:
      return "L";
    case MeasurementUnit.Kilograms:
      return "kg";
  }
}

/** Converts the units of a measurement value. Not all units can be converted between each other. */
export function convertMeasurementUnit(
  value: number | null | undefined,
  from: MeasurementUnit,
  to: MeasurementUnit
): number | null {
  if (
    physicalQuantityForUnit[from] !== physicalQuantityForUnit[to] ||
    value === null ||
    value === undefined
  ) {
    return null;
  }

  return (value * measurementUnitConversionFactor[from]) / measurementUnitConversionFactor[to];
}
