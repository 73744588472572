<template>
  <template v-for="indicator in activeMeasurement.scrubberIndicators?.value" :key="indicator.label">
    <div
      class="measurement-locator"
      :style="{
        left: `${
          100 * (((indicator.frame + 0.5) * clipModel.frameDuration) / clipModel.clipDuration)
        }%`,
        'background-color': `${indicator.color}`,
      }"
    />
    <div
      class="handle"
      :style="{
        left: `${
          100 * (((indicator.frame + 0.5) * clipModel.frameDuration) / clipModel.clipDuration)
        }%`,
        color: `${indicator.color}`,
      }"
    >
      {{ indicator.label }}
    </div>
  </template>

  <div
    v-for="[frameIndex, measurementDetails] in framesWithMeasurements"
    :key="frameIndex"
    class="measurement-locator"
    :style="`left: ${
      100 * (((frameIndex + 0.5) * clipModel.frameDuration) / clipModel.clipDuration)
    }%`"
  >
    <Tooltip placement="top">
      <div style="width: 12px; height: 12px" />

      <template #content>
        <div class="measurement-tooltip">
          <template v-for="{ id, name, value } of measurementDetails" :key="id">
            <strong>{{ name }}</strong>
            <div>{{ value }}</div>
          </template>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
} from "../../../../backend/src/measurements/measurement-display";
import Tooltip from "../../components/Tooltip.vue";
import { activeMeasurement } from "../../measurements/measurement-tool-state";
import { Study } from "../../utils/study-data";
import { ClipModel } from "../clip-model";

interface Props {
  study: Study;
  clipModel: ClipModel;
}

const props = defineProps<Props>();

const framesWithMeasurements = computed(() => {
  const result = new Map<number, { id: string; name: string; value: string }[]>();

  for (const measurement of props.study.measurements) {
    for (const measurementValue of measurement.values) {
      if (measurementValue.studyClipId !== props.clipModel.clip?.id) {
        continue;
      }

      if (measurementValue.frame === null) {
        continue;
      }

      if (!result.has(measurementValue.frame)) {
        result.set(measurementValue.frame, []);
      }

      result.get(measurementValue.frame)!.push({
        id: measurementValue.id,
        name: getMeasurementDisplayName(measurement, "unindexed"),
        value:
          getStudyMeasurementDisplayValue(
            { ...measurement, values: [measurementValue] },
            "unindexed"
          )?.fullText ?? "",
      });
    }
  }

  // Sort measurements by name
  for (const value of result.values()) {
    value.sort((a, b) => a.name.localeCompare(b.name));
  }

  return result;
});
</script>

<style scoped lang="scss">
.handle {
  top: 100%;
  position: absolute;
  transform: translateX(-50%);
  font-weight: bold;
  cursor: default;
}

.measurement-locator {
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 12px;
  border-radius: var(--border-radius);
  transform: translateX(-6px);
  position: absolute;
  background-color: #0f0;
}

.measurement-tooltip {
  display: grid;
  gap: 2px 12px;
  grid-template-columns: max-content auto;
}
</style>
