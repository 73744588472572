<template>
  <div class="settings-title">Measurement Sequences</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />
    <button
      class="add-sequence-button accented"
      data-testid="add-sequence"
      :disabled="!isSearchTermEmpty"
      @click="createSequence"
    >
      Create Sequence
    </button>
  </div>

  <div class="sequences">
    <OverlayScrollbar>
      <div class="sequence-list" data-testid="sequence-list">
        <MeasurementSequenceAndSteps
          v-for="(sequence, sequenceIndex) in filteredSequences"
          :key="sequence.id"
          :sequence="sequence"
          :sequence-index="sequenceIndex"
          @delete="deleteSequence(sequence)"
          @reorder="(sortableEvent) => reorderSequences(sequence, sortableEvent)"
          @save="
            sequencesToSave.add(sequence.id);
            saveSequencesDebounced();
          "
        />

        <div v-if="sequences.length === 0" class="no-sequences-text">
          No measurement sequences have been created
        </div>
        <div v-else-if="filteredSequences.length == 0" class="no-sequences-text">
          No results found
        </div>
      </div>
    </OverlayScrollbar>

    <OverlayScrollbar>
      <div class="side-pane">
        <div
          v-for="sequence in filteredSequences"
          :key="sequence.id"
          class="jump-to-sequence-button"
          :class="{ disabled: !filteredSequences.includes(sequence) }"
          @click="focusSequence(sequence.id)"
        >
          <template v-if="sequence.name.length > 0">
            {{ sequence.name }}
          </template>
          <i v-else> (no name) </i>

          <Tooltip
            content="Delete measurement sequence"
            style="margin-right: 4px; margin-left: auto"
          >
            <FontAwesomeIcon
              class="side-delete-button"
              icon="trash"
              size="sm"
              @click="deleteSequence(sequence)"
            />
          </Tooltip>
        </div>
      </div>
    </OverlayScrollbar>

    <ActivityOverlay v-if="activityText" :text="activityText" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { SortableEvent } from "sortablejs";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { MeasurementSequenceCreateResponseDto } from "../../../../backend/src/measurements/dto/measurement-sequence-create.dto";
import { MeasurementSequence as MeasurementSequenceEntity } from "../../../../backend/src/measurements/measurement-sequence.entity";
import ActivityOverlay from "../../components/ActivityOverlay.vue";
import FilterInput from "../../components/FilterInput.vue";
import OverlayScrollbar from "../../components/OverlayScrollbar.vue";
import Tooltip from "../../components/Tooltip.vue";
import { addNotification } from "../../utils/notifications";
import MeasurementSequenceAndSteps from "./MeasurementSequenceAndSteps.vue";

type MeasurementSequence = Pick<MeasurementSequenceEntity, "id" | "name" | "steps">;

const sequences = ref<MeasurementSequence[]>([]);

const searchTerm = ref("");
const isSearchTermEmpty = computed(() => searchTerm.value.trim() === "");

const activityText = ref("");

const filteredSequences = computed(() =>
  sequences.value.filter((seq) =>
    seq.name.toLowerCase().includes(searchTerm.value.toLowerCase().trim())
  )
);

async function createSequence(): Promise<void> {
  let response: AxiosResponse<MeasurementSequenceCreateResponseDto> | undefined = undefined;
  try {
    response = await axios.post<MeasurementSequenceCreateResponseDto>(
      `/api/measurement-sequences`,
      { name: "" }
    );
  } catch {
    addNotification({ type: "error", message: "Error adding measurement sequence" });
    return;
  }

  // Put the new sequence at the top
  sequences.value.splice(0, 0, {
    ...response.data,
    steps: [
      {
        id: uuidv4(),
        measurementTool: null,
        measurementName: null,
        enabledAssociatedMeasurements: [],
      },
    ],
  });
}

const sequencesToSave = new Set<string>();

async function saveSequences(): Promise<void> {
  try {
    const savePromises = [...sequencesToSave].map(async (sequenceId) => {
      const sequence = sequences.value.find((s) => s.id === sequenceId);
      if (sequence === undefined) {
        return;
      }

      return axios.patch(`/api/measurement-sequences/${sequence.id}`, {
        name: sequence.name,
        steps: sequence.steps,
      });
    });

    sequencesToSave.clear();

    await Promise.all(savePromises);
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving sequences" });
    return;
  }

  addNotification({ type: "info", message: "Measurement sequences saved" });
}

function focusSequence(id: string): void {
  document.getElementById(`measurement-sequence-${id}`)?.scrollIntoView();
  document.querySelector<HTMLInputElement>(`#measurement-sequence-name-${id} input`)?.focus();
}

async function fetchMeasurementSequences(): Promise<void> {
  activityText.value = "Loading";

  let response: AxiosResponse<MeasurementSequenceCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<MeasurementSequenceCreateResponseDto[]>(
      "/api/measurement-sequences"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading measurement sequences" });
    return;
  } finally {
    activityText.value = "";
  }

  sequences.value = response.data;
}

void fetchMeasurementSequences();

const saveSequencesDebounced = useDebounceFn(() => void saveSequences(), 500);

async function deleteSequence(sequence: { id: string; name: string }): Promise<void> {
  if (!confirm(`Are you sure you want to delete the sequence '${sequence.name}'`)) {
    return;
  }

  activityText.value = "Deleting sequence";

  try {
    await axios.delete(`/api/measurement-sequences/${sequence.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting sequence" });
    return;
  } finally {
    activityText.value = "";
  }

  // Remove the deleted sequence from the list
  const index = sequences.value.findIndex((g) => g.id === sequence.id);
  sequences.value.splice(index, 1);

  sequencesToSave.delete(sequence.id);
}

function getSequenceIdFromElement(element: HTMLElement): MeasurementSequence | undefined {
  return sequences.value.find(
    (sequence) =>
      document.getElementById(`measurement-sequence-${sequence.id}`)?.contains(element) === true
  );
}

function reorderSequences(sequence: MeasurementSequence, event: SortableEvent): void {
  if (event.oldIndex === undefined || event.newIndex === undefined) {
    return;
  }

  // Drag between measurement sequences
  if (event.from !== event.to) {
    const fromSequence = getSequenceIdFromElement(event.from);
    const toSequence = getSequenceIdFromElement(event.to);
    if (fromSequence === undefined || toSequence === undefined) {
      return;
    }

    const step = fromSequence.steps[event.oldIndex];
    toSequence.steps.splice(event.newIndex, 0, step);
    fromSequence.steps.splice(event.oldIndex, 1);

    sequencesToSave.add(fromSequence.id);
    sequencesToSave.add(toSequence.id);
  }

  // Drag in the same measurement sequence
  else {
    const [step] = sequence.steps.splice(event.oldIndex, 1);
    sequence.steps.splice(event.newIndex, 0, step);

    sequencesToSave.add(sequence.id);
  }

  void saveSequencesDebounced();
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.sequences {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  flex: 1;
}

.side-pane {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  max-width: 300px;
}

.sequence-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 12px;
}

.jump-to-sequence-button {
  justify-content: left;
  border-radius: var(--border-radius);
  width: 100%;
  font-weight: bold;
  color: var(--text-color-1);
  cursor: pointer;
  transition: color 100ms ease;
  padding: 4px 0;
  display: flex;

  &:hover {
    color: var(--text-color-2);

    .side-delete-button {
      color: var(--text-color-2);
    }
  }
}

.add-sequence-button {
  padding: 0 12px;
  margin-left: auto;
}

.no-sequences-text {
  padding-top: 20px;
  font-style: italic;
}
</style>
