<template>
  <div id="report-sentence-library" class="report-sentence-library">
    <div class="sentence-library-header">
      <b>{{ title }}</b>

      <FontAwesomeIcon icon="xmark" class="close-library" @click="emits('close')" />
    </div>

    <OverlayScrollbar style="max-height: calc(100vh - 210px)">
      <div v-for="(group, idx) in sentenceGroups" :key="group.id" class="sentence-group">
        <div class="sentence-group-name" :data-testid="`library-group-${idx}`">
          {{ group.name }}
        </div>

        <div class="sentences">
          <span
            v-for="(sentence, index) of group.sentences"
            :key="index"
            :data-testid="`library-group-${group.name}-sentence-${sentence}`"
            class="sentence"
            @click="onSentenceClick(sentence)"
          >
            {{ evaluateTextContainingCalculations(sentence, scope) }}
          </span>
        </div>
      </div>
    </OverlayScrollbar>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { computed } from "vue";
import { isSectionHorizontalDivider } from "../../../backend/src/reporting/report-structure";
import OverlayScrollbar from "../components/OverlayScrollbar.vue";
import { Study, StudyReport } from "../utils/study-data";
import { evaluateTextContainingCalculations } from "./report-calculation";
import { getCalculationScope } from "./report-calculation-scope";

interface Props {
  study: Study;
  report: StudyReport;
  focusedSection: { id: string; focusTextarea: () => void; insertText: (text: string) => void };
  sentenceGroups: { id: string; name: string; sentences: string[] }[];
  analyticsEnabled: boolean;
}

interface Emits {
  (name: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const scope = computed(() => getCalculationScope(props.study, props.report));

const section = computed(() => {
  const result = props.report.reportTemplateVersion.structure.sections.find(
    (s) => s.id === props.focusedSection.id
  );

  if (result === undefined) {
    throw Error(`Section with ${props.focusedSection.id} is not present in the report template`);
  }

  return result;
});

const title = computed(() => {
  if (section.value.name !== "") {
    return section.value.name;
  }

  const structure = props.report.reportTemplateVersion.structure;

  // When the selected section has no name, look back up through the previous sections for a heading
  // and use that instead
  for (let i = structure.sections.indexOf(section.value) - 1; i >= 0; i--) {
    if (
      structure.sections[i].type === "heading" &&
      !isSectionHorizontalDivider(structure.sections[i])
    ) {
      return structure.sections[i].name;
    }
  }

  // Fallback heading
  return "Sentence Library";
});

async function onSentenceClick(sentence: string): Promise<void> {
  props.focusedSection.insertText(`${evaluateTextContainingCalculations(sentence, scope.value)} `);

  // Update analytics for the report
  if (props.analyticsEnabled) {
    await axios.patch(
      `/api/studies/${props.study.id}/reports/${props.report.id}/increment-analytics`,
      { analyticsSentenceLibraryClickCount: 1 }
    );
  }
}
</script>

<style scoped lang="scss">
.report-sentence-library {
  color: white;
  width: 300px;
}

.sentence-library-header {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 12px 8px;
  font-weight: bold;
  font-size: 1.2em;
  border-bottom: 1px solid var(--border-color-1);
}

.close-library {
  color: var(--accent-color-1);
  transition: color 100ms ease;
  cursor: pointer;

  &:hover {
    color: var(--accent-color-2);
  }
}

.sentence-group {
  display: flex;
  flex-direction: column;
}

.sentence-group-name {
  background-color: var(--bg-color-4);
  padding: 4px 8px;
  color: var(--accent-color-2);
  font-weight: bold;
  border: 1px solid var(--border-color-1);
  border-left: none;
  border-right: none;
}

.sentences {
  display: flex;
  flex-direction: column;
  margin: 4px 12px 4px 4px;
}

.sentence {
  padding: 4px 4px;
  border-radius: var(--border-radius);
  color: var(--text-color-1);
  transition:
    color 100ms ease,
    background-color 100ms ease;
  cursor: pointer;

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-4);
  }
}
</style>
