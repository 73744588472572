import { defineStore } from "pinia";
import type { Volume } from "three/examples/jsm/Addons.js";
import type { Study, StudyClip } from "../utils/study-data";
import { loadNrrdIntoThreeVolume } from "./ct/ct-loader";
import { fetchStudyClipImageData } from "./study-clip-image-data";

/**
 * An array of HTMLImageElements that represent the frames of a clip, which are progressively loaded
 * from the webp-packed file as they are requested. An undefined value in the array means that the
 * image for that frame is not yet loaded.
 */
type ClipFrames = (HTMLImageElement | undefined)[];

/**
 * The image data that is associated with a study load. This includes the clip frames and CT series
 * NRRD data processed into a Three.js `Volume` object.
 */
interface StudyImageData {
  clipWebpData: Record<string, { frames: ClipFrames; destroy: () => void } | undefined>;
  seriesVolumes: Record<string, Volume | undefined>;
}

/**
 * A store that holds the image data that can be used in a study view. This can include multiple
 * studies as multiple studies image data can be accessed in the study comparison view. This store
 * needs to be manually emptied via the `unloadImageData` method when the study view tears down.
 */
export const useStudyViewImageData = defineStore("study-view", () => {
  const dataByStudyId: Record<string, StudyImageData> = {};

  // Initialize the empty data records for a study if they don't exist
  function initializeStudyRecord(studyId: string): void {
    dataByStudyId[studyId] ??= {
      clipWebpData: {},
      seriesVolumes: {},
    };
  }

  function getClipFrames(study: Study, clip: StudyClip): ClipFrames {
    initializeStudyRecord(study.id);

    const alreadyLoadedData = dataByStudyId[study.id].clipWebpData[clip.id];
    if (alreadyLoadedData !== undefined) {
      return alreadyLoadedData.frames;
    }

    const loadedFrames: (HTMLImageElement | undefined)[] = [];

    const controller = new AbortController();
    const { signal: abortSignal } = controller;

    function destroy(): void {
      // Empty the frames array manually to help the garbage collector know that these images are no
      // longer needed
      loadedFrames.fill(undefined);

      // Cancel the fetch of clip image data if it is still ongoing
      controller.abort();
    }

    dataByStudyId[study.id].clipWebpData[clip.id] = {
      frames: loadedFrames,
      destroy,
    };

    void fetchStudyClipImageData(study, clip, abortSignal, loadedFrames);

    return loadedFrames;
  }

  async function getThreeVolumeForSeries(studyId: string, seriesId: string): Promise<Volume> {
    initializeStudyRecord(studyId);

    const alreadyLoadedVolume = dataByStudyId[studyId].seriesVolumes[seriesId];
    if (alreadyLoadedVolume !== undefined) {
      return alreadyLoadedVolume;
    }

    const volume = await loadNrrdIntoThreeVolume(studyId, seriesId);
    dataByStudyId[studyId].seriesVolumes[seriesId] = volume;

    return volume;
  }

  function unloadImageData(): void {
    for (const studyId of Object.getOwnPropertyNames(dataByStudyId)) {
      Object.values(dataByStudyId[studyId].clipWebpData).forEach((c) => c?.destroy());
      delete dataByStudyId[studyId];
    }
  }

  return { getClipFrames, getThreeVolumeForSeries, unloadImageData };
});
