<template>
  <Modal title="View Report" centered @header-button-click="emits('close')">
    <div class="report-viewer">
      <ReportPDFViewer :study-id="studyId" :study-report-id="selectedReportId" />
      <div v-if="completedReports.length > 1" class="footer">
        <StudyReportsDropdown v-model="selectedReportId" :reports="completedReports" />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Modal from "../components/Modal.vue";
import { getSortedReports, StudyReportDescription } from "@/utils/study-data";
import ReportPDFViewer from "./ReportPDFViewer.vue";
import StudyReportsDropdown from "./StudyReportsDropdown.vue";

interface Props {
  studyId: string;
  reports: StudyReportDescription[];
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const completedReports = computed(() =>
  getSortedReports(props.reports.filter((report) => report.completedAt !== null))
);

const selectedReportId = ref(completedReports.value[0].id);
</script>

<style scoped lang="scss">
.report-viewer {
  width: 850px;
  max-width: 90vw;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  place-items: stretch;
}

.footer {
  align-self: flex-end;
  padding-top: 8px;
}
</style>
