export enum ChartDataType {
  StudiesTotal = "studiesTotal",
  UsersActive = "usersActive",
  AuditAction = "auditAction",
  ReportingTime = "reportingTime",
  ReportingClicks = "reportingClicks",
  ReportingSentenceLibraryClicks = "reportingSentenceLibraryClicks",
  ReportingKeystrokes = "reportingKeystrokes",
}

export enum ChartSampleFrequency {
  Day = "day",
  Week = "week",
  Month = "month",
}
