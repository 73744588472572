<template>
  <div ref="rootElement" class="draggable-list">
    <slot />
  </div>
</template>

<script setup lang="ts">
import Sortable, { type SortableEvent } from "sortablejs";
import { onMounted, onUnmounted, ref, watch } from "vue";

interface Props {
  enabled?: boolean;
  group?: string;
}

interface Emits {
  (event: "reorder", details: SortableEvent): void;
}

const props = withDefaults(defineProps<Props>(), { enabled: true, group: undefined });
const emits = defineEmits<Emits>();

const rootElement = ref<HTMLElement>();
let sortable: Sortable | undefined = undefined;

function createSortable(): void {
  destroySortable();

  if (!rootElement.value) {
    return;
  }

  sortable = Sortable.create(rootElement.value, {
    animation: 200,
    handle: ".drag-handle",
    group: props.group,

    onEnd(event: SortableEvent): void {
      // SortableJS' DOM mutations can cause confusion in VueJS when moving items between different
      // sortables that have the same group. This explicit removal of the element being moved
      // appears to help because otherwise SortableJS moves it to the new sortable and VueJS also
      // renders another one once the data mutation on the target list has been applied, resulting
      // in the moved item appearing twice in the target sortable. Should we look into v-draggable?
      //
      // Related issues:
      //
      // - https://github.com/SortableJS/Sortable/issues/546
      // - https://github.com/SortableJS/Sortable/pull/2195
      if (event.from !== event.to) {
        event.item.remove();
      }

      emits("reorder", event);
    },
  });
}

function destroySortable(): void {
  sortable?.destroy();
  sortable = undefined;
}

watch(
  () => [props.enabled],
  () => sortable?.option("disabled", !props.enabled)
);

onMounted(createSortable);
onUnmounted(destroySortable);
</script>

<style scoped lang="scss">
.draggable-list {
  display: contents;

  :deep(.sortable-ghost) {
    opacity: 0.5;
  }
}
</style>
