<template>
  <div class="settings-title">Study List Settings</div>
  <div class="field">
    <p>Toggle the columns that should be shown on the study list.</p>

    <p>
      The 'Trainee', 'Technician', and 'Physician' fields can be given custom labels that will be
      shown on the study list and on reports. You can also select a role to be associated with these
      fields which will cause only users with that role to be available for selection.
    </p>
  </div>

  <template v-for="optionalColumn of optionalColumns" :key="optionalColumn.value">
    <div class="field">
      <div class="toggle">
        <ToggleSwitch
          :model-value="isStudyListColumnVisible(optionalColumn.value)"
          :data-testid="`${optionalColumn.value}-visible-toggle`"
          @update:model-value="
            (newValue) => updateStudyListHiddenColumns(optionalColumn.value, newValue)
          "
        />
        <strong>{{ optionalColumn.text }}</strong>
      </div>
    </div>
  </template>

  <template v-for="role of optionalRoles" :key="role.value">
    <div class="field">
      <div class="toggle">
        <ToggleSwitch
          :model-value="isStudyListColumnVisible(role.value)"
          :data-testid="`${role.value}-visible-toggle`"
          @update:model-value="(newValue) => updateStudyListHiddenColumns(role.value, newValue)"
        />
        <strong class="capitalize">{{ role.value }}</strong>
        <div v-if="isStudyListColumnVisible(role.value)" style="display: flex; gap: 16px">
          <input
            v-model="role.label"
            :data-testid="`${role.value}-label-input`"
            @update:model-value="updateCurrentTenantDebounced"
          />
          <DropdownWidget
            v-model="role.id"
            :items="rolesDropdownItems"
            style="max-width: 200px"
            :data-testid="`${role.value}-role-id-dropdown`"
            maxlength="100"
            @update:model-value="updateCurrentTenant"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { addNotification } from "@/utils/notifications";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, reactive } from "vue";
import { StudyListColumn } from "../../../backend/src/tenants/study-list-column";
import { currentTenant } from "../auth/current-session";
import DropdownWidget from "../components/DropdownWidget.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import { isStudyListColumnVisible } from "../utils/study-data";
import { useUserRoleList } from "../utils/user-roles-list";

const userRoleList = useUserRoleList();

const rolesDropdownItems = computed(() => [
  { value: "", text: "No role selected" },
  ...userRoleList.value.map((r) => ({
    value: r.id,
    text: `${r.isEnabled ? "" : "[Disabled] "}${r.name}`,
  })),
]);

const traineeRoleId = computed({
  get() {
    return currentTenant.traineeRoleId ?? "";
  },
  set(roleId: string) {
    currentTenant.traineeRoleId = roleId === "" ? null : roleId;
  },
});

const technicianRoleId = computed({
  get() {
    return currentTenant.technicianRoleId ?? "";
  },
  set(roleId: string) {
    currentTenant.technicianRoleId = roleId === "" ? null : roleId;
  },
});

const physicianRoleId = computed({
  get() {
    return currentTenant.physicianRoleId ?? "";
  },
  set(roleId: string) {
    currentTenant.physicianRoleId = roleId === "" ? null : roleId;
  },
});

const optionalColumns = [
  { value: StudyListColumn.PatientID, text: "Patient ID" },
  { value: StudyListColumn.PatientBirthdate, text: "Patient Birthdate" },
  { value: StudyListColumn.Institute, text: "Institute" },
  { value: StudyListColumn.PerformedBy, text: "Performed By" },
  { value: StudyListColumn.StudyType, text: "Study Type" },
];

const optionalRoles = reactive([
  {
    value: StudyListColumn.Trainee,
    id: traineeRoleId,
    label: currentTenant.traineeLabel,
  },
  {
    value: StudyListColumn.Technician,
    id: technicianRoleId,
    label: currentTenant.technicianLabel,
  },
  {
    value: StudyListColumn.Physician,
    id: physicianRoleId,
    label: currentTenant.physicianLabel,
  },
]);

async function updateCurrentTenant(): Promise<void> {
  try {
    await axios.patch(`/api/tenants/current`, {
      traineeLabel: optionalRoles[0].label,
      technicianLabel: optionalRoles[1].label,
      physicianLabel: optionalRoles[2].label,
      traineeRoleId: currentTenant.traineeRoleId === "" ? null : currentTenant.traineeRoleId,
      technicianRoleId:
        currentTenant.technicianRoleId === "" ? null : currentTenant.technicianRoleId,
      physicianRoleId: currentTenant.physicianRoleId === "" ? null : currentTenant.physicianRoleId,
      studyListHiddenColumns: currentTenant.studyListHiddenColumns,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating study list settings" });
    return;
  }

  addNotification({ type: "info", message: "Updated study list settings" });
}

const updateCurrentTenantDebounced = useDebounceFn(() => {
  void updateCurrentTenant();
}, 1000);

async function updateStudyListHiddenColumns(
  column: StudyListColumn,
  value: boolean
): Promise<void> {
  if (!value) {
    currentTenant.studyListHiddenColumns.push(column);
  } else {
    currentTenant.studyListHiddenColumns = currentTenant.studyListHiddenColumns.filter(
      (col) => col !== column
    );
  }

  await updateCurrentTenant();
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 200px;
}

.toggle {
  display: grid;
  grid-template-columns: auto 150px 1fr;
  grid-auto-rows: 16px;
  gap: 16px;
  align-items: center;
}
</style>
