<template>
  <Tooltip content="Click and hold to dictate">
    <div
      class="transcription-button"
      :class="{ active: isTranscriptionActive }"
      @mousedown="startTranscription"
    >
      <FontAwesomeIcon icon="microphone" size="lg" />
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useEventListener } from "@vueuse/core";
import { computed, onUnmounted, ref, type Ref } from "vue";
import { currentUser } from "../auth/current-session";
import Tooltip from "../components/Tooltip.vue";
import { transcribeAudioStreamToText } from "../transcription/transcription";

interface Emits {
  (event: "transcription-output", text: string, isPartial: boolean): void;
}

const emits = defineEmits<Emits>();

let latestAbortController: Ref<AbortController | null> = ref(null);

// The presence of an abort controller is used to indicate whether there is a transcription active
const isTranscriptionActive = computed(() => latestAbortController.value !== null);

async function startTranscription(e: Event): Promise<void> {
  stopTranscription();

  // Prevent text selection while transcription is in progress
  e.preventDefault();

  // Create abort controller for the new transcription
  const thisAbortController = new AbortController();

  latestAbortController.value = thisAbortController;

  // Maximum duration of a single transcription is 5 minutes
  const maxDurationInSeconds = 5 * 60;

  try {
    for await (const { text, isPartial } of transcribeAudioStreamToText(
      currentUser.isSpokenPunctuationEnabled,
      maxDurationInSeconds,
      latestAbortController.value.signal
    )) {
      emits("transcription-output", text, isPartial);
    }
  } finally {
    if (latestAbortController.value === thisAbortController) {
      latestAbortController.value = null;
    }
  }
}

// Stop transcription on mouseup anywhere on the page, which means the user doesn't have to keep the
// mouse still while transcription is in progress
useEventListener(document, "mouseup", stopTranscription);

function stopTranscription() {
  latestAbortController.value?.abort();
  latestAbortController.value = null;
}

onUnmounted(stopTranscription);
</script>

<style scoped lang="scss">
.transcription-button {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: var(--report-text-color-1);

  &.active svg {
    color: red;
  }

  &:not(.active) {
    :hover {
      color: var(--report-text-color-2);
    }
  }
}
</style>
./transcription
