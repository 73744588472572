<template>
  <Modal title="Delete Clip" :activity-text="activityText" @header-button-click="closeModal">
    <div class="study-clip-delete-modal">
      <img
        :src="getStudyClipImageDataUrl(study, studyClip, StudyClipImageDataType.Thumbnail) ?? ''"
      />

      <div v-if="studyClipMeasurements.length > 0">
        This clip contains the following measurement values:
        <ul class="study-clip-measurements">
          <li v-for="measurement of studyClipMeasurements" :key="measurement">
            {{ measurement }}
          </li>
        </ul>
      </div>

      <Checkbox v-model="approved" data-testid="delete-clip-approve-checkbox">
        I approve deletion of this clip
      </Checkbox>

      <button
        class="accented"
        :disabled="!approved"
        data-testid="delete-clip-button"
        @click="deleteClip"
      >
        Delete Clip
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, ref } from "vue";
import {
  getMeasurementDisplayName,
  getStudyMeasurementDisplayValue,
} from "../../../backend/src/measurements/measurement-display";
import { StudyClipImageDataType } from "../../../backend/src/studies/study-clip-image-data-type";
import Checkbox from "../components/Checkbox.vue";
import Modal from "../components/Modal.vue";
import { addNotification } from "../utils/notifications";
import type { Study, StudyClip } from "../utils/study-data";
import { getStudyClipImageDataUrl } from "./study-clip-image-data";

interface Props {
  study: Study;
  studyClip: StudyClip;
}

interface Emits {
  (event: "close"): void;
  (event: "deleted"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const activityText = ref("");
const approved = ref(false);

// The list of measurements on this clip
const studyClipMeasurements = computed(() => {
  const result = [];

  for (const measurement of props.study.measurements) {
    for (const measurementValue of measurement.values) {
      if (measurementValue.studyClipId === props.studyClip.id) {
        const name = getMeasurementDisplayName(measurement.name, "unindexed");
        const value = getStudyMeasurementDisplayValue(
          {
            name: measurement.name,
            values: [measurementValue],
            customUnit: measurement.customUnit,
          },
          "unindexed"
        )?.fullText;

        result.push(`${name}: ${value}`);
      }
    }
  }

  return result;
});

async function deleteClip(): Promise<void> {
  activityText.value = "Deleting";

  try {
    await axios.delete(`/api/studies/${props.study.id}/clips/${props.studyClip.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting clip" });
    return;
  } finally {
    activityText.value = "";
  }

  emits("deleted");
  addNotification({ type: "info", message: "Deleted clip" });

  closeModal();
}

function closeModal(): void {
  emits("close");
}
</script>

<style scoped lang="scss">
.study-clip-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

img {
  border-radius: var(--border-radius);
}

.study-clip-measurements {
  max-height: 200px;
  overflow: auto;
}

button {
  margin-top: 8px;
}
</style>
