<template>
  <Modal
    :title="formatDicomName(patientName)"
    top-position="10vh"
    style="padding: 0; gap: 0"
    @header-button-click="emits('close')"
  >
    <div class="comparison-background">
      <div class="comparison-area">
        <ComparedStudyView
          v-model:study-id="selectedLeftStudyId"
          :current-study-id="initialRightStudyId"
          :patient-studies="patientStudies"
          :playback-controller="playbackController"
          :clips-grid-items="clipsGridItems"
          :selected-clip-ids="selectedClipIds"
          :grid-item-index="0"
        />

        <ComparedStudyView
          v-model:study-id="selectedRightStudyId"
          :current-study-id="initialRightStudyId"
          :patient-studies="patientStudies"
          :playback-controller="playbackController"
          :clips-grid-items="clipsGridItems"
          :selected-clip-ids="selectedClipIds"
          :grid-item-index="1"
        />
      </div>
    </div>

    <div class="bottom-toolbar">
      <Tooltip
        :content="`${isClipSyncEnabled ? 'Disable' : 'Enable'} synchronized playback`"
        shortcut="C"
      >
        <button
          class="outline-when-active"
          :class="{ active: isClipSyncEnabled }"
          data-testid="comparison-clip-sync-toggle"
          @click="isClipSyncEnabled = !isClipSyncEnabled"
        >
          <FontAwesomeIcon :icon="isClipSyncEnabled ? 'lock' : 'unlock'" />
        </button>
      </Tooltip>

      <PlaybackSpeedSlider v-model="playbackSpeedFactor" />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { formatDicomName } from "@/../../backend/src/shared/dicom-helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import type { PatientStudiesGetManyResponseDto } from "../../../../backend/src/studies/dto/patient-studies-get-many.dto";
import Modal from "../../components/Modal.vue";
import Tooltip from "../../components/Tooltip.vue";
import { onKeyboardShortcut } from "../../utils/keyboard-shortcut";
import { createClipsGridItemsArray } from "../clip-viewer/clips-grid-item";
import { createRegularClipPlaybackController } from "../clip-viewer/regular-clip-playback";
import PlaybackSpeedSlider from "../PlaybackSpeedSlider.vue";
import ComparedStudyView from "./ComparedStudyView.vue";

interface Props {
  patientName: string[];
  patientStudies: PatientStudiesGetManyResponseDto;
  initialLeftStudyId: string;
  initialRightStudyId: string;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

// The study they opened the comparison modal from is the initial right study
const selectedLeftStudyId = ref<string | null>(props.initialLeftStudyId);
const selectedRightStudyId = ref<string | null>(props.initialRightStudyId);

const clipsGridItems = createClipsGridItemsArray(2);

const isClipSyncEnabled = ref(false);
const playbackSpeedFactor = ref(1);

const selectedClipIds = ref<string[]>([]);

const playbackController = createRegularClipPlaybackController(
  clipsGridItems,
  playbackSpeedFactor,
  isClipSyncEnabled,
  { isClipInModal: true }
);

onKeyboardShortcut(
  "c",
  () => {
    isClipSyncEnabled.value = !isClipSyncEnabled.value;
  },
  { isAllowedInModal: true }
);
</script>

<style scoped lang="scss">
.comparison-background {
  background-color: var(--border-color-1);
}

.comparison-area {
  height: 75vh;
  width: 90vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1px 0px;
  gap: 1px;
}

.bottom-toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  background-color: var(--bg-color-1);
  color: var(--accent-color-1);
  padding: 0 8px;
}
</style>
