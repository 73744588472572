export const RELEASE_SHA = import.meta.env.VITE_RELEASE_SHA ?? "unknown";

export const RELEASE_DATE = import.meta.env.VITE_RELEASE_DATE ?? "YYYY-MM-DD";

export const BASE_URL = import.meta.env.VITE_BASE_URL ?? "/";

export const IS_DEV_MODE = import.meta.env.DEV;

export const DATADOG_SITE = import.meta.env.VITE_DATADOG_SITE;

export const DATADOG_RUM_APPLICATION_ID = import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID;

export const DATADOG_RUM_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN;
