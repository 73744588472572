<template>
  <UserDropdown
    :model-value="study[props.field] ?? ''"
    :allow-empty="isUnassignedAllowed"
    :required-role-id="requiredRoleId"
    empty-text="Not assigned"
    :disabled="!hasStudyUpdatePermission || isUpdating"
    class="study-user-dropdown"
    @update:model-value="changeUser"
  />
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, ref } from "vue";
import { hasStudyUpdatePermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import { addNotification } from "../utils/notifications";
import type { Study } from "../utils/study-data";
import UserDropdown from "./UserDropdown.vue";

interface Props {
  study: Study;
  field: "assignedUserId" | "physicianUserId" | "technicianUserId" | "traineeUserId";
  requiredRoleId?: string;
}

const props = defineProps<Props>();

const isUpdating = ref(false);

const isUnassignedAllowed = computed(
  () =>
    props.field !== "assignedUserId" ||
    !currentTenant.isStudyAssigneeRequired ||
    props.study[props.field] === null
);

async function changeUser(newUserId: string): Promise<void> {
  isUpdating.value = true;

  const study = props.study;
  study[props.field] = newUserId;

  const routeName = props.field === "assignedUserId" ? "assign" : "participants";

  try {
    await axios.patch(`/api/studies/${study.id}/${routeName}`, { [props.field]: newUserId });
  } catch {
    addNotification({ type: "error", message: `Failed changing selected user` });
    return;
  } finally {
    isUpdating.value = false;
  }

  addNotification({ type: "info", message: "Changed selected user" });
}
</script>

<style scoped lang="scss">
.study-user-dropdown {
  border: none;

  &:hover {
    background-color: var(--bg-color-3);
  }
}
</style>
