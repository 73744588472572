<template>
  <div
    :id="`report-sentence-group-${group.id}`"
    ref="rootElement"
    class="report-sentence-group"
    data-testid="report-sentence-group"
  >
    <div :id="`report-sentence-group-name-${group.id}`" class="name">
      <input
        :value="group.name"
        placeholder="Sentence group title"
        :data-testid="`report-sentence-group-${groupIndex}-name`"
        @input="updateName($event)"
        @keypress.enter="onNameEnterKeyPressed"
        @keydown.arrow-down="focusSentence(0)"
      />

      <Tooltip content="Duplicate group">
        <button
          class="side-button"
          tabindex="-1"
          :data-testid="`report-sentence-group-${groupIndex}-duplicate`"
          @click="emits('duplicate')"
        >
          <FontAwesomeIcon icon="copy" size="sm" />
        </button>
      </Tooltip>

      <Tooltip content="Delete sentence group">
        <button
          tabindex="-1"
          class="side-button"
          :data-testid="`report-sentence-group-${groupIndex}-delete`"
          @click="emits('delete')"
        >
          <FontAwesomeIcon icon="trash" size="sm" />
        </button>
      </Tooltip>
    </div>

    <DraggableList
      v-if="isRootElementVisible"
      group="report-sentences"
      @reorder="emits('reorder', $event)"
    >
      <div v-for="(sentence, index) in group.sentences" :key="sentence.id" class="sentence">
        <div class="drag-handle">
          <FontAwesomeIcon icon="grip-lines-vertical" />
        </div>

        <input
          :id="`sentence-${index}`"
          :value="group.sentences[index].text"
          placeholder="Enter sentence"
          class="sentence-input"
          autocomplete="off"
          :data-testid="`report-sentence-group-${groupIndex}-sentence-${index}`"
          @input="updateSentence(index, $event)"
          @keypress.enter="onSentenceEnterKeyPressed(index)"
          @keydown.arrow-down="focusSentence(index + 1)"
          @keydown.arrow-up="focusSentence(index - 1)"
          @keydown.backspace="onSentenceBackspace(index, $event)"
          @focusin="focusedFieldIndex = index"
          @focusout="focusedFieldIndex = undefined"
        />

        <Tooltip
          v-if="isCalculation(group.sentences[index].text)"
          :content="`${
            getTextFieldCalculationErrors(group.sentences[index].text, getEmptyReportStructure()) ??
            'The calculation is valid'
          }. Click to view documentation.`"
          max-width="400px"
        >
          <RouterLink
            :to="{ name: 'settings-reporting-calculations' }"
            class="calculation-status"
            target="_blank"
          >
            <button class="side-button calculation-status" tabindex="-1" disabled>
              <FontAwesomeIcon
                v-if="
                  getTextFieldCalculationErrors(
                    group.sentences[index].text,
                    getEmptyReportStructure()
                  )
                "
                class="invalid"
                icon="xmark"
              />
              <FontAwesomeIcon v-else icon="check" class="valid" />
            </button>
          </RouterLink>
        </Tooltip>

        <Tooltip content="Duplicate sentence" placement="top">
          <button
            class="side-button"
            tabindex="-1"
            :data-testid="`report-sentence-group-${groupIndex}-sentence-${index}-duplicate`"
            @click="addSentence(index + 1, sentence.text)"
          >
            <FontAwesomeIcon icon="copy" size="sm" />
          </button>
        </Tooltip>

        <Tooltip content="Delete sentence" placement="top">
          <button
            class="side-button"
            tabindex="-1"
            :data-testid="`report-sentence-group-${groupIndex}-sentence-${index}-delete`"
            @click="deleteSentence(index)"
          >
            <FontAwesomeIcon icon="trash" size="sm" />
          </button>
        </Tooltip>
      </div>
    </DraggableList>

    <div
      v-else
      class="sentences-placeholder"
      :style="{ minHeight: `${group.sentences.length * 28}px` }"
    />

    <Tooltip content="Add sentence" placement="left" style="margin-left: auto">
      <button
        class="add-sentence-button"
        tabindex="-1"
        :data-testid="`report-sentence-group-${groupIndex}-add-sentence`"
        @click="addSentence(group.sentences.length)"
      >
        +
      </button>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke, useIntersectionObserver } from "@vueuse/core";
import type { SortableEvent } from "sortablejs";
import { v4 as uuidv4 } from "uuid";
import { nextTick, ref } from "vue";
import { getEmptyReportStructure } from "../../../backend/src/reporting/report-structure";
import DraggableList from "../components/DraggableList.vue";
import Tooltip from "../components/Tooltip.vue";
import { getTextFieldCalculationErrors, isCalculation } from "../reporting/report-calculation";

interface ReportSentenceGroupWithIds {
  id: string;
  name: string;
  sentences: { id: string; text: string }[];
}

interface Props {
  group: ReportSentenceGroupWithIds;
  groupIndex: number;
}

interface Emits {
  (name: "duplicate"): void;
  (name: "delete"): void;
  (name: "reorder", event: SortableEvent): void;
  (name: "save"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const rootElement = ref<HTMLDivElement | null>(null);
const isRootElementVisible = ref(false);

const focusedFieldIndex = ref<number | null>();

useIntersectionObserver(
  rootElement,
  ([{ isIntersecting }]) => (isRootElementVisible.value = isIntersecting),
  { rootMargin: "600px" }
);

function focusSentence(index: number): void {
  if (index === -1) {
    rootElement.value
      ?.querySelector<HTMLInputElement>(`#report-sentence-group-name-${props.group.id} input`)
      ?.focus();
    return;
  }

  rootElement.value?.querySelector<HTMLInputElement>(`#sentence-${index}`)?.focus();
}

function updateName(event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.group.name = event.target.value;

  emits("save");
}

function updateSentence(index: number, event: Event): void {
  if (!(event.target instanceof HTMLInputElement)) {
    return;
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.group.sentences[index].text = event.target.value;

  emits("save");
}

async function deleteSentence(index: number): Promise<void> {
  // eslint-disable-next-line vue/no-mutating-props
  props.group.sentences.splice(index, 1);

  emits("save");

  await nextTick();
  focusSentence(index);
}

async function onNameEnterKeyPressed(): Promise<void> {
  if (props.group.sentences.length === 0) {
    await addSentence(0, "");
  } else {
    focusSentence(0);
  }
}

async function onSentenceEnterKeyPressed(index: number): Promise<void> {
  await addSentence(index + 1);
}

async function addSentence(index: number, text = ""): Promise<void> {
  // eslint-disable-next-line vue/no-mutating-props
  props.group.sentences.splice(index, 0, { id: uuidv4(), text });

  // Select the new sentence field
  await nextTick();
  rootElement.value?.querySelector<HTMLInputElement>(`#sentence-${index}`)?.focus();

  emits("save");
}

function onSentenceBackspace(index: number, _event: Event): void {
  if (props.group.sentences[index].text === "") {
    document.addEventListener(
      "keyup",
      () => {
        focusSentence(index - 1);

        // eslint-disable-next-line vue/no-mutating-props
        props.group.sentences.splice(index, 1);
      },
      { once: true }
    );
  }
}

onKeyStroke("Escape", () => (focusedFieldIndex.value = undefined));
</script>

<style scoped lang="scss">
.report-sentence-group {
  display: flex;
  flex-direction: column;
  transition: filter 100ms ease;

  &:focus-within {
    filter: brightness(115%);

    .side-highlight {
      opacity: 1;
    }
  }
}

.sentences-placeholder {
  background-color: var(--bg-color-2);
  border: 1px solid var(--border-color-1);
  border-top: none;
  border-bottom-left-radius: var(--border-radius);
}

.name {
  display: grid;
  grid-template-columns: 1fr auto auto;
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border: 1px solid var(--border-color-1);
  overflow: hidden;

  &:hover,
  &:focus-within {
    .side-button {
      visibility: visible;
      opacity: 1;
    }
  }

  input {
    font-weight: bold;
    padding: 4px 8px;
    height: 20px;
    border-radius: 0;
    border: none;

    &::placeholder {
      font-weight: normal;
    }
  }
}

.side-button {
  height: 28px;
  width: 24px;
  background-color: var(--bg-color-3);
  opacity: 0;
  transition: opacity 100ms ease;
  border-radius: 0 !important;
}

.sentence {
  background-color: var(--bg-color-2);
  display: grid;
  grid-template-columns: 1.8em 1fr auto auto auto;
  border: 1px solid var(--border-color-1);
  border-top: none;

  &:hover,
  &:focus-within {
    .side-button,
    .calculation-status svg {
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--bg-color-3);
  }

  &:focus-within {
    background-color: var(--bg-color-4);
  }

  // Make the input full size. There must be a better way?
  :deep(> :nth-child(2)) {
    display: flex;

    > div:first-child {
      flex: 1;
      display: flex;
    }
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:last-child {
    border-radius: 0 0 0 var(--border-radius);
  }
}

.drag-handle {
  color: var(--accent-color-1);
  transition: color 100ms ease;
  display: grid;
  place-content: center;
  cursor: grab;
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius) 0 0 var(--border-radius);

  &:hover {
    color: var(--accent-color-2);
  }
}

.sentence-input {
  flex: 1;
  padding: 4px 8px;
  height: 20px;
  border-radius: 0 !important;
  border: none;
  background: none;
}

.add-sentence-button {
  margin-top: 2px;
  height: 28px;
  width: 28px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.calculation-status {
  svg {
    opacity: 0;
    transition: opacity 100ms ease;

    &.valid {
      color: green;
    }

    &.invalid {
      color: red;
    }
  }
}
</style>
