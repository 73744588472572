<template>
  <Tooltip content="More actions" :visible="!isDropdownOpen">
    <Popper
      class="study-dropdown"
      placement="bottom-end"
      :offset-distance="2"
      :interactive="false"
      @open="isDropdownOpen = true"
      @close="isDropdownOpen = false"
    >
      <div
        class="icon-button"
        :class="{ active: isDropdownOpen }"
        :data-testid="`show-study-dropdown-${formatDicomName(study.patientName)}`"
      >
        <FontAwesomeIcon icon="ellipsis" size="lg" fixed-width />
      </div>

      <template #content>
        <div class="study-dropdown-content">
          <div
            v-if="hasStudyUpdatePatientIdentityPermission"
            class="study-dropdown-option"
            :data-testid="`patient-identity-update-btn-${formatDicomName(study.patientName)}`"
            @click="isPatientIdentityUpdateModalVisible = true"
          >
            Update patient identity
          </div>

          <div
            v-if="hasHL7MessageSendPermission && study.reports.some((report) => report.isSigned)"
            class="study-dropdown-option"
            @click="
              hl7StudyReportToSend = getLatestReport(
                study.reports.filter((report) => report.isSigned)
              )
            "
          >
            Send HL7 message
          </div>

          <div
            v-if="hasStudyReprocessPermission"
            class="study-dropdown-option"
            @click="reprocessStudy(study)"
          >
            Reprocess study
          </div>

          <div
            class="study-dropdown-option"
            :data-testid="`download-dicom-btn-${formatDicomName(study.patientName)}`"
            @click="isDICOMDownloadModalVisible = true"
          >
            Download DICOMs
          </div>

          <div
            v-if="hasDicomEndpointSendPermission && currentTenant.hasDicomEndpoints"
            class="study-dropdown-option"
            :data-testid="`study-send-btn-${formatDicomName(study.patientName)}`"
            @click="isDicomEndpointSendModalVisible = true"
          >
            Send to DICOM modality
          </div>

          <div
            v-if="hasAuditLogViewPermission"
            class="study-dropdown-option"
            :data-testid="`view-audit-logs-btn-${formatDicomName(study.patientName)}`"
            @click="isAuditLogModalVisible = true"
          >
            View study audit logs
          </div>

          <template v-if="webhookIntegrations.length > 0">
            <div class="divider" />

            <Tooltip
              v-for="webhook of webhookIntegrations"
              :key="webhook.id"
              :content="
                study.activeWebhookIntegrationIds.includes(webhook.id)
                  ? `This study has already been sent to ${webhook.name}`
                  : ''
              "
              :content-data-test-id="`send-to-webhook-tooltip-content-${webhook.name}`"
              placement="left"
            >
              <div
                class="study-dropdown-option"
                :class="{ disabled: study.activeWebhookIntegrationIds.includes(webhook.id) }"
                :data-testid="`send-to-webhook-btn-${webhook.name}`"
                @click="activateWebhookForStudy(webhook)"
              >
                {{ `Send to ${webhook.name}` }}
              </div>
            </Tooltip>
          </template>

          <template v-if="hasStudyDeletePermission">
            <div class="divider" />

            <div
              class="study-dropdown-option"
              :data-testid="`study-delete-btn-${formatDicomName(study.patientName)}`"
              @click="isStudyDeleteModalVisible = true"
            >
              Delete study
            </div>
          </template>
        </div>
      </template>
    </Popper>
  </Tooltip>

  <StudyDeleteModal
    v-if="isStudyDeleteModalVisible"
    :study="study"
    @close="isStudyDeleteModalVisible = false"
    @study-deleted="emits('study-deleted')"
  />

  <StudyAuditLogModal
    v-if="isAuditLogModalVisible"
    :study="study"
    @close="isAuditLogModalVisible = false"
  />

  <StudyPatientIdentityUpdateModal
    v-if="isPatientIdentityUpdateModalVisible"
    :study="study"
    :is-study-open="isStudyOpen"
    @close="isPatientIdentityUpdateModalVisible = false"
    @patient-identity-updated="emits('patient-identity-updated')"
  />

  <StudyDownloadDicomModal
    v-if="isDICOMDownloadModalVisible"
    :study="study"
    @close="isDICOMDownloadModalVisible = false"
  />

  <DicomEndpointSendModal
    v-if="isDicomEndpointSendModalVisible"
    :study="study"
    @close="isDicomEndpointSendModalVisible = false"
  />

  <HL7MessageSendModal
    v-if="hl7StudyReportToSend"
    :study-report-id="hl7StudyReportToSend.id"
    @close="hl7StudyReportToSend = undefined"
  />
  <ActivityOverlay v-if="isRequestingStudyReprocess" text="Requesting study reprocess" />
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { ref } from "vue";
import type { WebhookIntegrationGetOneResponseDto } from "../../../backend/src/integrations/webhook/dto/webhook-integration-get-one.dto";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import {
  hasAuditLogViewPermission,
  hasDicomEndpointSendPermission,
  hasHL7MessageSendPermission,
  hasStudyDeletePermission,
  hasStudyReprocessPermission,
  hasStudyUpdatePatientIdentityPermission,
} from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";
import { Study, StudyReportDescription, getLatestReport } from "../utils/study-data";
import { useStudyDropdownWebhookIntegrations } from "../utils/study-dropdown-webhook-integrations";
import ActivityOverlay from "./ActivityOverlay.vue";
import DicomEndpointSendModal from "./DicomEndpointSendModal.vue";
import HL7MessageSendModal from "./HL7MessageSendModal.vue";
import Popper from "./Popper.vue";
import StudyAuditLogModal from "./StudyAuditLogModal.vue";
import StudyDeleteModal from "./StudyDeleteModal.vue";
import StudyDownloadDicomModal from "./StudyDownloadDicomModal.vue";
import StudyPatientIdentityUpdateModal from "./StudyPatientIdentityUpdateModal.vue";

interface Props {
  study: Study;
  isStudyOpen: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (event: "study-deleted"): void;
  (event: "patient-identity-updated"): void;
}
const emits = defineEmits<Emits>();

const isStudyDeleteModalVisible = ref(false);
const isPatientIdentityUpdateModalVisible = ref(false);
const isDICOMDownloadModalVisible = ref(false);
const isDicomEndpointSendModalVisible = ref(false);
const isAuditLogModalVisible = ref(false);
const isDropdownOpen = ref(false);
const hl7StudyReportToSend = ref<StudyReportDescription>();
const isRequestingStudyReprocess = ref(false);

async function reprocessStudy(study: Study): Promise<void> {
  isRequestingStudyReprocess.value = true;

  try {
    await axios.post(`/api/studies/${study.id}/reprocess`, undefined);
  } catch {
    addNotification({ type: "error", message: "Failed reprocessing study" });
    return;
  } finally {
    isRequestingStudyReprocess.value = false;
  }

  addNotification({ type: "info", message: "Started reprocessing study" });

  study.processedClipsCount = 0;
  study.erroredClipsCount = 0;
}

const webhookIntegrations = useStudyDropdownWebhookIntegrations();

async function activateWebhookForStudy(
  webhook: WebhookIntegrationGetOneResponseDto
): Promise<void> {
  try {
    await axios.post(`/api/studies/${props.study.id}/webhook-integrations/${webhook.id}/activate`);
  } catch {
    addNotification({ type: "error", message: `Failed sending study to ${webhook.name}` });
    return;
  }

  addNotification({ type: "info", message: `This study will be sent to ${webhook.name}` });

  if (!props.study.activeWebhookIntegrationIds.includes(webhook.id)) {
    // eslint-disable-next-line vue/no-mutating-props
    props.study.activeWebhookIntegrationIds.push(webhook.id);
  }
}
</script>

<style scoped lang="scss">
.icon-button {
  display: grid;
  place-content: center;
  color: var(--accent-color-1);
  transition: color 100ms ease;
  height: 14px;
  width: 14px;
  border-radius: var(--border-radius);

  &:hover,
  &.active {
    cursor: pointer;
    color: var(--accent-color-2);
  }
}

:deep(.study-dropdown) {
  background-color: var(--bg-color-4);
}

.study-dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--text-color-1);
}

.study-dropdown-option {
  margin: 0 4px;
  transition: color 100ms ease;

  &:not(.disabled):hover {
    color: var(--text-color-2);
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.divider {
  border-top: 1px solid var(--accent-color-1);
}
</style>
