import { computed, reactive, ref, watch, type ComputedRef, type Ref } from "vue";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import type { BaseSlopeCalculation } from "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import { calculateBaseSlopeDetails } from "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import type { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import {
  arePointsEqual,
  distance,
  isPointWithinTolerance,
} from "../../../../../backend/src/shared/math-utils";
import { RegionUnit } from "../../../../../backend/src/studies/study-clip-region";
import { getClips } from "../../../../../backend/src/studies/study-helpers";
import type { Study, StudyClipRegion } from "../../../utils/study-data";
import { MEASUREMENT_LABEL_OFFSET } from "../../measurement-helpers";
import { buildMeasurementToolBatchChangeRequest } from "../../measurement-tool-helpers";
import { restartMeasuring } from "../../measurement-tool-state";
import {
  drawLinearMeasurement,
  getLinearMeasurementLabelPosition,
} from "../linear/measurement-base-linear";
import type {
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolMeasurementCreationRequest,
  MeasurementToolRecreationDetails,
  ToolbarItem,
} from "../measurement-tool";
import {
  RESTART_MEASUREMENT_ICON,
  checkForMeasurementPointEdits,
  isRegionSpectral,
} from "../measurement-tool-helpers";
import { measurementIncompleteTooltipText } from "../measurement-tooltips";
import type { DopplerMeasurementBase } from "./measurement-base-doppler";
import { createBaseDopplerMeasurement } from "./measurement-base-doppler";

type SlopeMeasurementTool = Omit<
  MeasurementTool,
  | "associatedMeasurements"
  | "displayName"
  | "getCreatableMeasurementNames"
  | "isMeasurableOnStudyClip"
  | "toggleAssociatedMeasurement"
>;

interface SlopeMeasurementToolDetails {
  frame: Ref<number>;
  pointA: number[];
  pointB: number[];
  isFixedToMidline: Ref<boolean>;
  baseSlopeCalculation: ComputedRef<BaseSlopeCalculation>;
  baseDopplerMeasurement: DopplerMeasurementBase;
  baseSlopeCreationRequest: ComputedRef<
    (MeasurementToolMeasurementCreationRequest & { value: number }) | undefined
  >;
}

/**
 * Creates a new slope measurement tool instance for the given study. This tool works on:
 *
 * - A spectral doppler clip to measure an acceleration (m/s^2), OR
 * - An M-mode clip to measure a velocity (cm/s)
 */
// eslint-disable-next-line max-statements
export function createSlopeMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined,
  options: {
    customMeasurementName: MeasurementName;
    yAxisCustomMeasurementName: MeasurementName;
    isFixSlopeToMidlineCheckboxVisible: boolean;
    measurementTool: MeasurementToolName;
  }
): {
  slopeTool: Readonly<SlopeMeasurementTool>;
  slopeToolDetails: SlopeMeasurementToolDetails;
} {
  const {
    customMeasurementName,
    yAxisCustomMeasurementName,
    isFixSlopeToMidlineCheckboxVisible,
    measurementTool,
  } = options;

  const measurementName = ref<MeasurementName | undefined>(undefined);
  const customName = ref("");

  const frame = ref(0);

  // The point that is fixed on the midline in spectral doppler slope measurements.
  const pointA = reactive([-1, -1]);

  // The point that is not fixed to the midline in spectral doppler slope measurements.
  const pointB = reactive([-1, -1]);

  const lastMousePosition = ref<number[] | null>(null);

  const contour = computed(() => [...pointA, ...pointB]);

  const studyClip = getClips(study).find((c) => c.id === studyClipId);

  const baseDopplerMeasurement = createBaseDopplerMeasurement(studyClip, region);

  // The slope measurement is considered started once any point values have been set
  const isStarted = computed(() => contour.value.some((v) => v !== -1));

  const shouldFixSlopeToMidline = ref(isFixSlopeToMidlineCheckboxVisible);

  const slopeCalculation = computed(() =>
    calculateBaseSlopeDetails(studyClip, region, pointA, pointB)
  );

  const isSaveButtonEnabled = computed(() => !slopeCalculation.value.slopeMeasuredValue.isNull());

  const saveButtonTooltip = computed(() =>
    !isSaveButtonEnabled.value ? measurementIncompleteTooltipText : ""
  );

  const helpText = computed(() => {
    if (region === undefined) {
      return "Select the doppler clip region";
    }

    return "Click to place points";
  });

  const toolbarItems = computed((): ToolbarItem[] => {
    const items: ToolbarItem[] = [
      {
        text: slopeCalculation.value.slopeMeasuredValue.formatAsStringForName(
          measurementName.value ?? customMeasurementName
        ),
      },
    ];

    if (!isEditingSavedMeasurement.value) {
      items.push({
        icon: RESTART_MEASUREMENT_ICON,
        onClick: (): void => {
          restartMeasuring({ studyClipId: undefined, region: undefined });
          requestRedrawHandlers.forEach((handler) => handler());
        },
      });
    }

    if (isFixSlopeToMidlineCheckboxVisible) {
      items.push({
        text: "Fix to midline",
        checkbox: { checked: shouldFixSlopeToMidline.value },
        onClick: (): void => {
          shouldFixSlopeToMidline.value = !shouldFixSlopeToMidline.value;

          if (
            shouldFixSlopeToMidline.value &&
            baseDopplerMeasurement.midlineY.value !== undefined
          ) {
            pointA[1] = baseDopplerMeasurement.midlineY.value;
          }

          requestRedrawHandlers.forEach((handler) => handler());
        },
      });
    }

    return items;
  });

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    const isFacingRight = pointA[0] > pointB[0];

    const labels: MeasurementLabel[] = [];

    if (!slopeCalculation.value.slopeMeasuredValue.isNull()) {
      const slopeLabelPosition = getLinearMeasurementLabelPosition({
        canvas,
        from: pointA,
        to: pointB,
        pixelOffsetFromMidpoint: (isFacingRight ? 1 : -1) * MEASUREMENT_LABEL_OFFSET,
      });

      labels.push({
        measurementName: measurementName.value ?? customMeasurementName,
        measurementValue: slopeCalculation.value.slopeMeasuredValue.createTransientValue(
          customMeasurementName,
          contour.value,
          frame.value
        ),
        x: slopeLabelPosition.x,
        y: slopeLabelPosition.y,
        alignment: isFacingRight ? "left" : "right",
      });
    }

    if (!slopeCalculation.value.yMeasuredValue.isNull()) {
      const yAxisLabelPosition = getLinearMeasurementLabelPosition({
        canvas,
        from: [pointB[0], pointA[1]],
        to: pointB,
        pixelOffsetFromMidpoint: (isFacingRight ? -1 : 1) * MEASUREMENT_LABEL_OFFSET,
      });

      labels.push({
        measurementName: yAxisCustomMeasurementName,
        measurementValue: slopeCalculation.value.yMeasuredValue.createTransientValue(
          yAxisCustomMeasurementName,
          contour.value,
          frame.value
        ),
        ...yAxisLabelPosition,
        alignment: isFacingRight ? "right" : "left",
      });
    }

    if (!slopeCalculation.value.timeMeasuredValue.isNull()) {
      const timeLabelPosition = getLinearMeasurementLabelPosition({
        canvas,
        from: [Math.min(pointA[0], pointB[0]), pointA[1]],
        to: [Math.max(pointA[0], pointB[0]), pointA[1]],
        pixelOffsetFromMidpoint: (pointB[1] < pointA[1] ? -2 : 1) * MEASUREMENT_LABEL_OFFSET,
      });

      labels.push({
        measurementName: MeasurementName.CustomTime,
        measurementValue: slopeCalculation.value.timeMeasuredValue.createTransientValue(
          MeasurementName.CustomTime,
          contour.value,
          frame.value
        ),
        ...timeLabelPosition,
        alignment: "center-above",
      });
    }

    return labels;
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    if (
      studyClip === undefined ||
      studyClip.width === null ||
      region === undefined ||
      baseDopplerMeasurement.midlineY.value === undefined ||
      (arePointsEqual(pointB, [-1, -1]) && !shouldFixSlopeToMidline.value)
    ) {
      return;
    }

    if (isRegionSpectral(region)) {
      baseDopplerMeasurement.drawMidline(
        canvas,
        ctx,
        !isStarted.value ? lastMousePosition.value ?? undefined : undefined
      );
    }

    // Velocity or displacement (y-axis)
    if (shouldFixSlopeToMidline.value || region.yDirectionUnit === RegionUnit.Centimeters) {
      drawLinearMeasurement({
        canvas,
        ctx,
        from: pointB,
        to: [pointB[0], pointA[1]],
        drawEditHandles: false,
        opacity: 1,
      });
    }

    // Time (x-axis)
    drawLinearMeasurement({
      canvas,
      ctx,
      from: pointA,
      to: [pointB[0], pointA[1]],
      drawEditHandles: false,
      opacity: 1,
    });

    drawLinearMeasurement({
      canvas,
      ctx,
      from: pointA,
      to: pointB,
      drawEditHandles: true,
      opacity: 1,
    });
  }

  let firstMouseDownPosition: number[] | null = null;
  let editingPointIndex: number | null = null;
  let editPointOffset = [0, 0];

  function onCanvasMouseDown(pt: number[]): void {
    if (shouldFixSlopeToMidline.value && baseDopplerMeasurement.midlineY.value === undefined) {
      return;
    }

    if (shouldFixSlopeToMidline.value && baseDopplerMeasurement.midlineY.value !== undefined) {
      if (isStarted.value) {
        const edit = checkForMeasurementPointEdits(contour.value, pt);
        if (edit !== undefined) {
          editingPointIndex = edit.editingPointIndex;
          editPointOffset = edit.editPointOffset;
        }

        return;
      }

      pointA[0] = pt[0];
      pointA[1] = baseDopplerMeasurement.midlineY.value;
      pointB[0] = pt[0];
      pointB[1] = pt[1];

      editingPointIndex = Math.abs(baseDopplerMeasurement.midlineY.value - pt[1]) < 0.05 ? 1 : 0;

      firstMouseDownPosition = pt;
    } else if (arePointsEqual(pointA, [-1, -1]) || firstMouseDownPosition !== null) {
      if (firstMouseDownPosition === null) {
        pointA.splice(0, 2, ...pt);
        editingPointIndex = 1;
        firstMouseDownPosition = pt;
      }
    }

    if (!shouldFixSlopeToMidline.value && firstMouseDownPosition === null) {
      if (isPointWithinTolerance(pointA, pt)) {
        editingPointIndex = 0;
      } else if (isPointWithinTolerance(pointB, pt)) {
        editingPointIndex = 1;
      }
    }
  }

  function onCanvasMouseMove(pt: number[]): boolean {
    lastMousePosition.value = pt;

    const offsetMouse = [pt[0] - editPointOffset[0], pt[1] - editPointOffset[1]];

    if (editingPointIndex === 0) {
      pointA[0] = offsetMouse[0];

      if (!shouldFixSlopeToMidline.value) {
        pointA[1] = offsetMouse[1];
      }
    } else if (editingPointIndex === 1) {
      pointB[0] = offsetMouse[0];
      pointB[1] = offsetMouse[1];
    }

    return true;
  }

  function onCanvasMouseUp(pt: number[]): void {
    if (firstMouseDownPosition !== null && distance(firstMouseDownPosition, pt) < 0.05) {
      return;
    }

    firstMouseDownPosition = null;
    editingPointIndex = null;
  }

  const baseSlopeCreationRequest = computed(() => {
    const slopeUnitAndValue = slopeCalculation.value.slopeMeasuredValue.getInternalValueAndUnit();

    if (measurementName.value === undefined || slopeUnitAndValue === null || region === undefined) {
      return undefined;
    }

    return {
      ...slopeUnitAndValue,
      name: measurementName.value,
      customName: customName.value,
      frame: frame.value,
      contour: contour.value,
      tool: measurementTool,
      studyClipId,
    };
  });

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    if (baseSlopeCreationRequest.value === undefined) {
      return {};
    }

    return buildMeasurementToolBatchChangeRequest(
      baseSlopeCreationRequest.value,
      savedMeasurementBeingEdited.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const isEditingSavedMeasurement = computed(() => editingMeasurementBatchId.value !== null);

  const editingMeasurementBatchId = ref<string | null>(null);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    if (value.contour === null) {
      return;
    }

    savedMeasurementBeingEdited.value = value;
    editingMeasurementBatchId.value = value.measurementCreationBatchId;
    measurementName.value = value.measurementName;
    customName.value = value.customName ?? "";
    pointA.splice(0, pointA.length, ...value.contour.slice(0, 2));
    pointB.splice(0, pointB.length, ...value.contour.slice(2, 4));
    frame.value = value.frame ?? 0;
  }

  const requestRedrawHandlers = new Set<() => void>();

  watch([contour, lastMousePosition], () => {
    requestRedrawHandlers.forEach((fn) => fn());
  });

  return {
    slopeTool: {
      measurementName,
      customName,
      customMeasurementName,

      region,
      studyClipId,
      isSaveButtonVisible: computed(() => true),
      isSaveButtonEnabled,
      saveButtonTooltip,
      helpText,
      toolbarItems,

      interactivePoints: computed(() => (isStarted.value ? contour.value : [])),
      getMeasurementLabels,
      requestRedrawHandlers,
      frameChangeHandlers: new Set(),
      shouldPassMousedownAfterRegionSelection: !shouldFixSlopeToMidline.value,
      drawToCanvas,
      onCanvasMouseDown,
      onCanvasMouseMove,
      onCanvasMouseUp,
      onFrameChange: (newFrame: number) => (frame.value = newFrame),
      isChangeAllowedOf: (_target: "clip" | "frame") => !isStarted.value,

      getMeasurementChangeRequests,

      editingMeasurementBatchId,
      loadSavedMeasurement,

      isMeasurementNameFixed: ref(false),
      onSaved: [],
      onDestroy: [],
    },

    // These are exported from the base for use in the main wrapper tool, such as for associated
    // measurement calculation.
    slopeToolDetails: {
      frame,
      pointA,
      pointB,
      isFixedToMidline: shouldFixSlopeToMidline,
      baseSlopeCalculation: slopeCalculation,
      baseDopplerMeasurement,
      baseSlopeCreationRequest,
    },
  };
}
