<template>
  <Tooltip :content="tooltip">
    <FontAwesomeIcon class="icon-button" :icon="icon" v-bind="iconProps" @click="emits('click')" />
  </Tooltip>
</template>

<script setup lang="ts">
/**
 * A simple icon button that renders as a fontawesome icon and emits a click event.
 */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Tooltip from "@/components/Tooltip.vue";
import { toRefs } from "vue";

interface Props {
  icon: string;
  tooltip?: string;
}

interface Emits {
  (event: "click"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { icon, tooltip, ...iconProps } = toRefs(props);
</script>

<style scoped lang="scss">
.icon-button {
  cursor: pointer;
  transition: color 100ms ease;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
