import { computed, ref } from "vue";
import type { DopplerSlopeAssociatedMeasurementName } from "../../../../../backend/src/measurements/associated-measurements";
import { dopplerSlopeAssociatedMeasurements } from "../../../../../backend/src/measurements/associated-measurements";
import { getDrawableDopplerSlopeMeasurements } from "../../../../../backend/src/measurements/drawable-measurements";
import { MeasurementName } from "../../../../../backend/src/measurements/measurement-names";
import {
  calculatePressureHalfTime,
  getDopplerSlopeAssociatedMeasurementValues,
} from "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import { MeasurementToolName } from "../../../../../backend/src/measurements/measurement-tool-names";
import { getClips } from "../../../../../backend/src/studies/study-helpers";
import type { Study, StudyClip, StudyClipRegion } from "../../../utils/study-data";
import {
  MEASUREMENT_LABEL_OFFSET,
  findSavedAssociatedMeasurements,
} from "../../measurement-helpers";
import {
  buildMeasurementToolBatchChangeRequest,
  generateAssociatedMeasurementRefs,
  setAssociatedMeasurementDetailsOnEdit,
  setDefaultEnabledForAssociatedMeasurements,
} from "../../measurement-tool-helpers";
import {
  drawLinearMeasurement,
  getLinearMeasurementLabelPosition,
} from "../linear/measurement-base-linear";
import type {
  AssociatedMeasurement,
  MeasurementLabel,
  MeasurementTool,
  MeasurementToolBatchChangeRequest,
  MeasurementToolRecreationDetails,
} from "../measurement-tool";
import { isRegionSpectral } from "../measurement-tool-helpers";
import { createSlopeMeasurementTool } from "./measurement-base-slope";

export function createDopplerSlopeMeasurementTool(
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
): Readonly<MeasurementTool> {
  const baseMeasurementCreationRequest = {
    tool: MeasurementToolName.DopplerSlope,
    studyClipId,
  };

  const { slopeTool, slopeToolDetails } = createSlopeMeasurementTool(study, studyClipId, region, {
    customMeasurementName: MeasurementName.CustomDopplerSlope,
    yAxisCustomMeasurementName: MeasurementName.CustomVelocity,
    isFixSlopeToMidlineCheckboxVisible: true,
    measurementTool: MeasurementToolName.DopplerSlope,
  });

  const pressureHalfTimeDetails = computed(() => {
    const clip = getClips(study).find((c) => c.id === studyClipId);

    const changeRequests = slopeTool.getMeasurementChangeRequests();

    if (
      (changeRequests.creates?.length ?? 0) === 0 &&
      (changeRequests.updates?.length ?? 0) === 0
    ) {
      return undefined;
    }

    return calculatePressureHalfTime(
      slopeToolDetails.baseSlopeCalculation.value,
      clip,
      region,
      slopeToolDetails.pointA,
      slopeToolDetails.pointB
    );
  });

  const associatedPeakVelocityRefs = generateAssociatedMeasurementRefs();
  const associatedTimeRefs = generateAssociatedMeasurementRefs();
  const associatedPressureHalfTimeRefs = generateAssociatedMeasurementRefs();
  const associatedAreaByPhtRefs = generateAssociatedMeasurementRefs();

  const associatedMeasurements = computed(() => {
    if (slopeTool.measurementName.value === undefined) {
      return {};
    }

    const associatedNames = dopplerSlopeAssociatedMeasurements[slopeTool.measurementName.value];
    if (associatedNames === undefined) {
      return {};
    }

    const result: Partial<Record<DopplerSlopeAssociatedMeasurementName, AssociatedMeasurement>> =
      {};

    const associatedMeasurementValues = getDopplerSlopeAssociatedMeasurementValues(
      slopeTool.measurementName.value,
      slopeToolDetails.baseSlopeCalculation.value,
      pressureHalfTimeDetails.value,
      slopeToolDetails.pointA,
      slopeToolDetails.pointB
    );

    // Don't create the velocity associated measurement when the slope isn't fixed to the midline,
    // as the measurement would actually measure the change in velocity and would be meaningless.
    if (associatedMeasurementValues.peakVelocity && slopeToolDetails.isFixedToMidline.value) {
      result.peakVelocity = {
        ...associatedPeakVelocityRefs,
        ...baseMeasurementCreationRequest,
        ...associatedMeasurementValues.peakVelocity,
        frame: slopeToolDetails.frame.value,
      };
    }

    if (associatedMeasurementValues.time) {
      result.time = {
        ...associatedTimeRefs,
        ...baseMeasurementCreationRequest,
        ...associatedMeasurementValues.time,
        frame: slopeToolDetails.frame.value,
      };
    }

    if (associatedMeasurementValues.pressureHalfTime) {
      result.pressureHalfTime = {
        ...associatedPressureHalfTimeRefs,
        ...baseMeasurementCreationRequest,
        ...associatedMeasurementValues.pressureHalfTime,
        frame: slopeToolDetails.frame.value,
      };

      if (associatedMeasurementValues.areaByPressureHalfTime !== undefined) {
        result.areaByPressureHalfTime = {
          ...associatedAreaByPhtRefs,
          ...baseMeasurementCreationRequest,
          ...associatedMeasurementValues.areaByPressureHalfTime,
          frame: slopeToolDetails.frame.value,
        };
      }
    }

    if (savedMeasurementBeingEdited.value === undefined) {
      setDefaultEnabledForAssociatedMeasurements(result, associatedNames);
    }

    return result;
  });

  function toggleAssociatedMeasurement(name: string): void {
    const associatedMeasurement =
      associatedMeasurements.value[name as DopplerSlopeAssociatedMeasurementName];

    if (associatedMeasurement !== undefined) {
      associatedMeasurement.enabled.value = !associatedMeasurement.enabled.value;

      slopeTool.requestRedrawHandlers.forEach((fn) => fn());
    }
  }

  function drawToCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    slopeTool.drawToCanvas(canvas, ctx);

    if (
      associatedMeasurements.value.pressureHalfTime?.enabled.value === true &&
      associatedMeasurements.value.pressureHalfTime.contour
    ) {
      drawLinearMeasurement({
        canvas,
        ctx,
        from: associatedMeasurements.value.pressureHalfTime.contour.slice(0, 2),
        to: associatedMeasurements.value.pressureHalfTime.contour.slice(2, 4),
        drawEditHandles: false,
        opacity: 1,
      });
    }
  }

  function getMeasurementLabels(canvas: HTMLCanvasElement): MeasurementLabel[] {
    const labels = slopeTool.getMeasurementLabels(canvas);

    if (
      associatedMeasurements.value.pressureHalfTime?.enabled.value === true &&
      associatedMeasurements.value.pressureHalfTime.value &&
      associatedMeasurements.value.pressureHalfTime.contour
    ) {
      const contour = associatedMeasurements.value.pressureHalfTime.contour;
      const labelPosition = getLinearMeasurementLabelPosition({
        canvas,
        from: contour.slice(0, 2),
        to: contour.slice(2, 4),
        pixelOffsetFromMidpoint: (contour[0] > contour[2] ? -1 : 1) * MEASUREMENT_LABEL_OFFSET,
      });

      if (pressureHalfTimeDetails.value?.pressureHalfTime) {
        labels.push({
          measurementName: associatedMeasurements.value.pressureHalfTime.name,
          measurementValue: pressureHalfTimeDetails.value.pressureHalfTime.createTransientValue(
            associatedMeasurements.value.pressureHalfTime.name,
            contour,
            slopeToolDetails.frame.value
          ),
          ...labelPosition,
          alignment:
            slopeToolDetails.pointA[1] > slopeToolDetails.pointB[1]
              ? "center-below"
              : "center-above",
        });
      }
    }

    return labels;
  }

  function getMeasurementChangeRequests(): MeasurementToolBatchChangeRequest {
    const baseSlopeCreationRequest = slopeToolDetails.baseSlopeCreationRequest.value;

    if (baseSlopeCreationRequest === undefined) {
      return {};
    }

    return buildMeasurementToolBatchChangeRequest(
      baseSlopeCreationRequest,
      savedMeasurementBeingEdited.value,
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  const savedMeasurementBeingEdited = ref<MeasurementToolRecreationDetails | undefined>(undefined);

  const previouslySavedAssociatedMeasurements = ref<
    (MeasurementToolRecreationDetails & { measurementId: string })[]
  >([]);

  function loadSavedMeasurement(value: MeasurementToolRecreationDetails): void {
    slopeTool.loadSavedMeasurement(value);
    savedMeasurementBeingEdited.value = value;

    previouslySavedAssociatedMeasurements.value = findSavedAssociatedMeasurements(
      study,
      value,
      dopplerSlopeAssociatedMeasurements
    );

    setAssociatedMeasurementDetailsOnEdit(
      associatedMeasurements.value,
      previouslySavedAssociatedMeasurements.value
    );
  }

  return {
    displayName: "Doppler Slope Measurement",

    isMeasurableOnStudyClip: isDopplerSlopeMeasurableOnStudyClip,
    getCreatableMeasurementNames: getDrawableDopplerSlopeMeasurements,

    associatedMeasurements,
    toggleAssociatedMeasurement,

    ...slopeTool,

    drawToCanvas,
    getMeasurementLabels,

    getMeasurementChangeRequests,
    loadSavedMeasurement,
  };
}

export function isDopplerSlopeMeasurableOnStudyClip(
  clip: StudyClip,
  region?: StudyClipRegion
): boolean {
  const regions = region ? [region] : clip.regions;

  return regions.some((r) => isRegionSpectral(r));
}
