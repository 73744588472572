<template>
  <div class="settings-title">
    Webhook Settings

    <OutboundSourceIpLabel />
  </div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button
      class="accented"
      style="margin-left: auto"
      data-testid="add-webhook-integration-modal-btn"
      @click="isAddModalVisible = true"
    >
      Create Webhook
    </button>
  </div>

  <div class="integrations-table">
    <div class="header">
      <div>Name</div>
      <div>URL</div>
      <div>Last Used</div>
      <div>Study Send Mode</div>
    </div>

    <div class="header-line" />

    <template v-for="integration in filteredWebhookIntegrations" :key="integration.id">
      <div
        class="grid-table-row"
        :data-testid="`webhook-row-${integration.name}`"
        @click="viewIntegration(integration.id)"
      >
        <div data-testid="name">{{ integration.name }}</div>
        <div>{{ integration.url }}</div>
        <div>
          {{ formatDateTime(integration.lastUsedAt, { includeDate: true, includeTime: true }) }}
        </div>
        <div>{{ getWebhookStudySendModeDisplayText(integration.studySendMode) }}</div>
      </div>
    </template>
  </div>

  <ActivityOverlay v-if="isLoading" text="Loading" />

  <AddWebhookIntegrationModal
    v-if="isAddModalVisible"
    @close="isAddModalVisible = false"
    @integration-added="
      isAddModalVisible = false;
      fetchWebhookIntegrations();
    "
  />
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import type { WebhookIntegrationGetManyResponseDto } from "../../../../../backend/src/integrations/webhook/dto/webhook-integration-get-many.dto";
import { getWebhookStudySendModeDisplayText } from "../../../../../backend/src/integrations/webhook/webhook-study-send-mode";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import FilterInput from "../../../components/FilterInput.vue";
import router from "../../../router";
import { formatDateTime } from "../../../utils/date-time-utils";
import { addNotification } from "../../../utils/notifications";
import OutboundSourceIpLabel from "../OutboundSourceIpLabel.vue";
import AddWebhookIntegrationModal from "./WebhookIntegrationAddModal.vue";

const isLoading = ref(false);
const webhookIntegrations = ref<WebhookIntegrationGetManyResponseDto>([]);
const searchTerm = ref("");

const isAddModalVisible = ref(false);

async function fetchWebhookIntegrations(): Promise<void> {
  isLoading.value = true;

  let response: AxiosResponse<WebhookIntegrationGetManyResponseDto> | undefined = undefined;
  try {
    response = await axios.get<WebhookIntegrationGetManyResponseDto>(`/api/integrations/webhook`);
  } catch {
    addNotification({ type: "error", message: "Failed loading webhooks" });
    return;
  } finally {
    isLoading.value = false;
  }

  webhookIntegrations.value = response.data;
}

onMounted(fetchWebhookIntegrations);

// Integration search by name and url
const filteredWebhookIntegrations = computed(() => {
  const token = searchTerm.value.toLowerCase().trim();

  return webhookIntegrations.value.filter(
    (integration) =>
      integration.name.toLowerCase().includes(token) ||
      integration.url.toLowerCase().includes(token)
  );
});

async function viewIntegration(integrationId: string): Promise<void> {
  await router.push({ name: "settings-integrations-webhook-view", params: { id: integrationId } });
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.integrations-table {
  display: grid;
  align-items: center;
  row-gap: 2px;

  .header {
    font-weight: bold;
    display: contents;

    > * {
      padding: 0 20px 4px 0;
    }
  }

  .header-line {
    grid-area: 2 / 1 / 2 / span 4;
    border-bottom: 2px solid var(--bg-color-4);
  }
}
</style>
