<template>
  <div
    v-if="state"
    class="cell status"
    :class="state.status"
    :data-testid="`status-${state.status}`"
  >
    <Tooltip>
      <template #content>
        <div style="display: flex; flex-direction: column; gap: 2px">
          <div v-for="row in state.tooltipMessage" :key="row">
            {{ row }}
          </div>
        </div>
      </template>

      <FontAwesomeIcon :icon="state.icon" />
    </Tooltip>
  </div>
  <div v-else class="cell" />
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import { Study } from "@/utils/study-data";
import { useUserList } from "@/utils/users-list";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { getStudyReportStateDetails } from "../reporting/report-status";

interface Props {
  study: Study;
}

const props = defineProps<Props>();

const { userList } = useUserList();

const state = computed(() => getStudyReportStateDetails(props.study.reports, userList.value));
</script>

<style scoped lang="scss">
.cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status {
  display: grid;
  place-content: center;

  &.preliminaryReportApproved {
    color: var(--accent-color-blue);
    background-color: var(--accent-bg-color-blue);

    &.hovered {
      background-color: var(--accent-bg-color-blue-hover);
    }
  }

  &.amendmentInProgress {
    color: var(--accent-color-purple);
    background-color: var(--accent-bg-color-purple);

    &.hovered {
      background-color: var(--accent-bg-color-purple-hover);
    }
  }

  &.reportFinalized {
    color: var(--accent-color-green);
    background-color: var(--accent-bg-color-green);

    &.hovered {
      background-color: var(--accent-bg-color-green-hover);
    }
  }

  &.reportInProgress {
    color: #797979;
    background-color: var(--bg-color-2);

    &.hovered {
      background-color: var(--bg-color-4);
    }
  }
}
</style>
