import { MeasurementName } from "@/../../backend/src/measurements/measurement-names";
import { ReferenceRangeName } from "./reference-range";
import type { ReferenceRangeSet } from "./reference-range-sets";

export const ASE_FEMALE: ReferenceRangeSet = {};

ASE_FEMALE[MeasurementName.AorticRootDiameter] = {
  unindexed: [
    { to: 2.7, name: ReferenceRangeName.Abnormal },
    { to: 3.3, name: ReferenceRangeName.Normal },
    { to: 5, name: ReferenceRangeName.Abnormal },
  ],
};

ASE_FEMALE[MeasurementName.AscendingAortaDiameter] = {
  unindexed: [
    { to: 2.3, name: ReferenceRangeName.Abnormal },
    { to: 3.1, name: ReferenceRangeName.Normal },
    { to: 5, name: ReferenceRangeName.Abnormal },
  ],
};

ASE_FEMALE[MeasurementName.InterventricularSeptumDiastolicThickness] = {
  unindexed: [
    { to: 0.6, name: ReferenceRangeName.Unknown },
    { to: 1.0, name: ReferenceRangeName.Normal },
    { to: 1.3, name: ReferenceRangeName.Mild },
    { to: 1.5, name: ReferenceRangeName.Moderate },
    { to: 2.0, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentricleEjectionFractionTeichholz] = {
  unindexed: [
    { to: 30, name: ReferenceRangeName.Severe },
    { to: 40, name: ReferenceRangeName.Moderate },
    { to: 54, name: ReferenceRangeName.Mild },
    { to: 74, name: ReferenceRangeName.Normal },
    { to: 100, name: ReferenceRangeName.Unknown },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentricleInternalEndDiastolicDimension] = {
  indexed: [
    { to: 2.3, name: ReferenceRangeName.Unknown },
    { to: 3.2, name: ReferenceRangeName.Normal },
    { to: 3.5, name: ReferenceRangeName.Mild },
    { to: 3.7, name: ReferenceRangeName.Moderate },
    { to: 5.0, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentricleInternalSystolicDimension] = {
  indexed: [
    { to: 1.3, name: ReferenceRangeName.Unknown },
    { to: 2.2, name: ReferenceRangeName.Normal },
    { to: 2.4, name: ReferenceRangeName.Mild },
    { to: 2.6, name: ReferenceRangeName.Moderate },
    { to: 3.5, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentricleOutflowTractMeanGradient] = {
  unindexed: [
    { to: 20, name: ReferenceRangeName.Mild },
    { to: 40, name: ReferenceRangeName.Moderate },
    { to: 60, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentricleOutflowTractPeakVelocity] = {
  unindexed: [
    { to: 2.6, name: ReferenceRangeName.Unknown },
    { to: 3.0, name: ReferenceRangeName.Mild },
    { to: 4.0, name: ReferenceRangeName.Moderate },
    { to: 5.0, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.LeftVentriclePosteriorWallDiastolicThickness] = {
  unindexed: [
    { to: 0.6, name: ReferenceRangeName.Unknown },
    { to: 1.0, name: ReferenceRangeName.Normal },
    { to: 1.3, name: ReferenceRangeName.Mild },
    { to: 1.5, name: ReferenceRangeName.Moderate },
    { to: 2.0, name: ReferenceRangeName.Severe },
  ],
};

ASE_FEMALE[MeasurementName.MitralValveAWavePeakVelocity] = {
  unindexed: [
    { to: 1.9, name: ReferenceRangeName.Normal },
    { to: 2.5, name: ReferenceRangeName.Mild },
    { to: 3.5, name: ReferenceRangeName.Moderate },
  ],
};

ASE_FEMALE[MeasurementName.MitralValveEWavePeakVelocity] = {
  unindexed: [
    { to: 1.9, name: ReferenceRangeName.Normal },
    { to: 2.5, name: ReferenceRangeName.Mild },
    { to: 3.5, name: ReferenceRangeName.Moderate },
  ],
};

ASE_FEMALE[MeasurementName.StrokeVolume] = {
  unindexed: [
    { to: 50, name: ReferenceRangeName.Abnormal },
    { to: 70, name: ReferenceRangeName.Normal },
    { to: 100, name: ReferenceRangeName.Abnormal },
  ],
};

ASE_FEMALE[MeasurementName.TricuspidAnnularPlaneSystolicExcursion] = {
  unindexed: [
    { to: 1.7, name: ReferenceRangeName.Moderate },
    { to: 2.05, name: ReferenceRangeName.Mild },
    { to: 2.75, name: ReferenceRangeName.Normal },
    { to: 3.5, name: ReferenceRangeName.Unknown },
  ],
};
