<template>
  <div class="slider">
    <DragMove
      enabled
      class="drag-move"
      :class="{ dragging: isDragging }"
      @start="isDragging = true"
      @end="isDragging = false"
      @move="onDragMove"
      @click.stop
    >
      <div class="bar" />
      <div class="current" :style="`width: ${percentage}`" />
      <div class="scrubber" :style="`left: ${percentage}`" data-testid="scrubber" />
    </DragMove>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import DragMove from "./DragMove.vue";

interface Props {
  modelValue: number;
  min?: number;
  max?: number;
}

interface Emits {
  (event: "update:modelValue", newValue: number): void;
}

const props = withDefaults(defineProps<Props>(), { min: 0, max: 1 });
const emits = defineEmits<Emits>();

const isDragging = ref(false);

const percentage = computed(
  () => `${100 * ((props.modelValue - props.min) / (props.max - props.min))}%`
);

function onDragMove({ xFraction }: { xFraction: number }): void {
  emits("update:modelValue", props.min + (props.max - props.min) * xFraction);
}
</script>

<style scoped lang="scss">
.slider {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background-color 100ms ease;
  gap: 4px;

  &:hover {
    .line,
    .circle {
      background-color: var(--bg-color-3);
    }

    .scrubber {
      background-color: var(--accent-color-2);
    }
  }
}

.drag-move {
  position: relative;
  align-self: stretch;
  cursor: pointer;
  margin: 0 8px;
  flex: 1;
  width: 120px;

  &.dragging .scrubber {
    background-color: var(--accent-color-2);
  }
}

.bar {
  position: absolute;
  height: 4px;
  width: 100%;
  margin-top: -2px;
  top: 50%;
  background: var(--bg-color-3);
  border-radius: 2px;
}

.current {
  position: absolute;
  height: 4px;
  margin-top: -2px;
  left: 0;
  top: 50%;
  background-color: var(--accent-color-1);
  border-radius: 2px 0 0 2px;
}

.scrubber {
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  top: 50%;
  cursor: pointer;
  background-color: var(--accent-color-1);
  border-radius: 50%;
  transition: background-color 100ms ease;
}
</style>
