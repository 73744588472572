<template>
  <div class="stress-mode-header" :class="`layout-${stressModeLayout}`">
    <div class="stress-heading" data-testid="stress-mode-heading">
      <Tooltip :content="stressStudyDetailsBannerText" placement="left">
        <b>Stress Review {{ stressModeLayout === "table" ? "" : `– View:` }}</b>
      </Tooltip>

      <DropdownWidget
        v-if="stressModeLayout === 'byView'"
        :model-value="stressModeSelectedView"
        :items="viewNames.map((viewName) => ({ value: viewName, text: viewName }))"
        style="height: min-content"
        @update:model-value="emits('update:stress-mode-selected-view', $event)"
      />
    </div>

    <div class="controls">
      <div v-if="isByViewLayoutOptionVisible" class="layout-options">
        <Tooltip content="Show all images for the selected view in a flat table">
          <button
            class="first"
            :class="{ accented: stressModeShowAllClipsInByView }"
            data-testid="show-all-clips-for-selected-view"
            @click="emits('update:stress-mode-show-all-clips-in-by-view', true)"
          >
            All Images
          </button>
        </Tooltip>
        <Tooltip content="Show images for the selected view grouped by stage">
          <button
            class="last"
            :class="{ accented: !stressModeShowAllClipsInByView }"
            @click="emits('update:stress-mode-show-all-clips-in-by-view', false)"
          >
            Group by Stage
          </button>
        </Tooltip>
      </div>

      <div class="layout-options">
        <button
          class="first"
          data-testid="stress-show-table"
          :class="{ accented: stressModeLayout === 'table' }"
          @click="emits('update:stress-mode-layout', 'table')"
        >
          Show as Table
        </button>
        <button
          class="last"
          :class="{ accented: stressModeLayout === 'byView' }"
          data-testid="stress-show-by-view"
          @click="emits('update:stress-mode-layout', 'byView')"
        >
          Show by View
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DropdownWidget from "@/components/DropdownWidget.vue";
import Tooltip from "@/components/Tooltip.vue";
import { computed } from "vue";
import { isMeasuring, stopMeasuring } from "../../measurements/measurement-tool-state";
import { onKeyboardShortcut } from "../../utils/keyboard-shortcut";
import { Study } from "../../utils/study-data";
import {
  getStressClipsSortedByViewAndStage,
  getStressStageNames,
  getStressViewNames,
} from "../study-clip-helpers";

interface Props {
  study: Study;
  stressModeLayout: "byView" | "table";
  stressModeSelectedView: string;
  stressModeShowAllClipsInByView: boolean;
}

interface Emits {
  (event: "disable-stress-mode"): void;
  (event: "update:stress-mode-layout", type: "byView" | "table"): void;
  (event: "update:stress-mode-selected-view", view: string): void;
  (event: "update:stress-mode-show-all-clips-in-by-view", newValue: boolean): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const viewNames = computed(() => getStressViewNames(props.study));
const stageNames = computed(() => getStressStageNames(props.study));

const stressStudyDetailsBannerText = computed(
  () => `There are ${stageNames.value.length} stages and ${viewNames.value.length} views`
);

// The option to group by stage or show all clips is only visible when, for the clips for the
// selected view, there is at least one stage that has more than one clip.
const isByViewLayoutOptionVisible = computed(() => {
  if (props.stressModeLayout !== "byView") {
    return false;
  }

  const selectedViewIndex = viewNames.value.indexOf(props.stressModeSelectedView);
  const clipsForSelectedView = getStressClipsSortedByViewAndStage(props.study)[selectedViewIndex];

  return clipsForSelectedView.some((stageClips) => stageClips.length > 1);
});

onKeyboardShortcut("Escape", () => {
  if (isMeasuring.value) {
    stopMeasuring();
  } else {
    emits("disable-stress-mode");
  }
});
</script>

<style scoped lang="scss">
.stress-mode-header {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "main";
  height: 36px;
  background-color: var(--bg-color-2);

  > * {
    grid-area: main;
  }

  &.layout-table {
    padding-left: 36px;
  }
}

.stress-heading {
  place-self: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

.controls {
  place-self: center end;
  padding-right: 4px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  gap: 8px;

  .layout-options {
    display: flex;
    align-items: center;

    button {
      padding: 6px 8px;
      height: min-content;

      &.accented {
        background-color: var(--border-color-1);
        color: white;

        &:hover {
          background-color: var(--border-color-1);
        }
      }

      &.first {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
