<template>
  <div class="rpt-main-header">
    <span class="rpt-main-header-text">Measurement Groups</span>
  </div>

  <template
    v-for="measurementGroup in reportStructure.measurementGroups"
    :key="measurementGroup.name"
  >
    <div class="rpt-measurement-group-title">
      <span class="rpt-measurement-group-title-text"> {{ measurementGroup.name }} </span>
    </div>

    <div class="rpt-measurement-table">
      <template
        v-for="(column, columnIndex) in getMeasurementGridItems(measurementGroup.columns)"
        :key="columnIndex"
      >
        <ReportCheckbox
          v-for="(item, itemIndex) in column"
          :key="itemIndex"
          :style="`grid-area: ${itemIndex + 1} / ${columnIndex + 1} / ${itemIndex + 1} / ${
            columnIndex + 1
          }`"
          :model-value="item.isVisible"
          :enabled="mode === ReportContentMode.EditReportStructure"
          @update:model-value="onToggle(item.name, item.isIndexed)"
        >
          <span class="rpt-measurement-name">
            {{ getMeasurementDisplayName(item.name, item.isIndexed ? "indexed" : "unindexed") }}
          </span>
        </ReportCheckbox>
      </template>
    </div>
  </template>
</template>

<script setup lang="ts">
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import {
  ReportMeasurementGroupItem,
  type ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import { isMeasurementIndexable } from "../measurements/measurement-helpers";
import ReportCheckbox from "./ReportCheckbox.vue";
import { ReportContentMode } from "./report-content";
import {
  ReportStructureMutation,
  createMeasurementGroupToggleMeasurementVisibilityMutation,
} from "./report-structure-mutations";

interface Props {
  reportStructure: ReportStructure;
  mode: ReportContentMode;
}

interface Emits {
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

defineProps<Props>();
const emits = defineEmits<Emits>();

function getMeasurementGridItems(
  columns: ReportMeasurementGroupItem[][]
): { name: MeasurementName; isVisible: boolean; isIndexed: boolean }[][] {
  const fullColumns: { name: MeasurementName; isVisible: boolean; isIndexed: boolean }[][] = [];

  for (const column of columns) {
    const newColumn = [];

    for (const mmt of column) {
      newColumn.push({ name: mmt.name, isVisible: mmt.isUnindexedVisible, isIndexed: false });

      if (isMeasurementIndexable(mmt.name)) {
        newColumn.push({ name: mmt.name, isVisible: mmt.isIndexedVisible, isIndexed: true });
      }
    }

    fullColumns.push(newColumn);
  }

  return fullColumns;
}

function onToggle(name: MeasurementName, isIndexed: boolean): void {
  emits(
    "mutate-structure",
    createMeasurementGroupToggleMeasurementVisibilityMutation(name, isIndexed)
  );
}
</script>

<style scoped lang="scss">
.rpt-measurement-table {
  row-gap: 0.2em;
}
</style>
