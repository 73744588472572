<template>
  <div class="reference-range-bar">
    <div ref="rangesElement" class="ranges">
      <div
        v-for="range in referenceRanges"
        :key="range.from"
        class="range"
        :style="{
          width: `${rangesElementWidth * ((range.to - range.from) / fullRange!)}px`,
          'background-color': getReferenceRangeColor(range.name),
        }"
      />
    </div>

    <div :style="{ position: 'absolute', left: `${percentage}%` }">
      <div class="value-indicator" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { MeasurementName } from "@/../../../backend/src/measurements/measurement-names";
import { clamp } from "@/../../backend/src/shared/math-utils";
import { getReferenceRangeColor } from "@/reference-ranges/reference-range";
import {
  getReferenceRangeSetData,
  ReferenceRangeSetName,
} from "@/reference-ranges/reference-range-sets";
import { useElementSize } from "@vueuse/core";
import { computed, ref } from "vue";

interface Props {
  referenceRangeSetName: ReferenceRangeSetName;
  measurementName: MeasurementName;
  indexed: boolean;
  value: number;
}

const props = defineProps<Props>();

const referenceRanges = computed(() =>
  getReferenceRangeSetData(props.referenceRangeSetName, props.measurementName, props.indexed)
);

const fullRange = computed(
  () => referenceRanges.value[referenceRanges.value.length - 1].to - referenceRanges.value[0].from
);

const percentage = computed(() => {
  const result = (100 * (props.value - referenceRanges.value[0].from)) / fullRange.value;

  return clamp(result, 0, 100);
});

const rangesElement = ref<HTMLDivElement>();
const rangesElementWidth = useElementSize(rangesElement).width;
</script>

<style scoped lang="scss">
.reference-range-bar {
  width: 100%;
  position: relative;
  display: flex;
  padding: 4px 0;
}

.ranges {
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
}

.range {
  height: 5px;
}

.value-indicator {
  width: 4px;
  height: 13px;
  margin-top: -4px;
  border-radius: var(--border-radius);
  background: white;
}
</style>
