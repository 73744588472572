<template>
  <Modal
    title="Delete Study"
    :activity-text="isDeleting ? 'Deleting study' : ''"
    @header-button-click="emits('close')"
  >
    <div class="study-delete-modal">
      <div>Are you sure you want to delete the following study?</div>

      <div class="patient-details">
        <b>Patient Name</b>
        <div class="row-value">
          {{ formatDicomName(study.patientName, currentTenant.patientNameFormat) }}
        </div>

        <b>{{ currentTenant.patientIdLabel }}</b>
        <div class="row-value">{{ study.patientId }}</div>

        <b>Study Date</b>
        <div class="row-value">
          {{ formatDateTime(getStudyDateTime(study), { includeTime: true }) }}
        </div>

        <b>DICOM Study Instance UID</b>
        <div class="row-value">{{ study.studyInstanceUid }}</div>

        <b>Modality</b>
        <div class="row-value">{{ study.modality }}</div>

        <b>Reported</b>
        <div class="row-value">
          {{ study.reports.some((report) => report.isSigned) ? "Yes" : "No" }}
        </div>
      </div>

      <div class="field">
        <strong>Reason</strong>
        <input
          ref="reasonInputElement"
          v-model="reason"
          placeholder="Enter the reason for deleting this study"
          data-testid="reason-input"
        />
      </div>

      <Checkbox v-model="approved" data-testid="approval-checkbox">
        I approve the deletion of this study and all of its data
      </Checkbox>

      <Tooltip
        :content="buttonTooltip"
        style="width: max-content; align-self: center"
        placement="bottom"
      >
        <button
          class="accented"
          :disabled="!approved || reason === ''"
          data-testid="delete-btn"
          @click="deleteStudy"
        >
          Delete Study
        </button>
      </Tooltip>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import Tooltip from "@/components/Tooltip.vue";
import { addNotification } from "@/utils/notifications";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import { formatDicomName } from "../../../backend/src/shared/dicom-helpers";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import { currentTenant } from "../auth/current-session";
import { formatDateTime } from "../utils/date-time-utils";
import { Study } from "../utils/study-data";
import Checkbox from "./Checkbox.vue";

interface Props {
  study: Study;
}

interface Emits {
  (event: "close"): void;
  (event: "study-deleted"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const reason = ref("");

const reasonInputElement = ref<HTMLInputElement>();
useFocus(reasonInputElement, { initialValue: true });

const approved = ref(false);

const isDeleting = ref(false);

const buttonTooltip = computed(() => {
  if (reason.value.trim().length === 0) {
    return "Enter a reason for deleting this study";
  }

  if (!approved.value) {
    return "Tick the box above to approve deletion of this study";
  }

  return "";
});

async function deleteStudy(): Promise<void> {
  isDeleting.value = true;

  try {
    await axios.delete(`/api/studies/${props.study.id}`, {
      data: { reason: reason.value },
    });
  } catch {
    addNotification({ type: "error", message: "Failed deleting study" });
    return;
  } finally {
    isDeleting.value = false;
  }

  addNotification({ type: "info", message: "Deleted study" });

  emits("study-deleted");
  emits("close");
}
</script>

<style scoped lang="scss">
.study-delete-modal {
  min-width: 440px;
  max-width: 80vw;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  &.is-deleting {
    opacity: 0.5;
  }
}

.patient-details {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 24px;
  row-gap: 4px;
  padding: 12px;

  .row-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
