<template>
  <div
    v-if="studyClip !== undefined"
    class="stress-clip-details-overlay"
    data-testid="stress-overlay"
    :data-test-stage="studyClip.stageName"
    :data-test-view="studyClip.viewName"
    @click="onShowNextClip"
  >
    <Tooltip
      content="Click to cycle through clips"
      placement="left"
      :visible="clipsWithMatchingViewAndStage.length > 1"
    >
      <div class="content" :class="{ 'multiple-clips': clipsWithMatchingViewAndStage.length > 1 }">
        <span>{{ studyClip.stageName }} - {{ studyClip.viewName }}</span>
        <span>{{ timeSinceStudyStart }}</span>
        <span v-if="clipsWithMatchingViewAndStage.length > 1">
          {{ clipsWithMatchingViewAndStage.indexOf(studyClip.id) + 1 }}
          / {{ clipsWithMatchingViewAndStage.length }}
        </span>
      </div>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { toDateTime } from "../../../../backend/src/studies/study-time";
import Tooltip from "../../components/Tooltip.vue";
import { Study, StudyClip } from "../../utils/study-data";
import { getStressClips } from "../study-clip-helpers";

interface Props {
  study: Study;
  studyClip: StudyClip | undefined;
}

interface Emits {
  (event: "change-selected-clip", clipId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const clipsWithMatchingViewAndStage = computed(() => {
  if (props.studyClip === undefined) {
    return [];
  }

  const { viewName, stageName } = props.studyClip;

  return getStressClips(props.study)
    .filter((clip) => clip.viewName === viewName && clip.stageName === stageName)
    .map((clip) => clip.id);
});

const timeSinceStudyStart = computed(() => {
  if (props.studyClip === undefined) {
    return "";
  }

  const studyTakenAt = toDateTime(props.study.takenAt);
  const studyClipTakenAt = toDateTime(props.studyClip.takenAt);
  if (studyTakenAt === undefined || studyClipTakenAt === undefined) {
    return "";
  }

  return studyClipTakenAt.diff(studyTakenAt).toFormat("mm:ss");
});

function onShowNextClip(): void {
  if (props.studyClip === undefined) {
    return;
  }

  const index = clipsWithMatchingViewAndStage.value.indexOf(props.studyClip.id);
  if (index === -1) {
    return;
  }

  emits(
    "change-selected-clip",
    clipsWithMatchingViewAndStage.value[(index + 1) % clipsWithMatchingViewAndStage.value.length]
  );
}
</script>

<style scoped lang="scss">
.stress-clip-details-overlay {
  position: absolute;
  top: 4px;
  right: 4px;
}

.content {
  background-color: var(--bg-color-2);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);

  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px 4px;

  pointer-events: all;
  font-size: 0.9em;
  text-align: right;

  &.multiple-clips {
    cursor: pointer;
    transition: filter 100ms ease;

    &:hover {
      filter: brightness(115%);
    }
  }
}
</style>
