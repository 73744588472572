<template>
  <AuthPage
    :auth-button-visible="isShareLinkValid"
    auth-button-text="Open Study"
    :auth-button-spinner="isCheckingPasscode"
    :auth-button-enabled="isPasscodeValid"
    @submit="checkPasscode"
  >
    <div v-if="isShareLinkValid" class="share-link-prompt">
      <b>Enter the passcode for this study share link</b>

      <PINField
        ref="pinField"
        v-model="passcode"
        :size="6"
        data-testid="passcode-prompt"
        @enter="checkPasscode"
      />
    </div>
    <div v-else class="invalid-share-link">
      This study share link is invalid.
      <br />
      <br />
      Please check the link and try again.
    </div>
  </AuthPage>
</template>

<script setup lang="ts">
import { signInWithStudyShareLink } from "@/auth/authentication";
import PINField from "@/components/PINField.vue";
import router from "@/router";
import { useRouteQuery } from "@vueuse/router";
import { computed, ref } from "vue";
import { SessionCreateResult } from "../../../backend/src/auth/session-create-result";
import AuthPage from "./AuthPage.vue";

const studyIdQuery = useRouteQuery("studyId");
const shareTokenQuery = useRouteQuery("shareToken");

const isShareLinkValid = computed(
  () => typeof studyIdQuery.value === "string" && typeof shareTokenQuery.value === "string"
);

const pinField = ref<{ focusInput: () => void } | null>(null);
const passcode = ref("");

const isCheckingPasscode = ref(false);

const isPasscodeValid = computed(() => /^[0-9]{6}$/.test(passcode.value));

async function checkPasscode(): Promise<void> {
  const studyId = studyIdQuery.value;

  if (
    !isPasscodeValid.value ||
    typeof studyId !== "string" ||
    typeof shareTokenQuery.value !== "string"
  ) {
    return;
  }

  isCheckingPasscode.value = true;

  if (
    (await signInWithStudyShareLink(studyId, shareTokenQuery.value, passcode.value)) !==
    SessionCreateResult.Success
  ) {
    isCheckingPasscode.value = false;
    passcode.value = "";
    pinField.value?.focusInput();
    return;
  }

  await router.push({ name: "study-view", params: { id: studyId } });
}
</script>

<style scoped lang="scss">
.share-link-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.invalid-share-link {
  margin: 32px auto;
  font-weight: bold;
  text-align: center;
}
</style>
