<template>
  <div ref="rootElement" class="wrapper">
    <Popper
      v-if="focusedSection !== null && focusedSectionSentenceGroups.length !== 0"
      show
      placement="left"
      class="report-sentence-library-popper"
    >
      <div />

      <template #content>
        <ReportSentenceLibrary
          :study="study"
          :report="report"
          :focused-section="focusedSection"
          :sentence-groups="focusedSectionSentenceGroups"
          :analytics-enabled="false"
          @close="onCloseSentenceLibrary"
        />
      </template>
    </Popper>

    <PdfViewer v-if="showPdf" :doc-path="reportPdfBlobUri" />

    <Report
      v-else
      :mode="mode"
      :study="study"
      :report="report"
      :signature-data="signatureData"
      :container-width="rootElementWidth"
      :analytics-enabled="false"
      :display-mode="displayMode"
      @mutate-structure="onMutateStructure"
      @section-comment-field-action="onSectionCommentFieldAction"
    />

    <!-- Hidden copy of the report preview that emits the HTML for the final PDF -->
    <Report
      :analytics-enabled="false"
      :study="study"
      :report="report"
      :signature-data="signatureData"
      :mode="ReportContentMode.GenerateHTML"
      :display-mode="displayMode"
      @html-updated="onReportHtmlUpdated"
    />
  </div>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { useElementSize } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { computed, reactive, ref } from "vue";
import {
  MeasurementName,
  isCustomMeasurement,
} from "../../../backend/src/measurements/measurement-names";
import {
  convertMeasurementUnit,
  getInternalUnitForMeasurementName,
  getMeasurementDisplayUnit,
} from "../../../backend/src/measurements/measurement-units";
import { ReportSentenceGroupCreateResponseDto } from "../../../backend/src/reporting/dto/report-sentence-group-create.dto";
import { PatientSex } from "../../../backend/src/studies/patient-sex";
import {
  StudyMeasurementDisplayOption,
  StudyMeasurementValueSource,
} from "../../../backend/src/studies/study-measurement-enums";
import type { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { currentUser } from "../auth/current-session";
import Report from "../reporting/Report.vue";
import ReportSentenceLibrary from "../reporting/ReportSentenceLibrary.vue";
import { useGeneratedReportPdf } from "../reporting/generate-report-pdf";
import { ReportContentMode } from "../reporting/report-content";
import { ReportStructureMutation } from "../reporting/report-structure-mutations";
import { useReportFocusedSection } from "../reporting/use-report-focused-section";
import { addNotification } from "../utils/notifications";
import {
  getEmptyStudy,
  getEmptyStudyReport,
  type ReportTemplateVersion,
  type SignatureData,
  type Study,
  type StudyReport,
} from "../utils/study-data";
import PdfViewer from "@/components/PdfViewer.vue";

interface Props {
  reportTemplateVersion: ReportTemplateVersion;
  mode: ReportContentMode;
  displayMode: StudyReportType;
  showPdf?: boolean;
}

interface Emits {
  (event: "mutate-structure", mutation: ReportStructureMutation): void;
}

const props = withDefaults(defineProps<Props>(), { showPdf: false });
const emits = defineEmits<Emits>();

function onMutateStructure(mutation: ReportStructureMutation): void {
  emits("mutate-structure", mutation);
}

const signatureData: SignatureData = {
  signatureLogoDataUri: currentUser.signatureLogoDataUri,
  signatureDataUri: currentUser.signatureDataUri,
  signatureText: currentUser.signatureText,
};

const study = computed(
  (): Study =>
    reactive({
      ...getEmptyStudy(),
      id: "report-template-editor",
      patientName: ["Skywalker", "Luke"],
      patientId: "JEDI-001",
      patientBirthdate: "1951-09-25",
      takenAt: DateTime.now().setZone("utc", { keepLocalTime: true }).toISO(),
      patientSex: PatientSex.Male,
      patientEthnicity: "American",
      patientHeight: 1.75,
      patientWeight: 80,
      referringPhysician: ["Yoda"],
      performingPhysician: ["Solo", "Han"],
      physicianUserId: currentUser.id,
      traineeUserId: currentUser.id,
      technicianUserId: currentUser.id,
      indication: "? Lightsaber cardiomyopathy.",
      patientMedicalHistory: "Raised on Tatooine and then joined the rebellion.",
      measurements: Object.values(MeasurementName)
        .filter((measurementName) => !isCustomMeasurement(measurementName))
        .map((measurementName) => ({
          id: measurementName,
          studyId: "report-template-editor",
          name: measurementName,
          customName: isCustomMeasurement(measurementName) ? measurementName : "",
          customUnit: null,
          values: [
            {
              id: measurementName,
              measurementTool: null,
              calculationFormula: null,
              calculationOutputUnit: null,
              calculationVariables: null,
              calculationInputs: [],
              measurementCreationBatchId: uuidv4(),
              studyClipId: null,
              value:
                convertMeasurementUnit(
                  99,
                  getMeasurementDisplayUnit(measurementName),
                  getInternalUnitForMeasurementName(measurementName)
                ) ?? 99,
              source: StudyMeasurementValueSource.DICOM,
              selected: true,
              frame: null,
              contour: null,
              measurementId: measurementName,
              createdById: null,
              createdAt: null,
              lastUpdatedById: null,
              lastUpdatedAt: null,
              apiKeyName: null,
            },
          ],
          displayOption: StudyMeasurementDisplayOption.Unindexed,
        })),
    })
);

const report = computed(
  (): StudyReport =>
    reactive({
      ...getEmptyStudyReport(props.reportTemplateVersion),
      id: "report-template-editor",
    })
);

const rootElement = ref<HTMLDivElement>();
const { width: rootElementWidth } = useElementSize(rootElement);

const { onReportHtmlUpdated, reportPdfBlobUri } = useGeneratedReportPdf(
  computed(() => props.showPdf)
);

const {
  focusedSection,
  focusedSectionSentenceGroups,
  onSectionCommentFieldAction,
  onCloseSentenceLibrary,
} = useReportFocusedSection(() => ({
  ...report.value.reportTemplateVersion.structure,
  sentenceGroups,
}));

const sentenceGroups = reactive({}) as Record<string, { name: string; sentences: string[] }>;

void fetchSentenceGroups();

async function fetchSentenceGroups(): Promise<void> {
  let response: AxiosResponse<ReportSentenceGroupCreateResponseDto[]> | undefined = undefined;

  try {
    response = await axios.get<ReportSentenceGroupCreateResponseDto[]>(
      "/api/report-sentence-groups"
    );
  } catch (error) {
    addNotification({ type: "error", message: "Error loading sentence groups" });
    return;
  }

  for (const group of response.data) {
    sentenceGroups[group.id] = {
      name: group.name,
      sentences: group.sentences,
    };
  }
}
</script>

<style scoped lang="scss">
iframe {
  height: 100%;
  width: 100%;
  border: none;
}

:deep(.report-sentence-library-popper) {
  background-color: var(--bg-color-3);
  padding: 0;

  position: absolute;
  left: 44px;
  width: 300px;
}
</style>
