/**
 * This file holds shared code between Doppler measurements (y-position, VTI, and slope) related to
 * handling the midline and other calculations useful for Doppler measurements.
 */

import type { ComputedRef } from "vue";
import { computed } from "vue";
import { MeasuredValue } from "../../../../../backend/src/measurements/measured-value";
import {
  getMidlineYPosition,
  getYPositionOfPointOnDopplerClip,
} from "../../../../../backend/src/measurements/measurement-tool-evaluation-doppler";
import { MeasurementUnit } from "../../../../../backend/src/measurements/measurement-units";
import { RegionUnit, SpatialFormat } from "../../../../../backend/src/studies/study-clip-region";
import type { StudyClip, StudyClipRegion } from "../../../utils/study-data";
import { drawLinearMeasurement } from "../linear/measurement-base-linear";
import { drawXMark } from "../measurement-tool-helpers";

export type DopplerSpatialFormat = SpatialFormat.MMode | SpatialFormat.Spectral;

export interface DopplerMeasurementBase {
  type: DopplerSpatialFormat;
  midlineY: ComputedRef<number | undefined>;
  midline: ComputedRef<number[] | undefined>;

  getYPositionOfPoint: (pt: number[]) => MeasuredValue;
  getYPositionForVelocity: (velocity: MeasuredValue, baselineY: number) => number | null;
  drawMidline: (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    xMarkPosition?: number[]
  ) => void;
}

export function createBaseDopplerMeasurement(
  studyClip: StudyClip | undefined,
  region: StudyClipRegion | undefined
): DopplerMeasurementBase {
  const type =
    region?.spatialFormat === SpatialFormat.MMode ? SpatialFormat.MMode : SpatialFormat.Spectral;

  const midlineY = computed(() => {
    if (region === undefined || studyClip === undefined) {
      return undefined;
    }

    return getMidlineYPosition(region, studyClip);
  });

  const midline = computed(() => {
    if (midlineY.value === undefined || region === undefined || studyClip === undefined) {
      return undefined;
    }

    if (studyClip.height === null || studyClip.width === null) {
      return undefined;
    }

    const response = [
      region.left / studyClip.width,
      midlineY.value,
      region.right / studyClip.width,
      midlineY.value,
    ];

    return response;
  });

  function getYPositionOfPoint(pt: number[]): MeasuredValue {
    if (studyClip === undefined || region === undefined) {
      return MeasuredValue.Null;
    }

    return getYPositionOfPointOnDopplerClip(region, studyClip, pt);
  }

  function getYPositionForVelocity(
    velocity: MeasuredValue,
    baselineY: number | undefined = midlineY.value
  ): number | null {
    if (
      studyClip === undefined ||
      studyClip.height === null ||
      region === undefined ||
      midlineY.value === undefined
    ) {
      return null;
    }

    if (region.yDirectionUnit !== RegionUnit.CentimetersPerSecond) {
      throw Error(
        `Can't find y-position for velocity on clip with y-unit ${region.yDirectionUnit}`
      );
    }

    const valueInCentimetersPerSecond = velocity.getValueAsUnit(
      MeasurementUnit.CentimetersPerSecond
    );
    if (valueInCentimetersPerSecond === null) {
      return null;
    }

    const yPositionInCentiUnits = valueInCentimetersPerSecond / region.physicalDeltaY;

    return (baselineY ?? 0) + yPositionInCentiUnits / studyClip.height;
  }

  function drawMidline(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    xMarkPosition?: number[]
  ): void {
    if (
      studyClip === undefined ||
      studyClip.height === null ||
      studyClip.width === null ||
      region === undefined ||
      midlineY.value === undefined
    ) {
      return;
    }

    drawLinearMeasurement({
      canvas,
      ctx,
      from: [region.left / studyClip.width, midlineY.value],
      to: [region.right / studyClip.width, midlineY.value],
      drawEditHandles: false,
      opacity: 1,
      dotted: true,
    });

    // If the slope hasn't been started, show an x aligned with the mouse
    if (xMarkPosition !== undefined) {
      const x = xMarkPosition[0] * canvas.width;
      const y = midlineY.value * canvas.height;
      drawXMark(ctx, x, y);
    }

    return;
  }

  return {
    type,
    midlineY,
    midline,
    getYPositionOfPoint,
    getYPositionForVelocity,
    drawMidline,
  };
}
