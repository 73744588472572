<template>
  <div
    v-if="
      !isViewingReportStructure &&
      (hasStudyReportPreliminaryFinalizePermission || hasStudyReportAmendmentCompletePermission)
    "
    class="rpt-signature-container"
  >
    <img
      v-if="signatureData.signatureLogoDataUri"
      :src="signatureData.signatureLogoDataUri"
      class="rpt-signature-logo"
      data-testid="signature-logo-data-uri"
    />
    <div v-else />

    <div class="rpt-signature-box">
      <div v-if="signatureData.signatureDataUri">
        <img
          :src="signatureData.signatureDataUri"
          style="height: 8em"
          data-testid="signature-data-uri"
        />
      </div>

      <strong class="rpt-text-normal" data-testid="signature-text">
        <TextWithLineBreaks :text="signatureData.signatureText ?? ''" />
      </strong>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  hasStudyReportAmendmentCompletePermission,
  hasStudyReportPreliminaryFinalizePermission,
} from "../auth/authorization";
import type { SignatureData } from "../utils/study-data";
import TextWithLineBreaks from "./TextWithLineBreaks.vue";
import { ReportContentMode } from "./report-content";

interface Props {
  mode: ReportContentMode;
  signatureData: SignatureData;
}

const props = defineProps<Props>();

const isViewingReportStructure = computed(
  () =>
    props.mode === ReportContentMode.ViewReportStructure ||
    props.mode === ReportContentMode.EditReportStructure
);
</script>

<style scoped lang="scss"></style>
