<template>
  <div
    class="add-report-structure-button rpt-text-normal"
    :class="{ 'single-option': options.length === 1 }"
    data-testid="add-report-structure-button"
    @click="onClickRootElement"
  >
    <div class="dashes" />

    <div class="unhovered-content">
      <FontAwesomeIcon icon="plus" class="plus" />
    </div>

    <div class="hovered-content">
      <template v-for="(option, optionIndex) in options" :key="option.key">
        <button
          class="add-button"
          :data-testid="`add-report-structure-${option.key}`"
          @click="onClickOption(option.key)"
        >
          {{ option.text }}
        </button>

        <div v-if="optionIndex !== options.length - 1" class="dashes short" />
      </template>
    </div>

    <div class="dashes" />
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Props {
  options: { key: string; text: string }[];
}

interface Emits {
  (name: "click", key: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

function onClickRootElement(): void {
  if (props.options.length === 1) {
    emits("click", props.options[0].key);
  }
}

function onClickOption(optionKey: string): void {
  if (props.options.length !== 1) {
    emits("click", optionKey);
  }
}
</script>

<style scoped lang="scss">
.add-report-structure-button {
  padding: 0 0.4em;
  background: none;
  display: flex;
  align-items: center;
  gap: 0.6em;
  height: 2em;
  transition: background-color 100ms ease;
  border-radius: 0.2em;

  &.single-option {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--report-widget-bg-color-hover);

    .dashes {
      border-top-color: var(--report-accent-color-2);
    }

    .unhovered-content {
      display: none;
    }

    .hovered-content {
      display: contents;
    }
  }
}

.unhovered-content {
  color: var(--report-text-color-1);
}

.hovered-content {
  display: none;
}

.dashes {
  flex: 1;
  border-top: 0.1em dashed var(--report-accent-color-1);

  &.short {
    flex: 0.15;
  }
}

button.add-button {
  background: none;
  height: min-content;
  color: var(--report-text-color-1);
  padding: 0;
  font-size: 0.9em;

  &:not(:disabled) {
    &:hover,
    &:active {
      color: var(--report-text-color-2);
      background-color: var(--report-widget-bg-color-hover);
      filter: none;
    }
  }
}
</style>
