<template>
  <div class="measurement-toolbar">
    <div class="current-measurement">
      <Tooltip
        :content="`Cancel measurement ${
          activeMeasurement.editingMeasurementBatchId.value === null ? 'creation' : 'edit'
        }`"
      >
        <button class="close-button" data-testid="exit-measurement" @click="stopMeasuring">
          <FontAwesomeIcon class="accented fa-fw" icon="times" />
        </button>
      </Tooltip>

      <div
        v-if="activeMeasurementSequence === null"
        class="measurement-label"
        data-testid="measurement-label"
      >
        {{ activeMeasurement.displayName }}
      </div>

      <Tooltip v-else style="display: flex">
        <div class="measurement-label" data-testid="sequence-mmt-label">
          Sequence: {{ activeMeasurementSequence.sequence.name }} - Step
          {{ activeMeasurementSequence.currentStepIndex + 1 }} /
          {{ activeMeasurementSequence.sequence.steps.length }}
        </div>

        <template #content>
          <div class="steps-tooltip-content">
            <div
              v-for="(step, index) in activeMeasurementSequence.sequence.steps"
              :key="index"
              class="steps-tooltip-step"
              :class="{ current: activeMeasurementSequence.currentStepIndex === index }"
            >
              <FontAwesomeIcon
                icon="chevron-right"
                :style="{
                  visibility:
                    activeMeasurementSequence.currentStepIndex === index ? 'visible' : 'hidden',
                }"
              />

              Step {{ index + 1 }}:
              {{ getMeasurementDisplayName(step.measurementName!, "unindexed") }}
            </div>
          </div>
        </template>
      </Tooltip>
    </div>

    <div class="help-text">
      <div class="text-ellipsis">
        {{ activeMeasurement.helpText.value }}
      </div>
    </div>

    <div class="toolbar-items">
      <template v-if="activeMeasurement.measurementName.value !== undefined">
        <div v-if="activeMeasurement.isMeasurementNameFixed.value">
          {{ getMeasurementDisplayName(activeMeasurement.measurementName.value, "unindexed") }}
        </div>
        <MeasurementNameSelector
          v-else
          :custom-name="activeMeasurement.customName.value"
          @update:custom-name="(n) => (activeMeasurement.customName.value = n)"
        />
      </template>

      <template v-for="(toolbarItem, index) in activeMeasurement.toolbarItems.value" :key="index">
        <div v-if="toolbarItem.label" class="toolbar-item-label">
          {{ toolbarItem.label }}
        </div>

        <Tooltip :content="toolbarItem.tooltip" placement="top">
          <div
            class="toolbar-item-content"
            :data-testid="`mmt-toolbar-item-${index}`"
            :class="{
              selected: toolbarItem.highlighted === true,
              invalid: toolbarItem.invalid === true,

              interactive: toolbarItem.onClick !== undefined,
              disabled: toolbarItem.enabled === false,
            }"
            @click="toolbarItem.onClick?.()"
          >
            <Checkbox v-if="toolbarItem.checkbox" :model-value="toolbarItem.checkbox.checked">
              {{ toolbarItem.text }}
            </Checkbox>
            <span v-else>{{ toolbarItem.text }}</span>
            <FontAwesomeIcon v-if="toolbarItem.icon" :icon="toolbarItem.icon" />
          </div>
        </Tooltip>
      </template>
    </div>

    <div />

    <div style="display: flex; gap: 8px">
      <Tooltip v-if="activeMeasurement.isBackButtonVisible?.value">
        <button
          class="back-btn"
          data-testid="previous-step-button"
          @click="activeMeasurement.onBackButtonClick?.()"
        >
          <FontAwesomeIcon class="accented" icon="chevron-left" />
          Back
        </button>
      </Tooltip>

      <Tooltip
        v-if="activeMeasurementSequence?.sequence"
        content="Skip this step in the measurement sequence"
      >
        <button
          class="next-btn"
          data-testid="skip-sequence-step"
          @click="advanceMeasurementSequence(study)"
        >
          Skip
        </button>
      </Tooltip>

      <template v-if="activeMeasurement.isSaveButtonVisible.value">
        <MeasurementNameSelector
          v-if="activeMeasurement.measurementName.value === undefined"
          accented
          :custom-name="activeMeasurement.customName.value"
          @update:custom-name="(n) => (activeMeasurement.customName.value = n)"
        />

        <Tooltip v-else :content="saveButtonTooltipText" max-width="350px">
          <button
            class="save-button accented"
            :class="{ 'next-sequence-step-btn': showSaveButtonAsNextSequenceStep }"
            :disabled="
              !activeMeasurement.isSaveButtonEnabled.value ||
              !isStudyUpdatePermitted(study) ||
              activeMeasurement.toolbarItems.value.some((item) => item.invalid) ||
              isSaving
            "
            data-testid="save-measurement-button"
            @click="onSaveMeasurements"
          >
            {{ showSaveButtonAsNextSequenceStep ? "Next Step" : "Save" }}
            <FontAwesomeIcon v-if="showSaveButtonAsNextSequenceStep" icon="chevron-right" />
          </button>
        </Tooltip>
      </template>

      <Tooltip
        v-else
        :content="
          activeMeasurement.isNextButtonEnabled?.value
            ? activeMeasurement.nextButtonTooltip?.value
            : ''
        "
      >
        <button
          class="next-btn accented"
          :disabled="!activeMeasurement.isNextButtonEnabled?.value"
          data-testid="next-step-button"
          @click="activeMeasurement.onNextButtonClick?.()"
        >
          Next <FontAwesomeIcon icon="chevron-right" />
        </button>
      </Tooltip>
    </div>
  </div>

  <ActivityOverlay v-if="activeMeasurement.isProcessing?.value" />
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { hasStudyUpdatePermission, isStudyUpdatePermitted } from "../auth/authorization";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import Checkbox from "../components/Checkbox.vue";
import Tooltip from "../components/Tooltip.vue";
import { onKeyboardShortcut } from "../utils/keyboard-shortcut";
import { Study } from "../utils/study-data";
import MeasurementNameSelector from "./MeasurementNameSelector.vue";
import { changeBatchMeasurements } from "./measurement-save-helpers";
import {
  activeMeasurement,
  activeMeasurementSequence,
  advanceMeasurementSequence,
  stopMeasuring,
} from "./measurement-tool-state";

interface Props {
  study: Study;
}

interface Emits {
  (event: "measurement-changed", measurementId: string): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const saveButtonTooltipText = computed(() => {
  if (!hasStudyUpdatePermission.value) {
    return "You only have permission to view this study";
  }

  if (!isStudyUpdatePermitted(props.study)) {
    return "Measurement can't be saved because the report is signed. Start an amendment to add new measurements.";
  }

  return activeMeasurement.value.saveButtonTooltip?.value ?? "";
});

const showSaveButtonAsNextSequenceStep = computed(() => {
  return (
    activeMeasurementSequence.value !== null &&
    activeMeasurementSequence.value.currentStepIndex !==
      activeMeasurementSequence.value.sequence.steps.length - 1
  );
});

const isSaving = ref(false);

async function onSaveMeasurements(): Promise<void> {
  isSaving.value = true;

  const requests = activeMeasurement.value.getMeasurementChangeRequests();
  const responses = await changeBatchMeasurements(props.study, requests);

  if (responses === undefined) {
    isSaving.value = false;
    return;
  }

  const updatedMeasurementValues = responses.updateResponses.map((r) => r.measurementValue);

  // If a measurement value is now associated with a different measurement, then emit a
  // measurement-changed event so that the new measurement is expanded and scrolled to in the
  // measurement pane.
  for (const request of requests.updates ?? []) {
    const newValue = updatedMeasurementValues.find((m) => m.id === request.measurementValueId);
    if (
      newValue?.measurementId !== request.measurementId &&
      newValue?.measurementId !== undefined
    ) {
      emits("measurement-changed", newValue.measurementId);
      break;
    }
  }

  // Jump the measurement list to the first of the newly created measurements
  if (responses.createResponses.length >= 1) {
    emits("measurement-changed", responses.createResponses[0].measurement.id);
  }

  activeMeasurement.value.onSaved.forEach((cb) => cb(responses));

  if (activeMeasurementSequence.value !== null) {
    advanceMeasurementSequence(props.study);
  } else {
    stopMeasuring();
  }

  isSaving.value = false;
}

// Pressing enter goes to the next step if the button is enabled
onKeyboardShortcut("Enter", () => {
  if (activeMeasurement.value.isSaveButtonVisible.value) {
    void onSaveMeasurements();
  } else if (activeMeasurement.value.isNextButtonEnabled?.value === true) {
    activeMeasurement.value.onNextButtonClick?.();
  }
});
</script>

<style scoped lang="scss">
.measurement-toolbar {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto 1fr auto;
  width: 100%;
  height: 32px;
  gap: 8px;
}

.current-measurement {
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;

  .close-button {
    height: 100%;
    width: 32px;
    border-radius: 0;
  }

  .measurement-label {
    border: 1px solid var(--border-color-1);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    border-left: none;
    color: var(--accent-color-2);
    padding: 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.help-text {
  font-weight: bold;
  display: flex;
  align-self: center;
  color: var(--text-color-1);
}

.toolbar-items {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-color-2);
  font-weight: bold;
}

.toolbar-item-content {
  background-color: var(--bg-color-3);
  height: 32px;
  padding: 0 8px;
  min-width: 16px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color-2);
  transition: background-color 100ms ease;

  &.interactive:hover {
    background-color: var(--bg-color-4);
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.selected {
    background-color: var(--bg-color-4);
    outline: 1px solid var(--accent-color-1);
  }

  &.invalid {
    outline: 1px solid red;
  }
}

.save-button,
.next-btn,
.back-btn {
  width: 75px;

  // Reduce size of the buttons in e2e tests to keep all toolbar content visible
  &.is-e2e-test {
    width: 60px;
  }
}

.next-sequence-step-btn {
  width: 100px;
}

.back-btn {
  color: var(--accent-color-2);
}

.accented {
  color: var(--accent-color-2);
}

.text-ellipsis {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.steps-tooltip-content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 6px 8px;
  place-items: center left;
}

.steps-tooltip-step {
  display: contents;

  &.current {
    font-weight: bold;
  }
}
</style>
