import { computed, ref, shallowRef } from "vue";
import type { MeasurementToolName } from "../../../backend/src/measurements/measurement-tool-names";
import { postMessageToPrimaryWindow } from "../study-view/multi-window/primary-window-messages";
import { WindowType, getWindowType } from "../study-view/multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "../study-view/multi-window/secondary-window-messages";
import type { MeasurementSequence, Study, StudyClipRegion } from "../utils/study-data";
import { getCreationFunctionForMeasurementToolName } from "./measurement-tool-helpers";
import type { MeasurementTool } from "./tools/measurement-tool";
import { createNullMeasurementTool } from "./tools/measurement-tool-null";

export type MeasurementToolCreationFunction = (
  study: Study,
  studyClipId: string,
  region: StudyClipRegion | undefined
) => Readonly<MeasurementTool>;

/**
 * The currently active measurement tool. This will be set to a null measurement tool when measuring
 * isn't currently active, and this inactive state can be checked using the `isMeasuring` ref.
 */
export const activeMeasurement = shallowRef(createNullMeasurementTool());

/** Whether there is a currently active measurement in progress. */
export const isMeasuring = computed(() => activeMeasurement.value.displayName !== "null");

/** Starts using the measurement tool returned by the specified creation function. */
export function startMeasuring(opts: {
  tool: MeasurementToolName;
  study: Study;
  clipId: string;
  region?: StudyClipRegion;
  informOtherWindows?: boolean;
}): void {
  const { tool, study, clipId, region, informOtherWindows } = opts;
  const creationFn = getCreationFunctionForMeasurementToolName(tool);

  restartMeasuringFunction = (
    studyClipId: string | undefined,
    newRegion: StudyClipRegion | undefined
  ): void => {
    const previousStudyClipId = activeMeasurement.value.studyClipId;
    const previousMeasurementName = activeMeasurement.value.measurementName.value;
    const previousMeasurementCustomName = activeMeasurement.value.customName.value;
    const previousMeasurementFixed = activeMeasurement.value.isMeasurementNameFixed.value;
    const previousOnSaved = activeMeasurement.value.onSaved;
    const previousOnDestroy = activeMeasurement.value.onDestroy;

    activeMeasurement.value = creationFn(study, studyClipId ?? previousStudyClipId, newRegion);
    activeMeasurement.value.measurementName.value = previousMeasurementName;
    activeMeasurement.value.customName.value = previousMeasurementCustomName;
    activeMeasurement.value.isMeasurementNameFixed.value = previousMeasurementFixed;
    activeMeasurement.value.onSaved.push(...previousOnSaved);
    activeMeasurement.value.onDestroy.push(...previousOnDestroy);
  };

  // The selected measurement name can't be preserved when changing the measurement tool, it can
  // only be preserved when restarting the same measurement tool
  activeMeasurement.value.measurementName.value = undefined;

  restartMeasuringFunction(clipId, region);

  if (informOtherWindows === true) {
    if (getWindowType() === WindowType.Primary) {
      postMessageToSecondaryWindow({ type: "set-measurement-tool", tool });
    } else {
      postMessageToPrimaryWindow({ type: "set-measurement-tool", tool });
    }
  }
}

/**
 * Restarts the currently selected measurement tool with the specified study clip and region. If the
 * study clip ID is undefined then the previous study clip ID will be used.
 */
export function restartMeasuring({
  studyClipId,
  region,
}: {
  studyClipId: string | undefined;
  region: StudyClipRegion | undefined;
}): void {
  restartMeasuringFunction?.(studyClipId, region);
}

/** Aborts any currently in-progress measurement. */
export function stopMeasuring(): void {
  if (activeMeasurement.value.displayName === "null") {
    return;
  }

  activeMeasurement.value.onDestroy.forEach((cb) => cb());
  activeMeasurement.value = createNullMeasurementTool();
  activeMeasurementSequence.value = null;
  restartMeasuringFunction = undefined;

  if (getWindowType() === WindowType.Primary) {
    postMessageToSecondaryWindow({ type: "set-measurement-tool", tool: null });
  } else {
    postMessageToPrimaryWindow({ type: "set-measurement-tool", tool: null });
  }
}

let restartMeasuringFunction:
  | ((studyClipId: string | undefined, region: StudyClipRegion | undefined) => void)
  | undefined = undefined;

/**
 * The currently active measurement sequence. Will be null if there is no in-progress sequence.
 */
export const activeMeasurementSequence = ref<{
  sequence: MeasurementSequence;
  currentStepIndex: number;
} | null>(null);

/** Starts a measurement sequence and activates its first step. */
export function beginMeasurementSequence(
  study: Study,
  sequenceId: string,
  measurementSequenceList: MeasurementSequence[]
) {
  if (activeMeasurementSequence.value?.sequence.id === sequenceId) {
    return;
  }

  const sequence = measurementSequenceList.find((s) => s.id === sequenceId);
  if (sequence === undefined) {
    return;
  }

  if (sequence.steps[0].measurementTool === null) {
    return;
  }

  activeMeasurementSequence.value = {
    sequence,
    currentStepIndex: -1,
  };

  setActiveMeasurementSequenceStep({ stepIndex: 0, study });
}

/**
 * Moves to the next step of the active measurement sequence, or completes it if the last step is
 * currently active.
 */
export function advanceMeasurementSequence(study: Study): void {
  if (!activeMeasurementSequence.value) {
    return;
  }

  setActiveMeasurementSequenceStep({
    stepIndex: activeMeasurementSequence.value.currentStepIndex + 1,
    study,
  });
}

export function setActiveMeasurementSequenceStep(opts: { stepIndex: number; study: Study }): void {
  const { stepIndex, study } = opts;

  if (activeMeasurementSequence.value === null) {
    return;
  }

  if (stepIndex === activeMeasurementSequence.value.currentStepIndex) {
    return;
  }

  // If the requested step index is past the end then stop measuring and clear the current sequence
  if (stepIndex >= activeMeasurementSequence.value.sequence.steps.length) {
    stopMeasuring();
    return;
  }

  const step = activeMeasurementSequence.value.sequence.steps[stepIndex];
  if (step.measurementTool === null || step.measurementName === null) {
    return;
  }

  startMeasuring({ tool: step.measurementTool, study, clipId: "" });

  activeMeasurement.value.measurementName.value = step.measurementName;
  activeMeasurement.value.isMeasurementNameFixed.value = true;
  activeMeasurementSequence.value.currentStepIndex = stepIndex;

  if (getWindowType() === WindowType.Primary) {
    postMessageToSecondaryWindow({
      type: "set-measurement-sequence",
      sequenceId: activeMeasurementSequence.value.sequence.id,
      stepIndex,
    });
  } else {
    postMessageToPrimaryWindow({
      type: "set-measurement-sequence",
      sequenceId: activeMeasurementSequence.value.sequence.id,
      stepIndex,
    });
  }
}
