<template>
  <Modal title="Remove MFA" :activity-text="activityText" @header-button-click="emits('close')">
    <div class="totp-delete-modal">
      <div>Enter your password to remove MFA from your account</div>

      <PasswordInput
        v-model="password"
        initial-focus
        data-testid="totp-delete-password"
        @enter="deleteMfa"
      />

      <button
        data-testid="remove-mfa-button"
        :disabled="!isPasswordValid"
        style="margin: 0 auto"
        class="accented"
        @click="deleteMfa"
      >
        Remove MFA
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useFocus } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import { PASSWORD_MIN_LENGTH } from "../../../backend/src/auth/password-strength-check";
import Modal from "../components/Modal.vue";
import PasswordInput from "../components/PasswordInput.vue";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { currentUser } from "./current-session";

interface Emits {
  (event: "close"): void;
}

const emits = defineEmits<Emits>();

const activityText = ref("");

const password = ref("");

const passwordInput = ref<HTMLInputElement | null>(null);
useFocus(passwordInput, { initialValue: true });

const isPasswordValid = computed(() => password.value.length >= PASSWORD_MIN_LENGTH);

async function deleteMfa(): Promise<void> {
  if (!isPasswordValid.value) {
    return;
  }

  activityText.value = "Removing MFA...";

  try {
    await axios.post(`/api/user/current/mfa/totp-delete`, { password: password.value });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed removing MFA",
    });
    return;
  } finally {
    activityText.value = "";
  }

  currentUser.mfaEnabled = false;

  addNotification({ type: "info", message: "MFA removed" });
  emits("close");
}
</script>

<style scoped lang="scss">
.totp-delete-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
}
</style>
