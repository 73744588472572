import type { StudyClip, StudySeries } from "../../utils/study-data";

export function isCT3DSeries(series: StudySeries, clip: StudyClip): boolean {
  let clipImageTypeIsLocalizer = false;
  if (
    Array.isArray(clip.imageType) &&
    clip.imageType.some((imageType) => imageType.toLocaleLowerCase() === "localizer")
  ) {
    clipImageTypeIsLocalizer = true;
  }

  return (
    clip.modality === "CT" &&
    clip.scanOptions.every((option) => !option.toLowerCase().includes("scout")) &&
    !clipImageTypeIsLocalizer &&
    series.rescaleSlope !== null &&
    series.rescaleIntercept !== null
  );
}
