<template>
  <ClipsAreaBase
    v-model:playback-speed-factor="playbackSpeedFactor"
    :study="study"
    :clips-grid-items="clipsGridItems"
    :column-count="columnCount"
    :row-count="rowCount"
    :focused-clip-index="focusedClipIndex"
    :is-clip-sync-enabled="isClipSyncEnabled"
    is-stress-mode-enabled
    :show-measurements="showMeasurements"
    @update:is-clip-sync-enabled="emits('update:is-clip-sync-enabled', $event)"
    @update:is-stress-mode-enabled="emits('update:is-stress-mode-enabled', $event)"
    @update:focused-clip-index="emits('update:focused-clip-index', $event)"
    @update:show-measurements="emits('update:show-measurements', $event)"
    @scroll-to-measurement="
      (measurementId, openMeasurementPane) =>
        emits('scroll-to-measurement', measurementId, openMeasurementPane)
    "
  >
    <template #clipOverlay="{ index }">
      <StressClipDetailsOverlay
        :study="study"
        :study-clip="clipsGridItems[index].clip"
        @change-selected-clip="onChangeSelectedClip(index, $event)"
      />
    </template>

    <template #toolbarItems>
      <b>
        {{ viewNames.indexOf(stressModeSelectedView) + 1 }}
        /
        {{ viewNames.length }}
      </b>

      <div class="packed-controls">
        <Tooltip content="Previous view" shortcut="⇦">
          <button data-testid="stress-view-previous-btn" @click="stepView(-1)">
            <FontAwesomeIcon icon="chevron-left" />
          </button>
        </Tooltip>
        <Tooltip content="Next view" shortcut="⇨">
          <button data-testid="stress-view-next-btn" @click="stepView(1)">
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </Tooltip>
      </div>
    </template>
  </ClipsAreaBase>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStorage } from "@vueuse/core";
import { computed, watch } from "vue";
import { positiveModulo } from "../../../../backend/src/shared/math-utils";
import Tooltip from "../../components/Tooltip.vue";
import { onKeyboardShortcut } from "../../utils/keyboard-shortcut";
import { Study } from "../../utils/study-data";
import ClipsAreaBase from "../ClipsAreaBase.vue";
import { ClipsGridItem } from "../clip-viewer/clips-grid-item";
import {
  onPrimaryWindowMessageReceived,
  postMessageToPrimaryWindow,
} from "../multi-window/primary-window-messages";
import {
  SecondaryWindowState,
  WindowType,
  getSecondaryWindowState,
  getWindowType,
} from "../multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "../multi-window/secondary-window-messages";
import { getStressClipsSortedByViewAndStage, getStressViewNames } from "../study-clip-helpers";
import StressClipDetailsOverlay from "./StressClipDetailsOverlay.vue";

interface Props {
  study: Study;
  clipsGridItems: ClipsGridItem[];
  selectedClipIds: string[];
  focusedClipIndex: number;
  isClipSyncEnabled: boolean;
  stressModeSelectedView: string;
  showMeasurements: boolean;
  stressModeShowAllClipsInByView: boolean;
}

interface Emits {
  (event: "update:focused-clip-index", index: number): void;
  (event: "update:is-clip-sync-enabled", enabled: boolean): void;
  (event: "update:is-stress-mode-enabled", enabled: boolean): void;
  (event: "update:stress-mode-selected-view", viewName: string): void;
  (event: "update:show-measurements", enabled: boolean): void;
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const playbackSpeedFactor = useStorage("stress-playback-speed-factor", 1);

const columnCount = computed(() => {
  if (!props.stressModeShowAllClipsInByView) {
    return 2;
  }

  return Math.min(Math.ceil(Math.sqrt(selectedViewStageClipIds.value.flat().length)), 4);
});

const rowCount = computed(() => {
  const entryCount = props.stressModeShowAllClipsInByView
    ? selectedViewStageClipIds.value.flat().length
    : selectedViewStageClipIds.value.length;

  return Math.min(Math.ceil(entryCount / columnCount.value), 4);
});

const viewNames = computed(() => getStressViewNames(props.study));

function onChangeSelectedClip(clipIndex: number, clipId: string): void {
  const selectedClipIds = props.selectedClipIds;
  selectedClipIds[clipIndex] = clipId;
}

function stepView(step: -1 | 1): void {
  const currentViewIndex = viewNames.value.indexOf(props.stressModeSelectedView);
  if (currentViewIndex === -1) {
    return;
  }

  // If this is a secondary window, send a message to the primary window to step the view as
  // all view changes are handled by the primary window
  if (getWindowType() !== WindowType.Primary) {
    postMessageToPrimaryWindow({ type: "stress-mode-step-view", step });
    return;
  }

  const isSecondaryWindowOpen = getSecondaryWindowState() === SecondaryWindowState.Open;

  // If the secondary window is open, then step by 2 so that new views are shown on both windows
  const nextViewIndex = currentViewIndex + step * (isSecondaryWindowOpen ? 2 : 1);

  emits(
    "update:stress-mode-selected-view",
    viewNames.value[positiveModulo(nextViewIndex, viewNames.value.length)]
  );

  // Set the secondary window stress view to be the view after this one
  postMessageToSecondaryWindow({
    type: "set-stress-mode-view",
    viewName: viewNames.value[positiveModulo(nextViewIndex + 1, viewNames.value.length)],
  });
}

onKeyboardShortcut("ArrowLeft", () => stepView(-1));
onKeyboardShortcut("ArrowRight", () => stepView(1));

const selectedViewStageClipIds = computed(() => {
  const selectedViewIndex = viewNames.value.indexOf(props.stressModeSelectedView);
  if (selectedViewIndex === -1) {
    return [];
  }

  return getStressClipsSortedByViewAndStage(props.study)[selectedViewIndex];
});

function setDefaultSelectedClips(): void {
  const selectedClipIds = props.selectedClipIds;

  const newClips = props.stressModeShowAllClipsInByView
    ? selectedViewStageClipIds.value.flat()
    : selectedViewStageClipIds.value
        .map((clipIds) => clipIds[0] ?? "")
        .filter(Boolean)
        .slice(0, columnCount.value * rowCount.value);

  selectedClipIds.splice(0, selectedClipIds.length, ...newClips);

  // Make sure there are the correct number of selected clip ids
  while (selectedClipIds.length < rowCount.value * columnCount.value) {
    selectedClipIds.push("");
  }
}

// Update selected clips when the study or selected view changes
watch(
  () => [props.study, props.stressModeSelectedView, props.stressModeShowAllClipsInByView],
  setDefaultSelectedClips,
  { immediate: true }
);

if (getWindowType() === WindowType.Primary) {
  // Update selected clips when the secondary window changes the selected view
  onPrimaryWindowMessageReceived("stress-mode-step-view", (message) => {
    if (message.step === 1 || message.step === -1) {
      stepView(message.step);
    }
  });
}
</script>
