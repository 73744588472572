import type { ShallowReactive } from "vue";
import { computed, ref, shallowReactive, type ComputedRef, type Ref } from "vue";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { activeMeasurement, stopMeasuring } from "../../measurements/measurement-tool-state";
import type { Study, StudyClip } from "../../utils/study-data";
import { createClipModel, type ClipModel } from "../clip-model";
import { isCT3DSeries } from "../ct/ct-helpers";
import { createCTModel, type CTModel } from "../ct/ct-model";

/**
 * The types of content that can be present inside a position inside the study viewer clips grid.
 * - `RegularClip` refers to any 2D clip, such as an echo, angiogram, single 2D frame, etc.
 * - `CTClip` specifically refers to an item that is represented by a NRRD 3D mesh file.
 *
 * At present it's possible for a CT to be represented as a 2D clip where the .webp files are used
 * to show a video at a fixed window level moving vertically in the axial direction. In the future
 * once CT is expanded we're likely to remove the concept of "CT Mode" and use only the 3D viewer.
 */
export enum ClipsGridItemType {
  Null = "Null",
  RegularClip = "RegularClip",
  CTClip = "CTClip",
}

/**
 * The set of styles that are applied to the scrubber handle and track progress bar in the viewer
 * in order to control the current percentage based position in the full scrollbar.
 */
interface ScrubberProgressBarStyles {
  progressBarLeft: string;
  progressBarWidth: string;
  handleLeft: string;
}

/**
 * The shared properties between all types of ClipsGridItems. This should be combined with a *Model
 * object in order to create a certain type of *ClipsGridItem. At present only regular clips and
 * CT (3D mesh) type clips items are supported, but it's set up in this way so we can easily add
 * new grid items such as embedding PDFs in the clips grid.
 */
export interface BaseClipsGridItem {
  /** The type of content that this clips grid item represents.  */
  type: ClipsGridItemType;

  /**
   * The clip that is linked to this clips grid item, if any. If this grid item is a CT clip, this
   * is the faked clip that is used for that series at present.
   *
   * TODO: The faked clip concept for series should be removed at some point in the future. It might
   * not be easily doable until we depreciate the 2D CT viewer, as the .webp loading strategy is
   * fairly tightly integrated with the singular clip concept.
   */
  clip?: StudyClip;

  /** Whether the scrubber on this clips grid item is being moved. */
  isScrubbing: Ref<boolean>;

  /**
   * Whether the clip is currently being dragged over as part of a drag-and-drop from the clip list.
   */
  isDraggedOver: Ref<boolean>;

  /** Whether the clip is currently loading and a loading indicator should be shown. */
  isLoading: Ref<boolean> | ComputedRef<boolean>;

  /**
   * The styles that should be applied to the scrubber handle and progress bar in order to show the
   * current position throughout this grid item.
   */
  scrubberStyles: ComputedRef<ScrubberProgressBarStyles>;

  /**
   * The text that should be displayed while this grid item is loading, if any.
   */
  loadingText: ComputedRef<string | null>;

  /**
   * The action that should run when a single frame is stepped in the viewer, such as via the period
   * and comma keyboard shortcuts, if any.
   */
  onStepFrame: (delta: number) => void;

  /**
   * The actions that should be ran when this grid item is no longer in use, such as cancelling
   * in-progress data loading.
   */
  destroy?: () => void;
}

/**
 * A ClipsGridItem that represents no data in that item slot. Used to fill in empty spaces in.
 */
export type NullClipsGridItem = BaseClipsGridItem & { type: ClipsGridItemType.Null };

/**
 * A ClipsGridItem that represents a regular clip, such as an echo, angiogram, or single 2D frame.
 */
export type RegularClipsGridItem = BaseClipsGridItem &
  ClipModel & { type: ClipsGridItemType.RegularClip };

/**
 * A ClipsGridItem that represents a CT series, which is a 3D mesh file that is loaded in the viewer
 */
export type CTClipsGridItem = BaseClipsGridItem & CTModel & { type: ClipsGridItemType.CTClip };

export type ClipsGridItem = NullClipsGridItem | RegularClipsGridItem | CTClipsGridItem;

/**
 * Creates an array of grid items for use with a ClipsArea component. The grid items array is a
 * ShallowReactive in order to have reactivity when creating new grid items and putting them in
 * this array, while avoiding unwanted deep reactivity as grid items handle reactivity themselves.
 */
export function createClipsGridItemsArray(count: number): ClipsGridItem[] {
  return shallowReactive(
    Array(count)
      .fill(null)
      .map((_) => createNullClipsGridItem())
  );
}

/** Creates a empty clips grid item used to represent a empty space in the clips grid. */
export function createNullClipsGridItem(): NullClipsGridItem {
  return {
    type: ClipsGridItemType.Null,
    isScrubbing: ref(false),
    isDraggedOver: ref(false),
    scrubberStyles: computed(() => ({ progressBarLeft: "", progressBarWidth: "", handleLeft: "" })),
    isLoading: computed(() => false),
    loadingText: computed(() => null),
    onStepFrame: () => null,
  };
}

/** Creates a regular clips grid item from a provided ClipModel. */
export function createRegularClipsGridItem(model: ClipModel): RegularClipsGridItem {
  const isLoading = computed(() => model.clip?.processedAt === null);
  const loadingText = computed(() =>
    model.clip?.processedAt === null ? "Image data is being processed" : null
  );

  const scrubberStyles = computed(() => {
    const clip = model.clip;
    if (clip === undefined || clip.frameCount === null) {
      return { progressBarLeft: "", progressBarWidth: "", handleLeft: "" };
    }

    const heartbeat = model.getSelectedHeartbeat();
    const frameOffset = heartbeat === undefined ? 0 : heartbeat.firstFrame;

    const progressBarLeft = `${100 * (frameOffset / clip.frameCount)}%`;
    const progressBarWidth = `${100 * (model.getCurrentTime() / model.clipDuration - frameOffset / clip.frameCount)}%`;

    return {
      progressBarLeft,
      progressBarWidth,
      handleLeft: `${(model.getCurrentTime() / model.clipDuration) * 100}%`,
    };
  });

  return {
    type: ClipsGridItemType.RegularClip,
    isLoading,
    isDraggedOver: ref(false),
    loadingText,
    scrubberStyles,
    ...model,
  };
}

/** Creates a CT clips grid item from a provided CTModel. */
export function createCTClipsGridItem(model: CTModel): CTClipsGridItem {
  const loadingText = computed(() => null);

  const scrubberStyles = computed(() => {
    const percentage = model.sliceNumber.value / model.maxSliceNumber.value;

    return {
      progressBarLeft: "0%",
      progressBarWidth: `${percentage * 100}%`,
      handleLeft: `${percentage * 100}%`,
    };
  });

  return {
    type: ClipsGridItemType.CTClip,
    isScrubbing: ref(false),
    isDraggedOver: ref(false),
    loadingText,
    scrubberStyles,
    ...model,
  };
}

/**
 * Converts the regular clips grid item at the current index to a CT (3D mesh) item. This is used
 * when CT Mode is entered on that clip.
 */
export function switchRegularGridItemToCT(
  clipsGridItems: ClipsGridItem[],
  study: Study,
  index: number
): void {
  const series = study.series.find((s) => s.id === clipsGridItems[index].clip?.seriesId);

  if (series === undefined) {
    return;
  }

  clipsGridItems[index].destroy?.();
  clipsGridItems[index] = createCTClipsGridItem(createCTModel(study, series));
}

/**
 * Converts the CT clips grid item at the current index to a regular item. This is used when CT Mode
 * is exited on that clip.
 */
export function switchCTGridItemToRegular(
  clipsGridItems: ClipsGridItem[],
  study: Study,
  index: number
): void {
  const clip = clipsGridItems[index].clip;

  if (clip === undefined) {
    return;
  }

  clipsGridItems[index].destroy?.();
  clipsGridItems[index] = createRegularClipsGridItem(createClipModel(study, clip));
}

/**
 * Updates an array of grid items to have items that match the set of desired clip IDs. Clip
 * models will be destroyed and created as required in order to achieve this state.
 */
export function updateClipsGridItemsArrayFromSelectedClips(
  clipsGridItems: ClipsGridItem[],
  study: Study,
  desiredClipIds: string[],
  isClipSyncEnabled: boolean,
  isMultiStudyGrid = false
): void {
  for (let i = 0; i < clipsGridItems.length; i++) {
    const desiredClipId = i < desiredClipIds.length ? desiredClipIds[i] : undefined;
    const currentClipId = clipsGridItems[i].clip?.id;

    const desiredClip = getClips(study).find((c) => c.id === desiredClipId);
    const desiredSeries = study.series.find((s) => s.id === desiredClip?.seriesId);

    // If the selected clip hasn't changed, and exists in the study, then there's nothing to do
    // OR if this is a multi-study grid and the clip isn't in the study, skip as it will get handled
    // in the other study's update pass, as otherwise this would destroy the other study's image
    if (
      (desiredClipId === currentClipId && desiredClip !== undefined) ||
      (isMultiStudyGrid && desiredClip === undefined)
    ) {
      continue;
    }

    clipsGridItems[i].destroy?.();

    if (desiredClip === undefined) {
      clipsGridItems[i] = createNullClipsGridItem();
      continue;
    }

    const previousItem = clipsGridItems[i];

    const isHeartbeatSelected =
      previousItem.type === ClipsGridItemType.RegularClip &&
      previousItem.getSelectedHeartbeatIndex() !== undefined;

    if (
      previousItem.type === ClipsGridItemType.CTClip &&
      desiredSeries?.nrrdProcessState === NRRDProcessState.Completed &&
      isCT3DSeries(desiredSeries, desiredClip)
    ) {
      clipsGridItems[i] = createCTClipsGridItem(createCTModel(study, desiredSeries));
    } else {
      clipsGridItems[i] = createRegularClipsGridItem(createClipModel(study, desiredClip));

      if (isHeartbeatSelected || isClipSyncEnabled) {
        (clipsGridItems[i] as RegularClipsGridItem).setSelectedHeartbeatIndex(0);
      }
    }
  }

  // Cancel measurement editing if the clip for the measurement value being edited is no longer
  // visible
  if (activeMeasurement.value.editingMeasurementBatchId.value !== null) {
    for (const measurement of study.measurements) {
      for (const value of measurement.values) {
        if (
          value.measurementCreationBatchId ===
            activeMeasurement.value.editingMeasurementBatchId.value &&
          value.studyClipId !== null
        ) {
          if (!desiredClipIds.includes(value.studyClipId)) {
            stopMeasuring();
          }

          break;
        }
      }
    }
  }
}

/** Extracts the regular clips grid items from a items array with the appropriate output typing. */
export function getRegularClipsGridItems(clipsGridItems: ClipsGridItem[]): RegularClipsGridItem[] {
  return clipsGridItems.filter(
    (item): item is RegularClipsGridItem => item.type === ClipsGridItemType.RegularClip
  );
}

/**
 * Sets up event handlers for updating selected clips when a grid item is dragged over or dropped
 * by a element from the clip list.
 */

export function useGridItemDragDropEventHandlers(
  gridItems: ShallowReactive<ClipsGridItem[]>,
  selectedClipIds: string[]
): {
  onDrop: (dragEvent: DragEvent, selectedClipIndex: number) => void;
  onDragOver: (dragEvent: DragEvent, clipIndex: number) => void;
  onDragLeave: (clipIndex: number) => void;
} {
  function onDrop(dragEvent: DragEvent, selectedClipIndex: number): void {
    const clipId = dragEvent.dataTransfer?.getData("clipId");
    if (clipId === undefined || clipId === "") {
      return;
    }

    selectedClipIds[selectedClipIndex] = clipId;

    dragEvent.preventDefault();
  }

  function onDragOver(dragEvent: DragEvent, clipIndex: number): void {
    const gridItem = gridItems[clipIndex];

    gridItem.isDraggedOver.value = dragEvent.dataTransfer?.types.includes("clipid") ?? false;

    if (gridItem.isDraggedOver.value) {
      dragEvent.preventDefault();
    }
  }

  function onDragLeave(clipIndex: number): void {
    const gridItem = gridItems[clipIndex];
    gridItem.isDraggedOver.value = false;
  }

  return { onDrop, onDragOver, onDragLeave };
}
