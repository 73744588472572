<template>
  <Tooltip
    v-if="!isOpen"
    :content="isWebkit() ? 'Color mapping is not supported on Safari' : 'Select clip color map'"
  >
    <div class="color-map-selector">
      <div
        class="color-map-container"
        data-testid="color-map-selector"
        @click="isOpen = !isWebkit() && !isOpen"
      >
        <div class="color-map color-map-selected">
          <FontAwesomeIcon
            :icon="['fad', 'circle-half']"
            transform="rotate-45"
            :class="`color-map-${(props.colorMap ?? ColorMap.Grey).toLowerCase()}`"
          />
        </div>
        <div class="down-chevron">
          <FontAwesomeIcon :icon="'chevron-down'" style="color: #6d6d6d" />
        </div>
      </div>
    </div>
  </Tooltip>

  <Tooltip v-else>
    <div class="color-map-selector">
      <div class="color-map-options-container" @click="isOpen = !isOpen">
        <div v-for="color in ColorMap" :key="color">
          <Tooltip :content="color">
            <div class="color-map-option">
              <FontAwesomeIcon
                :icon="['fad', 'circle-half']"
                transform="rotate-45"
                :data-testid="`color-map-option-${color.toLowerCase()}`"
                :class="`color-map-${color.toLowerCase()}`"
                @click="emits('update:clip-color-map', color)"
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import Tooltip from "../components/Tooltip.vue";
import { ColorMap } from "../utils/color-map";
import { isWebkit } from "../utils/user-agent";

interface Props {
  colorMap: ColorMap | undefined;
}

interface Emits {
  (event: "update:clip-color-map", newValue: ColorMap): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const isOpen = ref(false);
</script>

<style scoped lang="scss">
.color-map-selector {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  background-color: var(--bg-color-3);
  height: 32px;
  transition: background-color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
    background-color: var(--bg-color-4);
  }

  &.closed {
    cursor: pointer;
  }
}

.color-map-aqua {
  --fa-primary-color: #00d1b2;
  --fa-secondary-color: #00d1b2;
  --fa-secondary-opacity: 0.5;
}

.color-map-purple {
  --fa-primary-color: #b86bff;
  --fa-secondary-color: #b86bff;
  --fa-secondary-opacity: 0.5;
}

.color-map-sepia {
  --fa-primary-color: #f4a460;
  --fa-secondary-color: #f4a460;
  --fa-secondary-opacity: 0.5;
}

.color-map-grey {
  --fa-primary-color: #f1f2f2;
  --fa-secondary-color: #aaaaaa;
  --fa-secondary-opacity: 1;
}

.down-chevron {
  place-content: center;
  padding-left: 8px;
}

.color-map {
  flex: 1;
}

.color-map-container {
  align-self: stretch;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  place-content: center;
  cursor: pointer;
}

.color-map-options-container {
  display: flex;
}

.color-map-selected {
  flex: 1;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border: 1px solid;
  border-color: transparent;
}

.color-map-option {
  flex: 1;
  margin: 8px;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border: 1px solid;
  border-color: transparent;
  &:hover {
    cursor: pointer;
    border: 1px solid #f1f2f2;
  }
}
</style>
