<template>
  <Modal
    title="Delete Measurements"
    :activity-text="activityText"
    @header-button-click="closeModal"
  >
    <div class="delete-measurement-values">
      Please confirm deletion of the following measurement values:

      <div class="measurements-table">
        <template v-for="mmt of measurementValuesToDeleteLabels" :key="mmt">
          <b>{{ mmt.name }}</b>
          <span>{{ mmt.value }}</span>
        </template>
      </div>

      <button class="accented" data-testid="delete-measurement-values" @click="deleteMeasurements">
        Delete
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { getDependentMeasurementValues } from "../../../backend/src/measurements/measurement-calculation-evaluation";
import {
getMeasurementDisplayName,
getStudyMeasurementDisplayValue,
} from "../../../backend/src/measurements/measurement-display";
import { isCustomMeasurement } from "../../../backend/src/measurements/measurement-names";
import { StudyMeasurementValueSource } from "../../../backend/src/studies/study-measurement-enums";
import Modal from "../components/Modal.vue";
import { addNotification } from "../utils/notifications";
import type { Study, StudyMeasurement, StudyMeasurementValue } from "../utils/study-data";
import { findMainMeasurementAndValueForBatch, getValuesInBatch } from "./measurement-helpers";
import { changeBatchMeasurements } from "./measurement-save-helpers";
import { activeMeasurement, stopMeasuring } from "./measurement-tool-state";

interface Props {
  study: Study;
  measurement: StudyMeasurement;
  valueToDelete: StudyMeasurementValue;
}

interface Emits {
  (event: "close"): void;
  (event: "deleted"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const activityText = ref("");

const measurementValuesToDelete = computed(() => {
  let result = [{ measurement: props.measurement, value: props.valueToDelete }];

  // If this measurement value was the main measurement in its batch then all measurements in the
  // batch are to be deleted as well so that the others don't get orphaned
  if (
    findMainMeasurementAndValueForBatch(props.study, props.valueToDelete.measurementCreationBatchId)
      ?.value.id === props.valueToDelete.id
  ) {
    const valuesInBatch = getValuesInBatch(
      props.study,
      props.valueToDelete.measurementCreationBatchId
    );

    for (const value of valuesInBatch) {
      const measurement = props.study.measurements.find((m) => m.id === value.measurementId);
      if (measurement === undefined) {
        throw Error("Failed finding measurement");
      }

      if (value.id !== props.valueToDelete.id) {
        result.push({ measurement, value });
      }
    }
  }

  // We also need to delete calculations that are dependent on any measurements being deleted
  const allCalculatedValues = props.study.measurements
    .flatMap((m) => m.values)
    .filter((v) => v.source === StudyMeasurementValueSource.Calculated);

  for (const value of result.map((m) => m.value)) {
    for (const dependentValue of getDependentMeasurementValues(
      value,
      allCalculatedValues,
    )) {
      const measurement = props.study.measurements.find(
        (m) => m.id === dependentValue.measurementId
      );
      if (measurement === undefined) {
        throw Error("Failed finding measurement");
      }

      result.push({ measurement, value: dependentValue });
    }
  }

  return result;
});

const measurementValuesToDeleteLabels = computed(() => {
  const result: { name: string; value: string }[] = [];

  for (const { measurement, value: measurementValue } of measurementValuesToDelete.value) {
    const name = isCustomMeasurement(measurement.name)
      ? measurement.customName
      : getMeasurementDisplayName(measurement.name, "unindexed");

    const displayValue = getStudyMeasurementDisplayValue(
      {
        name: measurement.name,
        values: [measurementValue],
        customUnit: measurement.customUnit,
      },
      "unindexed"
    );

    result.push({ name, value: displayValue?.fullText ?? "" });
  }

  return result;
});

async function deleteMeasurements(): Promise<void> {
  activityText.value = "Deleting";

  try {
    await changeBatchMeasurements(props.study, {
      deletes: measurementValuesToDelete.value.map((m) => ({
        measurementId: m.value.measurementId,
        measurementValueId: m.value.id,
      })),
    });
  } catch (e) {
    addNotification({ type: "error", message: "Measurement deletion failed" });
    return;
  } finally {
    activityText.value = "";
  }

  if (
    activeMeasurement.value.editingMeasurementBatchId.value ===
    props.valueToDelete.measurementCreationBatchId
  ) {
    stopMeasuring();
  }

  closeModal();
}

function closeModal(): void {
  emits("close");
}
</script>

<style scoped lang="scss">
.delete-measurement-values {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  width: 100px;
  justify-self: center;
}

.measurements-table {
  display: grid;
  grid-template-columns: auto auto;
  gap: 6px 40px;
  margin: 24px 0;
}
</style>
