<template>
  <div class="report-template-selector">
    <div v-if="isLoading" class="loading-indicator-container">
      <LoadingIndicator size="2x" />
    </div>
    <template v-else>
      <div class="report-template-list">
        <div
          v-for="template in reportTemplates"
          :key="template.reportTemplateVersionId"
          class="report-template"
          :data-testid="`select-report-template-${template.name}`"
          @click="emits('select-template', template.reportTemplateVersionId)"
        >
          {{ template.name }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import type { ReportTemplateVersionGetAvailableResponseDto } from "../../../backend/src/reporting/dto/report-template-version-get-available.dto";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { addNotification } from "../utils/notifications";

interface Emits {
  (event: "select-template", reportTemplateVersionId: string): void;
}

const emits = defineEmits<Emits>();

const reportTemplates = ref<ReportTemplateVersionGetAvailableResponseDto | undefined>();
const isLoading = ref(true);

onMounted(async () => {
  let response: AxiosResponse<ReportTemplateVersionGetAvailableResponseDto> | undefined = undefined;

  try {
    response = await axios.get<ReportTemplateVersionGetAvailableResponseDto>(
      "/api/reporting/available-templates"
    );
  } catch {
    addNotification({ type: "error", message: "Error loading report templates" });
    return;
  } finally {
    isLoading.value = false;
  }

  reportTemplates.value = response.data;

  reportTemplates.value.sort((a, b) => a.name.localeCompare(b.name));
});
</script>

<style scoped lang="scss">
.report-template-selector {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;

  max-height: 60vh;
  overflow-y: auto;
}

.report-template-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.report-template {
  padding: 4px;
  cursor: pointer;
  transition: color 100ms ease;
  border-radius: var(--border-radius);

  &:hover {
    color: var(--text-color-2);
  }
}

.loading-indicator-container {
  width: 120px;
  height: 60px;
  display: grid;
  place-content: center;
}
</style>
