<template>
  <div data-testid="calculation-input-tooltip" style="display: grid; gap: 4px">
    <b padding-bottom>Calculation Inputs</b>

    <div />
    <div />

    <div
      v-for="calculationInput in calculationInputValues"
      :key="calculationInput.name"
      class="calculation-input-value"
    >
      {{ calculationInput.name }}: {{ calculationInput.value ?? "—" }} {{ calculationInput.unit }}
      <MeasurementBadge :source="calculationInput.source" force-default-formatting />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { findMeasurementForVariableName } from "../../../backend/src/measurements/measurement-calculation-evaluation";
import {
  CalculationPatientMetric,
  MeasurementCalculationVariableType,
  getCalculationMetricDisplayText,
  getCalculationMetricDisplayUnit,
} from "../../../backend/src/measurements/measurement-calculation-variable";
import {
  getMeasurementDisplayName,
  getPatientMetrics,
  getStudyMeasurementDisplayValue,
} from "../../../backend/src/measurements/measurement-display";
import { isCustomMeasurement } from "../../../backend/src/measurements/measurement-names";
import {
  getMeasurementDisplayUnit,
  getUnitDisplayText,
} from "../../../backend/src/measurements/measurement-units";
import { StudyMeasurementValueSource } from "../../../backend/src/studies/study-measurement-enums";
import { removeNullish } from "../utils/array-helpers";
import {
  Study,
  StudyMeasurement,
  StudyMeasurementValue,
  getPatientAgeInYearsWhenScanned,
} from "../utils/study-data";
import MeasurementBadge from "./MeasurementBadge.vue";

interface Props {
  study: Study;
  measurementValue: StudyMeasurementValue;
}

const props = defineProps<Props>();

const calculationInputValues = computed(() => {
  if (props.measurementValue.calculationInputs.length === 0) {
    return [];
  }

  const allMeasurementValues = props.study.measurements.flatMap((m) => m.values);

  const patientMetrics: Partial<Record<CalculationPatientMetric, number | undefined>> = {
    ...getPatientMetrics(props.study),
    age: getPatientAgeInYearsWhenScanned(props.study),
  };

  return removeNullish(
    props.measurementValue.calculationInputs.map((v) => {
      const variable = (props.measurementValue.calculationVariables ?? []).find(
        (c) => c.variableName === v.variableName
      );

      if (variable === undefined) {
        return undefined;
      }

      if (variable.type === MeasurementCalculationVariableType.PatientMetric) {
        return {
          name: getCalculationMetricDisplayText(variable.patientMetric),
          value: patientMetrics[variable.patientMetric]?.toPrecision(3),
          unit: getCalculationMetricDisplayUnit(variable.patientMetric),
          source: "study" as const,
        };
      }

      let measurement: StudyMeasurement | undefined = undefined;
      let measurementValue: StudyMeasurementValue | undefined = undefined;

      if (v.inputMeasurementValueId === null) {
        measurement = findMeasurementForVariableName(
          v.variableName,
          props.study.measurements,
          props.measurementValue.calculationVariables
        );
      } else {
        measurementValue = allMeasurementValues.find((m) => m.id === v.inputMeasurementValueId);

        measurement = props.study.measurements.find(
          (m) => m.id === measurementValue?.measurementId
        );
      }

      if (measurement === undefined) {
        return undefined;
      }

      const name = isCustomMeasurement(measurement.name)
        ? measurement.customName
        : getMeasurementDisplayName(measurement.name, "unindexed");

      const value = getStudyMeasurementDisplayValue(
        {
          name: measurement.name,
          values:
            measurementValue === undefined
              ? measurement.values
              : [{ value: measurementValue.value, selected: true }],
          customUnit: measurement.customUnit,
        },
        "unindexed"
      )?.meanValueRounded;

      const source: StudyMeasurementValueSource | "mean" | "study" =
        measurementValue !== undefined ? measurementValue.source : "mean";

      const unit = getUnitDisplayText(
        isCustomMeasurement(measurement.name) && measurement.customUnit !== null
          ? measurement.customUnit
          : getMeasurementDisplayUnit(measurement.name)
      );

      return { name, value, unit, source };
    })
  );
});
</script>

<style scoped lang="scss">
.calculation-input-value {
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
